import React from 'react';

import { classnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiCommentOutline } from '@lumapps/lumx/icons';
import { ButtonProps } from '@lumapps/lumx/react';
import { ReactionButton } from '@lumapps/reactions-core/components/ReactionButton';
import { REACTIONS_SCOPE } from '@lumapps/reactions-core/constants';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import './index.scss';
import { REACTIONS_COMMENTS } from '../../keys';

export interface CommentButtonProps extends Omit<ButtonProps, 'children'> {
    /** Comment count */
    commentCount?: number;
    /** Show "comment"/"comments" label */
    showLabel?: boolean;
}

const CLASSNAME = 'comment-reaction-button';

/**
 * Specialized ReactionButton to display comment count and link to the comment section of a resource
 */
export const CommentButton = React.forwardRef<HTMLButtonElement, CommentButtonProps>((props, ref) => {
    const { className, commentCount = 0, onClick, isDisabled, showLabel, ...forwardedProps } = props;
    const { translateAndReplace, translateKey, pluralize } = useTranslate();
    const { get } = useDataAttributes(REACTIONS_SCOPE);

    if (!onClick && !forwardedProps.linkAs && !forwardedProps.href) {
        return null;
    }

    const ariaLabel =
        !!forwardedProps.linkAs || !!forwardedProps.href
            ? translateAndReplace(pluralize(REACTIONS_COMMENTS.COMMENT_BUTTON_LINK_ARIA_LABEL, commentCount), {
                  NB: commentCount,
              })
            : translateAndReplace(REACTIONS_COMMENTS.COMMENT_BUTTON_ARIA, { NB: commentCount });

    return (
        <ReactionButton
            ref={ref}
            className={classnames(className, CLASSNAME)}
            leftIcon={mdiCommentOutline}
            onClick={onClick}
            isDisabled={isDisabled}
            aria-label={ariaLabel}
            {...get({ element: 'button', action: 'comment' })}
            {...forwardedProps}
        >
            {String(commentCount)} {showLabel && translateKey(commentCount <= 1 ? GLOBAL.COMMENT : GLOBAL.COMMENTS)}
        </ReactionButton>
    );
});
CommentButton.displayName = 'CommentButton';
