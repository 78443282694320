import { useMemo } from 'react';

import includes from 'lodash/fp/includes';

import { useSelector } from '@lumapps/redux/react';

import { selectSpacePermissions, selectSpaceServices } from '../ducks/selectors';
import { SpaceServices, SpaceViews } from '../types';
import { useSpace } from './useSpace';

export const useSpaceContributionField = (): boolean => {
    const { view } = useSpace() ?? {};

    const {
        canContribute = false,
        canCreateArticle = false,
        canCreateEvent = false,
    } = useSelector(selectSpacePermissions) ?? {};
    const spaceEnabledServices = useSelector(selectSpaceServices);

    return useMemo(() => {
        switch (view) {
            case SpaceViews.home:
                return canContribute && includes(SpaceServices.posts, spaceEnabledServices);
            case SpaceViews.feed:
                return (
                    (canContribute && includes(SpaceServices.posts, spaceEnabledServices)) ||
                    (canCreateArticle && includes(SpaceServices.article, spaceEnabledServices)) ||
                    (canCreateEvent && includes(SpaceServices.event, spaceEnabledServices))
                );
            case SpaceViews.posts:
                return canContribute;
            case SpaceViews.articles:
                return canCreateArticle;
            case SpaceViews.events:
                return canCreateEvent;
            default:
                return false;
        }
    }, [canContribute, canCreateArticle, canCreateEvent, spaceEnabledServices, view]);
};
