import { Dispatch, SetStateAction } from 'react';

import { DialogProps } from '@lumapps/lumx/react';
import type { Video, VideoKind } from '@lumapps/play/api/types';

export interface InitialValues {
    /** Kind of video */
    kind?: VideoKind;
    /** ID used to track actions with PendoID */
    trackingId?: string;
    /** API Error */
    transcriptError: boolean;
    /** Whether the user consulting the video editor can change the video author or not */
    userCanChangeVideoAuthor?: boolean;
    /** Callback that download the current video */
    onVideoDownload?(): Promise<void>;
    /** The original video to edit and compare */
    video?: Video;
    /** Callback when dialog visibility has changed */
    onVisibilityChange: DialogProps['onVisibilityChange'];
    /** All languages site's aware of */
    languages: string[];
    /** Language by default for the site */
    defaultLanguage: string;
    /** Whether the form contains a scheduled date or not */
    isScheduled: boolean;
}

export interface InitialState extends InitialValues {
    nextThumbnail?: File;
    setNextThumbnail: Dispatch<SetStateAction<File | undefined>>;
    /** @deprecated */
    setTranscriptError: (payload: boolean) => void;
    setIsScheduled: (payload: boolean) => void;
}

export enum ACTIONS {
    SET_TRANSCRIPT_ERROR = 'SET_TRANSCRIPT_ERROR',
    SET_IS_SCHEDULED = 'SET_IS_SCHEDULED',
}

export type Action = {
    type: keyof typeof ACTIONS;
    payload: any;
};
