import React from 'react';

import { BackendRecomputedWidget } from '@lumapps/widget-base/components/BackendRecomputedWidget';
import { useEditableWidget } from '@lumapps/widget-base/hooks/useEditableWidget';
import { EditableWidgetProps } from '@lumapps/widget-base/types/designer';

import type { LegacyWidgetTitleProps } from '../../type';
import { EditableWidgetTitle, EditableWidgetTitleProps } from '../EditableWidgetTitle';

export interface WidgetTitleWrapperProps extends EditableWidgetProps<LegacyWidgetTitleProps> {}

export interface EditableWidgetTitleProperties {
    content?: string;
}

export const WidgetTitleWrapper: React.FC<WidgetTitleWrapperProps> = ({
    legacyWidget: initLegacyWidget,
    switchToReadMode,
    saveTextInWidgetProperties,
    isEditingContent,
    isWidgetEmpty,
    contentTitle,
    ...props
}) => {
    const getTranslateObject = React.useCallback((properties?: LegacyWidgetTitleProps) => properties?.content, []);

    const { legacyWidget, content, editableWidgetProps } = useEditableWidget<LegacyWidgetTitleProps>(
        { ...initLegacyWidget, properties: { ...initLegacyWidget?.properties, content: contentTitle } },
        switchToReadMode,
        saveTextInWidgetProperties,
        getTranslateObject,
        true,
    );

    return (
        <BackendRecomputedWidget<LegacyWidgetTitleProps, EditableWidgetTitleProperties, EditableWidgetTitleProps>
            legacyWidget={legacyWidget}
            editableWidgetRenderer={EditableWidgetTitle}
            editableWidgetProps={editableWidgetProps}
            isWidgetEmpty={isWidgetEmpty}
            blockProperties={{ content }}
            editingContent={isEditingContent}
            canUseLangFallback={false}
            {...props}
        />
    );
};
