import React, { ReactNode } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiUpload } from '@lumapps/lumx/icons';
import { Alignment, FlexBox, Icon, Orientation, Size, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import './index.scss';

export interface DroppableFileZoneProps {
    /** custom classname */
    className?: string;
    /** text to be displayed on the droppable file zone */
    droppableZoneText: string | React.ReactNode;
    /** callback on file uploaded */
    onUpload: (files: File[]) => void;
    children?: ReactNode;
}

const CLASSNAME = 'lumx-droppable-file-zone';

/**
 * Component used to add a Drag & Drop zone to upload files
 *
 * @family Files
 */
export const DroppableFileZone: React.FC<DroppableFileZoneProps> = ({
    className,
    children,
    droppableZoneText,
    onUpload,
}) => {
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const [isDropZoneActive, setIsDropZoneActive] = React.useState(false);
    const [shouldEnableZone, setShouldEnableZone] = React.useState(false);

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (isDropZoneActive) {
            setShouldEnableZone(true);
        } else {
            setIsDropZoneActive(true);
        }
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!shouldEnableZone) {
            setIsDropZoneActive(false);
        } else {
            setShouldEnableZone(false);
        }
    };

    const handleDrop = React.useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();

            const files: File[] = event.dataTransfer.files && Array.from(event.dataTransfer.files);

            setIsDropZoneActive(false);
            setShouldEnableZone(false);

            onUpload(files);
        },
        [onUpload, setIsDropZoneActive],
    );

    const renderTextZone = () => {
        if (typeof droppableZoneText === 'string') {
            return (
                <Text as="span" typography="subtitle2">
                    {translateKey(droppableZoneText)}
                </Text>
            );
        }
        return droppableZoneText;
    };

    return (
        <div
            className={block(undefined, className)}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
        >
            {isDropZoneActive && (
                <FlexBox
                    fillSpace
                    hAlign={Alignment.center}
                    vAlign={Alignment.center}
                    orientation={Orientation.vertical}
                    className={element('wrapper')}
                    gap="medium"
                >
                    <Icon icon={mdiUpload} size={Size.l} hasShape />
                    {renderTextZone()}
                </FlexBox>
            )}
            <div className={element('content', { dragging: isDropZoneActive })}>{children}</div>
        </div>
    );
};
