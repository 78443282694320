import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { CommunityVisibilityBlock } from '@lumapps/communities/components/CommunityVisibilityBlock';
import { SPACES } from '@lumapps/communities/keys';
import { CommunityRole } from '@lumapps/communities/types';
import { Tagz } from '@lumapps/folksonomy/components/Tagz';
import { Divider, FlexBox, Text, Typography } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { UserList } from '@lumapps/user/components/UserList';

import { useSpaceMembers } from '../../../hooks/useSpaceMembers';
import { SpaceData } from '../../../types';

type DescriptionProps = Pick<SpaceData, 'description' | 'visibility' | 'folksonomyTags'>;

export const Description: React.FC<DescriptionProps> = ({ description, visibility, folksonomyTags }) => {
    const { translateObject, translateKey } = useTranslate();

    const { users, isLoading, onLoadMore } = useSpaceMembers({ role: CommunityRole.admin });

    const hasFolksonomyTags = folksonomyTags && !isEmpty(folksonomyTags);

    return (
        <FlexBox gap="huge" orientation="vertical">
            {(description || hasFolksonomyTags) && (
                <>
                    <FlexBox gap="big" orientation="vertical">
                        <Text as="p" typography={Typography.body1}>
                            {translateObject(description)}
                        </Text>
                        {hasFolksonomyTags && <Tagz values={folksonomyTags} />}
                    </FlexBox>
                    <Divider />
                </>
            )}
            {visibility && (
                <>
                    <FlexBox orientation="vertical">
                        <Text as="h2" typography={Typography.subtitle2}>
                            {translateKey(SPACES.STEP_VISIBILITY)}
                        </Text>
                        <CommunityVisibilityBlock visibility={visibility} isSpace displayAsCommunity />
                    </FlexBox>
                    <Divider />
                </>
            )}
            <FlexBox orientation="vertical">
                <Text as="h2" typography={Typography.subtitle2}>
                    {translateKey(GLOBAL.MANAGERS)}
                </Text>
                <UserList users={users} isLoading={isLoading} onLoadMore={onLoadMore} scope="space-about-managers" />
            </FlexBox>
        </FlexBox>
    );
};
