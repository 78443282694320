import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useContent } from '@lumapps/contents/hooks/useContent';
import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { getDefaultInstanceLanguage } from '@lumapps/instance/ducks/selectors';
import { useInstance } from '@lumapps/instance/hooks/useInstance';
import { currentLanguageSelector } from '@lumapps/languages';
import { Size, SkeletonRectangle } from '@lumapps/lumx/react';
import { Navigation } from '@lumapps/navigation/types';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { FetchCallbackParams } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { Breadcrumb } from './Breadcrumb';

import './index.scss';

const CLASSNAME = 'widget-breadcrumb';

/**
 * Breadcrumb Widget using Navigation service
 */
export const ClientComputedBreadcrumb: React.FC<ClientComputedProps> = ({ theme, uuid }) => {
    const { element } = useClassnames(CLASSNAME);
    const dispatch = useDispatch();

    const currentContent = useContent();
    const { homeTitle, id: instanceId } = useInstance();
    const lang = useSelector(currentLanguageSelector);
    const alternativeLang = useSelector(getDefaultInstanceLanguage);
    const customer = useSelector(customerIdSelector);

    const { currentContentId, currentContentSlug, currentContentTitle, legacyUrl, isLegacyContentPage, homePageId } =
        currentContent;

    /**
     * Full path to the content, if given. If the URL has a legacy format, we need to use an alternative URL in order
     * to make the routing work.
     */
    const url = isLegacyContentPage ? legacyUrl : currentContentSlug;

    const fetchCallback = React.useCallback(
        ({ success }: FetchCallbackParams<Navigation>) => {
            // if an error occurred or we have not enough data to display anything, hide the widget
            if (!success || !currentContentId || !currentContentTitle) {
                dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
            }
        },
        [currentContentId, currentContentTitle, dispatch, uuid],
    );

    const { breadcrumb, status } = useBreadcrumb({
        uuid,
        url,
        lang,
        alternativeLang,
        customer,
        instance: instanceId,
        pageId: currentContentId,
        fetchCallback,
    });

    if ([BaseLoadingStatus.initial, BaseLoadingStatus.loading].includes(status)) {
        return <SkeletonRectangle className={element('skeleton')} width={Size.xxl} height={Size.m} />;
    }

    if (status === BaseLoadingStatus.idle && currentContentId && currentContentTitle) {
        return (
            <Breadcrumb
                className={CLASSNAME}
                currentContent={{
                    id: currentContentId,
                    slug: url,
                    title: currentContentTitle,
                }}
                home={{ title: homeTitle, id: homePageId }}
                breadcrumb={breadcrumb}
                theme={theme}
            />
        );
    }

    // Return null in the other cases (should not happen)
    return null;
};
