import { LikeListParams, LikeState, MinimalUserList, ResourceRef } from './types';

/** Generic toggle like/unlike query function type */
export type FetchToggleLike<T extends string = string> = (
    resourceRef: ResourceRef<T>,
    isLiked: boolean,
    likeCount: number,
) => Promise<LikeState>;

/** Generic fetch like user list function type */
export type FetchLikeUserList<T extends string = string> = (
    resourceRef: ResourceRef<T>,
    params: LikeListParams,
) => Promise<MinimalUserList>;

/** Generic fetch like status */
export type FetchLikeStatus<T extends string = string> = (
    resourceRef: ResourceRef<T>,
    signal?: AbortSignal,
) => Promise<LikeState>;

/**
 * Generic reactions query keys (independent of v1 or v2)
 */
export const reactionsQueryKeys = {
    listLikes: ({ resourceType, resourceId }: ResourceRef<string>, pageParams?: LikeListParams) => [
        'reactions',
        'list-likes',
        resourceType,
        resourceId,
        pageParams,
    ],
    likeStatus: ({ resourceType, resourceId }: ResourceRef<string>) => [
        'reactions',
        'like-status',
        resourceType,
        resourceId,
    ],
};
