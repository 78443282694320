import React from 'react';

import { getCurrentContentTitle } from '@lumapps/contents/ducks/selectors';
import { isFeatureEnabled } from '@lumapps/features';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { inputLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { ContainerType } from '@lumapps/medias/types';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { STRUCTURED_CONTENT_FORMAT } from '@lumapps/structured-content/types';
import { uploadFile } from '@lumapps/structured-content/utils/uploadFile';
import { standardizeTranslateObject, useTranslate } from '@lumapps/translations';
import { USE_RICH_TEXT_IN_CONTRIBUTION_TOKEN } from '@lumapps/wrex/constants';
import { document } from '@lumapps/wrex/document/structured-content';
import { fromDITA } from '@lumapps/wrex/serialization/dita/fromDITA/fromDITA';
import { toDITA } from '@lumapps/wrex/serialization/dita/toDITA/toDITA';
import { isDitaContentEmpty } from '@lumapps/wrex/serialization/dita/utils/isDitaContentEmpty';
import { isRichTextContentEmpty } from '@lumapps/wrex/serialization/slate/utils/isRichTextContentEmpty';
import { getFirstContentAsString } from '@lumapps/wrex/slate/utils/getFirstContentAsString';
import type { Wrex } from '@lumapps/wrex/types';
import { createEmptyContent } from '@lumapps/wrex/utils/createEmptyContent';
import { normalizeForSlate } from '@lumapps/wrex/utils/normalizeForSlate';

import type { WidgetContributionWrapperProps } from '../components/WidgetContributionWrapper';

export const useEditableWidgetContribution = ({
    legacyWidget,
    switchToReadMode,
    saveTextualContentInWidgetProperties,
}: Pick<
    WidgetContributionWrapperProps,
    'legacyWidget' | 'switchToReadMode' | 'saveTextualContentInWidgetProperties'
>) => {
    const wrapperRef = React.useRef(null);
    const childrenRefs = React.useRef([wrapperRef]);
    const site = useSelector(getCurrentInstance);
    const siteId = site.id || site.uid;
    const contentTitle = useSelector(getCurrentContentTitle);
    const currentInputLang = useSelector(inputLanguageSelector);
    const shouldUseRichText = useSelector(isFeatureEnabled(USE_RICH_TEXT_IN_CONTRIBUTION_TOKEN));
    const widgetProperties = legacyWidget?.properties;

    const dispatch = useDispatch();
    const { translateObject } = useTranslate();

    /** we use slate format to easily switch from read to edit without having to parse every time */
    const getInitialContent = React.useCallback(
        (currentInputLang: string) => {
            // From rich text
            const richText = shouldUseRichText && widgetProperties?.richText?.[currentInputLang]?.children;
            if (richText) {
                return normalizeForSlate(richText);
            }

            // From DITA
            const dita = widgetProperties?.dita?.[currentInputLang];
            if (dita) {
                return fromDITA(dita).children;
            }

            // Fallback to empty content
            return createEmptyContent();
        },
        [shouldUseRichText, widgetProperties?.dita, widgetProperties?.richText],
    );

    /** on click outside the editor, we save the slate content in the widget properties and switch to read mode */
    const exitEditionMode = React.useCallback(
        (content: Wrex.Nodes) => {
            if (shouldUseRichText) {
                const newRichText = document.parse({
                    children: content,
                    title: translateObject(standardizeTranslateObject(contentTitle)) || '',
                    description: getFirstContentAsString(content),
                });

                // Save only if not empty and the lang was previously undefined
                // Used to not trigger an empty modification on the designer
                if (!(!widgetProperties?.richText?.[currentInputLang] && isRichTextContentEmpty(newRichText))) {
                    saveTextualContentInWidgetProperties(
                        {
                            ...widgetProperties?.richText,
                            [currentInputLang]: newRichText,
                        },
                        STRUCTURED_CONTENT_FORMAT.RICH_TEXT,
                    );
                }
            } else {
                const newDita = toDITA({
                    children: content,
                    title: translateObject(standardizeTranslateObject(contentTitle)) || '',
                });

                // Save only if not empty and the lang was previously undefined
                // Used to not trigger an empty modification on the designer
                if (!(!widgetProperties?.dita?.[currentInputLang] && isDitaContentEmpty(newDita))) {
                    /** saved as translatable object to manage multilang */
                    saveTextualContentInWidgetProperties({
                        ...widgetProperties?.dita,
                        [currentInputLang]: newDita,
                    });
                }
            }

            switchToReadMode();
        },
        [
            contentTitle,
            currentInputLang,
            widgetProperties,
            saveTextualContentInWidgetProperties,
            shouldUseRichText,
            switchToReadMode,
            translateObject,
        ],
    );

    const onFileUpload = React.useCallback(
        (files: File[], errorMessage?: string) => {
            return uploadFile(siteId, ContainerType.SITE, files, errorMessage)(dispatch);
        },
        [siteId, dispatch],
    );

    return {
        wrapperRef,
        exitEditionMode,
        childrenRefs,
        onFileUpload,
        getInitialContent,
    };
};
