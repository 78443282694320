/* istanbul ignore file */
import React, { Suspense } from 'react';

import { injectReducer } from '@lumapps/redux/manager';
import { reducer as saShareableContentReducer } from '@lumapps/sa-shareable-contents/ducks';
import { ShareMenuItemLoading } from '@lumapps/share/components/ShareMenuItemLoading';

import { DEFAULT_LOADING_ITEMS } from './constants';

// Dynamically inject the saShareableContent reducer.
injectReducer('saShareableContent', saShareableContentReducer);

const LazyShareToSocialNetworksMenuSection = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'share-to-social-networks-menu-section' */
            './ShareToSocialNetworksMenuSection'
        ),
);

export const ShareToSocialNetworksMenuSection = (
    props: React.ComponentProps<typeof LazyShareToSocialNetworksMenuSection>,
) => {
    const { loadingItems } = props;
    return (
        <Suspense fallback={<ShareMenuItemLoading itemNumber={loadingItems || DEFAULT_LOADING_ITEMS} />}>
            <LazyShareToSocialNetworksMenuSection {...props} />
        </Suspense>
    );
};
