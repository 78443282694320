import { useMemo } from 'react';

import { mdiFormatBold, mdiFormatItalic, mdiFormatStrikethroughVariant, mdiFormatUnderline } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { PLUGIN_SHORTCUTS } from '@lumapps/wrex/constants';
import { ReactEditor, useSlate, useSlateStatic } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { ToolbarItem } from '@lumapps/wrex/types';

import { BOLD, ITALIC, UNDERLINE, STRIKETHROUGH } from '../../../constants';
import { WREX_TYPOGRAPHY } from '../../../keys';
import { TypographyEditor } from '../../../types';

const ICONS = {
    [BOLD]: mdiFormatBold,
    [ITALIC]: mdiFormatItalic,
    [UNDERLINE]: mdiFormatUnderline,
    [STRIKETHROUGH]: mdiFormatStrikethroughVariant,
};

const KEYS = {
    withShortcuts: {
        [BOLD]: WREX_TYPOGRAPHY.BOLD_WITH_SHORTCUT,
        [ITALIC]: WREX_TYPOGRAPHY.ITALIC_WITH_SHORTCUT,
        [UNDERLINE]: WREX_TYPOGRAPHY.UNDERLINE_WITH_SHORTCUT,
        [STRIKETHROUGH]: WREX_TYPOGRAPHY.STRIKETHROUGH_WITH_SHORTCUT,
    },
    withoutShortcuts: {
        [BOLD]: WREX_TYPOGRAPHY.BOLD_BUTTON,
        [ITALIC]: WREX_TYPOGRAPHY.ITALIC_BUTTON,
        [UNDERLINE]: WREX_TYPOGRAPHY.UNDERLINE,
        [STRIKETHROUGH]: WREX_TYPOGRAPHY.STRIKETHROUGH,
    },
};

export const useMarkButton = (type: 'bold' | 'italic' | 'underline' | 'strikethrough', otherProps?: any) => {
    const { translateAndReplace } = useTranslate();

    const editor = useSlate() as ReactEditor & TypographyEditor;
    const editorStatic = useSlateStatic() as ReactEditor & TypographyEditor;

    const isSelected = editor.isMarkActive(type);
    const isDisabled = !editor.isMarkAllowed();

    return useMemo((): ToolbarItem => {
        return {
            type: 'toggle-option',
            itemKey: type,
            icon: ICONS[type],
            tooltipLabel: translateAndReplace(KEYS.withShortcuts[type], {
                shortcut: PLUGIN_SHORTCUTS[type].shortcut,
            }),
            onClick() {
                if (!editorStatic.selection) {
                    initSelection(editorStatic, 'inline');
                }
                editorStatic.toggleMark(type);
                focusAt(editorStatic, editorStatic.selection);
            },
            'aria-disabled': isDisabled,
            isChecked: isSelected,
            verticalModeProps: {
                label: translateAndReplace(KEYS.withoutShortcuts[type], {
                    shortcut: PLUGIN_SHORTCUTS[type].shortcut,
                }),
                tooltipLabel: translateAndReplace(KEYS.withShortcuts[type], {
                    shortcut: PLUGIN_SHORTCUTS[type].shortcut,
                }),
            },
            otherProps,
        };
    }, [type, translateAndReplace, isDisabled, isSelected, otherProps, editorStatic]);
};
