import React from 'react';

import find from 'lodash/find';
import includes from 'lodash/includes';
import map from 'lodash/map';

import { get } from '@lumapps/constants';
import { ContentState } from '@lumapps/contents/ducks/types';
import { getUserAuthorDetails } from '@lumapps/contents/utils/getUserAuthorDetails';
import { Customer } from '@lumapps/customer/types';
import { inputLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations/hooks/useTranslate';
import { connectedUserSelector } from '@lumapps/user/ducks/selectors';
import { User } from '@lumapps/user/types';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';
import { getUserProfilePictureUrl } from '@lumapps/user/utils/getUserProfilePicture';
import { useDetectMobileAppWebView } from '@lumapps/utils/hooks/useDetectMobileAppWebView';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { BlockUserVariant } from '@lumapps/widget-base/types/blocks';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

import { BlockMetadata as BlockMetadataType, LegacyMetadataProperties } from '../../types';
import { BlockMetadata } from '../BlockMetadata';

const constants = get();

export interface ClientComputedMetadataProps extends ClientComputedProps {
    /** Current content. */
    currentContent: ContentState;
    /** Customer. */
    customer: Customer;
    /** Method fetching metadata for a content. */
    fetchMetadata: (currentContentId: string) => void;
    /** Legacy widget properties. */
    properties?: LegacyMetadataProperties;
}

export const ClientComputedMetadata: React.FC<ClientComputedMetadataProps> = ({
    currentContent,
    customer,
    properties,
    uuid,
    fetchMetadata,
}) => {
    const { translateObject } = useTranslate();
    const currentInputLang = useSelector(inputLanguageSelector);
    const connectedUser = useSelector(connectedUserSelector);
    const dispatch = useDispatch();
    const {
        id,
        authorDetails,
        publicationDate,
        comments,
        likes,
        liked,
        customContentTypeTags,
        customContentTypeDetails,
        metadataDetails,
        writerDetails,
        isDesignerMode,
    } = currentContent;
    const { fields, style } = properties || {};
    const isMobileAppWebView = useDetectMobileAppWebView();

    const hasAuthor = !isMobileAppWebView && find(fields, ['name', 'author'])?.enable;
    const hasPublicationDate = !isMobileAppWebView && find(fields, ['name', 'publication-date'])?.enable;
    const hasTags = !isMobileAppWebView && find(fields, ['name', 'tags'])?.enable;
    const hasMetadata = find(fields, ['name', 'metadata'])?.enable;
    const hasSocialBlocks = !isMobileAppWebView && find(fields, ['name', 'social'])?.enable;
    const userBlockDetails =
        !id && connectedUser ? getUserAuthorDetails(connectedUser) : writerDetails || authorDetails;

    // Fetch metadata
    const [fetchMetadataInitiated, setFetchMetadataInitiated] = React.useState(false);
    React.useEffect(() => {
        if (
            hasMetadata &&
            // if metadata haven't already been fetched (in which case the status would be `loading`, `loaded` or `error`)
            !includes(['loading', 'loaded', 'error'], currentContent?.actionState?.fetch?.metadataDetails) &&
            currentContent.metadataDetails === undefined &&
            // if there is no content, we do not want to do this API call
            Boolean(currentContent.id)
        ) {
            fetchMetadata(currentContent.id);
        }
        setFetchMetadataInitiated(true);
    }, [currentContent, fetchMetadata, hasMetadata]);

    // Construct block
    const blockMetadata: BlockMetadataType = {
        type: 'BlockMetadata',
    };

    if (hasAuthor && userBlockDetails) {
        blockMetadata.author = {
            type: 'BlockUser',
            userId: userBlockDetails.id as string,
            firstName: userBlockDetails.firstName as string,
            lastName: userBlockDetails.lastName as string,
            fullName: getUserFullName(userBlockDetails, constants.userLang),
            email: userBlockDetails.email as string,
            variant: BlockUserVariant.regular,
            picture: getUserProfilePictureUrl({ id: userBlockDetails.id, customer: customer.id } as User),
        };
    }

    if (hasPublicationDate) {
        blockMetadata.publicationDate = publicationDate;
    }

    blockMetadata.theme = style?.content?.theme;

    if (hasTags && customContentTypeTags !== undefined && customContentTypeDetails !== undefined) {
        blockMetadata.tags = map(
            customContentTypeDetails.tags?.filter((tag) => customContentTypeTags.includes(tag?.id)) ?? [],
            (tag) => translateObject(tag.name) as string,
        );
    }

    if (hasMetadata && metadataDetails) {
        blockMetadata.metadata = map(metadataDetails, ({ id: metadataId, name }) => ({
            id: metadataId,
            name:
                translateObject(name.translations, isDesignerMode ? currentInputLang : undefined) ||
                (name.value as string),
        }));
    }

    if (hasSocialBlocks) {
        blockMetadata.reactions = {
            type: 'BlockReactions',
            resourceId: id,
            resourceType: 'CONTENT',
            likes,
            isLiked: !!liked,
            // Need to re-fetch the like state (likes & liked)
            isLikeStateStale: true,
            comments,
        };
    }

    const areFieldsHidden = !hasAuthor && !hasPublicationDate && !hasTags && !hasMetadata && !hasSocialBlocks;
    const areFieldsEmpty =
        (hasAuthor ? !authorDetails : true) &&
        (hasPublicationDate ? !publicationDate : true) &&
        (hasTags ? !blockMetadata.tags || blockMetadata.tags.length === 0 : true) &&
        (hasMetadata ? !metadataDetails || metadataDetails.length === 0 : true) &&
        !hasSocialBlocks;

    const isWidgetEmpty = !properties || areFieldsHidden || areFieldsEmpty;

    React.useEffect(() => {
        // Ignore first render, currentContent.actionState is empty and we don't know
        // Wether it's because the metadata are not loaded yet or because the content is empty
        if (!fetchMetadataInitiated) {
            return;
        }
        if (isWidgetEmpty) {
            // We don't want to set the widget as empty if the metadata have not finished loading
            if (includes(['loaded', 'error', undefined], currentContent.actionState?.fetch?.metadataDetails)) {
                dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
            }
        }
    }, [
        areFieldsEmpty,
        areFieldsHidden,
        currentContent.actionState,
        dispatch,
        isWidgetEmpty,
        properties,
        uuid,
        fetchMetadataInitiated,
    ]);

    if (isWidgetEmpty) {
        return null;
    }

    return <BlockMetadata {...blockMetadata} />;
};
