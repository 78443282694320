import React from 'react';

import { BackendRecomputedWidget } from '@lumapps/widget-base/components/BackendRecomputedWidget';
import { useRegisterEditableWidget } from '@lumapps/widget-base/hooks/useEditableWidget';
import { EditableWidgetProps } from '@lumapps/widget-base/types/designer';

import { LegacyWidgetFeaturedImageProps } from '../../type';
import { EditableWidgetFeaturedImage, EditableWidgetFeaturedImageProps } from '../EditableWidgetFeaturedImage';

export interface WidgetFeaturedImageWrapperProps extends EditableWidgetProps<LegacyWidgetFeaturedImageProps> {
    editableWidgetProps?: EditableWidgetFeaturedImageProps;
}

export interface EditableWidgetFeaturedImageProperties {}

export const WidgetFeaturedImageWrapper: React.FC<WidgetFeaturedImageWrapperProps> = ({
    legacyWidget,
    isEditingContent = false,
    isWidgetEmpty,
    ...props
}) => {
    useRegisterEditableWidget(legacyWidget);

    return (
        <BackendRecomputedWidget<
            LegacyWidgetFeaturedImageProps,
            EditableWidgetFeaturedImageProperties,
            EditableWidgetFeaturedImageProps
        >
            legacyWidget={legacyWidget}
            editableWidgetRenderer={EditableWidgetFeaturedImage}
            shouldUpdateWhileEditing
            isWidgetEmpty={isWidgetEmpty}
            editingContent={isEditingContent}
            canUseLangFallback={false}
            {...props}
        />
    );
};
