import React, { Children } from 'react';

import { useDimensions } from '@lumapps/responsive';

// Breakpoints are selected to match Grid ones, that are handled through CSS
const BREAKPOINTS_COLUMNS = {
    5: Infinity, // if container if above 960px we don't need to enforce responsivness
    4: 980,
    3: 840,
    2: 550,
    1: 0,
};

export const useColumns = ({
    columns,
    children,
}: {
    columns: number;
    children: React.ReactNode;
    defaultColumns?: number;
}) => {
    /**
     * In order to determine how many columns to use, we need to first figure out our context.
     * - If we are in a mobile resolution, we will display one or two columns depending on the available space.
     * - If the total children to display is less than the total columns to use, is does not make a lot of sense to display 2 items in three
     * columns, since one of them will be empty. If that is the case, we just use as many columns as items we have.
     */
    const { ref, currentBreakpoint: responsiveColumns } = useDimensions({ breakpoints: BREAKPOINTS_COLUMNS });

    const columnsToUse = columns < responsiveColumns ? columns : responsiveColumns;

    return { columns: !responsiveColumns ? undefined : Math.min(columnsToUse, Children.count(children)), ref };
};
