import React, { Ref } from 'react';

import filter from 'lodash/filter';

import { padding, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { SearchField, SearchFieldProps } from '@lumapps/lumx-filter-and-sort/components/SearchField';
import { NoResultsState } from '@lumapps/lumx-states/components/NoResultsState';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { mdiAlertCircle, mdiClose } from '@lumapps/lumx/icons';
import {
    Chip,
    Icon,
    List,
    SelectMultiple,
    Size,
    ListSubheader,
    ListDivider,
    SelectMultipleProps,
    Theme,
    ColorPalette,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useFocus } from '@lumapps/utils/hooks/useFocus';
import { InfiniteScroll } from '@lumapps/utils/hooks/useInfiniteScroll';

import { useFixedHeightList } from '../../hooks/useFixedHeightList';
import { OptionsLoadingState } from '../SelectFieldSimple/OptionsLoadingState';
import { defaultChoiceRender } from './renderChoicesFactories';
import { ChoiceRenderer } from './types';

import './index.scss';

const CLASSNAME = 'select-field-multiple';

export interface SelectFieldMultipleProps<V = any> {
    /** Select field label. */
    label?: string;
    /** List of selected values. */
    value: V[];
    /** On change selected values. */
    onChange(value: V[]): void;
    /** Get identifier for given value (or cast to string by default). */
    getValueId?(value: V): string;
    /** Get display name of the value (or cast to string by default). */
    getValueName?(value: V): React.ReactNode;
    /** Whether the select is disabled or not. */
    isDisabled?: boolean;
    /** Open the select choice dropdown. */
    isOpen: boolean;
    /** Open/Close dropdown on user interaction. */
    setOpen(open: boolean): void;
    /** Select field choice search text. */
    searchText?: string;
    /** Select field choice search placeholder. */
    searchPlaceholder?: string;
    /** On select field choice search change. */
    onSearch?(searchText: string): void;
    /**
     * Either provide an array of values of the same type of the select values and get generic rendering of choices.
     * Or provide your own renderer for choices.
     */
    choices: V[] | ChoiceRenderer<V>;
    /** Error message. */
    error?: string;
    /** Is loading. */
    isLoading?: boolean;
    /** is loading more elements */
    isLoadingMore?: boolean;
    /** Show loading error state. */
    hasLoadingError?: boolean;
    /** scope where this component is used */
    scope?: string;
    /** The callback function called when the button on the loading error state is clicked. */
    onRetryLoading?(): void;
    /** The callback function called when the bottom of the dropdown is reached. */
    onInfiniteScroll?(): void;
    /** ClassName to add to the underlying select */
    className?: string;
    /** LumX dark/light theme */
    theme?: Theme;
    /** props for the search field */
    searchFieldProps?: Partial<SearchFieldProps>;
    /** select field multiple helper */
    additionalProps?: Partial<SelectMultipleProps>;
    /**
     * total amount of items to display in the list before the scroll is displayed. This should be consistent with the
     * amount of results you are displaying, mainly with the page size if you are using a paginated API to show these results
     */
    maxResultsForInfiniteScroll?: number;
    /** whether the multiple select can be empty or not. If false, the last option selected cannot be removed */
    canSelectionBeEmpty?: boolean;
    /** ref of the element to focus in form validation */
    selectElementRef?: Ref<HTMLDivElement>;
    /** array of items ids on which we want to apply an error state */
    choicesInError?: string[];
}

/**
 * Component that displays a select field with multiple options.
 *
 * @deprecated If possible, prefer usage of `SelectTextField` with `selectionType="multiple"`. This new component
 * uses the combobox accessible pattern.
 * Be aware that `SelectTextField` implements only the `input` `selectVariant` with the search feature.
 * It should now be the default design. *
 * @family Combobox
 * @param SelectFieldMultipleProps
 * @returns SelectFieldMultiple
 */
export function SelectFieldMultiple<V>(props: SelectFieldMultipleProps<V>) {
    const {
        label,
        value: selectedValues,
        isDisabled,
        isOpen,
        setOpen,
        onChange,
        getValueId = String,
        getValueName = String,
        onSearch,
        onInfiniteScroll,
        searchText,
        searchPlaceholder,
        searchFieldProps = {},
        choices = [],
        error,
        isLoading,
        isLoadingMore,
        hasLoadingError,
        onRetryLoading,
        additionalProps = {},
        maxResultsForInfiniteScroll,
        scope = 'select-field-multiple',
        className,
        canSelectionBeEmpty = true,
        theme,
        selectElementRef,
        choicesInError,
    } = props;
    const { get } = useDataAttributes(scope);
    const { translateKey } = useTranslate();
    const listRef = React.useRef<HTMLUListElement | null>(null);
    const toggleSelect = () => setOpen(!isOpen);
    const closeSelect = () => isOpen && setOpen(false);
    const { element } = useClassnames(CLASSNAME);

    const shouldBeUnableToRemove = !canSelectionBeEmpty && selectedValues.length === 1;

    const onSelect = React.useCallback(
        (selectValue: V, wasSelected: boolean) => () => {
            const newValue = wasSelected
                ? filter(selectedValues, (v) => getValueId(v) !== getValueId(selectValue))
                : [...selectedValues, selectValue];
            onChange(newValue);
        },
        [getValueId, onChange, selectedValues],
    );

    const onError = React.useCallback(
        (selectedValue: V) => {
            if (choicesInError && choicesInError.find((c: string) => c === getValueId(selectedValue))) {
                return <Icon icon={mdiAlertCircle} color={ColorPalette.red} />;
            }
            return undefined;
        },
        [choicesInError, getValueId],
    );

    const selectedChoiceRender = React.useCallback(
        (selectedValue: V) => {
            const onClick = (evt: any) => {
                evt.stopPropagation();
                return onSelect(selectedValue, true)();
            };

            return (
                <Chip
                    key={getValueId(selectedValue)}
                    after={!shouldBeUnableToRemove ? <Icon icon={mdiClose} size={Size.xxs} /> : undefined}
                    size={Size.s}
                    onAfterClick={!shouldBeUnableToRemove ? onSelect(selectedValue, true) : undefined}
                    onClick={!shouldBeUnableToRemove ? onClick : undefined}
                    theme={theme}
                    before={onError(selectedValue)}
                    {...get({ element: 'chip', action: getValueId(selectedValue) })}
                >
                    {getValueName(selectedValue)}
                </Chip>
            );
        },
        [getValueId, shouldBeUnableToRemove, onSelect, theme, onError, get, getValueName],
    );

    const onScrollBottomReached = () => {
        if (onInfiniteScroll) {
            onInfiniteScroll();
        }
    };

    const searchFieldRef = React.useRef<HTMLInputElement>(null);
    useFocus(isOpen && searchFieldRef);

    let renderedChoices: React.ReactNode = React.useMemo(() => {
        if (typeof choices === 'function') {
            // Custom renderer.
            return choices(selectedValues, onSelect);
        }
        return defaultChoiceRender<V>({
            choices,
            getValueId,
            getValueName,
            canSelectionBeEmpty,
        })(selectedValues, onSelect);
    }, [choices, getValueId, getValueName, canSelectionBeEmpty, selectedValues, onSelect]);

    if (isLoading) {
        renderedChoices = <OptionsLoadingState />;
    } else if (hasLoadingError) {
        renderedChoices = <ServiceNotAvailableState onRetry={onRetryLoading} className={padding('all', 'regular')} />;
    } else if (React.Children.count(renderedChoices) <= 0) {
        renderedChoices = <NoResultsState searchValue={searchText} />;
    }

    const heightList = useFixedHeightList({
        totalChoices: choices.length,
        hasHeader: Boolean(onSearch),
        shouldCalculateHeight: Boolean(onInfiniteScroll),
        maxResults: maxResultsForInfiniteScroll,
    });

    return (
        <SelectMultiple
            selectElementRef={selectElementRef}
            theme={theme}
            isDisabled={isDisabled}
            isOpen={isOpen}
            label={label}
            value={selectedValues as any}
            onDropdownClose={closeSelect}
            onInputClick={toggleSelect}
            selectedChipRender={selectedChoiceRender as any}
            selectedValueRender={getValueName as any}
            error={error}
            hasError={!!error}
            {...get({ element: 'select' })}
            className={className}
            {...additionalProps}
        >
            <List
                className={element('list')}
                isClickable
                {...get({ element: 'option-list' })}
                ref={listRef}
                style={{
                    height: !isLoading ? `${heightList}px` : undefined,
                }}
            >
                {onSearch && (
                    <>
                        <ListSubheader>
                            <SearchField
                                onSearch={onSearch}
                                debounced
                                {...searchFieldProps}
                                textFieldProps={{
                                    ...searchFieldProps?.textFieldProps,
                                    inputRef: searchFieldRef,
                                    ...get({ element: 'input', action: 'search' }),
                                }}
                                wrapperProps={{
                                    className: element('search-field'),
                                }}
                                label={searchPlaceholder || translateKey(GLOBAL.SEARCH)}
                                value={searchText ?? ''}
                            />
                        </ListSubheader>
                        <ListDivider role="presentation" />
                    </>
                )}

                {renderedChoices}

                {onInfiniteScroll ? (
                    <InfiniteScroll
                        callback={onScrollBottomReached}
                        options={{
                            root: listRef.current,
                        }}
                    />
                ) : null}

                {isLoadingMore ? <OptionsLoadingState /> : null}
            </List>
        </SelectMultiple>
    );
}
SelectFieldMultiple.displayName = 'SelectFieldMultiple';
