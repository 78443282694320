module.exports = [
    'account',
    'account-box-outline',
    'account-card-details',
    'account-card-details-outline',
    'account-circle',
    'account-edit',
    'account-multiple',
    'account-network',
    'account-star',
    'account-search',
    'airballoon',
    'airplane',
    'alarm',
    'album',
    'alert-circle-outline',
    'alert-outline',
    'alphabetical',
    'apps',
    'arrow-expand-all',
    'arrow-down-drop-circle',
    'arrow-down-drop-circle-outline',
    'arrow-left',
    'arrow-left-drop-circle',
    'arrow-left-drop-circle-outline',
    'arrow-right',
    'arrow-right-drop-circle',
    'arrow-right-drop-circle-outline',
    'attachment',
    'baidu',
    'bank',
    'basket',
    'beer',
    'bell',
    'bell-outline',
    'bike',
    'blinds',
    'blur',
    'book',
    'book-open',
    'book-multiple',
    'book-open-variant',
    'bookmark',
    'bookmark-outline',
    'bookmark-plus',
    'bowling',
    'briefcase',
    'briefcase-outline',
    'brightness-2',
    'broom',
    'bullhorn',
    'bus',
    'cake-variant',
    'calculator',
    'calendar',
    'calendar-clock',
    'calendar-question',
    'calendar-text',
    'call-merge',
    'camcorder',
    'camera',
    'camera-burst',
    'car',
    'cards',
    'cart',
    'cash',
    'cash-multiple',
    'cellphone-android',
    'chart-areaspline',
    'chart-bar',
    'chart-line',
    'checkbox-blank',
    'checkbox-blank-circle',
    'checkbox-blank-circle-outline',
    'checkbox-marked-outline',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'city',
    'clipboard-outline',
    'clipboard-text',
    'clock',
    'clock-outline',
    'cloud',
    'code-greater-than',
    'code-less-than',
    'coffee',
    'comment',
    'comment-question-outline',
    'comment-search',
    'comment-search-outline',
    'comment-text-outline',
    'compass',
    'console',
    'content-paste',
    'cow',
    'creation',
    'credit-card',
    'crosshairs-gps',
    'death-star',
    'death-star-variant',
    'discord',
    'dna',
    'duck',
    'earth',
    'email',
    'emoticon',
    'facebook',
    'facebook-box',
    'factory',
    'ferry',
    'file',
    'file-document-box',
    'file-tree',
    'filmstrip',
    'fingerprint',
    'fire',
    'fish',
    'flag',
    'flag-checkered',
    'flash',
    'flower',
    'folder',
    'folder-google-drive',
    'folder-star',
    'food',
    'food-apple',
    'food-variant',
    'format-paint',
    'gamepad-variant',
    'gas-station',
    'glass-cocktail',
    'gift',
    'github-circle',
    'github-face',
    'google',
    'google-circles',
    'google-circles-extended',
    'google-drive',
    'google-maps',
    'google-plus',
    'hanger',
    'headphones',
    'headset',
    'heart',
    'help-circle',
    'help-circle-outline',
    'home',
    'hospital',
    'hotel',
    'image-area',
    'image-filter',
    'image-filter-drama',
    'image-filter-none',
    'instagram',
    'key',
    'lan',
    'laptop',
    'leaf',
    'lightbulb',
    'link',
    'linkedin',
    'linkedin-box',
    'link-variant',
    'linux',
    'lock',
    'looks',
    'loupe',
    'magnify',
    'magnify-minus',
    'magnify-plus',
    'at',
    'map',
    'map-marker',
    'marker-check',
    'math-compass',
    'menu',
    'message',
    'microphone',
    'minus',
    'minus-box',
    'minus-box-outline',
    'motorbike',
    'multiplication',
    'navigation',
    'needle',
    'new-box',
    'newspaper',
    'numeric-0-box',
    'numeric-0-box-outline',
    'numeric-1-box',
    'numeric-1-box-outline',
    'numeric-2-box',
    'numeric-2-box-outline',
    'numeric-3-box',
    'numeric-3-box-outline',
    'numeric-4-box',
    'numeric-4-box-outline',
    'numeric-5-box',
    'numeric-5-box-outline',
    'numeric-6-box',
    'numeric-6-box-outline',
    'numeric-7-box',
    'numeric-7-box-outline',
    'numeric-8-box',
    'numeric-8-box-outline',
    'numeric-9-box',
    'numeric-9-box-outline',
    'open-in-new',
    'package-variant',
    'palette',
    'pencil',
    'phone',
    'pin',
    'pine-tree',
    'play',
    'play-circle-outline',
    'plus',
    'plus-box',
    'plus-box-outline',
    'presentation',
    'printer',
    'puzzle',
    'puzzle-outline',
    'rocket',
    'rss',
    'rss-box',
    'run',
    'school',
    'script',
    'seal',
    'send',
    'settings',
    'signal',
    'silverware-variant',
    'sina-weibo',
    'snapchat',
    'snowflake',
    'snowman',
    'speaker',
    'star',
    'subway',
    'swim',
    'tag',
    'tencent-qq',
    'tencent-video',
    'tent',
    'terrain',
    'tik-tok',
    'tooltip-account',
    'traffic-light',
    'train',
    'tram',
    'trophy',
    'trophy-variant',
    'truck',
    'tshirt-crew',
    'twitch',
    'twitter',
    'twitter-box',
    'walk',
    'wallet',
    'watch',
    'weather-partly-cloudy',
    'web',
    'wechat',
    'wrench',
    'youtube',
];
