import { useMemo } from 'react';

import type { TranslatedLanguage } from '@lumapps/languages/types';
import { sortArrayWithSpecifiedOrder } from '@lumapps/play/utils';
import { getKeyForLanguage, useTranslate } from '@lumapps/translations';

import { GB, PTBR, US, ZHHK } from '../../constants';
import { PLAY_SUBTITLES } from '../../keys';
import type { VideoSubtitle } from '../../types';

export interface UseTranslatedLanguageParams {
    /**
     * List of language codes
     */
    fullLanguageList: string[];
    /**
     *  List of most used language codes in a specific order
     * @example ['en', 'fr', 'gb']
     */
    topLanguages?: string[];
}

export const useTranslatedLanguages = (
    { fullLanguageList, topLanguages }: UseTranslatedLanguageParams = { fullLanguageList: [] },
) => {
    const { translateKey } = useTranslate();

    const getLabel = (langCode: string) => {
        switch (langCode) {
            case GB:
                return translateKey(PLAY_SUBTITLES.LANG_EN_UK);
            case US:
            case 'en_us':
                return translateKey(PLAY_SUBTITLES.LANG_EN_US);
            case PTBR:
                return translateKey(PLAY_SUBTITLES.LANG_PT_BR);
            case ZHHK:
                return translateKey(PLAY_SUBTITLES.LANG_ZH_HK);
            default:
                return translateKey(getKeyForLanguage(langCode));
        }
    };

    /**
     * Create a label/value pair for each available language
     */
    const getTranslationsFromLanguageCodes = (codeList: string[]): TranslatedLanguage[] => {
        return codeList?.map((code) => ({
            label: getLabel(code),
            key: code,
        }));
    };
    const fullTranslatedLanguageList = getTranslationsFromLanguageCodes(fullLanguageList);

    // Add the chosen top languages to the list of languages
    const translatedTopLanguages = (topLanguages && getTranslationsFromLanguageCodes(topLanguages)) || [];

    const sortedTranslatedList = useMemo(
        () => fullTranslatedLanguageList?.sort((a, b) => a.label.localeCompare(b.label)),
        [fullTranslatedLanguageList],
    );

    const langListWithTopLanguages = [...translatedTopLanguages, ...sortedTranslatedList];

    const codeListWithTopLanguages = langListWithTopLanguages.map((lang) => lang.key);

    const withFilteredLanguages = useMemo(
        () => topLanguages && sortArrayWithSpecifiedOrder(fullLanguageList, topLanguages),
        [topLanguages, fullLanguageList],
    );

    const withTopAndFilteredLanguages =
        withFilteredLanguages && getTranslationsFromLanguageCodes(withFilteredLanguages);

    const switchLanguageCode = (lang: string) => {
        switch (lang) {
            case 'en':
            case 'en_us':
                return US;
            case 'en_gb':
                return GB;
            case 'pt_br':
                return PTBR;
            case 'zh_hk':
                return ZHHK;
            default:
                return lang;
        }
    };

    const sortSubtitlesByLanguageLabel = (subs: VideoSubtitle[]) => {
        const subtitlesWithSwitchedLangCodes = subs.map((sub) => {
            return {
                ...sub,
                language: switchLanguageCode(sub.language),
            };
        });

        const subtitlesSortedByLabel = subtitlesWithSwitchedLangCodes.sort((a, b) =>
            getLabel(a.language).localeCompare(getLabel(b.language)),
        );

        return subtitlesSortedByLabel;
    };

    return {
        // full language list (no top languages, no A-Z order)
        fullTranslatedLanguageList,
        /** Function that return the label for the language code.
         *
         * Handle special cases like 'en-GB', 'en-US' or 'pt-BR'
         */
        getLabel,
        // full language list (no top languages, languages in A-Z order)
        sortedTranslatedList,
        // list of top languages + full language list (top languages repeated, languages in A-Z order)
        langListWithTopLanguages,
        /**
         *  list of top languages + language list (top languages filtered out)
         * ['en', 'gb', 'fr', 'ja', 'pt']
         */
        withTopAndFilteredLanguages,
        /**
         * list of top languages + full language list codes (top languages repeated, languages in A-Z order)
         * // ['en', 'gb', 'fr', 'ja', 'en', 'pt', 'fr', 'gb'];
         */
        codeListWithTopLanguages,
        /** The custom language translation function */
        getTranslationsFromLanguageCodes,
        /** Sorts subitles by language label */
        sortSubtitlesByLanguageLabel,
        /** Convert language codes, received as v1 format('en_gb') to 'en-GB' (as expected by the API) */
        switchLanguageCode,
    };
};
