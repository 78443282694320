import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Size, Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { ShareToExtensionDialog } from '@lumapps/share-extensions/components/ShareToExtensionDialog';
import { ShareToExtensionsMenuItem } from '@lumapps/share-extensions/components/ShareToExtensionsMenuItem';
import { useLoadExtensionsShareDialog } from '@lumapps/share-extensions/hooks/useLoadExtensionsShareDialog';
import { ShareToSlackDialog } from '@lumapps/share-slack/components/ShareToSlackDialog';
import { ShareToSlackMenuItem } from '@lumapps/share-slack/components/ShareToSlackMenuItem';
import { useLoadSlackShareDialog } from '@lumapps/share-slack/hooks/useLoadSlackShareDialog';
import { ShareContentToSocialNetworkDialog } from '@lumapps/share-social-networks/components/ShareContentToSocialNetworkDialog';
import { ShareToSocialNetworksMenuSection } from '@lumapps/share-social-networks/components/ShareToSocialNetworksMenuSection';
import { useLoadSocialNetworkShareDialog } from '@lumapps/share-social-networks/hooks/useLoadSocialNetworkShareDialog';
import { ShareToTeamsDialog } from '@lumapps/share-teams/components/ShareToTeamsDialog';
import { ShareToTeamsMenuItem } from '@lumapps/share-teams/components/ShareToTeamsMenuItem';
import { useLoadTeamsShareDialog } from '@lumapps/share-teams/hooks/useLoadTeamsShareDialog';
import { ShareMenuButton } from '@lumapps/share/components/ShareMenuButton';
import { ShareToCopyMenuItem } from '@lumapps/share/components/ShareToCopyMenuItem';
import type { Preview } from '@lumapps/share/types';
import { isConnected } from '@lumapps/user/ducks/selectors';

export interface ShareContentMenuButtonProps {
    theme?: Theme;
    preview: Preview;
}

const SCOPE = 'share-actions-dropdown';
const CLASSNAME = 'content-share-button';

/**
 * Share content menu button
 */
export const ShareContentMenuButton: React.FC<ShareContentMenuButtonProps> = (props) => {
    const { preview, theme } = props;
    const { element } = useClassnames(CLASSNAME);
    const buttonRef = React.useRef(null);

    const slack = useLoadSlackShareDialog();
    const teams = useLoadTeamsShareDialog();
    const extensions = useLoadExtensionsShareDialog();
    const socialNetwork = useLoadSocialNetworkShareDialog();

    // Do not render if no user is connected
    const connectedUser = useSelector(isConnected);
    if (!connectedUser) {
        return null;
    }

    return (
        <div className={element('wrapper')}>
            <ShareMenuButton buttonRef={buttonRef} theme={theme} size={Size.m} scope={SCOPE}>
                <ShareMenuButton.Section>
                    <ShareToSlackMenuItem onClick={slack.handleOpenSlack} scope={SCOPE} />
                    <ShareToTeamsMenuItem onClick={teams.handleOpenTeams} scope={SCOPE} />
                </ShareMenuButton.Section>

                <ShareMenuButton.Section>
                    <ShareToExtensionsMenuItem onClick={extensions.handleOpenExtension} scope={SCOPE} />
                </ShareMenuButton.Section>

                <ShareMenuButton.Section>
                    <ShareToCopyMenuItem link={preview.link} scope={SCOPE} />
                </ShareMenuButton.Section>

                {preview.id && (
                    <ShareToSocialNetworksMenuSection
                        onClick={socialNetwork.handleSetActiveSocialNetwork}
                        contentInternalId={preview.id}
                        scope={SCOPE}
                    />
                )}
            </ShareMenuButton>

            {teams.teamsIsLoaded && (
                <ShareToTeamsDialog
                    parentElement={buttonRef}
                    preview={preview}
                    onOpen={teams.onTeamsLoaded}
                    isOpen={teams.isShareToTeamsDialog}
                    onClose={teams.closeShareToTeamsDialog}
                />
            )}

            {slack.slackIsLoaded && (
                <ShareToSlackDialog
                    parentElement={buttonRef}
                    preview={preview}
                    onOpen={slack.onSlackLoaded}
                    isOpen={slack.isShareToSlackDialog}
                    onClose={slack.closeShareToSlackDialog}
                />
            )}

            {extensions.currentExtension && extensions.extensionIsLoaded && (
                <ShareToExtensionDialog
                    parentElement={buttonRef}
                    preview={preview}
                    extension={extensions.currentExtension}
                    onOpen={extensions.onExtensionLoaded}
                    isOpen={extensions.isShareToExtensionDialog}
                    onClose={extensions.closeShareToExtensionDialog}
                />
            )}

            {preview.id && socialNetwork.activeSocialNetwork && (
                <ShareContentToSocialNetworkDialog
                    contentInternalId={preview.id}
                    socialNetwork={socialNetwork.activeSocialNetwork}
                    isOpen={socialNetwork.isOpen}
                    onClose={socialNetwork.onClose}
                    parentElement={buttonRef}
                />
            )}
        </div>
    );
};
