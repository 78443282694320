import React, { useMemo, useState } from 'react';

import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import lowerCase from 'lodash/fp/lowerCase';
import map from 'lodash/fp/map';
import property from 'lodash/fp/property';
import trim from 'lodash/fp/trim';

import { MultipleSelectFilter } from '@lumapps/lumx-filter-and-sort/components/MultipleSelectFilter';

import { PostListFilterBlockProps } from '../../../types';

const CLASSNAME = 'post-select-multiple-filter';

export interface PostSelectMultipleFilterBlockProps<T> extends PostListFilterBlockProps<T> {
    getValueId(value: T): string;
}

export const PostSelectMultipleFilterBlock = <T,>({
    onSelect,
    choices = [],
    selectedValues = [],
    getChoiceName,
    getValueId,
    filterId,
    label,
}: PostSelectMultipleFilterBlockProps<T>) => {
    const [searchValue, setSearchValue] = useState<string>('');

    const selectTextFieldProps = useMemo(
        () => ({
            autoFilter: true,
            getOptionId: getValueId,
            getOptionName: getChoiceName,
            onSearch: setSearchValue,
            showEmptyState: true,
            showErrorState: true,
        }),
        [getChoiceName, getValueId],
    );

    const filteredChoices = React.useMemo(() => {
        if (!searchValue) {
            return choices;
        }
        return filter((choice) => {
            const translatedName = getChoiceName(property('value', choice));

            const comparable = flow(trim, lowerCase);

            return comparable(translatedName).includes(comparable(searchValue));
        }, choices);
    }, [choices, getChoiceName, searchValue]);

    const handleSelect = (nextSelectedValues: T[]) => {
        onSelect(filterId, nextSelectedValues);
    };

    return (
        <MultipleSelectFilter<T>
            title={label}
            options={map(property('value'), filteredChoices)}
            selection={selectedValues}
            onSelectedMultiple={handleSelect}
            selectTextFieldProps={selectTextFieldProps}
            id={filterId}
            className={CLASSNAME}
        />
    );
};
