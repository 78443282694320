import React, { ReactNode } from 'react';

import { UseFormReturn } from 'react-hook-form';

import { FlexBox, FlexBoxProps, Theme, ToolbarProps } from '@lumapps/lumx/react';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { LumxFormContextOptions } from '../../utils/FormContext';
import { FormProvider } from '../../utils/FormProvider';
import { FormActions, FormActionsProps } from '../FormActions';

export interface FormProps extends LumxFormContextOptions {
    /** callback on form cancel */
    onCancel?: () => void;
    /** callback on form submit */
    onSubmit: (data: any) => void;
    /** data related to the form setup and configuration */
    methods: UseFormReturn;
    /** additional props of the form wrapper */
    wrapperProps?: FlexBoxProps;
    /** additional props for the actions toolbar */
    toolbarProps?: ToolbarProps;
    /** additional props for the form HTML tag */
    formProps?: React.FormHTMLAttributes<HTMLFormElement>;
    /** whether to implement your own actions external. Useful when creating forms inside dialogs */
    useExternalActions?: boolean;
    /** custom form actions, useful for maintaining the actions inside the form, but customising them nonetheless */
    CustomFormActions?: React.FC<FormActionsProps>;
    /** additional form action props, useful for wording changes */
    formActionProps?: Partial<FormActionsProps>;
    /** element to be added before the form fields */
    before?: React.ReactNode;
    /** element to be added after the form fields */
    after?: React.ReactNode;
    /** status for the current form */
    status?: BaseLoadingStatus;
    /** callback on form discard */
    hasDiscard?: boolean;
    /** theme */
    theme?: Theme;
    /** classname */
    className?: string;
    children?: ReactNode;
}

/**
 * A component that renders a form that uses react-hook-form. This is only
 * the UI, please use the default export if you want to avoid managing the state yourself
 * @family Forms
 * @param FormProps
 * @returns Form
 */
export const Form: React.FC<FormProps> = ({
    children,
    onSubmit,
    onCancel,
    hasDiscard,
    methods,
    formProps,
    wrapperProps,
    toolbarProps,
    useExternalActions = false,
    CustomFormActions,
    formActionProps,
    before,
    after,
    scope,
    status = BaseLoadingStatus.idle,
    theme = Theme.light,
    ...restOfProps
}) => {
    const Actions = CustomFormActions || FormActions;

    return (
        <FormProvider {...methods} {...restOfProps} scope={scope}>
            <form onSubmit={onSubmit} noValidate {...formProps}>
                {before}

                <FlexBox orientation="vertical" gap="big" {...wrapperProps}>
                    {children}
                </FlexBox>

                {after}

                {!useExternalActions ? (
                    <Actions
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        onDiscard={hasDiscard ? () => methods.reset() : undefined}
                        toolbarProps={toolbarProps}
                        scope={scope}
                        theme={theme}
                        {...formActionProps}
                        status={status}
                    />
                ) : null}
            </form>
        </FormProvider>
    );
};
