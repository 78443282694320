import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { DragDropContext, Draggable, Droppable } from '@lumapps/lumx-dnd/components/DragAndDrop';

import { UseDraggable } from '../../../hooks/useDraggable';
import { DraggableListDisplayHorizontal, DraggableListDisplayVertical } from '../../../types';
import { getKeyForItem, revertUniqueId } from '../../../utils';

import './index.scss';

export type DraggableListProps<T> = {
    /** node to be rendered after all items have been rendered */
    afterItems?: React.ReactNode;
    /** Custom class name */
    className?: string;
    /** The display properties of the list */
    display: DraggableListDisplayVertical | DraggableListDisplayHorizontal;
    /** the useDraggable return */
    draggable: UseDraggable<T>;
    /** whether the entire list is disabled or not */
    isDisabled?: boolean;
    /** Callback to render an item */
    itemRenderer: (item: T, dragHandleProps: any, index: number) => React.ReactNode;
    /** Key to identify an item ex: id */
    keyPropName?: string;
    /** ref to the parent element */
    parentRef?: React.RefObject<HTMLDivElement>;
};

/**
 * Generic simple Draggable list
 * Only support one drop zone
 * @family Lists
 * @param param0 DraggableListProps
 * @returns component
 */
export const DraggableListView = <T,>({
    afterItems,
    className,
    display,
    draggable,
    isDisabled,
    itemRenderer,
    keyPropName,
    parentRef,
}: DraggableListProps<T>) => {
    const { block, element } = useClassnames(draggable.className);

    return (
        <DragDropContext
            onDragUpdate={draggable.onDragUpdate}
            onDragEnd={draggable.onDragEnd}
            onDragStart={draggable.onDragStart}
        >
            <div ref={parentRef}>
                {draggable.listOfItems.map((list, index) => {
                    /**
                     * If we are in vertical mode, items that should be displayed after the original
                     * list of items are just displayed the bottom. To figure out if we need to display
                     * them, we just validate that we are the last list rendered.
                     */
                    const shouldDisplayAfterItemsInVerticalMode =
                        afterItems && display.mode === 'vertical' && draggable.listOfItems.length - 1 === index;

                    /**
                     * If we are in horizontal mode, items that should be displayed after the original
                     * list of items are just displayed as the last item of the last list. To figure out if we need to display
                     * them, we check if the last list has a length that is equal or less than the horizontal size.
                     * If it is less, then we add the after items as another item in the list.
                     *
                     * If it is not the case, meaning that the last list has reached the limit of total items
                     * to be displayed, then we need to create a new list and display the after items there.
                     */
                    const shouldDisplayAfterItemsInHorizontalModeAsAnItem =
                        afterItems &&
                        display.mode === 'horizontal' &&
                        draggable.listOfItems.length - 1 === index &&
                        draggable.listOfItems[index].length < draggable.horizontalSize;

                    const shouldDisplayAfterItemsInHorizontalModeAsAList =
                        afterItems &&
                        display.mode === 'horizontal' &&
                        draggable.listOfItems.length - 1 === index &&
                        draggable.listOfItems[index].length === draggable.horizontalSize;

                    return (
                        <React.Fragment key={draggable.listIds[index]}>
                            <Droppable
                                droppableId={draggable.listIds[index]}
                                direction={display.mode === 'horizontal' ? 'horizontal' : undefined}
                            >
                                {(dropProvided: any) => (
                                    <ol
                                        ref={dropProvided.innerRef}
                                        className={block({ horizontal: display.mode === 'horizontal' }, [className])}
                                    >
                                        {list.map((item: any, listIndex) => {
                                            const itemKey = getKeyForItem(item, keyPropName);
                                            const isDragDisabled =
                                                draggable.itemsToUse.length === 1 || isDisabled || item.isDragDisabled;

                                            return (
                                                <Draggable
                                                    key={itemKey}
                                                    draggableId={itemKey}
                                                    isDragDisabled={isDragDisabled}
                                                    index={listIndex}
                                                >
                                                    {(dragProvided: any) => {
                                                        return (
                                                            <li
                                                                className={element('item')}
                                                                ref={dragProvided.innerRef}
                                                                {...dragProvided.draggableProps}
                                                            >
                                                                {itemRenderer(
                                                                    revertUniqueId(item, keyPropName),
                                                                    dragProvided.dragHandleProps,
                                                                    listIndex +
                                                                        index *
                                                                            (display.mode === 'horizontal'
                                                                                ? draggable.horizontalSize
                                                                                : 0),
                                                                )}
                                                            </li>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}

                                        {dropProvided.placeholder}

                                        {shouldDisplayAfterItemsInVerticalMode ? (
                                            <li className={element('item')}>{afterItems}</li>
                                        ) : null}

                                        {shouldDisplayAfterItemsInHorizontalModeAsAnItem ? (
                                            <li className={element('item')}>{afterItems}</li>
                                        ) : null}
                                    </ol>
                                )}
                            </Droppable>

                            {shouldDisplayAfterItemsInHorizontalModeAsAList ? (
                                <ol className={block({ horizontal: display.mode === 'horizontal' }, [className])}>
                                    <li className={element('item')}>{afterItems}</li>
                                </ol>
                            ) : null}
                        </React.Fragment>
                    );
                })}
            </div>
        </DragDropContext>
    );
};
