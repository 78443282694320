import get from 'lodash/get';

import { GDDocument } from '@lumapps/google-drive-pickers/types';
import { MediaSourceV2, MediaStatus } from '@lumapps/medias/types';
import { FILE_TYPES_ICONS, getMediaIcon } from '@lumapps/medias/utils';
import { OneDriveDocument } from '@lumapps/one-drive-pickers/types';
import { first } from '@lumapps/utils/iterable/first';
import { Editor, NodeEntry, Point, Range, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';
import { isSelectionEmpty } from '@lumapps/wrex/slate/utils/isSelectionEmpty';

import { FileEditor, WrexDriveMediaSource } from '../types';
import { createFile } from './createFile';
import { isFile } from './isFile';

/**
 * Insert drive file after editor selection.
 * Returns the final insert position of the node. Useful for targeting the inserted node.
 */
export function insertDriveFile(
    editor: ReactEditor & FileEditor,
    file: GDDocument | OneDriveDocument,
    provider?: WrexDriveMediaSource,
    point?: Point | undefined,
) {
    let node;
    const at = point || editor.selection?.focus;
    const after = getSiblingPath([get(at, ['path', 0]) as number], 'after');
    const canInsertOnSelection = at && isSelectionEmpty(editor, at.path);

    if (provider === MediaSourceV2.GOOGLE_DRIVE) {
        const typedFile = file as GDDocument;
        const isoDate = new Date(typedFile.lastEditedUtc).toISOString();

        node = createFile({
            src: typedFile.url,
            name: typedFile.name,
            mimeType: typedFile?.mimeType,
            createdAt: isoDate,
            editedAt: isoDate,
            status: MediaStatus.LIVE,
            // On insertion, we don't have google drive thumbnail
            isLoading: false,
            resource: {
                id: typedFile.id,
                type: MediaSourceV2.GOOGLE_DRIVE,
            },
        });
    } else {
        const typedFile = file as OneDriveDocument;

        node = createFile({
            src: typedFile.webUrl,
            name: typedFile.name,
            mimeType: typedFile.file?.mimeType as string,
            createdAt: typedFile.createdDateTime,
            editedAt: typedFile.lastModifiedDateTime,
            status: MediaStatus.LIVE,
            thumbnailUrl:
                getMediaIcon({ mimeType: typedFile.file?.mimeType }) === FILE_TYPES_ICONS.photo
                    ? typedFile.webUrl
                    : undefined,
            isLoading: false,
            resource: {
                id: typedFile.id,
                type: MediaSourceV2.MICROSOFT,
                driveId: typedFile.parentReference.driveId,
            },
        });
    }

    Transforms.insertNodes(editor, node, {
        at: canInsertOnSelection ? [at.path[0]] : after ?? [editor.children.length],
        select: true,
    });

    const [, firstPath] = first(Editor.nodes(editor, { at: editor.selection as Range, match: isFile })) as NodeEntry;

    const path = canInsertOnSelection ? [at.path[0]] : firstPath;

    focusAt(editor, path);

    return path;
}
