import React from 'react';

import { classnames, padding } from '@lumapps/classnames';
import { UserPickerField } from '@lumapps/user-pickers/components/UserPickerField';
import type { User } from '@lumapps/user/types';

import { UseSingleSelectFilter } from '../../hooks/useSingleSelectFilter';
import { Filter } from '../Filter';
import { FilterProps } from '../Filter/types';

export interface UserFilterProps extends FilterProps {
    /** onSelected callback, generated from the `useListFilter` hook */
    onSelected: UseSingleSelectFilter<User>['onSelected'];
    /** isInSelection callback, generated from the `useListFilter` hook */
    selection: UseSingleSelectFilter<User>['selection'];
}

const CLASSNAME = 'user-filter';
/**
 * Component that generates a filter by a single user, used on the `Filters` pattern
 * @family Filters
 * @param UserFilterProps
 * @returns UserFilter
 */
export const UserFilter: React.FC<UserFilterProps> = ({ selection, onSelected, ...props }) => {
    return (
        <Filter {...props}>
            <UserPickerField
                selectionType="single"
                icon={null}
                aria-label={props.title}
                onChange={onSelected}
                value={selection}
                className={classnames(padding('all', null), CLASSNAME)}
            />
        </Filter>
    );
};
