import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menubar } from '@lumapps/lumx-menu/components/Menubar';
import { mdiDotsHorizontal } from '@lumapps/lumx/icons';
import { ThemeProvider } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { ToolbarItem as ToolbarItemType } from '../../types';
import { ToolbarItem } from '../Toolbar/ToolbarItem';

import './index.scss';

interface ElementToolbarProps {
    className?: string;
    toolbarOptions: ToolbarItemType[];
    staticOptions?: ToolbarItemType[];
    toolbarAriaLabel: string;
    dataScope: string;
    currentBreakpoint: 'reduced' | 'full';
    menuBarRef?: React.RefObject<HTMLDivElement>;
}

const CLASSNAME = 'wrex-element-toolbar';

export const ElementToolbar = ({
    className,
    toolbarOptions,
    staticOptions,
    toolbarAriaLabel,
    dataScope,
    currentBreakpoint,
    menuBarRef,
}: ElementToolbarProps) => {
    const { get: getDataAttributes } = useDataAttributes(dataScope);
    const { translateKey } = useTranslate();

    const { block, element } = useClassnames(CLASSNAME);

    let visibleItems = toolbarOptions;
    let overflowItems: ToolbarItemType[] = [];

    if (currentBreakpoint === 'reduced') {
        overflowItems = toolbarOptions;
        visibleItems = [];
    }

    return (
        <div
            className={block(
                {
                    'has-static-options': !!staticOptions && staticOptions?.length > 0,
                },
                [className],
            )}
            contentEditable={false}
            ref={menuBarRef}
        >
            <ThemeProvider value={undefined}>
                <Menubar ariaLabel={toolbarAriaLabel}>
                    {visibleItems.map((item) => (
                        <ToolbarItem
                            {...item}
                            otherProps={{
                                ...item.otherProps,
                            }}
                            key={`toolbar-item-${item.itemKey}`}
                        />
                    ))}

                    {overflowItems.length > 0 && (
                        <Menubar.ItemSubmenu
                            icon={mdiDotsHorizontal}
                            tooltipLabel={translateKey(GLOBAL.MORE)}
                            aria-label={translateKey(GLOBAL.MORE)}
                            {...getDataAttributes({ element: 'button', action: 'more-button' })}
                            triggerProps={{
                                isRightIconVisible: false,
                            }}
                            className={element('submenu')}
                        >
                            {overflowItems.map((item) => {
                                return <ToolbarItem {...item} inVerticalMode key={`toolbar-item-${item.itemKey}`} />;
                            })}
                        </Menubar.ItemSubmenu>
                    )}
                    {staticOptions?.map((item) => (
                        <ToolbarItem
                            {...item}
                            otherProps={{
                                ...item.otherProps,
                            }}
                            key={`toolbar-item-${item.itemKey}`}
                        />
                    ))}
                </Menubar>
            </ThemeProvider>
        </div>
    );
};
