/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { directoryEntriesRoute } from '@lumapps/directories-entries/routes';
import { adminDirectoryTags } from '@lumapps/directories-tags/routes';
import { DEFAULT_DIRECTORY_MDI_ICON } from '@lumapps/directories/constants';
import { DIRECTORIES } from '@lumapps/directories/keys';
import { Directory } from '@lumapps/directories/types';
import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { DIRECTORIES_ADMIN } from '../../keys';
import { directoriesRoute } from '../../routes';

export interface DirectoriesSideNavigationProps extends Partial<SideNavigationItemProps> {
    /** the list of directories to display */
    directories?: Directory[] | undefined;
    /** True if the current user is super admin */
    isSuperAdmin: boolean;
    /** True if the current user has access to the directories feature or not */
    isAllowed: boolean;
    /** whether to display the side navigation grouped in a menu or not */
    grouped?: boolean;
}

const DirectoriesSideNavigation = (props: DirectoriesSideNavigationProps) => {
    const { directories = [], isSuperAdmin = false, isAllowed = false, grouped = false } = props;
    const { translateKey, translateObject } = useTranslate();

    /** If there are no directories, we will render a blank section */
    if (directories.length === 0) {
        return null;
    }

    /**
     * If the user is superAdmin, we will render all directories and allow the user
     * to edit the content as well as the tags for the directory
     */
    if (isSuperAdmin) {
        const Directories = directories.map((directory: Directory) => (
            <SideNavigationItem
                label={translateObject(directory.name) || ''}
                key={directory.id}
                id="directories"
                {...(directory.properties.icon
                    ? {
                          dynamicIcon: !grouped ? directory.properties.icon : undefined,
                      }
                    : {
                          icon: !grouped ? DEFAULT_DIRECTORY_MDI_ICON : undefined,
                      })}
            >
                <SideNavigationItem
                    label={translateKey(DIRECTORIES.CONTENTS)}
                    route={directoryEntriesRoute(directory.id)}
                />
                <SideNavigationItem label={translateKey(GLOBAL.TAGS)} route={adminDirectoryTags(directory.id)} />
            </SideNavigationItem>
        ));

        if (grouped) {
            return (
                <SideNavigationItem
                    id="directories-menu"
                    label={translateKey(GLOBAL.DIRECTORIES)}
                    icon={DEFAULT_DIRECTORY_MDI_ICON}
                >
                    <SideNavigationItem
                        id="all-directories"
                        label={translateKey(DIRECTORIES_ADMIN.ALL_DIRECTORIES)}
                        route={directoriesRoute}
                    />
                    {Directories}
                </SideNavigationItem>
            );
        }

        return <>{Directories}</>;
    }

    /** Finally, if the user has access to the directories, we just let them see the content section */
    const Directories = directories.map((directory: Directory) => (
        <SideNavigationItem
            label={translateObject(directory.name) || ''}
            key={directory.id}
            {...(directory.properties.icon
                ? {
                      dynamicIcon: !grouped ? directory.properties.icon : undefined,
                  }
                : {
                      icon: !grouped ? DEFAULT_DIRECTORY_MDI_ICON : undefined,
                  })}
            route={directoryEntriesRoute(directory.id)}
        />
    ));

    if (isAllowed) {
        if (grouped) {
            return (
                <SideNavigationItem
                    id="directories"
                    label={translateKey(GLOBAL.DIRECTORIES)}
                    icon={DEFAULT_DIRECTORY_MDI_ICON}
                >
                    {Directories}
                </SideNavigationItem>
            );
        }

        return <>{Directories}</>;
    }

    return null;
};

DirectoriesSideNavigation.displayName = 'SideNavigationItem';

export { DirectoriesSideNavigation };
