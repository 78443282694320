import React from 'react';

import { classnames, margin } from '@lumapps/classnames';
import {
    ColorPalette,
    ColorVariant,
    GenericProps,
    Heading,
    Icon,
    IconProps,
    Theme,
    Typography,
    useTheme,
    HeadingElement,
} from '@lumapps/lumx/react';

import './index.scss';

const CLASSNAME = 'comments-section-title';

export interface CommentsSectionTitleProps extends GenericProps {
    icon?: IconProps;
    titleAs?: HeadingElement | 'p';
}

export const CommentsSectionTitle = ({ children, icon, titleAs }: CommentsSectionTitleProps) => {
    const theme = useTheme();
    return (
        <Heading
            as={titleAs as HeadingElement}
            className={classnames(CLASSNAME, margin('bottom', 'big'))}
            typography={Typography.overline}
            colorVariant={ColorVariant.L2}
            color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
        >
            {icon && <Icon {...icon} />}
            {children}
        </Heading>
    );
};
