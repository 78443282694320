import { SpaceServices } from '@lumapps/spaces/types';

/** List of all the static entries of the space navigation v1 */
export const SPACE_NAVIGATION_STATIC_ITEMS = [
    SpaceServices.homepage,
    SpaceServices.feed,
    SpaceServices.posts,
    SpaceServices.article,
    SpaceServices.event,
    SpaceServices.learning,
    SpaceServices.documents,
    SpaceServices.drive,
    SpaceServices.calendar,
];
