import { Route, createPageRoute } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const featuresManagementRoute: Route = createPageRoute({
    slug: 'platform/features-management',
    legacyId: 'app.admin.features-management',
    appId: AppId.backOffice,
});

export const featuresManagement = (): Route => featuresManagementRoute;
