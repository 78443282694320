import property from 'lodash/fp/property';

import { PostListFilterBlockProps } from '../../types';

export function hasAtLeastXChoices<T>(filterProps: PostListFilterBlockProps<T>, numberOfChoices = 1) {
    if (property('isLoading', filterProps)) {
        return true;
    }
    const filterChoicesCount = property(['choices', 'length'], filterProps);

    return Boolean(filterChoicesCount) && filterChoicesCount >= numberOfChoices;
}
