import { MediaSourceV2, MediaStatus } from '@lumapps/medias/types';
import { getMediaIcon, FILE_TYPES_ICONS } from '@lumapps/medias/utils';
import { OneDriveDocument } from '@lumapps/one-drive-pickers/types';

import { FileElement } from '../types';
import { createFile } from './createFile';

export const mapOneDriveFileToSlateFile = (files: OneDriveDocument[]): FileElement[] => {
    return files.map((file) => {
        const errorCode = (file as any).error?.code;
        if (errorCode) {
            const oldFile = (file as any).error?.backupFile as FileElement;

            return createFile({
                ...oldFile,
                status: errorCode === 'itemNotFound' ? MediaStatus.DELETED : MediaStatus.LIVE,
                isLoading: false,
            });
        }

        return createFile({
            src: file.webUrl,
            name: file.name,
            mimeType: file.file?.mimeType as string,
            createdAt: file.createdDateTime,
            editedAt: file.lastModifiedDateTime,
            status: MediaStatus.LIVE,
            isLoading: false,
            thumbnailUrl:
                getMediaIcon({ mimeType: file.file?.mimeType }) === FILE_TYPES_ICONS.photo ? file.webUrl : undefined,
            resource: {
                id: file.id,
                type: MediaSourceV2.MICROSOFT,
                driveId: file.parentReference.driveId,
            },
        });
    });
};
