import { CommunityView } from '@lumapps/communities/constants';
import { COMMUNITIES } from '@lumapps/communities/keys';
import { COMMUNITY_EVENTS } from '@lumapps/community-events/keys';
import { mdiCalendar, mdiFolderGoogleDrive, mdiHome, mdiMessageTextOutline } from '@lumapps/lumx/icons';
import { GLOBAL } from '@lumapps/translations';

import { StaticNavigationItem } from './types';

export const MAX_DEPTH = 2;
export const MAX_WIDTH = 50;
export const WIDTH_WARNING = 10;

/**
 * List of all the Community pages available in the navigation.
 * Order is important, entries will be displayed in that order of priority.
 * */
export const COMMUNITY_SERVICE_RECORD: Record<
    Exclude<keyof typeof CommunityView, 'home' | 'post'>,
    Omit<StaticNavigationItem<CommunityView>, 'to'>
> = {
    [CommunityView.dashboard]: {
        view: CommunityView.dashboard,
        label: COMMUNITIES.DASHBOARD,
        icon: mdiHome,
    },
    [CommunityView.posts]: {
        view: CommunityView.posts,
        label: GLOBAL.POSTS,
        icon: mdiMessageTextOutline,
    },
    [CommunityView.events]: {
        view: CommunityView.events,
        label: COMMUNITY_EVENTS.EVENTS_PAGE_NAVIGATION_BUTTON,
        icon: mdiCalendar,
    },
    [CommunityView.media]: {
        view: CommunityView.media,
        label: COMMUNITIES.MEDIA,
        icon: mdiFolderGoogleDrive,
    },
    [CommunityView.calendar]: {
        view: CommunityView.calendar,
        label: COMMUNITIES.CALENDAR,
        icon: mdiCalendar,
    },
};
