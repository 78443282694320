import { GDDocument } from '@lumapps/google-drive-pickers/types';
import { OneDriveDocument } from '@lumapps/one-drive-pickers/types';
import { ToolbarItem } from '@lumapps/wrex/types';

import { MediaPickerParams, Media, MediaSourceV2Drive } from '../types';
import { useMediaPickerOptions } from './useMediaPickerOptions';
import { useMediaPickerOptionsV2 } from './useMediaPickerOptionsV2';

interface UseMediaPickerDropdownProps {
    /** Files that will already be selected on the media picker */
    currentFiles?: Media[];
    /** Whether Drive is allowed in the media picker. */
    isDriveAllowed?: boolean;
    // Whether we are in a community context or not.
    isInCommunityContext?: boolean;
    /** Whether Lumdrive is allowed in the media picker. */
    isLumdriveAllowed?: boolean;
    /** Params of the media picker */
    mediaPickerParams?: MediaPickerParams;
    /** On media change callback */
    onMediaChange?(medias: Media[] | Media): void;
    /** On upload from computer callback. */
    onUploadFromComputer(): void;
    /** Props passed to the submenu item */
    subMenuProps: Omit<ToolbarItem, 'type' | 'childrenOptions'>;
    /** Whether we use picker options v2 in structured content */
    isPickerV2?: boolean;
    /** Callback for select files */
    onFilesSelected?: (file: GDDocument[] | OneDriveDocument[], provider: MediaSourceV2Drive) => void;
    /** Whether is maximum number of files has been added */
    isMaxDriveFiles?: boolean;
}

/**
 * Media picker hook, displaying each media picker in a dropdown list item
 */
export const useMediaPickerDropdown = ({
    currentFiles,
    isDriveAllowed,
    isInCommunityContext,
    isLumdriveAllowed,
    mediaPickerParams,
    onMediaChange,
    onUploadFromComputer,
    subMenuProps,
    isPickerV2,
    onFilesSelected,
    isMaxDriveFiles,
}: UseMediaPickerDropdownProps) => {
    const mediaPickerOptions = useMediaPickerOptions({
        currentFiles,
        isDriveAllowed,
        isInCommunityContext,
        isLumdriveAllowed,
        mediaPickerParams,
        onMediaChange,
        onUploadFromComputer,
    });

    const { options: mediaPickerOptionsV2, OneDrivePicker } = useMediaPickerOptionsV2({
        isDriveAllowed,
        onUploadFromComputer,
        onFilesSelected,
        isMaxDriveFiles,
    });

    const mediaPickerDropdownProps: ToolbarItem = {
        type: 'submenu',
        childrenOptions: isPickerV2 ? mediaPickerOptionsV2 : mediaPickerOptions,
        ...subMenuProps,
    };

    return {
        oneDrivePicker: OneDrivePicker,
        mediaPickerDropdownProps:
            mediaPickerOptions.length > 1 || mediaPickerOptionsV2.length > 1 ? mediaPickerDropdownProps : undefined,
    };
};
