import { MediaSourceV2 } from '@lumapps/medias/types';

import { FileElement, WrexDriveMediaSource } from '../types';
import { isFile } from './isFile';

/**
 * Check if the given node is a drive file
 * A specific drive to check can be provided
 */
export const isDriveFile = (node?: any, driveProvider?: WrexDriveMediaSource): node is FileElement => {
    if (!isFile(node)) {
        return false;
    }
    if (driveProvider) {
        return node.resource?.type === driveProvider;
    }
    return node.resource?.type === MediaSourceV2.GOOGLE_DRIVE || node.resource?.type === MediaSourceV2.MICROSOFT;
};
