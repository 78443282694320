import React, { Children, ReactElement, ReactNode, useMemo } from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { ImageLightboxProvider } from '@lumapps/lumx-images/components/ImageLightboxProvider';
import { PaginatedGridColumns } from '@lumapps/lumx-layouts/components/PaginatedGridColumns';
import { WIDGET_COMMUNITY_LIST_TYPE } from '@lumapps/widget-community-list/constants';

import { BlockComponent, BlockGrid as BlockGridType, ContainerBlockVariant, WidgetLoadingState } from '../../../types';
import { getBlockMinWidth } from '../../../utils/getBlockMinWidth';
import {
    CLASSNAME,
    DEFAULT_COLUMNS,
    GRID_ITEM_UNGROUPED_DEFAULT_PADDING,
    GRID_LOADING_STATUS_MAPPING,
} from './constants';

import './index.scss';

/** Grid block. */
export interface BlockGridProps extends BlockGridType {
    children?: ReactNode;
    loadingState: WidgetLoadingState;
    onLoadMore?(): void;
    /** The component that will be used as a loader when the list loads more items. Default: <Progress /> */
    loadingStateRenderer?: React.FC;
    /** Item min width to adapt the number of visible items to the available space */
    itemMinWidth?: number;
}

export const BlockGrid: BlockComponent<BlockGridProps> = ({
    children,
    variant = ContainerBlockVariant.grouped,
    columns = DEFAULT_COLUMNS,
    contentStyles,
    onLoadMore,
    loadingState,
    loadingStateRenderer: LoadingStateRenderer,
    itemMinWidth,
    maxItemsPerPage,
}) => {
    const { element, block } = useClassnames(CLASSNAME);

    /** Getting childType from Children, assuming that all children are sharing the same type */
    const blockType = (Children.toArray(children)[0] as ReactElement)?.props?.type;
    const widget = (Children.toArray(children)[0] as ReactElement)?.props?.widget;

    /** For the community list, ungrouped variant, we need to implement a style exception to remove the padding of each items */
    const shouldItemHaveNoPadding =
        widget?.widgetType === WIDGET_COMMUNITY_LIST_TYPE && variant === ContainerBlockVariant.ungrouped;

    /**
     * We want to adjust the grid item min-width for each BlockType.
     * It will define when to reduce the number of column.
     * Take into account the innerSpacing by default or set by the user.
     * */
    const minWidth = useMemo(() => {
        return getBlockMinWidth({
            itemMinWidth,
            blockType,
            variant,
            contentStyles,
            defaultPadding: GRID_ITEM_UNGROUPED_DEFAULT_PADDING,
        });
    }, [blockType, contentStyles, itemMinWidth, variant]);

    const gridLoadingState = (GRID_LOADING_STATUS_MAPPING as any)[loadingState];

    return (
        <ImageLightboxProvider>
            <PaginatedGridColumns
                className={block({
                    grouped: variant === ContainerBlockVariant.grouped,
                    ungrouped: variant === ContainerBlockVariant.ungrouped,
                })}
                itemMinWidth={minWidth}
                maxItemsPerPage={maxItemsPerPage}
                maxColumns={columns}
                onLoadMore={onLoadMore}
                loadingStatus={gridLoadingState}
                renderLoading={LoadingStateRenderer}
                loadMoreButtonProps={{ className: margin('top', 'huge') }}
            >
                {React.Children.map(children, (child) => {
                    if (!React.isValidElement(child)) {
                        return child;
                    }
                    return (
                        <div
                            key={child.key}
                            className={element('item', { 'no-padding': shouldItemHaveNoPadding })}
                            style={variant === ContainerBlockVariant.ungrouped ? contentStyles : undefined}
                        >
                            {child}
                        </div>
                    );
                })}
            </PaginatedGridColumns>
        </ImageLightboxProvider>
    );
};
BlockGrid.displayName = 'BlockGrid';
