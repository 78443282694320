(function IIFE() {
    'use strict';

    /////////////////////////////

    function CommentButtonController(Content, InitialSettings, Instance, Post) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        vm.link = (angular.isUndefined(vm.link)) ? false : vm.link;
        vm.target = (angular.isUndefined(vm.target)) ? '_self' : vm.target;

        /////////////////////////////

        /**
        * Services and utilities.
        */
        vm.Content = Content;
        vm.Instance = Instance;


        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.Service = (vm.content.type === InitialSettings.CONTENT_TYPES.POST) ? Post : Content;
        }

        init();
    }

    function CommentButtonDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: CommentButtonController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                content: '<',
                isExtended: '<?',
                link: '<?',
                target: '<?',
                theme: '@?',
            },
            templateUrl: '/client/front-office/modules/social/views/comment-button.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('commentButton', CommentButtonDirective);
})();
