import React from 'react';

import { toMarkdown } from '@lumapps/wrex/serialization/markdown/toMarkdown';
import { ReactEditor, useSlate, useSlateSelection } from '@lumapps/wrex/slate';
import { getCurrentRange } from '@lumapps/wrex/slate/utils/getCurrentRange';
import { Nodes } from '@lumapps/wrex/types/core';

import { useRefineContentDialog } from '../../hooks/useRefineContentDialog';
import { RefineContentDialog } from '../RefineContentDialog';
import { RefineContentMenu } from '../RefineContentMenu';

export const RefineContentButton = () => {
    const {
        newSuggestions,
        isRefiningContent,
        isPopoverOpen,
        onRefineContent,
        onInsertNewContent,
        onCancelRefinement,
        setNewSuggestions,
        setIsPopoverOpen,
        setInitialContent,
    } = useRefineContentDialog();

    const editor = useSlate() as ReactEditor;
    const slateSelection = useSlateSelection();
    const domSelection = window.getSelection();

    const buttonRef = React.useRef(null);
    const [currentRange, setCurrentRange] = React.useState<Range | undefined>(undefined);

    const selectedNodesInMarkdown = React.useMemo(() => {
        if (slateSelection) {
            return toMarkdown(editor.getFragment() as Nodes);
        }
        return undefined;
    }, [editor, slateSelection]);

    // Set new range on selection change
    React.useEffect(() => {
        setCurrentRange(getCurrentRange(editor, slateSelection, domSelection));
    }, [slateSelection, domSelection, editor]);

    const onClose = React.useCallback(() => setIsPopoverOpen(false), [setIsPopoverOpen]);

    // If a new text is generated and the user clicks away, force the popover to close and delete generated texts
    React.useEffect(() => {
        setNewSuggestions([]);
        setInitialContent(undefined);
        onClose();
    }, [currentRange, setNewSuggestions, onClose, setInitialContent]);

    return currentRange ? (
        <>
            <RefineContentMenu
                buttonRef={buttonRef}
                selectedText={selectedNodesInMarkdown}
                onRefineContent={onRefineContent}
            />

            {isPopoverOpen && (
                <RefineContentDialog
                    isRefiningContent={isRefiningContent}
                    hasCurrentRange={Boolean(currentRange)}
                    anchorRef={buttonRef}
                    newSuggestions={newSuggestions}
                    slateSelection={slateSelection}
                    onClose={onClose}
                    onCancelRefinement={onCancelRefinement}
                    onInsertNewContent={onInsertNewContent}
                    onRefineContent={onRefineContent}
                    setNewSuggestions={setNewSuggestions}
                />
            )}
        </>
    ) : null;
};
