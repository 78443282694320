import mdast from 'mdast';

import { SerializeOptions } from '@lumapps/wrex/serialization/markdown/toMarkdown/types';

import { CODE_BLOCK, INLINE_CODE, PARAGRAPH } from '../../constants';

export const TYPOGRAPHY_MARKS: SerializeOptions['marks'] = {
    bold: (children) => ({ type: 'strong', children }) as mdast.Strong,
    italic: (children) => ({ type: 'emphasis', children }) as mdast.Emphasis,
    strikethrough: (children) => ({ type: 'delete', children }) as mdast.Delete,
};

export const TYPOGRAPHY_ELEMENTS: SerializeOptions['elements'] = {
    headline: (_, children) => ({ type: 'heading', depth: 1, children }) as mdast.Heading,
    title: (_, children) => ({ type: 'heading', depth: 2, children }) as mdast.Heading,
    [PARAGRAPH]: (_, children) => ({ type: 'paragraph', children }) as mdast.Paragraph,
    [CODE_BLOCK]: (_, children) => {
        const value = children
            .map((node) => node.value)
            .filter(Boolean)
            .join('');
        return { type: 'code', value } as mdast.Code;
    },
    [INLINE_CODE]: (_, children) => {
        const value = children
            .map((node) => node.value)
            .filter(Boolean)
            .join('');
        return { type: 'inlineCode', value } as mdast.InlineCode;
    },
};
