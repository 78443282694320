import React from 'react';

import moment from 'moment';

import { useClassnames } from '@lumapps/classnames';
import { mdiShare } from '@lumapps/lumx/icons';
import { Placement, Theme, Typography } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { UserLink, UserLinkProps } from '@lumapps/user/components/UserLink';
import { connectedUserSelector } from '@lumapps/user/ducks/selectors';
import { BlockContextBanner } from '@lumapps/widget-base/components/Block/BlockContextBanner';

import { BLOCK_POST_CLASSNAME } from '../../../constants';
import { WIDGET_POST_LIST } from '../../../keys';

interface SharedByUserProps {
    /** avatar to display */
    userAvatar?: string;
    /** id of the user */
    userId: string;
    /** FullName of the user */
    fullName: string;
    /** Date the publication has been shared  */
    sharedAt?: string;
    /** Optional community destination of the share */
    sharedIn?: JSX.Element;
    /** Theme to display */
    theme?: Theme;
}

/** Component to display the user who shared a publication */
export const SharedByUser: React.FC<SharedByUserProps> = ({
    userAvatar,
    userId,
    fullName,
    sharedAt,
    sharedIn,
    theme = Theme.light,
}) => {
    const { translateAndReplaceWithComponents, translateKey } = useTranslate();
    const { element } = useClassnames(BLOCK_POST_CLASSNAME);
    const currentUser = useSelector(connectedUserSelector);
    const sharer: UserLinkProps['user'] = {
        id: userId as string,
        fullName: fullName as string,
    };

    const SharerUserLink = (
        <UserLink
            user={sharer}
            className={element('shared-by-user')}
            userCardPopoverProps={{
                showFollow: true,
                customUserAvatar: userAvatar,
                placement: Placement.BOTTOM_START,
            }}
            key={sharer.fullName}
            color={theme === Theme.dark ? Theme.light : Theme.dark}
            colorVariant="N"
            typography={Typography.subtitle1}
        >
            {sharer.fullName}
        </UserLink>
    );

    const durationSinceShare = sharedAt ? moment.utc(sharedAt).local().fromNow() : '';

    const sharePostText = (
        <>
            {/* Current user, no location */}
            {currentUser?.id === sharer.id && !sharedIn && translateKey(WIDGET_POST_LIST.YOU_SHARED_A_POST)}

            {/* Current user, with location */}
            {currentUser?.id === sharer.id &&
                sharedIn &&
                translateAndReplaceWithComponents(WIDGET_POST_LIST.YOU_SHARED_A_POST_IN_LOCATION, {
                    LOCATION: sharedIn,
                })}

            {/* Other user, no location */}
            {currentUser?.id !== sharer.id &&
                !sharedIn &&
                translateAndReplaceWithComponents(WIDGET_POST_LIST.USER_SHARED_A_POST, {
                    USER: SharerUserLink,
                })}

            {/* Other user, with location */}
            {currentUser?.id !== sharer.id &&
                sharedIn &&
                translateAndReplaceWithComponents(WIDGET_POST_LIST.USER_SHARED_A_POST_IN_LOCATION, {
                    USER: SharerUserLink,
                    LOCATION: sharedIn,
                })}
        </>
    );

    return (
        <BlockContextBanner
            icon={mdiShare}
            pictureUrl={userAvatar}
            contextBannerText={sharePostText}
            actionDate={durationSinceShare}
            theme={theme}
        />
    );
};
