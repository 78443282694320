import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiDatabaseRefresh } from '@lumapps/lumx/icons';
import { PROVISIONING_DASHBOARD } from '@lumapps/providers-dashboard-admin/keys';
import { provisioningDashboardRoute } from '@lumapps/providers-dashboard-admin/routes';
import { useTranslate } from '@lumapps/translations';

import { PROVIDERS } from '../../keys';
import { identityProviderRoute } from '../../routes';

export interface ProvidersSideNavigationProps {
    isAllowed: boolean;
}

const ProvidersSideNavigation = ({ isAllowed }: ProvidersSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(PROVIDERS.PROVIDER)} icon={mdiDatabaseRefresh} id="providers">
            <SideNavigationItem
                label={translateKey(PROVISIONING_DASHBOARD.DASHBOARD)}
                route={provisioningDashboardRoute}
            />
            <SideNavigationItem label={translateKey(PROVIDERS.IDP)} route={identityProviderRoute} />
        </SideNavigationItem>
    );
};

ProvidersSideNavigation.displayName = 'SideNavigationItem';

export { ProvidersSideNavigation };
