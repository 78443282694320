import React from 'react';

import { fetchLikeStatus, fetchLikeUserList, fetchToggleLike } from '@lumapps/reactions-core/api/v1';
import { LegacyLikeResource } from '@lumapps/reactions-core/api/v1/types';

import { GenericLikeButton, GenericLikeButtonProps } from '../GenericLikeButton';

export interface LegacyLikeButtonProps
    extends Omit<GenericLikeButtonProps, 'resourceRef' | 'fetchToggleLike' | 'fetchLikeUserList'>,
        LegacyLikeResource {}

/** Like button for legacy resources (content, post, etc.) */
export const LegacyLikeButton = React.forwardRef<HTMLButtonElement, LegacyLikeButtonProps>((props, ref) => {
    const { resourceType, resourceId, ...forwardedProps } = props;

    return (
        <GenericLikeButton
            ref={ref}
            resourceRef={{ resourceType, resourceId }}
            fetchToggleLike={fetchToggleLike}
            fetchLikeUserList={fetchLikeUserList}
            fetchLikeStatus={fetchLikeStatus}
            {...forwardedProps}
        />
    );
});
LegacyLikeButton.displayName = 'LegacyLikeButton';
