import { useMemo } from 'react';

import intersection from 'lodash/fp/intersection';

import type { SpaceServices, SpaceServicesProviders } from '@lumapps/spaces/types';

import { SPACE_NAVIGATION_STATIC_ITEMS } from '../constants';
import { servicesToNavigationItems } from '../utils';

export interface UseSpaceNavigationItemsProps {
    id: string;
    slug: string;
    enabledServices: SpaceServices[];
    providers: SpaceServicesProviders;
}

export const useSpaceNavigationItems = ({ id, slug, enabledServices, providers }: UseSpaceNavigationItemsProps) =>
    useMemo(() => {
        const staticServices = intersection(enabledServices, SPACE_NAVIGATION_STATIC_ITEMS);

        const staticItems = servicesToNavigationItems(id, slug, providers, staticServices);

        return { staticItems };
    }, [enabledServices, id, providers, slug]);
