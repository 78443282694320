import { useState } from 'react';

import { useQuery } from '@lumapps/base-api/react-query';
import { useDialogOpenState } from '@lumapps/utils/hooks/useDialogOpenState';

import { getCategories, learningCatalogQueryKeys } from '../api';

export const useLearningCatalogSelectField = (locale: string) => {
    /**
     * Deal with the open/close state of the select
     * */
    const [isOpen, setOpen] = useDialogOpenState();
    /**
     * Fetch the learning available catalogs
     * based on the user current language
     * */
    const {
        data = [],
        isLoading,
        ...useQueryResults
    } = useQuery({
        queryKey: learningCatalogQueryKeys(locale),
        queryFn: () => getCategories(locale),
        cacheTime: Infinity,
        staleTime: Infinity,
    });

    /**
     * Search field control
     * */
    const [searchText, onSearch] = useState('');

    return {
        selectFieldSimpleProps: {
            isOpen,
            setOpen,
            choices: data,
            isLoading,
            searchText,
            onSearch,
        },
        useQueryResults,
    };
};
