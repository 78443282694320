import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ColorPalette, SkeletonTypography, Text, Typography } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { PlayStatus, PublicationStatus, VideoAssetStatus } from '../../api/types';
import { PLAY } from '../../keys';
import './index.scss';

export type Status = PlayStatus | Exclude<VideoAssetStatus, 'ready'>;

export interface PlayStatusFlagProps {
    className?: string;
    status?: Status | PublicationStatus;
}

export const colorMap: Record<Status | PublicationStatus, ColorPalette> = {
    archived: 'dark',
    draft: 'yellow',
    encoding: 'yellow',
    error: 'red',
    published: 'green',
    unpublished: 'dark',
    expired: 'red',
    scheduled: 'green',
};

export const translationsMap: Record<Status | PublicationStatus, PLAY | GLOBAL> = {
    archived: PLAY.VIDEO_STATUS_ARCHIVED,
    draft: PLAY.VIDEO_STATUS_DRAFT,
    encoding: PLAY.VIDEO_STATUS_ENCODING,
    error: PLAY.VIDEO_STATUS_ERROR,
    published: PLAY.VIDEO_STATUS_PUBLISHED,
    unpublished: PLAY.VIDEO_STATUS_UNPUBLISHED,
    scheduled: GLOBAL.SCHEDULED,
    expired: PLAY.VIDEO_STATUS_EXPIRED,
};

export const CLASSNAME = 'video-status-flag';

export const PlayStatusFlag = ({ className, status }: PlayStatusFlagProps) => {
    const { block } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();
    const color = status ? colorMap[status] : 'dark';

    return status ? (
        <Text as="span" className={block(color, className)}>
            {translateKey(translationsMap[status])}
        </Text>
    ) : (
        <SkeletonTypography
            className={block({ skeleton: true, [color]: true }, className)}
            typography={Typography.body1}
        />
    );
};
