import React, { forwardRef } from 'react';

import { MovingFocusProvider } from '@lumapps/moving-focus';
import { ReactEditor, useSlateStatic, type Editable } from '@lumapps/wrex/slate';
import { EditableWrapper } from '@lumapps/wrex/slate/plugin';

import type { InlineAutocompleteEditor } from '../../../types';
import { InlineAutocompleteSearchContext } from '../context';

export type InlineAutocompleteEditableWrapper = (E: typeof Editable, name: string) => typeof Editable;

/**
 * HOC wrapper around the slate editable.
 * Handling virtual focus into the inline autocomplete list box (see InlineAutocompleteSearch)
 * */
export const InlineAutocompleteHOC: EditableWrapper = (Editable) => {
    const Component: typeof Editable = forwardRef(({ onKeyDown, ...props }, editableRef) => {
        const [highlightedItem, setHighlightedItem] = React.useState<any>();
        const [fetchHookOnItemSelected, setFetchHookOnItemSelected] = React.useState<any>();
        const highlightedItemRef = React.useRef(highlightedItem);
        React.useEffect(() => {
            highlightedItemRef.current = highlightedItem;
        }, [highlightedItem]);

        const editorStatic = useSlateStatic() as ReactEditor & InlineAutocompleteEditor;

        // Insert highlighted element on `Enter` key press
        const handleKeyDown = React.useCallback<React.KeyboardEventHandler>(
            (evt) => {
                const { current } = highlightedItemRef;
                if (current && evt.key === 'Enter') {
                    editorStatic.insertAutocompletedElement(current);
                    if (fetchHookOnItemSelected) {
                        fetchHookOnItemSelected(highlightedItem);
                    }
                    evt.preventDefault();
                    return;
                }
                onKeyDown?.(evt as any);
            },
            [onKeyDown, editorStatic, fetchHookOnItemSelected, highlightedItem],
        );
        const { getEntityId } = editorStatic.getCurrentSearchOption() || {};
        const value = React.useMemo<InlineAutocompleteSearchContext>(
            () => ({ setHighlightedItem, setFetchHookOnItemSelected }),
            [],
        );

        // Wrap editable in item search context and moving focus (virtual focus) context
        return (
            <InlineAutocompleteSearchContext.Provider value={value}>
                <MovingFocusProvider options={{ direction: 'vertical', loopAround: false, allowFocusing: true }}>
                    <Editable
                        {...props}
                        ref={editableRef}
                        aria-activedescendant={getEntityId?.(highlightedItem) || ''}
                        onKeyDown={handleKeyDown}
                    />
                </MovingFocusProvider>
            </InlineAutocompleteSearchContext.Provider>
        );
    });
    return Component;
};
