import { NGI_WIDGETS_IN_DESIGNER_FF_TOKEN } from '@lumapps/widget-base/constants';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { observeTargets, LAZY_LOADABLE_CLASS, LAZY_LOADED_CLASS } from './iframe-lazy-loading';

/////////////////////////////

function WidgetIframeController($sce, $scope, $window, Content, Document, Features, User, Utils, $timeout) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The default iframe height.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_IFRAME_HEIGHT = 500;

    /**
     * Check if iframe url is defined and filled.
     *
     * @type {boolean}
     */
    let _hasUrl;

    /**
     * Check if iframe url couldn't be retrieved.
     *
     * @type {boolean}
     */
    let _hasUrlError;

    /**
     * Url of current content.
     *
     * @type {string}
     */
    const _parentUrl = `parentUrl=${encodeURIComponent($window.location.href)}`;

    /**
     * The iframe url in current lang.
     *
     * @type {string}
     */
    let _url;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The iframe height.
     *
     * @type {number}
     */
    vm.iframeHeight = undefined;

    /**
     * The iframe url.
     *
     * @type {string}
     */
    vm.iframeUrl = undefined;

    /**
     * The iframe width.
     *
     * @type {string}
     */
    vm.iframeWidth = undefined;

    /**
     * Whether the widget should be displayed in NGI or not.
     */
    vm.isNGICompatible = Utils.isDesignerMode() && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2');


    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the iframe height.
     * Default to 500px.
     *
     * @param {Object} properties The widget properties.
     */
    function _getIframeHeight(properties) {
        if (angular.isDefinedAndFilled(properties.style.content.height)) {
            vm.iframeHeight = angular.isString(properties.style.content.height)
                ? parseInt(properties.style.content.height, 10)
                : properties.style.content.height;
            return;
        }

        // ParsInt is a backward compatibility with old width and height casted in string.
        if (angular.isDefinedAndFilled(properties.height)) {
            vm.iframeHeight = angular.isString(properties.height) ? parseInt(properties.height, 10) : properties.height;
        }
    }

    /**
     * Get iframe url.
     *
     * @return {Promise} Resolved with iframe url string.
     */
    async function _getUrl() {
        const properties = get(vm.widget, 'properties', {});

        if (!properties.docPath) {
            return vm.parentCtrl.getWidgetTranslation(properties.url);
        }
    }

    /**
     * Init the iframe url.
     * Add the url of the current page as parameter.
     *
     * @param {Object} properties The widget properties.
     */
    async function _initIframeUrl(properties) {
        if (angular.isDefinedAndFilled(properties.docPath)) {
            try {
                const { previewUrl } = await Document.fetchPreview(properties.docPath);

                vm.iframeUrl = $sce.trustAsResourceUrl(previewUrl);
                vm.dataSrc = vm.iframeUrl;
                vm.src = Utils.isDesignerMode() ? vm.iframeUrl : undefined;

                _hasUrlError = false;
            } catch (error) {
                if (error?.status && error?.status === 404) {
                    _hasUrlError = true;
                    return;
                }
            }

            return;
        }

        if (angular.isDefinedAndFilled(properties.embedUrl)) {
            vm.iframeUrl = $sce.trustAsResourceUrl(properties.embedUrl);
            vm.dataSrc = vm.iframeUrl;
            vm.src = Utils.isDesignerMode() ? vm.iframeUrl : undefined;

            return;
        }

        if (!_hasUrl || !angular.isDefinedAndFilled(_url)) {
            return;
        }

        let iframeUrl;
        let urlHash;
        let urlSplit;

        // CMS-1153 : force HTTP as protocol if not specified - Prevents DOM based XSS
        if (!_url.toLowerCase().startsWith('http')) {
            _url = 'http://' + _url;
        }

        // Remove hash in _url and set it back at the end.
        if (includes(_url, '#')) {
            urlHash = _url.split(/[#](.+)/);
            _url = get(urlHash, '0');
        }
        // Format params if it exists.
        if (includes(_url, '?')) {
            urlSplit = _url.split(/\?(.+)/);

            let replacedSplittedUrl = urlSplit[1];
            if (angular.isDefinedAndFilled(urlSplit[1]) && includes(urlSplit[1], 'user.')) {
                replacedSplittedUrl = await Utils.getUrlUserInjectionInformation(urlSplit[1], User.getConnected());
            }
            iframeUrl = `${urlSplit[0]}?${_parentUrl}&${replacedSplittedUrl}`;
        } else {
            iframeUrl = `${_url}?${_parentUrl}`;
        }

        iframeUrl += `&iframeId=${vm.widget.uuid}`;

        if (properties.sendUserIdentity) {
            iframeUrl += `&token=${encodeURIComponent(User.getToken())}`;
        }

        if (properties.sendContentId) {
            iframeUrl += `&contentId=${encodeURIComponent(Content.getCurrent().id)}`;
        }

        if (angular.isDefinedAndFilled(get(urlHash, '1'))) {
            iframeUrl += `#${get(urlHash, '1')}`;
        }

        vm.iframeUrl = $sce.trustAsResourceUrl(iframeUrl);
        vm.dataSrc = vm.iframeUrl;
        vm.src = Utils.isDesignerMode() ? vm.iframeUrl : undefined;
    }

    /**
     * Get the iframe width.
     * Defaults to 100%.
     *
     * @param {Object} properties The widget properties.
     */
    function _getIframeWidth(properties) {
        // ParsInt is a backward compatibility with old width and height casted in string.
        if (angular.isDefinedAndFilled(properties.width)) {
            vm.iframeWidth = angular.isString(properties.width) ? parseInt(properties.width, 10) : properties.width;
        }
    }

    /**
     * Handle any message event triggered by the child iframe.
     * It is used here to resize the widget to the size of the iframe.
     *
     * Note: this is used by VIGS (Veolia) for example.
     *
     * @param {Event} evt The message event triggering this method.
     */
    function _handleMessageEvent(evt) {
        const iframeId = get(evt, 'data.srcId');

        if (angular.isDefinedAndFilled(iframeId) && iframeId !== vm.widget.uuid) {
            return;
        }

        // AWTheight is a property sent by AwesomeTable only - but the auto-resizing is already handled by them.
        if (
            angular.isUndefinedOrEmpty(get(evt, 'data.height')) ||
            angular.isDefinedAndFilled(get(evt, 'data.AWTheight'))
        ) {
            return;
        }

        $scope.$apply(() => {
            vm.iframeHeight = `${evt.data.height}px`;
        });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get widget class.
     *
     * @return {Array} Widget classes.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);
        
        widgetClass.push('widget-editable');

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        const indexFoldedClass = widgetClass.indexOf('widget--is-folded');

        if (vm.embed && indexFoldedClass > -1) {
            widgetClass.splice(indexFoldedClass, 1);
        }

        return widgetClass;
    }

    /**
     * The widget is empty in designer mode if we don't have an url for the current language.
     * The widget is empty in read mode if we don't have any url.
     *
     * @return {boolean} Is widget empty.
     */
    function isWidgetEmpty() {
        if (Utils.isDesignerMode()) return _hasUrlError ? _hasUrl && angular.isUndefinedOrEmpty(_url) : !_hasUrl;

        return !_hasUrl && (Utils.isDesignerMode() || angular.isUndefinedOrEmpty(_url));
    }

    /**
     * The widget is never hidden in designer mode.
     * The widget is hidden in read mode if we don't have any url and any no result message.
     *
     * @return {boolean} Is widget hidden.
     */
    function isWidgetHidden() {
        let isHidden = false;

        if (
            !vm.parentCtrl.designerMode() &&
            !vm.widget.properties.noResults &&
            !_hasUrl &&
            angular.isUndefinedOrEmpty(_url)
        ) {
            vm.parentCtrl.isHidden = true;

            isHidden = true;
        }

        return isHidden;
    }

    function _shouldDisplayAsNGI() {
        return vm.isNGICompatible;
    }

    function setAsNonNgiCompatible() {
        vm.isNGICompatible = false;
    }

    function setAsNgiCompatible() {
        const isInDesignerContext = vm.parentCtrl.designerMode();
        // Prevent the switch to true if the required FF are not enabled.
        if(isInDesignerContext && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2')) {
            vm.isNGICompatible = true;
        }
    }


    /////////////////////////////

    vm.getWidgetClass = getWidgetClass;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;
    vm.shouldDisplayAsNGI = _shouldDisplayAsNGI;
    vm.setAsNonNgiCompatible = setAsNonNgiCompatible;
    vm.setAsNgiCompatible = setAsNgiCompatible;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Remove the event listener added during the init when the directive is destroyed.
     */
    $scope.$on('$destroy', () => $window.removeEventListener('message', _handleMessageEvent, false));

    /**
     * Watch widget setting update.
     */
    $scope.$on('widget-iframe-settings', (evt, widgetUuid) => {
        if (vm.widget.uuid === widgetUuid) {
            vm.init();
        }
    });

    $scope.$on('widget-resized', () => {
        vm.init();
    });

    $scope.$on('inputLanguage', () => {
        vm.init();
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    vm.init = async function init() {
        _hasUrlError = false;
        const properties = get(vm.widget, 'properties', {});
        _hasUrl = angular.isDefinedAndFilled(
            [properties.docPath, vm.parentCtrl.getWidgetTranslation(properties.url), properties.embedUrl],
            'some',
        );

        if (!_hasUrl) {
            return;
        }

        // Set a default value to the sendUserIdentity option.
        if (angular.isUndefinedOrEmpty(properties.sendUserIdentity)) {
            properties.sendUserIdentity = false;
        }

        vm.iframeHeight = _DEFAULT_IFRAME_HEIGHT;
        vm.iframeWidth = '100%';
        vm.iframeClass = `display-block ${Utils.isDesignerMode() ? LAZY_LOADED_CLASS : LAZY_LOADABLE_CLASS}`;

        _url = await _getUrl();

        _getIframeHeight(properties);

        _getIframeWidth(properties);

        await _initIframeUrl(properties);

        // Listen to message from child window (within the iframe).
        $window.addEventListener('message', _handleMessageEvent, false);

        $timeout(function timedOut() {
            observeTargets(Utils.isDesignerMode());
        }, 300);
    };

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl Parent controller.
     */
    // eslint-disable-next-line angular/controller-as-vm
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;

        const isInDesignerContext = vm.parentCtrl.designerMode();

        // If we are not in the designer context or the content is not v2 compatible
        // we fallback to legacy display (we consider unsaved content as v2 compatible).
        // Here we are trying to guess which display will be the correct one in view mode.
        // This is not perfect, but we want to avoid too much logic in the frontend to know if a content is
        // compatible v2 or not (widget types presents on the content + some specific settings on some widgets)
        if ((!isInDesignerContext || (Content.getAction() !== 'create' && Content.getCurrent()?.template.isV2Compatible === false))) {
            setAsNonNgiCompatible();
        }

        vm.init();
    };
}

/////////////////////////////

angular.module('Controllers').controller('WidgetIframeController', WidgetIframeController);

/////////////////////////////

export { WidgetIframeController };
