import React from 'react';

export type InlineAutocompleteSearchContext = {
    setHighlightedItem(item?: any): void;
    /** Allow to use the onItemSelected given by the fetchHook, on the handleKeyDown of InlineAutocompleteHOC */
    setFetchHookOnItemSelected(onItemSelected: () => void): void;
};

export const InlineAutocompleteSearchContext = React.createContext<undefined | InlineAutocompleteSearchContext>(
    undefined,
);

export const useInlineAutocompleteSearchContext = () =>
    React.useContext(InlineAutocompleteSearchContext) as InlineAutocompleteSearchContext;
