import { learningHatImage } from '@lumapps/lumx/custom-icons';
import { mdiCalendarCheck, mdiCommentOutline, mdiGoogleCirclesExtended } from '@lumapps/lumx/icons';
import { GLOBAL } from '@lumapps/translations';

import { WIDGET_STREAM } from './keys';
import { ManagedStreamResources, StreamActions } from './types';

export const WIDGET_STREAM_TYPE = 'stream';

export const DEFAULT_MAX_STREAM_ITEMS = 6;

/** Map of each resource filter to their corresponding translation key  */
export const RESOURCE_FILTER_TO_TRANSLATION_KEY: Record<ManagedStreamResources, string> = {
    article: GLOBAL.ARTICLES,
    community: GLOBAL.COMMUNITIES,
    content: GLOBAL.CONTENT,
    event: GLOBAL.EVENTS,
    learning: GLOBAL.LEARNING,
    play_video: GLOBAL.VIDEOS,
    play_video_playlist: GLOBAL.PLAYLISTS,
    post: GLOBAL.POSTS,
};

export const WIDGET_STREAM_SCOPE = 'widget-stream';

export const STREAM_RESOURCE_FILTER_QUERY_PARAM = 'streamResources';

/** Map of each key depending on the stream resource and action */
export const STREAM_ACTION_TO_CONTEXT_BANNER_KEY: Partial<
    Record<ManagedStreamResources, Partial<Record<StreamActions, string>>>
> = {
    article: {
        new_comment: WIDGET_STREAM.USER_COMMENTED_AN_ARTICLE,
    },
    community: {
        new_follow: WIDGET_STREAM.USER_STARTED_FOLLOWING_A_COMMUNITY,
    },
    content: {
        new_comment: WIDGET_STREAM.USER_COMMENTED_IN_CONTENT,
    },
    event: {
        new_comment: WIDGET_STREAM.USER_COMMENTED_AN_EVENT,
        new_attendee: WIDGET_STREAM.USER_ATTENDS_AN_EVENT,
    },
    learning: {
        new_enrollment: WIDGET_STREAM.USER_ENROLLED_IN_A_TRAINING,
    },
    post: {
        new_comment: WIDGET_STREAM.USER_COMMENTED_A_POST,
    },
};

/** Dictionary of the icons for each type of stream action */
export const STREAM_ACTION_TO_CONTEXT_BANNER_ICON: Partial<
    Record<ManagedStreamResources, Partial<Record<StreamActions, string>>>
> = {
    article: {
        new_comment: mdiCommentOutline,
    },
    community: {
        new_follow: mdiGoogleCirclesExtended,
    },
    content: {
        new_comment: mdiCommentOutline,
    },
    event: {
        new_comment: mdiCommentOutline,
        new_attendee: mdiCalendarCheck,
    },
    learning: {
        new_enrollment: learningHatImage,
    },
    post: {
        new_comment: mdiCommentOutline,
    },
};
