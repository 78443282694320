import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import {
    FlexBox,
    GenericProps,
    Orientation,
    SkeletonCircle,
    Size,
    SkeletonRectangle,
    SkeletonRectangleVariant,
    Typography,
    SkeletonTypography,
} from '@lumapps/lumx/react';

import './index.scss';

export const CLASSNAME = 'comment-skeleton';

interface CommentSkeletonProps extends GenericProps {
    variant?: 'editor';
}

export const CommentSkeleton: React.FC<CommentSkeletonProps> = ({ variant, ...forwardedProps }) => {
    const { className } = forwardedProps;
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <FlexBox orientation={Orientation.horizontal} {...forwardedProps} className={block([className])}>
            <SkeletonCircle className={element('avatar')} size={Size.m} />
            <div className={element('content')}>
                <SkeletonRectangle
                    variant={SkeletonRectangleVariant.rounded}
                    style={{ height: variant === 'editor' ? '84px' : '60px', width: '100%' }}
                />
                {variant !== 'editor' && (
                    <SkeletonTypography
                        typography={Typography.body2}
                        style={{ maxWidth: '152px', width: '100%' }}
                        className={margin('top')}
                    />
                )}
            </div>
        </FlexBox>
    );
};
