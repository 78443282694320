import { Route, AppId, createPageRoute } from '@lumapps/router';

import { DEFAULT_VIEW } from './constants';

export const communitiesTemplatesRoute: Route = createPageRoute({
    slug: 'community/templates',
    id: 'app.admin.community-templates',
    appId: AppId.backOffice,
});

export const communityTemplateCreationRoute: Route = {
    path: '/community/create/template/posts',
    legacyId: 'app.front.content-create',
    appId: AppId.legacy,
};

export const communityTemplateEditLayoutRoute: Route = {
    // The param names should be the same as in the legacy state.js $stateParams
    path: '/community/edit/:key/:subTemplate',
    params: {
        contentType: 'community',
        isTemplate: 'true',
        // The community template id.
        key: undefined,
        // The community view (dashboard, posts, etc.)
        subTemplate: undefined,
    },
    legacyId: 'app.front.content-edit',
    appId: AppId.legacy,
};

export const newCommunityTemplate = (): Route => {
    return communityTemplateCreationRoute;
};

export const communityTemplateEditlayout = (templateId: string): Route => {
    return {
        ...communityTemplateEditLayoutRoute,
        params: {
            ...communityTemplateEditLayoutRoute.params,
            key: templateId,
            subTemplate: DEFAULT_VIEW,
        },
        query: {
            isTemplate: 'true',
        },
    };
};
