/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import includes from 'lodash/includes';
import loFind from 'lodash/find';
import some from 'lodash/some';
import union from 'lodash/union';
import without from 'lodash/without';
import { getAttributes } from '@lumapps/data-attributes';

// ///////////////////////////

function AbstractPickerController(
    $element,
    $http,
    $injector,
    $q,
    $rootScope,
    $scope,
    $templateCache,
    $timeout,
    AbstractPicker,
    Config,
    Feed,
    Instance,
    Layout,
    User,
    Utils,
    Metadata,
    Tag,
    Translation,
) {
    'ngInject';

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The list of templates sections defined in abstract picker.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _ABSTRACT_TEMPLATES = ['footer'];

    /**
     * The delay for debouncing the trigger of the search when filters are updated (in milliseconds).
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _FILTER_DEBOUNCE_DELAY = 500;

    /**
     * Map Object type with Object service.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _OBJECT_SERVICE_MAP = {
        Media: 'Document',
    };

    /**
     * The list of dynamic templates sections defined for each object picker.
     * Each template is in its specific folder.
     * Example: In media-picker folder I find media-picker/views/partials/media-picker-[_object_template].html.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _OBJECT_TEMPLATES = ['actions', 'detail', 'filter', 'list', 'grid', 'sidebar', 'header', 'selection'];

    /**
     * The default template path for each object type.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _STATIC_ABSTRACT_TEMPLATE_PATH = '/client/common/modules/abstract-picker/views/partials/';

    /**
     * The model to rollback in case we cancel submition.
     *
     * @type {Array}
     */
    let _rollbackModel;

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * The name of the controller for the specific picker.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.CONTROLLER_NAME = '';

    /**
     * The dynamic template path for each object type.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.DYNAMIC_OBJECT_TEMPLATE_PATH = '';

    /**
     * The list key of the selected object type.
     *
     * @type {string}
     * @constant
     */
    vm.SELECTION_LIST_KEY = '';

    /**
     * List of displayed objects.
     *
     * @type {Array}
     */
    vm.currentObjectList = [];

    /**
     * The current filter object used to display the object list.
     *
     * @type {Object}
     */
    vm.currentFilter = {};

    /**
     * The current filter array for uploading files corresponding to uploader allowedTypes format.
     *
     * @type {Array}
     */
    vm.allowedTypesForUploader = [];

    /**
     * The current object element.
     *
     * @type {Object}
     */
    vm.current = {
        /**
         * The current instance id.
         *
         * @type {string}
         */
        instanceId: '',
        /**
         * The current input language.
         *
         * @type {string}
         */
        lang: '',
        /**
         * The navigation path collection.
         *
         * @type {Array}
         */
        objectPathMap: [],
        /**
         * The navigation current path.
         *
         * @type {Object}
         */
        path: {},
        /**
         * The dataTable field sort order.
         *
         * @type {Object}
         */
        sortOrder: {},
    };

    /**
     * The abstract picker view states.
     *
     * @type {Object}
     */
    vm.is = {
        admin: false,
        open: false,
        selectionShown: false,
        sidebarShown: false,
        viewList: Layout.breakpoint === 'desk',
    };

    /**
     * The injected object service for the picker.
     *
     * @type {Object}
     */
    vm.objectService = {};

    /**
     * The injected object service path for the picker.
     *
     * @type {Object}
     */
    vm.objectServicePath = {};

    /**
     * Object that hold search parameters.
     *
     * @type {Object}
     */
    vm.searchParameters = {};

    /**
     * Define the selected object to display detail in template.
     *
     * @type {boolean}
     */
    vm.selectedDetailObject = {};

    /**
     * The template cache object.
     *
     * @type {Object}
     */
    vm.templatesToCache = {};

    // ///////////////////////////

    /**
     * Services and utilities.
     */
    vm.Config = Config;
    vm.Instance = Instance;
    vm.Translation = Translation;
    vm.Utils = Utils;
    vm.dataScope = 'picker';

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    /**
     * Extend given filters with extra objectService properties.
     *
     * @return {Object} Object service list request filters.
     */
    function _getObjectServiceListRequestFilters() {
        // Note: this is to prevent "" strings.

        if (includes(vm.allowedProviders, 'community')) {
            vm.providerService = 'CommunityDocumentProvider';
        } else {
            vm.providerService = vm.providerService || 'DocumentProvider';
        }
        const providerService = angular.isDefinedAndFilled(vm.providerService) ? vm.providerService : undefined;

        return vm.objectService.getPickerListRequestFilters(
            vm.currentFilter,
            vm.current.objectPathMap,
            vm.showFoldersOnly,
            providerService,
        );
    }

    /**
     * Clear listed objects.
     */
    function _clearObjects() {
        vm.currentObjectList.length = 0;
    }

    /**
     * Get list of parameters for "list" request.
     *
     * @return {Object} "list" request parameters.
     */
    function _getCommonObjectListFilters() {
        const { query, type } = vm.searchParameters;
        const { sortOrder } = vm.currentFilter;
        const sortOrders = [];

        // Only apply the sortOrder if it has explicitely been asked (i.e. by clicking on a header).
        if (angular.isDefinedAndFilled(sortOrder)) {
            sortOrders.push(...vm.objectService.getMandatorySortOrders(), sortOrder);
        }

        const types = union(type, vm.allowedTypes);
        const params = {
            searchTags: vm.searchParameters.tags,
            searchText: query,
            sortOrder: sortOrders,
        };

        if (!vm.showFoldersOnly) {
            params.searchTypes = types;
        }

        return params;
    }

    /**
     * Init the uploader allowedTypes filter format which is different than backend searchTypes filter.
     */
    function _initUploaderFilterFormat() {
        if (angular.isUndefinedOrEmpty(vm.allowedTypes)) {
            return;
        }

        vm.allowedTypesForUploader = [];

        if (includes(vm.allowedTypes, 'IMAGE')) {
            vm.allowedTypesForUploader = vm.allowedTypesForUploader.concat(Config.FILE_TYPES.IMAGE);
        }
        if (includes(vm.allowedTypes, 'OTHER')) {
            vm.allowedTypesForUploader = vm.allowedTypesForUploader.concat(
                Config.FILE_TYPES.OTHER,
                Config.FILE_TYPES.DOCUMENT,
            );
        }
    }

    /**
     * Replace displayed list objects.
     *
     * @param {Array} object List of object to set.
     */
    function _setObjects(object) {
        const objects = [];

        angular.forEach(object, (doc) => {
            objects.push({ ...doc, kind: vm.objectService.getObjectKind(doc, vm.currentFilter) });
        });

        vm.currentObjectList = objects;
    }

    /**
     * Add the common filters to the filters that will be used to list the objects.
     *
     * @param {Object} filters The filters to extend.
     */
    function _addCommonObjectListFilters(filters) {
        angular.extend(filters, _getCommonObjectListFilters());
    }

    /**
     * Make a request to list objects and display them.
     *
     * @param {Object} parameters List of erqust parameters.
     */
    async function _listObjects(parameters) {
        await vm.objectService
            .promiseFilterize(parameters, vm.SELECTION_LIST_KEY)
            .then(_setObjects, Utils.displayServerError);
    }

    /**
     * Inject object services from object type.
     */
    function _injectObjectServices() {
        const mappedObjectServiceName = _OBJECT_SERVICE_MAP[vm.objectType];

        if (angular.isDefinedAndFilled(mappedObjectServiceName)) {
            vm.objectService = $injector.get(mappedObjectServiceName);
        } else {
            vm.objectService = $injector.get(`${vm.objectType}Picker`);
        }

        if (angular.isDefinedAndFilled(vm.objectTypePath)) {
            vm.objectServicePath = $injector.get(vm.objectTypePath);
        }
    }

    /**
     * Init vm Model in case its empty and for rollback value.
     */
    function _initModel() {
        // In case ngModel is not defined, set it as empty array or undefined.
        if (angular.isUndefinedOrEmpty(vm.ngModel)) {
            vm.ngModel = vm.multiple ? [] : undefined;
            _rollbackModel = undefined;

            return;
        }

        // In case we cancel selection, rollback the data.
        _rollbackModel = angular.fastCopy(vm.ngModel);

        if (angular.isDefined(vm.ngModel) && !angular.isArray(vm.ngModel) && vm.multiple) {
            vm.ngModel = [vm.ngModel];
        }
    }

    /**
     * Determine if user have admin rights to manage the element.
     */
    function _initRights() {
        vm.is.admin = get(User.getConnected(), 'isSuperAdmin', false) || User.isInstanceAdmin();
    }

    /**
     * Init vm data regarding directive attributs.
     */
    function _initData() {
        _initUploaderFilterFormat();
        vm.initFilterAndOrder();
        _initModel();
        _initRights();

        angular.extend(vm.current, {
            instanceId: Instance.getCurrentInstanceId(),
            lang: Translation.inputLanguage,
        });

        vm.SELECTION_LIST_KEY = `${vm.objectType}-picker-selection-${vm.pickerId}`;
        vm.multiple = Boolean(vm.multiple);
        vm.embedded = Boolean(vm.embedded);
    }

    /**
     * Init picker view.
     */
    function _initView() {
        vm.actionSidebarShow(Layout.breakpoint === 'desk');
        vm.actionSelectionShow(false);
    }

    /**
     * Reset search parameters.
     */
    function _resetSearchParameters() {
        vm.searchParameters = {};
    }

    /**
     * Determine the path to templates for abstract picker.
     */
    function _setTemplateCache() {
        // Static abstract templates.
        angular.forEach(_ABSTRACT_TEMPLATES, (staticTemplateItem) => {
            vm.templatesToCache[
                staticTemplateItem
            ] = `${_STATIC_ABSTRACT_TEMPLATE_PATH}abstract-picker-${staticTemplateItem}.html`;
        });

        // Dynamic object templates.
        angular.forEach(_OBJECT_TEMPLATES, (dynamicTemplateItem) => {
            const templatePath = `${vm.DYNAMIC_OBJECT_TEMPLATE_PATH +
                vm.objectType.toLowerCase()}-picker-${dynamicTemplateItem}.html`;
            vm.templatesToCache[dynamicTemplateItem] = templatePath;
        });
    }

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Open or Close the selection detail panel.
     *
     * @param {boolean} [showSelection] The action to display the selection.
     */
    function actionSelectionShow(showSelection) {
        vm.resetDetailObject();
        vm.is.selectionShown = angular.isDefined(showSelection) ? showSelection : !vm.is.selectionShown;
    }

    /**
     * Open or Close the sidebar.
     *
     * @param {boolean} [showSideBar] The action to display the sidebar.
     */
    function actionSidebarShow(showSideBar) {
        vm.is.sidebarShown = angular.isDefined(showSideBar) ? showSideBar : !vm.is.sidebarShown;
    }

    /**
     * Set search parameters and execute changes.
     *
     * @param  {Object}  searchParams New search parameters.
     * @return {Promise} Request promise.
     */
    function _changeSearchParameters(searchParams) {
        vm.searchParameters = searchParams;
        delete vm.currentFilter.sortOrder;

        return vm.executeFilter();
    }

    /**
     * Close abstract picker.
     *
     * @param {string} action The button name who call the action close.
     */
    function closeAbstractPicker(action) {
        if (action === 'cancel') {
            vm.ngModel = angular.fastCopy(_rollbackModel);
            AbstractPicker.close(vm.pickerId, false);
        } else {
            AbstractPicker.close(vm.pickerId, true);
        }
    }

    /**
     * Init default filter and order.
     */
    function initFilterAndOrder() {
        // Set vm.currentFilter with object service default picker params.
        if (angular.isDefinedAndFilled(vm.objectService.defaultPickerParams)) {
            vm.currentFilter = angular.fastCopy(vm.objectService.defaultPickerParams);
        }

        if (angular.isDefinedAndFilled(vm.filterView)) {
            angular.extend(vm.currentFilter, vm.filterView);
        }
    }

    /**
     * Reset picker and load results.
     */
    function resetPicker() {
        // Get model if it has changed.
        _initModel();

        // Reset open filter and sidebar.
        _initView();

        // Reset remaining values in filters and order.
        vm.initFilterAndOrder();
    }

    /**
     * Get object list request parameters.
     *
     * @return {Object} List of request parameters.
     */
    async function _getObjectListRequestParameters() {
        const requestFilters = await _getObjectServiceListRequestFilters();

        _addCommonObjectListFilters(requestFilters);

        return requestFilters;
    }

    /**
     * Reset current path and current path map.
     */
    function resetPath() {
        vm.current.objectPathMap = [];
    }

    /**
     * The filter callback function call in object picker filter template.
     *
     * @param {boolean} [forcePathReset] Determine if the current path has to be reset.
     */
    async function executeFilter(forcePathReset) {
        vm.resetDetailObject();

        if (forcePathReset) {
            vm.resetPath();
        }

        _clearObjects();
        await _listObjects(await _getObjectListRequestParameters());
    }

    /**
     * Check if the details sidebar is empty.
     *
     * @return {boolean} If the detail sidebar is empty or not.
     */
    function isDetailEmpty() {
        return angular.isUndefinedOrEmpty(vm.selectedDetailObject) || vm.is.selectionShown;
    }

    /**
     * Check if an object type is displayed in the detail.
     *
     * @param  {Object}  object The object to check if it is displayed in detail.
     * @return {boolean} If the object is displayed in detail or not.
     */
    function isDisplayedInDetail(object) {
        if (angular.isUndefinedOrEmpty(vm.selectedDetailObject)) {
            return false;
        }

        return object === vm.selectedDetailObject;
    }

    /**
     * The filter callback function call in object picker filter template.
     *
     * @param  {Object}  objectFilter The filter params to test.
     * @return {boolean} Whether the filter is empty without any values.
     */
    function isFilterEmpty(objectFilter) {
        if (angular.isUndefinedOrEmpty(objectFilter)) {
            return true;
        }

        return some(objectFilter, angular.isDefinedAndFilled);
    }

    /**
     * Check if an object type is selected.
     *
     * @param  {Object}  object The object to check if is Selected.
     * @return {boolean} If the object is selected or not.
     */
    function isSelected(object) {
        if (angular.isUndefinedOrEmpty(vm.ngModel)) {
            return false;
        }

        return vm.multiple
            ? angular.isDefinedAndFilled(
                loFind(vm.ngModel, {
                    id: object.id,
                }),
            )
            : vm.ngModel.id === object.id;
    }

    /**
     * Load the next page of items (not folders).
     */
    function loadNextPage() {
        vm.objectService.nextPage(_setObjects, Utils.displayServerError, vm.SELECTION_LIST_KEY);
    }

    /**
     * Navigate to path from path object.
     *
     * @param  {Object}  [objectPath] The path object to navigate to.
     * @return {Promise} Navigation promise.
     */
    function navigatePath(objectPath) {
        _resetSearchParameters();

        if (angular.isDefinedAndFilled(objectPath)) {
            vm.current.objectPathMap.push(angular.fastCopy(objectPath));
        }

        return vm.executeFilter();
    }

    /**
     * Navigate back to the parent path.
     *
     * @return {Promise} Navigation promise.
     */
    function navigateToParentPath() {
        vm.current.objectPathMap.splice(-1, 1);

        return navigatePath();
    }

    /**
     * Remove an object from selection list.
     *
     * @param {Object} object The object to remove from the selection list.
     */
    function removeFromSelection(object) {
        if (angular.isUndefinedOrEmpty(object)) {
            return;
        }

        if (vm.multiple) {
            vm.ngModel = without(
                vm.ngModel,
                loFind(vm.ngModel, {
                    id: object.id,
                }),
            );
        } else {
            vm.ngModel = undefined;
        }
    }

    /**
     * Reset current detail object.
     */
    function resetDetailObject() {
        vm.selectedDetailObject = undefined;
    }

    /**
     * Submit the selected object list.
     */
    function submitList() {
        $timeout(() => {
            AbstractPicker.close(vm.pickerId, true);
        });
    }

    /**
     * Toggle object for more details.
     *
     * @param {Object} object The object to display detail.
     */
    function toggleDetail(object) {
        $timeout(() => {
            vm.selectedDetailObject = object;

            if (vm.selectedDetailObject && angular.isDefinedAndFilled(vm.selectedDetailObject.override)) {
                return;
            }

            vm.selectedDetailObject.override = {
                description: {},
                name: {},
                thumbnail: {},
            };
        });
    }

    /**
     * Change display mode for object service elements from list to element blocks.
     */
    function toggleListView() {
        vm.is.viewList = !vm.is.viewList;
    }

    /**
     * 
     * @param {Object} options 
     * @param {string} options.element 
     * @param {string} options.action 
     * @returns 
     */
    function getDataId(scope, options) {
        return getAttributes(scope)(options)['data-id'];
    }

    /**
     * Toggle the selection status of given object.
     *
     * @param {Object} object The object to toggle the status of.
     */
    function toggleSelection(object) {
        if (vm.embedded) {
            return;
        }

        if (vm.isSelected(object)) {
            vm.removeFromSelection(object);

            return;
        }

        const modelObject = angular.fastCopy(object);

        if (vm.multiple) {
            /**
             * If "maxSelect" option is set, replace the last selected image
             * if the number of selected images exceed the max.
             */
            if (vm.maxSelect && vm.ngModel.length === Number(vm.maxSelect)) {
                vm.ngModel[vm.ngModel.length - 1] = modelObject;
            } else {
                vm.ngModel.push(modelObject);
            }
        } else if (!vm.multiple) {
            vm.ngModel = modelObject;
        }
    }

    /**
     * Toggle the selection sort order.
     *
     * @param  {string}  sortOrder The sort order to be toggled.
     * @return {Promise} Request promise.
     */
    function toggleSortOrder(sortOrder) {
        if (angular.isUndefinedOrEmpty(sortOrder)) {
            return Promise.resolve();
        }

        vm.currentFilter.sortOrder =
            angular.isUndefinedOrEmpty(vm.currentFilter.sortOrder) || sortOrder !== vm.currentFilter.sortOrder
                ? sortOrder
                : `-${sortOrder}`;

        return vm.executeFilter();
    }

    // ///////////////////////////

    vm.actionSelectionShow = actionSelectionShow;
    vm.actionSidebarShow = actionSidebarShow;
    vm.closeAbstractPicker = closeAbstractPicker;
    vm.debouncedChangeSearchParameters = debounce(_changeSearchParameters, _FILTER_DEBOUNCE_DELAY);
    vm.executeFilter = executeFilter;
    vm.getDataId = getDataId;
    vm.initFilterAndOrder = initFilterAndOrder;
    vm.isDetailEmpty = isDetailEmpty;
    vm.isDisplayedInDetail = isDisplayedInDetail;
    vm.isFilterEmpty = isFilterEmpty;
    vm.isSelected = isSelected;
    vm.loadNextPage = loadNextPage;
    vm.navigatePath = navigatePath;
    vm.navigateToParentPath = navigateToParentPath;
    vm.removeFromSelection = removeFromSelection;
    vm.resetDetailObject = resetDetailObject;
    vm.resetPath = resetPath;
    vm.resetPicker = resetPicker;
    vm.submitList = submitList;
    vm.toggleDetail = toggleDetail;
    vm.toggleListView = toggleListView;
    vm.toggleSelection = toggleSelection;
    vm.toggleSortOrder = toggleSortOrder;

    // ///////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vm.CONTROLLER_NAME = `${vm.objectType}PickerController`;
        vm.DYNAMIC_OBJECT_TEMPLATE_PATH = `/client/common/modules/${vm.objectType.toLowerCase()}-picker/views/partials/`;

        _injectObjectServices();
        _initData();
        _setTemplateCache();
    }

    init();
}

// ///////////////////////////

/**
 * Abstract picker directive.
 * Handle a dialog (or an embedded) abstract picker that allow to search and select object [Media, User, Content].
 *
 * @param {boolean} [allowEmptySelection=false]          Indicates if the user can submit the selection with an empty
 *                                                       selection
 * @param {boolean} [allowFolderPick=false]              Indicates if the end user can select a folder or not.
 *                                                       Usually used with the showFoldersOnly boolean.
 * @param {Array}   [allowedProviders]                   Whether only drive files are allowed.
 * @param {Array}   [allowedTypes]                       The objectType types allowed (ie media : .jpg)
 * @param {boolean} [embedded=false]                     Indicates if we want the abstract picker to be displayed.
 *                                                       as a dialog or directly embedded to its position.
 * @param {number}  [maxSelect]                          The maximum number of objects that can be selected.
 * @param {boolean} [multiple=false]                     Indicates if we can select several elements at the same time.
 * @param {Array}   ngModel                              The model where to store the picked objects.
 * @param {Object}  objectType                           Indicates the kind of object the abstract picker manage.
 * @param {string}  pickerId                             The id of the abstract picker.
 * @param {string}  [providerService='DocumentProvider'] Give the ability to change to default document provider svc.
 * @param {boolean} [showFoldersOnly=false]              Indicates if we want to display only folders in the list as
 *                                                       opposed to folders and files.
 * @param {string}  [title]                              The title of the abstract picker.
 * @param {string}  widgetUuid                           The uuid of the widget containing the link to the content.
 */

function AbstractPickerDirective() {
    'ngInject';

    function link(scope, el) {
        if (!scope.vm.embedded) {
            el.appendTo('body');

            scope.$on('$destroy', () => {
                el.remove();
            });
        }
    }

    return {
        bindToController: true,
        controller: AbstractPickerController,
        controllerAs: 'vm',
        link,
        restrict: 'E',
        scope: {
            allowEmptySelection: '<?lsAllowEmptySelection',
            allowFolderPick: '<?lsAllowFolderPick',
            allowedProviders: '<?lsAllowedProviders',
            allowedTypes: '<?lsAllowedTypes',
            embedded: '<?lsEmbedded',
            filterView: '<?lsFilterView',
            hasTemplateSidebar: '<?lsHasSidebar',
            maxSelect: '@?lsMaxSelect',
            multiple: '<?lsMultiple',
            language: '@?lsLanguage',
            ngModel: '=?',
            objectType: '@lsType',
            objectTypePath: '@lsTypePath',
            pickerId: '@lsPickerId',
            providerService: '@?lsProviderService',
            showFoldersOnly: '<?lsShowFoldersOnly',
            title: '@lsTitle',
            widgetUuid: '@lsWidgetUuid',
        },
        templateUrl: '/client/common/modules/abstract-picker/views/abstract-picker.html',
        transclude: true,
    };
}

// ///////////////////////////

angular.module('Directives').directive('lsAbstractPicker', AbstractPickerDirective);

// ///////////////////////////

export { AbstractPickerDirective };
