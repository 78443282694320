import React from 'react';

import { padding } from '@lumapps/classnames';
import {
    MetadataPickerField,
    MetadataPickerFieldProps,
} from '@lumapps/metadata-pickers/components/MetadataPickerField';
import { Metadata } from '@lumapps/metadata/types';
import { useTranslate } from '@lumapps/translations';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';

export interface FormMetadataFieldProps
    extends Omit<MetadataPickerFieldProps, 'selectionType' | 'onChange' | 'onBlur' | 'value' | 'familyId'> {
    family: Metadata;
}

/**
 * Metadata field in order to use in a context of a Form
 * @param FormMetadataFieldProps
 * @returns FormMetadataField
 */
export const FormMetadataField: React.FC<FormMetadataFieldProps & Omit<BaseInput, 'label'>> = ({
    controllerProps,
    family,
    name,
    showMultipleLevels,
    ...props
}) => {
    const { translateObject } = useTranslate();
    const label = translateObject(family.name) || `metadatas.${family.id}`;
    const { field, valueToUse = [] } = useFormField<Metadata[] | Metadata | undefined>({
        name,
        controllerProps,
        label,
    });
    const onChange = React.useCallback((e = null) => field.onChange(e), [field]);
    const selectionType = family.multiple ? 'multiple' : 'single';
    const value = selectionType === 'single' && Array.isArray(valueToUse) ? valueToUse[0] : valueToUse;

    return (
        <MetadataPickerField
            selectionType={selectionType}
            {...props}
            label={label}
            familyId={family.id}
            onBlur={field.onBlur}
            instanceId={family.instance}
            showMultipleLevels={showMultipleLevels}
            // fields displayed inside a form should have no padding since distances between
            // fields are managed by the fieldset they are displayed in. This removes the
            // default padding for the Select
            className={padding('vertical', null)}
            onChange={onChange}
            value={value || undefined}
        />
    );
};
