import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';

import { CalendarEvent, EventStatus } from '@lumapps/lumx-calendar/types';
import { fixBackendMissingUTC } from '@lumapps/utils/date/fixBackendMissingUTC';
import { getMoment } from '@lumapps/utils/time/getMoment';

import { LegacyCalendar, LegacyCalendarAttendee, LegacyCalendarEvent } from '../types';
import { getEventCalendarColor } from './getEventCalendarColor';

const MAP_LEGACY_EVENT_STATUS: Record<LegacyCalendarAttendee['responseStatus'], EventStatus> = {
    tentative: 'tentative',
    tentativelyAccepted: 'tentative',
    accepted: 'accepted',
    declined: 'declined',
    needsAction: 'needsAction',
};

/**
 * Import moment to reformat the Date string returned by the backend
 * This is required to deal with specific format that are not well-supported by the javascript Date object.
 * */
const moment = getMoment();

/**
 * Map the date string provided by the v1 API to a Javascript Date object
 * */
const getDateFromLegacy = (date: string, isAllDay?: boolean | null): Date => {
    /**
     * If the date is formated with `YYYY-MM-DD` format.
     * We do not need to use `fixBackendMissingUTC` since the timezone is not specified.
     * If the event is flag with `isAllDay`, we do not need to happen the timezone.
     * All day events should not be timezone.
     * */
    if (/^\d{4}-\d{2}-\d{2}$/.exec(date) || isAllDay) {
        return moment(date).toDate();
    }

    return moment(fixBackendMissingUTC(date)).toDate();
};

/**
 * Convert legacy event, returned by the API into Calendar compatible Event format
 * */
export const mapLegacyEvent =
    (calendarsSelected: LegacyCalendar[], userCalendarColor: string) =>
    ({
        htmlLink: link,
        customWebLink,
        hangoutLink: meetingLink,
        start,
        end,
        id,
        calendarId,
        summary,
        description,
        attendees,
        isAllDay,
    }: LegacyCalendarEvent): CalendarEvent =>
        omitBy<CalendarEvent>(
            {
                id,
                calendarId,
                link: customWebLink ?? link,
                meetingLink,
                summary,
                description,
                start: getDateFromLegacy(start, isAllDay),
                end: getDateFromLegacy(end, isAllDay),
                status: MAP_LEGACY_EVENT_STATUS[
                    attendees.find((attendee) => attendee.self)
                        ?.responseStatus as LegacyCalendarAttendee['responseStatus']
                ],
                color: getEventCalendarColor(userCalendarColor, calendarsSelected, calendarId),
            } as CalendarEvent,
            isNull,
        ) as CalendarEvent;
