/* istanbul ignore file */
import React, { Suspense } from 'react';

import { ShareMenuItemLoading } from '@lumapps/share/components/ShareMenuItemLoading';

const LazyShareToTeamsMenuItem = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'share-to-teams-menu-item' */
            './ShareToTeamsMenuItem'
        ),
);

export const ShareToTeamsMenuItem = (props: React.ComponentProps<typeof LazyShareToTeamsMenuItem>) => {
    return (
        <Suspense fallback={<ShareMenuItemLoading />}>
            <LazyShareToTeamsMenuItem {...props} />
        </Suspense>
    );
};
