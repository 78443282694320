
import reverse from 'lodash/reverse';
import loGet from 'lodash/get';
import { NGI_WIDGETS_IN_DESIGNER_FF_TOKEN } from '@lumapps/widget-base/constants';
import { isDitaContentEmpty } from '@lumapps/wrex/serialization/dita/utils/isDitaContentEmpty';

/////////////////////////////

/* eslint-disable no-invalid-this */
(function IIFE() {
    'use strict';

    /////////////////////////////

    function AbstractContentService($injector, $location, $log, $q, $rootScope, $sanitize, $state, $stateParams, $window, Config,
        ContentTemplate, Customer, Features, InitialSettings, Instance, LumsitesBaseService, MainNav, Metadata, ReduxStore, Translation,
        User, Utils, Widget) {
        'ngInject';

        var lumsiteServiceClass = LumsitesBaseService.proto;

        /////////////////////////////

        var AbstractContent = (function AbstractContentIIFE() {
            /////////////////////////////
            //                         //
            //    Private attributes   //
            //                         //
            /////////////////////////////

            /**
             * The default date format to convert start and end publication date.
             *
             * @type {string}
             * @readonly
             */
            var _DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS';

            /**
             * The short date format to use to format date.
             *
             * @type {string}
             * @readonly
             */
            var _SHORT_DATE_FORMAT = 'YYYY-MM-DD';

            /**
             * A hook function to execute before any save call.
             *
             * @type {Function}
             */
            var _preSaveMethod = _.identity;

            /**
             * Enumeration of all the available link target.
             *
             * @type {Object}
             */
            var _targets = {
                blank: '_blank',
                empty: '',
                self: '_self',
            };

            /////////////////////////////
            //                         //
            //    Private functions    //
            //                         //
            /////////////////////////////

            /**
             * Fix the global widgets inside of a content.
             * Either update them with the most recent global widget or "unglobal" them if they are not supposed to be
             * global anymore.
             *
             * @param {Object} content The content we want to get the global widgets of.
             */
            function _fixGlobalWidgets(content) {
                var globalWidgets = ContentTemplate.getElementList(content, 'widget', 'isGlobal', true);

                angular.forEach(globalWidgets, function forEachGlobalWidgets(contentGlobalWidget) {
                    var globalWidget = _.find(Widget.getGlobalWidgets(), {
                        id: contentGlobalWidget.id,
                    });

                    if (angular.isDefinedAndFilled(globalWidget)) {
                        var uuid = contentGlobalWidget.uuid;

                        Utils.swapObject(contentGlobalWidget, angular.fastCopy(globalWidget));
                        contentGlobalWidget.uuid = uuid;
                    // The global widget doesn't exists anymore, remove the properties.
                    } else {
                        contentGlobalWidget.isGlobal = false;

                        delete contentGlobalWidget.id;
                        delete contentGlobalWidget.instance;
                        delete contentGlobalWidget.customer;
                    }
                });
            }

            /**
             * Format a date input field of a content to a simple date format.
             *
             * TODO [Clément]: move this to a future DateService/DateUtils.
             *
             * @param {Object} content      The content in which we want to format date.
             * @param {string} dateProperty The name of the property containing the date input we want to format.
             * @param {string} dateFormat   The date format to use.
             */
            function _formatDateInputToDate(content, dateProperty, dateFormat) {
                if (angular.isUndefined(_.get(content, dateProperty + 'Input.date'))) {
                    content[dateProperty] = undefined;

                    return;
                }

                var dateInput = content[dateProperty + 'Input'];

                var date = moment(dateInput.date).format(_SHORT_DATE_FORMAT);
                var hour = (angular.isDefined(dateInput.hour) && dateInput.hour > 0 && dateInput.hour <= 24) ?
                    dateInput.hour : '00';
                var min = (angular.isDefined(dateInput.min) && dateInput.min > 0 && dateInput.min <= 60) ?
                    dateInput.min : '00';

                content[dateProperty] =
                    moment(date + 'T' + hour + ':' + min + ':00.000', _DATE_FORMAT).utc().format(dateFormat);
            }

            /**
             * Format a frontend formatted content to a format more usable by the backend (backend format).
             *
             * @param  {Object} content The frontend formatted content.
             * @return {Object} The backend formatted content.
             */
            function _formatClientObjectForServer(content) {
                if (angular.isUndefinedOrEmpty(content)) {
                    return content;
                }

                _formatDateInputToDate(content, 'startDate', _DATE_FORMAT);
                _formatDateInputToDate(content, 'endDate', _DATE_FORMAT);

                _formatDateInputToDate(content, 'featuredStartDate', _DATE_FORMAT);
                _formatDateInputToDate(content, 'featuredEndDate', _DATE_FORMAT);

                Metadata.formatMetadataForServer(content);
                if (angular.isDefinedAndFilled(content.template)) {
                    Metadata.formatMetadataForServer(content.template);
                }

                content.userContent = undefined;

                return content;
            }

            /**
             * Format a date of a content from the backend to a format suitable for a date input.
             *
             * TODO [Clément]: move this to a future DateService/DateUtils.
             *
             * @param {Object} content      The content in which we want to format date.
             * @param {string} dateProperty The name of the property containing the date we want to format.
             * @param {string} dateFormat   The date format to use.
             */
            function _formatDateToDateInput(content, dateProperty, dateFormat) {
                if (angular.isUndefinedOrEmpty(content[dateProperty])) {
                    return;
                }

                var momentDate = moment.utc(content[dateProperty]).local();
                var formattedDate = momentDate.format(dateFormat);
                var formattedHour = parseInt(momentDate.format('HH'), 10);
                var formattedMin = parseInt(momentDate.format('mm'), 10);

                content[dateProperty + 'Input'] = {
                    date: formattedDate,
                    hour: formattedHour,
                    min: formattedMin,
                };
            }

            /**
             * Format a backend formatted content to a format more usable by the frontend (frontend format).
             *
             * @param  {Object} content The backend formatted content.
             * @return {Object} The frontend formatted content.
             */
            function _formatServerObjectToClient(content) {
                Metadata.formatMetadataForClient(content, false);

                content.notifyAuthor = (angular.isUndefined(content.notifyAuthor)) ? false : content.notifyAuthor;

                if (angular.isDefinedAndFilled(content.template)) {
                    Utils.initComponents(content.template);
                }

                _formatDateToDateInput(content, 'startDate', _DATE_FORMAT);
                _formatDateToDateInput(content, 'endDate', _DATE_FORMAT);

                _formatDateToDateInput(content, 'featuredStartDate', _DATE_FORMAT);
                _formatDateToDateInput(content, 'featuredEndDate', _DATE_FORMAT);


                // Clean styles on GET if there is a global style applied.
                // This guaranties that we won't have unwanted styles
                //
                // In legacy app, styles are kept even if there is a global style applied. But they are never used. 
                // And when removing the global styles, global styles replace the previously set styles. So there is no need to 
                // keep styles if there is a global style.
                // This approach is not compatible with the NGI approach. NGI will apply all styles sent to the backend. So we 
                // need to make sure there is only applied styles at the initial call (ie. global styles).
                if(Utils.isDesignerMode() && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN)) {
                    Widget.transformWidgetsByPredicate(content.template, (widget) => {
                        return angular.isDefined(widget.style) && angular.isDefined(ReduxStore.store.getState().style[widget.style]);
                    }, (widget) => {
                        const newWidgetProperties = {
                            ...widget.properties,
                            // For some reason, the global styles don't override those specific styles, so we need to keep them (it's a legacy-legacy issue) 
                            style: {
                                content: {
                                    height: widget.properties.style.content.height,
                                    fullHeight: widget.properties.style.content.fullHeight,
                                },
                                main: {
                                    marginLeft: widget.properties.style.main.marginLeft,
                                    marginRight: widget.properties.style.main.marginRight,
                                    marginTop: widget.properties.style.main.marginTop,
                                    marginBottom: widget.properties.style.main.marginBottom,
                                }
                            }
                        };
                        Object.assign(widget, { properties: newWidgetProperties });
                    });
                }
                return content;
            }

            /**
             * Get the excerpt string of the given string.
             * Remove all HTML and cleanup the string.
             *
             * @param  {string} excerptContent The excerpt string to cleanup.
             * @return {string} The cleaned excerpt string.
             */
            function _getExcerptString(excerptContent) {
                try {
                    excerptContent = Utils.replaceVariables(Utils.replaceTranslatableValues(excerptContent));
                    // We use $sanitize to remove potentials <script> and <style>.
                    return $sanitize(excerptContent);
                } catch (exception) {
                    return '';
                }
            }

            /**
             * The post get hook function. Called everytime a "get" call resolves.
             * Convert the content from the backend format to a format more usable by the frontend.
             *
             * Note: this is also called by the `postList` hook, for each content returned by the "list" call.
             *
             * @param  {Object}  content               The content returned by the "get" call (backend format).
             * @param  {boolean} [saveAsOriginal=true] Indicates if we want to remember this content as the original
             *                                         one.
             *                                         This is used to avoid that the call made by the `postList` hook
             *                                         overrides the already remember original content.
             * @return {Object}  The content transformed to be usable by the frontend (frontend format).
             */
            function _postGet(content, saveAsOriginal) {
                saveAsOriginal = (angular.isUndefined(saveAsOriginal)) ? true : saveAsOriginal;

                this._fixGlobalWidgets(content);

                if (saveAsOriginal) {
                    this.originalContent = angular.fastCopy(content);
                }

                return this._formatServerObjectToClient(content);
            }

            /**
             * The post list hook function. Called everytime a "list" call resolves.
             * Convert each content of the list from a backend format to a format more usable by the frontend.
             *
             * @param  {Object} contents The list of contents to be converted (backend format).
             * @return {Array}  The list of contents transformed to be usable by the frontend (frontend format).
             */
            function _postList(contents) {
                var converted = [];

                var _this = this;
                angular.forEach(contents, function forEachContents(content) {
                    converted.push(_this._postGet(content, false));
                });

                return converted;
            }

            /**
             * The post save hook function. Called everytime a "save" call resolves.
             * Convert the saved content from a backend format to a format more usable by the frontend.
             *
             * @param  {Object} content The saved content (backend format).
             * @return {Object} The saved content transformed to be usable by the frontend (frontend format).
             */
            function _postSave(content) {
                MainNav.init();

                if (this.isHomepage && (User.isInstanceAdmin() || User.getConnected().isSuperAdmin)) {
                    content.isHomepage = true;
                    this._setHomePage(content.id);
                }

                var currentInstance = Instance.getInstance();
                var cct = content.customContentType;

                if (this.isDefaultUserDirectory) {
                    content.isDefaultUserDirectory = true;
                    currentInstance.defaultUserDirectory = content.id;
                }

                currentInstance.defaultContentLists = currentInstance.defaultContentLists || {};

                // We just made the list 'default' so add it.
                if (this.isDefaultContentList) {
                    content.isDefaultContentList = true;
                    currentInstance.defaultContentLists[cct] = content.id;
                // We just made the list 'not default' so remove it.
                } else if (currentInstance.defaultContentLists[cct] === content.id) {
                    delete currentInstance.defaultContentLists[cct];
                }

                return this._formatServerObjectToClient(content);
            }

            /**
             * The pre save hook function. Called everytime a "save" call will be made.
             * Convert the saved content from a frontend format to a format suitable for the backend.
             *
             * @param  {Object} content The object that will be saved (frontend format).
             * @return {Object} The content to save transformed to be usable by the backend (backend format).
             */
            function _preSave(content) {
                this.isHomepage = Boolean(content.isHomepage);
                this.isDefaultUserDirectory = Boolean(content.isDefaultUserDirectory);
                this.isDefaultContentList = Boolean(content.isDefaultContentList);

                if (angular.isFunction(_preSaveMethod)) {
                    content = _preSaveMethod(content);
                }

                return this._formatClientObjectForServer(content);
            }

            /**
             * Declare a content as the homepage of the current instance.
             *
             * @param {string} key The key of the content to set as homepage.
             */
            function _setHomePage(key) {
                this.Api.setHomePage({
                    uid: key,
                }, function onSetHomePageSuccess() {
                    Instance.getInstance().homePage = key;
                });
            }

            /////////////////////////////

            /**
             * Create a new abstract content.
             *
             * @param {$Resource} Api       The API to use with the service.
             * @param {Object}    [options] The options of the service.
             */
            // eslint-disable-next-line no-shadow
            function AbstractContent(Api, options) {
                /////////////////////////////
                //                         //
                //    Public attributes    //
                //                         //
                /////////////////////////////

                /**
                 * The action we are executing.
                 * Possible values are: 'get', 'create', 'style' or 'edit'.
                 *
                 * @type {string}
                 */
                this.action = undefined;

                /**
                 * Contains the breadcrumb of the content.
                 * Each element of the array is a part of the path of the content.
                 *
                 * @type {Array}
                 */
                this.breadcrumb = undefined;

                /**
                 * Indicates if the current content is the default content list when clicking on a tag/metadata.
                 *
                 * @type {boolean}
                 */
                this.isDefaultContentList = false;

                /**
                 * Indicates if the current content is the default user directory when clicking on an user.
                 *
                 * @type {boolean}
                 */
                this.isDefaultUserDirectory = false;

                /**
                 * The current view mode of the content.
                 * Possible values are: 'default', 'simple', 'basic' or 'locked'.
                 *
                 * @type {string}
                 */
                this.viewMode = undefined;

                /////////////////////////////

                options = options || {};

                // Bind pre/post hooks.
                options.postList = this.bindCallback(this, '_postList');
                options.postGet = this.bindCallback(this, '_postGet');
                options.preSave = this.bindCallback(this, '_preSave');
                options.postSave = this.bindCallback(this, '_postSave');

                // Instanciate a new base service.
                lumsiteServiceClass.call(this, Api, options);

                // Setup the default parameters of the base service.
                this.defaultParams = {};
            }

            AbstractContent.prototype = Object.create(lumsiteServiceClass.prototype);

            /////////////////////////////

            AbstractContent.prototype._fixGlobalWidgets = _fixGlobalWidgets;
            AbstractContent.prototype._formatClientObjectForServer = _formatClientObjectForServer;
            AbstractContent.prototype._formatServerObjectToClient = _formatServerObjectToClient;
            AbstractContent.prototype._postGet = _postGet;
            AbstractContent.prototype._postList = _postList;
            AbstractContent.prototype._postSave = _postSave;
            AbstractContent.prototype._preSave = _preSave;
            AbstractContent.prototype._setHomePage = _setHomePage;

            /////////////////////////////
            //                         //
            //     Public functions    //
            //                         //
            /////////////////////////////

            /**
             * Get the callback bound to the given context.
             *
             * @param  {Object}   cbContext    The context in which we want to get the callback.
             * @param  {string}   functionName The function name we want to bind the callback to.
             * @return {Function} The function bound to the given callback in the given context.
             */
            function bindCallback(cbContext, functionName) {
                return function bindCallbackClosure(argument) {
                    return (angular.isFunction(cbContext[functionName])) ? cbContext[functionName](argument) : argument;
                };
            }

            /**
             * Clean anything that needs to be cleaned in the content when checking for changes:
             *     - remove any empty array;
             *     - remove any empty object;
             *     - remove any "null" or "undefined";
             *     - remove any empty string;
             *     - remove any "false" object;
             *     - remove any empty metadata;
             *     - remove any weather attached to a widget weather;
             *
             * It is highly recommanded to pass a copy (angular.fastCopy) of the real content and to use these copies to
             * compare changes as the clean function will mutate your content.
             *
             * @param {Object|Array} content The content to clean.
             */
            function cleanForHasChanges(content) {
                if (angular.isUndefinedOrEmpty(content)) {
                    return;
                }

                var propertiesToDelete = [
                    'adminsDetails',
                    'authorDetails',
                    'createdAt',
                    'endDateInput',
                    'featuredEndDateInput',
                    'featuredStartDateInput',
                    'isBackwardedStyle',
                    'key',
                    'markedAsRelevant',
                    'relevantCommentDetails',
                    'startDateInput',
                    'stylesMigrated',
                    'updatedAt',
                    'updatedByDetails',
                    'userContent',
                    'weather',
                    // edition props
                    'fixedLayout',
                    'fixedWidgets',
                ];

                if (content.type === InitialSettings.CONTENT_TYPES.COMMUNITY) {
                    propertiesToDelete.push('customContentTypeDetails');
                    propertiesToDelete.push('isDefaultContentList');
                    propertiesToDelete.push('template');
                }

                angular.forEach(content, function forEachContentProperties(value, property) {
                    if (_.includes(propertiesToDelete, property) ||
                        // This will remove all the $XXX properties (usually angular related stuff).
                        (angular.isString(property) && property.charAt(0) === '$') ||
                        (angular.isObject(value) && value.type === 'widget' && value.isGlobal === true)) {

                        delete content[property];

                        return;
                    }

                    // Don't compare widget state
                    if (angular.isObject(value) && value.type === 'widget') {
                        delete value.state;
                    }

                    if (
                        angular.isObject(value) &&
                        value.type === 'widget' &&
                        value.widgetType === 'contribution'
                    ) {
                        for (const dita of Object.values(value?.properties?.dita)) {
                            // Don't compare the dita id, that always changes
                            delete dita.attributes.id;
                        }
                    }

                    if (property === 'metadata') {
                        var metadataList = [];
                        angular.forEach(value, function forEachContentMetadata(metadata, key) {
                            if (angular.isUndefinedOrEmpty(metadata)) {
                                delete content[property][key];
                            } else if (angular.isArray(metadata)) {
                                metadataList = metadataList.concat(metadata);
                            } else if (angular.isString(metadata)) {
                                metadataList.push(metadata);
                            }
                        });

                        content[property] = metadataList.sort();
                    }

                    if (property === 'featuredEndDate' || property === 'featuredStartDate') {
                        // Ignore any timezone modifiers to make sure we test the same values.
                        content[property] = moment.utc(value).format(_SHORT_DATE_FORMAT);
                    }

                    if (angular.isObject(value) && angular.isDefinedAndFilled(value)) {
                        if (_.get(value, 'type') === 'cell' && _.get(value, 'properties.sticky') === true) {
                            delete value.properties.plain;
                        }

                        cleanForHasChanges(value);
                    }

                    if (angular.isUndefinedOrEmpty(value) || value === false) {
                        delete content[property];
                    }
                });
            }

            /**
             * Create the breadcrumb for the current content.
             *
             * @param {string} [parentPath=<Current slug>] The path in which the content is (basically, the slug of its
             *                                             parent).
             */
            function createBreadcrumb(parentPath) {
                if (angular.isUndefinedOrEmpty(MainNav.getCurrent())) {
                    return;
                }

                this.breadcrumb = [];

                var currentContent = this.getCurrent();

                if (angular.isUndefinedOrEmpty(currentContent) || angular.isUndefinedOrEmpty($state.params.slug)) {
                    return;
                }

                var slugFull;
                var contentPath = (parentPath || $state.params.slug).split('/');

                var _this = this;

                if (currentContent.type === 'community') {
                    var contentId = currentContent.id;
                    var navItem = MainNav.findNavItemById(contentId);
                    var hasParentItem = angular.isDefinedAndFilled(loGet(navItem, 'parentId'));

                    while(hasParentItem) {
                        _this.breadcrumb.push(navItem);

                        hasParentItem = angular.isDefinedAndFilled(navItem.parentId);
                        contentId = navItem.parentId;
                        navItem = MainNav.findNavItemById(contentId);
                    }

                    reverse(_this.breadcrumb);
                } else {
                    angular.forEach(contentPath, function forEachContentPathParts(contentPathPart) {
                        if (angular.isDefinedAndFilled(slugFull)) {
                            slugFull += '/' + contentPathPart;
                        } else {
                            slugFull = contentPathPart;
                        }

                        var mainNavItem = MainNav.findNavItemBySlug(slugFull);
                        if (angular.isDefinedAndFilled(mainNavItem)) {
                            _this.breadcrumb.push(mainNavItem);
                        }
                    });
                }

                // If the breadcrumb is empty, try to fetch the first nav item matching the content.
                if (angular.isUndefinedOrEmpty(parentPath) && angular.isUndefinedOrEmpty(this.breadcrumb)) {
                    var navItem = MainNav.findNavItemById(currentContent.id);

                    if (Translation.hasTranslations(_.get(navItem, 'slugFull'))) {
                        this.createBreadcrumb(Translation.translate(navItem.slugFull));
                    }
                }

                // If the breadcrumb is still empty, place the page under the home page.
                if (angular.isUndefinedOrEmpty(this.breadcrumb)) {
                    this.breadcrumb.push(currentContent);
                }
            }

            /**
             * Get the current action.
             *
             * @return {string} The current action.
             */
            function getAction() {
                return this.action;
            }

            /**
             * Get the author of the given content.
             * Note that this will return the full author details object.
             *
             * @param  {Object} content The content we want to get the author of.
             * @return {Object} The author of the content.
             */
            function getAuthor(content) {
                var author = _.get(content, 'content');

                return (angular.isUndefinedOrEmpty(author)) ? _.get(content, 'authorDetails') : author;
            }

            /**
             * Get the current content's breadcrumb.
             *
             * @return {Array} The current content's breadcrumb.
             */
            function getBreadcrumb() {
                return this.breadcrumb;
            }

            /**
             * Get the current content slug from url.
             *
             * @return {string} The current content slug catched in the url.
             */
            function getCurrentContentSlug() {
                var currentContent = this.getCurrent();
                if (angular.isDefinedAndFilled(_.get(currentContent, 'slug'))) {
                    return Translation.translate(currentContent.slug);
                }

                var locationPath = $location.path();
                var splittedPath = locationPath.split('/');
                var isCommunityContext = locationPath.indexOf('/ls/community/') !== -1 || locationPath.indexOf('/ls/group/') !== -1;
                var currentInstanceSlug = Instance.getCurrentInstanceSlug();
                var communityPrefixIndex;

                // For a community, the content slug is prefixed by an `ls/community`.
                if (isCommunityContext) {
                    var instanceIndex = splittedPath.indexOf(currentInstanceSlug);

                    if (splittedPath[instanceIndex + 1] === 'ls') {
                        communityPrefixIndex = instanceIndex + 2;
                    } else {
                        const communityStrIndex = splittedPath.indexOf('community');
                        communityPrefixIndex = communityStrIndex !== -1 ? communityStrIndex : splittedPath.indexOf('group');
                    }
                }

                return (isCommunityContext && angular.isDefined(communityPrefixIndex)) ?
                    splittedPath[communityPrefixIndex + 1] : _.last(splittedPath);
            }

            /**
             * Get the excerpt of a content.
             * The excerpt is taken either from:
             *     - The first HTML widget having the `isIntro` property;
             *     - The first Introduction widget;
             *     - The first HTML widget;
             *     - An empty string;
             *
             * @param  {Object}        content                    The content from which we want to get the excerpt.
             * @param  {boolean}       [preserveTags=false]       Indicates if we want to keep the HTML tags in the
             *                                                    excerpt.
             * @param  {boolean}       [ignoreHtmlFallback=false] Indicates if we don't want to take the first HTML
             *                                                    widget as fallback.
             * @return {string|Object} The excerpt of the content as a string or a translatable object.
             */
            function getExcerpt(content, preserveTags, ignoreHtmlFallback) {
                preserveTags = preserveTags || false;
                ignoreHtmlFallback = ignoreHtmlFallback || false;

                if (angular.isUndefinedOrEmpty(_.get(content, 'template'))) {
                    return '';
                }

                // Get the list of all HTML widgets in the content.
                var htmlWidgets = Widget.findWidgetsByType(content.template, InitialSettings.WIDGET_TYPES.HTML);

                var nonEmptyHtmlWidget = [];
                var introWidgets = [];

                // Compute the lists of non empty HTML widgets and HTML widgets having the `isIntro` property.
                for (var i = 0, len = htmlWidgets.length; i < len; i++) {
                    var htmlWidget = htmlWidgets[i];

                    if (!Translation.hasTranslations(_.get(htmlWidget, 'properties.content'), true)) {
                        continue;
                    }

                    nonEmptyHtmlWidget.push(htmlWidget);

                    // If the non-empty HTML widget is specified as intro widget, only keep this one.
                    if (_.get(htmlWidget, 'properties.isIntro', false)) {
                        introWidgets = [htmlWidget];

                        // We also can empty this array, it will not be usefull anymore.
                        nonEmptyHtmlWidget = [];

                        break;
                    }
                }

                // If we don't have any HTML intro widget, then look for any Introduction widget.
                if (angular.isUndefinedOrEmpty(introWidgets)) {
                    var nonEmptyIntroWidget =
                        _.find(Widget.findWidgetsByType(content.template, InitialSettings.WIDGET_TYPES.INTRO),
                            function findNonEmptyIntroWidget(introWidget) {
                                return Translation.hasTranslations(_.get(introWidget, 'properties.content'), true);
                            }
                        );

                    if (angular.isDefinedAndFilled(nonEmptyIntroWidget)) {
                        introWidgets = [nonEmptyIntroWidget];
                    }
                }

                // If we still don't have any matching widget and we don't ignore HTML widget, take the first HTML one.
                if (!ignoreHtmlFallback && angular.isUndefinedOrEmpty(introWidgets)) {
                    introWidgets = [_.get(nonEmptyHtmlWidget, '[0]', {})];
                }

                /*
                 * `introWidgets[0]` = 1st non-empty HTML widget with property `isIntro` or 1st non-empty introduction
                 * widget or 1st non-empty HTML widget.
                 */
                if (angular.isDefinedAndFilled(_.get(introWidgets, '[0].properties.content'))) {
                    var widgetContent = introWidgets[0].properties.content;

                    if (angular.isObject(widgetContent)) {
                        angular.forEach(widgetContent, function forEachWidgetContent(widgetContentString, lang) {
                            widgetContent[lang] = Utils.replaceTranslatableValues(widgetContentString);

                            if (!preserveTags && angular.isDefinedAndFilled(Utils.stripTags(widgetContentString))) {
                                widgetContent[lang] = _getExcerptString(widgetContentString);
                            }
                        });

                        return widgetContent;
                    }

                    if (!preserveTags && angular.isDefinedAndFilled(Utils.stripTags(widgetContent))) {
                        return _getExcerptString(widgetContent);
                    }

                    return widgetContent;
                }

                return '';
            }

            /**
             * Get the link to the given content.
             * Handle content from another instance too.
             *
             * @param  {Object}  content            The content to get the link of.
             * @param  {boolean} [absolute=true]    Indicates if the wanted link is relative or absolute.
             * @param  {boolean} [toComments=false] Indicates if we want to go to the comments section.
             * @param  {boolean} [sref=false]       Indicates if we want the soft SRef link (for UI-Router) instead of
             *                                      hard HRef.
             * @param  {string}  [instanceSlug]     The slug of the instance where the content we want to reach is.
             * @return {string}  The content link.
             */
            function getLink(content, absolute, toComments, sref, instanceSlug) {
                if (angular.isUndefinedOrEmpty(content)) {
                    return '';
                }

                absolute = (angular.isUndefined(absolute)) ? true : absolute;

                if (Translation.hasTranslations(content.link, true)) {
                    return Translation.translate(content.link) + ((toComments) ? '#comments' : '');
                }

                var slugTranslation = Translation.translate(content.slug);

                var params = {
                    slug: slugTranslation,
                };
                var stateName = this.getStateName(content.type);

                if (content.instance === Instance.getCurrentInstanceId()) {
                    if ($state.current.name === 'app.front.search') {
                        var navItem = MainNav.findNavItemById(content.uid);
                        if (angular.isDefinedAndFilled(navItem)) {
                            params.slug = Translation.translate(navItem.slugFull) || params.slug;
                        }
                    } else {
                        // Kinda hack like in MainNav.goto.
                        var parentNavElement = MainNav.findNavItemBySlug($stateParams.slug);

                        var children = _.get(parentNavElement, 'children', []);
                        var isChildOfCurrentNavElement = angular.isDefinedAndFilled(_.find(children, {
                            id: content.id,
                        }));

                        if (angular.isDefinedAndFilled($stateParams.slug) &&
                            (isChildOfCurrentNavElement || !MainNav.findNavItemById(content.id))) {
                            params.slug = $stateParams.slug + '/' + slugTranslation;
                        }
                    }
                } else {
                    var target = Instance.instanceKeyToInstanceFromSiblings(content.instance, true);

                    if (angular.isDefinedAndFilled(target)) {
                        params.instance = target.slug;
                    }
                }

                if (angular.isDefinedAndFilled(instanceSlug) && angular.isUndefinedOrEmpty(params.instance)) {
                    params.instance = instanceSlug;
                }

                if (toComments) {
                    params['#'] = 'comments';
                }

                if (!sref) {
                    return $state.href(stateName, params, {
                        absolute: absolute,
                    });
                }

                return stateName + '(' + angular.toJson(params) + ')';
            }

            /**
             * If content is not from current instance the link must open in a new tab.
             *
             * @param  {Object} content Content to check.
             * @return {string} The target type.
             */
            function getLinkTarget(content) {
                if (_.get(content, 'instance') === Instance.getCurrentInstanceId() &&
                    !Translation.hasTranslations(_.get(content, 'link', true))) {
                    return _targets.empty;
                }

                if (Instance.getProperty(Config.INSTANCE_PROPERTIES.CONTENT_LIST_LINK_OPEN_NEW_TAB) === 'same_tab' &&
                    !Translation.hasTranslations(_.get(content, 'link', true))) {
                    return _targets.self;
                }

                return _targets.blank;
            }

            /**
             * Provides the content slug with through a promise.
             *
             * @param  {string}  contentId  The id of the content.
             * @param  {string}  instanceId The id of the content's instance.
             * @return {Promise} The promise linked to the `Content.get` request.
             */
            function getContentLink(contentId, instanceId) {
                var abstractContent = this;
                if (angular.isUndefinedOrEmpty([contentId, instanceId], 'some')) {
                    return $q.reject();
                }

                var params = {
                    fields: 'slug,link',
                    instance: instanceId,
                    uid: contentId,
                };

                return $q(function getContentSlug(resolve, reject) {
                    abstractContent.Api.get(params, function onGetSuccess(response) {
                        var contentLink = {
                            'slug': _.get(response, 'slug', {}),
                            'externalLink': _.get(response, 'link', {}),
                        }
                        resolve(contentLink);
                    }, function onGetError(err) {
                        reject(err);
                    });
                });
            }

            /**
             * Get the state name of a content based on its type.
             *
             * @param  {string} contentType The content type we want the state name of.
             * @return {string} The state name corresponding to the content type.
             */
            function getStateName(contentType) {
                switch (contentType) {
                    case InitialSettings.CONTENT_TYPES.COMMUNITY:
                        return 'app.front.community';

                    default:
                        return 'app.front.content-get';
                }
            }

            /**
             * Get the current view mode of the content.
             *
             * @return {string} The current view mode.
             */
            function getViewMode() {
                return this.viewMode;
            }

            /**
             * Get the writer of the given content. If there is no writer, get the author.
             * Note that this will return the full writer/author details object.
             *
             * @param  {Object}         content     The content we want to get the writer/author of.
             * @param  {Object|boolean} [defaultTo] Return a default value if there is no writer or author.
             *                                      You can either pass the default value you want to return or "true"
             *                                      to return the connected user.
             * @return {Object}         The writer (or author).
             */
            function getWriter(content, defaultTo) {
                defaultTo = (defaultTo === true) ? $injector.get('User').getConnected() : defaultTo;

                var writer = _.get(content, 'writerDetails');

                return (angular.isUndefinedOrEmpty(writer)) ?
                    _.get(content, 'authorDetails', (angular.isObject(defaultTo)) ? defaultTo : undefined) : writer;
            }

            /**
             * Get the full name of the writer of the given content. If there is no writer, get the author.
             * Note that this will return the writer/author full name as a string.
             *
             * @param  {Object} content The content we want the writer/author fullname of.
             * @return {string} The writer/author full name.
             */
            function getWriterFullName(content) {
                if (angular.isUndefinedOrEmpty(content)) {
                    return '';
                }

                var User = $injector.get('User');
                var keys = ['firstName', 'lastName'];

                if (Utils.hasMultiple(content.writerDetails, keys)) {
                    return User.getUserFullName(content.writerDetails);
                } else if (Utils.hasMultiple(content.authorDetails, keys)) {
                    return User.getUserFullName(content.authorDetails);
                }

                return '';
            }

            /**
             * Open the given content.
             *
             * @param {Object} content The content top open.
             */
            function goTo(content) {
                if (angular.isUndefinedOrEmpty(content)) {
                    return;
                }

                if (Translation.hasTranslations(content.link, true)) {
                    $window.open(Translation.translate(content.link), '_blank');
                } else {
                    MainNav.goTo('app.front.content-get', content.id, content.instance, content.slug);
                }
            }

            /**
             * Check if a content has been changed.
             * Compare the current content with the previously saved as original one.
             *
             * @return {boolean} If there are changes or not.
             */
            function hasChanges() {
                var currentContent = this.getCurrent();

                var currentContentToCheck = angular.fastCopy(currentContent, undefined, true);

                if (angular.isDefinedAndFilled(currentContentToCheck)) {
                    this.cleanForHasChanges(currentContentToCheck);

                    var originalContentToCheck = angular.fastCopy(this.originalContent, undefined, true);

                    this.cleanForHasChanges(originalContentToCheck);

                    var areEquals = angular.equals(originalContentToCheck, currentContentToCheck);
                    if (!areEquals && $location.search().debug) {
                        console.group('Content changes');
                        $log.debug('Original content:', originalContentToCheck, this.originalContent);
                        $log.debug('Current content:', currentContentToCheck, currentContent);
                        console.groupEnd();
                    }

                    return !areEquals;
                }

                return false;
            }

            /**
             * Check if the user has rights to modify the content and his widgets according to the content status and
             * the user rights.
             *
             * @param  {boolean} [asDraft=false]      Indicates if we want to check if the content is editable as draft.
             * @param  {boolean} [checkContent=false] Indicates if we want to check the rights inside of the content.
             * @return {boolean} If the user has rights to modify the content.
             */
            function isEditable(asDraft, checkContent) {
                asDraft = Boolean(asDraft);
                checkContent = Boolean(checkContent);

                var currentContent = this.getCurrent();

                if (currentContent.type === 'template' || !currentContent.customContentTypeDetails ||
                    !currentContent.customContentTypeDetails.isWorkflowEnabled) {
                    return true;
                } else if (currentContent.customContentTypeDetails.isWorkflowEnabled) {
                    var UserAccess = $injector.get('UserAccess');

                    if (currentContent.status !== Config.CONTENT_STATUS.ARCHIVE.value &&
                        (currentContent.status === Config.CONTENT_STATUS.DRAFT.value ||
                            (currentContent.status === Config.CONTENT_STATUS.TO_VALIDATE.value && asDraft) ||
                            currentContent.status === Config.CONTENT_STATUS.LIVE.value) &&
                        UserAccess.isUserAllowed('CUSTOM_CONTENT_EDIT', {
                            checkContent: checkContent,
                            content: currentContent,
                            customContentTypeId: currentContent.customContentType,
                        })) {
                        // User with edit rights can manage the content.
                        // If the content is in DRAFT or PUBLISH modex.
                        return true;
                    }
                }

                return false;
            }

            /**
             * Check if the workflow is enabled for the current content.
             *
             * @param  {Object}  content The content to check.
             * @return {boolean} If the workflow is enabled for the current content.
             */
            function isWorkflowEnabled(content) {
                var disallowedContentTypes = [
                    InitialSettings.CONTENT_TYPES.CUSTOM_LIST,
                    InitialSettings.CONTENT_TYPES.DIRECTORY,
                ];

                return _.get(content, 'customContentTypeDetails.isWorkflowEnabled', false) &&
                    !_.includes(disallowedContentTypes, content.type);
            }

            /**
             * The post get hook function. Called everytime a "get" call resolves.
             * Convert the content from the backend format to a format more usable by the frontend.
             *
             * @param  {Object}  content               The content returned by the "get" call (backend format).
             * @param  {boolean} [saveAsOriginal=true] Indicates if we want to remember this content as the original
             *                                         one.
             *                                         This is used to avoid that the call made by the `postList` hook
             *                                         overrides the already remembered original content.
             * @return {Object}  The content transformed to be usable by the frontend (frontend format).
             */
            function postGet(content, saveAsOriginal) {
                return this._postGet(content, saveAsOriginal);
            }

            /**
             * Register the pre-save hook function.
             *
             * @param {Function} hook The function to register as pre-save hook.
             */
            function registerPreSaveMethod(hook) {
                _preSaveMethod = (angular.isFunction(hook)) ? hook : _.identity;
            }

            /**
             * Set the current action.
             *
             * @param {string} newAction The new action to set as current.
             */
            function setAction(newAction) {
                this.action = newAction;
            }

            /**
             * Set the current view mode of the content.
             *
             * @param {string} newViewMode The new view mode to set as current.
             */
            function setViewMode(newViewMode) {
                this.viewMode = newViewMode;
            }

            /**
             * Remove the pre-save hook function.
             */
            function unregisterPreSaveMethod() {
                _preSaveMethod = _.identity;
            }

            /**
             * Updates a content status. Available content statuses can be found in the `Config.CONTENT_STATUS`
             * constant.
             *
             * @param {string}   contentId The id of the content we want to update the status of.
             * @param {string}   status    The new status.
             * @param {string}   message   The message that goes with the status.
             * @param {Function} [cb]      The function to execute when the update status is successfull.
             * @param {Function} [errCb]   The function to execute if there is an error with the status update.
             */
            function updateStatus(contentId, status, message, cb, errCb) {
                cb = cb || angular.noop;
                errCb = errCb || angular.noop;

                var methodName = Utils.toCamelCase(status);

                // For some statuses, a function is directly available in the Api (i.e: archive/unarchive).
                var method = (angular.isFunction(this.Api[methodName])) ? this.Api[methodName] : this.Api.updateStatus;

                var params = {
                    comment: message,
                    status: status,
                    uid: contentId,
                };

                method(params, cb, errCb);
            }

            /**
             * Returns the current contribution mode of the designer.
             * The new simple mode has 3 contribution modes.
             * Those modes are depending on various legacy variables bound to the template 
             * that we don't want to refactor for now.
             * 
             * @returns {'WRITER_MODE'|'BUILDER_MODE'|'EXPERT_MODE'|'READER_MODE'}
             */
            function getCurrentContributionMode() {
                const viewMode = this.getViewMode();
                const currentContent = this.getCurrent();

                if (viewMode === 'locked') {
                    return 'READER_MODE';
                } else if (viewMode === 'basic' || viewMode === 'simple') {
                    return 'WRITER_MODE';
                } else if (viewMode === 'default' && currentContent.template.fixedLayout && !Features.hasFeature('new-contribution-experience')) {
                    return 'BUILDER_MODE';
                } else if (viewMode === 'default' && (!currentContent.template.fixedLayout || Features.hasFeature('new-contribution-experience'))) {
                    return 'EXPERT_MODE';
                }
            }

            /**
             * Returns the current display mode of the designer. Will return undefined if not in designer mode.
             * 
             * @returns {'SIMPLE_LEGACY'|'FULL_LEGACY_DESIGNER'|'NEW_SIMPLE_READER_MODE'|'NEW_SIMPLE_WRITER_MODE'|'NEW_SIMPLE_BUILDER_MODE' | 'NEW_SIMPLE_EXPERT_MODE' | undefined}
             */
            function getDesignerDisplayMode() {
                if (!Utils.isDesignerMode()) {
                    return;
                }

                const currentContent = this.getCurrent();

                // The new simple template FF will replace the simple legacy mode by the new one.
                const isNewSimpleTemplateFFEnabled = Features.hasFeature('new-simple-template-experience');
                // The new contribution experience FF will force the use of the simple mode over the full designer mode (legacy).
                const isNewContributionExperienceFFEnabled = Features.hasFeature('new-contribution-experience');

                // If new simple template FF is disabled
                // view mode determines if the content should be displayed in simple mode or in full designer mode.
                // In legacy, the view mode is not always linked to the current template mode, so we can't use the 
                // template.basicMode value.
                if (!isNewSimpleTemplateFFEnabled) {
                    return this.getViewMode() === 'basic' ? 'SIMPLE_LEGACY' : 'FULL_LEGACY_DESIGNER';
                }

                // If the new contribution experience is enabled, we display as simple mode, independently 
                // from the template.basicMode value.
                if (isNewContributionExperienceFFEnabled) {
                    // Simple mode should apply only for content in edition and creation mode
                    const isContent = currentContent.type === 'page' || currentContent.type === 'news' || currentContent.type === 'custom';
                    const isCreatingOrEditing = this.getAction() === 'edit' || this.getAction() === 'create';
                    
                    if (isContent && isCreatingOrEditing) {
                        // New simple mode has 3 different contribution mode.
                        const contributionMode = this.getCurrentContributionMode();
                        return `NEW_SIMPLE_${contributionMode}`;
                    }

                    // If we are in a use case that doesn't support the simple mode, 
                    // we fallback in full designer mode.
                    return 'FULL_LEGACY_DESIGNER';
                } else {
                    // If the FF is disabled, we need to check the template.basicMode status to determine the mode.
                    if (currentContent.template.basicMode) {
                        const contributionMode = this.getCurrentContributionMode();
                        return `NEW_SIMPLE_${contributionMode}`;
                    }
                    return 'FULL_LEGACY_DESIGNER';
                }
            }

            /**
             * Whether the current designer mode matches the one passed as an argument.
             * @param {'SIMPLE_LEGACY' | 'FULL_LEGACY_DESIGNER' | 'NEW_SIMPLE_WRITER_MODE' | 'NEW_SIMPLE_BUILDER_MODE' | 'NEW_SIMPLE_EXPERT_MODE'} mode 
             * @returns {boolean}
             */
            function isCurrentDesignerMode(mode) {
                return this.getDesignerDisplayMode() === mode;
            }

            /**
             * Whether the designer uses the new simple template.
             * @returns boolean 
             */
            function isDesignerInNewSimpleMode() {
                return this.getDesignerDisplayMode()?.startsWith('NEW_SIMPLE');
            }

            /**
             * Set the right view mode and the right template mode according to the given contribution mode.
             *
             * @param {'writer'|'builder'|'expert'} mode The contribution mode.
             */
            function setContributionMode(mode) {
                if (mode === 'writer') {
                    this.setViewMode('basic');

                    this.getCurrent().template.fixedLayout = true;
                    this.getCurrent().template.fixedWidgets = true;

                    $rootScope.$broadcast('contribution-mode', 'writer');
                } else if (mode === 'builder') {
                    this.setViewMode('default');

                    this.getCurrent().template.fixedLayout = true;
                    this.getCurrent().template.fixedWidgets = false;

                    $rootScope.$broadcast('contribution-mode', 'builder');
                } else if (mode === 'expert') {
                    this.setViewMode('default');

                    this.getCurrent().template.fixedLayout = false;
                    this.getCurrent().template.fixedWidgets = false;

                    $rootScope.$broadcast('contribution-mode', 'expert');
                }
            }

            /////////////////////////////

            AbstractContent.prototype.bindCallback = bindCallback;
            AbstractContent.prototype.cleanForHasChanges = cleanForHasChanges;
            AbstractContent.prototype.createBreadcrumb = createBreadcrumb;
            AbstractContent.prototype.getAction = getAction;
            AbstractContent.prototype.getAuthor = getAuthor;
            AbstractContent.prototype.getBreadcrumb = getBreadcrumb;
            AbstractContent.prototype.getCurrentContentSlug = getCurrentContentSlug;
            AbstractContent.prototype.getExcerpt = getExcerpt;
            AbstractContent.prototype.getLink = getLink;
            AbstractContent.prototype.getLinkTarget = getLinkTarget;
            AbstractContent.prototype.getContentLink = getContentLink;
            AbstractContent.prototype.getStateName = getStateName;
            AbstractContent.prototype.getViewMode = getViewMode;
            AbstractContent.prototype.getWriter = getWriter;
            AbstractContent.prototype.getWriterFullName = getWriterFullName;
            AbstractContent.prototype.goTo = goTo;
            AbstractContent.prototype.hasChanges = hasChanges;
            AbstractContent.prototype.isEditable = isEditable;
            AbstractContent.prototype.isWorkflowEnabled = isWorkflowEnabled;
            AbstractContent.prototype.postGet = postGet;
            AbstractContent.prototype.registerPreSaveMethod = registerPreSaveMethod;
            AbstractContent.prototype.setAction = setAction;
            AbstractContent.prototype.setViewMode = setViewMode;
            AbstractContent.prototype.unregisterPreSaveMethod = unregisterPreSaveMethod;
            AbstractContent.prototype.updateStatus = updateStatus;
            AbstractContent.prototype.getCurrentContributionMode = getCurrentContributionMode;
            AbstractContent.prototype.getDesignerDisplayMode = getDesignerDisplayMode;
            AbstractContent.prototype.isCurrentDesignerMode = isCurrentDesignerMode;
            AbstractContent.prototype.isDesignerInNewSimpleMode = isDesignerInNewSimpleMode;
            AbstractContent.prototype.setContributionMode = setContributionMode;

            /////////////////////////////

            /**
             * Initialize the service.
             */
            AbstractContent.prototype.init = function init() {
                this.defaultParams = {
                    customerId: Customer.getCustomerId(),
                    instanceId: Instance.getCurrentInstanceId(),
                    lang: Translation.getPreferredContributionLanguage(),
                };
            };

            /////////////////////////////

            return AbstractContent;
        })();

        /////////////////////////////

        /**
         * Instantiate an abstract content service.
         *
         * @param  {$Resource}       Api       The API to use with the service.
         * @param  {Object}          [options] The options of the service.
         * @return {AbstractContent} The newly created abstract content service.
         */
        function createAbstractContentService(Api, options) {
            options = options || {
                autoInit: false,
                objectIdentifier: 'uid',
            };

            return new AbstractContent(Api, options);
        }

        /////////////////////////////

        return {
            createAbstractContentService: createAbstractContentService,
            proto: AbstractContent,
        };
    }

    /////////////////////////////

    angular.module('Services').service('AbstractContent', AbstractContentService);
})();
