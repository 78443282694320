/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';

/**
 * Event API.
 *
 * @see https://lumapps-api.stoplight.io/docs/lumapps-private-api/a1fc727ccd25b-contribution-api-v2-article-and-event
 */
const eventApi = new BaseApi({ version: BaseApi.versions.v2 });

/** API parameterized paths. */
const paths = {
    eventById: (eventId: string) => `events/${eventId}`,
    eventLayoutById: (eventId: string) => `events/${eventId}/layout`,
    events: 'events',
    eventSelfRegistration: (eventId: string) => `events/${eventId}/self-registration`,
    eventGuests: (eventId: string) => `events/${eventId}/guests`,
    eventDownloadGuestsList: (eventId: string) => `events/${eventId}/guests-file`,
    eventDownloadAttendanceList: (eventId: string) => `events/${eventId}/attendance-file`,
    eventsInContainer: 'events-in-container',
    updateEventInContainer: (eventId: string) => `events-in-container/${eventId}`,
    sendEventInvitation: (eventId: string) => `events/${eventId}/invitations`,
    sendEventUpdateNotification: (eventId: string) => `events/${eventId}/notifications?notificationType=event_updated`,
    updateAttendance: (eventId: string, userId: string) => `events/${eventId}/guests/${userId}`,
};

export { eventApi, paths };
