export enum WREX_GENERATED_CONTENT {
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409282486 */
    MENU_REFINE_STYLE = 'WREX_AI.MENU_REFINE_STYLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409290827 */
    MENU_REFINE_TONE = 'WREX_AI.MENU_REFINE_TONE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409291932 */
    MENU_REFINE_SHORTEN = 'WREX_AI.MENU_REFINE_SHORTEN',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409291967 */
    MENU_REFINE_LENGTHEN = 'WREX_AI.MENU_REFINE_LENGTHEN',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409328803 */
    MODIFY_SELECTION_SECTION = 'WREX_AI.MENU_MODIFY_SELECTION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409733956 */
    GENERATING_RESULT = 'WREX_AI.GENERATING_RESULT',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409893990 */
    REPLACE_SELECTION = 'WREX_AI.REPLACE_SELECTION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409893941 */
    IGNORE_SUGGESTION = 'WREX_AI.IGNORE_SUGGESTION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=420711590 */
    GIVE_A_TASK = 'WREX_AI.GIVE_A_TASK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409292163
     */
    REVISE_SELECTION = 'AI.REVISE_SELECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=511623216
     */
    AI_EDITOR_TOOLBAR_LABEL = 'WREX_AI.AI_EDITOR_TOOLBAR_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=501657158
     */
    OR_ASK_ANYTHING = 'AI.OR_ASK_ANYTHING',
}
