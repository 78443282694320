import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox, FlexBoxProps, Orientation, Size, Theme } from '@lumapps/lumx/react';
import { useDimensions } from '@lumapps/responsive';

import { Author } from './Author';
import { BlockPlaylistContextProvider } from './BlockPlaylistContext';
import { Button } from './Button';
import { CLASSNAME } from './constants';
import { Content } from './Content';
import { Date as PlaylistDate } from './Date';
import { Description } from './Description';
import { Figure } from './Figure';
import { Link } from './Link';
import { Metadata } from './Metadata';
import { SiteDetails } from './SiteDetails';
import { Status } from './Status';
import { Thumbnail } from './Thumbnail';
import { Title } from './Title';
import type { Playlist, VisibleElement } from './types';
import { VideosCount } from './VideosCount';

const breakpoints: Record<Exclude<Orientation, 'auto'>, number> = { vertical: 0, horizontal: 550 };

type BaseBlockPlaylistProps = Pick<FlexBoxProps, 'as' | 'className' | 'orientation'> & {
    /** Vertical alignment. */
    alignment?: FlexBoxProps['hAlign'];

    /** Component children */
    children: React.ReactNode;

    /** Whether the component is pending or not (will display components skeleton) */
    isLoading?: boolean;

    /** UI theme */
    theme?: Theme;

    /** Playlist */
    playlist?: Playlist;

    /** Elements to display */
    visibleElements?: VisibleElement[];
};

type ReadyBlockPlaylistProps = BaseBlockPlaylistProps & { isLoading?: false; playlist: Playlist };

type LoadingBlockPlaylistProps = BaseBlockPlaylistProps & { isLoading: true };

export type BlockPlaylistProps = ReadyBlockPlaylistProps | LoadingBlockPlaylistProps;

export const BlockPlaylist = ({
    as = 'article',
    alignment,
    children,
    className,
    isLoading,
    playlist,
    orientation: orientationProp,
    theme = Theme.light,
    visibleElements,
}: BlockPlaylistProps) => {
    const { block } = useClassnames(CLASSNAME);

    const { ref, currentBreakpoint: autoOrientation } = useDimensions({ breakpoints });

    if (Array.isArray(visibleElements) && !visibleElements.length) {
        return null;
    }

    const orientation = orientationProp ?? autoOrientation;

    return (
        <BlockPlaylistContextProvider
            orientation={orientation}
            theme={theme}
            visibleElements={visibleElements}
            // Calculating props this way is needed to make Typescript happy 😬
            {...(isLoading ? { isLoading } : { playlist })}
        >
            <FlexBox
                as={as}
                className={block([className])}
                gap={Size.big}
                orientation={orientation}
                ref={ref}
                hAlign={alignment}
            >
                {children}
            </FlexBox>
        </BlockPlaylistContextProvider>
    );
};

BlockPlaylist.Author = Author;
BlockPlaylist.Button = Button;
BlockPlaylist.Content = Content;
BlockPlaylist.Date = PlaylistDate;
BlockPlaylist.Description = Description;
BlockPlaylist.Figure = Figure;
BlockPlaylist.Link = Link;
BlockPlaylist.Metadata = Metadata;
BlockPlaylist.Status = Status;
BlockPlaylist.Thumbnail = Thumbnail;
BlockPlaylist.SiteDetails = SiteDetails;
BlockPlaylist.Title = Title;
BlockPlaylist.VideosCount = VideosCount;
