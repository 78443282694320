import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ReactEditor, useSlateStatic, useSelected } from '@lumapps/wrex/slate';
import { useInlineVoid } from '@lumapps/wrex/slate/utils/useInlineVoid';
import { ElementRender } from '@lumapps/wrex/types';

import { EmbeddedVideoEditor, EmbeddedVideoElement } from '../../../types';
import { CLASSNAME, EmbeddedVideo } from '../../blocks/EmbeddedVideo';
import { DeleteButton } from '../../buttons/DeleteButton';

import './index.scss';

export const EditableEmbeddedVideo: ElementRender<EmbeddedVideoElement, HTMLDivElement> = ({
    element = {} as EmbeddedVideoElement,
    children,
    ...forwardedProps
}) => {
    const editorStatic = useSlateStatic() as ReactEditor & EmbeddedVideoEditor;
    const selected = useSelected();
    const { onFocus, onDelete } = useInlineVoid(editorStatic, element);
    const { block } = useClassnames(CLASSNAME);

    return (
        <EmbeddedVideo
            className={block({
                editable: true,
                selected,
            })}
            element={element}
            onFocus={onFocus}
            tabIndex={0}
            {...forwardedProps}
        >
            <DeleteButton onDelete={onDelete} />
            {children}
        </EmbeddedVideo>
    );
};

EditableEmbeddedVideo.displayName = 'embedded-video';
