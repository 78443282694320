import React from 'react';

import { padding } from '@lumapps/classnames';
import { FormDialog } from '@lumapps/lumx-forms/components/FormDialog';
import { FormTextField } from '@lumapps/lumx-forms/components/FormTextField';
import { mdiLinkBoxVariantOutline } from '@lumapps/lumx/icons';
import { FlexBox, Kind, Message, Text } from '@lumapps/lumx/react';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { UserMicrosoftToken } from '@lumapps/user/interface';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { ONEDRIVE_PICKER_KEYS } from '../../../keys';
import { OneDriveDocument, OneDrivePickerConfiguration } from '../../../types';
import { fetchDocument } from '../../../utils/fetchDocument';

interface OneDrivePickerProps {
    /** User MS token */
    token?: UserMicrosoftToken | Promise<UserMicrosoftToken | undefined>;
    /** OneDrivePicker configuration object */
    pickerConfig: OneDrivePickerConfiguration;
    /** whether the picker is open or not */
    isOpen: boolean;
    /** callback on close */
    onClose(): void;
    /** callback on selection */
    onSelect(files: OneDriveDocument): void;
}

/**
 * OneDrivePicker display allowing only folder url
 * */
export const OneDriveFolderPicker: React.FC<OneDrivePickerProps> = ({
    isOpen,
    onClose,
    token,
    onSelect,
    pickerConfig,
}) => {
    const { translateKey } = useTranslate();
    const { error } = useNotification();
    const [baseLoadingStatus, setBaseLoadingStatus] = React.useState<BaseLoadingStatus>(BaseLoadingStatus.initial);

    const submitForm = React.useCallback(
        async ({ url }: { url: string }) => {
            try {
                setBaseLoadingStatus(BaseLoadingStatus.loading);
                const document = await fetchDocument({ url, token, pickerConfig });

                onSelect(document);
            } catch (e) {
                error({ translate: e instanceof Error ? e.message : GLOBAL.GENERIC_ERROR });
            } finally {
                setBaseLoadingStatus(BaseLoadingStatus.idle);
            }
        },
        [error, onSelect, pickerConfig, token],
    );

    const dialogProps = React.useMemo(
        () => ({ isLoading: baseLoadingStatus === BaseLoadingStatus.loading }),
        [baseLoadingStatus],
    );
    const formActionProps = {
        saveProps: {
            children: translateKey(GLOBAL.ADD),
        },
    };

    return (
        <FormDialog
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={submitForm}
            dialogProps={dialogProps}
            formActionProps={formActionProps}
            heading={
                <Text as="h3" typography="title">
                    {translateKey(ONEDRIVE_PICKER_KEYS.TITLE)}
                </Text>
            }
            scope="onedrive-picker"
        >
            <FlexBox as="section" orientation="vertical" gap="big">
                <FormTextField
                    name="url"
                    label={translateKey(ONEDRIVE_PICKER_KEYS.TEXT_FIELD)}
                    placeholder={translateKey(ONEDRIVE_PICKER_KEYS.TEXT_FIELD_PLACEHOLDER)}
                    isRequired
                    icon={mdiLinkBoxVariantOutline}
                    clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
                />
                <Message kind={Kind.info} hasBackground>
                    <Text as="h2" typography="body1">
                        {translateKey(ONEDRIVE_PICKER_KEYS.MESSAGE_TITLE)}
                    </Text>
                    <ol className={padding('left', 'big')}>
                        <li>
                            <Text as="p" typography="body1">
                                {translateKey(ONEDRIVE_PICKER_KEYS.MESSAGE_STEP_ONE)}
                            </Text>
                        </li>
                        <li>
                            <Text as="p" typography="body1">
                                {translateKey(ONEDRIVE_PICKER_KEYS.MESSAGE_STEP_TWO)}
                            </Text>
                        </li>
                        <li>
                            <Text as="p" typography="body1">
                                {translateKey(ONEDRIVE_PICKER_KEYS.MESSAGE_STEP_THREE)}
                            </Text>
                        </li>
                    </ol>
                </Message>
            </FlexBox>
        </FormDialog>
    );
};
