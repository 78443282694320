import React from 'react';

import { color, margin, useClassnames, classnames } from '@lumapps/classnames';
import {
    AspectRatio,
    Theme,
    Thumbnail,
    Text,
    Size,
    GenericBlock,
    Orientation,
    ThumbnailVariant,
    Link,
    UserBlockSize,
    ColorPalette,
    ColorVariant,
    Typography,
} from '@lumapps/lumx/react';
import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { InlineReactions } from '@lumapps/reactions-core/components/InlineReactions';
import { Link as RouterLink } from '@lumapps/router/components/Link';
import { BlockUser } from '@lumapps/widget-base/components/Block/BlockUser';

import { useArticlePreview } from '../../hooks/useArticlePreview';
import { ArticlePreview as ArticlePreviewTypes } from '../../types';

export interface ArticlePreviewProps {
    theme?: Theme;
    article: ArticlePreviewTypes;
    isLegacy?: boolean;
}

const CLASSNAME = 'article-preview';

/**
 * Component that displays an article as a preview
 *
 * @family Blocks
 * @param ArticlePreviewProps
 * @returns ArticlePreview
 */
export const ArticlePreview = ({ theme = Theme.light, article, isLegacy }: ArticlePreviewProps) => {
    const { articleRoute, image, title, intro, author } = useArticlePreview(article, isLegacy);
    const { element } = useClassnames(CLASSNAME);

    return (
        <GenericBlock
            as="article"
            theme={theme}
            orientation={Orientation.vertical}
            className={color(
                'background',
                theme === Theme.light ? ColorPalette.dark : ColorPalette.light,
                ColorVariant.L6,
            )}
        >
            <GenericBlock.Figure>
                {image ? (
                    <Thumbnail
                        image={makeSecuredMediaURLRelative(image?.url) as string}
                        alt={image?.alt || ''}
                        aspectRatio={AspectRatio.wide}
                        variant={ThumbnailVariant.squared}
                        linkAs={RouterLink}
                        linkProps={{ to: articleRoute, tabIndex: -1, target: '_blank' } as any}
                        className={element('image-block')}
                    />
                ) : undefined}
            </GenericBlock.Figure>

            <GenericBlock.Content
                gap={Size.tiny}
                className={classnames(margin('horizontal', Size.big), margin('bottom', Size.big), {
                    [margin('top', Size.big)]: !image,
                })}
            >
                {author && (
                    <BlockUser
                        theme={theme}
                        fullName={author.fullName}
                        userId={author.userId}
                        email={author.email}
                        picture={author.pictureUrl}
                        avatarSize={Size.s as UserBlockSize}
                    />
                )}

                <Link
                    linkAs={RouterLink}
                    to={articleRoute}
                    className={element('link-article')}
                    color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
                    colorVariant={ColorVariant.N}
                    typography={Typography.subtitle2}
                    target="_blank"
                >
                    <Text as="span">{title || ''}</Text>
                </Link>

                <Text
                    as="span"
                    typography={Typography.body1}
                    truncate
                    color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
                    colorVariant={ColorVariant.N}
                >
                    {intro || ''}
                </Text>

                {article.reactionCounts && article.commentCount !== undefined && (
                    <InlineReactions
                        likes={article.reactionCounts?.total}
                        comments={article.commentCount}
                        theme={theme}
                    />
                )}
            </GenericBlock.Content>
        </GenericBlock>
    );
};
