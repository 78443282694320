import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { ServerListResponse } from '@lumapps/base-api';

import { BaseLoadingStatus } from '../types/BaseLoadingStatus';
import { FetchCallback, FetchPropsWithoutCallback } from './useFetchWithStatus';
import { usePaginatedFetchWithStatus, UsePaginatedFetchWithStatusProps } from './usePaginatedFetchWithStatus';

export interface UseServerList<T, P> {
    /** whether the items are loading or not */
    isLoading: boolean;
    /** whether server list is in initial state or not */
    isInitial: boolean;
    /** whether there was an error while loading the items */
    hasLoadingError: boolean;
    /** whether there are more results to the current list of items */
    hasMoreResults: boolean;
    /** whether it is loading more items */
    isLoadingMore: boolean;
    /** whether the hook is waiting for the user to finish its input to perform a search */
    isDebouncing: boolean;
    /** current search text applied */
    searchText?: string;
    /** list of items */
    items?: T[];
    /** callback to fetch the list of items */
    fetch: (params?: Partial<P>) => void;
    /** callback to load more items */
    fetchMore: (params?: Partial<P>) => void;
    /** callback to search items by a search query */
    onSearch: (searchText: string, triggerApiCall?: boolean, params?: Partial<P>) => void;
    /** loading status */
    status: BaseLoadingStatus;
    /** callback for resetting state */
    reset: () => void;
    /** callback to add an item and update the response state */
    add: (item: T) => void;
    /** callback to delete an item and update the response state */
    remove: (itemAt: number) => void;
    /** callback to delete a list of items and update the response state */
    removeItems: (items: T[]) => void;
    /** callback to update an item */
    update: (item?: T) => void;
}

export interface UseServerListOptions<T, P = any> extends UsePaginatedFetchWithStatusProps<ServerListResponse<T>> {
    params?: Partial<P>;
    /**
     * The query param name in the request payload used to do a search.
     *
     * @default 'query'
     */
    searchParamName?: string;
    /**
     * callback to be executed once the new items are fetched
     */
    callback?: (response: ServerListResponse<T>, fetchParams: FetchPropsWithoutCallback) => void;
    /**
     * Retrieves the id of an item in order to distinguish items between each other. Mandatory if you are using the
     * `removeItems` callback.
     */
    getItemId?: (item: T) => string;
    fetchOnParamsChange?: boolean;
}

/**
 * Generic hook that retrieves a list of items, filter and load more of them.
 *
 * @returns UseServerList
 * @deprecated use React Query's `useInfiniteQuery`
 */
export const useServerList = <T, P>({
    params,
    searchParamName = 'query',
    callback,
    getItemId,
    fetchOnParamsChange,
    ...props
}: UseServerListOptions<T, P>): UseServerList<T, P> => {
    const [searchValue, setSearchValue] = React.useState<string | undefined>();
    const currentParams = React.useRef(params);
    const { fetch, status, response, errorResponse, reset, update } = usePaginatedFetchWithStatus<
        ServerListResponse<T>
    >({
        ...props,
        initialFetchParams: props.fetchOnMount ? { params } : undefined,
    });

    const onFetchCallback: FetchCallback<ServerListResponse<T>> = React.useCallback(
        ({ success, response }, fetchParams) => {
            if (callback && success && response) {
                callback(response, fetchParams);
            }
        },
        [callback],
    );

    const loading = status === BaseLoadingStatus.loading;
    const isLoadingMore = status === BaseLoadingStatus.loadingMore;

    const onLoadMore = React.useCallback(
        (parameters?: Partial<P>) => {
            if (!loading && !isLoadingMore) {
                fetch({
                    params: {
                        ...parameters,
                        ...params,
                        [searchParamName]: searchValue,
                        cursor: response?.cursor,
                    },
                    fetchMore: true,
                    callback: onFetchCallback,
                });
            }
        },
        [fetch, isLoadingMore, loading, onFetchCallback, params, response?.cursor, searchParamName, searchValue],
    );

    /**
     * Callback for searching items manually.
     *
     * @param searchText - text to search
     */
    const onSearch = React.useCallback<UseServerList<T, P>['onSearch']>(
        // eslint-disable-next-line default-param-last
        (searchText, triggerApiCall = true, parameters) => {
            const value = searchText === '' ? undefined : searchText;

            if (triggerApiCall) {
                fetch({
                    debounce: true,
                    params: {
                        ...params,
                        ...parameters,
                        [searchParamName]: value,
                        cursor: undefined,
                    },
                    callback: onFetchCallback,
                });
            }

            setSearchValue(value);
        },
        [fetch, onFetchCallback, params, searchParamName],
    );

    /**
     * Callback for fetching more items manually.
     * Will only work if there are more items in the first response received.
     *
     * @param parameters - parameters to override
     */
    const fetchMore = React.useCallback<UseServerList<T, P>['fetchMore']>(
        (parameters) => {
            if (response?.more) {
                onLoadMore(parameters);
            }
        },
        [onLoadMore, response?.more],
    );

    /**
     * Callback for fetching items manually.
     *
     * @param parameters - parameters to override
     */
    const fetchItems = React.useCallback(
        (parameters?: Partial<P>) => {
            fetch({
                params: {
                    ...params,
                    ...parameters,
                },
                callback: onFetchCallback,
            });
        },
        [fetch, onFetchCallback, params],
    );

    /**
     * Callback to add an item to the list of fetched items.
     * Note that this callback only adds it from the in-memory list, please
     * make sure that you have added it with the appropriate API calls.
     *
     * @param item - item to add
     */
    const addItem = (item: T) => {
        if (response) {
            const newItems = response && response.items ? [...response.items, item] : [];
            update({
                ...response,
                items: newItems,
            });
        }
    };

    /**
     * Callback to remove an item from the list of fetched items.
     * Note that this callback only removes it from the in-memory list, please
     * make sure that you have removed it with the appropriate API calls.
     *
     * @param itemAt - the position for the item that will be removed.
     */
    const removeItem = (itemAt: number) => {
        if (response) {
            const items = response.items || [];
            if (itemAt < items.length) {
                const deletedItem = items.splice(itemAt, 1);
                if (!isEmpty(deletedItem)) {
                    update({
                        ...response,
                        items: response.items.filter((item) => item !== deletedItem[0]),
                    });
                }
            }
        }
    };

    /**
     * Callback to remove items from the list of fetched items.
     * Note that this callback only removes them from the in-memory list, please
     * make sure that you have removed them with the appropriate API calls.
     *
     * @param itemsToRemove - elements to remove
     */
    const removeItems = (itemsToRemove: T[]) => {
        if (response && getItemId) {
            const items = response.items || [];
            const itemsToRemoveIds = itemsToRemove.map(getItemId);

            update({
                ...response,
                items: items.filter((item) => !itemsToRemoveIds.includes(getItemId(item))),
            });
        }
    };

    /**
     * Callback for updating a specific item. Note that this callback only updates the item in the in-memory list, please
     * make sure that you have updated it with the appropriate API calls.
     * @param itemToUpdate
     */
    const updateItem = (itemToUpdate: T) => {
        if (response && getItemId) {
            const items = response.items || [];
            const itemToUpdateId = getItemId(itemToUpdate);
            const itemToUpdateIndex = items.findIndex((item) => getItemId(item) === itemToUpdateId);

            if (itemToUpdateIndex >= 0) {
                items[itemToUpdateIndex] = itemToUpdate;
            }

            update({
                ...response,
                items,
            });
        }
    };

    React.useEffect(() => {
        const areParamsEqual = isEqual(params, currentParams.current);

        if (fetchOnParamsChange && status === BaseLoadingStatus.idle && currentParams.current && !areParamsEqual) {
            fetchItems(params);

            currentParams.current = params;
        }
    }, [fetchItems, fetchOnParamsChange, params, status]);

    return {
        isLoading: loading,
        isDebouncing: status === BaseLoadingStatus.debouncing,
        isInitial: status === BaseLoadingStatus.initial,
        hasLoadingError: Boolean(errorResponse),
        hasMoreResults: Boolean(response?.more),
        onSearch,
        isLoadingMore,
        fetchMore,
        searchText: searchValue,
        items: response?.items,
        fetch: fetchItems,
        status,
        reset,
        add: addItem,
        update: (item?: T) => {
            if (item) {
                updateItem(item);
            } else if (response) {
                update({
                    ...response,
                    items: response.items,
                });
            }
        },
        remove: removeItem,
        removeItems,
    };
};
