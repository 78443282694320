import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useImageLightbox, ImageLightbox } from '@lumapps/lumx-images/components/ImageLightbox';
import { AspectRatio, Slideshow, SlideshowItem, Theme, Thumbnail, ThumbnailProps, useTheme } from '@lumapps/lumx/react';
import { MEDIAS } from '@lumapps/medias/keys';
import { useTranslate } from '@lumapps/translations';

import './index.scss';

export interface SlideshowViewProps {
    /** List of images */
    images: ThumbnailProps[];
    /** Whether is loading */
    isLoading?: boolean;
    /** If component is editable */
    isEditable?: boolean;
}

const CLASSNAME = 'slideshow-view';

/** Component for a display image gallery in view mode slideshow
 *
 * @param SlideshowViewProps
 * @returns SlideshowView
 */
export const SlideshowView: React.FC<SlideshowViewProps> = ({ images, isLoading, isEditable }) => {
    const { translateKey } = useTranslate();
    const { element, block } = useClassnames(CLASSNAME);
    const theme = useTheme() || Theme.light;
    const { getTriggerProps, imageLightboxProps } = useImageLightbox(images);

    const getContextTriggerProps = React.useCallback(
        (activeImageIndex: number) => {
            if (isEditable) {
                return undefined;
            }
            return getTriggerProps({ activeImageIndex });
        },
        [isEditable, getTriggerProps],
    );

    return (
        <>
            <Slideshow
                className={block()}
                slideshowControlsProps={{
                    nextButtonProps: { label: translateKey(MEDIAS.NEXT_IMAGE) },
                    previousButtonProps: { label: translateKey(MEDIAS.PREVIOUS_IMAGE) },
                }}
                fillHeight
                theme={theme}
            >
                {images?.map((image, index) => (
                    <SlideshowItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={element('slideshow-item')}
                    >
                        <Thumbnail
                            {...image}
                            className={element('image')}
                            isLoading={isLoading}
                            {...getContextTriggerProps(index)}
                            aspectRatio={AspectRatio.wide}
                            theme={theme}
                        />
                    </SlideshowItem>
                ))}
            </Slideshow>

            {!isEditable && <ImageLightbox {...imageLightboxProps} />}
        </>
    );
};
