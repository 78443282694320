import React, { useState } from 'react';

import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { useLazyLoadingProgress } from '@lumapps/utils/hooks/useLazyLoadingProgress';

export function useLoadSlackShareDialog() {
    const [isShareToSlackDialog, openShareToSlackDialog, closeShareToSlackDialog] = useBooleanState(false);
    const [slackIsLoaded, setSlackIsLoaded] = useState(false);
    const { onComponentLoaded: onSlackLoaded } = useLazyLoadingProgress(slackIsLoaded);
    const handleOpenSlack = React.useCallback(() => {
        setSlackIsLoaded(true);
        openShareToSlackDialog();
    }, [openShareToSlackDialog]);
    return {
        slackIsLoaded,
        onSlackLoaded,
        handleOpenSlack,
        isShareToSlackDialog,
        openShareToSlackDialog,
        closeShareToSlackDialog,
    };
}
