import React, { useContext } from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { Theme, useTheme } from '@lumapps/lumx/react';
import { FilePreviewBlock } from '@lumapps/medias/components/FilePreviewBlock';
import { MediaSourceV2, MediaStatus } from '@lumapps/medias/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { ElementRender } from '@lumapps/wrex/types';

import { FILE, WREX_LUMAPPS_MEDIA_PROVIDER } from '../../../constants';
import { WrexFileContext } from '../../../context';
import { FileElement } from '../../../types';
import { isDriveFile } from '../../../utils/isDriveFile';

import './index.scss';

export const CLASSNAME = 'wrex-file';

export const File: ElementRender<FileElement, HTMLDivElement> = ({
    element,
    elementRef,
    children,
    className,
    actions,
    ...forwardedProps
}) => {
    const theme = useTheme() || Theme.light;
    const {
        name: elementName,
        createdAt: elementCreatedAt,
        src: elementSrc,
        mimeType: elementMimeType,
        isLoading,
        editedAt: elementEditedAt,
        status: elementStatus,
        thumbnailUrl: elementThumbnailUrl,
        resource,
    } = element ?? {};
    const contextData = useContext(WrexFileContext);
    const isElementDriveFile = isDriveFile(element);
    const canUpdateDriveFile = isDriveFile(element, contextData?.userProvider);

    const { translateAndReplace } = useTranslate();

    const updatedFile = React.useMemo(() => {
        if (contextData && !contextData.isFetching && contextData.updatedFiles?.length) {
            return contextData.updatedFiles.find((file) => file.resource?.id === resource?.id);
        }
        return undefined;
    }, [contextData, resource?.id]);

    const { block } = useClassnames(CLASSNAME);
    const isFileDeleted = updatedFile?.status === MediaStatus.DELETED || elementStatus === MediaStatus.DELETED;

    const fileElement = isFileDeleted
        ? {
              status: MediaStatus.DELETED,
              editedAt: undefined,
              src: undefined,
              mimeType: undefined,
              name: undefined,
              createdAt: undefined,
              thumbnailUrl: undefined,
          }
        : {
              status: updatedFile?.status || elementStatus,
              editedAt: updatedFile?.editedAt || elementEditedAt,
              src: updatedFile?.src || elementSrc,
              mimeType: updatedFile?.mimeType || elementMimeType,
              name: updatedFile?.name || elementName,
              createdAt: updatedFile?.createdAt || elementCreatedAt,
              thumbnailUrl: updatedFile?.thumbnailUrl || elementThumbnailUrl,
          };

    return (
        <div className={classnames(className, block())} ref={elementRef} {...forwardedProps}>
            {children}
            <FilePreviewBlock
                status={fileElement.status}
                provider={
                    resource?.type === WREX_LUMAPPS_MEDIA_PROVIDER
                        ? MediaSourceV2.HAUSSMANN_MEDIA
                        : (resource?.type as MediaSourceV2)
                }
                editedAt={fileElement.editedAt}
                theme={theme}
                id={resource?.id}
                url={fileElement.src}
                mimeType={fileElement.mimeType}
                isLoading={canUpdateDriveFile ? contextData?.isFetching : isLoading || !element}
                thumbnailUrl={fileElement.thumbnailUrl}
                shouldDisplayImageAsThumbnail={!isElementDriveFile}
                name={fileElement.name}
                createdAt={fileElement.createdAt}
                actions={actions}
                linkAriaLabel={
                    !actions && !!fileElement.name
                        ? translateAndReplace(GLOBAL.OPEN_SOMETHING, {
                              ENT: fileElement.name as string,
                          })
                        : undefined
                }
                {...{ contentEditable: false }}
            />
        </div>
    );
};
File.displayName = FILE;
