/* istanbul ignore file */
import {
    canViewInstanceAnalytics,
    canViewPlatformAnalytics,
    isAnalyticsAllowed as isAnalyticsAllowedSelector,
} from '@lumapps/analytics/ducks/selectors';
import { isAskAIEnabled } from '@lumapps/ask-ai/ducks/selectors';
import { isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import { isInstanceSuperAdmin as isInstanceSuperAdminSelector } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { BaseStore } from '@lumapps/redux/types';
import { hasCoveoIndexingEnabled, isSearchAnalyticsEnabled } from '@lumapps/search-analytics/ducks/selectors';

import { AnalyticsSideNavigation } from './AnalyticsSideNavigation';

const mapStateToProps = (state: BaseStore) => {
    const isInstanceSuperAdmin = isInstanceSuperAdminSelector(state);
    const isAnalyticsReadAllowed = isAnalyticsAllowedSelector(state);
    const isSearchAnalyticsFeatureEnabled = isSearchAnalyticsEnabled(state);
    const isLearningAnalyticsFeatureEnabled = isLearningEnabled(state);
    const hasCoveo = hasCoveoIndexingEnabled(state);
    const canViewPlatformEntry = canViewPlatformAnalytics(state);
    const canViewInstanceEntry = canViewInstanceAnalytics(state);
    const isFFAskAiEnabled = isAskAIEnabled(state);

    return {
        hasSearchAnalyticsAccess:
            (isInstanceSuperAdmin || (isAnalyticsReadAllowed && !hasCoveo)) && isSearchAnalyticsFeatureEnabled,
        hasSiteAnalyticsAccess: canViewInstanceEntry,
        hasExtendedAnalyticsAccess: canViewPlatformEntry,
        hasMobileAnalyticsAccess: canViewPlatformEntry,
        hasLearningAnalyticsAccess: canViewPlatformEntry && isLearningAnalyticsFeatureEnabled,
        hasAskAiAccess: canViewPlatformEntry && isFFAskAiEnabled,
    };
};

const ConnectedSideNavigation = connect(mapStateToProps)(AnalyticsSideNavigation);

export { ConnectedSideNavigation as AnalyticsSideNavigation };
