import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';

/**
 * Build an array of custom CSS classes from a string of comma separated CSS classes.
 *
 * @param  {string} customClasses    The custom CSS classes string (comma separated CSS classes).
 * @param  {string} [classPrefix=''] A prefix to add before each CSS class.
 * @return {Array}  The array of all (prefixed if needed) CSS classes.
 */
function getCustomClass(customClasses, classPrefix) {
    classPrefix = classPrefix || '';

    const classesArray = [];

    if (isUndefined(customClasses) || customClasses === '') {
        return classesArray;
    }

    const customClassesSplit = customClasses.split(',');
    if (isUndefined(customClassesSplit) || customClassesSplit === '') {
        return classesArray;
    }

    map(customClassesSplit, (customClass) => {
        if (!isUndefined(customClass) && customClass !== '') {
            classesArray.push(classPrefix + customClass.trim());
        }
    });

    return classesArray;
}

/**
 * Security / XSS convenient method that removes js script that can be placed in HTML anchor href. 
 * Replaces href="javascript:xxxxxxxxx" with href=""
 * 
 * @param {string} html Html string to be analysed
 * 
 * @return {string} Sanitized html string
 */
function preventJSInHTMLLinks(html) {
    return html.replace(/href="(javascript:(.*)?)"/g, ()=>'href=""');
}

export { getCustomClass, preventJSInHTMLLinks };
