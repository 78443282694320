import { useMemo } from 'react';

import { mdiXml } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { ReactEditor, useSlate, useSlateStatic } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { ToolbarItem } from '@lumapps/wrex/types';

import { INLINE_CODE } from '../../../constants';
import { WREX_TYPOGRAPHY } from '../../../keys';
import { TypographyEditor } from '../../../types';

export const useInlineCodeButton = (otherProps?: any) => {
    const editor = useSlate() as ReactEditor & TypographyEditor;
    const editorStatic = useSlateStatic() as ReactEditor & TypographyEditor;

    const isCurrentNodeAnInlineCode = editor.getInlineType() === INLINE_CODE;
    const { translateKey } = useTranslate();

    const isSelected = isCurrentNodeAnInlineCode;
    const isDisabled = !editor.isInlineAllowed() && !isCurrentNodeAnInlineCode;

    return useMemo((): ToolbarItem => {
        return {
            type: 'toggle-option',
            itemKey: INLINE_CODE,
            icon: mdiXml,
            tooltipLabel: translateKey(WREX_TYPOGRAPHY.INLINE_CODE_BUTTON),
            onClick() {
                if (!editorStatic.selection) {
                    initSelection(editorStatic, 'inline');
                }
                editorStatic.toggleCode();
                focusAt(editorStatic, editorStatic.selection);
            },
            'aria-disabled': isDisabled,
            isChecked: isSelected,
            verticalModeProps: {
                label: translateKey(WREX_TYPOGRAPHY.INLINE_CODE_BUTTON),
                tooltipLabel: undefined,
            },
            otherProps,
        };
    }, [translateKey, isDisabled, isSelected, otherProps, editorStatic]);
};
