import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function LikeButtonController() {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    function getContentResourceType(content) {
        switch (content.type) {
            case 'post':
                return 'community/post';
            default:
                return 'content';
        }
    }

    /**
     * Initialize the controller.
     */
    function init() {
        vm.kind = vm.kind || 'Content';
        vm.theme = vm.theme || 'light';
        vm.resourceType = getContentResourceType(vm.content);


        // Initialize the like count if it doesn't exist.
        if (angular.isUndefined(vm.content.likes)) {
            vm.content.likes = 0;
        }

        if (angular.isUndefinedOrEmpty(vm.content.uid) && angular.isDefinedAndFilled(vm.content.id)) {
            vm.content.uid = vm.content.id;
        }

        vm.content.liked = angular.isUndefined(vm.content.liked) ? false : vm.content.liked;
    }

    init();
}

/**
 * Display a like count and allows toggling of the like status for the current user.
 *
 * @param {Object}  content          The related content object to be liked / unliked.
 * @param {boolean} [isExtended]     Indicates if we need to display the label next to the icon or not.
 * @param {string}  [kind='Content'] The kind of the related content object.
 * @param {string}  [theme='light']  The theme to apply to the template.
 */

function LikeButtonDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: LikeButtonController,
        controllerAs: 'vm',
        replace: false,
        restrict: 'E',
        scope: {
            content: '<lsContent',
            isExtended: '<?lsIsExtended',
            kind: '@?lsKind',
            theme: '@?lsTheme',
        },
        templateUrl: '/client/front-office/modules/social/views/like-button.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsLikeButton', LikeButtonDirective);

/////////////////////////////

export { LikeButtonDirective };
