import { AppId, createEntityManagementRoutes } from '@lumapps/router';

const { baseRoute, createRoute, editRoute, getEditRoute } = createEntityManagementRoutes({
    appId: AppId.backOffice,
    id: 'roles',
    legacyId: 'app.admin.role',
    slug: 'roles',
});

export { baseRoute as rolesListRoute, createRoute as rolesCreateRoute, editRoute as rolesEditRoute, getEditRoute };
