import React from 'react';

import { DetailedDirectoryEntryBlock } from '@lumapps/directories-entries/components/DetailedDirectoryEntryBlock/DetailedDirectoryEntryBlock';

import { useDeletedUserDirectoryEntry } from '../../hooks/useDeleteUserDirectoryEntry';
import { useSaveUserDirectoryEntry } from '../../hooks/useSaveUserDirectoryEntry';
import { BlockDirectoryEntry as BlockDirectoryEntryProps } from '../../types';

export const BlockDirectoryEntry = (props: BlockDirectoryEntryProps) => {
    const { directoryEntryId, directoryId, widget } = props;
    const { openSaveDialog, creationDialogProps } = useSaveUserDirectoryEntry(widget, directoryId);
    const { deleteDialogProps, openDeleteDialog } = useDeletedUserDirectoryEntry(widget, directoryEntryId);

    return (
        <DetailedDirectoryEntryBlock
            openDeleteDialog={openDeleteDialog}
            creationDialogProps={creationDialogProps}
            deleteDialogProps={deleteDialogProps}
            openSaveDialog={openSaveDialog}
            {...props}
        />
    );
};
