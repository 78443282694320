import React, { useCallback } from 'react';

import { DashboardEvent, LookerEmbedSDK } from '@looker/embed-sdk';

import { get } from '@lumapps/constants';
import { useQueryParams, useRouter } from '@lumapps/router';
import { decodeB64ToJson, encodeJsonToB64 } from '@lumapps/utils/string/base64Unicode';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { cancelFetchEmbeddedDashboardSignedUrl, fetchEmbeddedDashboardSignedUrl } from '../api';

interface LookerDashboardState {
    filters: Record<string, string>;
}

const constants = get();

export interface UseLookerProps {
    url: string;
    dashboardId: string;
    /** Don't interfere with the page url, suited for dashboard in dialogs */
    readOnlyUrl?: boolean;
}
export const useLooker = ({ url, dashboardId, readOnlyUrl }: UseLookerProps) => {
    const localStorageStateKey = `lumapps-looker-dash-state-${dashboardId}`;

    const [dashboardStatus, setDashboardStatus] = React.useState<BaseLoadingStatus>(BaseLoadingStatus.initial);
    const [dashboardError, setDashboardError] = React.useState<any>(undefined);

    const { redirect } = useRouter();

    const queryParams = useQueryParams();

    const setFiltersInQueryParams = useCallback(
        (filters: string) => {
            redirect({
                path: '',
                appId: constants.applicationId,
                query: { filters },
            });
        },
        [redirect],
    );

    /*
     * Store current dashboard state (filter et dash id) when they are changed
     * Store them in url and in local storage for futur retrieval
     */
    const storeState = useCallback(
        (event: DashboardEvent) => {
            // Filters
            const filtersStr = encodeJsonToB64(event.dashboard.dashboard_filters);
            const stateToStore = { filters: filtersStr };

            localStorage.setItem(localStorageStateKey, JSON.stringify(stateToStore));
            if (!readOnlyUrl) {
                setFiltersInQueryParams(filtersStr);
            }
        },
        [localStorageStateKey, readOnlyUrl, setFiltersInQueryParams],
    );

    const cleanUpState = React.useCallback(() => {
        if (!readOnlyUrl) {
            setFiltersInQueryParams(encodeJsonToB64({}));
        }
        localStorage.removeItem(localStorageStateKey);
    }, [localStorageStateKey, readOnlyUrl, setFiltersInQueryParams]);

    /*
     * Retrieve the state of the dashboard
     * From url or localstorage if there is any
     */
    const retrieveState = useCallback((): LookerDashboardState | undefined => {
        // 1 - Read url params
        const queryParamsFilters = queryParams?.filters;
        if (queryParamsFilters) {
            const filters = decodeB64ToJson(queryParamsFilters);
            return { filters };
        }

        // 2 - if nothing, read local storage
        const localStorageState = localStorage.getItem(localStorageStateKey);
        if (localStorageState) {
            const parsedState: Record<string, string> = JSON.parse(localStorageState);
            // Put the filters from local storage in url
            // to enable url sharing in all cases
            if (parsedState.filters) {
                setFiltersInQueryParams(parsedState.filters);
            }
            return { filters: decodeB64ToJson(parsedState.filters) };
        }

        // Else no state retrieved
        return undefined;
    }, [localStorageStateKey, queryParams?.filters, setFiltersInQueryParams]);

    const createDashboard = React.useCallback(
        async (appendTo: string) => {
            if (dashboardStatus !== BaseLoadingStatus.initial) {
                return;
            }

            setDashboardStatus(BaseLoadingStatus.loading);
            try {
                const storedState = retrieveState();

                const initialFilters = storedState?.filters ? encodeJsonToB64(storedState.filters) : undefined;
                const langs = [
                    ...new Set([constants.userLang, constants.userAlernativeLangs[0], navigator.language]),
                ].filter(Boolean);

                let dashboardUrl: string | undefined;
                try {
                    dashboardUrl = await fetchEmbeddedDashboardSignedUrl(url, langs, initialFilters);
                } catch (err) {
                    // Try reseting the filters to avoid crashing the page
                    // This can occur when there are to lany selected filters and we can't control it.
                    cleanUpState();
                    dashboardUrl = await fetchEmbeddedDashboardSignedUrl(url, langs, undefined);
                }

                if (!dashboardUrl) {
                    setDashboardStatus(BaseLoadingStatus.error);
                    return;
                }

                const apiHost = new URL(dashboardUrl).host;

                const dashboard = LookerEmbedSDK.createDashboardWithUrl(dashboardUrl)
                    .withApiHost(apiHost)
                    .appendTo(appendTo)

                    .on('dashboard:loaded', () => {
                        // Once dashboard is loaded,
                        // the Looker loader (in iframe) will be displayed
                        // so we remove our own loading indicator
                        setDashboardStatus(BaseLoadingStatus.idle);
                    })
                    .on('dashboard:filters:changed', (e) => {
                        storeState(e);
                    })
                    .build();

                dashboard.connect();
            } catch (err) {
                setDashboardStatus(BaseLoadingStatus.error);
                setDashboardError(err);
            }
        },

        [cleanUpState, retrieveState, storeState, url, dashboardStatus],
    );

    React.useEffect(() => {
        return () => {
            cancelFetchEmbeddedDashboardSignedUrl(url);
        };
    }, [url]);

    return { createDashboard, dashboardStatus, dashboardError };
};
