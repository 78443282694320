import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiFolderAccount } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { USER } from '@lumapps/user/keys';

import { userManagementRoute } from '../../routes';

export interface UserManagementSideNavigationProps {
    isAllowed: boolean;
    onClick?: () => void;
}

const UserManagementSideNavigation = ({ isAllowed, onClick }: UserManagementSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            label={translateKey(USER.USERS_MANAGEMENT)}
            icon={mdiFolderAccount}
            route={userManagementRoute}
            onClick={onClick}
        />
    );
};

UserManagementSideNavigation.displayName = 'SideNavigationItem';

export { UserManagementSideNavigation };
