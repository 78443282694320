import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiShareOutline } from '@lumapps/lumx/icons';
import { Button, ButtonProps, IconButton } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface ShareButtonProps extends Pick<ButtonProps, 'size' | 'theme' | 'isDisabled'> {
    showLabel?: boolean;
    className?: string;
    scope: string;
}

/** Base share button */
export const ShareButton = React.forwardRef<HTMLButtonElement, ShareButtonProps>(
    ({ showLabel, className, scope, ...forwardedProps }, ref) => {
        const { translateKey } = useTranslate();
        const { get: getAttributes } = useDataAttributes(scope);
        const baseProps = {
            ...forwardedProps,
            ...getAttributes({ element: 'button', action: 'share' }),
        };

        if (!showLabel) {
            return (
                <IconButton
                    ref={ref}
                    className={className}
                    emphasis="low"
                    icon={mdiShareOutline}
                    label={translateKey(GLOBAL.SHARE)}
                    {...baseProps}
                />
            );
        }

        return (
            <Button ref={ref} className={className} emphasis="low" leftIcon={mdiShareOutline} {...baseProps}>
                {translateKey(GLOBAL.SHARE)}
            </Button>
        );
    },
);
