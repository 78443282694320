import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { CAMPAIGN_ICON } from '../../constants';
import { canAccessCampaign } from '../../ducks/selectors';
import { CAMPAIGNS } from '../../keys';
import { campaignRoutes } from '../../routes';

const CampaignSideNavigation: React.FC = () => {
    const isAllowed = useSelector(canAccessCampaign);
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(CAMPAIGNS.TITLE)} icon={CAMPAIGN_ICON} id="campaigns">
            <SideNavigationItem
                label={translateKey(CAMPAIGNS.SIDE_NAV_TITLE)}
                route={campaignRoutes.baseRoute}
                excludedRoutes={[campaignRoutes.settingsRoute]}
            />
            <SideNavigationItem label={translateKey(GLOBAL.SETTINGS)} route={campaignRoutes.settingsRoute} />
        </SideNavigationItem>
    );
};

CampaignSideNavigation.displayName = 'SideNavigationItem';

export { CampaignSideNavigation };
