import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { instanceIdSelector, getCurrentInstanceName } from '../ducks/selectors';

/**
 * Retrieves the basic data for the current instance, usually to be displayed
 * as the title page or the browser's tab name.
 * @returns Partial<Instance>
 */
export const useInstance = () => {
    const { translateKey, translateAndReplace } = useTranslate();
    const instanceId = useSelector(instanceIdSelector);
    const instanceName = useSelector(getCurrentInstanceName);

    const homeTitle = instanceName
        ? translateAndReplace(GLOBAL.HOME_PAGE, { site: instanceName })
        : translateKey(GLOBAL.GO_TO_HOMEPAGE);

    return {
        id: instanceId,
        name: instanceName,
        homeTitle,
    };
};
