import React from 'react';

import { EditableImage } from '@lumapps/lumx-images/components/EditableImage';
import { ThumbnailProps, UploaderProps } from '@lumapps/lumx/react';
import { UseFilePickerProps } from '@lumapps/utils/hooks/useFilePicker';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { type UseUploadMediaProps, useUploadMedia } from '../../hooks/useUploadMedia';
import { UploadedMedia } from '../../types';

export interface UploadMediaProps extends UploaderProps {
    /** custom class name for Uploader */
    className?: string;
    /** options that will be passed in to the upload media API */
    uploadMediaOptions?: UseUploadMediaProps['uploadMediaOptions'];
    /** component that will be displayed before the uploader */
    before?: React.ReactNode;
    /** component that will be displayed after the uploader */
    after?: React.ReactNode;
    /** props to be passed in to the thumbnail displayed */
    thumbnailProps?: Partial<ThumbnailProps>;
    /** callback to be executed once the medias have been uploaded */
    onMediaUploaded: (media?: UploadedMedia) => void;
    /** options for the file picker */
    filePickerOptions?: Partial<UseFilePickerProps>;
    /** already selected medias */
    selectedMedia?: UploadedMedia;
    /** update image loading status so parent component can use it */
    setLoadingStatus?: React.Dispatch<React.SetStateAction<BaseLoadingStatus>> | ((status: BaseLoadingStatus) => void);
}

/**
 * Displays a component that upload a file to the medias v2 storage.
 * This component only uploads one single image.
 *
 * @family Files
 * @param UploadMediaProps
 * @returns UploadMedia
 */
export const UploadMedia: React.FC<UploadMediaProps> = ({
    uploadMediaOptions,
    onMediaUploaded,
    selectedMedia,
    setLoadingStatus,
    ...props
}) => {
    const { state, areImagesLoading, onSelectImages, onDelete } = useUploadMedia({
        uploadMediaOptions,
        onMediaUploaded: (medias) => {
            if (medias) {
                const [media] = medias;

                onMediaUploaded(media);
            } else {
                onMediaUploaded();
            }
        },
        selectedMedias: selectedMedia ? [selectedMedia] : [],
    });

    const { medias, status } = state;

    const [media] = medias;

    React.useEffect(() => {
        setLoadingStatus?.(status);
    }, [setLoadingStatus, status]);

    return (
        <EditableImage
            {...props}
            isImageLoading={areImagesLoading}
            onDelete={onDelete}
            onImageSelected={(img) => {
                onSelectImages([img]);
            }}
            image={
                media
                    ? {
                          file: media.file,
                          url: media.url,
                          blobUrl: media.blobUrl,
                          name: media.file?.name,
                          id: media.id,
                      }
                    : undefined
            }
        />
    );
};
