import { useMemo } from 'react';

import find from 'lodash/find';
import flow from 'lodash/fp/flow';
import getOr from 'lodash/fp/getOr';
import identity from 'lodash/fp/identity';
import map from 'lodash/fp/map';
import mapKeys from 'lodash/fp/mapKeys';
import pick from 'lodash/fp/pick';

import { useSelector } from '@lumapps/redux/react';
import { selectSpaceData } from '@lumapps/spaces/ducks/selectors';
import { useTranslate } from '@lumapps/translations';
import evolve from '@lumapps/utils/function/evolve';

import { PostSelectMultipleFilterBlockProps } from '../../components/BlockContainerFilters/PostSelectMultipleFilterBlock';
import { WIDGET_POST_LIST } from '../../keys';
import { PostListFilterChoice, PostListFilterHookProps } from '../../types';
import { createGetChoiceName } from '../../utils/PostListFilters/getChoiceName';

export function usePostCategoriesFilter({
    activeFiltersValues,
    filtersValues,
    handleValuesChange,
}: PostListFilterHookProps): PostSelectMultipleFilterBlockProps<string> {
    const filterId = 'postCategories';

    const { translateObject, translateKey } = useTranslate();

    const currentCommunity = useSelector(selectSpaceData);

    const choices = useMemo(
        () =>
            flow(
                getOr([], 'tags'),
                map(
                    flow(
                        pick(['id', 'name']),
                        mapKeys((key: string) => (key === 'id' ? 'value' : key)),
                        evolve({
                            name: translateObject,
                        }),
                    ),
                ),
            )(currentCommunity as any),
        [currentCommunity, translateObject],
    ) as PostListFilterChoice<string>[];

    // We need to make sure that the selected values are really existing among the available choices
    const selectedValues = getOr([] as string[], filterId, filtersValues).filter((filterId) =>
        find(choices, ['value', filterId]),
    );

    const activeValues = getOr([] as string[], filterId, activeFiltersValues).filter((filterId) =>
        find(choices, ['value', filterId]),
    );

    const { getChoiceName, label } = useMemo(() => {
        const getChoiceName = createGetChoiceName(choices);
        const label = translateKey(WIDGET_POST_LIST.FILTERS_CATEGORIES);

        return {
            getChoiceName,
            label,
        };
    }, [choices, translateKey]);

    return {
        choices,
        filterId,
        getChoiceName,
        getValueId: identity,
        label,
        onSelect: handleValuesChange,
        selectedValues,
        activeValues,
    };
}
