import React, { useCallback } from 'react';

import { classnames, padding } from '@lumapps/classnames';
import { SelectTextField, type SelectTextFieldProps } from '@lumapps/combobox/components/SelectTextField';
import { useDataAttributes } from '@lumapps/data-attributes';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';
import { DefaultValueWrapper } from '../DefaultValueWrapper';

export type FormSelectTextFieldProps<O> = Omit<SelectTextFieldProps<O>, 'onChange' | 'value'> &
    BaseInput<O> & {
        isClearable?: boolean;
    };

type Value<S, O> = S extends 'multiple' ? O[] : O;

/**
 * Component to be used for selecting single or multiple options inside a form
 * @family Forms
 * @param FormSelectTextFieldProps
 * @returns FormSelectTextField
 */
export const FormSelectTextField = <O,>({
    name,
    isRequired,
    controllerProps,
    isClearable = false,
    label,
    selectionType,
    ...props
}: FormSelectTextFieldProps<O>) => {
    const { translateKey } = useTranslate();
    const { field, fieldState, isFieldDisabled, defaultValueWrapperProps, valueToUse, isFormLoading, scope } =
        useFormField<Value<typeof selectionType, O>>({
            name,
            isRequired,
            controllerProps,
            label,
        });
    const fieldScope = `${scope}-select-field-${selectionType}`;
    const { get } = useDataAttributes(fieldScope);

    const hasError = Boolean(fieldState.error);
    const defaultValue = (selectionType === 'multiple' ? [] : undefined) as Value<typeof selectionType, O>;

    // Form on change doesn't accept undefined as a value so we need to handle it to be able to reset the field
    const handleChange = useCallback(
        (val?: Value<typeof selectionType, O>) => {
            field.onChange(val ?? null);
        },
        [field],
    );

    return (
        <DefaultValueWrapper {...defaultValueWrapperProps}>
            <SelectTextField<O>
                scope={fieldScope}
                {...get({ element: 'input', action: 'search' })}
                {...props}
                selectionType={selectionType as any}
                listProps={{ ...get({ element: 'option-list' }) }}
                className={classnames(props.className, padding('vertical', null))}
                name={field.name}
                label={label}
                value={(valueToUse || defaultValue) as any}
                isDisabled={isFormLoading || isFieldDisabled}
                isRequired={isRequired}
                error={translateKey(GLOBAL.FIELD_IS_REQUIRED)}
                hasError={hasError}
                onChange={handleChange}
                onBlur={field.onBlur}
                inputRef={field.ref}
                hasClearButton={isClearable}
            />
        </DefaultValueWrapper>
    );
};
