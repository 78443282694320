import React, { ReactNode } from 'react';

import { padding, useClassnames } from '@lumapps/classnames';
import { UploadImageDialog } from '@lumapps/lumx-images/components/UploadImageDialog';
import type { UploadImageOption } from '@lumapps/lumx-images/types';
import { Menubar } from '@lumapps/lumx-menu/components/Menubar';
import { mdiClose, mdiCrop, mdiFileImage, mdiPencilOutline } from '@lumapps/lumx/icons';
import { Theme, Uploader } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { CLASSNAME } from '../../constants';

import './index.scss';

export interface EditableWidgetFeaturedImageProps {
    theme?: Theme;
    isEmpty?: boolean;
    onDelete: () => void;
    onCrop: () => void;
    uploadDialogProps: {
        isLoading?: boolean;
        options: UploadImageOption[];
    };
    children?: ReactNode;
}

export const EditableWidgetFeaturedImage: React.FC<EditableWidgetFeaturedImageProps> = ({
    isEmpty,
    theme = Theme.light,
    onCrop,
    onDelete,
    children,
    uploadDialogProps,
}) => {
    const { translateKey } = useTranslate();
    const [isUploadDialogOpen, , closeUploadDialog, openUploadDialog] = useBooleanState(false);
    const { element } = useClassnames(CLASSNAME);

    return (
        <>
            {isEmpty ? (
                <div className={element('editable-wrapper', 'empty')}>
                    <Uploader
                        theme={theme}
                        className={padding('vertical', 'huge')}
                        onClick={openUploadDialog}
                        label={translateKey(GLOBAL.ADD_FEATURED_IMAGE)}
                        icon={mdiFileImage}
                        aspectRatio="free"
                    />
                </div>
            ) : (
                <>
                    <Menubar ariaLabel={translateKey(GLOBAL.FEATURED_IMAGE_TOOLBAR)} className={element('toolbar')}>
                        <Menubar.Section>
                            <Menubar.Item
                                onClick={onCrop}
                                aria-label={translateKey(GLOBAL.CROP)}
                                tooltipLabel={translateKey(GLOBAL.CROP)}
                                icon={mdiCrop}
                            />
                            <Menubar.Item
                                onClick={openUploadDialog}
                                aria-label={translateKey(GLOBAL.EDIT)}
                                tooltipLabel={translateKey(GLOBAL.EDIT)}
                                icon={mdiPencilOutline}
                            />
                        </Menubar.Section>
                        <Menubar.Section>
                            <Menubar.Item
                                onClick={onDelete}
                                aria-label={translateKey(GLOBAL.DELETE)}
                                tooltipLabel={translateKey(GLOBAL.DELETE)}
                                icon={mdiClose}
                            />
                        </Menubar.Section>
                    </Menubar>

                    {children}
                </>
            )}
            <UploadImageDialog
                isOpen={isUploadDialogOpen}
                onClose={closeUploadDialog}
                isLoading={uploadDialogProps.isLoading}
                options={uploadDialogProps.options}
                dialogTitle={translateKey(GLOBAL.ADD_FEATURED_IMAGE)}
                scope="widget-featured-image"
            />
        </>
    );
};
