import React from 'react';

import Database from 'emoji-picker-element/database';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import { currentLanguageSelector } from '@lumapps/languages';
import type { Emoji, EmojiDetails } from '@lumapps/pickers/types';
import { fetchEmojiData } from '@lumapps/pickers/utils/fetchEmojiData';
import { useSelector } from '@lumapps/redux/react';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { sortEmojiList } from '../utils/sortEmojiList';

/** Search for emojis with a given shortcode */
export function useSearchEmojiData(searchTerm: string | undefined) {
    const currentLang = useSelector(currentLanguageSelector);
    const [dataSource, setDataSource] = React.useState<string | undefined>(undefined);
    const [emojis, setEmojis] = React.useState<Emoji[]>();
    const [favoriteEmojis, setFavoriteEmojis] = React.useState<Emoji[]>();

    const getEmojiData = React.useCallback(async () => {
        const emojiData = await fetchEmojiData(currentLang);

        const emojiDataUrl = emojiData?.default;

        setDataSource(typeof emojiDataUrl === 'string' ? emojiDataUrl : undefined);
    }, [currentLang]);

    React.useEffect(() => {
        getEmojiData();
    }, [getEmojiData]);

    const emojiDb = React.useMemo(() => {
        if (dataSource) {
            return new Database({
                locale: currentLang,
                dataSource,
            });
        }
        return undefined;
    }, [currentLang, dataSource]);

    const getSearchedEmoji = React.useCallback(async () => {
        if (searchTerm && !isEmpty(searchTerm) && emojiDb) {
            const emojis = await emojiDb.getEmojiBySearchQuery(searchTerm);

            setEmojis(emojis);

            return emojis;
        }
        return undefined;
    }, [searchTerm, emojiDb]);

    const getFavoriteEmojis = React.useCallback(async () => {
        if (emojiDb && !favoriteEmojis) {
            const topFavoriteEmoji = await emojiDb.getTopFavoriteEmoji(10);
            setFavoriteEmojis(topFavoriteEmoji);
        }
    }, [emojiDb, favoriteEmojis]);

    React.useEffect(() => {
        if (searchTerm !== undefined && emojiDb) {
            getSearchedEmoji();
            getFavoriteEmojis();
        }
    }, [searchTerm, emojiDb, getSearchedEmoji, getFavoriteEmojis]);

    const entities = React.useMemo(() => {
        if (emojis && searchTerm) {
            return sortEmojiList({ emojis, favoriteEmojis, searchTerm });
        }
        return undefined;
    }, [emojis, searchTerm, favoriteEmojis]);

    const onItemSelected = async (emojiObject: EmojiDetails['emoji']) => {
        if (emojiObject) {
            await emojiDb?.incrementFavoriteEmojiCount(emojiObject.unicode);
        }
    };

    return {
        entities,
        status: BaseLoadingStatus.idle,
        onItemSelected,
        fetchMore: noop,
    };
}
