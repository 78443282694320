import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Color } from '@lumapps/lumx/react';

import { Card, CardProps } from '../Card';

import './index.scss';

export interface ColorCardProps extends CardProps {
    /** Color to be given to the card background */
    color?: Color;
    /** element to display before the content */
    before?: React.ReactNode;
    /** element to display after the content */
    after?: React.ReactNode;
}

export const CLASSNAME = 'color-card';

/**
 * Component displaying a simple card with with colored background.
 *
 * @family Cards
 * @param ColorCardProps
 * @returns ColorCard
 */
export const ColorCard: React.FC<ColorCardProps> = ({
    className = '',
    color = 'light',
    children,
    before,
    after,
    ...forwardedProps
}) => {
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <Card className={block(color, [className])} {...forwardedProps}>
            {before}
            {children ? <div className={element('content')}>{children}</div> : null}
            {after}
        </Card>
    );
};
