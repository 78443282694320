import { ReactEditor, PathRef, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { withoutHistory } from '@lumapps/wrex/slate/utils/withoutHistory';

import { WREX_LUMAPPS_MEDIA_PROVIDER } from '../constants';
import { FileEditor, FileEditorOptions } from '../types';
import { updateFileNode } from './updateFileNode';

/**
 * Upload an inline File and update its status directly into the editor.
 */
export async function uploadFile(
    options: FileEditorOptions,
    editor: ReactEditor & FileEditor,
    file: File,
    pathRef: PathRef,
) {
    try {
        // Upload file to server.
        const [{ id, url }] = await options.uploadFunction([file]);
        withoutHistory(editor, () => {
            updateFileNode(
                editor,
                {
                    src: url,
                    isLoading: false,
                    resource: { id, type: WREX_LUMAPPS_MEDIA_PROVIDER },
                },
                pathRef,
            );
        });
    } catch (e) {
        const path = pathRef.current;
        if (!path) {
            return;
        }
        Transforms.removeNodes(editor, { at: path });
        focusAt(editor);
    }
}
