import React from 'react';

import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { Theme } from '@lumapps/lumx/react';
import { MetadataPickerField } from '@lumapps/metadata-pickers/components/MetadataPickerField';
import { MetadataFilter, PickerMetaData } from '@lumapps/metadata-pickers/types';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { RootMetadataReferences } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';

interface MetadataContentFilterProps {
    /** List of metadata selected */
    selectedMetadata?: MetadataFilter[];
    /** List of available root metadata */
    rootMetadataReferences?: RootMetadataReferences[];
    /** Theme */
    theme: Theme;
    /** Handler */
    onChange: (values: MetadataFilter[]) => void;
    /** Scope */
    scope: string;
    hideSubheader?: boolean;
}

/**
 * Component that fetch metadata root attached to the provided content types,
 * and then displays one multi select filter for each of these metadata root.
 * Each select allow the selection of several metadata inherited from their metadata root.
 * @family Filters
 * @param MetadataContentFilterProps
 * @returns MetadataContentFilterProps
 */
export const MetadataContentFilter = ({
    selectedMetadata = [],
    rootMetadataReferences = [],
    theme,
    scope,
    onChange,
    hideSubheader,
}: MetadataContentFilterProps) => {
    const { translateKey } = useTranslate();
    const instanceId = useSelector(instanceIdSelector);

    const selectedMetadataByFamily = React.useMemo(
        () => groupBy(selectedMetadata, 'rootId') as Record<string, MetadataFilter[]>,
        [selectedMetadata],
    );

    const handleChange = React.useCallback(
        (rootId: string) =>
            (metadataSelection: PickerMetaData[] = []) => {
                const newSelection = { ...selectedMetadataByFamily };

                // Replace selection for current family (converting to v2 filter format)
                newSelection[rootId] = metadataSelection.map(({ id, name }): MetadataFilter => ({ id, name, rootId }));

                onChange(Object.values(newSelection).flat());
            },
        [onChange, selectedMetadataByFamily],
    );

    if (isEmpty(rootMetadataReferences)) {
        return null;
    }

    return (
        <>
            {!hideSubheader && <FilterSubheader label={translateKey(GLOBAL.METADATA)} theme={theme} />}
            {rootMetadataReferences.map(({ id, name }) => {
                return (
                    <MetadataPickerField
                        selectionType="multiple"
                        key={id}
                        label={name}
                        familyId={id}
                        theme={theme}
                        scope={scope}
                        value={selectedMetadataByFamily[id]}
                        onChange={handleChange(id)}
                        useInstanceMetadata
                        showMultipleLevels
                        instanceId={instanceId}
                    />
                );
            })}
        </>
    );
};
