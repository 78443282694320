import React from 'react';

import { SUGGESTED_FORMALITIES, SUGGESTED_TONES } from '@lumapps/ai-generative-content/constants';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiMicrophone, mdiSortVariant, mdiMenu, mdiAutoFix } from '@lumapps/lumx/icons';
import { Emphasis, ColorPalette, Placement } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { UseRefineContentOutput } from '../../hooks/useRefineContentDialog';
import { WREX_GENERATED_CONTENT } from '../../keys';

import './index.scss';

export interface RefineContentMenuProps {
    buttonRef: React.MutableRefObject<null>;
    selectedText?: string;
    onRefineContent: UseRefineContentOutput['onRefineContent'];
}

export const RefineContentMenu = ({ buttonRef, selectedText, onRefineContent }: RefineContentMenuProps) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes('ai-adapt-selection');

    return (
        <Menu.Trigger
            popoverProps={{
                placement: Placement.TOP,
            }}
        >
            <Menu.TriggerButton
                emphasis={Emphasis.low}
                hasBackground
                ref={buttonRef}
                color={ColorPalette.primary}
                {...get({ element: 'revise-selection' })}
            >
                {translateKey(WREX_GENERATED_CONTENT.REVISE_SELECTION)}
            </Menu.TriggerButton>

            <Menu>
                <Menu.Section label={translateKey(WREX_GENERATED_CONTENT.MODIFY_SELECTION_SECTION)}>
                    <Menu.Item
                        onClick={() => onRefineContent('Rewrite the text for a better flow.', selectedText)}
                        icon={mdiAutoFix}
                        {...get({ element: 'refine-style' })}
                    >
                        {translateKey(WREX_GENERATED_CONTENT.MENU_REFINE_STYLE)}
                    </Menu.Item>

                    <Menu.ItemSubmenu
                        icon={mdiMicrophone}
                        label={translateKey(WREX_GENERATED_CONTENT.MENU_REFINE_TONE)}
                    >
                        {Object.entries({ ...SUGGESTED_FORMALITIES, ...SUGGESTED_TONES }).map(
                            ([key, { resume, refinePrompt, dataId }]) => (
                                <Menu.Item
                                    key={key}
                                    onClick={() => onRefineContent(refinePrompt, selectedText)}
                                    {...get({ element: `tov-${dataId}` })}
                                >
                                    {translateKey(resume)}
                                </Menu.Item>
                            ),
                        )}
                    </Menu.ItemSubmenu>
                    <Menu.Item
                        icon={mdiSortVariant}
                        onClick={() =>
                            onRefineContent('Shorten this text while maintaining its core message', selectedText)
                        }
                        {...get({ element: 'shorten' })}
                    >
                        {translateKey(WREX_GENERATED_CONTENT.MENU_REFINE_SHORTEN)}
                    </Menu.Item>
                    <Menu.Item
                        onClick={() =>
                            onRefineContent('Provide a more in-depth explanation for this text.', selectedText)
                        }
                        icon={mdiMenu}
                        {...get({ element: 'lengthen' })}
                    >
                        {translateKey(WREX_GENERATED_CONTENT.MENU_REFINE_LENGTHEN)}
                    </Menu.Item>
                </Menu.Section>
            </Menu>
        </Menu.Trigger>
    );
};
