import BaseApi, { dangerousAxiosInstanceUseOnlyIfNeeded, formatLanguageHeader, PRIORITY } from '@lumapps/base-api';
import { ServerListResponse } from '@lumapps/base-api/types';
import { CACHE_TYPE, generateCacheKey, cache } from '@lumapps/cache';
import { get } from '@lumapps/constants';
import { getFirstAlternativeLanguage } from '@lumapps/languages/utils';

import { SEGMENT_LIST_PAGE_SIZE } from '../constants';
import { Field, FieldCode, FieldValue, Segment, SegmentExportStatus, SegmentQuery, SegmentWithContext } from '../types';

export type GetSegmentsApiResponse = ServerListResponse<Segment>;
export interface GetSegmentSizeApiResponse {
    approxCount: number;
    computedAt: string;
}

export interface GetSegmentRequest {
    segmentId: string;
}
export interface GetSegmentsRequest {
    siteId: string;
    maxResults?: number;
    cursor?: string;
    title?: string;
    status?: string;
    isGroup?: boolean;
    isUsableInAnalytics?: boolean;
}

// we need to override browser languages to make sure that backend do not throw an error
// when receiving a description set in once of the languages supported by the customer but not
// listed by default in the header
const constants = get();
const headerLanguages = [constants.userLang, getFirstAlternativeLanguage()];
const headers = { 'Accept-Language': formatLanguageHeader(headerLanguages) };

export const segmentApi = new BaseApi({
    path: 'segments',
    version: BaseApi.versions.v2,
});

export const getSegment = async (params: GetSegmentRequest) => {
    return segmentApi.get<SegmentWithContext>(`/${params.segmentId}`, {
        headers,
        params: {
            siteId: constants.instanceId,
        },
    });
};

export const getSegmentWithCache = async (params: GetSegmentRequest) => {
    const url = `/${params.segmentId}`;
    return segmentApi.getCacheFirst<SegmentWithContext>(
        url,
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
        {
            headers,
            params: {
                siteId: constants.instanceId,
            },
        },
        undefined,
        true,
        url,
    );
};

/**
 * Cancel any call to segment api with given params.
 */
export const cancelGetSegment = (segmentId: string) => {
    if (!segmentId) {
        return;
    }
    const url = `/${segmentId}`;
    segmentApi.cancel(url, undefined, url);
};

export const getSegments = async (params: GetSegmentsRequest) => {
    return segmentApi.get<GetSegmentsApiResponse>('', {
        headers,
        params: {
            maxResults: SEGMENT_LIST_PAGE_SIZE,
            ...params,
        },
    });
};

export const createSegment = (segment: Segment, siteId: string) => {
    return segmentApi.post<Segment>('', { ...segment, siteId }, { headers });
};

export const editSegment = (segment: Segment, siteId: string) => {
    return segmentApi.put<Segment>(`/${segment.id}`, { ...segment, siteId }, { headers });
};

export const calculateSegmentSizeFromQuery = ({ query }: { query: SegmentQuery }) => {
    return segmentApi.post<GetSegmentSizeApiResponse>(
        '/query/preview',
        { query },
        {
            params: {
                siteId: constants.instanceId,
            },
        },
    );
};

export const calculateSegmentSize = ({ segmentId }: { segmentId: string }) => {
    return segmentApi.get<GetSegmentSizeApiResponse>(`/${segmentId}/preview`, {
        params: { siteId: constants.instanceId },
    });
};

export interface PreviewSegmentAudienceParams {
    includes: string[];
}

export interface PreviewSegmentAudienceResponse {
    approxCount: number;
}

/** Compute statistics about an audience. */
export const previewSegmentAudience = (body: PreviewSegmentAudienceParams) => {
    return segmentApi.postCacheFirst<PreviewSegmentAudienceResponse>(
        '/audiences/preview',
        body,
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
        {
            params: {
                siteId: constants.instanceId,
            },
        },
    );
};

/** Get the cached value of the calculated audience size, if it exists, or return undefined */
export const getCachedPreviewAudience = (params: PreviewSegmentAudienceParams) => {
    const key = generateCacheKey(segmentApi.getCompleteUrl('/audiences/preview'), params);
    const audienceSize = cache.retrieve(key, CACHE_TYPE.MEMORY)?.approxCount;

    return typeof audienceSize === 'number' ? audienceSize : undefined;
};

export interface GetSegmentUserFieldsResponse {
    items: Field[];
}

export const getSegmentUserFields = ({ siteId }: { siteId: string }) => {
    return segmentApi.getCacheFirst<GetSegmentUserFieldsResponse>('/user-fields', CACHE_TYPE.MEMORY, PRIORITY.HIGH, {
        params: {
            siteId,
        },
    });
};

export interface GetSegmentUserProfileFieldValuesRequest {
    code: FieldCode;
    value?: string;
    cursor?: string;
    maxResults?: number;
}

export interface GetSegmentUserProfileFieldValuesResponse {
    code: FieldCode;
    availableValues: FieldValue[];
    cursor?: string;
    more: boolean;
}

export const getSegmentUserProfileFieldValues = (
    params: GetSegmentUserProfileFieldValuesRequest,
    requestKey?: string,
) => {
    const finalParams: Partial<GetSegmentUserProfileFieldValuesRequest> = {
        maxResults: params?.maxResults || SEGMENT_LIST_PAGE_SIZE,
    };
    if (params?.cursor) {
        finalParams.cursor = params.cursor;
    }
    if (params?.value && params?.value !== '') {
        finalParams.value = params.value;
    }
    return segmentApi.getCacheFirst<GetSegmentUserProfileFieldValuesResponse>(
        `/user-fields/${params.code}/values`,
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
        {
            params: {
                ...finalParams,
                siteId: constants.instanceId,
            },
        },
        true,
        true,
        requestKey,
    );
};

export const cancelSegmentExport = () => {
    return segmentApi.cancelAll();
};

export interface LaunchSegmentExportResponse {
    exportId: string;
}

export const launchSegmentExport = ({ query, name }: { query: SegmentQuery; name: string }) => {
    const url = 'query/exports';
    return segmentApi.post<LaunchSegmentExportResponse>(
        url,
        { query, name },
        {
            params: {
                siteId: constants.instanceId,
            },
        },
        true,
    );
};

export interface GetSegmentExportStatus {
    id: string;
    status: SegmentExportStatus;
    url?: string;
}

export const getSegmentExportStatus = ({ exportId }: LaunchSegmentExportResponse) => {
    return segmentApi.get<GetSegmentExportStatus>(
        `/query/exports/${exportId}`,
        {
            params: {
                siteId: constants.instanceId,
            },
        },
        undefined,
        true,
    );
};

/**
 * Get upload url
 * https://api-doc.lumapps.net/#operation/core.segment.adapters.http.files.create_file
 */
export interface GetSegmentCsvUploadUrlParams {
    fileName: string;
}
export interface GetSegmentCsvUploadUrlResponse {
    id: string;
    uploadUrl: string;
}
export const getSegmentCsvUploadUrl = ({ fileName }: GetSegmentCsvUploadUrlParams) => {
    return segmentApi.post<GetSegmentCsvUploadUrlResponse>(
        '/files',
        { fileName },
        {
            params: {
                siteId: constants.instanceId,
            },
        },
    );
};

/**
 * Upload csv from signed url
 * https://leandrodamascena.medium.com/understanding-resumable-upload-in-google-cloud-storage-and-curl-example-9988534de0a6
 */
export interface UploadSegmentCsvParams {
    signedUrl: string;
    file: File;
}
export const uploadSegmentCsv = ({ signedUrl, file }: UploadSegmentCsvParams) => {
    const headers = { 'Content-Type': 'text/csv' };
    return dangerousAxiosInstanceUseOnlyIfNeeded({
        method: 'put',
        url: signedUrl,
        data: file,
        headers,
    });
};

/**
 * Get csv upload status
 * https://api-doc.lumapps.net/#operation/core.segment.adapters.http.files.get_status
 */
export interface GetSegmentUploadStatusParams {
    fileId: string;
}
export interface GetSegmentUploadStatusResponse {
    lineCount: number;
    columnCount: number;
}
export const getSegmentUploadStatus = ({ fileId }: GetSegmentUploadStatusParams) => {
    return segmentApi.get<GetSegmentUploadStatusResponse>(`/files/${fileId}/status`, {
        params: {
            siteId: constants.instanceId,
        },
    });
};

/**
 *
 */
export interface GetSegmentFileDownloadUrlParams {
    fileId: string;
}
export interface GetSegmentFileDownloadUrlResponse {
    downloadUrl: string;
}
export const getSegmentFileDownloadUrl = ({ fileId }: GetSegmentFileDownloadUrlParams) => {
    return segmentApi.get<GetSegmentFileDownloadUrlResponse>(`/files/${fileId}/download-url`, {
        params: {
            siteId: constants.instanceId,
        },
    });
};
