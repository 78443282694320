import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { EditLinkPreviewDialog } from '@lumapps/lumx-preview/components/LinkPreview/EditLinkPreviewDialog';
import type { LinkPreviewMetadata } from '@lumapps/lumx-preview/types';
import { mdiLink } from '@lumapps/lumx/icons';
import { Alignment, FlexBox, Icon, Kind, Message, Size, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { WREX_CORE } from '@lumapps/wrex/keys';
import { ReactEditor, useSelected, useSlateStatic } from '@lumapps/wrex/slate';
import { useInlineVoid } from '@lumapps/wrex/slate/utils/useInlineVoid';
import type { ElementRender } from '@lumapps/wrex/types';

import { EXTERNAL_RESOURCE_IMG_CONFIG, LINK_PREVIEW, PREVIEW_MAX_LENGTH } from '../../../constants';
import { WREX_LINK_PREVIEW } from '../../../keys';
import type { LinkPreviewEditor, LinkPreviewEditorOptions, LinkPreviewElement, Resource } from '../../../types';
import { handlePreviewChange } from '../../../utils/handlePreviewChange';
import { CLASSNAME, LinkPreview, RESOURCES_CONFIG } from '../../blocks/LinkPreview';
import { useResourceLinkProps } from '../../blocks/LinkPreview/useResourceLinkProps';
import { ActionButtons } from '../../buttons/ActionButtons';
import { ReplaceResourcePreviewDialog } from '../../dialog/ReplaceResourcePreviewDialog';

import './index.scss';

type EditableLinkPreviewType = ElementRender<LinkPreviewElement & LinkPreviewEditorOptions, HTMLDivElement>;

/**
 * Display a link preview with actions buttons to either edit, replace or delete the preview item
 */
export const EditableLinkPreview: EditableLinkPreviewType = ({ element, children, className, ...forwardedProps }) => {
    const editorStatic = useSlateStatic() as ReactEditor & LinkPreviewEditor;
    const selected = useSelected();
    const currentElement = element as LinkPreviewElement;
    const { onFocus, onDelete } = useInlineVoid(editorStatic, currentElement);
    const { translateKey } = useTranslate();
    const [isEditDialogOpen, toggleEditDialog, closeEditDialog] = useBooleanState(false);
    const [isReplaceDialogOpen, toggleReplaceDialog, closeReplaceDialog] = useBooleanState(false);
    const { block } = useClassnames(CLASSNAME);
    const linkPreviewOptions = editorStatic.getLinkPreviewOptions();

    const { resource, url } = currentElement;

    const parentRef = React.useRef(null);

    const path = ReactEditor.findPath(editorStatic, currentElement);
    const pathRef = editorStatic.pathRef(path);

    const customLink = useResourceLinkProps(resource, url);

    const visibilityMessage = (
        <Message className={margin('top')} kind={Kind.info} hasBackground>
            {translateKey(WREX_CORE.THUMBNAIL_VISIBILITY)}
        </Message>
    );

    const thumbnailFallback = (
        <FlexBox as="span" hAlign={Alignment.center} vAlign={Alignment.center}>
            <Icon icon={resource ? RESOURCES_CONFIG.icons[resource.type] : mdiLink} size={Size.m} theme={Theme.light} />
        </FlexBox>
    );

    /**
     * Computes preview object to forward to EditLinkPreviewDialog
     */
    const computePreviewElement = (
        currentEl: LinkPreviewElement,
    ): Partial<LinkPreviewMetadata> & { resource?: Resource } => {
        const { description, title, url, thumbnailUrl, resource } = currentEl;
        const image = thumbnailUrl || resource?.thumbnailUrl;

        return {
            title: title || resource?.title,
            description: description || resource?.description,
            images: image ? [image] : [],
            url,
            thumbnailIndex: 0,
            resource,
        };
    };

    return (
        <>
            <LinkPreview
                className={block({
                    editable: true,
                    selected,
                })}
                element={element}
                onFocus={onFocus}
                tabIndex={0}
                {...forwardedProps}
            >
                <ActionButtons
                    actionRef={parentRef}
                    hasReplaceButton={!!resource}
                    onDelete={onDelete}
                    onEdit={toggleEditDialog}
                    onReplace={toggleReplaceDialog}
                />
                {children}
            </LinkPreview>

            <ReplaceResourcePreviewDialog
                isOpen={isReplaceDialogOpen}
                onClose={closeReplaceDialog}
                onChange={(link) => handlePreviewChange(editorStatic, pathRef, link)}
                parentElement={parentRef}
                resourceType={resource?.type}
            />

            <EditLinkPreviewDialog
                className={className}
                editImageOptions={{
                    className: margin('top', Size.huge),
                    thumbnailProps: {
                        ...EXTERNAL_RESOURCE_IMG_CONFIG,
                    },
                    after: linkPreviewOptions.uploadMediaOptions.arePublic === true ? visibilityMessage : undefined,
                    uploadMediaOptions: linkPreviewOptions.uploadMediaOptions,
                }}
                isOpen={isEditDialogOpen}
                onChange={(link) => handlePreviewChange(editorStatic, pathRef, link)}
                onClose={closeEditDialog}
                parentElement={parentRef}
                preview={computePreviewElement(currentElement)}
                descriptionFieldProps={{
                    maxLength: PREVIEW_MAX_LENGTH,
                }}
                previewImgProps={{
                    fallback: thumbnailFallback,
                    ...EXTERNAL_RESOURCE_IMG_CONFIG,
                }}
                title={resource ? translateKey(WREX_LINK_PREVIEW.EDIT_RESOURCE_PREVIEW) : undefined}
                titleFieldProps={{
                    maxLength: PREVIEW_MAX_LENGTH,
                }}
                customLink={customLink}
                // we display an image in the uploader only when the resource/link thumbnail has been overridden. Otherwise the uploader should display without any image
                selectedMedia={
                    element?.thumbnailUrl && element?.thumbnailUrl !== resource?.thumbnailUrl
                        ? {
                              url: element?.thumbnailUrl,
                          }
                        : undefined
                }
            />
        </>
    );
};
EditableLinkPreview.displayName = LINK_PREVIEW;

export const editableLinkPreviewWithOptions = (options: LinkPreviewEditorOptions) => {
    const Enhanced: EditableLinkPreviewType = (props) => <EditableLinkPreview {...props} {...options} />;
    Enhanced.displayName = EditableLinkPreview.displayName;

    return Enhanced;
};
