import type { ExtensionTopic } from './types';

export enum MARKETPLACE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107549358
     */
    ACTIVATED = 'MARKETPLACE.ACTIVATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107549333
     */
    ACTIVATION_STATUS = 'MARKETPLACE.ACTIVATION_STATUS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=133956118
     */
    ALL_EXTENSIONS_UP_TO_DATE = 'MARKETPLACE.ALL_EXTENSIONS_UP_TO_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108379184
     */
    AVAILABLE_UPDATES = 'MARKETPLACE.AVAILABLE_UPDATES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113255244
     */
    CONFIRM_UNINSTALL_EXTENSIONS = 'MARKETPLACE.CONFIRM_UNINSTALL_EXTENSIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444789885
     */
    CHOOSE_EXTENSION_CONNECTORS = 'MARKETPLACE.CHOOSE_EXTENSION_CONNECTORS',
    /**
     * @todo: [MA-709] Clean unused lokalise keys in repositories
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170418277
     */
    CONNECTOR = 'MARKETPLACE.CONNECTOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444565394
     */
    CONNECTOR_LIST_ERROR = 'MARKETPLACE.CONNECTOR_LIST_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=132656700
     */
    DAY_SINCE_UPDATE = 'MARKETPLACE.DAY_SINCE_UPDATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107549370
     */
    DEACTIVATED = 'MARKETPLACE.DEACTIVATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=433734846
     */
    DISABLED = 'MARKETPLACE.EXTENSION_DISABLED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=228465861
     */
    EXTENSION_CONNECTORS = 'ADMIN.MARKETPLACE.EXTENSION.SETTINGS.CONNECTORS_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=500377961
     */
    EXTENSION_RUNS_WILL_BE_LOGGED = 'MARKETPLACE.EXTENSION_RUNS_WILL_BE_LOGGED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79526570
     */
    EXTENSION_COULD_NOT_LOAD = 'FRONT.MARKETPLACE.EXTENSION_COULD_NOT_LOAD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=500377905
     */
    EXTENSION_SAVE_RUNS = 'MARKETPLACE.EXTENSION_SAVE_RUNS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=61011590
     */
    EXTENSION_SETTINGS = 'ADMIN.MARKETPLACE.EXTENSION.SETTINGS_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109098362
     */
    EXTENSION_SETTINGS_SAVED = 'MARKETPLACE.EXTENSION_SETTINGS_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=230820233
     */
    EXTENSION_TAB_CONNECTOR = 'ADMIN.MARKETPLACE.EXTENSION.SETTINGS.CONNECTORS_TAB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470786454
     */
    EXTENSION_TAB_RUN_HISTORY = 'ADMIN.MARKETPLACE.EXTENSION.SETTINGS.RUN_HISTORY_TAB',
    /**
     * Use to call dynamically all the extension type key below
     */
    EXTENSION_TYPE = 'MARKETPLACE.EXTENSION_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108347368
     */
    EXTENSION_TYPE_BACKEND = 'MARKETPLACE.EXTENSION_TYPE_BACKEND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=248386037
     */
    EXTENSION_TYPE_BROADCAST = 'MARKETPLACE.EXTENSION_TYPE_BROADCAST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=168770921
     */
    EXTENSION_TYPE_LOGIN = 'MARKETPLACE.EXTENSION_TYPE_LOGIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=384657001
     */
    EXTENSION_TYPE_MICRO_APP = 'MARKETPLACE.EXTENSION_TYPE_MICRO_APP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=168771022
     */
    EXTENSION_TYPE_PROVISIONING = 'MARKETPLACE.EXTENSION_TYPE_PROVISIONING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=168771069
     */
    EXTENSION_TYPE_SEARCH = 'MARKETPLACE.EXTENSION_TYPE_SEARCH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108347634
     */
    EXTENSION_TYPE_SHARE_TO = 'MARKETPLACE.EXTENSION_TYPE_SHARE_TO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108347373
     */
    EXTENSION_TYPE_THIRD_PARTY = 'MARKETPLACE.EXTENSION_TYPE_THIRD_PARTY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108347615
     */
    EXTENSION_TYPE_WIDGET = 'MARKETPLACE.EXTENSION_TYPE_WIDGET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=208819834
     */
    EXTENSION_UNAVAILABLE = 'MARKETPLACE.EXTENSION_UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=58314086
     */
    EXTENSION_UNINSTALL_CONFIRM = 'ADMIN.MARKETPLACE.EXTENSION.UNINSTALL.CONFIRM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=58314176
     */
    EXTENSION_UNINSTALL_CONFIRM_TITLE = 'ADMIN.MARKETPLACE.EXTENSION.UNINSTALL.CONFIRM_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=61011968
     */
    EXTENSION_UNINSTALLED = 'ADMIN.MARKETPLACE.EXTENSION.UNINSTALLED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108143376
     */
    EXTENSION_UPDATED = 'MARKETPLACE.EXTENSION_UPDATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108367316
     */
    EXTENSION_WILL_BE_AVAILABLE = 'MARKETPLACE.EXTENSION_WILL_BE_AVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=33869274
     */
    EXTENSION_LOADIND_INSTALL = 'ADMIN.MARKETPLACE.EXTENSION.LOADING_INSTALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=39011392
     */
    EXTENSION_ACTIVATED = 'ADMIN.MARKETPLACE.EXTENSION.ACTIVATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=39014680
     */
    EXTENSION_DEACTIVATED = 'ADMIN.MARKETPLACE.EXTENSION.DEACTIVATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=84409543
     */
    EXTENSIONS_LIBRARY = 'ADMIN.NAV.EXTENSIONS_LIBRARY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37036113
     */
    EXTENSIONS_SEE_MORE = 'ADMIN.EXTENSIONS.SEE_MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30337771
     */
    GLOBAL_SETTINGS = 'ADMIN.EXTENSIONS.GLOBAL_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=512382563
     */
    GROUP_VISIBILITY_HELPER = 'MARKETPLACE.GROUP_VISIBILITY_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=341204063
     */
    HAS_CUSTOMIZATION_API_SCRIPT = 'MARKETPLACE.EXTENSION_HAS_CUSTOMIZATION_SCRIPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108379208
     */
    INSTALL_UPDATES = 'MARKETPLACE.INSTALL_UPDATES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=131249206
     */
    INSTALLED_AFTER = 'MARKETPLACE.INSTALLED_AFTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=131249156
     */
    INSTALLED_BEFORE = 'MARKETPLACE.INSTALLED_BEFORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108382166
     */
    INSTALLED_BY = 'MARKETPLACE.INSTALLED_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=84380193
     */
    INSTALLED_EXTENSIONS = 'ADMIN.NAV.INSTALLED_EXTENSIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108382154
     */
    INSTALLED_ON = 'MARKETPLACE.INSTALLED_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107549312
     */
    INSTALLATION = 'MARKETPLACE.INSTALLATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=131248873
     */
    INSTALLATION_DATE = 'MARKETPLACE.INSTALLATION_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=33970109
     */
    INSTALLATION_SUCCESS = 'ADMIN.MARKETPLACE.INSTALLATION_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=33970111
     */
    INSTALLATION_ERROR = 'ADMIN.MARKETPLACE.INSTALLATION_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=132539460
     */
    NEW_UPDATE_AVAILABLE = 'MARKETPLACE.NEW_UPDATE_AVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78959544
     */
    OAUTH_APP = 'ADMIN.MARKETPLACE.APPLICATIONS.OAUTH_APP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108379442
     */
    PARTNER = 'MARKETPLACE.PARTNER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108379190
     */
    PARTNER_NEW_UPDATE = 'MARKETPLACE.PARTNER_NEW_UPDATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28500957
     */
    PLATFORM_EXTENSIONS = 'ADMIN.NAV.PLATFORM_EXTENSIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=56209667
     */
    PLATFORM_EXTENSIONS_OLD = 'ADMIN.NAV.PLATFORM_EXTENSIONS_OLD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444564661
     */
    PROVIDER_ERROR = 'MARKETPLACE.PROVIDER_LIST_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108373053
     */
    REMOVE_EXTENSION_FROM_PLATFORM = 'MARKETPLACE.REMOVE_EXTENSION_FROM_PLATFORM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78958078
     */
    SEE_MORE = 'ADMIN.MARKETPLACE.APPLICATIONS.SEE_MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=321016234
     */
    SELECT_CONNECTOR = 'MARKETPLACE.SELECT_CONNECTOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78958201
     */
    SELECT_OAUTH = 'ADMIN.MARKETPLACE.APPLICATIONS.SELECT_OAUTH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=208819155
     */
    UNAVAILABLE = 'MARKETPLACE.UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=208820086
     */
    UNAVAILABLE_REPLACE = 'MARKETPLACE.UNAVAILABLE_REPLACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=58314077
     */
    UNINSTALL = 'ADMIN.MARKETPLACE.EXTENSION.UNINSTALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108372453
     */
    UNINSTALL_EXTENSION = 'MARKETPLACE.UNINSTALL_EXTENSION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113275230
     */
    UNINSTALL_EXTENSIONS = 'MARKETPLACE.UNINSTALL_EXTENSIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183788286
     */
    UPGRADED_ON = 'MARKETPLACE.UPGRADED_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108379267
     */
    VERSION = 'MARKETPLACE.VERSION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=260776656
     */
    VERSION_MOBILE_COMPATIBLE = 'MARKETPLACE.VERSION_MOBILE_COMPATIBLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=260782941
     */
    VERSION_MOBILE_INCOMPATIBLE = 'MARKETPLACE.VERSION_MOBILE_INCOMPATIBLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449064908
     */
    EXTENSIONS_TOPIC_ACCOUNTING_AND_FINANCE = 'MARKETPLACE.EXTENSIONS_TOPIC_ACCOUNTING_AND_FINANCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449068000
     */
    EXTENSIONS_TOPIC_ADMINISTRATION_AND_MANAGEMENT = 'MARKETPLACE.EXTENSIONS_TOPIC_ADMINISTRATION_AND_MANAGEMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449064934
     */
    EXTENSIONS_TOPIC_ANALYTICS = 'MARKETPLACE.EXTENSIONS_TOPIC_ANALYTICS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449064964
     */
    EXTENSIONS_TOPIC_CHAT_AND_COMMUNICATION = 'MARKETPLACE.EXTENSIONS_TOPIC_CHAT_AND_COMMUNICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065057
     */
    EXTENSIONS_TOPIC_COLLABORATION = 'MARKETPLACE.EXTENSIONS_TOPIC_COLLABORATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065112
     */
    EXTENSIONS_TOPIC_DOCUMENT_AND_FILE_MANAGEMENT = 'MARKETPLACE.EXTENSIONS_TOPIC_DOCUMENT_AND_FILE_MANAGEMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065135
     */
    EXTENSIONS_TOPIC_EDUCATION_AND_LEARNINGS = 'MARKETPLACE.EXTENSIONS_TOPIC_EDUCATION_AND_LEARNINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065169
     */
    EXTENSIONS_TOPIC_ERP_AND_LOGISTICS = 'MARKETPLACE.EXTENSIONS_TOPIC_ERP_AND_LOGISTICS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065178
     */
    EXTENSIONS_TOPIC_GAMIFICATION = 'MARKETPLACE.EXTENSIONS_TOPIC_GAMIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065195
     */
    EXTENSIONS_TOPIC_GEOLOCATION_AND_MAP_SERVICES = 'MARKETPLACE.EXTENSIONS_TOPIC_GEOLOCATION_AND_MAP_SERVICES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065258
     */
    EXTENSIONS_TOPIC_HR_AND_LEGAL = 'MARKETPLACE.EXTENSIONS_TOPIC_HR_AND_LEGAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065288
     */
    EXTENSIONS_TOPIC_IAM_SERVICES = 'MARKETPLACE.EXTENSIONS_TOPIC_IAM_SERVICES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065313
     */
    EXTENSIONS_TOPIC_IT_AND_HELP_DESK = 'MARKETPLACE.EXTENSIONS_TOPIC_IT_AND_HELP_DESK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065342
     */
    EXTENSIONS_TOPIC_KNOWLEDGE = 'MARKETPLACE.EXTENSIONS_TOPIC_KNOWLEDGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065357
     */
    EXTENSIONS_TOPIC_MARKETING = 'MARKETPLACE.EXTENSIONS_TOPIC_MARKETING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065368
     */
    EXTENSIONS_TOPIC_NEWS_AND_WEATHER = 'MARKETPLACE.EXTENSIONS_TOPIC_NEWS_AND_WEATHER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065381
     */
    EXTENSIONS_TOPIC_OPERATIONS = 'MARKETPLACE.EXTENSIONS_TOPIC_OPERATIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065402
     */
    EXTENSIONS_TOPIC_SALES_AND_CRM = 'MARKETPLACE.EXTENSIONS_TOPIC_SALES_AND_CRM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065485
     */
    EXTENSIONS_TOPIC_SEARCH_SERVICES = 'MARKETPLACE.EXTENSIONS_TOPIC_SEARCH_SERVICES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065507
     */
    EXTENSIONS_TOPIC_TASK_MANAGEMENT = 'MARKETPLACE.EXTENSIONS_TOPIC_TASK_MANAGEMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065530
     */
    EXTENSIONS_TOPIC_TRAVEL_AND_EXPENSES = 'MARKETPLACE.EXTENSIONS_TOPIC_TRAVEL_AND_EXPENSES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449065587
     */
    EXTENSIONS_TOPIC_UTILITIES = 'MARKETPLACE.EXTENSIONS_TOPIC_UTILITIES',
}

export const getExtensionsTopicKey = (topic: ExtensionTopic) => `MARKETPLACE.EXTENSIONS_TOPIC_${topic.toUpperCase()}`;
