import castArray from 'lodash/castArray';
import flatMap from 'lodash/flatMap';
import mdast from 'mdast';
import gfm from 'mdast-util-gfm';
import mdastToMarkdown from 'mdast-util-to-markdown';
import unist from 'unist';

import { isElement } from '../../../slate/utils/isElement';
import { isText } from '../../../slate/utils/isText';
import type { Wrex } from '../../../types';
import { ZERO_WIDTH_SPACE } from '../constants';
import { OPTIONS } from './options';

function convertTextNode(node: Wrex.Text): unist.Node[] {
    const { text, ...marks } = node;
    const nodes: unist.Node[] = [];

    // Convert leading spaces.
    const leadingSpaces = text.match(/^\s+/);
    if (leadingSpaces) {
        nodes.push({ type: 'text', value: leadingSpaces[0].replace(/ +/, ' ').replace(/\n+/, '\n') } as mdast.Text);
    }

    // Convert nested mark nodes.
    const hasMarks = Object.values(marks).some(Boolean);
    const textNode = {
        type: 'text',
        // We need to wrapp the text with zero width space to always have the text parsed as mark.
        // correctly in markdown even if the first and/or last character is a markdown delimiter.
        value: hasMarks ? `${ZERO_WIDTH_SPACE}${text.trim()}${ZERO_WIDTH_SPACE}` : `${text.trim()}`,
    };
    const textNodeInMarks = Object.entries(marks).reduce((acc, [mark, markActivated]) => {
        const markConverter = OPTIONS.marks[mark];
        if (markConverter && markActivated) {
            return markConverter(castArray(acc));
        }
        return acc;
    }, textNode as unist.Node);
    nodes.push(textNodeInMarks);

    // Convert trailing spaces.
    const trailingSpaces = text.match(/\s+$/);
    if (trailingSpaces) {
        nodes.push({ type: 'text', value: trailingSpaces[0].replace(/ +/, ' ').replace(/\n+/, '\n') } as mdast.Text);
    }
    return nodes;
}

/**
 * Convert slate node into MDAST nodes.
 */
function convertNode(node: Wrex.Node): unist.Node[] {
    // Convert text node.
    if (isText(node)) {
        return convertTextNode(node);
    }

    // Convert children nodes.
    const children = flatMap(node.children, convertNode);

    // Convert element node.
    if (isElement(node)) {
        const elementConverter = OPTIONS.elements[node.type];
        if (elementConverter) {
            return [elementConverter(node, children)];
        }
    }

    // Unknown slate node: returning children.
    return children;
}

export function toMarkdown(nodes: Wrex.Nodes = []): string {
    return mdastToMarkdown(
        {
            type: 'root',
            children: flatMap(nodes, convertNode),
        },
        {
            extensions: [gfm.toMarkdown()],
            handlers: OPTIONS.customElements,
            emphasis: '*',
            // we use 'resourceLink: true' for to avoid wrapping the link in <>, because links did not open on android
            resourceLink: true,
            // Use "`" fences instead of tabulation for code blocks
            fences: true,
        },
    );
}
