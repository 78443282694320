import React from 'react';

import { ServerListResponse } from '@lumapps/base-api';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import type { Video } from '@lumapps/play/api/types';
import { GLOBAL, withApiv1Languages } from '@lumapps/translations';
import { FetchCallback, useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { cancelGetPlaylistVideos, getPlaylistVideos } from '../../api';
import type { GetPlaylistVideosParams } from '../../api/types';

export const useFetchPlaylistVideos = () => {
    const { error: showErrorNotification } = useNotification();
    const [videos, setVideos] = React.useState<Video[]>();
    const { cancelFetch, fetch, fetchParams, response, status } = useFetchWithStatus({
        onFetch: getPlaylistVideos,
        onFetchCancel: cancelGetPlaylistVideos,
    });

    const handleFetchCallback = React.useCallback<FetchCallback<ServerListResponse<Video>>>(
        ({ success, response: callbackResponse }) => {
            if (success && callbackResponse) {
                setVideos(withApiv1Languages(callbackResponse.items));
            } else {
                showErrorNotification({ translate: GLOBAL.GENERIC_ERROR });
            }
        },
        [showErrorNotification],
    );

    const fetchPlaylistVideos = React.useCallback(
        ({ fields, id, maxResults, thumbnailSizes }: GetPlaylistVideosParams) => {
            fetch({
                callback: handleFetchCallback,
                debounce: true,
                params: {
                    id,
                    ...(maxResults && { maxResults }),
                    ...(fields && { fields }),
                    ...(thumbnailSizes && { ...thumbnailSizes }),
                },
            });
        },
        [fetch, handleFetchCallback],
    );

    React.useEffect(() => {
        return () => {
            if (status === BaseLoadingStatus.loading) {
                cancelFetch({ params: [fetchParams] });
            }
        };
    }, [cancelFetch, fetchParams, status]);

    return {
        fetchPlaylistVideos,
        response,
        status,
        videos,
    };
};
