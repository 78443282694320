import React from 'react';

import keyBy from 'lodash/keyBy';
import noop from 'lodash/noop';
import range from 'lodash/range';

import { useClassnames } from '@lumapps/classnames';
import { GenericProps, List, ListItem, Popover, SkeletonCircle, SkeletonTypography, Text } from '@lumapps/lumx/react';
import { useVirtualFocus, useVirtualFocusParent } from '@lumapps/moving-focus';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { InfiniteScroll } from '@lumapps/utils/hooks/useInfiniteScroll';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';
import { ReactEditor, useFocused, useSlateStatic } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { MarkRender } from '@lumapps/wrex/types';

import { INLINE_AUTOCOMPLETE_SEARCH } from '../../../constants';
import type { InlineAutocompleteEditor } from '../../../types';
import { useInlineAutocompleteSearchContext } from '../context';

import './index.scss';

const CLASSNAME = 'wrex-autocomplete-search';

/** Option for the inline autocomplete search list box. */
const ItemOption: React.FC<GenericProps> = ({ item, label, id, className, onItemSelected, listItemProps = {} }) => {
    const ref = React.useRef<HTMLLIElement>(null);
    const isHighlighted = useVirtualFocus(id, ref);
    const editorStatic = useSlateStatic() as ReactEditor & InlineAutocompleteEditor;

    const onMouseDown = React.useCallback(
        (event: MouseEvent) => {
            editorStatic.insertAutocompletedElement(item);
            onItemSelected?.(item);
            // Used to not trigger clickaway in widget RTE / AskAIEditor
            event.stopPropagation();
            event.preventDefault();

            focusAt(editorStatic, editorStatic.selection?.anchor);
        },
        [editorStatic, item, onItemSelected],
    );

    return (
        <ListItem
            ref={ref}
            role="none"
            linkProps={{ role: 'option', id, tabIndex: -1 }}
            size="tiny"
            isHighlighted={isHighlighted}
            onItemSelected={noop}
            onMouseDown={onMouseDown}
            title={label}
            className={className}
            {...listItemProps}
        >
            {label}
        </ListItem>
    );
};

const skeletons = range(3).map((i) => (
    <ListItem key={i} aria-hidden size="tiny" before={<SkeletonCircle size="s" />}>
        <SkeletonTypography typography="body1" width="90%" />
    </ListItem>
));

/** Autocomplete search text with popover listbox. */
export const InlineAutocompleteSearch: MarkRender<typeof INLINE_AUTOCOMPLETE_SEARCH> = ({
    attributes,
    leaf,
    children,
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const anchorRef = React.useRef(null);

    // Static editor (does not update)
    const editorStatic = useSlateStatic() as ReactEditor & InlineAutocompleteEditor;
    const focused = useFocused();
    const [isOpen, , close, open] = useBooleanState(true);
    const options = editorStatic.getCurrentSearchOption();
    const { setHighlightedItem, setFetchHookOnItemSelected } = useInlineAutocompleteSearchContext();
    const textBoxRef = React.useMemo(
        () => ({ current: ReactEditor.toDOMNode(editorStatic, editorStatic) }),
        [editorStatic],
    );
    const listBoxOptionId = useVirtualFocusParent(textBoxRef);

    // Fetch entities
    const rawQuery = leaf.text;
    const queryReplaceRegex = new RegExp(`^\\${options?.triggerCharacter}`, 'g');
    const query = rawQuery.replace(queryReplaceRegex, '');
    const { status, entities, hasMore, fetchMore, onItemSelected } = options?.fetchHook(query) || {};
    const isLoading = status === BaseLoadingStatus.loading || status === BaseLoadingStatus.debouncing;
    const hasData = Boolean(entities && entities.length);
    const isIdle = status === BaseLoadingStatus.idle;
    const isEmpty = isIdle && !hasData;

    // Entity by id
    const itemIndexRef = React.useRef<Record<string, any> | undefined>();
    React.useEffect(() => {
        itemIndexRef.current = entities && keyBy(entities, options?.getEntityId);
    }, [entities, options?.getEntityId]);

    // Update highlighted entity in parent context (to set the aria-activedescendant)
    React.useEffect(() => {
        const entity = listBoxOptionId && itemIndexRef.current?.[listBoxOptionId];
        if (isOpen && entity) {
            setHighlightedItem(entity);
            return () => setHighlightedItem(undefined);
        }
        return undefined;
    }, [isOpen, listBoxOptionId, setHighlightedItem]);

    React.useEffect(() => {
        if (isOpen && !!onItemSelected) {
            setFetchHookOnItemSelected(() => onItemSelected);
        }
    }, [isOpen, onItemSelected, setFetchHookOnItemSelected]);

    // Open popover on query change
    React.useEffect(() => {
        if (query) {
            open();
        }
    }, [open, query]);

    React.useEffect(() => {
        if (
            // Search empty (no trigger character)
            !rawQuery ||
            // Focus lost
            !focused ||
            // When typing space at the beginning
            query.match(/^\s/) ||
            // When no entity found and typing space at the end.
            (isEmpty && query.match(/\s$/))
        ) {
            editorStatic.closeAutocompleteSearch();
        }
    }, [editorStatic, focused, isEmpty, rawQuery, query]);

    return (
        <>
            <Text ref={anchorRef} as="span" color="primary" className={block()} {...attributes}>
                {children}
            </Text>

            <Popover
                anchorRef={anchorRef}
                placement="bottom-start"
                isOpen={isOpen && !isEmpty}
                onClose={close}
                closeOnEscape
                closeOnClickAway
                fitWithinViewportHeight
            >
                <List role="listbox" isClickable className={element('suggestions')}>
                    {isLoading && skeletons}
                    {!isLoading &&
                        entities?.map((entity) => {
                            return (
                                <ItemOption
                                    id={options?.getEntityId(entity)}
                                    label={options?.getEntityLabel(entity)}
                                    elementCreator={options?.elementCreator}
                                    key={options?.getEntityId(entity)}
                                    item={entity}
                                    className={element('suggestion-option')}
                                    listItemProps={options?.getListItemProps?.(entity) || {}}
                                    onItemSelected={onItemSelected}
                                />
                            );
                        })}
                    {isIdle && hasMore && fetchMore && <InfiniteScroll callback={fetchMore} />}
                    {hasMore && skeletons}
                </List>
            </Popover>
        </>
    );
};
InlineAutocompleteSearch.displayName = INLINE_AUTOCOMPLETE_SEARCH;
