import React from 'react';

import { useQuery, type UseQueryOptions } from '@lumapps/base-api/react-query';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import type { FileSize } from '@lumapps/play/types';
import { useSelector } from '@lumapps/redux/react';
import { connectedUserSelector, getSubscriptions } from '@lumapps/user/ducks/selectors';

import { getMe, playUsersQueryKeys } from '../api';

const DEFAULT_MAX_UPLOAD_SIZE = 4;

/**
 * Hook allowing us to retrieve the current connected Play user informations
 */
export const usePlayUser = (options?: UseQueryOptions) => {
    const currentUser = useSelector(connectedUserSelector);
    const instanceId = useSelector(instanceIdSelector);

    const { data, ...query } = useQuery({
        ...options,
        queryKey: playUsersQueryKeys.me(),
        queryFn: ({ signal }) => getMe(signal),
        staleTime: Infinity, // Will never be refetched again during the user session
    });

    const sitePermissions = data?.permissions.find((permission) => permission.scopeId === instanceId);

    const isOrgAdmin = data?.isOrgAdmin;

    const isPlayAdministrator = sitePermissions?.canManageAllVideos || false;
    const isPlayContributor = sitePermissions?.canManageOwnVideos || false;
    const isPlaylistAdministrator = sitePermissions?.canManageAllPlaylists || false;
    const isPlaylistContributor = sitePermissions?.canManageOwnPlaylists || false;

    const isAdminAuthorized = isOrgAdmin || isPlayAdministrator;

    const isAllowedManagingVideos = isOrgAdmin || isPlayAdministrator || isPlayContributor;
    const isAllowedManagingPlaylists = isOrgAdmin || isPlaylistAdministrator || isPlaylistContributor;
    const isAllowedUpdatingRoles = isOrgAdmin || sitePermissions?.canManageRoleAssignments || false;
    const isAllowedManagingConfigurations = isOrgAdmin || sitePermissions?.canManageConfigurations || false;

    const humanReadableMaxUploadSize: FileSize = data?.maxUploadSize
        ? `${data?.maxUploadSize}GB`
        : `${DEFAULT_MAX_UPLOAD_SIZE}GB`;

    const isAuthor = React.useCallback((createdById: string) => createdById === currentUser?.id, [currentUser?.id]);

    const subscriptions = useSelector(getSubscriptions);

    const hasEditorRightOnVideo = React.useCallback(
        (editableByGroupIds?: string[]) => {
            const subscriptionsIds = subscriptions?.map((subscription) => subscription.feed);

            const intersectionEditableGroup = [...new Set(subscriptionsIds)].filter((x) =>
                new Set(editableByGroupIds).has(x),
            );

            return Boolean(intersectionEditableGroup.length);
        },
        [subscriptions],
    );

    return React.useMemo(
        () => ({
            /** We pass it all the query except the data object: we should used derivated states exposed below */
            ...query,
            /** Whether the user can manage gallery page configuration */
            isAllowedManagingConfigurations,
            /** Whether the user can manage playlists */
            isAllowedManagingPlaylists,
            /** Whether the user has administrative access (as an organization or Play admin) */
            isAdminAuthorized,
            /** Whether the user can manage videos */
            isAllowedManagingVideos,
            /** Whether the user can manage play roles */
            isAllowedUpdatingRoles,
            /** Whether the user is author of a Play entity */
            isAuthor,
            /** Whether the user is an admin of the organization */
            isOrgAdmin,
            /** TODO */
            hasEditorRightOnVideo,
            /** Whether the user is a Play administrator (can manage all Play videos) */
            isPlayAdministrator,
            /** Whether the user is a Play contributor (can manage only its videos only) */
            isPlayContributor,
            /** Whether the user is a Playlist administrator (can manage all Play playlists) */
            isPlaylistAdministrator,
            /** Whether the user is a Playlist contributor (can manage its playlists only) */
            isPlaylistContributor,
            /* Maximum possible upload size for this user */
            maxUploadSize: data?.maxUploadSize,
            /**
             * Maximum possible upload size for this user
             *
             * ℹ️ Expressed as ${number}GB
             */
            humanReadableMaxUploadSize,
        }),
        [
            query,
            isAllowedManagingConfigurations,
            isAllowedManagingPlaylists,
            isAllowedManagingVideos,
            isAllowedUpdatingRoles,
            isAuthor,
            hasEditorRightOnVideo,
            isAdminAuthorized,
            isOrgAdmin,
            isPlayAdministrator,
            isPlayContributor,
            isPlaylistAdministrator,
            isPlaylistContributor,
            data?.maxUploadSize,
            humanReadableMaxUploadSize,
        ],
    );
};
