/* eslint-disable react/forbid-elements */
import React, { useMemo } from 'react';

import moment from 'moment';

import { useGenerateAriaId } from '@lumapps/aria-ids/hooks/useGenerateAriaId';
import { element, useClassnames } from '@lumapps/classnames';
import { CommunityLinkRef } from '@lumapps/communities/types';
import { ContentLinkRef } from '@lumapps/contents/types';
import { useDataAttributes } from '@lumapps/data-attributes';
import { AspectRatio, Heading, Theme, ThumbnailProps } from '@lumapps/lumx/react';
import { MetadataLinkList } from '@lumapps/metadata-link/components/MetadataLinkList';
import { useResponsive } from '@lumapps/responsive';
import { GLOBAL, TranslationAPI, useTranslate } from '@lumapps/translations';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { BlockImage } from '@lumapps/widget-base/components/Block/BlockImage';
import { BlockReactions } from '@lumapps/widget-base/components/Block/BlockReactions';
import { BlockReactionVariants } from '@lumapps/widget-base/components/Block/BlockReactions/types';
import { BlockUser } from '@lumapps/widget-base/components/Block/BlockUser';
import { useBlockBreakpoints } from '@lumapps/widget-base/hooks/useBlockBreakpoints';
import { BlockComponent, WidgetSizes } from '@lumapps/widget-base/types';
import { BlockHtml } from '@lumapps/widget-html/components/BlockHtml';

import { BlockPagePreview as BlockPagePreviewProps, BlockPagePreviewVariant, OrderElement } from '../../type';
import { sanitizeExcerpt } from '../../utils/sanitizeExcerpt';
import { BlockPagePreviewLink } from './BlockPagePreviewLink';
import { BlockPagePreviewPublicationInfo } from './BlockPagePreviewPublicationInfo';

import './index.scss';

const CLASSNAME = 'block-page-preview';

type BlockPagePreview = BlockComponent<BlockPagePreviewProps>;

type RenderFields = (options: {
    block: React.ComponentProps<BlockPagePreview>;
    translateKey: TranslationAPI['translateKey'];
    translateObject: TranslationAPI['translateObject'];
    contentLinkRef?: ContentLinkRef;
    theme?: Theme;
    linkData?: Partial<CommunityLinkRef>;
    isPreviewOnly?: boolean;
    getDataAttributes(options: any): any;
}) => React.ReactNode;

const RENDER_FIELDS: { [key in OrderElement]: RenderFields } = {
    // publicationInfo is in backend order but placement is hard coded in the component so we return null render for the map
    [OrderElement.publicationInfo]: () => {
        return null;
    },
    // image is in backend order but placement is hard coded in the component so we return null render for the map
    [OrderElement.image]: () => {
        return null;
    },
    [OrderElement.author]: ({ block, theme }) => {
        const value = block.author;
        if (!value) {
            return null;
        }
        return <BlockUser {...value} theme={theme} />;
    },
    [OrderElement.excerpt]: ({ block, translateObject, theme }) => {
        const translatedRichText = translateObject(block.richTextPreview);
        if (translatedRichText) {
            const sanitizedRichText = sanitizeExcerpt(translatedRichText);
            return <BlockHtml html={sanitizedRichText} theme={theme} />;
        }
        if (!block.excerpt) {
            return null;
        }
        return (
            <div className={element(CLASSNAME)('excerpt')} dir="auto">
                {block.excerpt}
            </div>
        );
    },
    [OrderElement.metadata]: ({ block, theme }) => {
        // Get the list slug corresponding to its content type in order to generate metadata links.
        const { contentTypeId = '' } = block;
        const metadataList = block.metadata?.map(({ name, rootName, metadataId }) => ({
            name,
            rootName,
            id: metadataId,
        }));
        return <MetadataLinkList metadataList={metadataList} theme={theme} contentTypeId={contentTypeId} />;
    },
    [OrderElement.publishedAt]: ({ block, translateKey }) => {
        const value = block.publishedAt;
        if (!value) {
            return null;
        }
        return (
            <time className={element(CLASSNAME)('date')} dir="auto" dateTime={value}>
                {`${translateKey(GLOBAL.PUBLISHED_ON)} ${moment.utc(value).local().format('LL')}`}
            </time>
        );
    },
    [OrderElement.siteName]: ({ block }) => {
        const value = block.site?.name;
        if (!value) {
            return null;
        }
        return (
            <div className={element(CLASSNAME)('site')} dir="auto">
                {value}
            </div>
        );
    },
    [OrderElement.reactions]: ({ block, isPreviewOnly, linkData, theme }) => {
        const value = block.reactions;
        if (!value) {
            return null;
        }
        return (
            <BlockReactions
                isPreviewOnly={isPreviewOnly}
                displayOptions={{ variant: BlockReactionVariants.content }}
                parentContentRef={linkData}
                path={[...(block.path || []), 'reactions']}
                {...value}
                theme={theme}
            />
        );
    },
    [OrderElement.tags]: ({ block }) => {
        const tags = block.tags?.items;
        if (!tags || tags.length === 0) {
            return null;
        }
        return (
            <div className={element(CLASSNAME)('tags')}>
                {tags.map((tag) => (
                    <span key={tag.tagId}>
                        <span>{tag.name}</span>
                    </span>
                ))}
            </div>
        );
    },
    [OrderElement.title]: ({ block, linkData, getDataAttributes }) => {
        const value = block.title;
        if (!value) {
            return null;
        }
        return (
            <Heading className={element(CLASSNAME)('title')} dir="auto">
                <BlockPagePreviewLink
                    className={element(CLASSNAME)('link')}
                    externalUrl={block.externalUrl}
                    pageType={block.pageType}
                    linkData={linkData}
                    {...getDataAttributes({ element: 'content-title', action: 'link' })}
                >
                    {value}
                </BlockPagePreviewLink>
            </Heading>
        );
    },
};

/**
 *
 * This block renders a preview of a content, the main block of the content-list widget.
 */
export const BlockPagePreview: BlockPagePreview = (props) => {
    const {
        title,
        slug,
        pageId,
        pageType,
        site,
        variant,
        image,
        order,
        theme = Theme.light,
        externalUrl,
        isPreviewOnly,
        renderingType,
    } = props;
    const { translateKey, translateObject } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const { ref, currentBreakpoint } = useBlockBreakpoints();
    const [isSlideshowView, setIsSlideshowView] = React.useState(false);
    const { isMobile } = useResponsive();
    const { get: getDataAttributes } = useDataAttributes(`content-${pageId}`);
    const blockPagePreviewRef = React.useRef<HTMLDivElement>(null);
    const titleId = useGenerateAriaId('label');

    const linkData = useMemo(
        () => ({
            slug,
            instance: { id: site?.siteId, slug: site?.slug },
            renderingType,
            id: pageId,
        }),
        [pageId, renderingType, site, slug],
    );

    // If in cover mode, force dark theme
    const computedTheme = variant === BlockPagePreviewVariant.cover ? Theme.dark : theme;

    // Activate thumbnail fill height on cover variant or side variant with a large enough width (responsive).
    const fillHeight =
        (variant === BlockPagePreviewVariant.side && currentBreakpoint !== WidgetSizes.xs) ||
        variant === BlockPagePreviewVariant.cover;

    const thumbnailProps: Partial<ThumbnailProps> = {
        alt: title || '',
        aspectRatio: fillHeight ? AspectRatio.free : AspectRatio.horizontal,
        fillHeight,
    };

    React.useEffect(() => {
        // Get the parent element className to know if it is a slideshow
        if (blockPagePreviewRef.current?.parentElement) {
            const { parentElement } = blockPagePreviewRef.current;

            setIsSlideshowView(parentElement.className.includes('block-slideshow__item'));
        }
    }, [blockPagePreviewRef]);

    return (
        <div
            ref={mergeRefs([ref, blockPagePreviewRef])}
            className={block({
                [`theme-${computedTheme}`]: true,
                [`size-${currentBreakpoint || WidgetSizes.l}`]: true,
                horizontal: variant === BlockPagePreviewVariant.horizontal,
                side: variant === BlockPagePreviewVariant.side,
                vertical: variant === BlockPagePreviewVariant.vertical,
                cover: variant === BlockPagePreviewVariant.cover,
                mobile: Boolean(isMobile),
                'is-preview-only': Boolean(isPreviewOnly),
            })}
        >
            {/* Display the publication info block ONLY if block is in variant vertical */}
            {order.includes(OrderElement.publicationInfo) && variant === BlockPagePreviewVariant.vertical && (
                <BlockPagePreviewPublicationInfo {...props} />
            )}

            {order.includes(OrderElement.image) && image && (
                <BlockPagePreviewLink
                    pageType={pageType}
                    linkData={linkData}
                    externalUrl={externalUrl}
                    /*
                     * If a title is defined, remove tab index from image as the title will suffice.
                     * However without any title, the image should remain tabbable as it is the only
                     * link into the content.
                     */
                    className={element('thumbnail-wrapper', {
                        'is-preview-only': Boolean(isPreviewOnly),
                    })}
                    tabIndex={title ? -1 : 0}
                    // Hide the thumbnail for screen reader to avoid duplicate reading on slideshow
                    aria-hidden={!!title && isSlideshowView}
                >
                    <BlockImage {...image} theme={theme} thumbnailProps={thumbnailProps} />
                </BlockPagePreviewLink>
            )}

            <div
                className={element('content', {
                    'is-preview-only': Boolean(isPreviewOnly),
                })}
            >
                {order.map((field) => {
                    const render = RENDER_FIELDS[field];
                    const rendered = render?.({
                        block: props,
                        translateKey,
                        translateObject,
                        theme: computedTheme,
                        isPreviewOnly,
                        getDataAttributes,
                        linkData,
                    });
                    if (!rendered) {
                        return null;
                    }
                    return (
                        <div
                            className={element('field', {
                                block: field === OrderElement.author,
                                text: field !== OrderElement.reactions && field !== OrderElement.author,
                                reactions: field === OrderElement.reactions,
                            })}
                            key={field}
                            id={field === OrderElement.title ? titleId : undefined}
                        >
                            {rendered}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
BlockPagePreview.displayName = 'BlockPagePreview';
