import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import type { UploadingVideoFormDialogProps } from './UploadingCorporateVideoFormDialog';

const LazyUploadingCorporateVideoFormDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'uploading-corporate-video-form-dialog' */
            './UploadingCorporateVideoFormDialog'
        ),
);

/**
 * FormDialog used to upload and edit new videos
 * @param UploadingVideoFormDialogProps
 * @returns UploadingCorporateVideoFormDialog
 */
export const UploadingCorporateVideoFormDialog = ({ isOpen, ...props }: UploadingVideoFormDialogProps) => (
    <DelayedSuspense shouldLoad={isOpen}>
        {(setShouldMount) => (
            <LazyUploadingCorporateVideoFormDialog onVisibilityChange={setShouldMount} isOpen={isOpen} {...props} />
        )}
    </DelayedSuspense>
);
