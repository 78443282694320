import get from 'lodash/get';

import { first } from '@lumapps/utils/iterable/first';
import { Editor, NodeEntry, Point, Range, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';
import { isEditorEmpty } from '@lumapps/wrex/slate/utils/isEditorEmpty';

import { ImageEditor, ImageEditorOptions, ImageElement, ImageGroupElement } from '../types';
import { createImageGroup } from './createImageGroup';
import { isImageGroup } from './isImageGroup';
import { uploadImage } from './uploadImage';

/**
 * Insert image after editor selection.
 * Returns the final insert position of the node. Useful for targeting the inserted node.
 */
export function insertImage(
    options: ImageEditorOptions,
    editor: ReactEditor & ImageEditor,
    attributes: Partial<ImageGroupElement>,
    files?: File[],
    point?: Point | undefined,
    /** Param used on attachment, to insert the image at the end of the editor (where the attachments are) */
    insertOnSelection = true,
) {
    const at = point || editor.selection?.focus;

    const after = getSiblingPath([get(at, ['path', 0]) as number], 'after');
    const tempImages = files?.map((file) => ({ alt: '', tempSrc: URL.createObjectURL(file) })) as ImageElement[];
    const node = createImageGroup({ images: tempImages, isLoading: true, ...attributes });
    let insertionPath;

    // Force insertion on first line when empty editor
    if (insertOnSelection && isEditorEmpty(editor)) {
        insertionPath = at?.path;
    } else if (!insertOnSelection) {
        insertionPath = after ?? [editor.children.length];
    }

    Transforms.insertNodes(editor, node, {
        select: true,
        at: insertionPath,
    });

    const [, firstPath] = first(
        Editor.nodes(editor, { at: editor.selection as Range, match: isImageGroup }),
    ) as NodeEntry;

    focusAt(editor, firstPath);

    if (files) {
        const pathRef = Editor.pathRef(editor, firstPath);
        uploadImage(options, editor, files, pathRef).finally(() => {
            pathRef.unref();
        });
    }

    return firstPath;
}
