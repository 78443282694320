import { Editor, Location, Transforms, Point } from '@lumapps/wrex/slate';
import { isText } from '@lumapps/wrex/slate/utils/isText';
import type { Wrex } from '@lumapps/wrex/types';

/**
 * Insert an autocompleted element.
 */
export function insertAutocompletedElement<E extends Wrex.Element | Wrex.Text, EN>(
    editor: Editor,
    entity: EN,
    createElement: (entity: EN) => E,
    at: Location,
): Point | undefined {
    const point = Editor.end(editor, at);
    const createdElement = createElement(entity);
    Transforms.insertNodes(editor, createdElement, { at: point, select: true });

    if (!isText(createdElement)) {
        Transforms.move(editor, { distance: 1, unit: 'offset' });
        Transforms.insertText(editor, ' ');
    }

    const afterInsertPoint = editor.selection?.anchor;
    return afterInsertPoint || undefined;
}
