import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const mediaRoutes: Route = {
    path: '/admin/media-library',
    legacyId: 'app.admin.media',
    appId: AppId.legacy,
};

const mediaTrashRoutes: Route = {
    path: '/admin/media-trash',
    legacyId: 'app.admin.media_trash',
    appId: AppId.legacy,
};

/**
 * Retrieves the route for custom content type's admin.
 */
const admin = (): Route => {
    return mediaRoutes;
};

const adminTrash = (): Route => {
    return mediaTrashRoutes;
};

export { admin, mediaRoutes, adminTrash, mediaTrashRoutes };
