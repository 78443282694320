import { Route, createPageRoute } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const instancesRoute: Route = createPageRoute({
    slug: 'platform/site-management',
    legacyId: 'app.admin.instance',
    appId: AppId.backOffice,
});

export const instanceRoute: Route = {
    path: '',
    legacyId: 'app.home',
    appId: AppId.frontOffice,
};

/** Retrieves the route for the instances admin. */
export const instances = (): Route => instancesRoute;

export const instance = (slug: string): Route => {
    return {
        ...instanceRoute,
        instanceSlug: slug,
    };
};
