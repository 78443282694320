import { useCallback, useEffect, useMemo, useReducer } from 'react';

import { type CommunityRole } from '@lumapps/communities/types';
import { useSelector } from '@lumapps/redux/react';

import { actions, initialState, reducer } from '../ducks/members/slice';
import { fetchSpaceMembers } from '../ducks/members/thunks';
import { selectSpaceId } from '../ducks/selectors';

interface UseSpaceMembersProps {
    role: CommunityRole;
}

const MEMBERS_PER_FETCH = 10;

export const useSpaceMembers = ({ role }: UseSpaceMembersProps) => {
    const [{ users, hasInit, isLoading, cursor, hasMore, searchQuery }, dispatch] = useReducer(reducer, initialState);
    const spaceId = useSelector(selectSpaceId) as string;

    const fixedOptions = useMemo(() => ({ maxResults: MEMBERS_PER_FETCH, role }), [role]);

    useEffect(() => {
        fetchSpaceMembers(spaceId, { ...fixedOptions, searchQuery })(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spaceId, searchQuery]);

    const onLoadMore = useMemo(() => {
        if (!hasMore) {
            return undefined;
        }

        return () => {
            fetchSpaceMembers(spaceId, { ...fixedOptions, cursor, searchQuery })(dispatch);
        };
    }, [hasMore, spaceId, fixedOptions, cursor, searchQuery]);

    const onSearch = useCallback(
        (searchQuery: string) => {
            dispatch(actions.setSearchQuery(searchQuery));
        },
        [dispatch],
    );

    return { users, isLoading: !hasInit || isLoading, onLoadMore, onSearch, searchQuery };
};
