export enum DIRECTORIES_ADMIN {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=618838003
     */
    ALL_DIRECTORIES = 'DIRECTORIES_ADMIN.ALL_DIRECTORIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=618839125
     */
    DELETE_DIRECTORY = 'DIRECTORIES_ADMIN.DELETE_DIRECTORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=618839369
     */
    DELETE_DIRECTORY_DESCRIPTION = 'DIRECTORIES_ADMIN.DELETE_DIRECTORY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=619855191
     */
    NEW_DIRECTORY = 'DIRECTORIES_ADMIN.NEW_DIRECTORY',
}
