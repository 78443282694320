import { useCallback } from 'react';

import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { GLOBAL } from '@lumapps/translations';

import { copyText } from '../browser/copyText';

export const useCopyToClipboard = () => {
    const { success } = useNotification();

    return useCallback(
        (string: string) => {
            copyText(string);
            success({ translate: GLOBAL.COPIED });
        },
        [success],
    );
};
