export enum AI_GENERATED_CONTENT {
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=367418967 */
    GENERATE_CONTENT_TITLE = 'WREX_AI.GENERATE_CONTENT_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=367534867 */
    GENERATE_CONTENT_DESC = 'WREX_AI.GENERATE_CONTENT_DESC',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=367451929 */
    GENERATE_CONTENT_TIPS = 'WREX_AI.GENERATE_CONTENT_TIPS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=367453423 */
    GENERATE_CONTENT_TIPS_1 = 'WREX_AI.GENERATE_CONTENT_TIPS_1',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=367453535 */
    GENERATE_CONTENT_TIPS_2 = 'WREX_AI.GENERATE_CONTENT_TIPS_2',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=367460206 */
    GENERATE_CONTENT_TIPS_3 = 'WREX_AI.GENERATE_CONTENT_TIPS_3',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=396601200 */
    GENERATE_CONTENT_BUTTON = 'WREX_AI.GENERATE_CONTENT_BUTTON',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=515213351 */
    PROMPT_LANGUAGE_TIPS = 'AI.PROMPT_LANGUAGE_TIPS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=509032612 */
    GENERATE_TEXT = 'AI.GENERATE_TEXT',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398885119 */
    PROMPT_LABEL = 'WREX_AI.PROMPT_LABEL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398888769 */
    PROMPT_TOOLTIP = 'WREX_AI.PROMPT_TOOLTIP',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398888935 */
    DIALOG_SUBTITLE = 'WREX_AI.DIALOG_SUBTITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398889115 */
    INPUT_PLACEHOLDER = 'WREX_AI.INPUT_PLACEHOLDER',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398889417 */
    TIPS_1 = 'WREX_AI.DIALOG_TIPS_1',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398889932 */
    TIPS_2 = 'WREX_AI.DIALOG_TIPS_2',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398890111 */
    CHIP_START_THINKING_ABOUT = 'WREX_AI.CHIP_START_THINKING_ABOUT',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398890157 */
    PROMPT_START_THINKING_ABOUT = 'WREX_AI.PROMPT_START_THINKING_ABOUT',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398890224 */
    CHIP_ARTICLE = 'WREX_AI.CHIP_ARTICLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398890230 */
    PROMPT_ARTICLE = 'WREX_AI.PROMPT_ARTICLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398896569 */
    CHIP_INTERNAL_COMMUNICATION = 'WREX_AI.CHIP_INTERNAL_COMMUNICATION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398896680 */
    PROMPT_INTERNAL_COMMUNICATION = 'WREX_AI.PROMPT_INTERNAL_COMMUNICATION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398894513 */
    CHIP_JOB_OFFER = 'WREX_AI.CHIP_JOB_OFFER',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398894527 */
    PROMPT_JOB_OFFER = 'WREX_AI.PROMPT_JOB_OFFER',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398894558 */
    CHIP_TO_DO_LIST = 'WREX_AI.CHIP_TO_DO_LIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398894837 */
    PROMPT_TO_DO_LIST = 'WREX_AI.PROMPT_TO_DO_LIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398894851 */
    CHIP_PLAN = 'WREX_AI.CHIP_PLAN',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398894861 */
    PROMPT_PLAN = 'WREX_AI.PROMPT_PLAN',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=509056448 */
    PROMPT_SUGGESTION_LABEL_WRITING_ABOUT = 'AI_GENERATIVE_TEXT.PROMPT_SUGGESTION_LABEL_WRITING_ABOUT',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=509057607 */
    PROMPT_SUGGESTION_RESULT_WRITING_ABOUT = 'AI_GENERATIVE_TEXT.PROMPT_SUGGESTION_RESULT_WRITING_ABOUT',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=398905567 */
    DISPLAY_SUGGESTED_PROMPTS = 'WREX_AI.DISPLAY_SUGGESTED_PROMPTS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=509078071 */
    PROMPT_REQUIRED_ERROR = 'AI_GENERATIVE_TEXT.PROMPT_REQUIRED_ERROR',

    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=399653419 */
    CHIP_FORMALITY_CASUAL = 'WREX_AI.CHIP_FORMALITY_CASUAL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=399653421 */
    PROMPT_FORMALITY_CASUAL = 'WREX_AI.PROMPT_FORMALITY_CASUAL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=399653428 */
    CHIP_FORMALITY_NEUTRAL = 'WREX_AI.CHIP_FORMALITY_NEUTRAL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=399653497 */
    PROMPT_FORMALITY_NEUTRAL = 'WREX_AI.PROMPT_FORMALITY_NEUTRAL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=399653503 */
    CHIP_FORMALITY_FORMAL = 'WREX_AI.CHIP_FORMALITY_FORMAL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=399653505 */
    PROMPT_FORMALITY_FORMAL = 'WREX_AI.PROMPT_FORMALITY_FORMAL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=399653849 */
    FORMALITY = 'WREX_AI.FORMALITY',

    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740806 */
    TONE = 'WREX_AI.TONE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=413019339 */
    CHIP_TONE_PROFESSIONAL = 'WREX_AI.CHIP_TONE_PROFESSIONAL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=413023461 */
    PROMPT_TONE_PROFESSIONAL = 'WREX_AI.PROMPT_TONE_PROFESSIONAL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740719 */
    CHIP_TONE_HUMOUROUS = 'WREX_AI.CHIP_TONE_HUMOUROUS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740725 */
    PROMPT_TONE_HUMOUROUS = 'WREX_AI.PROMPT_TONE_HUMOUROUS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740733 */
    CHIP_TONE_FRIENDLY = 'WREX_AI.CHIP_TONE_FRIENDLY',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740735 */
    PROMPT_TONE_FRIENDLY = 'WREX_AI.PROMPT_TONE_FRIENDLY',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740739 */
    CHIP_TONE_DIRECT = 'WREX_AI.CHIP_TONE_DIRECT',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740749 */
    PROMPT_TONE_DIRECT = 'WREX_AI.PROMPT_TONE_DIRECT',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740785 */
    CHIP_TONE_SUPPORTIVE = 'WREX_AI.CHIP_TONE_SUPPORTIVE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740789 */
    PROMPT_TONE_SUPPORTIVE = 'WREX_AI.PROMPT_TONE_SUPPORTIVE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740795 */
    CHIP_TONE_POSITIVE = 'WREX_AI.CHIP_TONE_POSITIVE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740797 */
    PROMPT_TONE_POSITIVE = 'WREX_AI.PROMPT_TONE_POSITIVE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740801 */
    CHIP_TONE_CONFIDENT = 'WREX_AI.CHIP_TONE_CONFIDENT',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=402740803 */
    PROMPT_TONE_CONFIDENT = 'WREX_AI.PROMPT_TONE_CONFIDENT',
}
