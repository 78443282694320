import React, { ReactElement } from 'react';

import facebooksvg from '@lumapps/assets/brands/facebook.svg';
import linkedinsvg from '@lumapps/assets/brands/linkedin.svg';
import twittersvg from '@lumapps/assets/brands/twitter.svg';
import { margin, classnames } from '@lumapps/classnames';
import { ActionableCard, ActionableCardProps } from '@lumapps/lumx-cards/components/ActionableCard';
import { mdiCheck } from '@lumapps/lumx/icons';
import { ButtonProps, ColorPalette, Flag, Orientation } from '@lumapps/lumx/react';
import { useResponsive } from '@lumapps/responsive';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { capitalizeFirstLetter } from '@lumapps/utils/string/capitalizeFirstLetter';

import { SOCIAL_NETWORKS } from '../../keys';

const iconBySocialPlatformId: Record<string, string> = {
    facebook: facebooksvg,
    linkedin: linkedinsvg,
    twitter: twittersvg,
};

export interface SocialPlatformEntryOptions extends Partial<ActionableCardProps> {
    advancedActions?: ReactElement;
    id: string;
    isActive: boolean;
    isEnabled?: boolean;
    username?: string;
    onClick: () => void;
    isOperationInProcess?: boolean;
    action?: ButtonProps;
}

const CLASSNAME = 'sa-social-platform-entry';
const SocialPlatformEntry: React.FC<SocialPlatformEntryOptions> = ({
    id,
    isEnabled = true,
    isActive,
    onClick,
    advancedActions = null,
    action = {},
    isOperationInProcess = false,
    username,
    ...props
}) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const { isSmall } = useResponsive();
    const orientation: Orientation = isSmall ? 'vertical' : 'horizontal';

    return (
        <ActionableCard
            as="li"
            key={id}
            subtitle={{
                subtitle: capitalizeFirstLetter(id),
                img: {
                    image: iconBySocialPlatformId[id],
                    alt: '',
                    aspectRatio: 'square',
                    objectFit: 'contain',
                },
            }}
            className={classnames(CLASSNAME, margin('bottom', 'big'))}
            action={{
                children: translateKey(isActive ? GLOBAL.DISCONNECT : GLOBAL.CONNECT),
                onClick,
                isDisabled: !isEnabled || isOperationInProcess,
                'aria-describedby': `title-${id}`,
                emphasis: isActive ? 'low' : 'medium',
                ...action,
            }}
            description={translateAndReplace(SOCIAL_NETWORKS.SHARE_CONTENT_ON_PLATFORM, {
                PLATFORM: id,
            })}
            afterTitle={
                isActive ? (
                    <Flag
                        color={ColorPalette.green}
                        label={
                            username
                                ? translateAndReplace(GLOBAL.CONNECTED_AS, {
                                      USER: username,
                                  })
                                : translateKey(GLOBAL.CONNECTED)
                        }
                        className={margin('left')}
                        icon={mdiCheck}
                        truncate
                    />
                ) : null
            }
            afterAction={advancedActions}
            wrapperProps={{ orientation, gap: 'big' }}
            {...props}
            title={capitalizeFirstLetter(id)}
            titleProps={{
                id: `title-${id}`,
                ...props.titleProps,
            }}
        />
    );
};

export { SocialPlatformEntry };
