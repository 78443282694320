/* istanbul ignore file */
import { ReactNode } from 'react';

import type { Orientation } from '@lumapps/lumx/react';
import { Video } from '@lumapps/play/api/types';
import type {
    Block,
    LegacyWidgetProperties,
    BlockImage,
    SiteReference,
    UserReference,
} from '@lumapps/widget-base/types';

export interface BlockPlayType extends Block {
    type: 'BlockPlay';
}

export interface WidgetPlayVideoIds {
    video?: {
        id: Video['id'] | undefined;
    };
    /**
     * We keep the videoId until we are sure that all widgets have
     * been reverted back to video.id
     */
    videoId?: string;
}

export enum MetadataItems {
    author = 'author',
    description = 'description',
    publicationDate = 'publication_date', // snake_case for translation keys
    title = 'title',
    views = 'views',
}

export const AllVideoMetadata = { ...MetadataItems };

export type VisibleMetadata = Array<keyof typeof AllVideoMetadata>;

export interface WidgetPlayPropertiesType extends WidgetPlayVideoIds {
    order?: VisibleMetadata;
    style?: LegacyWidgetProperties['style'];
}

export interface VisibleMetadataObject {
    author: boolean;
    description: boolean;
    publicationDate: boolean;
    title: boolean;
    views: boolean;
}

export type VisibleElements = (
    | 'AUTHOR'
    | 'CREATED_AT'
    | 'DESCRIPTION'
    | 'DURATION'
    | 'PUBLICATION_INFO'
    | 'THUMBNAIL'
    | 'TITLE'
    | 'VIEWS'
)[];

/**
 * This interface is our contract with the backend.
 */
export interface BlockPlayVideoInterface extends Block {
    /** Video author */
    author: UserReference;

    /** Video creation date */
    createdAt: Video['createdAt'];

    /** Video description */
    description?: string;

    /** Video duration */
    duration: Video['duration'];

    /** Elements to display */
    order: VisibleElements;

    /** Orientation of the component */
    orientation: Uppercase<Orientation>;

    /** Video ID */
    playVideoId: string;

    /** The site to which the video belongs */
    site: SiteReference;

    /** Video thumbnail */
    thumbnail: BlockImage;

    /** Video title */
    title: string;

    /** Widget block type */
    type: 'BlockPlayVideo';

    /** Video views */
    views: number;

    children?: ReactNode;
}
