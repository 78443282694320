(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetContentListSettingsController(
        $q,
        $rootScope,
        $scope,
        $timeout,
        Content,
        ContentPicker,
        ContentTemplate,
        CustomContentType,
        Features,
        Instance,
        MainNav,
        Metadata,
        Translation,
        Utils,
        Widget,
    ) {
        'ngInject';

        var widgetContentListSettings = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The delay for debouncing the trigger of the update of the widget.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        var _DEBOUNCE_DELAY = 250;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The identifier of the content picker.
         *
         * @type {string}
         */
        widgetContentListSettings.CONTENT_PICKER_ID = 'widget-content-picker-';

        /**
         * The options for the sortable fields.
         *
         * @type {Object}
         */
        widgetContentListSettings.SORTABLE_OPTIONS = {};

        /**
         * The key of the author.
         *
         * @type {string}
         */
        widgetContentListSettings.authorKey = undefined;

        /**
         * The list of available authors.
         *
         * @type {Array}
         */
        widgetContentListSettings.availableAuthors = [];

        /**
         * The list of the content type metadata.
         *
         * @type {Array}
         */
        widgetContentListSettings.contentTypeMetadata = [];

        /**
         * Contains the content types.
         *
         * @type {Object}
         */
        widgetContentListSettings.contentTypes = {};

        /**
         * Contains the tags of the content types.
         *
         * @type {Object}
         */
        widgetContentListSettings.contentTypesTags = {};

        /**
         * Indicates if the "Excerpt" field is enabled.
         *
         * @type {boolean}
         */
        widgetContentListSettings.isExcerptEnabled = false;

        /**
         * Indicates if the "Metadata" field is enabled.
         *
         * @type {boolean}
         */
        widgetContentListSettings.isMetadataEnabled = false;

        /**
         * The order of the fields in the selector.
         *
         * @type {Array}
         */
        widgetContentListSettings.listOrderSelection = [
            'publicationDate',
            'updatedAt',
            'title',
            'author',
            'likes',
            'comments',
        ];

        /**
         * The list key.
         *
         * @type {string}
         */
        widgetContentListSettings.listkey = undefined;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetContentListSettings.CustomContentType = CustomContentType;
        widgetContentListSettings.Features = Features;
        widgetContentListSettings.Instance = Instance;
        widgetContentListSettings.MainNav = MainNav;
        widgetContentListSettings.Metadata = Metadata;
        widgetContentListSettings.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Clear all metadata from the widget if there are no more custom content types selected.
         *
         * @param {Array} ccts The selected custom content types on the widget.
         */
        function _clearMetadata(ccts) {
            if (angular.isDefinedAndFilled(ccts)) {
                return;
            }

            delete Widget.getCurrent().properties.metadata;
        }

        /**
         * Gets the local custom content types.
         *
         * @param  {string}  cctIds The custom content types ids we want to get.
         * @return {Promise} A promise that resolves with the local custom content types.
         */
        function _getLocalCustomContentTypes(cctIds) {
            return $q(function resolveLocalCustomContentTypes(resolve, reject) {
                if (angular.isUndefinedOrEmpty(cctIds)) {
                    resolve([]);
                }

                cctIds = angular.isArray(cctIds) ? cctIds : [cctIds];

                CustomContentType.getItems(cctIds)
                    .then(function onCctsGetSuccess(ccts) {
                        var newCcts = [];

                        angular.forEach(ccts, function forEachCcts(cct) {
                            var cctId = _.get(cct, 'id');
                            if (angular.isUndefinedOrEmpty(cctId)) {
                                return;
                            }

                            if (_.includes(cctIds, cctId)) {
                                newCcts.push(cct);
                            }
                        });

                        resolve(newCcts);
                    })
                    .catch(reject);
            });
        }

        /**
         * Remove from properties the value that are not in the select anymore.
         *
         * @param {Array}  items              The items still available in the select.
         * @param {string} itemPluckOn        The name of the attribute of the items to pluck.
         * @param {string} propertyName       The property to check.
         * @param {string} propertyIdentifier The property attribute to compare with.
         */
        function _removeNonAvailableProperties(items, itemPluckOn, propertyName, propertyIdentifier) {
            var properties = Widget.getCurrent().properties;

            if (angular.isUndefined(properties[propertyName])) {
                return;
            }

            // Get headers list ids.
            var availableItems = [];
            angular.forEach(items, function fortEachItems(item) {
                availableItems = availableItems.concat(_.map(item, itemPluckOn));
            });

            // Filter the properties.
            var propertyValues = angular.fastCopy(properties[propertyName]);
            var newPropertyValues = [];
            properties[propertyName] = [];

            angular.forEach(propertyValues, function forEachPropertyValues(propertyValue) {
                var check = angular.isDefined(propertyIdentifier) ? propertyValue[propertyIdentifier] : propertyValue;

                if (availableItems.indexOf(check) > -1) {
                    newPropertyValues.push(propertyValue);
                }
            });

            properties[propertyName] = newPropertyValues;
        }

        /**
         * Filter the metadata and custom content types tags.
         *
         * @param {Array} cctIds The custom content type Ids we want to filter metadata and tags.
         */
        function _filterCustomContentType(cctIds) {
            if (!angular.isArray(cctIds) || angular.isUndefinedOrEmpty(cctIds)) {
                return;
            }

            var properties = _.get(Widget.getCurrent(), 'properties', {});

            /**
             * Get current CCTs to check if we have its parent in cctIds, if so, add it to the list
             * to retrieve metadatas.
             **/
            angular.forEach(
                CustomContentType.displayList(CustomContentType.CURRENT_INSTANCE_CCT_LIST_KEY),
                (currentSiteCct) => {
                    if (_.includes(cctIds, currentSiteCct.parentCustomContentType)) {
                        cctIds = [...cctIds, currentSiteCct.id];
                    }
                },
            );

            // Update metadata list depending CCTs selection.
            widgetContentListSettings.customContentTypeMetaData = Metadata.listByCustomContentTypeIds(cctIds);

            var instanceIds = properties.isAllInstancesSiblings ? undefined : properties.instance;
            CustomContentType.getTagsForHeaderSelect(
                cctIds,
                function onTagsComputed(tags) {
                    widgetContentListSettings.contentTypesTags = tags;

                    _removeNonAvailableProperties(
                        widgetContentListSettings.contentTypesTags,
                        'uuid',
                        'customContentTypeTags',
                    );
                },
                undefined,
                instanceIds,
            );
        }

        /**
         * Refresh the custom content types list and the custom content types tags and metadata.
         */
        function _refreshCustomContentTypes() {
            _getLocalCustomContentTypes(_.get(Widget.getCurrent(), 'properties.customContentType')).then(
                function onLocalCustomContentTypesGetSuccess(localCcts) {
                    _filterCustomContentType(_.map(localCcts, 'id'));
                },
            );
        }

        /**
         * Find an element in a collection of arrays of objects.
         *
         * @param  {Object} objectHaystack The collection of arrays of objects.
         * @param  {*}      needle         What we are looking for in the collection of arrays.
         * @param  {string} propertyName   The name of the property in which we are searching.
         * @return {*}      The matching object.
         */
        function _findInCollection(objectHaystack, needle, propertyName) {
            for (var key in objectHaystack) {
                if (objectHaystack.hasOwnProperty(key) && angular.isArray(objectHaystack[key])) {
                    for (var idx = 0, len = objectHaystack[key].length; idx < len; ++idx) {
                        if (objectHaystack[key][idx][propertyName] === needle) {
                            return objectHaystack[key][idx];
                        }
                    }
                }
            }

            return undefined;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Following lumx modelToSelection.
         * Transform data value to fit with ngModel.
         *
         * @param {Object}   data The data to transform
         * @param {Function} cb   The callback to call with the transformed value.
         */
        function emailToUserObject(data, cb) {
            cb = cb || angular.noop;

            if (angular.isDefinedAndFilled(data)) {
                cb({
                    email: data,
                });
            }
        }

        /**
         * Open the content picker.
         */
        function openContentPicker() {
            Utils.waitForAndExecute('#' + widgetContentListSettings.CONTENT_PICKER_ID, ContentPicker);
        }

        /**
         * Convert the properties to content type.
         *
         * Use for BACKWARD COMPATIBILITY only!
         *
         * @param {Object}   data The data to convert.
         * @param {Function} cb   The callback to call with the converted data.
         */
        function propertyToContentTypes(data, cb) {
            var cct = _findInCollection(widgetContentListSettings.contentTypes, data, 'id');

            if (angular.isDefinedAndFilled(cct)) {
                cb(cct);
            }
        }

        /**
         * When custom content types are selected in the settings, filters the content types and update the widget.
         */
        function selectCustomContentType() {
            _filterCustomContentType(Widget.getCurrent().properties.customContentType);
            _clearMetadata(Widget.getCurrent().properties.customContentType);
            widgetContentListSettings.updateWidget();
        }

        /**
         * If this widget is now a "Main Widget", remove the isMainWidget attribute to the other widgets.
         */
        function setAsMainWidget() {
            var currentWidget = Widget.getCurrent();

            if (currentWidget.isMainWidget) {
                var _widgetList = ContentTemplate.getElementList(Content.getCurrent(), 'widget', 'isMainWidget', true);
                angular.forEach(_widgetList, function forEachWidget(widget) {
                    if (widget.uuid !== currentWidget.uuid) {
                        widget.isMainWidget = false;
                    }
                });
            }

            $rootScope.$broadcast('widget-is-main-toggled', currentWidget.uuid, currentWidget.isMainWidget);
        }

        /**
         * Force theme dark when we use view mode cover with background thumbnail.
         */
        function updateCoverTheme() {
            var properties = Widget.getCurrent().properties;

            if (properties.thumbnailPosition === 'background') {
                properties.coverTheme = 'dark';
            } else {
                properties.coverTheme = undefined;
            }

            widgetContentListSettings.updateWidget(true);
        }

        /**
         * When a field is toggled.
         * If the field is the "Excerpt" or the "Metadata" one, toggle a flag.
         *
         * @param {Object} field The field that is toggled.
         */
        function toggleField(field) {
            if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name)) {
                return;
            }

            var fieldName = field.name.toLowerCase();
            if (fieldName === 'excerpt') {
                widgetContentListSettings.isExcerptEnabled = field.enable;
            }

            if (fieldName === 'metadata') {
                widgetContentListSettings.isMetadataEnabled = field.enable;
            }

            widgetContentListSettings.updateWidget(true);
        }

        function getFieldLabel(field) {
            if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name)) {
                return;
            }

            const fieldName = field.name.toUpperCase();

            // Title is a generic key, we add an exception to optimize our lokalise keys use.
            if (fieldName === 'TITLE') {
                return Translation.translate('GLOBAL.TITLE')
            }
            
            return Translation.translate(`WIDGET_TYPE_CONTENT-LIST_${fieldName}`);
        }

        /**
         * Update the content type list.
         *
         * @param {Array}   instancesIds The ids of the instances to use.
         * @param {boolean} keepModel    Indicates if we want to update the widget or not.
         */
        function updateContentTypeList(instancesIds, keepModel) {
            var properties = _.get(Widget.getCurrent(), 'properties', {});
            var isOnlyHeritableFromParent = false;

            var siblings = Instance.getSiblings(true);
            var parentLessInstance = _.find(siblings, function findParentLessInstance(instance) {
                return angular.isUndefined(instance.parent);
            });

            var parentLessInstanceId = _.get(parentLessInstance, 'id');

            if (properties.isAllInstancesSiblings && angular.isDefinedAndFilled(parentLessInstanceId)) {
                /*
                 * When the All instance siblings switch is On, only the parent instance is needed to fetch just
                 * heritables content types.
                 */
                instancesIds = [parentLessInstanceId];
            } else if (angular.isDefinedAndFilled(properties.instance)) {
                properties.isAllInstancesFavorites = false;
                // `properties.instance` is in fact a list of instances ids.
                instancesIds = angular.fastCopy(properties.instance);

                if (
                    angular.isDefinedAndFilled(parentLessInstanceId) &&
                    !_.includes(instancesIds, parentLessInstanceId)
                ) {
                    instancesIds.push(parentLessInstanceId);

                    isOnlyHeritableFromParent = true;
                }
            }

            CustomContentType.groupByInstances(
                instancesIds,
                properties.isAllInstancesSiblings,
                isOnlyHeritableFromParent,
                function groupByInstancesCallback(items) {
                    if (
                        !keepModel &&
                        angular.isDefinedAndFilled(properties.customContentType) &&
                        angular.isArray(properties.customContentType)
                    ) {
                        _removeNonAvailableProperties(items, 'id', 'customContentType');
                        _filterCustomContentType(properties.customContentType);
                    }

                    widgetContentListSettings.contentTypes = items;

                    if (!keepModel) {
                        widgetContentListSettings.updateWidget();
                    }
                },
            );
        }

        /**
         * Reset the custom content type tags on custom content type changes.
         */
        function updateContentTypeTags() {
            widgetContentListSettings.updateWidget();
        }

        /**
         * Update the widget whenever a settings changes.
         *
         * @param {boolean} [quick=false] Indicates if we only want to send the update event (and not recompute the
         *                                metadata).
         */
        function updateWidget(quick) {
            quick = Boolean(quick);

            var currentWidget = Widget.getCurrent();
            var properties = currentWidget.properties || {};

            /**
             * If the content-list does not contain content from multiple sites
             * disable the display of the content site name.
             * */
            if (!properties.isAllInstancesSiblings && properties.instance.length === 1) {
                properties.isInstanceNameDisplayed = false;
            }

            if (!quick) {
                // Compute metadata and make a list of ids.
                // Do the same for the tags.
                // This is used in the filter when linked to the content.
                var metadata = _.get(currentWidget, 'properties.metadata', {});

                currentWidget.properties.filterRestriction = {
                    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
                    metadata: _.filter(metadata, function filterMetadata(val) {
                        // The un-setting doesn't remove the element but sets it to undefined...
                        // So cast it to boolean.
                        return Boolean(val);
                    }),
                    tags: _.get(currentWidget, 'properties.customContentTypeTags'),
                };
            }

            $timeout(function timeoutBroadcast() {
                $rootScope.$broadcast('widget-content-list-settings', currentWidget.uuid);
            });
        }

        /**
         * Following lumx selectionToModel.
         * Transform data value to fit with ngModel.
         *
         * @param {Object}   data The data to transform.
         * @param {Function} cb   The callback to call with the transformed value.
         */
        function userObjectToEmail(data, cb) {
            cb = cb || angular.noop;

            if (angular.isDefinedAndFilled(_.get(data, 'email'))) {
                cb(data.email);
            }
        }

        /**
         * Model to selection for the tags picker.
         *
         * @param {Object}   data The data to transform.
         * @param {Function} cb   The callback to call with the transformed value.
         */
        function uuidToTagMultiContentTypes(data, cb) {
            cb(_findInCollection(widgetContentListSettings.contentTypesTags, data, 'uuid'));
        }

        /////////////////////////////

        widgetContentListSettings.debouncedUpdateWidget = _.debounce(updateWidget, _DEBOUNCE_DELAY);
        widgetContentListSettings.emailToUserObject = emailToUserObject;
        widgetContentListSettings.openContentPicker = openContentPicker;
        widgetContentListSettings.propertyToContentTypes = propertyToContentTypes;
        widgetContentListSettings.selectCustomContentType = selectCustomContentType;
        widgetContentListSettings.setAsMainWidget = setAsMainWidget;
        widgetContentListSettings.updateCoverTheme = updateCoverTheme;
        widgetContentListSettings.toggleField = toggleField;
        widgetContentListSettings.getFieldLabel = getFieldLabel;
        widgetContentListSettings.updateContentTypeList = updateContentTypeList;
        widgetContentListSettings.updateContentTypeTags = updateContentTypeTags;
        widgetContentListSettings.updateWidget = updateWidget;
        widgetContentListSettings.userObjectToEmail = userObjectToEmail;
        widgetContentListSettings.uuidToTagMultiContentTypes = uuidToTagMultiContentTypes;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the content picker closes, update the widget to display the newly picked contents.
         *
         * @param {Event}  evt      The event.
         * @param {string} idPicker The id of the picker that starts to close.
         */
        $scope.$on('content-picker__close-start', function onContentPickerCloseStart(evt, idPicker) {
            if (idPicker === widgetContentListSettings.CONTENT_PICKER_ID) {
                widgetContentListSettings.updateWidget(true);
            }
        });

        /**
         * When the list of custom content type is updated, refresh the custom content types picker, the tags and the
         * metadata.
         */
        $scope.$on('custom-content-type-list-updated', function onCustomContentTypesListUpdated() {
            _refreshCustomContentTypes();
        });

        /**
         * When instance siblings selector is reset, reset all filters.
         */
        $scope.$on('reset-instance-siblings-selector', function onInstanceSiblingsSelectorReset() {
            var properties = Widget.getCurrent().properties;

            properties.instance = [Instance.getCurrentInstanceId()];
            properties.customContentType = [];
            properties.customContentTypeTags = [];
            properties.metadata = [];
            properties.author = [];
        });

        // WTF [Clément]: Why?
        /**
         * When a sub-nav settings are updated, update the content list widget.
         *
         * @param {Event}  evt      The event.
         * @param {string} idPicker The id of the widget that is updated.
         */
        $scope.$on('widget-sub-nav-settings', function onWidgetSubNavSettings(evt, idWidget) {
            if (Widget.getCurrent().uuid === idWidget) {
                widgetContentListSettings.updateWidget();
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var currentWidget = Widget.getCurrent();
            var instancesIds = [];
            var currentInstanceId = Instance.getCurrentInstanceId();

            currentWidget.properties = currentWidget.properties || {};
            var properties = currentWidget.properties;

            widgetContentListSettings.authorKey = 'author-' + currentWidget.uuid;
            widgetContentListSettings.listkey = 'widget-' + currentWidget.uuid;
            widgetContentListSettings.CONTENT_PICKER_ID += currentWidget.uuid;

            // Backward compatibility.
            if (angular.isUndefinedOrEmpty(properties.instance)) {
                properties.instance = [currentInstanceId];
            }

            // Todo [Arnaud]: remove this once the backend script removing duplicates has been run.
            properties.customContentType = _.uniq(properties.customContentType);

            Instance.getSiblings(false).then(function onSiblingsListSuccess(siblings) {
                if (siblings.length > 1) {
                    instancesIds = properties.isAllInstancesSiblings
                        ? Instance.getSiblingsIds(true)
                        : properties.instance;
                } else {
                    instancesIds = [currentInstanceId];
                    properties.instance = [currentInstanceId];

                    properties.isAllInstancesSiblings = false;
                }

                if (!properties.isAllInstancesSiblings) {
                    instancesIds = _.intersection(instancesIds, Instance.getSiblingsIds(true));
                }

                widgetContentListSettings.updateContentTypeList(instancesIds, true);
            });

            if (
                angular.isArray(properties.customContentType) &&
                angular.isDefinedAndFilled(properties.customContentType)
            ) {
                _refreshCustomContentTypes();
            }

            // Set the default sort direction to desc when no value is defined
            if (!properties.listOrderDir) {
                properties.listOrderDir = 'desc';
            }

            // Set the default sort field to publicationDate when no value is defined
            if (!properties.listOrder) {
                properties.listOrder = 'publicationDate';
            }

            // Check if excerpt is enabled.
            widgetContentListSettings.isExcerptEnabled = angular.isDefinedAndFilled(
                _.filter(properties.fields, {
                    enable: true,
                    name: 'excerpt',
                }),
            );

            // Check if metadata is enabled.
            widgetContentListSettings.isMetadataEnabled = angular.isDefinedAndFilled(
                _.filter(properties.fields, {
                    enable: true,
                    name: 'metadata',
                }),
            );

            widgetContentListSettings.SORTABLE_OPTIONS.orderChanged = widgetContentListSettings.updateWidget;

            widgetContentListSettings.updateWidget();
        }

        init();
    }

    /////////////////////////////

    angular
        .module('Controllers')
        .controller('WidgetContentListSettingsController', WidgetContentListSettingsController);
})();
