import React from 'react';

import { padding, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiInformationOutline } from '@lumapps/lumx/icons';
import { Emphasis, IconButton, Placement, Popover } from '@lumapps/lumx/react';
import { useEventListener } from '@lumapps/utils/hooks/useEventListener';

import { SCOPE, SEGMENT_POPOVER_CLASSNAME } from '../../constants';
import { useSegment } from '../../hooks/useSegment';
import { Segment } from '../../types';
import { SegmentInformation } from '../SegmentInformation/SegmentInformation';
import { SegmentDetailsPopoverSkeleton } from './SegmentDetailsPopoverSkeleton';

import './SegmentDetailsPopover.scss';

export interface SegmentDetailsPopoverProps {
    segmentId: string;
    segmentInfo?: Segment;
    scope?: string;
}

export const SegmentDetailsPopover = ({ segmentId, segmentInfo, scope }: SegmentDetailsPopoverProps) => {
    const { get } = useDataAttributes(scope || SCOPE);
    const ref = React.useRef<HTMLButtonElement | null>(null);
    const { block, element } = useClassnames(SEGMENT_POPOVER_CLASSNAME);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

    // If we already the segment info, we don't need to fetch it again
    const {
        isLoading,
        segment: fetchedSegment,
        cancelFetch,
    } = useSegment({
        fetchOnMount: isPopoverOpen && !segmentInfo,
        segmentId,
        withCache: true,
    });

    const segment = segmentInfo || fetchedSegment;

    const onMouseEnter = (evt: MouseEvent) => {
        ref.current = evt.target as HTMLButtonElement;
        setIsPopoverOpen(true);
    };

    const onMouseLeave = () => {
        setIsPopoverOpen(false);
        cancelFetch();
    };

    // Need to close the popover when the user presses escape
    const onKeyDown = React.useCallback((event: KeyboardEvent) => {
        if (event.code === 'Escape') {
            setIsPopoverOpen(false);
        }
    }, []);

    useEventListener('keydown', onKeyDown, ref);

    if (!segmentId && !isLoading) {
        return null;
    }

    return (
        <>
            <IconButton
                icon={mdiInformationOutline}
                emphasis={Emphasis.low}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                label=""
                className={element('icon')}
                {...get({ element: 'segment-picker-info' })}
            />

            {isLoading && <SegmentDetailsPopoverSkeleton anchorRef={ref} isOpen={isPopoverOpen} />}

            {segment && (
                <Popover
                    anchorRef={ref}
                    isOpen={isPopoverOpen}
                    className={block([padding('all', 'big')])}
                    closeOnEscape
                    closeOnClickAway
                    placement={Placement.BOTTOM_START}
                    {...get({ element: 'segment-picker-info' })}
                >
                    <SegmentInformation segment={segment} />
                </Popover>
            )}
        </>
    );
};
