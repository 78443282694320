import React from 'react';

import { useLooker } from '../../hooks/useLooker';
import { EmbedDashboard } from '../EmbedDasboard';

export const LearningDashboard = () => {
    const dashboardName = 'learning_overview';
    const dashboardUrl = `/analytics/embedded/${dashboardName}`;

    const { createDashboard, dashboardStatus } = useLooker({ url: dashboardUrl, dashboardId: dashboardName });

    return <EmbedDashboard createDashboard={createDashboard} dashboardStatus={dashboardStatus} />;
};

export default LearningDashboard;
