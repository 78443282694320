import React from 'react';

import { ReactEditor, Transforms, Element, Path } from '..';
import { focusAt } from './focusAt';

export const useInlineVoid = (editor: ReactEditor, element: Element) => {
    const onFocus = React.useCallback(() => {
        const path = ReactEditor.findPath(editor, element) as Path;
        const point = { path: [...path, 0], offset: 0 };
        focusAt(editor, point);
    }, [editor, element]);

    const onDelete = React.useCallback(
        (event?: React.SyntheticEvent) => {
            if (event) {
                event.stopPropagation();
            }

            const path = ReactEditor.findPath(editor, element as Element) as Path;
            Transforms.removeNodes(editor, { at: path });
            focusAt(editor);
        },
        [editor, element],
    );
    return {
        onFocus,
        onDelete,
    };
};
