import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Placement, Popover } from '@lumapps/lumx/react';

import { ViewMode } from '../../constants';
import { NavigationItem, NavigationItemProps } from '../NavigationItem';

import './index.scss';

export interface NavigationSectionProps
    extends Omit<
        NavigationItemProps,
        'isSection' | 'isSectionOpen' | 'isSelected' | 'linkAs' | 'linkProps' | 'onClick'
    > {
    /** Items inside the section */
    children: React.ReactNode;
    /** Whether the section should be opened on component mount. Defaults to false */
    openOnMount?: boolean;
}

const CLASSNAME = 'navigation-section';

const NavigationSection = React.forwardRef<HTMLLIElement, NavigationSectionProps>(
    ({ children, openOnMount = false, ...navigationItemProps }, ref) => {
        const { label, scope, theme, viewMode } = navigationItemProps;

        const isHorizontalViewMode = viewMode === ViewMode.horizontal;
        const { element: cssElement } = useClassnames(CLASSNAME);
        const [isOpen, setIsOpen] = React.useState(openOnMount);
        const buttonRef = React.useRef<HTMLButtonElement>(null);

        const childrenArray = React.Children.toArray(children);

        if (!childrenArray.length) {
            return null;
        }

        /* istanbul ignore next */
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
            if (!openOnMount) {
                setIsOpen(false);
            }
        }, [openOnMount, viewMode]);

        return (
            <>
                <NavigationItem
                    {...navigationItemProps}
                    isSection
                    isSectionOpen={isOpen}
                    ref={ref}
                    onClick={(event: React.MouseEvent) => {
                        setIsOpen(!isOpen);
                        event.stopPropagation();
                    }}
                    linkProps={{
                        ref: buttonRef,

                        onKeyUp: (event: React.KeyboardEvent) => {
                            if (event.key === 'Enter') {
                                event.stopPropagation();
                            }
                        },
                    }}
                />
                {isOpen &&
                    (isHorizontalViewMode ? (
                        <Popover
                            anchorRef={buttonRef}
                            isOpen={isOpen}
                            placement={Placement.BOTTOM_START}
                            usePortal={false}
                            closeOnClickAway
                            closeOnEscape
                            onClick={() => setIsOpen(false)}
                            onClose={() => setIsOpen(false)}
                            onKeyUp={(event: React.KeyboardEvent) => {
                                if (event.key === 'Enter') {
                                    setIsOpen(false);
                                }
                            }}
                            zIndex={996}
                        >
                            <ul className={cssElement('drawer', 'popover')} id={`section-${label}`}>
                                {childrenArray.map((child) =>
                                    React.cloneElement(child as React.ReactElement, { scope }),
                                )}
                            </ul>
                        </Popover>
                    ) : (
                        <ul className={cssElement('drawer')} id={`section-${label}`}>
                            {childrenArray.map((child) =>
                                React.cloneElement(child as React.ReactElement, { theme, scope }),
                            )}
                        </ul>
                    ))}
            </>
        );
    },
);

NavigationSection.displayName = 'NavigationSection';

export { NavigationSection };
