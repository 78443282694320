import React, { Fragment } from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { mdiFile } from '@lumapps/lumx/icons';
import {
    ListItem,
    List,
    AspectRatio,
    Icon,
    Thumbnail,
    ThumbnailVariant,
    ColorPalette,
    Size,
    Theme,
    GenericProps,
    Button,
    Emphasis,
    ThumbnailProps,
    IconProps,
    ListDivider,
} from '@lumapps/lumx/react';
import { MediaSource } from '@lumapps/medias/types';
import { resizeHaussmannImage, getMediaUrl } from '@lumapps/medias/utils';
import { resizeImage } from '@lumapps/medias/utils/resizeImage';
import { AttachmentTypes } from '@lumapps/posts/types';
import { useTranslate } from '@lumapps/translations';
import { isBlobUrl } from '@lumapps/utils/string/isBlobUrl';

import { WIDGET_BASE } from '../../../../keys';
import { RemoveButton } from '../../../RemoveButton';
import './FileAttachmentMultiple.scss';

export interface File {
    /** Unique (really unique?) id of the file. */
    uuid: string;
    /** The file url. */
    url: string;
    /** The file title, displayed on top of the block. */
    name?: string;
    /** The file image preview. */
    thumbnail?: Omit<ThumbnailProps, 'size' | 'aspectRatio'>;
    /** The icon to display if no image is specified, displays a file Icon by default. */
    icon?: Omit<IconProps, 'size' | 'hasShape'>;
    /** The description of the file. */
    description?: string;
    /** The description of the file. */
    mimeType?: string;
    /** The source of the media. */
    source: MediaSource;
}

/**
 * Defines the props of the component.
 */
export interface FileAttachmentMultipleProps extends GenericProps {
    /** Theme. */
    theme?: Theme;
    /** Array of files you want to display. */
    files: File[];
    /** Can delete the file */
    canDelete?: boolean;
    /** Callback for remove attachment */
    onRemoveAttachment?(attachmentType: AttachmentTypes, attachmentIndex: number): void;
    // If we use attachments in comments context (added for render different view)
    isCommentContext?: boolean;
}

const COMPONENT_PREFIX = '';
/**
 * The display name of the component.
 */
const COMPONENT_NAME = `${COMPONENT_PREFIX}FileAttachmentMultiple`;

/**
 * The default class name and classes prefix for this component.
 */
const CLASSNAME = 'lumx-file-attachment-multiple';

/**
 * The default value of props.
 */
const DEFAULT_PROPS: Partial<FileAttachmentMultipleProps> = {
    theme: Theme.light,
};

/**
 * The haussmann media storage host
 */
const HAUSSMANN_STORAGE_MEDIA_URL = 'https://storage.googleapis.com';

/**
 * Size of resized images
 */
const IMAGE_SIZE = 512;

const FileAttachmentMultiple: React.FC<FileAttachmentMultipleProps> = ({
    files,
    className,
    theme = DEFAULT_PROPS.theme,
    canDelete,
    onRemoveAttachment,
    isCommentContext = false,
    ...props
}) => {
    const onFileClick = (file: File) => () => {
        if (file.source === MediaSource.HAUSSMANN_MEDIA) {
            const mediaUrl = getMediaUrl(file.uuid);
            window.open(mediaUrl, '_blank');
        } else {
            window.open(file.url, '_blank');
        }
    };

    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <List
            className={classnames(
                className,
                block({
                    'remove-single-attachment': !isCommentContext,
                    [`theme-${theme}`]: true,
                }),
            )}
            {...props}
        >
            {files.map((file, index) => {
                const { thumbnail, icon = { icon: mdiFile, color: ColorPalette.dark }, name, mimeType } = file;
                const thumbnailImage = thumbnail?.image;
                const shouldResize =
                    !isBlobUrl(thumbnailImage) && mimeType && !['image/gif', 'image/svg+xml'].includes(mimeType);
                const isHaussmannImage = thumbnailImage && thumbnailImage.indexOf(HAUSSMANN_STORAGE_MEDIA_URL) !== -1;
                const resizeUrl = isHaussmannImage
                    ? resizeHaussmannImage(thumbnailImage, IMAGE_SIZE)
                    : resizeImage(thumbnailImage, IMAGE_SIZE);
                const imageUrl = shouldResize ? resizeUrl : thumbnailImage;

                return (
                    <Fragment key={file.uuid}>
                        <ListItem
                            className={element('file', {
                                'remove-single-attachment': !isCommentContext,
                            })}
                            before={
                                <Thumbnail
                                    alt={name || ''}
                                    role="link"
                                    variant={ThumbnailVariant.rounded}
                                    onClick={onFileClick(file)}
                                    tabIndex={0}
                                    {...thumbnail}
                                    image={imageUrl}
                                    size={Size.m}
                                    aspectRatio={AspectRatio.square}
                                    fallback={
                                        <Icon
                                            icon=""
                                            {...icon}
                                            theme={theme}
                                            className={classnames(element('file-icon'), icon.className)}
                                            size={Size.m}
                                        />
                                    }
                                />
                            }
                        >
                            <Button
                                theme={theme}
                                emphasis={Emphasis.low}
                                onClick={onFileClick(file)}
                                className={element('link', {
                                    'remove-single-attachment': !isCommentContext,
                                })}
                            >
                                {name}
                            </Button>
                            {canDelete && onRemoveAttachment && (
                                <RemoveButton
                                    className={element('remove-file-button')}
                                    label={translateKey(WIDGET_BASE.REMOVE_FILE)}
                                    onClick={() => onRemoveAttachment('files', index)}
                                />
                            )}
                        </ListItem>
                        {isCommentContext && index < files.length - 1 && <ListDivider className={element('divider')} />}
                    </Fragment>
                );
            })}
        </List>
    );
};

FileAttachmentMultiple.displayName = COMPONENT_NAME;

export { CLASSNAME, DEFAULT_PROPS, FileAttachmentMultiple };
