import { ACTIONS, type Action, type InitialValues } from './types';

export const reducer = (state: InitialValues, action: Action) => {
    switch (action.type) {
        case ACTIONS.SET_TRANSCRIPT_ERROR:
            return {
                ...state,
                transcriptError: action.payload,
            };
        case ACTIONS.SET_IS_SCHEDULED:
            return {
                ...state,
                isScheduled: action.payload,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
