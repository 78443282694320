import { isInstanceSuperAdmin, isSuperAdmin } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { BackOfficeStore } from '@lumapps/redux/types';
import { isCoveoEnabled, isCoveoIndexingEnabled } from '@lumapps/search/ducks/selectors';

import { CoveoSettings } from '../types';
import { SearchSettingsState } from './state';

export const searchSettingsSelector = (state: BackOfficeStore) => state?.searchSettings || {};

export const canAccessSearchSettings = createSelector(
    isCoveoEnabled,
    isSuperAdmin,
    isInstanceSuperAdmin,
    (isCoveo, isGlobalAdmin, isSiteAdmin) => {
        return !isCoveo && (isGlobalAdmin || isSiteAdmin);
    },
);

/**
 * Should be true if the flag is enabled
 * and if the user is global admin or admin of the site
 */
export const canAccessCoveoSearchSettings = createSelector(
    isCoveoEnabled,
    isCoveoIndexingEnabled,
    isSuperAdmin,
    (isEnabled, canIndex, isAdmin) => {
        return Boolean((isEnabled || canIndex) && isAdmin);
    },
);

export const getSettings = createSelector(searchSettingsSelector, (state: SearchSettingsState): CoveoSettings => {
    return {
        searchHub: state.searchHub,
        hasSearchApiKey: state.hasSearchApiKey,
        hasIndexingApiKey: state.hasIndexingApiKey,
        coveoOrgId: state.coveoOrgId,
        analyticsSettings: state.analyticsSettings,
        region: state.region,
    };
});

export const getError = createSelector(searchSettingsSelector, (state: SearchSettingsState) => state.error);
export const getStatus = createSelector(searchSettingsSelector, (state: SearchSettingsState) => state.status);
export const hasSearchApiKey = createSelector(
    searchSettingsSelector,
    (state: SearchSettingsState) => state.hasSearchApiKey,
);
export const hasIndexingApiKey = createSelector(
    searchSettingsSelector,
    (state: SearchSettingsState) => state.hasIndexingApiKey,
);
