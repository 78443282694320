import { useCallback, useMemo } from 'react';

import { deleteUserEntry } from '@lumapps/directories-entries/api';
import { ConfirmDialogProps } from '@lumapps/lumx-dialogs/components/ConfirmDialog';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { WidgetState } from '@lumapps/widget-base/ducks/type';
import { useResetBlock } from '@lumapps/widget-base/hooks/useResetBlock';

import { WIDGET_DIRECTORY_ENTRIES } from '../keys';

/**
 * Hook used to display the Directory entry deletion dialog
 * Within the widget directory entry widget for deleting an entry.
 * */
export const useDeletedUserDirectoryEntry = (widget: WidgetState, entryId: string) => {
    const { error, success } = useNotification();
    const { translateKey } = useTranslate();
    const resetBlock = useResetBlock(widget);
    const [isOpen, , onClose, onOpen] = useBooleanState(false);
    const [isLoading, , finishLoading, startLoading] = useBooleanState(false);

    const onDelete = useCallback(async () => {
        startLoading();

        try {
            await deleteUserEntry(entryId);
            resetBlock();
            success({ translate: WIDGET_DIRECTORY_ENTRIES.ENTRY_DELETED });
        } catch (err) {
            error({ translate: GLOBAL.GENERIC_ERROR });
        } finally {
            finishLoading();
            onClose();
        }
    }, [entryId, error, finishLoading, onClose, resetBlock, startLoading, success]);

    const deleteDialogProps: ConfirmDialogProps = useMemo(
        () => ({
            id: entryId,
            isOpen,
            isLoading,
            onClose,
            onConfirm: onDelete,
            title: translateKey(WIDGET_DIRECTORY_ENTRIES.DELETE_ENTRY_CONFIRM_TITLE),
            confirmMessage: translateKey(WIDGET_DIRECTORY_ENTRIES.DELETE_ENTRY_CONFIRM_MESSAGE),
        }),
        [entryId, isLoading, isOpen, onClose, onDelete, translateKey],
    );

    return {
        openDeleteDialog: onOpen,
        deleteDialogProps,
    };
};
