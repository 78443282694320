import React from 'react';

import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';

import { SelectTextField } from '@lumapps/combobox/components/SelectTextField';
import { getDirectories } from '@lumapps/directories/ducks/selectors';
import { Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { usePaginatedFrontendSearch } from '@lumapps/utils/hooks/usePaginatedFrontendSearch';

import { FormattedTagFilter, FormattedDirectoryFilter } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';

interface TagsFromDirectoriesFilterProps {
    onChange: (tags: FormattedTagFilter[]) => void;
    directories?: FormattedDirectoryFilter[];
    selectedTags?: FormattedTagFilter[];
    shouldBeHiddenIfEmpty?: boolean;
    theme: Theme;
    itemsPerPage?: number;
    scope: string;
    hideSubheader: boolean;
}

export const TagsFromDirectoriesFilter = ({
    onChange,
    directories,
    selectedTags,
    shouldBeHiddenIfEmpty,
    theme = Theme.light,
    itemsPerPage = 20,
    scope,
    hideSubheader,
}: TagsFromDirectoriesFilterProps) => {
    const allDirectories = useSelector(getDirectories);
    const { translateKey, translateObject } = useTranslate();

    /** Extracting all tags from selected directories, adding information about there directories */
    const selectedDirectoriesIds = map(directories, 'id');
    const allTagsWithDirectories = React.useMemo(() => {
        /** Getting all information about selected directories from the store */
        const selectedDirectories = filter(allDirectories, (directory) =>
            includes(selectedDirectoriesIds, directory.id),
        );
        const list = selectedDirectories.reduce<FormattedTagFilter[]>((result, directory): FormattedTagFilter[] => {
            const tagsFromDirectories = map(get(directory, 'tags', []), (tag) => ({
                id: get(tag, 'uuid'),
                name: translateObject(get(tag, 'name')) || '',
                directory: {
                    id: get(directory, 'id'),
                    name: translateObject(get(directory, 'name')) || '',
                },
            }));
            return [...result, ...tagsFromDirectories];
        }, []);

        const byId = keyBy(list, 'id');
        return { list, byId };
    }, [allDirectories, selectedDirectoriesIds, translateObject]);

    /** Pagination */
    const { getMoreItems, items, onSearch } = usePaginatedFrontendSearch({
        itemList: allTagsWithDirectories.list,
        perPage: itemsPerPage,
        getItemName: (tag: FormattedTagFilter) => tag.name,
    });

    /**
     * Group tags by their directories
     * We are formatting the data to use the renderGroupChoice function that can
     * create select options grouped by sections
     */
    const getSectionId = React.useCallback((tag: FormattedTagFilter) => get(tag, 'directory.name', ''), []);

    /** Get up-to-date tag name from redux store */
    const getValueName = React.useCallback(
        (tag: FormattedTagFilter) => tag.name || allTagsWithDirectories.byId[tag.id]?.name,
        [allTagsWithDirectories.byId],
    );

    if (shouldBeHiddenIfEmpty && isEmpty(selectedDirectoriesIds)) {
        return null;
    }

    return (
        <>
            {!hideSubheader && <FilterSubheader label={translateKey(GLOBAL.CATEGORY)} theme={theme} />}
            <SelectTextField<FormattedTagFilter>
                selectionType="multiple"
                value={selectedTags || []}
                options={items}
                theme={theme}
                scope={scope}
                onLoadMore={getMoreItems}
                onSearch={onSearch}
                autoFilter={false}
                onChange={onChange}
                getOptionId="id"
                getOptionName={getValueName}
                getSectionId={getSectionId}
                label={translateKey(GLOBAL.TAGS)}
            />
        </>
    );
};
