/* istanbul ignore file */

import { contentSelector, getCurrentContentHasCommentWidget } from '@lumapps/contents/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BaseStore } from '@lumapps/redux/types';

import { ClientComputedSocialMetadata } from './ClientComputedSocialMetadata';

export const mapStateToProps = (state: BaseStore) => ({
    currentContent: contentSelector(state),
    hasCommentWidget: getCurrentContentHasCommentWidget(state),
});

const ConnectedClientComputedSocialMetadata = connect(mapStateToProps, undefined)(ClientComputedSocialMetadata);

export { ConnectedClientComputedSocialMetadata as ClientComputedSocialMetadata };
