import React from 'react';

import { SelectTextField, SelectTextFieldProps } from '@lumapps/combobox/components/SelectTextField';
import { useDataAttributes } from '@lumapps/data-attributes';
import { useTranslate } from '@lumapps/translations';

import { useMetadataPicker } from '../../hooks/useMetadataPicker';
import { MetadataPickerProps, PickerMetaData } from '../../types';

type InheritedSelectTextFieldProps = Pick<
    SelectTextFieldProps<PickerMetaData>,
    | 'selectionType'
    | 'label'
    | 'placeholder'
    | 'helper'
    | 'error'
    | 'isDisabled'
    | 'isRequired'
    | 'className'
    | 'value'
    | 'getOptionId'
    | 'onChange'
    | 'renderOption'
    | 'icon'
    | 'onSearch'
    | 'onBlur'
    | 'listProps'
    | 'inputRef'
    | 'theme'
    | 'scope'
    | 'hasError'
>;

type InheritedMetadataPickerProps = Pick<
    MetadataPickerProps,
    'familyId' | 'instanceId' | 'extraRequestFields' | 'useInstanceMetadata' | 'showMultipleLevels'
>;

export interface MetadataPickerFieldProps extends InheritedSelectTextFieldProps, InheritedMetadataPickerProps {}

const CLASSNAME = 'lumx-metadata-picker-field';

const SCOPE = 'metadata-picker';

/**
 * Metadata select text field
 *
 * @family Pickers
 */
export const MetadataPickerField = (props: MetadataPickerFieldProps) => {
    const {
        familyId,
        instanceId,
        onChange,
        useInstanceMetadata,
        extraRequestFields,
        showMultipleLevels,
        scope = SCOPE,
        getOptionId = 'id',
        listProps,
        ...forwardedProps
    } = props;
    const { translateObject } = useTranslate();
    const { get } = useDataAttributes(scope);

    const [fetchEnabled, setFetchEnabled] = React.useState(false);
    const enableFetch = React.useCallback(() => setFetchEnabled(true), []);

    const getMetadataName = React.useCallback(
        (metadata: PickerMetaData) => translateObject(metadata.name),
        [translateObject],
    );

    const isMultiple = forwardedProps.selectionType === 'multiple';
    const { status, setSearchText, filteredChoices, handleSelect, getValueName } = useMetadataPicker({
        familyId,
        instanceId,
        extraRequestFields,
        useInstanceMetadata,
        showMultipleLevels,
        shouldFetch: fetchEnabled,
        multiple: isMultiple,
        onChange: onChange as MetadataPickerProps['onChange'],
    });

    return (
        <SelectTextField<PickerMetaData>
            className={CLASSNAME}
            {...get({ element: isMultiple ? 'multiple-select' : 'select' })}
            {...(forwardedProps as any)}
            listProps={{
                ...listProps,
                ...get({
                    element: isMultiple ? 'multiple-option-list' : 'option-list',
                }),
            }}
            status={status}
            options={filteredChoices}
            onChange={handleSelect}
            getOptionId={getOptionId}
            getOptionName={getMetadataName}
            onSearch={setSearchText}
            autoFilter={false}
            onFocus={enableFetch}
            showEmptyState
            showErrorState
            openOnFocus
            renderOption={(metadata) => <SelectTextField.Option>{getValueName(metadata)}</SelectTextField.Option>}
        />
    );
};
