function DraggableWindowDirective($timeout) {
    'ngInject';

    function link(scope, el, attrs) {
        $timeout(() => {
            const handle = el.find(attrs.draggableWindowHandle);

            handle[0].onmousedown = function(event) {
                const isLeftClick = event.button === 0;
                if (!isLeftClick) return;
                let shiftX = event.clientX - el[0].getBoundingClientRect().left;
                let shiftY = event.clientY - el[0].getBoundingClientRect().top;

                function moveAt(clientX, clientY) {
                    el[0].style.bottom = 'auto';
                    el[0].style.right = 'auto';
                    el[0].style.left = clientX - shiftX + 'px';
                    el[0].style.top = clientY - shiftY + 'px';
                }

                moveAt(event.clientX, event.clientY);

                function onMouseMove(event) {
                    moveAt(event.clientX, event.clientY);
                }

                document.addEventListener('mousemove', onMouseMove);

                handle[0].onmouseup = function() {
                    document.removeEventListener('mousemove', onMouseMove);
                    handle[0].onmouseup = null;
                };
            }

            handle[0].ondragstart = function() {
                return false;
            };
        });
    }

    return {
        link: link,
    };
}

/////////////////////////////

angular.module('Directives').directive('draggableWindow', DraggableWindowDirective);

/////////////////////////////

export { DraggableWindowDirective };
