import React from 'react';

import { useClassnames, margin, font, typography, classnames } from '@lumapps/classnames';
import { useCroppedImage } from '@lumapps/focal-point/hooks/useCroppedImage';
import { mdiPlus } from '@lumapps/lumx/icons';
import { Orientation, Uploader, FlexBox, FlexBoxProps, Text, UploaderProps } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { UseFilePickerProps, useFilePicker } from '@lumapps/utils/hooks/useFilePicker';
import { getImageSizeForObjectUrl } from '@lumapps/utils/images/getImageSize';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { Image } from '../../types';
import { ImageWithActions, ImageWithActionsProps } from '../ImageWithActions';

export interface EditableImageProps extends Omit<ImageWithActionsProps, 'size' | 'img'> {
    /** custom class name for Uploader */
    className?: string;
    /** component that will be displayed before the uploader */
    before?: React.ReactNode;
    /** component that will be displayed after the uploader */
    after?: React.ReactNode;
    /** callback to be executed once the image has been selected */
    onImageSelected: (image: Image) => void;
    /**
     * callback when file picker is configured for multiple.
     * This prop was only created to be used from the EditableImages component
     * no need to use this directly if you are uploading multiple images.
     */
    onImagesSelected?: (image: Image[]) => void;
    /** options for the file picker */
    filePickerOptions?: Partial<UseFilePickerProps>;
    /** already selected image */
    image?: Image;
    /** option to display the label above the uploader */
    withLabel?: boolean;
    /** input field label */
    label?: string;
    /** label to be displayed inside the uploader */
    uploaderLabel?: string;
    /** other uploader props */
    uploaderProps?: Partial<UploaderProps>;
    wrapperProps?: Partial<FlexBoxProps>;
    /**
     * List of validations to perform on the uploaded file.
     */
    validations?: {
        /** function that returns a boolean that returns true if the image passes the validation or not */
        check: ({ width, height, fileSize }: { width: number; height: number; fileSize: number }) => boolean;
        /** error message to display if the validation does not pass */
        error: string;
    }[];
}

const CLASSNAME = 'editable-image';
const INPUT_ID = 'editable-image-field';
/**
 * Displays a component that allows to display an image, preview it and edit it.
 * This component only allows to manage a single image at a time. If you need to
 * manage multiple images, use the `EditableImages` component.
 *
 * @family Images
 * @param EditableImageProps
 * @returns EditableImage
 */
export const EditableImage: React.FC<EditableImageProps> = ({
    before,
    after,
    className,
    onImageSelected,
    onImagesSelected,
    filePickerOptions,
    image,
    label,
    withLabel = true,
    uploaderLabel,
    uploaderProps,
    thumbnailProps = {},
    validations = [],
    icon,
    onDelete,
    wrapperProps,
    ...props
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();
    const [error, setError] = React.useState<string | undefined>();
    const hasValidations = validations.length > 0;

    const {
        onCroppedImage,
        originalImage,
        image: croppedImage,
        cropInfo,
        focalPoint,
    } = useCroppedImage({
        image,
        onCrop: (newImage) => {
            onImageSelected(newImage);
        },
    });

    const { hiddenInput, openPicker } = useFilePicker({
        accept: 'image/*',
        ...filePickerOptions,
        onSelectFiles: (files: File[]) => {
            /**
             * We are managing images in order to contemplate the scenario where we are validating
             * the file when it is uploaded.
             */
            const selectedImages: Promise<Image>[] = [];

            files.forEach((file) => {
                const blobUrl = URL.createObjectURL(file);
                const selectedImage = {
                    blobUrl,
                    file,
                    name: file.name,
                    id: generateUUID(),
                };

                const imagePromise = new Promise<Image>((resolve, reject) => {
                    /**
                     * If there are any validations in place, we need to mount the image into the DOM
                     * in order to retrieve the actual width and height.
                     */
                    if (hasValidations) {
                        getImageSizeForObjectUrl(blobUrl).then(({ width, height }) => {
                            /**
                             * Once mounted, we go through each of the validations in order to check that they all pass.
                             * We use a `every` in order to stop the iteration if we know that there is at least one error.
                             */
                            const isValid = validations.every((validation) => {
                                const isValidationValid = validation.check({ width, height, fileSize: file.size });

                                /**
                                 * If there is an error, we reject the promise with the error message
                                 */
                                if (!isValidationValid) {
                                    reject(validation.error);
                                }

                                return isValidationValid;
                            });

                            /**
                             * If all validations passed, we resolve the promise
                             */
                            if (isValid) {
                                resolve(selectedImage);
                            }
                        });
                    } else {
                        /**
                         * If there are no validations, we resolve directly.
                         */
                        resolve(selectedImage);
                    }
                });

                selectedImages.push(imagePromise);
            });

            /**
             * Once all images/files are uploaded and validated, we respond to the
             * callback with either the list of images uploaded or the first image,
             * depending on the callbacks provided.
             */
            Promise.all(selectedImages)
                .then((selected) => {
                    if (onImagesSelected) {
                        onImagesSelected(selected);
                    }

                    onImageSelected(selected[0]);
                })
                .catch((error: string) => {
                    setError(error);
                });
        },
        inputProps: {
            id: INPUT_ID,
            ...filePickerOptions?.inputProps,
        },
    });

    const onOpenFilePicker = () => {
        setError(undefined);
        openPicker();
    };

    const imageToDisplay = !image ? undefined : croppedImage || image;
    const { multiple } = filePickerOptions || { multiple: false };
    const isUploaderDisplayed = !imageToDisplay || multiple;

    return (
        <FlexBox orientation={Orientation.vertical} className={block([className])} {...wrapperProps}>
            {before}

            {withLabel && (
                <label className={element('label', ['lumx-text-field__label'])} htmlFor={INPUT_ID}>
                    {label || translateKey(GLOBAL.THUMBNAIL)}
                </label>
            )}

            <FlexBox className={block('inner', [margin('top')])} orientation={Orientation.horizontal} gap="regular">
                {isUploaderDisplayed ? (
                    <Uploader
                        label={uploaderLabel || translateKey(GLOBAL.SELECT_IMAGE)}
                        icon={icon || mdiPlus}
                        onClick={onOpenFilePicker}
                        size={thumbnailProps.size}
                        {...uploaderProps}
                    />
                ) : null}

                {imageToDisplay ? (
                    <ImageWithActions
                        img={imageToDisplay}
                        originalImage={originalImage}
                        {...thumbnailProps}
                        {...props}
                        onDelete={
                            onDelete
                                ? () => {
                                      onDelete();
                                      setError(undefined);
                                  }
                                : undefined
                        }
                        onEdit={() => {
                            setError(undefined);
                            onOpenFilePicker();
                        }}
                        onCrop={onCroppedImage}
                        croppingProps={{
                            cropInfo,
                            focalPoint,
                        }}
                    />
                ) : null}
            </FlexBox>

            {hiddenInput}

            {after}

            {error ? (
                <Text as="span" className={classnames(font('red', 'N'), typography('caption'), margin('top'))}>
                    {error}
                </Text>
            ) : null}
        </FlexBox>
    );
};
