import React, { RefObject } from 'react';

import { GenerativeContentDialog } from '@lumapps/ai-generative-content/components/GenerativeContentDialog';
import { AI_GENERATED_CONTENT } from '@lumapps/ai-generative-content/keys';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiSparkles } from '@lumapps/lumx/icons';
import { Button, ColorPalette, Emphasis } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { fromMarkdown } from '@lumapps/wrex/serialization/markdown/fromMarkdown';
import { Editor, ReactEditor, Transforms, useSlate } from '@lumapps/wrex/slate';

export interface GenerativeContentButtonProps {
    parentDialogRef?: RefObject<HTMLDivElement>;
    className?: string;
}

export const GenerativeContentButton = ({ parentDialogRef, className }: GenerativeContentButtonProps) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes('wrex-generative-content');
    const editor = useSlate() as ReactEditor;

    const [isOpen, setIsOpen] = React.useState(false);

    const onInsert = (generatedText: string) => {
        Transforms.insertFragment(editor, fromMarkdown(generatedText), {
            at: Editor.end(editor, []),
        });

        const articleDialogContent = parentDialogRef?.current;

        if (articleDialogContent) {
            articleDialogContent.scrollTop = articleDialogContent.scrollHeight;
        }
    };

    return (
        <>
            <Button
                emphasis={Emphasis.low}
                hasBackground={false}
                color={ColorPalette.primary}
                className={className}
                leftIcon={mdiSparkles}
                onClick={() => setIsOpen(true)}
                {...get({ element: 'button', action: 'generate' })}
            >
                {translateKey(AI_GENERATED_CONTENT.GENERATE_TEXT)}
            </Button>

            <GenerativeContentDialog isOpen={isOpen} onClose={() => setIsOpen(false)} onInsert={onInsert} />
        </>
    );
};
