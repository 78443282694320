import BaseApi, { API_VERSION, ServerListResponse } from '@lumapps/base-api';

import { FetchLikeUserList, FetchToggleLike } from '../query';
import { LikeListParams } from '../types';
import { ReactionDetails, ReactionResource, ReactionResourceType, ResourceReaction, UserReactions } from './types';

const api = new BaseApi({ path: '', version: API_VERSION.v2 });

/** Add a reaction on a resource */
export const addReaction = async ({ resourceType, resourceId, reactionType }: ResourceReaction) => {
    const { data } = await api.put<ReactionDetails>(`${resourceType}/${resourceId}/reactions/${reactionType}`);
    return data;
};

/** Delete a reaction on a resource */
export const deleteReaction = async ({ resourceType, resourceId, reactionType }: ResourceReaction) => {
    const { data } = await api.delete<ReactionDetails>(`${resourceType}/${resourceId}/reactions/${reactionType}`);
    return data;
};

/** List reactions by users */
export const listUserReactions = async ({ resourceType, resourceId }: ReactionResource, params: LikeListParams) => {
    const { data } = await api.get<ServerListResponse<UserReactions>>(`${resourceType}/${resourceId}/reactions/users`, {
        params,
    });
    return data;
};

/** Toggle like/unlike */
export const fetchToggleLike: FetchToggleLike<ReactionResourceType> = async (resourceRef, isLiked) => {
    const call = isLiked ? deleteReaction : addReaction;
    const data = await call({ ...resourceRef, reactionType: 'like' });
    return { isLiked: data.hasUserReacted, likeCount: data.count };
};

/** Fetch users who like the resource */
export const fetchLikeUserList: FetchLikeUserList<ReactionResourceType> = async (resourceRef, params) => {
    const data = await listUserReactions(resourceRef, params);

    // format users
    const users = data.items
        .filter(({ reactions }) => reactions.includes('like'))
        .map(({ user: { userId, fullName } }) => ({ id: userId, fullName }));
    return { ...data, items: users };
};
