import BaseApi from '@lumapps/base-api';
import { get as getConfig } from '@lumapps/constants';
import { list } from '@lumapps/user/api';

import { LikeListParams, LikeState, MinimalUserList } from '../types';
import { LikeResponse, LegacyLikeResource } from './types';

const api = new BaseApi({ path: '' });

/** List users who liked an v1 resource */
export const fetchLikeUserList = async (
    { resourceType, resourceId }: LegacyLikeResource,
    params: LikeListParams,
): Promise<MinimalUserList> => {
    const { data } = await list({
        reactedEntityKind: resourceType,
        reactedEntityKey: resourceId,
        status: 'ENABLED',
        fields: 'items(id,fullName),more,callId,cursor',
        showHidden: false,
        lang: getConfig().userLang,
        ...(params as any),
    });
    return data as any;
};

/** Toggle like/unlike */
export const fetchToggleLike = async (
    { resourceId, resourceType }: LegacyLikeResource,
    isLiked: boolean,
    likeCount: number,
): Promise<LikeState> => {
    const action = isLiked ? 'unlike' : 'like';
    await api.post<LikeResponse>(`${resourceType}/${action}`, {
        uid: resourceId,
        fields: 'likes,liked',
    });
    return { isLiked: !isLiked, likeCount: action === 'like' ? likeCount + 1 : likeCount - 1 };
};

/** Fetch like status */
export async function fetchLikeStatus(
    { resourceId, resourceType }: LegacyLikeResource,
    signal?: AbortSignal,
): Promise<LikeState> {
    const { data } = await api.get<LikeResponse>(`${resourceType}/get`, {
        params: { uid: resourceId, fields: 'id,likes,liked' },
        headers: { 'x-lumapps-analytics': 'off' },
        signal,
    });
    return { isLiked: data.liked, likeCount: data.likes };
}
