import { GDFile } from '@lumapps/google-drive-pickers/types';
import { MediaSourceV2, MediaStatus } from '@lumapps/medias/types';
import { getMediaIcon, FILE_TYPES_ICONS } from '@lumapps/medias/utils';

import { FileElement } from '../types';
import { createFile } from './createFile';

export const mapGoogleDriveFileToSlateFile = (files: GDFile[]): FileElement[] => {
    return files.map((file) =>
        createFile({
            name: file.name,
            mimeType: file.mimeType,
            createdAt: file.createdTime,
            editedAt: file.modifiedTime,
            src: file.webViewLink || file.webContentLink,
            status: file.trashed || file.explicitlyTrashed ? MediaStatus.DELETED : MediaStatus.LIVE,
            isLoading: false,
            thumbnailUrl:
                getMediaIcon({ mimeType: file.mimeType }) === FILE_TYPES_ICONS.photo ? file.thumbnailLink : undefined,
            resource: {
                id: file.id,
                type: MediaSourceV2.GOOGLE_DRIVE,
            },
        }),
    );
};
