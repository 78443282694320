import React from 'react';

import { getContentListLinkRef } from '@lumapps/content-lists/utils/getContentListLinkRef';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { contentSelector } from '@lumapps/contents/ducks/selectors';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { Link } from '@lumapps/lumx/react';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { getMainWidget } from '@lumapps/widget-base/ducks/selectors';
import { filterByMetadata } from '@lumapps/widget-base/ducks/thunks/filterByMetadata';

import { MetadataV2 } from '../types';
import { useMetadataContentListResolver } from './useMetadataContentListResolver';

export const useMetadataLink = (metadata: MetadataV2, contentTypeId: string) => {
    const { id: instanceId } = useSelector(getCurrentInstance);
    const { id: contentId } = useSelector(contentSelector);
    const { translateObject } = useTranslate();
    const dispatch = useDispatch();
    const mainWidget = useSelector(getMainWidget);

    const defaultContentList = useMetadataContentListResolver({
        contentTypeId,
        currentSiteId: instanceId,
        metadataId: metadata.id,
    });

    const route = React.useMemo(() => {
        if (defaultContentList) {
            return contentId && defaultContentList.id === contentId && mainWidget
                ? {
                      LinkAs: Link,
                      onClick: async () => {
                          dispatch(filterByMetadata(mainWidget, metadata.id));
                      },
                  }
                : {
                      LinkAs: ContentLink,
                      to: getContentListLinkRef(
                          defaultContentList,
                          translateObject(defaultContentList.slug) as string,
                          metadata.id,
                      ),
                  };
        }

        return null;
    }, [contentId, defaultContentList, dispatch, mainWidget, metadata.id, translateObject]);

    return route;
};
