import React from 'react';

import { BaseApiError } from '@lumapps/base-api';
import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages';
import { useTranslatedLanguages } from '@lumapps/play-video-subtitles/hooks';
import { oldToNewTranscript } from '@lumapps/play-video-subtitles/utils/oldToNewTranscript/oldToNewTranscript';
import { useSelector } from '@lumapps/redux/react';
import { Route, useRouter } from '@lumapps/router';
import { DEFAULT_AVATAR } from '@lumapps/user/constants';
import { getUserProfilePictureUrl } from '@lumapps/user/utils/getUserProfilePicture';

import { getVideo } from '../../api';
import type { GetVideoResult, ThumbnailSizes, Video } from '../../api/types';
import { THUMBNAIL_REGULAR } from '../../constants';
import { isPlayAutogeneratedSubtitlesEnabled } from '../../ducks/selectors';

export const VIDEO_PAGE_FIELDS: (keyof Video)[] = [
    'assets',
    'chapters',
    'createdAt',
    'createdByDetails',
    'createdById',
    'publishedAt',
    'publicationStatus',
    'description',
    'id',
    'isDownloadable',
    'tagz',
    'title',
    'thumbnail',
    'transcript',
    'views',
    'editableByGroupIds',
    'editableByGroupDetails',
];

export const WIDGET_PLAY_SETTINGS_FIELDS: (keyof Video)[] = ['id', 'thumbnail', 'title'];

export const CLIENT_COMPUTED_PLAY_FIELDS: (keyof Video)[] = [
    'createdAt',
    'createdByDetails',
    'createdById',
    'publishedAt',
    'publicationStatus',
    'description',
    'id',
    'thumbnail',
    'title',
    'views',
];

export interface UseFetchUniqueVideoParams {
    videoId: Video['id'] | undefined;
    notFoundRoute?: Route;
    fields?: (keyof Video)[];
}

export const useFetchUniqueVideo = (
    { videoId, notFoundRoute, fields }: UseFetchUniqueVideoParams,
    {
        enabled = true,
        thumbnailSizes = THUMBNAIL_REGULAR,
    }: {
        enabled?: boolean;
        /** The thumbnail sizes to retrieve from getVideo()
         * @default THUMBNAIL_REGULAR
         */
        thumbnailSizes?: ThumbnailSizes;
    } = {},
) => {
    const { redirect } = useRouter();
    const [videoLoading, setVideoLoading] = React.useState(false);
    const [video, setVideo] = React.useState<GetVideoResult>();
    const [videoError, setVideoError] = React.useState(false);
    const [videoErrorCode, setVideoErrorCode] = React.useState<number | undefined>();
    const customerId = useSelector(customerIdSelector);

    const { switchLanguageCode } = useTranslatedLanguages();
    const currentLanguage = switchLanguageCode(useSelector(currentLanguageSelector));
    const isPlayAutogeneratedSubtitles = useSelector(isPlayAutogeneratedSubtitlesEnabled);

    const fetchVideo = React.useCallback(async () => {
        if (videoId) {
            setVideoLoading(true);
            try {
                const fetchedVideo = await getVideo(videoId, fields, { thumbnailSizes });

                if (fetchedVideo) {
                    const profilePictureUrl = fetchedVideo.createdByDetails?.id
                        ? getUserProfilePictureUrl({
                              id: fetchedVideo.createdByDetails.id,
                              customer: customerId,
                          })
                        : DEFAULT_AVATAR;

                    const formattedVideo = {
                        ...fetchedVideo,
                        createdByDetails: fetchedVideo.createdByDetails
                            ? {
                                  ...fetchedVideo.createdByDetails,
                                  profilePictureUrl,
                              }
                            : undefined,
                    } as Video;

                    const newSubtitles = isPlayAutogeneratedSubtitles
                        ? formattedVideo.subtitles
                        : oldToNewTranscript(formattedVideo, formattedVideo.audioLanguage || currentLanguage);

                    setVideo({ ...formattedVideo, subtitles: newSubtitles });
                }
                setVideoErrorCode(undefined);
            } catch (error) {
                setVideo(undefined);
                setVideoError(true);
                if (error instanceof BaseApiError) {
                    setVideoErrorCode(error?.status);
                }
                if (notFoundRoute) {
                    redirect(notFoundRoute);
                }
            }
        } else {
            setVideo(undefined);
        }
        setVideoLoading(false);
    }, [
        currentLanguage,
        customerId,
        fields,
        isPlayAutogeneratedSubtitles,
        notFoundRoute,
        redirect,
        thumbnailSizes,
        videoId,
    ]);

    React.useEffect(() => {
        if (enabled) {
            fetchVideo();
        }
    }, [enabled, fetchVideo]);

    return {
        videoLoading,
        video,
        videoError,
        videoErrorCode,
        fetchVideo,
    };
};
