import { useCallback } from 'react';

import map from 'lodash/map';

import { useGoogleDrivePicker } from '@lumapps/google-drive-pickers/hooks/useGoogleDrivePicker';
import { GDCustomDocViewType, GDDocument, GDPickerConfiguration } from '@lumapps/google-drive-pickers/types';
import { currentLanguageSelector } from '@lumapps/languages';
import { useSelector } from '@lumapps/redux/react';
import { connectedUserSelector } from '@lumapps/user/ducks/selectors';

import { Media, MediaContent } from '../types';

/**
 * Hook that provides a function to open the Google drive picker.
 * The picked media will be passed to the provided callback as arguments.
 * @param onMediaChange callback to handle the picked media
 * @returns a function to open the Google drive picker
 */
export const useUploadMediaFromGoogleDrive = (
    onMediaChange?: (medias: Media[] | Media) => void,
    currentFiles: Media[] = [],
) => {
    const currentUser = useSelector(connectedUserSelector);
    const currentLanguage = useSelector(currentLanguageSelector);
    const token = currentUser?.token;

    const onSelectDriveFiles = useCallback(
        (files: GDDocument[]) => {
            const medias = map(files, (file) => {
                const { id, url, name, lastEditedUtc, mimeType } = file;

                return {
                    uid: id,
                    uuid: id,
                    isFolder: false,
                    updatedAt: lastEditedUtc.toString(),
                    content: [
                        {
                            downloadUrl: url,
                            url,
                            name,
                            mimeType,
                            lang: currentLanguage,
                            uid: id,
                            uuid: id,
                            servingUrl: url,
                        } as MediaContent,
                    ],
                    name: { en: name },
                    thumbnail: {
                        en: url,
                    },
                } as Partial<Media>;
            });

            onMediaChange?.([...currentFiles, ...medias] as Media[]);
        },
        [currentFiles, currentLanguage, onMediaChange],
    );

    const { openGoogleDrivePicker } = useGoogleDrivePicker(
        {
            clientId: '',
            developerKey: '',
            multiselect: true,
            customDocViews: [GDCustomDocViewType.DEFAULT_VIEW, GDCustomDocViewType.SHARED_DRIVE_VIEW],
            locale: currentLanguage,
            setIncludeFolders: true,
            setSelectFolderEnabled: false,
            token,
        } as GDPickerConfiguration,
        {
            onFilesSelected: (files: GDDocument[]) => {
                onSelectDriveFiles(files);
            },
        },
    );

    return openGoogleDrivePicker;
};
