import BaseApi, { API_VERSION, formatLanguageHeader } from '@lumapps/base-api';

const analyticsApiConfig = { version: API_VERSION.v2, bypassMonolith: true };

const analyticsApi = new BaseApi(analyticsApiConfig);

/**
 * Fetch analytics dashboard datas
 * @param url
 * @returns url
 */
const fetchEmbeddedDashboardSignedUrl = async (url: string, langs: string[], filters?: string): Promise<string> => {
    const headers = {
        'Accept-Language': formatLanguageHeader(langs),
    };
    let params = {};
    if (filters) {
        params = { ...params, initialLookerFilters: filters };
    }
    const { data } = await analyticsApi.get(url, { headers, params }, true, true, url);
    return data.url;
};

/**
 * Cancel the dashboad analytics fetching datas
 * @param url
 */
const cancelFetchEmbeddedDashboardSignedUrl = (url: string) => {
    analyticsApi.cancel(url, undefined, url);
};

export { cancelFetchEmbeddedDashboardSignedUrl, fetchEmbeddedDashboardSignedUrl };
