import { Route, createPageRoute } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const instancesCopyRoute: Route = createPageRoute({
    slug: 'platform/site-copy',
    legacyId: 'app.admin.instances-copy',
    appId: AppId.backOffice,
});

/** Retrieves the route for the instances copy admin */
const instancesCopy = (): Route => instancesCopyRoute;

export { instancesCopy, instancesCopyRoute };
