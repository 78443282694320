/* istanbul ignore file */
import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

const LazyShareToTeamsDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'share-to-teams-dialog' */
            './ConnectedShareToTeamsDialog'
        ),
);

export const ShareToTeamsDialog = ({ isOpen, ...props }: React.ComponentProps<typeof LazyShareToTeamsDialog>) => (
    <DelayedSuspense isDialog shouldLoad={isOpen}>
        <LazyShareToTeamsDialog isOpen={isOpen} {...props} />
    </DelayedSuspense>
);
