import React from 'react';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { FetchContext, ThumbnailSizes } from '@lumapps/play/api/types';
import { MAX_PAGE_CONTENT } from '@lumapps/play/constants';
import { useSelector } from '@lumapps/redux/react';
import { FetchCallback, FetchParams, FetchProps, useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { cancelGetPlaylists, getPlaylists } from '../../api';
import { GetPlaylistsResponse } from '../../api/types';
import type { Playlist } from '../../types';

export interface UseFetchPlaylistsParams {
    /**
     * Fetching playlists context (= library, picker)
     */
    context?: FetchContext;
    /**
     * Fetch key used to cancel api calls
     */
    fetchKey?: string;
    /**
     * Max results per page
     */
    maxResults?: number;
    /**
     * Whether podcasts should be fetched
     */
    shouldFetch?: boolean;
    /**
     * Thumbnail sizes required
     */
    thumbnailSizes?: ThumbnailSizes;
    /**
     * Include site family
     */
    includeSiteFamily?: boolean;
}

export const useFetchPlaylists = ({
    context,
    includeSiteFamily = false,
    fetchKey,
    maxResults = MAX_PAGE_CONTENT,
    shouldFetch = false,
    thumbnailSizes,
}: UseFetchPlaylistsParams = {}) => {
    const instanceId = useSelector(instanceIdSelector);
    const [playlists, setPlaylists] = React.useState<Playlist[]>([]);
    const [searchText, setSearchText] = React.useState('');
    const [filters, setFilters] = React.useState<Omit<FetchParams, 'search'>>();

    const { cancelFetch, fetch, response, status } = useFetchWithStatus({
        onFetch: getPlaylists,
        onFetchCancel: cancelGetPlaylists,
    });

    const playlistIds = React.useMemo(() => {
        return playlists.map((playlist) => playlist.id);
    }, [playlists]);

    const playlistCount = playlists.length;

    const hasPlaylists = playlistCount > 0;
    const canDisplayFilters = Boolean(hasPlaylists || (!hasPlaylists && (searchText || !!filters)));

    const handleFetchCallback = React.useCallback<FetchCallback<GetPlaylistsResponse>>(
        ({ success, response: callbackResponse }) => {
            if (success && callbackResponse) {
                setPlaylists(callbackResponse.items);
            }
        },
        [],
    );

    const handleFetchMoreCallback = React.useCallback<FetchCallback<GetPlaylistsResponse>>(
        ({ success, response: callbackResponse }) => {
            if (success && callbackResponse) {
                setPlaylists((currentPlaylists) => {
                    return [...currentPlaylists, ...callbackResponse.items];
                });
            }
        },
        [],
    );

    const fetchMore = React.useCallback(() => {
        if (response?.more && status === BaseLoadingStatus.idle) {
            fetch({
                params: [
                    {
                        context,
                        cursor: response.cursor,
                        maxResults,
                        ...(searchText && {
                            search: searchText,
                        }),
                        siteIds: [instanceId],
                        ...(thumbnailSizes && { ...thumbnailSizes }),
                        ...filters,
                        includeSiteFamily,
                    },
                    fetchKey,
                ],
                callback: handleFetchMoreCallback,
                fetchMore: true,
            });
        }
    }, [
        context,
        fetch,
        fetchKey,
        filters,
        handleFetchMoreCallback,
        instanceId,
        maxResults,
        response?.cursor,
        response?.more,
        searchText,
        status,
        thumbnailSizes,
        includeSiteFamily,
    ]);

    const fetchPlaylists = React.useCallback(
        ({ params, ...rest }: FetchProps = {}) => {
            fetch({
                callback: handleFetchCallback,
                params: [
                    {
                        context,
                        maxResults,
                        ...(searchText && { search: searchText }),
                        siteIds: [instanceId],
                        ...(thumbnailSizes && { ...thumbnailSizes }),
                        ...params,
                        ...filters,
                        includeSiteFamily,
                    },
                    fetchKey,
                ],
                ...rest,
            });
        },
        [
            context,
            fetch,
            fetchKey,
            filters,
            handleFetchCallback,
            instanceId,
            maxResults,
            searchText,
            thumbnailSizes,
            includeSiteFamily,
        ],
    );

    React.useEffect(() => {
        if (shouldFetch) {
            fetchPlaylists({
                debounce: true,
            });
        }

        return () => {
            cancelFetch({ params: [fetchKey] });
        };
    }, [context, shouldFetch, fetchPlaylists, fetchKey, searchText, thumbnailSizes, cancelFetch]);

    return {
        /** Boolean used to display or not filters inside the toolbar */
        canDisplayFilters,
        /** The API call, via the useFetchWithStatus hook  */
        fetchPlaylists,
        /** Handle playlists pagination
         *
         * Search param is automatically handled
         */
        fetchMore,
        /** Whether playlists are returned */
        hasPlaylists,
        /** How many playlists are returned */
        playlistCount,
        /** Array of playlist ids */
        playlistIds,
        /** The list of playlists */
        playlists,
        /** Fetching status */
        status,
        /** Items, more, cursor */
        response,
        /** Apply custom filters (status, author, dates) to fetch a new set of videos */
        setFilters,
        /** The search text is made available as it's used to update some components UI */
        searchText,
        /** Handles search params via the playlists searchbar */
        setSearchText,
    };
};
