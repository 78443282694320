import React from 'react';

import { Theme } from '@lumapps/lumx/react';
import { Media, MediaSource, MediaType, WithBlob } from '@lumapps/medias/types';
import { getMediaContent } from '@lumapps/medias/utils';
import { TranslateObject, useTranslate } from '@lumapps/translations';
import { isUrl } from '@lumapps/utils/string/isUrl';
import { Attachments as AttachmentsV2 } from '@lumapps/widget-base/components/Block/BlockAttachments/Attachments';
import { LinkAttachment, LinkResourceType } from '@lumapps/widget-base/types';

import { AttachmentTypes } from '../../types';

export const getThumbnailUrl = (thumbnail: TranslateObject, translateFunc: (toTranslate: any) => string | null) => {
    if (!thumbnail) {
        return '';
    }
    const translatedThumbnailUrl = translateFunc(thumbnail);
    if (!translatedThumbnailUrl) {
        return '';
    }
    return isUrl(translatedThumbnailUrl) ? translatedThumbnailUrl : `/serve/${translatedThumbnailUrl}`;
};

export interface AttachmentsProps {
    className?: string;
    images?: Array<Media & WithBlob>;
    files?: Array<Media & WithBlob>;
    link?: LinkAttachment;
    locale: string;
    containerId?: string;

    onClearAttachments?(): void;
    shouldDisplayEventAttendButton?: boolean;
    readOnly?: boolean;
    isLoading?: boolean;
    theme?: Theme;
    onRemoveAttachment?(attachmentType: AttachmentTypes, attachmentIndex: number): void;
    onEditAttachment?(attachmentType: AttachmentTypes, data?: LinkAttachment): void;
    isCommentContext?: boolean;
    /** Callback for switching the link preview thumbnail */
    onLinkPreviewThumbnailSwitch?: () => void;
}

const Attachments: React.FC<AttachmentsProps> = ({
    className,
    images,
    link,
    files: medias,
    onClearAttachments,
    locale,
    isLoading,
    readOnly,
    theme,
    containerId,
    shouldDisplayEventAttendButton = true,
    onRemoveAttachment,
    onEditAttachment,
    isCommentContext = false,
    onLinkPreviewThumbnailSwitch,
}) => {
    const { translateObject } = useTranslate();

    const articlePreview =
        link && link.resourceType === LinkResourceType.ARTICLE
            ? {
                  id: link.resourceId || '',
                  title: link.title || '',
                  description: link.description,
                  image: link.images?.[0],
                  shares: [
                      {
                          container: {
                              containerId,
                          },
                      },
                  ],
              }
            : undefined;

    const eventPreview =
        link && link.resourceType === LinkResourceType.EVENT
            ? {
                  id: link.resourceId || '',
                  title: link.title || '',
                  description: link.description,
                  image: link.images?.[0],
                  startsAt: link.startsAt,
                  endsAt: link.endsAt,
                  shares: [
                      {
                          container: {
                              containerId,
                          },
                      },
                  ],
              }
            : undefined;

    const playVideoPreview =
        link && link.resourceType === LinkResourceType.PLAY_VIDEO
            ? {
                  id: link.resourceId || '',
                  title: link.title || '',
                  description: link.description,
                  image: link.images?.[0],
              }
            : undefined;

    return (
        <AttachmentsV2
            className={className}
            theme={theme}
            readOnly={readOnly}
            isLoading={isLoading}
            onClearAttachments={onClearAttachments}
            onRemoveAttachment={onRemoveAttachment}
            onEditAttachment={onEditAttachment}
            isCommentContext={isCommentContext}
            eventPreview={eventPreview}
            articlePreview={articlePreview}
            playVideoPreview={playVideoPreview}
            shouldDisplayEventAttendButton={shouldDisplayEventAttendButton}
            shouldDisplayEmbed
            link={
                link
                    ? {
                          ...link,
                          image: {
                              type: 'BlockImage',
                              url: link.images?.[link.thumbnailIndex || 0] || '',
                          },
                      }
                    : undefined
            }
            files={medias?.map((media) => {
                const mediaContent = getMediaContent(media, translateObject, locale);
                const thumbnailUrl =
                    mediaContent.type === MediaType.IMAGE &&
                    (media.source === MediaSource.LUMDRIVE || media.source === MediaSource.HAUSSMANN_MEDIA)
                        ? mediaContent.url || mediaContent.servingUrl
                        : getThumbnailUrl(media.thumbnail, translateObject);
                const downloadUrl =
                    media.source === MediaSource.MICROSOFT ||
                    media.source === MediaSource.HAUSSMANN_MEDIA ||
                    media.source === MediaSource.GOOGLE_DRIVE
                        ? mediaContent.servingUrl || mediaContent.url
                        : mediaContent.downloadUrl;

                return {
                    source: media.source || media.from,
                    mimeType: mediaContent.mimeType || '',
                    mediaId: mediaContent.fileId || '',
                    title: mediaContent.name || '',
                    fileName: translateObject(media.name) || mediaContent.name || '',
                    blobUrl: media.blobUrl,
                    url: downloadUrl || '',
                    thumbnail: {
                        type: 'BlockImage',
                        url: thumbnailUrl || '',
                        focalX: mediaContent.focalPoint?.x,
                        focalY: mediaContent.focalPoint?.y,
                    },
                    uuid: media.uuid,
                    createdAt: media.createdAt || mediaContent.createdAt,
                };
            })}
            images={images?.map((img) => {
                const imageContent = getMediaContent(img, translateObject, locale);
                return {
                    source: img.source || img.from,
                    height: imageContent.height,
                    mimeType: imageContent.mimeType || '',
                    mediaId: imageContent.fileId || '',
                    title: imageContent.name || '',
                    fileName: imageContent.name || '',
                    blobUrl: img.blobUrl,
                    url: imageContent.downloadUrl || imageContent.url || imageContent.servingUrl,
                    thumbnail: {
                        type: 'BlockImage',
                        url: translateObject(img.thumbnail) || '',
                        focalX: imageContent.focalPoint?.x,
                        focalY: imageContent.focalPoint?.y,
                    },
                };
            })}
            onLinkPreviewThumbnailSwitch={onLinkPreviewThumbnailSwitch}
        />
    );
};

export { Attachments };
