import { NGI_WIDGETS_IN_DESIGNER_FF_TOKEN } from '@lumapps/widget-base/constants';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import loFind from 'lodash/find';
import get from 'lodash/get';

/////////////////////////////

function WidgetMetaController(
    $scope,
    $state,
    Config,
    Content,
    CustomContentType,
    Features,
    InitialSettings,
    Instance,
    SocialAdvocacy,
    Metadata,
    Translation,
    User,
    Utils,
    Widget,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Whether the widget should be displayed in NGI or not.
     */
    vm.isNGICompatible = Utils.isDesignerMode() && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2');


    /**
     * Indicates if it's a new (unsaved) content.
     *
     * @type {boolean}
     */
    vm.isNewContent = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Config = Config;
    vm.Content = Content;
    vm.CustomContentType = CustomContentType;
    vm.Metadata = Metadata;
    vm.Translation = Translation;
    vm.User = User;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Initialize the analytics views count.
     */
    function _initAnalyticsViews() {
        vm.contentAnalyticsViews =
            Instance.getInstance().googleAnalytics && vm.widget.properties.displayAnalyticsViews
                ? Content.getCurrent().analyticsViewSum
                : false;
    }

    /**
     * Initialize the fields to display in the widget.
     */
    function _initFields() {
        if (angular.isDefinedAndFilled(vm.widget.properties.fields)) {
            return;
        }

        const defaultMetaFields = Instance.getProperty(Config.INSTANCE_PROPERTIES.META_FIELDS);

        if (angular.isString(defaultMetaFields[0])) {
            vm.widget.properties.fields = [];

            angular.forEach(defaultMetaFields, function forEachMetaFields(metaField) {
                vm.widget.properties.fields.push({
                    enable: true,
                    name: metaField,
                });
            });
        } else {
            vm.widget.properties.fields = angular.fastCopy(defaultMetaFields);
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add metadata class according to metadata functionnal inner id.
     *
     * @param  {Object} metadataKey Metadata id.
     * @return {string} Metadata classes.
     *
     */
    function getMetadataFunctionalInnerId(metadataKey) {
        const metadata = Metadata.getMetadataFromKey(metadataKey, false, true);
        let metadataClass;

        if (angular.isDefinedAndFilled(metadata.functionalInnerId)) {
            metadataClass = `widget-meta__metadata--${metadata.functionalInnerId}`;
        }

        return metadataClass;
    }

    /**
     * Get widget classes.
     *
     * @return {Array} Widget classes.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        const authorField = loFind(vm.widget.properties.fields, {
            name: 'author',
        });

        if (get(authorField, 'enable', false)) {
            widgetClass.push('widget-meta--has-author');
        }

        const socialField = loFind(vm.widget.properties.fields, {
            name: 'social',
        });

        if (get(socialField, 'enable', false)) {
            widgetClass.push('widget-meta--has-social');
        }

        return widgetClass;
    }

    /**
     * Is widget empty in designer mode.
     *
     * @return {boolean} True if widget has no content.
     */
    function isWidgetEmpty() {
        /*
         * Widget is empty if all fields are disabled or
         * if tags and/or metadata field are enabled and content doesn't have tag/metadata.
         */
        const currentContent = Content.getCurrent();
        const visibleFields = loFind(vm.widget.properties.fields, function checkTagsAndMetadata(field) {
            if (!field.enable) {
                return false;
            }

            if (field.name === 'tags') {
                return angular.isDefinedAndFilled(currentContent.customContentTypeTags);
            } else if (field.name === 'metadata') {
                const areAllMetadataEmpty = isEmpty(compact(map(currentContent.metadata, (medatata) => !isEmpty(medatata))));
                return !areAllMetadataEmpty && angular.isDefinedAndFilled(currentContent.metadata);
            }
            return true;
        });

        return angular.isUndefinedOrEmpty(visibleFields);
    }

    /**
     * Is widget hidden in reading mode.
     *
     * @return {boolean} True if widget has to be hidden in reading mode.
     */
    function isWidgetHidden() {
        if (!vm.parentCtrl.designerMode() && vm.isWidgetEmpty()) {
            vm.parentCtrl.isHidden = true;
        } else {
            vm.parentCtrl.isHidden = false;
        }

        return vm.parentCtrl.isHidden;
    }

    function _shouldDisplayAsNGI() {
        return vm.isNGICompatible;
    }

    function setAsNonNgiCompatible() {
        vm.isNGICompatible = false;
    }

    function setAsNgiCompatible() {
        const isInDesignerContext = vm.parentCtrl.designerMode();
        // Prevent the switch to true if the required FF are not enabled.
        if(isInDesignerContext && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2')) {
            vm.isNGICompatible = true;
        }
    }

    /////////////////////////////

    vm.getMetadataFunctionalInnerId = getMetadataFunctionalInnerId;
    vm.getWidgetClass = getWidgetClass;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;
    vm.shouldDisplayAsNGI = _shouldDisplayAsNGI;
    vm.setAsNonNgiCompatible = setAsNonNgiCompatible;
    vm.setAsNgiCompatible = setAsNgiCompatible;
    vm.isArray = angular.isArray;
    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Reinitialize the meta of the current content when the widget meta settings change.
     *
     * @param {Event}  evt        The original event triggering this function.
     * @param {string} widgetUuid The uuid of the widget that just got changed.
     */
    $scope.$on('widget-meta-settings', function onPostListSettingsChange(evt, widgetUuid) {
        if (vm.widget.uuid === widgetUuid) {
            _initAnalyticsViews();
        }
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    vm.init = function init() {
        const currentContent = Content.getCurrent();

        vm.isNewContent = angular.isUndefinedOrEmpty(get(currentContent, 'uid', get(currentContent, 'id')));

        // Check if content has widget comments, if true display comments count is widget meta.
        vm.hasComments = angular.isDefinedAndFilled(
            Widget.findWidgetsByType(currentContent.template, InitialSettings.WIDGET_TYPES.COMMENTS),
        );

        // Set content publication date.
        vm.contentPublicationDate = currentContent.publicationDate || new Date();

        // Set flag for SocialAdvocacy.
        vm.isSoscialAdvocacyEnabled = SocialAdvocacy.isEnabled();

        // Set if is community
        vm.communityContext = $state.current.name === 'app.front.community';

        _initAnalyticsViews();

        _initFields();
    };

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl Parent Controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;

        const isInDesignerContext = vm.parentCtrl.designerMode();

        // If we are not in the designer context or the content is not v2 compatible
        // we fallback to legacy display (we consider unsaved content as v2 compatible).
        // Here we are trying to guess which display will be the correct one in view mode.
        // This is not perfect, but we want to avoid too much logic in the frontend to know if a content is
        // compatible v2 or not (widget types presents on the content + some specific settings on some widgets)
        if ((!isInDesignerContext || (Content.getAction() !== 'create' && Content.getCurrent()?.template.isV2Compatible === false))) {
            setAsNonNgiCompatible();
        }

        vm.init();
    };
}

function WidgetMetaDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetMetaController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetMeta', '^widget'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-meta/views/widget-meta.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetMeta', WidgetMetaDirective);

/////////////////////////////

export { WidgetMetaController, WidgetMetaDirective };
