import React from 'react';

import set from 'lodash/set';

import { get } from '@lumapps/constants';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { Image } from '@lumapps/lumx-images/types';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { uploadMedias, UploadMediaOptions } from '../api';
import { ContainerType, UploadedMedia } from '../types';
import { getMediaUrl } from '../utils';

const constants = get();

export interface UploadMediasState {
    medias: Image[];
    status: BaseLoadingStatus;
}

export const initialState: UploadMediasState = {
    medias: [],
    status: BaseLoadingStatus.initial,
};

export const reducers = {
    setStatus: (state: UploadMediasState, action: PayloadAction<BaseLoadingStatus>) => {
        set(state, 'status', action.payload);
    },
    setMedias: (state: UploadMediasState, action: PayloadAction<Image[]>) => {
        set(state, 'medias', action.payload);
    },
    reset: (state: UploadMediasState) => {
        set(state, 'medias', initialState.medias);
    },
};

export const { actions, reducer } = createSlice({
    domain: 'upload-media',
    initialState,
    reducers,
});

export interface UseUploadMediaProps {
    /** options that will be passed in to the upload media API */
    uploadMediaOptions?: Partial<Omit<UploadMediaOptions, 'files'>>;
    /**
     * callback to be executed once the medias have been uploaded,
     * it will be undefined if the uploaded media is removed
     */
    onMediaUploaded?: (medias?: UploadedMedia[]) => void;
    /** callback to be executed on error */
    onMediaUploadFailure?: () => void;
    /** list of already selected medias */
    selectedMedias?: Image[];
}

export interface UseUploadMedia {
    /** whether the images are currently uploading or not */
    areImagesLoading: boolean;
    /** callback to be executed once the media is deleted */
    onDelete: () => void;
    /** upload media state */
    state: UploadMediasState;
    /** callback for file selection */
    onSelectImages: (images: Image[]) => void;
}

/**
 * Hook that centralises the actions for uploading a media.
 * @param UseUploadMediaProps
 */
export const useUploadMedia = ({
    uploadMediaOptions,
    onMediaUploaded,
    onMediaUploadFailure,
    selectedMedias = [],
}: UseUploadMediaProps): UseUploadMedia => {
    const instanceId = useSelector(instanceIdSelector);
    const [state, dispatch] = React.useReducer(reducer, { ...initialState, medias: selectedMedias });
    const { error } = useNotification();
    const { status } = state;

    const onSelectImages = async (images: Image[]) => {
        if (images.length) {
            /**
             * In order to have a more responsive UI, once a file is selected
             * we create an object URL for each file, which will allow us to
             * quickly display the image on the UI.
             *
             * With those URLs generated, we update the state and instantly show
             * those medias in a loading state.
             */
            dispatch(actions.setStatus(BaseLoadingStatus.loading));
            dispatch(actions.setMedias(images));

            try {
                const uploadOptions = {
                    containerId: instanceId,
                    containerType: ContainerType.SITE,
                    arePublic: true,
                    ...uploadMediaOptions,
                    files: images.map((image) => image.file) as File[],
                };

                /**
                 * We then continue to upload those medias. The idea here is to let the uploadMedias
                 * method do the work, and when it is finished, we update the already exisiting
                 * medias with the new values sent from the API, which are the media id as well as
                 * the uploaded URL.
                 */
                const confirmedUploadedMedias = await uploadMedias(uploadOptions);

                const uploadedMediasWithIds: UploadedMedia[] = [];

                images.forEach((media, index) => {
                    uploadedMediasWithIds.push({
                        ...media,
                        ...uploadOptions,
                        id: confirmedUploadedMedias[index].mediaId,
                        mediaId: confirmedUploadedMedias[index].mediaId,
                        // As private link are temporary we need to construct them as permalink
                        url:
                            uploadMediaOptions?.arePublic === false
                                ? getMediaUrl(confirmedUploadedMedias[index].mediaId)
                                : confirmedUploadedMedias[index].url,
                        organizationId: constants.customerId,
                    });
                });

                /**
                 * Finally, we update the internal state with the new data, and if there is a callback
                 * defined for when images are uploaded we execute said callback.
                 */
                dispatch(actions.setMedias(uploadedMediasWithIds));
                dispatch(actions.setStatus(BaseLoadingStatus.idle));

                onMediaUploaded?.(uploadedMediasWithIds);
            } catch (excp) {
                dispatch(actions.reset());
                dispatch(actions.setStatus(BaseLoadingStatus.error));

                onMediaUploadFailure?.();

                error({ translate: GLOBAL.ERROR_OCCURRED_TRY_AGAIN });
            }
        }
    };

    /**
     * When deleting the image, we reset the internal state of the component and
     * we execute the callback with an undefined value, letting the parent component know
     * that nothing was added.
     */
    const onDelete = () => {
        dispatch(actions.reset());

        if (onMediaUploaded) {
            onMediaUploaded();
        }
    };

    return {
        areImagesLoading: status === BaseLoadingStatus.loading,
        onDelete,
        state,
        onSelectImages,
    };
};
