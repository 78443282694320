import React from 'react';

import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { useContentLink } from '@lumapps/contents/hooks/useContentLink';
import { CONTENTS } from '@lumapps/contents/keys';
import { ContentLinkRef } from '@lumapps/contents/types';
import { ActionMenu } from '@lumapps/lumx-menu/components/ActionMenu';
import { mdiEye, mdiContentCopy } from '@lumapps/lumx/icons';
import { getAbsoluteUrl } from '@lumapps/router/utils';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useCopyToClipboard } from '@lumapps/utils/hooks/useCopyToClipboard';

import { BlockPagePreview } from '../../type';

export type PagePreviewActionsProps = Pick<BlockPagePreview, 'pageId' | 'slug' | 'site' | 'theme'>;

const CLASSNAME = 'page-preview-actions';

/**
 * The action Menu and items for the BlockPagePreview
 */
export const PagePreviewActions: React.FC<PagePreviewActionsProps> = ({ pageId, slug, site, theme }) => {
    const { translateKey } = useTranslate();

    const copyText = useCopyToClipboard();

    const to: ContentLinkRef = {
        id: pageId,
        slug,
        instance: {
            id: site.siteId,
            slug: site.slug,
        },
    };

    const contentLink = useContentLink(to);

    const handleCopyLink = () => {
        copyText(getAbsoluteUrl(contentLink.props.href || ''));
    };

    return (
        <ActionMenu className={CLASSNAME} label={translateKey(GLOBAL.MORE_ACTIONS)} theme={theme}>
            <ActionMenu.Item icon={mdiEye} as={ContentLink} to={to} crossInstanceInNewTab>
                {translateKey(CONTENTS.DISPLAY_PUBLICATION)}
            </ActionMenu.Item>
            <ActionMenu.Item icon={mdiContentCopy} onClick={handleCopyLink}>
                {translateKey(GLOBAL.COPY_LINK)}
            </ActionMenu.Item>
        </ActionMenu>
    );
};
