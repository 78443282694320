/* istanbul ignore file */
import React from 'react';

import { getInstanceDirectories, isDirectoriesAllowed } from '@lumapps/directories/ducks/selectors';
import { isInstanceSuperAdmin } from '@lumapps/permissions';
import { useSelector } from '@lumapps/redux/react';

import { DirectoriesSideNavigation } from './DirectoriesSideNavigation';

const ConnectedDirectoriesSideNavigation: React.FC = () => {
    const directories = useSelector(getInstanceDirectories);
    const isAllowed = useSelector(isDirectoriesAllowed);
    const isSuperAdmin = useSelector(isInstanceSuperAdmin);

    return <DirectoriesSideNavigation directories={directories} isAllowed={isAllowed} isSuperAdmin={isSuperAdmin} />;
};

export { ConnectedDirectoriesSideNavigation as DirectoriesSideNavigation };
