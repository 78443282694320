import { useMemo } from 'react';

import filter from 'lodash/fp/filter';
import getOr from 'lodash/fp/getOr';
import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';
import upperCase from 'lodash/fp/upperCase';

import { getCurrentContentAsCommunity } from '@lumapps/communities/ducks/selectors';
import { POST_TYPE_LABEL_KEYS } from '@lumapps/posts/constants';
import { useSelector } from '@lumapps/redux/react';
import { selectSpaceData } from '@lumapps/spaces/ducks/selectors';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_POST_LIST } from '../../keys';
import { PostListFilterBlockProps, PostListFilterHookProps, PostType } from '../../types';

export const TYPES_FILTER_CHOICES = [
    {
        name: POST_TYPE_LABEL_KEYS[PostType.DEFAULT],
        value: PostType.DEFAULT,
    },
    {
        name: POST_TYPE_LABEL_KEYS[PostType.IDEA],
        value: PostType.IDEA,
    },
    {
        name: POST_TYPE_LABEL_KEYS[PostType.QUESTION],
        value: PostType.QUESTION,
    },
];

export function usePostTypesFilter({
    activeFiltersValues,
    filtersValues,
    handleValuesChange,
}: PostListFilterHookProps): PostListFilterBlockProps<PostType> {
    const filterId = 'postTypes';

    const { translateKey } = useTranslate();

    const currentSpace = useSelector(selectSpaceData);
    const currentCommunity = useSelector(getCurrentContentAsCommunity);
    const selectedValues = getOr([] as PostType[], filterId, filtersValues);
    const activeValues = getOr([] as PostType[], filterId, activeFiltersValues);

    const choices = useMemo(() => {
        const communityPostTypes = map(upperCase, getOr([], 'postTypes', currentSpace || currentCommunity));
        return filter(({ value }) => includes(value, communityPostTypes), TYPES_FILTER_CHOICES);
    }, [currentCommunity, currentSpace]);

    const { getChoiceName, label } = useMemo(() => {
        const getChoiceName = (value: PostType) => translateKey(POST_TYPE_LABEL_KEYS[value]);
        const label = translateKey(WIDGET_POST_LIST.FILTERS_TYPES);

        return {
            getChoiceName,
            label,
        };
    }, [translateKey]);

    return {
        choices,
        filterId,
        getChoiceName,
        label,
        onSelect: handleValuesChange,
        selectedValues,
        activeValues,
    };
}
