import React from 'react';

import LearningDashboard from '@lumapps/embedded-analytics/components/LearningDashboard';
import { FlexBox } from '@lumapps/lumx/react';

import './index.scss';

const CLASSNAME = 'learning-team-kpi-iframe';

/**
 *  widget that contains an iframe to teach on mars for the Manager TeamKpi
 * @returns Manager TeamKpi
 */
export const TeamKpi: React.FC = () => {
    return (
        <FlexBox className={CLASSNAME} orientation="vertical">
            <LearningDashboard />
        </FlexBox>
    );
};
