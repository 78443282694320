import React from 'react';

import upperFirst from 'lodash/fp/upperFirst';

import { useListFilter } from '@lumapps/lumx-filter-and-sort/hooks/useListFilter';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiMenu } from '@lumapps/lumx/icons';
import { ColorPalette, Emphasis, IconButton, Size, Theme } from '@lumapps/lumx/react';
import { SpaceServiceProvider } from '@lumapps/spaces/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WIDGET_CALENDAR } from '../keys';
import { LegacyCalendar } from '../types';

export const useCalendarsFilters = (availableCalendars: LegacyCalendar[], hasToolbar?: boolean, theme?: Theme) => {
    const { translateKey } = useTranslate();

    /**
     * Filter the list of calendars from which we want to extract events
     * Each calendar within this list will be queried to show event inside the calendar
     * */
    const {
        onSelected,
        selection: selectedCalendars,
        isInSelection,
    } = useListFilter<LegacyCalendar>({
        getId: (calendar) => calendar.id,
        defaultSelected: availableCalendars,
    });

    /**
     * Display the calendar selector if there is more than one.
     * */
    const CalendarSelectorMenu =
        hasToolbar && availableCalendars && availableCalendars.length > 1 ? (
            <Menu.Trigger>
                <Menu.TriggerButton
                    as={IconButton}
                    label={translateKey(GLOBAL.SETTINGS)}
                    size={Size.m}
                    color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
                    icon={mdiMenu}
                    emphasis={Emphasis.low}
                />
                <Menu>
                    <Menu.Section label={translateKey(WIDGET_CALENDAR.ACTIVE_CALENDAR)}>
                        {availableCalendars.map((calendar) => {
                            const calendarProvider =
                                calendar.provider === SpaceServiceProvider.lumapps
                                    ? translateKey(WIDGET_CALENDAR.COMMUNITY_EVENTS)
                                    : upperFirst(calendar.provider as string);
                            return (
                                <Menu.ItemCheckbox
                                    key={calendar.id}
                                    onClick={() => onSelected(calendar)}
                                    isChecked={isInSelection(calendar)}
                                >
                                    {calendar.summary ?? calendarProvider}
                                </Menu.ItemCheckbox>
                            );
                        })}
                    </Menu.Section>
                </Menu>
            </Menu.Trigger>
        ) : undefined;

    return {
        selectedCalendars,
        CalendarSelectorMenu,
    };
};
