import { ContentTypes } from '@lumapps/content-types/types';
import type { ContentLinkRef } from '@lumapps/contents/types';

import type { DefaultContentList } from '../types';

export const getContentListLinkRef = (
    defaultContentList: DefaultContentList,
    slug: string,
    metadataId?: string,
): ContentLinkRef => {
    const { id, instanceDetails: instance } = defaultContentList;
    return {
        id,
        slug,
        type: ContentTypes.CUSTOM_LIST,
        externalKey: metadataId,
        instance,
        query: metadataId ? { filters: `metadata_${metadataId}` } : undefined,
    };
};
