/* istanbul ignore file */
import React, { Suspense } from 'react';

import { ShareMenuItemLoading } from '@lumapps/share/components/ShareMenuItemLoading';

import { DEFAULT_LOADING_ITEMS } from './constants';

const LazyShareToExtensionsMenuItem = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'share-to-extension-menu-item' */
            './ShareToExtensionsMenuItem'
        ),
);

export const ShareToExtensionsMenuItem = (props: React.ComponentProps<typeof LazyShareToExtensionsMenuItem>) => {
    const { loadingItems } = props;
    return (
        <Suspense fallback={<ShareMenuItemLoading itemNumber={loadingItems || DEFAULT_LOADING_ITEMS} />}>
            <LazyShareToExtensionsMenuItem {...props} />
        </Suspense>
    );
};
