import React from 'react';

import { padding } from '@lumapps/classnames';
import { SelectFieldSimple, SelectFieldSimpleProps } from '@lumapps/combobox/components/SelectFieldSimple';
import { SelectProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';
import { DefaultValueWrapper } from '../DefaultValueWrapper';

export type FormSelectProps<T> = Omit<SelectFieldSimpleProps<T>, 'onChange' | 'value' | 'isOpen' | 'setOpen'> &
    BaseInput<T> & {
        isClearable?: boolean;
        /** Other Select props that we want to pass to the Select component */
        selectProps?: Pick<SelectProps, 'helper'>;
    };

/**
 * Component to be used for selecting an single option inside a form
 * @deprecated If possible, prefer usage of `FormSelectTextField` with `selectionType="single"`. This new component
 * uses the combobox accessible pattern.
 * Be aware that `FormSelectTextField` implements only the `input` `selectVariant` with the search feature.
 * It should now be the default design.
 *
 * @deprecated Use the `FormSelectTextField` component instead.
 *
 * @family Forms
 * @param FormSelectProps
 * @returns FormSelect
 */
export const FormSelect = <T,>({
    name,
    isRequired,
    controllerProps,
    isClearable = false,
    label,
    selectProps,
    isDisabled,
    ...props
}: FormSelectProps<T>) => {
    const { translateKey } = useTranslate();
    const {
        field,
        fieldState,
        isFieldDisabled,
        defaultValueWrapperProps,
        isOpen,
        setIsOpen,
        valueToUse,
        getDataAttributes,
    } = useFormField<T>({
        name,
        isRequired,
        controllerProps,
        label,
    });

    const hasError = Boolean(fieldState.error);

    return (
        <DefaultValueWrapper {...defaultValueWrapperProps}>
            <SelectFieldSimple
                aria-invalid={hasError}
                {...props}
                label={label}
                isOpen={isOpen}
                setOpen={setIsOpen}
                value={valueToUse || null}
                onChange={field.onChange}
                className={padding('vertical', null)}
                onSelectClear={isClearable ? () => field.onChange(null) : undefined}
                selectProps={{
                    ...getDataAttributes({ element: 'select', action: name }),
                    ...selectProps,
                    ref: field.ref,
                    name: field.name,
                    'aria-invalid': hasError,
                    hasError,
                    isRequired,
                    error: translateKey(GLOBAL.FIELD_IS_REQUIRED),
                    onBlur: field.onBlur,
                    isDisabled: isDisabled || isFieldDisabled,
                }}
            />
        </DefaultValueWrapper>
    );
};
