/* eslint-disable lumapps/no-classname-strings */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import * as ReactDOM from 'react-dom';

import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { RenderingType } from '@lumapps/communities/types';
import { ContentTypes } from '@lumapps/content-types/types';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { standardizeTranslateObject, TranslationAPI, useTranslate } from '@lumapps/translations';
import { Falsy } from '@lumapps/utils/types/Falsy';

interface PageLink {
    anchor: HTMLElement;
    linkData: {
        type: 'content' | 'community';
        id: string;
        slug: string;
        link?: string;
        instance?: { id: string };
        renderingType?: RenderingType;
    };
}

/**
 * Extract page links (anchor + link data) in the HTML element.
 */
function listPageLinks(
    element: HTMLElement | Falsy,
    translateObject: TranslationAPI['translateObject'],
): Array<PageLink> {
    if (!element) {
        return [];
    }
    return Array.from(element.querySelectorAll('a[data-url]'))
        .map((anchor): PageLink | undefined => {
            try {
                const dataUrl = anchor.getAttribute('data-url') as string;
                const { slug, link, instance, ...linkData } = JSON.parse(dataUrl);
                return {
                    anchor: anchor as HTMLElement,
                    linkData: {
                        ...linkData,
                        slug: translateObject(standardizeTranslateObject(slug)),
                        link: translateObject(standardizeTranslateObject(link)),
                        instance: instance ? { id: instance } : undefined,
                    },
                };
            } catch (ignored) {
                return undefined;
            }
        })
        .filter(Boolean) as Array<PageLink>;
}

/**
 * Mount children into a shadow DOM to isolate them from the style & js
 * of the rest of the page. Making sure no client customization can touch them.
 */
const ShadowLinks: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [shadow, setShadow] = React.useState(null);
    const initShadow = React.useCallback((div: any) => {
        if (!div) {
            return;
        }
        setShadow(div.attachShadow({ mode: 'open' }));
    }, []);
    return (
        <div className="block-html-shadow-links" ref={initShadow}>
            {shadow && children && ReactDOM.createPortal(children, shadow)}
        </div>
    );
};

/**
 * Detect LumApps page links in element (<a data-url="{...}") and render the corresponding React link into a shadow DOM
 * then copy the react link attributes to the original link and forward click from the original link to the react link.
 */
export function useResolveHTMLPageLinks(ref: React.RefObject<HTMLElement> | Falsy) {
    const { translateObject } = useTranslate();
    const element = ref && ref.current;

    return React.useMemo(
        () => {
            // Get all links from the HTML
            const pageLinks = listPageLinks(element as HTMLElement, translateObject);
            if (!element || pageLinks.length === 0) {
                return null;
            }

            // Render the page links as react links.
            const reactLinks = pageLinks.map(({ linkData, anchor }, index) => {
                const target = anchor.getAttribute('target');
                const { type } = linkData;

                const linkRef: React.Ref<HTMLAnchorElement> = (reactAnchor) => {
                    if (!reactAnchor) {
                        return;
                    }
                    // Copy link attributes
                    for (const attr of ['href', 'target', 'rel']) {
                        const value = reactAnchor.getAttribute(attr);
                        if (value) {
                            anchor.setAttribute(attr, value);
                        }
                    }
                    // Forward click event
                    anchor.addEventListener('click', (evt: MouseEvent) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        evt.stopImmediatePropagation();
                        reactAnchor.dispatchEvent(new MouseEvent(evt.type, evt));
                    });
                };
                // Render community link
                if (type === ContentTypes.COMMUNITY) {
                    return (
                        <CommunityLink
                            key={index}
                            style={{ display: 'none' }}
                            ref={linkRef}
                            to={linkData}
                            target={target}
                        />
                    );
                }
                // Render content link
                return (
                    <ContentLink
                        key={index}
                        style={{ display: 'none' }}
                        ref={linkRef}
                        to={{
                            ...linkData,
                            // Force update (slug could be out of date)
                            hasStaleData: true,
                        }}
                        target={target}
                    />
                );
            });

            // Mount react links into a shadow DOM
            return <ShadowLinks>{reactLinks}</ShadowLinks>;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [element],
    );
}
