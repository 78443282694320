import React, { ReactNode } from 'react';

import { classnames, padding } from '@lumapps/classnames';
import { Dialog, Toolbar, DialogProps, FlexBox, Size } from '@lumapps/lumx/react';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { useFormDialog } from '../../hooks/useFormDialog';
import { useMultiLanguageForm } from '../../hooks/useMultiLanguageForm';
import { FormProvider } from '../../utils/FormProvider';
import { FormActions } from '../FormActions';
import { FormLanguageSwitcher } from '../FormLanguageSwitcher';
import { MultiLanguageFormProps } from '../MultiLanguageForm';

export interface MultiLanguageFormDialogProps extends MultiLanguageFormProps {
    /** custom classname */
    className?: string;
    /** dialog title */
    heading: React.ReactNode;
    /** on close callback */
    onClose: DialogProps['onClose'];
    /** whether the dialog is open or not */
    isOpen: DialogProps['isOpen'];
    /** additional dialog props */
    dialogProps?: Partial<DialogProps>;
    /** feature scope. needed for tracking and a11y purposes */
    scope: string;
    /* Whether we display or not the language selector */
    isDialogLanguageSelectorDisplayed?: boolean;
    children?: ReactNode;
}

const CLASSNAME = 'lumx-form-dialog';

/**
 * Component that displays a form inside a dialog, controlling and preventing submit if the
 * dialog is not valid while managing multi language translations
 *
 * @family Forms
 * @param MultiLanguageFormDialogProps
 * @returns MultiLanguageFormDialog
 */
export const MultiLanguageFormDialog: React.FC<MultiLanguageFormDialogProps> = ({
    className,
    dialogProps,
    heading,
    children,
    onClose,
    onSubmit,
    scope,
    isOpen,
    formActionProps,
    form,
    formProps,
    wrapperProps,
    before,
    after,
    languages,
    defaultLanguage,
    status,
    isDialogLanguageSelectorDisplayed = true,
}) => {
    const {
        methods,
        onSubmit: onFormSubmit,
        ...restOfMethods
    } = useMultiLanguageForm({
        form,
        onSubmit,
        defaultLanguage,
        languages,
    });

    const { onCloseDialog, onVisibilityChange } = useFormDialog({
        reset: methods.reset,
        onClose,
        defaultValues: restOfMethods.defaultValues,
        values: form ? form.values : undefined,
    });

    return (
        <FormProvider {...methods} {...restOfMethods} scope={scope}>
            <Dialog
                className={classnames(className, CLASSNAME)}
                {...dialogProps}
                isOpen={isOpen}
                onVisibilityChange={(open) => {
                    onVisibilityChange(open);

                    if (dialogProps?.onVisibilityChange) {
                        dialogProps.onVisibilityChange(open);
                    }
                }}
                onClose={onCloseDialog}
                isLoading={status === BaseLoadingStatus.loading}
            >
                <header>
                    <Toolbar
                        label={heading}
                        after={
                            isDialogLanguageSelectorDisplayed && languages.length > 0 ? (
                                <FormLanguageSwitcher
                                    label=""
                                    languages={languages}
                                    defaultLanguage={defaultLanguage}
                                    marginRight={Size.regular}
                                />
                            ) : null
                        }
                    />
                </header>

                <form
                    onSubmit={onFormSubmit}
                    noValidate
                    {...formProps}
                    id={scope}
                    className={classnames(padding('horizontal', 'huge'), formProps?.className)}
                >
                    {before}

                    <FlexBox orientation="vertical" gap="big" {...wrapperProps}>
                        {children}
                    </FlexBox>

                    {after}
                </form>

                <footer>
                    <FormActions
                        onSubmit={onFormSubmit}
                        onCancel={onCloseDialog}
                        scope={scope}
                        {...formActionProps}
                        saveProps={{ form: scope, ...(formActionProps ? formActionProps.saveProps || {} : {}) }}
                        toolbarProps={{
                            ...(formActionProps?.toolbarProps || {}),
                            style: { paddingRight: '24px' },
                        }}
                        status={status}
                    />
                </footer>
            </Dialog>
        </FormProvider>
    );
};
