/* istanbul ignore file */
import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

const LazyShareContentToSocialNetworkDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'share-content-to-social-network-dialog' */
            './ShareContentToSocialNetworkDialog'
        ),
);

export const ShareContentToSocialNetworkDialog = (
    props: React.ComponentProps<typeof LazyShareContentToSocialNetworkDialog>,
) => {
    const { isOpen } = props;
    return (
        <DelayedSuspense isDialog shouldLoad={isOpen}>
            <LazyShareContentToSocialNetworkDialog {...props} />
        </DelayedSuspense>
    );
};
