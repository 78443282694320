import React, { Children, Fragment } from 'react';

import { LoadMoreFocusHelper } from '@lumapps/a11y/components/LoadMoreFocusHelper';
import { useClassnames, visuallyHidden } from '@lumapps/classnames';
import { Button, GridColumn, GridColumnProps, ProgressCircular, Theme, Text, ButtonProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import './index.scss';

export type LoadingRenderer = React.FC<{ theme?: Theme }>;

export type InheritedGridColumnProps = Pick<GridColumnProps, 'itemMinWidth' | 'maxColumns'>;

export interface PaginatedGridColumnsProps extends InheritedGridColumnProps {
    /** Forwarded className */
    className?: string;
    /** Nodes to display in a grid */
    children?: React.ReactNode;
    /** DS theme to be forwarded to the load more button and loader */
    theme?: Theme;
    /** Max number of item loaded in a page (used in a11y announcement) */
    maxItemsPerPage?: number;
    /** Callback when clicking the load more button */
    onLoadMore?: () => void;
    /** Loading state */
    loadingStatus?: BaseLoadingStatus;
    /** Loading renderer */
    renderLoading?: LoadingRenderer;
    /** Forward props to the load more button */
    loadMoreButtonProps?: Partial<ButtonProps>;
}

export const CLASSNAME = 'lumx-paginated-grid-columns';

/** The default loading renderer */
const DefaultLoadingRenderer: LoadingRenderer = ({ theme }) => {
    const { element } = useClassnames(CLASSNAME);
    return <ProgressCircular className={element('loader')} theme={theme} />;
};

/**
 * A `GridColumns` with loading state, loaders and load more buttons.
 *
 * @family Layouts
 */
export const PaginatedGridColumns: React.FC<PaginatedGridColumnsProps> = ({
    className,
    children,
    theme,
    itemMinWidth,
    maxColumns,
    maxItemsPerPage,
    onLoadMore,
    loadingStatus,
    renderLoading: LoadingRenderer = DefaultLoadingRenderer,
    loadMoreButtonProps,
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    const itemsDisplayedLength = Children.toArray(children).length;
    const isLoading = loadingStatus === BaseLoadingStatus.loading;
    const isLoadingMore = loadingStatus === BaseLoadingStatus.loadingMore;

    const loadingMessageRef = React.useRef<HTMLParagraphElement>(null);
    React.useEffect(() => {
        if (isLoading || isLoadingMore) {
            loadingMessageRef.current?.focus({ preventScroll: true });
        }
    }, [isLoadingMore, isLoading]);

    return (
        <GridColumn
            className={block(undefined, className)}
            itemMinWidth={itemMinWidth}
            maxColumns={maxColumns}
            gap="huge"
        >
            {!isLoading
                ? React.Children.map(children, (child, i) => {
                      if (!React.isValidElement(child)) {
                          return child;
                      }
                      return (
                          <Fragment key={child.key}>
                              <LoadMoreFocusHelper
                                  itemIndex={i}
                                  itemsDisplayedLength={itemsDisplayedLength}
                                  itemsPerPage={maxItemsPerPage}
                              />
                              {child}
                          </Fragment>
                      );
                  })
                : null}

            <Text
                as="p"
                className={visuallyHidden()}
                role="status"
                aria-live="polite"
                tabIndex={-1}
                ref={loadingMessageRef}
            >
                {isLoading || isLoadingMore ? translateKey(GLOBAL.LOADING) : null}
            </Text>

            {isLoading || isLoadingMore ? <LoadingRenderer theme={theme} /> : null}

            {onLoadMore && !isLoadingMore && !isLoading ? (
                <Button
                    fullWidth
                    theme={theme}
                    emphasis="medium"
                    {...loadMoreButtonProps}
                    className={element('load-more', undefined, loadMoreButtonProps?.className)}
                    onClick={onLoadMore}
                >
                    {translateKey(GLOBAL.SHOW_MORE)}
                </Button>
            ) : null}
        </GridColumn>
    );
};
