import React from 'react';

import isUndefined from 'lodash/isUndefined';

import { BaseApiError } from '@lumapps/base-api';
import { getServiceServerError } from '@lumapps/base-api/utils/getServerError';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useSelector } from '@lumapps/redux/react';
import { connectedUserSelector } from '@lumapps/user/ducks/selectors';
import { User } from '@lumapps/user/types';
import { UserReference } from '@lumapps/widget-base/types';

import { registerToEvent } from '../api/registerToEvent';
import { EVENTS } from '../keys';
import { RegistrationStatuses, RegistrationStatusTypes } from '../types';
import { toUserReference } from '../utils';

interface UseRegisterToEventProps {
    eventId: string;
    initialRegistrationStatus?: RegistrationStatusTypes;
    attendees?: UserReference[];
    attendeesCount?: number;
    remainingSpots?: number;
    isUserOrganizer: boolean;
    hasLimitedCapacity: boolean;
}

export const useRegisterToEvent = ({
    eventId,
    initialRegistrationStatus: backendInitialRegistrationStatus = RegistrationStatuses.unanswered,
    attendees,
    attendeesCount = 0,
    remainingSpots,
    isUserOrganizer,
    hasLimitedCapacity,
}: UseRegisterToEventProps) => {
    const connectedUser = useSelector(connectedUserSelector) as User;
    const connectedUserReference = toUserReference(connectedUser);

    const [registrationStatus, setRegistrationStatus] = React.useState(backendInitialRegistrationStatus);
    const [initialRegistrationStatus, setInitialRegistrationStatus] = React.useState(backendInitialRegistrationStatus);
    const [currentAttendees, setCurrentAttendees] = React.useState(attendees);
    const [currentAttendeesCount, setCurrentAttendeesCount] = React.useState(attendeesCount);
    const [currentRemainingSpots, setCurrentRemainingSpots] = React.useState(remainingSpots);

    const spotCanBeChanged =
        hasLimitedCapacity &&
        !isUserOrganizer &&
        initialRegistrationStatus === RegistrationStatuses.going &&
        !isUndefined(currentRemainingSpots);

    const { success, error, warning } = useNotification();

    const simpleRegisterToEvent = React.useCallback(
        async (newRegistrationStatus: RegistrationStatusTypes) => {
            if (newRegistrationStatus !== initialRegistrationStatus) {
                try {
                    setRegistrationStatus(newRegistrationStatus);

                    if (newRegistrationStatus !== RegistrationStatuses.going && spotCanBeChanged) {
                        // Increment spot left
                        setCurrentRemainingSpots(currentRemainingSpots + 1);
                    } else if (
                        hasLimitedCapacity &&
                        newRegistrationStatus === RegistrationStatuses.going &&
                        !isUserOrganizer &&
                        !isUndefined(currentRemainingSpots) &&
                        currentRemainingSpots > 0
                    ) {
                        // Decrement spot left.
                        setCurrentRemainingSpots(currentRemainingSpots - 1);
                    }

                    await registerToEvent(eventId, newRegistrationStatus);

                    // User vacated one spot (going user selected maybe or not going)
                    if (
                        hasLimitedCapacity &&
                        !isUserOrganizer &&
                        initialRegistrationStatus === RegistrationStatuses.going &&
                        initialRegistrationStatus !== newRegistrationStatus &&
                        !isUndefined(remainingSpots)
                    ) {
                        warning({ translate: EVENTS.REGISTRATION_VACATED_SPOT_WARNING });
                    }

                    // User didn't booked one spot (not going or unanswered user selected maybe)
                    else if (
                        hasLimitedCapacity &&
                        !isUserOrganizer &&
                        initialRegistrationStatus !== RegistrationStatuses.going &&
                        newRegistrationStatus === RegistrationStatuses.maybe &&
                        !isUndefined(remainingSpots)
                    ) {
                        warning({ translate: EVENTS.REGISTRATION_NOT_BOOKED_SPOT_WARNING });
                    }

                    setInitialRegistrationStatus(newRegistrationStatus);
                } catch (err) {
                    if (err instanceof BaseApiError) {
                        setRegistrationStatus(initialRegistrationStatus);

                        if (err.response?.status === 400) {
                            setCurrentRemainingSpots(0);
                        } else {
                            setCurrentRemainingSpots(remainingSpots);
                        }

                        error({ translate: getServiceServerError(err.response) });
                    }
                }
            }
        },
        [
            isUserOrganizer,
            currentRemainingSpots,
            eventId,
            initialRegistrationStatus,
            hasLimitedCapacity,
            remainingSpots,
            spotCanBeChanged,
            warning,
            error,
        ],
    );

    const registerToEventWithGuests = React.useCallback(
        async (newRegistrationStatus: RegistrationStatusTypes) => {
            if (newRegistrationStatus !== initialRegistrationStatus) {
                try {
                    const updatedRegistrationStatus =
                        newRegistrationStatus === RegistrationStatuses.askToAttend
                            ? RegistrationStatuses.waitingList
                            : newRegistrationStatus;
                    setRegistrationStatus(updatedRegistrationStatus);
                    if (newRegistrationStatus !== RegistrationStatuses.going) {
                        if (currentAttendees && currentAttendees.find((user) => user.userId === connectedUser.id)) {
                            // Remove current user for guests list.
                            setCurrentAttendees(currentAttendees.filter((user) => user.userId !== connectedUser.id));
                            // Decrement guests count.
                            setCurrentAttendeesCount(currentAttendeesCount - 1);
                            // Increment spot left
                            if (spotCanBeChanged) {
                                setCurrentRemainingSpots(currentRemainingSpots + 1);
                            }
                        }
                    } else {
                        if (currentAttendees) {
                            // Add current user to guests list.
                            setCurrentAttendees([...currentAttendees, connectedUserReference]);
                            // Increment guests count.
                            setCurrentAttendeesCount(currentAttendeesCount + 1);
                        }
                        if (
                            hasLimitedCapacity &&
                            !isUserOrganizer &&
                            !isUndefined(currentRemainingSpots) &&
                            currentRemainingSpots > 0
                        ) {
                            // Decrement spot left.
                            setCurrentRemainingSpots(currentRemainingSpots - 1);
                        }
                    }
                    await registerToEvent(eventId, updatedRegistrationStatus);

                    // User vacated one spot (going user selected maybe or not going)
                    if (
                        hasLimitedCapacity &&
                        !isUserOrganizer &&
                        initialRegistrationStatus === RegistrationStatuses.going &&
                        initialRegistrationStatus !== newRegistrationStatus &&
                        !isUndefined(remainingSpots)
                    ) {
                        warning({ translate: EVENTS.REGISTRATION_VACATED_SPOT_WARNING });
                    }

                    // User didn't booked one spot (not going or unanswered user selected maybe)
                    else if (
                        hasLimitedCapacity &&
                        !isUserOrganizer &&
                        initialRegistrationStatus !== RegistrationStatuses.going &&
                        newRegistrationStatus === RegistrationStatuses.maybe &&
                        !isUndefined(remainingSpots)
                    ) {
                        warning({ translate: EVENTS.REGISTRATION_NOT_BOOKED_SPOT_WARNING });
                    }

                    if (newRegistrationStatus === RegistrationStatuses.askToAttend) {
                        success({ translate: EVENTS.ASK_TO_ATTEND_SUCCESS_MESSAGE });
                    }

                    setInitialRegistrationStatus(newRegistrationStatus);
                } catch (err) {
                    if (err instanceof BaseApiError) {
                        setRegistrationStatus(initialRegistrationStatus);
                        setCurrentAttendees(currentAttendees);
                        setCurrentAttendeesCount(currentAttendeesCount);

                        if (err.response?.status === 400) {
                            setCurrentRemainingSpots(0);
                        } else {
                            setCurrentRemainingSpots(remainingSpots);
                        }

                        error({ translate: getServiceServerError(err?.response) });
                    }
                }
            }
        },
        [
            eventId,
            isUserOrganizer,
            initialRegistrationStatus,
            remainingSpots,
            currentAttendees,
            connectedUser.id,
            currentAttendeesCount,
            spotCanBeChanged,
            currentRemainingSpots,
            connectedUserReference,
            hasLimitedCapacity,
            success,
            warning,
            error,
        ],
    );

    return {
        simpleRegisterToEvent,
        registerToEventWithGuests,
        currentAttendees,
        currentAttendeesCount,
        currentRemainingSpots,
        registrationStatus,
    };
};
