import React from 'react';

import { classnames } from '@lumapps/classnames';
import { padding } from '@lumapps/classnames/utils';
import { Popover, Placement } from '@lumapps/lumx/react';

import { SEGMENT_POPOVER_CLASSNAME } from '../../constants';
import { SegmentInformationSkeleton } from '../SegmentInformation/SegmentInformationSkeleton';

export interface SegmentDetailsPopoverSkeletonProps {
    isOpen: boolean;
    anchorRef: React.RefObject<HTMLButtonElement>;
}

export const SegmentDetailsPopoverSkeleton = ({ anchorRef, isOpen }: SegmentDetailsPopoverSkeletonProps) => {
    return (
        <Popover
            anchorRef={anchorRef}
            isOpen={isOpen}
            className={classnames(padding('all', 'big'), SEGMENT_POPOVER_CLASSNAME)}
            closeOnEscape
            closeOnClickAway
            placement={Placement.BOTTOM_START}
        >
            <SegmentInformationSkeleton />
        </Popover>
    );
};
