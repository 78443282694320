import type { LinkPreviewMetadata } from '@lumapps/lumx-preview/types';
import type { PathRef } from '@lumapps/wrex/slate';
import type { WrexEditor } from '@lumapps/wrex/types';

import type { LinkPreviewEditor, Resource } from '../types';

/** Used to update a link preview on link preview edition or replace */
export const handlePreviewChange = (
    editor: WrexEditor<LinkPreviewEditor>,
    pathRef: PathRef,
    link: Partial<LinkPreviewMetadata> & { resource?: Resource },
) => {
    editor.updateLinkPreview(
        {
            title: link.title && link.title !== link.resource?.title ? link.title : undefined,
            description:
                link.description && link.description !== link.resource?.description ? link.description : undefined,
            thumbnailUrl:
                link.images && link.images[link.thumbnailIndex || 0] !== link.resource?.thumbnailUrl
                    ? link.images[link.thumbnailIndex || 0]
                    : undefined,
            url: link.url || '',
            ...(link.resource ? { resource: link.resource } : undefined),
        },
        pathRef,
    );
};
