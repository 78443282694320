import type { ContentLinkRef } from '@lumapps/contents/types';
import type { ButtonProps } from '@lumapps/lumx/react';
import type { Post } from '@lumapps/posts/types';
import type { Route } from '@lumapps/router';
import type { Preview } from '@lumapps/share/types';
import type { User } from '@lumapps/user/types';

import * as types from '../../../types/blocks';

export enum BlockReactionVariants {
    /** BlockReactions in the context of a Post (default one). */
    post = 'POST',
    /** BlockReactions in the context of a Comment. */
    comment = 'COMMENT',
    /** BlockReactions in the context of a Content. */
    content = 'CONTENT',
}

export interface BlockReactionsProps extends types.BlockReactions {
    /** Class name forwarding */
    className?: string;
    /** Whether the current user can share the post. */
    canShare?: boolean;
    /** Whether the current user can share the post to a space, if the post is space compatible. (calculated by the frontend) */
    canShareToSpace?: boolean;
    /** The list of users who like the associated resource. */
    likesList?: {
        items: User[];
        isLoaded: boolean;
    };
    /** Whether the like/unlike request is running. */
    isLikeLoading?: boolean;
    /** Route to comments. */
    commentRoute?: Route;
    /** The callback function on the comment button. */
    onComment?: () => void;
    /** The callback function called when the user clicks on the reply button. */
    onReply?: () => void;
    /** The reference of the parent content used to redirect to when comment button is clicked. */
    parentContentRef?: ContentLinkRef;
    /** The url to redirect to when comment button is clicked. */
    commentUrl?: string;
    /** The share menu to show */
    share?: {
        type: types.BlockReactionsShareTypes;
        preview: Preview;
    };
    /** Options to change the reactions look. */
    displayOptions?: {
        variant?: BlockReactionVariants;
        buttonSize?: ButtonProps['size'];
        buttonColor?: ButtonProps['color'];
        buttonEmphasis?: ButtonProps['emphasis'];
        showButtonLabels?: boolean;
        buttonClassName?: string;
    };
    settings?: {
        displayLikeButton?: boolean;
        displayFollowButton?: boolean;
    };
    /** Whether comment count should be visible. */
    isCommentCountVisible?: boolean;
    /** In some list (ex: reports) buttons should be displayed only as preview and mustn't be clickable */
    isPreviewOnly?: boolean;
    /** Callback function called after sharing to a community to refresh the legacy community posts list */
    afterShareToCommunity?: (updatedPost: Post) => void;
}
