import { Wrex } from '@lumapps/wrex/types';

import { FileElement, WrexDriveMediaSource } from '../types';
import { isDriveFile } from './isDriveFile';

/**
 * Get the drive files from the slate content
 */
export const getDriveFilesFromSlate = (content?: Wrex.Nodes, userProvider?: WrexDriveMediaSource | undefined) => {
    if (!content || !userProvider) {
        return { driveFiles: undefined };
    }

    const driveFiles: FileElement[] = content.filter((node) => isDriveFile(node, userProvider));
    return { driveFiles: driveFiles.length ? driveFiles : undefined };
};
