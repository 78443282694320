import { useMemo } from 'react';

import { mdiFormatQuoteClose } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { ReactEditor, useSlate, useSlateStatic } from '@lumapps/wrex/slate';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { ToolbarItem } from '@lumapps/wrex/types';

import { QUOTE_BLOCK } from '../../../constants';
import { WREX_QUOTE } from '../../../keys';
import { QuoteEditor } from '../../../types';

export const useQuoteBlockButton = (otherProps?: any) => {
    const { translateKey } = useTranslate();
    const editor = useSlate() as ReactEditor & QuoteEditor;
    const editorStatic = useSlateStatic() as ReactEditor & QuoteEditor;

    const isSelected = editor.isQuoteActive();
    const isDisabled = !editor.isQuoteAllowed();

    return useMemo((): ToolbarItem => {
        return {
            itemKey: QUOTE_BLOCK,
            type: 'toggle-option',
            icon: mdiFormatQuoteClose,
            tooltipLabel: translateKey(WREX_QUOTE.QUOTE_BLOCK_BUTTON),
            'aria-disabled': isDisabled,
            isChecked: isSelected,
            onClick() {
                if (!editorStatic.selection) {
                    initSelection(editorStatic, 'block');
                }
                editorStatic.toggleQuote(editorStatic.selection);
            },
            otherProps,
            verticalModeProps: {
                label: translateKey(WREX_QUOTE.QUOTE_BLOCK_BUTTON),
                tooltipLabel: undefined,
            },
        };
    }, [translateKey, isDisabled, isSelected, otherProps, editorStatic]);
};
