export const GENERATED_SUBTITLE_ID_BASE = 'subtitle-item-';

export const GB = 'en-GB';
export const US = 'en-US';
export const PTBR = 'pt-BR';
export const ZHHK = 'zh-HK';
export const ZHTW = 'zh-TW';

/**
 * We need to store this list of languages that are properly handled by both GCP and Azure services.
 */
export const TRANSCRIPT_LANGUAGE_LIST = [
    'af',
    'am',
    'ar',
    'bn',
    'bg',
    'ca',
    'cs',
    'da',
    'de',
    'el',
    GB,
    US,
    'es',
    'et',
    'fi',
    'fr',
    'gu',
    'he',
    'hi',
    'hr',
    'hu',
    'id',
    'is',
    'it',
    'ja',
    'jv',
    'km',
    'kn',
    'ko',
    'lo',
    'lt',
    'lv',
    'mk',
    'mr',
    'ms',
    'my',
    'nl',
    'no',
    'pl',
    'ps',
    'pt',
    PTBR,
    'ro',
    'ru',
    'si',
    'sk',
    'sl',
    'sr',
    'sv',
    'ta',
    'te',
    'th',
    'tr',
    'uk',
    'ur',
    'uz',
    'vi',
    'zh',
    ZHHK,
    ZHTW,
];

export const TOP_LANGUAGES = [GB, US, 'fr', 'de', 'ja', 'es'];

// Used to differentiate the auto-transcript from the other subtitles
/** @deprecated */
export const TRANSCRIPT_LANGUAGE_VALUE = 'cc';

export const UPLOADER_ACCEPT_SUBTITLES_TYPES = '.vtt, .srt';

export const SUBTITLE_GENERATION_MODE = {
    MANUAL: 'manual',
    SPEECH_TO_TEXT: 'speech_to_text',
    AI_TRANSLATION: 'ai_translation',
} as const;

export const SUBTITLE_STATUS = {
    LIVE: 'live',
    WAITING: 'waiting',
    PROCESSING: 'processing',
    ERROR: 'error',
    EMPTY: 'empty',
    DELETED: 'deleted',
} as const;

export const SUBTITLE_PREVIEW_STATUS = {
    NEW: 'new',
    EDITED: 'edited',
} as const;
