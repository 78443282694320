import curry from 'lodash/fp/curry';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { StaticNavigationItem } from '@lumapps/community-navigation/types';
import { SPACE_SERVICES_ALT_ICONS, SPACE_SERVICES_RECORD } from '@lumapps/spaces/constants';
import { spaceView } from '@lumapps/spaces/routes';
import { SpaceServices, SpaceServicesProviders } from '@lumapps/spaces/types';

/**
 * Filters a list of services and transforms them to static navigation items
 *
 * @param {string} spaceId
 * @param {string} spaceSlug
 * @param {ItemVariant} variant
 * @param {SpaceServicesProviders} providers
 * @param {SpaceServices[]} services
 */
export const servicesToNavigationItems = curry(
    (
        spaceId: string,
        spaceSlug: string,
        providers: SpaceServicesProviders,
        services: SpaceServices[],
    ): StaticNavigationItem[] =>
        map((service: SpaceServices) => {
            const record = get([service], SPACE_SERVICES_RECORD);

            const icon: string | undefined = get([service, get([service], providers) ?? ''], SPACE_SERVICES_ALT_ICONS);

            return {
                ...record,
                ...(icon ? { icon } : {}),
                to: spaceView({ params: { id: spaceId, slug: spaceSlug, view: record.view } }),
            };
        }, services),
);
