import { ServerListResponse } from '@lumapps/base-api';
import type { Event } from '@lumapps/events/types';
import { createUrl } from '@lumapps/router';
import { spaceView } from '@lumapps/spaces/routes';
import { SpaceViews, SpaceViewParams } from '@lumapps/spaces/types';

import type { ListEventParams } from '../types';

export const mapLumappsEvents = (
    lumappsEventsFetchParams: ListEventParams,
    lumappsEventsResults: ServerListResponse<Event>,
) => ({
    callId: lumappsEventsFetchParams?.calendarId,
    cursor: lumappsEventsResults?.cursor,
    items: lumappsEventsResults?.items.map((item) => {
        return {
            attendees: [],
            calendarId: lumappsEventsFetchParams?.calendarId,
            description: item?.structuredContent?.intro?.value,
            end: item?.endsAt,
            htmlLink: createUrl(
                spaceView({
                    params: {
                        id: item?.communityReference?.id,
                        slug: item?.communityReference?.slug?.value,
                        view: SpaceViews.event,
                        identifier: item.id,
                    } as SpaceViewParams,
                }),
            ),
            id: item?.id,
            location: item?.location,
            start: item?.startsAt,
            summary: item?.structuredContent?.title?.value,
        };
    }),
    more: lumappsEventsResults?.more,
});
