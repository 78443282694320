import React, { useEffect } from 'react';

import { inputLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { ThemeProvider } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { STRUCTURED_CONTENT_FORMAT } from '@lumapps/structured-content/types';
import { TranslateObject } from '@lumapps/translations';
import { BackendRecomputedWidget } from '@lumapps/widget-base/components/BackendRecomputedWidget';
import { WidgetContentProps } from '@lumapps/widget-base/components/WidgetContent';
import { LegacyWidget } from '@lumapps/widget-base/types';
import { Topic } from '@lumapps/wrex/serialization/dita/types';
import type { Document } from '@lumapps/wrex/types/core';

import { useEditableWidgetContribution } from '../../hooks/useEditableWidgetContribution';
import { LegacyWidgetContributionProps } from '../../type';
import { EditableWidgetContribution, EditableWidgetContributionProps } from '../EditableWidgetContribution';

import './index.scss';

export interface WidgetContributionWrapperProps extends Partial<WidgetContentProps> {
    /** The Legacy widget to be rendered. */
    legacyWidget: LegacyWidget<LegacyWidgetContributionProps>;
    /** The callBack to switch to reading mode on click outside the editor (legacy) */
    switchToReadMode: () => void;
    /** The callBack to save the content in widget properties (legacy) */
    saveTextualContentInWidgetProperties: (
        textualContent?: TranslateObject<Topic | Document | undefined>,
        format?: STRUCTURED_CONTENT_FORMAT,
    ) => void;
    /** the status of the widget: edition or reading mode */
    editingContent?: boolean;
    /** whether the widget is empty or not (no dita or richText) */
    isWidgetEmpty?: () => boolean;
}

export interface EditableWidgetBlockProperties {
    slate?: Node[];
}

export const WidgetContributionWrapper: React.FC<WidgetContributionWrapperProps> = ({
    legacyWidget: initLegacyWidget,
    switchToReadMode,
    saveTextualContentInWidgetProperties,
    editingContent,
    isWidgetEmpty,
    ...props
}: WidgetContributionWrapperProps) => {
    /**
     * Since <react-element> don't watch the object entierly, but watch it's 'properties' propertie
     * (to avoid rerendering and make first double click to edit work), we need to destructure the object
     * (since only the 'properties' propertie is watched).
     */
    const legacyWidget = { ...initLegacyWidget };

    const currentInputLang = useSelector(inputLanguageSelector);
    const { wrapperRef, childrenRefs, exitEditionMode, onFileUpload, getInitialContent } =
        useEditableWidgetContribution({
            legacyWidget,
            switchToReadMode,
            saveTextualContentInWidgetProperties,
        });

    const [content, onChange] = React.useState(() => getInitialContent(currentInputLang));

    /** manage language switch */
    useEffect(() => {
        onChange(getInitialContent(currentInputLang));
    }, [currentInputLang, getInitialContent]);

    const theme = legacyWidget?.properties?.style?.content?.theme;

    return (
        <ThemeProvider value={theme}>
            <BackendRecomputedWidget<
                LegacyWidgetContributionProps,
                EditableWidgetBlockProperties,
                EditableWidgetContributionProps
            >
                // Destructuring the object, because <react-element> don't watch it
                // entirely, but watch it's 'properties' (to avoid re-rendering)
                legacyWidget={{ ...legacyWidget }}
                editableWidgetRenderer={EditableWidgetContribution}
                editableWidgetProps={{
                    legacyWidget,
                    wrapperRef,
                    childrenRefs,
                    exitEditionMode,
                    onFileUpload,
                    content,
                    onChange,
                }}
                isWidgetEmpty={isWidgetEmpty}
                blockProperties={{ slate: content }}
                editingContent={editingContent}
                {...props}
            />
        </ThemeProvider>
    );
};
