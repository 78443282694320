import React from 'react';

import type { Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { UserPickerField } from '@lumapps/user-pickers/components/UserPickerField';
import { useUser } from '@lumapps/user/hooks/useUser';
import { User } from '@lumapps/user/types';

import { FormattedAuthorFilter } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';
import { SkeletonFilter } from '../SkeletonFilter';

interface AuthorContentFilterProps {
    onChange: (author?: FormattedAuthorFilter) => void;
    selectedValue?: FormattedAuthorFilter;
    theme: Theme;
    scope: string;
    hideSubheader: boolean;
}

export const AuthorContentFilter = ({
    selectedValue,
    onChange,
    theme,
    scope,
    hideSubheader,
}: AuthorContentFilterProps) => {
    const { translateKey } = useTranslate();

    const { fullName, firstName, lastName, email } = selectedValue || {};
    const incompleteUser = !fullName && !firstName && !lastName && !!email;

    const {
        isFetching,
        isError,
        data: author,
    } = useUser(
        { fullName, firstName, lastName, email },
        {
            enabled: incompleteUser,
        },
    );

    React.useEffect(() => {
        if (!incompleteUser || isFetching) {
            return;
        }

        if (!author || isError) {
            onChange({ fullName: translateKey(GLOBAL.UNKNOWN_USER), email } as FormattedAuthorFilter);
        } else {
            onChange(author as FormattedAuthorFilter);
        }
    }, [incompleteUser, isFetching, isError, author, onChange, email, translateKey]);

    /**
     * If incomplete author information, we are fetching them,
     * end update the author filter content with the result.
     * */
    if (incompleteUser && isFetching) {
        return <SkeletonFilter />;
    }

    return (
        <>
            {!hideSubheader && <FilterSubheader label={translateKey(GLOBAL.AUTHOR)} theme={theme} />}
            <UserPickerField
                selectionType="single"
                icon={null}
                value={selectedValue as User}
                onChange={onChange}
                label={translateKey(GLOBAL.AUTHOR)}
                theme={theme}
                scope={scope}
            />
        </>
    );
};
