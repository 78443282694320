import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { GenericProps, SkeletonTypography, Typography } from '@lumapps/lumx/react';

import { CommentSkeleton } from '../CommentSkeleton';
import { CommentsSectionTitle } from '../CommentsSectionTitle';

export const CLASSNAME = 'comment-list-skeleton';
export const DEFAULT_SKELETONS_NUMBER = 3;

export interface CommentListSkeletonProps extends GenericProps {
    /**
     * The title to display.
     * If undefined, will show a skeleton instead.
     */
    title?: string;
    /** The number of comment skeletons to display. */
    commentNumber?: number | null;
    /** Whether to show a skeleton for the "display more" button. */
    showLoadMoreButton?: boolean;
    /** Whether to show a skeleton for the title. */
    showTitle?: boolean;
}

export const CommentListSkeleton: React.FC<CommentListSkeletonProps> = ({
    commentNumber = DEFAULT_SKELETONS_NUMBER,
    title,
    showLoadMoreButton = true,
    showTitle = true,
    className,
    ...forwardedProps
}) => {
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <div className={block([className])} {...forwardedProps}>
            {showTitle &&
                (title ? (
                    <CommentsSectionTitle>{title}</CommentsSectionTitle>
                ) : (
                    <SkeletonTypography
                        className={element('title', [margin('vertical', 'big')])}
                        typography={Typography.subtitle2}
                        style={{ maxWidth: '152px', width: '100%' }}
                    />
                ))}

            {new Array(commentNumber).fill(null).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <CommentSkeleton key={index} className={margin('bottom', 'big')} />
            ))}

            {showLoadMoreButton && (
                <SkeletonTypography
                    className={element('button')}
                    typography={Typography.subtitle2}
                    style={{ maxWidth: '152px', width: '100%' }}
                />
            )}
        </div>
    );
};
