/* eslint-disable no-use-before-define */
/* istanbul ignore file */

import { FocusPoint } from '@lumapps/lumx/react';
import { AttachmentElement } from '@lumapps/wrex-attachments/types';
import { FileAttributes } from '@lumapps/wrex-file/types';
import { IMAGE_GALLERY_VIEW_MODE } from '@lumapps/wrex-image-gallery/constants';

import type { Wrex } from '../../types';

export type DITA = Topic;
export type Node = Text | Element;

export interface Text {
    type: 'text';
    value: string;
}

export const isText = (node: any): node is Text => node && node.type === 'text' && typeof node.value === 'string';

export interface Element {
    type: 'element';
    name: string;
    attributes?: Record<string, any>;
    children: Node[];
    outputclass?: string;
}
export const isElement = (node: any): node is Element => node && node.type === 'element' && node.children;

export interface Topic extends Element {
    name: 'topic';
    attributes: {
        id: string;
    };
    children: Element[];
}

export interface Title extends Element {
    name: 'title';
    children: Text[];
}

export interface ShortDesc extends Element {
    name: 'shortdesc';
    children: Text[];
}

export interface Prolog extends Element {
    name: 'prolog';
    children: Element[];
}

export interface Data extends Element {
    name: 'data';
    children: Data[];
}

export interface DataAbout extends Element {
    name: 'data-about';
    children: Data[];
}

export interface FeaturedImage extends DataAbout {
    name: 'data-about';
    attributes: {
        type: 'lumapps:featured-image';
        href: string;
        keyref: string;
    };
}

export interface Alt extends Element {
    name: 'alt';
    children: Text[];
}
export interface Image extends Element {
    name: 'image';
    attributes: {
        href: string;
        keyref: string;
        /**
         * Image height in pixel
         * @pattern ^\d+px$
         */
        height?: string;
        /**
         * Image width in pixel
         * @pattern ^\d+px$
         */
        width?: string;
    };
    children: [Alt];
}

export interface FocalPointDetails extends Data {
    attributes: {
        name: 'focalPoint:x' | 'focalPoint:y';
        value: number | string;
    };
    children: [];
}
export interface FocalPoint extends Data {
    attributes: {
        name: 'focalPoint';
    };
    children: FocalPointDetails[];
}

export interface Media extends DataAbout {
    attributes: {
        type: 'lumapps:media';
        keyref: string;
    };
    children: [FocalPoint];
}

export enum FigAlignClass {
    alignCenter = 'align-center',
    alignFull = 'align-full',
}

export interface ImageLink extends Element {
    name: 'xref';
    attributes: {
        scope?: 'external';
        type: 'fig';
        href: string;
    };
}

export interface Fig extends Element {
    name: 'fig';
    children: [Title, ...(Image | Media | ImageLink)[]];
    attributes: {
        /**
         * Image selected alignment
         */
        outputclass?: FigAlignClass | IMAGE_GALLERY_VIEW_MODE;
    };
}

export interface Body extends Element {
    name: 'body';
}

export interface Link extends Element {
    name: 'xref';
    attributes: {
        scope?: 'external';
        href: string;
    };
}

export interface Mention extends Element {
    name: 'xref';
    attributes: {
        scope: 'local';
        type: 'lumapps:tag' | 'lumapps:user';
        keyref: string;
    };
}

export interface FeaturedImageMedia {
    id?: string;
    url: string;
    focalPoint?: FocusPoint;
    alt?: string;
}

export interface SlateStructuredContent {
    title: string;
    description?: string;
    featuredImage?: FeaturedImageMedia;
    children: Wrex.Nodes;
    attachments?: any;
}

export interface Name extends Data {
    attributes: {
        name: 'name';
        value: string;
    };
}

export interface CreatedAt extends Data {
    attributes: {
        name: 'createdAt';
        value: string;
    };
}

export interface EditedAt extends Data {
    attributes: {
        name: 'editedAt';
        value: string;
    };
}

export interface MimeType extends Data {
    attributes: {
        name: 'mimeType';
        value: string;
    };
}

export interface FileProvider extends Data {
    name: 'data';
    attributes: {
        name: 'provider';
        value: 'local' | 'google_drive' | 'one_drive';
    };
}

export interface FileStatus extends Data {
    name: 'data';
    attributes: {
        name: 'status';
        value: FileAttributes['status'];
    };
}

export interface DriveId extends Data {
    name: 'data';
    attributes: {
        name: 'driveId';
        value: string;
    };
}

export interface File extends DataAbout {
    attributes: {
        scope: 'local';
        type: 'lumapps:file';
        keyref: string;
        href: string;
    };
    children: [Name, MimeType, CreatedAt];
}

export interface DriveFile extends Omit<File, 'children'> {
    children: [Name, MimeType, CreatedAt, DriveId, FileProvider, FileStatus, EditedAt];
}
export interface OgType extends Data {
    attributes: {
        name: 'og:type';
        value: 'website' | 'video.other';
    };
}
export interface LinkPreviewOgType extends OgType {
    attributes: {
        name: 'og:type';
        value: 'website';
    };
}
export interface OgTitle extends Data {
    attributes: {
        name: 'og:title';
        value: string;
    };
}

export interface OgDescription extends Data {
    attributes: {
        name: 'og:description';
        value: string;
    };
}
export interface OgImage extends Data {
    attributes: {
        name: 'og:image';
        value: string;
    };
}
export interface LinkPreviewType extends Element {
    name: 'data-about';
    attributes: {
        scope: 'external';
        href: string;
        type: 'lumapps:link-preview';
    };
    children: [LinkPreviewOgType, OgTitle, OgDescription, OgImage];
}

export interface EmbeddedType extends Element {
    name: 'data-about';
    attributes: {
        scope: 'external';
        href: string;
        type: 'lumapps:embedded';
    };
    children: [OgType, OgTitle, OgDescription, OgImage];
}

/** BEGIN Play */
export interface PlayVideoId extends Element {
    name: 'data';
    attributes: {
        name: 'lumapps:id';
        value: string;
    };
}

export interface PlayVideoTitle extends Element {
    name: 'data';
    attributes: {
        name: 'og:title';
        value: string;
    };
}

export interface PlayVideoURL extends Element {
    name: 'data';
    attributes: {
        name: 'og:url';
        value: string;
    };
}

export interface PlayVideoType extends Element {
    name: 'data-about';
    attributes: {
        type: 'lumapps:play';
    };
    children: [PlayVideoId, PlayVideoTitle, PlayVideoURL];
}
/** END Play */

export const ATTACHMENTS_OUTPUTCLASS = 'lumapps:attachments';

export interface AttachmentsTopic extends Element {
    name: 'topic';
    attributes: {
        id: string;
        outputclass: typeof ATTACHMENTS_OUTPUTCLASS;
    };
    children: [Title, Body & { children: Array<Element> }];
}

export const isAttachmentsTopic = (node: any): node is AttachmentsTopic => {
    return isElement(node) && node.name === 'topic' && node.attributes?.outputclass === ATTACHMENTS_OUTPUTCLASS;
};

export interface WrexComment {
    children: Wrex.Nodes;
    attachments?: AttachmentElement;
}
