import first from 'lodash/fp/first';
import pick from 'lodash/fp/pick';
import property from 'lodash/fp/property';

import { User } from '@lumapps/user/types';

export const fillAuthorFilterValuesMissingData = (values?: Partial<User>[], missingAuthorData?: User): User[] => {
    const value = first(values);

    if (!value) {
        return [] as User[];
    }

    const { email, firstName, lastName } = pick(['email', 'firstName', 'lastName'], value);

    if (email && !(firstName && lastName) && email === property('email', missingAuthorData)) {
        return [
            {
                email,
                ...pick(['firstName', 'lastName'], missingAuthorData),
            },
        ] as User[];
    }

    return values as User[];
};
