import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiClose, mdiPencil } from '@lumapps/lumx/icons';
import { Emphasis, FlexBox, IconButton, type IconButtonProps, Orientation, Size } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import './index.scss';

export interface AttachmentActionButtonsProps {
    /** callback to be triggered when clicking on remove button */
    onRemove?: (event: any) => void;
    removeButtonProps?: Partial<IconButtonProps>;
    /** callback to be triggered when clicking on edit button */
    onEdit?: () => void;
    editButtonProps?: Partial<IconButtonProps>;
}

const CLASSNAME = 'attachment-action-buttons';

/**
 * Available actions for an attachment
 * @param AttachmentActionButtonsProps
 * @returns AttachmentActionButtons
 */
export const AttachmentActionButtons: React.FC<AttachmentActionButtonsProps> = ({
    onRemove,
    onEdit,
    editButtonProps,
    removeButtonProps,
}) => {
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <FlexBox orientation={Orientation.horizontal} gap={Size.regular} className={block()}>
            {onEdit && (
                <IconButton
                    emphasis={Emphasis.low}
                    hasBackground
                    icon={mdiPencil}
                    label={translateKey(GLOBAL.EDIT)}
                    {...editButtonProps}
                    className={element('edit-action', [editButtonProps?.className])}
                    onClick={onEdit}
                />
            )}

            {onRemove && (
                <IconButton
                    icon={mdiClose}
                    emphasis={Emphasis.low}
                    hasBackground
                    label={translateKey(GLOBAL.DELETE)}
                    {...removeButtonProps}
                    className={element('remove-action', [removeButtonProps?.className])}
                    onClick={onRemove}
                />
            )}
        </FlexBox>
    );
};
