import type { User } from '@lumapps/user/types';

import type { AuthorDetails } from '../types';

export const getUserAuthorDetails = ({
    id,
    customer,
    lastName,
    firstName,
    apiProfile,
    email,
    profilePictureUrl,
}: User): AuthorDetails => ({
    id,
    customer,
    lastName,
    firstName,
    apiProfile,
    email,
    profilePictureUrl,
});
