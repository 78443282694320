import React from 'react';

import { platformConnectorsRoute } from '@lumapps/connectors/routes';
import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiPuzzle } from '@lumapps/lumx/icons';
import { marketplaceLibraryRoute } from '@lumapps/marketplace-library/routes';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { MARKETPLACE } from '../../keys';
import { platformExtensionsRoute } from '../../routes';

export interface PlatformExtensionsSideNavigationProps {
    isAllowed: boolean;
}

const PlatformExtensionsSideNavigation = ({ isAllowed }: PlatformExtensionsSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(MARKETPLACE.PLATFORM_EXTENSIONS)} icon={mdiPuzzle} id="mp">
            <SideNavigationItem label={translateKey(GLOBAL.LIBRARY)} route={marketplaceLibraryRoute} />;
            <SideNavigationItem
                label={translateKey(MARKETPLACE.INSTALLED_EXTENSIONS)}
                route={platformExtensionsRoute}
            />
            <SideNavigationItem
                label={translateKey(GLOBAL.CONNECTORS)}
                id="connectors"
                route={platformConnectorsRoute}
            />
        </SideNavigationItem>
    );
};

PlatformExtensionsSideNavigation.displayName = 'SideNavigationItem';

export { PlatformExtensionsSideNavigation };
