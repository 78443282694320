import React from 'react';

import { classnames } from '@lumapps/classnames';
import { Button, Dialog, Emphasis, Heading, Toolbar } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { PLAY_LIBRARY } from '../../keys';
import { VideoUploader } from '../VideoUploader';
import './index.scss';

export interface UploadCorporateVideoDialogProps {
    /** Is the dialog open ? */
    isOpen: boolean;
    /** Custom className */
    className?: string;
    /** Function called when cancelling the upload */
    onCancel: () => void;
    onDrop: (video: File) => void;
    /** Translation key for an error in create video API call */
    createVideoError?: string;
}

const CLASSNAME = 'upload-video-dialog';

export const UploadCorporateVideoDialog = ({
    className,
    isOpen,
    onCancel,
    onDrop,
    createVideoError,
}: UploadCorporateVideoDialogProps) => {
    const { translateKey } = useTranslate();

    return (
        <Dialog className={classnames(CLASSNAME, className)} isOpen={isOpen} onClose={onCancel}>
            <header>
                <Toolbar
                    label={
                        <Heading as="h2" typography="title">
                            {translateKey(PLAY_LIBRARY.UPLOAD_DIALOG_TITLE)}
                        </Heading>
                    }
                />
            </header>

            <VideoUploader onUpload={onDrop} createVideoError={createVideoError} />

            <footer>
                <Toolbar
                    after={
                        <Button emphasis={Emphasis.medium} onClick={onCancel}>
                            {translateKey(GLOBAL.CANCEL)}
                        </Button>
                    }
                />
            </footer>
        </Dialog>
    );
};
