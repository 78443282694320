import React from 'react';

import { margin } from '@lumapps/classnames';
import { DirectoryEntryCreationDialog } from '@lumapps/directories-entries/components/DirectoryEntryCreationDialog';
import { mdiPlus } from '@lumapps/lumx/icons';
import { Button, Emphasis } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BlockComponent } from '@lumapps/widget-base/types';

import { useSaveUserDirectoryEntry } from '../../hooks/useSaveUserDirectoryEntry';
import { BlockAddDirectoryUserEntry as BlockAddDirectoryUserEntryProps } from '../../types';

export const BlockAddDirectoryUserEntry: BlockComponent<BlockAddDirectoryUserEntryProps> = ({
    directoryId,
    widget,
}) => {
    const { translateKey } = useTranslate();

    const { openSaveDialog, creationDialogProps } = useSaveUserDirectoryEntry(widget, directoryId);

    return (
        <>
            <Button
                className={margin('bottom', 'big')}
                fullWidth
                onClick={() => {
                    openSaveDialog();
                }}
                leftIcon={mdiPlus}
                emphasis={Emphasis.medium}
            >
                {translateKey(GLOBAL.ADD)}
            </Button>
            <DirectoryEntryCreationDialog {...creationDialogProps} />
        </>
    );
};
