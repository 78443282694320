import React from 'react';

import { classnames, color, margin, useClassnames } from '@lumapps/classnames';
import {
    AspectRatio,
    ColorPalette,
    ColorVariant,
    GenericBlock,
    Link,
    Orientation,
    Size,
    Text,
    Theme,
    Thumbnail,
    ThumbnailVariant,
    Typography,
} from '@lumapps/lumx/react';
import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { InlineReactions } from '@lumapps/reactions-core/components/InlineReactions';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { connectedUserSelector } from '@lumapps/user/ducks/selectors';
import { User } from '@lumapps/user/types';
import { isDateInThePast } from '@lumapps/utils/date/isDateInThePast';

import { Event, LayoutEvent, RegistrationStatusTypes } from '../../api/types';
import { useEventPreview } from '../../hooks/useEventPreview';
import { useRegisterToEvent } from '../../hooks/useRegisterToEvent';
import { getIsUserOrganizer } from '../../utils';
import { RegistrationButton } from '../RegistrationButton';

import './index.scss';

export interface EventPreviewProps {
    theme?: Theme;
    event: Event | LayoutEvent;
    isLegacy?: boolean;
    shouldDisplayAttendButton?: boolean;
}

const CLASSNAME = 'event-preview';

export const EventPreview: React.FC<EventPreviewProps> = ({
    theme = Theme.light,
    event,
    isLegacy,
    shouldDisplayAttendButton = true,
}) => {
    const {
        id,
        eventRoute,
        image,
        title,
        startDate,
        endDate,
        intro,
        organizers,
        currentRegistrationStatus,
        remainingSpots,
        hasLimitedCapacity,
        hasRegistrationEnabled,
    } = useEventPreview(event, isLegacy);
    const { id: currentUserId } = useSelector(connectedUserSelector) as User;
    const isUserOrganizer = getIsUserOrganizer(organizers, currentUserId);

    const { simpleRegisterToEvent, registrationStatus, currentRemainingSpots } = useRegisterToEvent({
        eventId: id,
        initialRegistrationStatus: currentRegistrationStatus,
        remainingSpots,
        isUserOrganizer,
        hasLimitedCapacity,
    });
    const { element } = useClassnames(CLASSNAME);

    return (
        <GenericBlock
            as="article"
            theme={theme}
            orientation={Orientation.vertical}
            className={color(
                'background',
                theme === Theme.light ? ColorPalette.dark : ColorPalette.light,
                ColorVariant.L6,
            )}
        >
            <GenericBlock.Figure>
                {image ? (
                    <Thumbnail
                        image={makeSecuredMediaURLRelative(image?.url) as string}
                        alt={image?.alt || ''}
                        aspectRatio={AspectRatio.wide}
                        variant={ThumbnailVariant.squared}
                        linkAs={RouterLink}
                        linkProps={{ to: eventRoute, tabIndex: -1, target: '_blank' } as any}
                        className={element('image-block')}
                    />
                ) : undefined}
            </GenericBlock.Figure>

            <GenericBlock.Content
                gap={Size.tiny}
                className={classnames(margin('horizontal', Size.big), margin('bottom', Size.big), {
                    [margin('top', Size.big)]: !image,
                })}
            >
                <Text
                    as="span"
                    typography={Typography.body2}
                    truncate
                    color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
                    colorVariant={ColorVariant.N}
                >
                    {startDate.formatedStartsAt} &#8226; {startDate.formatedTimezone}
                </Text>

                <Link
                    linkAs={RouterLink}
                    target="_blank"
                    to={eventRoute}
                    className={element('link-article')}
                    color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
                    colorVariant={ColorVariant.N}
                    typography={Typography.subtitle2}
                >
                    <Text as="span">{title || ''}</Text>
                </Link>

                {!isDateInThePast(endDate) && shouldDisplayAttendButton && hasRegistrationEnabled && (
                    <RegistrationButton
                        registerToEvent={
                            simpleRegisterToEvent as (newRegistrationStatus: RegistrationStatusTypes) => Promise<void>
                        }
                        registrationStatus={registrationStatus}
                        theme={theme}
                        remainingSpots={currentRemainingSpots}
                        isUserOrganizer={isUserOrganizer}
                        hasLimitedCapacity={hasLimitedCapacity}
                        size={Size.m}
                        className={element('button-registration')}
                    />
                )}

                <Text
                    as="span"
                    typography={Typography.body1}
                    truncate
                    color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
                    colorVariant={ColorVariant.N}
                >
                    {intro || ''}
                </Text>

                {event?.reactionCounts && event.commentCount !== undefined && (
                    <InlineReactions likes={event.reactionCounts?.total} comments={event.commentCount} theme={theme} />
                )}
            </GenericBlock.Content>
        </GenericBlock>
    );
};

EventPreview.displayName = 'EventPreview';
