import React from 'react';

import { fetchLikeUserList, fetchToggleLike } from '@lumapps/reactions-core/api/v2';
import { ReactionResource } from '@lumapps/reactions-core/api/v2/types';

import { GenericLikeButton, GenericLikeButtonProps } from '../GenericLikeButton';

export interface LikeButtonProps
    extends Omit<GenericLikeButtonProps, 'resourceRef' | 'fetchToggleLike' | 'fetchLikeUserList'>,
        ReactionResource {}

/** Like button for v2 resources (articles, events, etc.) */
export const LikeButton = React.forwardRef<HTMLButtonElement, LikeButtonProps>((props, ref) => {
    const { resourceType, resourceId, ...forwardedProps } = props;

    return (
        <GenericLikeButton
            ref={ref}
            resourceRef={{ resourceType, resourceId }}
            fetchToggleLike={fetchToggleLike}
            fetchLikeUserList={fetchLikeUserList}
            {...forwardedProps}
        />
    );
});
LikeButton.displayName = 'LikeButton';
