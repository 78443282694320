import { mdiCheckboxMarkedCircleOutline, mdiLinkVariant, mdiLocationEnter, mdiReplay } from '@lumapps/lumx/icons';

import { EVENTS } from './keys';

export const EVENT_ATTENDEES_LIMITATION_RADIO_VALUES = {
    UNLIMITED_ATTENDEES: 'unlimited-attendees',
    LIMITED_ATTENDEES: 'limited-attendees',
    NO_ATTENDEES: 'no-attendees',
    REGISTRATION_ATTENDEES: 'registration-attendees',
} as const;

export enum RegistrationType {
    auto = 'auto',
    request = 'request',
    none = 'none',
}

export const EVENT_ATTENDEES_RADIO_TO_REGISTRATION_TYPE = {
    [EVENT_ATTENDEES_LIMITATION_RADIO_VALUES.UNLIMITED_ATTENDEES]: RegistrationType.auto,
    [EVENT_ATTENDEES_LIMITATION_RADIO_VALUES.LIMITED_ATTENDEES]: RegistrationType.auto,
    [EVENT_ATTENDEES_LIMITATION_RADIO_VALUES.NO_ATTENDEES]: RegistrationType.none,
    [EVENT_ATTENDEES_LIMITATION_RADIO_VALUES.REGISTRATION_ATTENDEES]: RegistrationType.request,
} as const;

/** List of the field names of an event edition (used on event dialog fields) */
export const EVENT_EDITION_FIELD_NAMES = {
    ATTENDEES_LIMITATION: 'attendees-limitation',
    CAPACITY: 'capacity',
    REGISTRATION_CAPACITY: 'registration-capacity',
    EXTERNAL_LINK: 'external-link',
    EXTERNAL_LINK_LABEL: 'external-link-label',
    HIDE_ATTENDEES_LIST: 'hide-attendees-list',
    LOCATION: 'location',
    DURATION_HOURS: 'duration-hours',
    DURATION_MINUTES: 'duration-minutes',
    REGISTRATION_TYPE: 'registration-type',
} as const;

export const ORIGINAL_LINK = 'original-link';
export const durationMinutesOptions = ['0', ' 5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
export const linkLabelOptions = [
    {
        value: ORIGINAL_LINK,
        label: EVENTS.ORIGINAL_LINK_LABEL,
        icon: mdiLinkVariant,
    },
    {
        value: 'registration',
        label: EVENTS.REGISTRATION_LABEL,
        icon: mdiCheckboxMarkedCircleOutline,
    },
    {
        value: 'videoconference',
        label: EVENTS.VIDEO_CONFERENCE_LABEL,
        icon: mdiLocationEnter,
    },
    {
        value: 'recording',
        label: EVENTS.RECORDING_LABEL,
        icon: mdiReplay,
    },
];

export const TRAINING_RESOURCE_TYPE = 'training';

export enum EVENTS_TEMPORALITY {
    TEMPORALITY_PAST = 'temporality_past',
    TEMPORALITY_ONGOING = 'temporality_ongoing',
    TEMPORALITY_FUTURE = 'temporality_future',
    TEMPORALITY_ALL = 'temporality_all',
}
