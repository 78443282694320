import React from 'react';

import concat from 'lodash/fp/concat';
import identity from 'lodash/fp/identity';
import includes from 'lodash/fp/includes';
import isEqual from 'lodash/fp/isEqual';
import remove from 'lodash/fp/remove';
import sortBy from 'lodash/fp/sortBy';

import { CheckboxFilter } from '@lumapps/lumx-filter-and-sort/components/CheckboxFilter';
import { PostType } from '@lumapps/posts/types';

import { PostListFilterBlockProps, PostListFilterChoice } from '../../../types';

export const PostTypeFilterBlock: React.FC<PostListFilterBlockProps<PostType>> = ({
    choices = [],
    selectedValues = [],
    onSelect,
    getChoiceName,
    filterId,
    label,
}) => {
    const handleChange = (choice: PostListFilterChoice<PostType>) => {
        const wasChecked = includes(choice.value, selectedValues);
        const nextSelectedValues = sortBy(
            identity,
            wasChecked ? remove(isEqual(choice.value), selectedValues) : concat(choice.value, selectedValues),
        );

        onSelect(filterId, nextSelectedValues);
    };

    const isInSelection = (choice: PostListFilterChoice<PostType>) => {
        includes(choice.value, selectedValues);
        return includes(choice.value, selectedValues);
    };

    return (
        <CheckboxFilter
            title={label}
            onSelected={handleChange}
            isInSelection={isInSelection}
            options={choices}
            renderCheckbox={(choice) => getChoiceName(choice.value)}
            getId={(choice) => choice.value}
            id={filterId}
        />
    );
};
