import React from 'react';

import isEqual from 'lodash/isEqual';
import uniqueId from 'lodash/uniqueId';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import {
    LeftElementProps,
    ResourceOrderingDialog,
    RightElementProps,
} from '@lumapps/lumx-dialogs/components/ResourceOrderingDialog';
import { Image } from '@lumapps/lumx-images/types';
import { mdiImageMultipleOutline, mdiPlus } from '@lumapps/lumx/icons';
import { AspectRatio, Emphasis, Kind, MessageProps, Thumbnail } from '@lumapps/lumx/react';
import { MEDIAS } from '@lumapps/medias/keys';
import { useResponsive, useMediaQuery } from '@lumapps/responsive';
import { useTranslate } from '@lumapps/translations';
import { useFilePicker } from '@lumapps/utils/hooks/useFilePicker';

import { WREX_IMAGE_GALLERY } from '../../keys';
import { formatFilesToImageStructure } from '../../utils/formatFilesToImageStructure';
import { ImageDraggableGrid } from '../ImageDraggableGrid';
import { ImageDetails } from './ImageDetails';
import { ImageWithKey } from './type';

import './index.scss';

export interface ImageGalleryDialogProps {
    /** Whether the dialog is open */
    isOpen?: boolean;
    /** The images picked / already picked */
    images: Image[];
    /** The maximum number of images allowed in the image gallery */
    maxImagesNumber?: number;
    /** Whether the dialog will provide a new image gallery or an edited one */
    isNewImageGallery?: boolean;
    /** Callback when closing the dialog */
    onClose: () => void;
    /** Callback when saving the dialog */
    onSave: (images: Image[]) => Promise<void>;
    /** Whether we open dialog in edition mode */
    isEdit?: boolean;
}

const CLASSNAME = 'image-gallery-dialog';

/**
 * Component that displays a dialog to make an image gallery.
 * The dialog allow you to change image order, replace or delete specific images and add an alt text for each one of them.
 *
 * @param ImageGalleryDialogProps
 * @returns ImageGalleryDialog
 */
export const ImageGalleryDialog: React.FC<ImageGalleryDialogProps> = ({
    isOpen,
    images: initImages,
    maxImagesNumber = 15,
    isNewImageGallery,
    onSave,
    onClose,
    isEdit,
}) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes('image-gallery-dialog-empty-state');
    const { isWide, isDesktop, isTablet } = useResponsive();
    const [images, setImages] = React.useState<ImageWithKey[]>(
        initImages.map((image) => ({ ...image, key: uniqueId() })),
    );
    const [resourceMessageProps, setResourceMessageProps] = React.useState<Partial<MessageProps> | undefined>();
    const [isLoadingImage, setIsLoadingImage] = React.useState(false);
    const [activeImageIndex, setActiveImageIndex] = React.useState(0);
    const [gridSize, setGridSize] = React.useState(3);
    const firstImageRef = React.useRef<HTMLElement>(null);

    // 480px + $lumx-spacing-unit-big * 2 + $lumx-spacing-unit-medium = 524px
    const isSmallCustom = useMediaQuery({
        query: '(max-width: 524px)',
    });

    // Comparison should be made without the key property
    // We remove the key in both arrays because each may have the key property (or not)
    // depending on the way the images were added
    const shouldDisplayConfirmDialog =
        isNewImageGallery ||
        !isEqual(
            images.map((image) => ({ ...image, key: undefined })),
            initImages.map((image) => ({ ...image, key: undefined })),
        );
    const warningMessageProps = React.useMemo(() => ({ kind: Kind.warning, hasBackground: true }), []);

    const { hiddenInput, openPicker } = useFilePicker({
        onSelectFiles: (pickedFiles) => {
            if (pickedFiles) {
                if (pickedFiles.length + images.length > maxImagesNumber) {
                    setResourceMessageProps(warningMessageProps);
                }

                const files = formatFilesToImageStructure(pickedFiles, true, {
                    maxNumber: maxImagesNumber,
                    currentNumber: images.length,
                });
                const filesWithKey = files.map((file) => ({ ...file, key: uniqueId() }));

                setImages(images ? images.concat(filesWithKey) : filesWithKey);
            }
        },
        accept: 'image/*',
        multiple: true,
        inputOrigin: 'image-gallery-dialog-button-add-resources',
    });

    const { hiddenInput: hiddenReplaceInput, openPicker: openReplacePicker } = useFilePicker({
        onSelectFiles: (pickedFiles) => {
            if (pickedFiles) {
                const pickedImage = formatFilesToImageStructure(pickedFiles, true);

                const imagesWithNewOne = images.map((image, index) => {
                    if (index === activeImageIndex) {
                        return pickedImage[0];
                    }
                    return image;
                });
                const imagesWithNewOneWithKey = imagesWithNewOne.map((image) => ({ ...image, key: uniqueId() }));

                setImages(imagesWithNewOneWithKey);
            }
        },
        accept: 'image/*',
        multiple: false,
        inputOrigin: 'image-gallery-dialog-button-replace-image',
    });

    const onRemove = () => {
        setImages(images.filter((_, index) => index !== activeImageIndex));
    };

    const onChangeAltText = React.useCallback(
        (newAltText: string) => {
            const newImages = images.map((image, index) =>
                index === activeImageIndex ? { ...image, alt: newAltText } : image,
            );
            setImages(newImages);
        },
        [activeImageIndex, images],
    );

    // Responsive grid size
    React.useEffect(() => {
        /**
         * Make sure to replicate the changes in index.scss
         */
        if (isWide) {
            setGridSize(3);
        } else if (!isWide && !isDesktop && !isSmallCustom && !isTablet) {
            setGridSize(2);
        } else if (isDesktop && !isTablet) {
            setGridSize(1);
        } else if (isTablet && !isSmallCustom) {
            setGridSize(2);
        } else if (isSmallCustom) {
            setGridSize(1);
        }
    }, [isTablet, isSmallCustom, gridSize, isDesktop, isWide]);

    const handleSave = async () => {
        try {
            setIsLoadingImage(true);
            await onSave(images);
        } finally {
            setResourceMessageProps(undefined);
            setIsLoadingImage(false);
        }
    };

    const resourceRenderer = (image: ImageWithKey) => {
        return (
            <Thumbnail
                className={element('grid-image')}
                image={image.blobUrl || image.url || ''}
                alt={image.alt || ''}
                aspectRatio={AspectRatio.square}
                isLoading={isLoadingImage && !image.url}
                imgProps={{
                    draggable: false,
                }}
            />
        );
    };

    const rightElementRenderer = (props: RightElementProps<ImageWithKey>) => {
        if (isTablet) {
            return null;
        }

        if (props.active.index !== activeImageIndex) {
            setActiveImageIndex(props.active.index);
        }

        return (
            <ImageDetails
                {...props}
                onRemove={onRemove}
                onReplace={openReplacePicker}
                isLoadingImage={isLoadingImage}
                onChangeAltText={onChangeAltText}
            />
        );
    };

    React.useEffect(() => {
        if (initImages) {
            if (initImages.length > maxImagesNumber) {
                setResourceMessageProps(warningMessageProps);
                setImages(initImages.slice(0, maxImagesNumber).map((image) => ({ ...image, key: uniqueId() })));
            } else {
                setResourceMessageProps(undefined);
                setImages(initImages.map((image) => ({ ...image, key: uniqueId() })));
            }
        }
    }, [initImages, maxImagesNumber, warningMessageProps]);

    const leftElementRenderer = (props: LeftElementProps) => {
        return (
            <ImageDraggableGrid
                {...props}
                firstElementRef={firstImageRef}
                images={images}
                setImages={setImages}
                horizontalSize={gridSize}
                focusFirstItemOnMount
            />
        );
    };

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            firstImageRef.current?.focus();
        }, 0);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <>
            <ResourceOrderingDialog<ImageWithKey>
                dialogTitle={translateKey(WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_EDIT_TITLE)}
                scope={CLASSNAME}
                onSave={handleSave}
                onClose={onClose}
                isOpen={isOpen}
                isLoading={isLoadingImage}
                maxResourceMessage={translateAndReplace(WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_MESSAGE, {
                    NB: maxImagesNumber,
                })}
                addResourceButtonLabel={translateKey(MEDIAS.ADD_IMAGES)}
                maxResourcesNumber={maxImagesNumber}
                onAddResources={openPicker}
                shouldDisplayConfirmDialog={shouldDisplayConfirmDialog}
                resourceMessageProps={resourceMessageProps}
                emptyStateProps={{
                    title: translateKey(WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_EMPTY_TITLE),
                    message: translateKey(WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_EMPTY_DESCRIPTION),
                    buttonProps: {
                        onClick: openPicker,
                        children: translateKey(MEDIAS.ADD_IMAGES),
                        emphasis: Emphasis.medium,
                        leftIcon: mdiPlus,
                        ...get({ element: 'button', action: 'add-images' }),
                    },
                    iconProps: { icon: mdiImageMultipleOutline },
                    wrapperProps: { className: element('empty-state') },
                }}
                resources={images}
                resourceRenderer={resourceRenderer}
                rightElementRenderer={rightElementRenderer}
                leftElementRenderer={leftElementRenderer}
                shouldFocusResourceParent
                isEdit={isEdit}
            />
            {isOpen && hiddenInput}
            {isOpen && !isTablet && hiddenReplaceInput}
        </>
    );
};
