import { useCallback, useMemo } from 'react';

import { GDDocument } from '@lumapps/google-drive-pickers/types';
import { mdiFileOutline } from '@lumapps/lumx/icons';
import { useMediaPickerDropdown } from '@lumapps/medias/hooks/useMediaPickerDropdown';
import { MediaSourceV2 } from '@lumapps/medias/types';
import { OneDriveDocument } from '@lumapps/one-drive-pickers/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useFilePicker } from '@lumapps/utils/hooks/useFilePicker';
import { ReactEditor, useSlate, useSlateStatic } from '@lumapps/wrex/slate';
import { ToolbarItem } from '@lumapps/wrex/types';

import { MAX_DRIVE_FILE_COUNT } from '../../../constants';
import { FileEditor, WrexDriveMediaSource } from '../../../types';
import { isDriveFile } from '../../../utils/isDriveFile';
import { File as FileElement } from '../../blocks/File';

export interface UseFileInsertionButtonProps {
    /** Whether Drive is allowed in the media picker. */
    isDriveAllowed?: boolean;
    /** Whether we use picker options v2 in structured content */
    isPickerV2?: boolean;
    /** Other props passed to the ToolbarItem */
    otherProps?: any;
}

export const useFileInsertionButton = ({ isDriveAllowed, isPickerV2, otherProps }: UseFileInsertionButtonProps) => {
    const editor = useSlate() as ReactEditor & FileEditor;
    const editorStatic = useSlateStatic() as ReactEditor & FileEditor;
    const { pluralize } = useTranslate();

    const driveFileLength = editor.children.filter((item: any) => isDriveFile(item)).length;
    const isMaxDriveFiles = driveFileLength >= MAX_DRIVE_FILE_COUNT;

    const onSelectFiles = useCallback(
        (files: File[]) => {
            files.forEach((file) => {
                editorStatic.insertFile({}, file);
            });
        },
        [editorStatic],
    );

    const onSelectDriveFiles = useCallback(
        (files: GDDocument[] | OneDriveDocument[], provider: WrexDriveMediaSource) => {
            const slicedFiles =
                provider === MediaSourceV2.GOOGLE_DRIVE
                    ? (files as GDDocument[])?.slice(0, MAX_DRIVE_FILE_COUNT - driveFileLength)
                    : files;

            slicedFiles.forEach((file) => {
                editorStatic.insertDriveFile(file, provider);
            });
        },
        [editorStatic, driveFileLength],
    );

    const { hiddenInput, openPicker } = useFilePicker({
        onSelectFiles,
        accept: '*',
        multiple: true,
        inputOrigin: 'file-insertion-button',
    });

    const { mediaPickerDropdownProps, oneDrivePicker } = useMediaPickerDropdown({
        onUploadFromComputer: openPicker,
        isDriveAllowed,
        isPickerV2,
        onFilesSelected: onSelectDriveFiles,
        isMaxDriveFiles,
        subMenuProps: {
            itemKey: 'attachment-file',
            icon: mdiFileOutline,
            otherProps,
            verticalModeProps: {
                label: pluralize(GLOBAL.FILE, 1),
            },
        },
    });

    const fileInsertionButton = useMemo(() => {
        const fileInsertionButtonProps: ToolbarItem = {
            itemKey: FileElement.displayName,
            icon: mdiFileOutline,
            label: pluralize(GLOBAL.FILE, 1),
            otherProps,
            onClick: openPicker,
            type: 'option',
        };
        return fileInsertionButtonProps;
    }, [pluralize, otherProps, openPicker]);

    return {
        oneDrivePicker,
        hiddenInput,
        fileInsertionButton: mediaPickerDropdownProps || fileInsertionButton,
    };
};
