import { MediaStatus } from '@lumapps/medias/types';
import { Converter } from '@lumapps/wrex/serialization/dita/toDITA/types';
import { Data, File, DriveFile } from '@lumapps/wrex/serialization/dita/types';

import { FileElement } from '../../types';
import { isDriveFile as isDriveFileCheck } from '../../utils/isDriveFile';

export const FILE_CONVERT: Converter<FileElement> = (node) => {
    const children: Data[] = [
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'name',
                value: node?.name || '',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'mimeType',
                value: node?.mimeType || '',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'createdAt',
                value: node?.createdAt || '',
            },
            children: [],
        },
    ];

    if (isDriveFileCheck(node)) {
        children.push(
            {
                type: 'element',
                name: 'data',
                attributes: {
                    name: 'driveId',
                    value: (node.resource as any)?.driveId || '',
                },
                children: [],
            },
            {
                type: 'element',
                name: 'data',
                attributes: {
                    name: 'provider',
                    value: node.resource?.type || 'local',
                },
                children: [],
            },
            {
                type: 'element',
                name: 'data',
                attributes: {
                    name: 'status',
                    value: node.status || MediaStatus.LIVE,
                },
                children: [],
            },
            {
                type: 'element',
                name: 'data',
                attributes: {
                    name: 'editedAt',
                    value: node.editedAt || '',
                },
                children: [],
            },
        );
    }

    const baseDitaNode = {
        type: 'element',
        name: 'data-about',
        attributes: {
            href: node?.src || '',
            scope: 'local',
            type: 'lumapps:file',
            keyref: node?.resource?.id || '',
        },
    };

    return { ...baseDitaNode, children } as File | DriveFile;
};
