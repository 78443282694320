export enum REACTIONS_LIKES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=59685966
     */
    LIKE_BUTTON_ARIA = 'FRONT.CONTENTS.LIKE_BUTTON.ARIA',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453772
     */
    USER_LIST_LIKES = 'USER_LIST_LIKES',
}
