/* eslint-disable */
import assign from 'lodash/assign';
import camelCase from 'lodash/camelCase';
import every from 'lodash/every';
import filter from 'lodash/filter';
import loFind from 'lodash/find';
import first from 'lodash/first';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import matches from 'lodash/matches';
import omit from 'lodash/omit';
import loReject from 'lodash/reject';
import snakeCase from 'lodash/snakeCase';
import some from 'lodash/some';
import toPairs from 'lodash/toPairs';
import zipObject from 'lodash/zipObject';
import { setInitialState as permissions } from '@lumapps/permissions/ducks/slice';
import { setInitialState as directories } from '@lumapps/directories/ducks/slice';
import { setInitialState as headerInitState } from '@lumapps/header/ducks/slice';
import { EVENTS_FF } from '@lumapps/events/ducks/selectors';
import { SPACES_FEATURE_TOKEN } from '@lumapps/communities/constants';
import { PLAY_VIDEO_FEATURE_TOKEN } from '@lumapps/play/constants';
import { CONTRIBUTION_WIDGET_FF } from '@lumapps/widget-contribution/ducks/selectors';
import { WIDGET_PERSONAL_FEED_PREVIEW_FF } from '@lumapps/widget-personal-feed-preview/constants';
import { onInitialStateLoaded } from '@apps/core/api/onInitialStateLoaded';
import {
    initializeGA3,
    initializeGA4,
    initializeGTM,
} from '@lumapps/metric/hooks/useGoogleAnalyticsAndTagManager/loadScripts';
import { CLIENT_DATA_LAYER_NAME } from '@lumapps/metric/constants';

import { getCookie } from 'common/utils/cookie_utils';

import { publicModeEnabled as isSocialAdvocacyPublicPage } from 'components/components/social-advocacy/utils';

import { DEFAULT_CONTRIBUTION_LANGS, SUPPORTED_LANGS } from '../../config';
import { LEARNING_FF } from '@lumapps/learning/constants';
import { ContentTypes as CONTENT_TYPES } from '@lumapps/content-types/types';

/////////////////////////////

function SettingsService(
    $anchorScroll,
    $exceptionHandler,
    $filter,
    $http,
    $injector,
    $interval,
    $location,
    $log,
    $q,
    $timeout,
    $timezone,
    $window,
    Analytics,
    Community,
    CommunityTemplates,
    Config,
    Content,
    CustomContentType,
    Customer,
    Directory,
    DirectoryEntryUser,
    Document,
    Features,
    Feed,
    FeedType,
    Fsitems,
    Header,
    InitialSettings,
    Instance,
    MainNav,
    Media,
    Metadata,
    Notification,
    NotificationSettings,
    Post,
    ReduxStore,
    Role,
    SettingsFactory,
    SocialAdvocacy,
    SocialProfile,
    Style,
    Template,
    Translation,
    Tutorial,
    User,
    UserDirectory,
    UserFavorite,
    Utils,
    Widget,
    WidgetConstant,
) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The main body of the document.
     *
     * @type {jQElement}
     * @constant
     * @readonly
     */
    const _BODY = angular.element('body');

    /**
     * Contains the default values for the different type of colors.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _DEFAULT_COLORS = {
        accent: '#4CAF50',
        primary: '#2196F3',
    };

    /**
     * The default theme to use for the instance.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _DEFAULT_THEME = 'default';

    /**
     * The element containing the SVG to display when an error occurred.
     *
     * @type {jQElement}
     * @constant
     * @readonly
     */
    const _ERROR_SVG = angular.element('#error-svg');

    /**
     * The size of the downloaded favicon.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _FAVICON_SIZE = 192;

    /**
     * The delay to hide the loader when settings has finished.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _LOADER_HIDE_DELAY = 1000;

    /**
     * The element containing the main SVG animation of the initial loader.
     *
     * @type {jQElement}
     * @constant
     * @readonly
     */
    const _LOADING_ANIMATION = angular.element('#loading-animation');

    /**
     * The main container of the whole initial loader.
     *
     * @type {jQElement}
     * @constant
     * @readonly
     */
    const _LOADING_CONTAINER = angular.element('#first-loader-container');

    /**
     * The minimum duration a message should be visible on screen, before allowing to display another one.
     * In milliseconds.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MESSAGE_MINIMUM_DISPLAY_TIME = 0;

    /**
     * Contains the element used to display messages when loading the application.
     *
     * @type {jQElement}
     * @constant
     * @readonly
     */
    let _MESSAGE_TICKER = angular.element('#messages-ticker');

    /**
     * Contain all the styles elements for the initial loader.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _STYLE_ELEMENTS = {
        style: angular.element('#first-loader-style'),
    };

    /**
     * The delay of fade in/fade out of the ticker of loading messages.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _TICKER_FADE_DELAY = 100;

    /**
     * The delay to unblur the main application after the loader is faded out.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _UNBLUR_DELAY = 400;

    /**
     * The delay to check if the message queue is empty before hiding the loader.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _WAIT_FOR_MESSAGES_INTERVAL = 100;

    /**
     * Contains the element used to display the welcome message.
     *
     * @type {jQElement}
     * @constant
     * @readonly
     */
    const _WELCOME_MESSAGE = angular.element('#welcome-message');

    /**
     * Contains the promise of the interval that check if the loader can be hidden.
     *
     * @type {$intervalPromise}
     */
    let _cancelInterval;

    /**
     * Indicates if it's the first time we end the loading (hence we hide the first loader).
     *
     * @type {boolean}
     */
    let _firstEndLoading = true;

    /**
     * Contains the promise of the expiration of the currently displayed message.
     * The message will be displayed at least one second on the screen and after that will display the next message
     * in the queue. It will remain displayed if there is no other message in the queue.
     *
     * @type {Promise}
     */
    let _messageDisplayedPromise;

    /**
     * Contains the queue of messages to display.
     * This queue allow us to make sure that each message is displayed at least 1 second on the screen.
     *
     * @type {Array}
     */
    const _messageQueue = [];

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The promise of the initialization.
     *
     * @type {Promise}
     */
    service.initializationPromise = undefined;

    /**
     * Contains letious indicators about the state of the service.
     *
     * @type {Object}
     */
    service.is = {
        // eslint-disable-next-line id-blacklist
        error: false,
        firstContentLoader: false,
        initialized: false,
        initializing: false,
    };

    /**
     * This contains a list of functions to execute when the settings service has ended the initialization.
     *
     * @type {Array}
     */
    service.executionQueue = [];

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Set the progress bar to the end with a message.
     */
    function _endProgress() {
        if (_LOADING_CONTAINER.hasClass('finishing')) {
            return;
        }

        _LOADING_CONTAINER.addClass('finishing');
        angular.element('html').removeClass('loading');

        if (window.IS_BROWSER_TRANSLATION_ENABLED === 'False') {
            document.querySelector('html').setAttribute('translate', 'no');
        }

        if (!service.is.error) {
            service.message(Translation.translate('LOADING.FINISHING_SETUP'));
        }
    }

    /**
     * Display a message (with a fade effect).
     *
     * @param {string} text The text to display.
     */
    function _displayMessage(text) {
        if (angular.isUndefinedOrEmpty(_MESSAGE_TICKER)) {
            return;
        }

        _messageDisplayedPromise = $q(function displayMessageAtLeastOneSecond(resolve) {
            _MESSAGE_TICKER.fadeOut(_TICKER_FADE_DELAY, function onMessageTickerHidden() {
                if (angular.isUndefinedOrEmpty(_MESSAGE_TICKER)) {
                    return;
                }

                _MESSAGE_TICKER.html($filter('safeAngular')(text));
                _MESSAGE_TICKER.fadeIn(_TICKER_FADE_DELAY);

                // eslint-disable-next-line no-magic-numbers
                const displayTime = _MESSAGE_MINIMUM_DISPLAY_TIME - _TICKER_FADE_DELAY * 2;
                $timeout(function waitForMessageToBeRead() {
                    resolve(text, displayTime);
                }, displayTime);
            });
        });

        _messageDisplayedPromise.then(function onMessageDisplayedOneSecond() {
            if (angular.isDefinedAndFilled(_messageQueue)) {
                const nextMessage = _messageQueue.shift();

                _displayMessage(nextMessage);
            } else if (service.is.initialized) {
                _endProgress();
            }

            _messageDisplayedPromise = undefined;
        });
    }

    /**
     * Hide the loader and the message ticker.
     *
     * @param {boolean} [immediate=false] Indicate if we want to immediatly end the loading.
     */
    function _endLoading(immediate) {
        service.hideFirstLoader(immediate);
        service.hideTicker();

        if (_firstEndLoading) {
            _firstEndLoading = false;

            $window.lumappsPerformances.loaderHidding = Date.now();

            $window.lumappsPerformances.loaderHidden =
                $window.lumappsPerformances.loaderHidding - $window.performance.timing.navigationStart;

            $window.lumappsPerformances.loaderDisplayed =
                $window.lumappsPerformances.loaderHidding - $window.lumappsPerformances.firstPainting;

            if (angular.isDefined($location.search().perfs)) {
                $log.debug(
                    'Loader hidding',
                    $window.lumappsPerformances.loaderHidding,
                    $window.lumappsPerformances.loaderHidden,
                    $window.lumappsPerformances.loaderDisplayed,
                );
            }
        }
    }

    /**
     * End the setup.
     *
     * @param {Function} resolve           The resolve function to resolve the setup promise.
     * @param {boolean}  [immediate=false] Indicates if we want to immediately end the setup.
     */
    function _endSetup(resolve, immediate) {
        if (angular.isUndefinedOrEmpty(_messageQueue) || immediate) {
            _endProgress();
        }

        service.is.initializing = false;
        service.is.initialized = true;

        resolve(User.getConnected());
    }

    /**
     * Remove the loader.
     */
    function _removeLoader() {
        _LOADING_CONTAINER.remove();
        _STYLE_ELEMENTS.style.remove();
        localStorage.removeItem(window.refreshTimeoutLocalStorageId);
        console.log('deleting strikes since we have shown the page');
    }

    /**
     * Returns if the Analytics should be setup or not.
     *
     * @return {boolean} Whether the Google Analytics and Tag Manager should be setup.
     */
    function _shouldSetupAnalytics() {
        const hasCookieBannerEnabled = Features.hasFeature('gdpr-banner-widget');

        const connectedUser = User.isConnected() && User.getConnected();
        const userId = connectedUser && connectedUser.id;

        const cookieName = userId ? `hasAcceptedCookies.${connectedUser.id}` : 'hasAcceptedCookies';

        /**
         * If the cookie-banner FF is enabled,
         * we consider that the user has accepted analytics cookies only if he explicity accept it
         * If not, we don't need the explicit agreement of the user to start tracking
         */
        const hasAcceptedCookies =
            (hasCookieBannerEnabled && getCookie(cookieName) === 'true') ||
            (!hasCookieBannerEnabled && getCookie(cookieName) !== 'false');

        return hasAcceptedCookies;
    }

    /**
     * Setup all Analytics features (Google Analytics, Pendo and Google Tag Manager).
     * Inject new scripts to load and configure existing Analytics instances.
     */
    function _setupAnalytics() {
        const currentInstance = Instance.getInstance();
        if (angular.isUndefinedOrEmpty(currentInstance)) {
            return;
        }

        if (!_shouldSetupAnalytics()) {
            return;
        }

        // If the user is connected from the `as` process, do not initialize google analytics nor GTM.
        if (User.isConnectedAs()) {
            window.ga = angular.noop;
            window.dataLayer = {
                push: angular.noop,
            };
            window[CLIENT_DATA_LAYER_NAME] = {
                push: angular.noop,
            };
            return;
        }

        /** GTM */
        if (angular.isDefinedAndFilled(currentInstance.googleTagManager)) {
            initializeGTM(currentInstance.googleTagManager, CLIENT_DATA_LAYER_NAME);
        }

        /** GA */
        const params = {};
        if (User.isConnected()) {
            const connectedUser = User.getConnected();
            if (angular.isDefinedAndFilled([connectedUser.uid, connectedUser.id], 'some')) {
                params.userId = connectedUser.uid || connectedUser.id;
            }
        }

        if (angular.isDefinedAndFilled(currentInstance.googleAnalytics)) {
            if (currentInstance.googleAnalytics.startsWith('G-')) {
                initializeGA4(currentInstance.googleAnalytics);
            } else {
                initializeGA3();
                window.ga('create', currentInstance.googleAnalytics, 'auto', undefined, params);
            }
        }
    }

    /**
     * Setup the custom stylesheets of the theme and the instance.
     * Inject them in the head element of the DOM.
     *
     * @param {Array} stylesheets The list of stylesheets to inject.
     */
    function _setupStylesheets(stylesheets) {
        const safeMode = angular.isDefined($location.search().safe);
        if (safeMode) {
            return;
        }

        const rootStyleSheets = filter(stylesheets, {
            kind: 'root',
        });

        // Inject after the component styles (to let instance style override them).
        let appendAfterElement =
            document.getElementById('components-stylesheet') ||
            document.getElementById('theme-stylesheet') ||
            first(_STYLE_ELEMENTS.style);

        angular.forEach(rootStyleSheets, function forEachRootStylesheets(rootStylesheet) {
            appendAfterElement = Utils.injectStylesheet(
                rootStylesheet.url,
                `instance-stylesheet-${rootStylesheet.kind}`,
                appendAfterElement,
            );
        });

        const customStyleSheets = filter(stylesheets, {
            kind: 'custom',
        });
        angular.forEach(customStyleSheets, function forEachCustomStylesheets(customStylesheet) {
            appendAfterElement = Utils.injectStylesheet(
                customStylesheet.url,
                `instance-stylesheet-${customStylesheet.kind}`,
                appendAfterElement,
            );
        });
    }

    /**
     * Setup the config constants.
     * This initialize some values of the config constant that cannot be initialized directly anymore since we are
     * receiving some initial settings asynchronously.
     */
    function _setupConfig() {
        Config.AVAILABLE_CONTENT_TYPES = InitialSettings.CONTENT_TYPES;
    }

    /**
     * Setup all the initial settings constants available.
     *
     * @param {Object} initialSetting The initial settings retrived from the handler.
     */
    function _setupConstants(initialSetting) {
        angular.forEach(InitialSettings, function forEachInitialSettings(unused, settingName) {
            const camelizedName = camelCase(settingName.toLowerCase());
            const lowercasedName = settingName.toLowerCase();
            const value = get(
                initialSetting,
                camelizedName,
                get(initialSetting, settingName, get(initialSetting, lowercasedName)),
            );

            if (angular.isDefined(value)) {
                InitialSettings[settingName] = value;
            }

            delete initialSetting[camelizedName];
            delete initialSetting[settingName];
            delete initialSetting[lowercasedName];
        });

        delete initialSetting.$promise;
        delete initialSetting.$resolved;
    }

    /**
     * Setup the favicon for the instance.
     *
     *
     * @param {Object|string} [favicon]         The favicon got from the instance. This can be an URL or a blob key.
     * @param {string}        [theme="default"] The theme of the instance.
     */
    function _setupFavicon(favicon, theme) {
        theme = theme || _DEFAULT_THEME;

        let faviconUrl = angular.isDefinedAndFilled(favicon)
            ? Utils.resizeImage(Translation.translate(favicon), _FAVICON_SIZE)
            : `${InitialSettings.PUBLIC_PATH_PREFIX}specifics/${theme}/img/favicon.png`;
        faviconUrl += includes(faviconUrl, '?') ? '&' : '?';

        /* eslint-disable angular/document-service */
        const header = document.head;

        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.id = 'dynamic-favicon';
        newFavicon.href = faviconUrl;
        const oldFavicon = document.getElementById('default-icon');
        if (angular.isDefined(oldFavicon)) {
            header.removeChild(oldFavicon);
        }
        header.appendChild(newFavicon);

        const newMobileIcon = document.createElement('link');
        newMobileIcon.rel = 'apple-touch-icon-precomposed apple-touch-icon';
        newMobileIcon.id = 'dynamic-mobile-icon';
        newMobileIcon.href = faviconUrl;
        const oldMobileIcon = document.getElementById('default-mobile-icon');
        if (angular.isDefined(oldMobileIcon)) {
            header.removeChild(oldMobileIcon);
        }
        header.appendChild(newMobileIcon);
        /* eslint-disable angular/document-service */
    }

    /**
     * Setup all the languages and translations files.
     *
     * Todo [Arnaud]: move that language logic to translation service? Just keep the bit injecting the script here.
     *
     * @param  {Object}  [instance] The instance.
     * @return {Promise} The promise of loding and injecting lang files.
     */
    function _setupLang(instance) {
        const langs = {};
        let mainLang;

        // Get the user language preferences.
        if (User.isConnected()) {
            const user = User.getConnected();

            if (angular.isDefinedAndFilled(user.lang)) {
                mainLang = user.lang;

                langs[user.lang] = true;
            }

            if (angular.isDefinedAndFilled(user.langs)) {
                mainLang = mainLang || first(user.langs);

                angular.forEach(user.langs, function forEachLangs(lang) {
                    langs[lang] = true;
                });
            }
        }

        // Get the browser languages.
        let browserLang = angular.isDefinedAndFilled(navigator.languages) ? navigator.languages[0] : navigator.language;
        if (angular.isDefinedAndFilled(browserLang)) {
            browserLang = browserLang.toLowerCase().replace(/-/g, '_');
            browserLang =
                includes(SUPPORTED_LANGS, browserLang) && includes(DEFAULT_CONTRIBUTION_LANGS, browserLang)
                    ? browserLang
                    : browserLang.split('_')[0];

            mainLang = mainLang || browserLang;

            langs[browserLang] = true;
        }

        // Get the language from the url.
        const urlLang = $location.search().lang;
        if (angular.isDefinedAndFilled(urlLang)) {
            mainLang = mainLang || urlLang;

            langs[urlLang] = true;
        }

        mainLang = mainLang || Translation.DEFAULT_LANG;

        let allLangs = Object.keys(langs);
        if (!includes(allLangs, mainLang) && mainLang !== Translation.DEFAULT_LANG) {
            allLangs.push(mainLang);
        }
        allLangs = loReject(allLangs, Translation.DEFAULT_LANG);

        // Get the instance languages.
        if (angular.isDefinedAndFilled(instance.langs)) {
            let instanceLangsLoaded = instance.langs;
            if (angular.isDefined(instance.defaultLang) && !includes(instanceLangsLoaded, instance.defaultLang)) {
                instanceLangsLoaded.push(instance.defaultLang);
            }
            instanceLangsLoaded = intersection(allLangs, instanceLangsLoaded);
            instanceLangsLoaded = loReject(instanceLangsLoaded, Translation.DEFAULT_LANG);
            if (angular.isUndefinedOrEmpty(instanceLangsLoaded)) {
                const instanceLang = instance.defaultLang || first(instance.langs);
                if (angular.isDefinedAndFilled(instanceLang)) {
                    allLangs.push(instanceLang);

                    // The instance only has a lang and the user doesn't have it.
                    mainLang = mainLang === instanceLang ? mainLang : instanceLang;
                }
            }
        }

        Translation.allLangs = allLangs;

        let id;
        let previousId = 'lang-script-front-office-en';
        if (angular.isDefinedAndFilled(mainLang) && mainLang !== Translation.DEFAULT_LANG) {
            id = `lang-script-front-office-${mainLang}`;
            Utils.injectScript(`langs/angular-i18n/angular-locale_${mainLang}.js`, id, 'body', previousId, false);
            previousId = id;
        }

        // Make sure Translation service has current lang in none is set.
        const currentLang = Translation.getLang('current');
        if (angular.isUndefinedOrEmpty(currentLang) || currentLang !== mainLang) {
            Translation.setLang('current', mainLang);
        }

        return $q(function defer(resolve) {
            const promises = [];

            angular.forEach(Translation.allLangs, function forEachLangsToDownload(lang) {
                const { theme } = instance;
                const newThemesStyles = document.querySelectorAll('#theme-head-style');
                const newThemesScripts = document.querySelectorAll('#theme-head-script');
                const newThemesLinks = document.querySelectorAll('#theme-head-link');

                const hasNewThemeSystem =
                    (newThemesStyles && newThemesStyles.length > 0) ||
                    (newThemesScripts && newThemesScripts.length > 0) ||
                    (newThemesLinks && newThemesLinks.length > 0);

                if (angular.isDefinedAndFilled(theme) && theme !== 'default' && !hasNewThemeSystem) {
                    id = `lang-theme-${theme}-${lang}`;
                    promises.push(
                        Utils.injectScript(
                            `specifics/${theme}/langs/${theme}_${lang}.min.js`,
                            id,
                            'body',
                            previousId,
                            true,
                        ),
                    );
                    previousId = id;
                }

                if (lang === Translation.DEFAULT_LANG) {
                    return;
                }

                id = `lang-script-front-office-${lang}`;
                promises.push(
                    Utils.injectScript(`langs/front-office_${lang.toLowerCase()}.min.js`, id, 'body', previousId, true),
                );
                previousId = id;

                const momentLang = Translation.momentLangs[lang] || lang;
                id = `lang-moment-${lang}`;
                promises.push(Utils.injectScript(`langs/moment/locale/${momentLang}.js`, id, 'body', previousId, true));
                previousId = id;
            });

            $q.all(promises).finally(resolve);
        });
    }

    /**
     * Setup the instance's script.
     *
     * @param {Array} instanceScripts The list of instance's scripts.
     */
    function _setupInstanceScripts(instanceScripts) {
        const safeMode = angular.isDefined($location.search().safe);
        if (angular.isUndefinedOrEmpty(instanceScripts) || safeMode) {
            return;
        }
        angular.forEach(instanceScripts, function forEachInstanceScripts(instanceScript, index) {
            if (angular.isUndefinedOrEmpty(get(instanceScript, 'url'))) {
                return;
            }

            const previous = index === 0 ? 'instance-script-dumb' : `instance-script-${index - 1}`;

            let instanceScriptUrl = instanceScript.url;
            instanceScriptUrl += includes(instanceScriptUrl, '?') ? '&' : '?';
            Utils.injectScript(instanceScriptUrl, `instance-script-${index}`, 'body', previous, false);
        });
    }

    /**
     * Setup the notification settings.
     * This replaces the static setup of the `NotificationSettings` constants now that the `FEATURES` are received
     * asynchronously.
     */
    function _setupNotifications() {
        const featureCommunityToken = 'community';
        const featureSocialToken = 'social';

        NotificationSettings['community_new_access_request'] = {
            getReplacements: function getCommunityNewAccessRequestReplacements(notificationGroup, actionCount) {
                return [
                    actionCount,
                    actionCount === 1 ? 'ACCESS_REQUEST' : 'ACCESS_REQUESTS',
                    get(notificationGroup.notification, 'contentDetails.title'),
                ];
            },
            icon: 'account-alert',
            requiredFeatures: [featureCommunityToken],
            tokens: ['NB_REQUESTS', 'ACCESS_REQUESTS', 'COMMUNITY_TITLE'],
        };

        NotificationSettings['community_extended_service_management'] = {
            getReplacements: function getCommunityExtendedServiceReplacements(notificationGroup) {
                return [
                    get(notificationGroup.notification, 'title'),
                    get(notificationGroup.notification, 'description'),
                ];
            },
            icon: 'pencil',
            requiredFeatures: [featureCommunityToken],
            tokens: ['COMMUNITY_TITLE', 'COMMUNITY_DESC'],
        };

        NotificationSettings['community_new_credential'] = {
            getReplacements: function getCommunityNewCredentialReplacements(notificationGroup) {
                return [get(notificationGroup.notification, 'contentDetails.title')];
            },
            icon: 'account-key',
            requiredFeatures: [featureCommunityToken],
            tokens: ['COMMUNITY_TITLE'],
        };

        NotificationSettings['post_mention'] = {
            getReplacements: function getPostMentionReplacements(notificationGroup) {
                const author = get(first(notificationGroup.actions), 'senderDetails');

                return [User.getUserFullName(author)];
            },
            hasThumbnail: true,
            icon: 'comment-text-outline',
            requiredFeatures: [featureCommunityToken],
            tokens: ['AUTHOR_NAME'],
        };

        NotificationSettings['post_merge_pending'] = {
            icon: 'help-network',
            requiredFeatures: [featureCommunityToken],
        };

        NotificationSettings['post_merged'] = {
            getReplacements: function getPostMergedReplacements(notificationGroup) {
                return [get(notificationGroup.notification, 'parentContentDetails.title')];
            },
            icon: 'source-merge',
            requiredFeatures: [featureCommunityToken],
            tokens: ['COMMUNITY_TITLE'],
        };

        NotificationSettings['post_merged_master'] = {
            icon: 'source-merge',
            requiredFeatures: [featureCommunityToken],
        };

        NotificationSettings['post_merged_slave'] = {
            icon: 'source-merge',
            requiredFeatures: [featureCommunityToken],
        };

        NotificationSettings['post_new'] = {
            getReplacements: function getPostNewReplacements(notificationGroup) {
                const author = get(first(notificationGroup.actions), 'senderDetails');

                return [
                    User.getUserFullName(author),
                    get(notificationGroup.notification, 'parentContentDetails.title'),
                ];
            },
            hasThumbnail: true,
            icon: 'comment-text-outline',
            requiredFeatures: [featureCommunityToken, featureSocialToken],
            tokens: ['AUTHOR_NAME', 'COMMUNITY_TITLE'],
        };

        // eslint-disable-next-line camelcase
        NotificationSettings['post_status_update'] = {
            getReplacements: function getPostStatusUpdateReplacements(notificationGroup) {
                return [get(notificationGroup.notification, 'contentDetails.postStatusDetails.title')];
            },
            icon: 'autorenew',
            requiredFeatures: [featureCommunityToken],
            tokens: ['STATUS_NAME'],
        };

        NotificationSettings['post_update'] = {
            getReplacements: function getPostUpdateReplacements(notificationGroup) {
                const author = get(first(notificationGroup.actions), 'senderDetails');

                return [
                    User.getUserFullName(author),
                    get(notificationGroup.notification, 'parentContentDetails.title'),
                ];
            },
            hasThumbnail: true,
            icon: 'comment-text-outline',
            requiredFeatures: [featureCommunityToken, featureSocialToken],
            tokens: ['AUTHOR_NAME', 'COMMUNITY_TITLE'],
        };

        NotificationSettings['social_advocacy_new_shareable_content'] = {
            tokens: ['CONTENT_TITLE', 'PROGRAM_NAME'],
            getReplacements: function getPostUpdateReplacements(notificationGroup) {
                const contentTitle = get(notificationGroup, 'notification.description.content_title', {});
                const programTitle = get(notificationGroup, 'notification.description.program_title', {
                    en: Translation.translate('ADMIN.FEATURES.SOCIAL_ADVOCACY.NAME'),
                });

                return [Translation.translate(contentTitle), Translation.translate(programTitle)];
            },
            icon: 'bullhorn',
            requiredFeatures: [featureSocialToken],
        };

        NotificationSettings['social_advocacy_user_promoted_amb'] = {
            icon: 'bullhorn',
            tokens: ['PROGRAM_NAME'],
            getReplacements: function getPostUpdateReplacements(notificationGroup) {
                const programTitle = get(notificationGroup, 'notification.description.program_title', {
                    en: Translation.translate('ADMIN.FEATURES.SOCIAL_ADVOCACY.NAME'),
                });

                return [Translation.translate(programTitle)];
            },
            requiredFeatures: [featureSocialToken],
        };

        NotificationSettings['social_advocacy_user_promoted_pom'] = {
            icon: 'bullhorn',
            tokens: ['PROGRAM_NAME'],
            getReplacements: function getPostUpdateReplacements(notificationGroup) {
                const programTitle = get(notificationGroup, 'notification.description.program_title', {
                    en: Translation.translate('ADMIN.FEATURES.SOCIAL_ADVOCACY.NAME'),
                });

                return [Translation.translate(programTitle)];
            },
            requiredFeatures: [featureSocialToken],
        };
    }

    /**
     * Setup everything related to the theme.
     * Basically, add the theme name as a class of the body element in the DOM.
     *
     * @param {string} [theme="default"] The theme to apply.
     */
    function _setupTheme(theme) {
        theme = theme || _DEFAULT_THEME;

        if (theme !== _DEFAULT_THEME) {
            _BODY.addClass(theme).removeClass(_DEFAULT_THEME);
        }
    }

    /**
     * Setup eveything related to the time management.
     */
    function _setupTime() {
        // Set the moment locale.
        moment.locale(Translation.getLang('current', 'moment'));
    }

    /**
     * Setup all the widgets available in the product.
     * This replaces the static setup of `DEFAULT_WIDGETS` in the `config.js` file, now that the `WIDGET_TYPES` are
     * received asynchronously.
     */
    function _setupWidgets() {
        Array.prototype.push.apply(WidgetConstant, [
            // Recommended training courses
            {
                options: {
                    icon: 'school',
                    type: WIDGET_TYPES.RECOMMENDED_TRAINING_COURSES,
                },
                relatedFeatureNames: [LEARNING_FF],
            },
            {
                options: {
                    icon: 'school',
                    type: WIDGET_TYPES.TRAINING_COURSES_IN_PROGRESS,
                },
                relatedFeatureNames: [LEARNING_FF],
            },
            {
                options: {
                    icon: 'school',
                    type: WIDGET_TYPES.USER_KPI,
                },
                relatedFeatureNames: [LEARNING_FF],
            },
            // Article list
            {
                options: {
                    icon: 'newspaper',
                    type: WIDGET_TYPES.ARTICLE_LIST,
                },
                relatedFeatureNames: [SPACES_FEATURE_TOKEN],
            },
            // AwesomeTable.
            {
                options: {
                    icon: 'view-list',
                    type: WIDGET_TYPES.AWESOME_TABLE,
                },
                relatedFeatureNames: ['awesome-table-widget'],
            },
            // Channel list.
            {
                options: {
                    icon: 'message',
                    isMicrosoft: true,
                    type: WIDGET_TYPES.CHANNEL_LIST,
                },
                relatedFeatureNames: ['channel-list-widget', 'microsoft'],
            },
            // Chart.
            {
                options: {
                    icon: 'chart-bar',
                    isMicrosoft: true,
                    type: WIDGET_TYPES.CHART,
                },
                relatedFeatureNames: ['chart-widget', 'microsoft'],
            },
            // Community list.
            {
                options: {
                    icon: 'bulletin-board',
                    type: WIDGET_TYPES.COMMUNITY_LIST,
                },
                relatedFeatureNames: ['community'],
            },
            // Community navigation.
            {
                options: {
                    context: [CONTENT_TYPES.COMMUNITY],
                    googleSync: false,
                    icon: 'menu',
                    type: WIDGET_TYPES.COMMUNITY_NAVIGATION,
                    isDisabled: () => {
                        const currentContent = Content.getCurrent();
                        return Boolean(
                            currentContent &&
                                Widget.findWidgetsByType(currentContent.template, WIDGET_TYPES.COMMUNITY_NAVIGATION)
                                    .length > 0,
                        );
                    },
                    disabledHelper: Translation.translate('COMMUNITIES.ONLY_ONE_COMMUNITY_NAVIGATION_HELPER'),
                },
                relatedFeatureNames: ['community'],
            },
            // Drawio.
            {
                options: {
                    icon: 'sitemap',
                    googleSync: true,
                    type: WIDGET_TYPES.DRAWIO,
                },
                relatedFeatureNames: ['drawio-widget'],
            },
            // Drive file preview.
            {
                options: {
                    icon: 'file-cloud',
                    type: WIDGET_TYPES.DRIVE_FILE_PREVIEW,
                },
                relatedFeatureNames: ['drive-file-preview-widget'],
            },
            // File list.
            {
                options: {
                    icon: 'file-multiple',
                    type: WIDGET_TYPES.FILE_LIST,
                },
                relatedFeatureNames: ['file-list-widget'],
            },
            // Instance list.
            {
                options: {
                    icon: 'checkbox-multiple-blank',
                    type: WIDGET_TYPES.INSTANCE_LIST,
                },
                relatedFeatureNames: ['instance-list-widget'],
            },
            // Meta social.
            {
                options: {
                    icon: 'heart',
                    type: WIDGET_TYPES.META_SOCIAL,
                },
                relatedFeatureNames: ['social'],
            },
            // Micro-app.
            {
                options: {
                    icon: 'view-grid-outline',
                    type: WIDGET_TYPES.MICRO_APP,
                },
                relatedFeatureNames: [],
            },
            // Post list.
            {
                options: {
                    icon: 'message-outline',
                    type: WIDGET_TYPES.COMMUNITY_POST_LIST,
                },
                relatedFeatureNames: ['community'],
            },
            // Process Maker.
            {
                options: {
                    icon: 'sitemap',
                    type: WIDGET_TYPES.PROCESS_MAKER,
                },
                relatedFeatureNames: ['process-maker'],
            },
            // Play.
            {
                options: {
                    icon: 'video',
                    type: WIDGET_TYPES.PLAY,
                },
                relatedFeatureNames: [PLAY_VIDEO_FEATURE_TOKEN],
            },
            // Playlist.
            {
                options: {
                    icon: 'play-box-multiple',
                    type: WIDGET_TYPES.PLAYLIST,
                },
                relatedFeatureNames: [PLAY_VIDEO_FEATURE_TOKEN],
            },
            // Event list
            {
                options: {
                    icon: 'calendar',
                    type: WIDGET_TYPES.EVENT_LIST,
                },
                relatedFeatureNames: [EVENTS_FF, SPACES_FEATURE_TOKEN],
                relatedFeatureRelation: 'OR',
            },
            // Rich text
            {
                options: {
                    icon: 'format-text',
                    type: WIDGET_TYPES.CONTRIBUTION,
                    isAIWidget: true,
                    isDisabled: () => {
                        const currentContent = Content.getCurrent();
                        return Boolean(
                            currentContent &&
                                Widget.findWidgetsByType(currentContent.template, WIDGET_TYPES.CONTRIBUTION).length > 0,
                        );
                    },
                    disabledHelper: Translation.translate('WIDGET_CONTRIBUTION.ONLY_ONE_PER_CONTENT_HELPER'),
                },
                relatedFeatureNames: [CONTRIBUTION_WIDGET_FF],
            },
            // Personal Feed Preview
            {
                options: {
                    icon: 'newspaper-variant-outline',
                    type: WIDGET_TYPES.PERSONAL_FEED_PREVIEW,
                },
                relatedFeatureNames: [WIDGET_PERSONAL_FEED_PREVIEW_FF],
            },
            // Learning Catalog
            {
                options: {
                    icon: 'school',
                    type: WIDGET_TYPES.LEARNING_CATALOG,
                },
                relatedFeatureNames: [LEARNING_FF],
            },
            // Team Kpi
            {
                options: {
                    icon: 'school',
                    type: WIDGET_TYPES.TEAM_KPI,
                },
                relatedFeatureNames: [LEARNING_FF],
            },
            // Learning Leaderboard
            {
                options: {
                    icon: 'school',
                    type: WIDGET_TYPES.LEARNING_LEADERBOARD,
                },
                relatedFeatureNames: [LEARNING_FF],
            },
            // Learning Certificate
            {
                options: {
                    icon: 'school',
                    type: WIDGET_TYPES.LEARNING_CERTIFICATE,
                },
                relatedFeatureNames: [LEARNING_FF],
            },
            // Summary
            {
                options: {
                    icon: 'timer-outline',
                    type: WIDGET_TYPES.SUMMARY,
                    isAIWidget: true,
                    isDisabled: () => {
                        const currentContent = Content.getCurrent();
                        return Boolean(
                            currentContent &&
                                Widget.findWidgetsByType(currentContent.template, WIDGET_TYPES.SUMMARY).length > 0,
                        );
                    },
                    disabledHelper: Translation.translate('WIDGET_SUMMARY.ONLY_ONE_PER_CONTENT_HELPER'),
                },
                relatedFeatureNames: [],
            },
        ]);
    }

    /**
     * Handles the service setup to initialize `accountChoice` root.
     *
     * @param {Function} resolve   The resolve function to resolve the setup promise.
     * @param {Function} reject    The reject function to reject the setup promise.
     * @param {Object}   response  The `service.init` response object.
     * @param {boolean}  fromCatch Whether the function is called in the `service.init` catch callback.
     * @param {string}   token     The user access token if any.
     */
    function _handleAccountChoiceSetup(resolve, reject, response, fromCatch, token) {
        if (fromCatch || angular.isUndefinedOrEmpty(response.user)) {
            reject();
        }

        User.setConnectedUser(response.user, token);

        if (angular.isDefinedAndFilled(response.customer)) {
            Customer.setCustomer(response.customer);
        }

        _setupTheme('default');

        User.listAccountChoices()
            .then(function onListAccountChoicesOrNothingSuccess() {
                _endSetup(resolve, true);
            })
            .catch(function onListAccountChoicesOrNothingError(exception) {
                reject({
                    cause: 'user.listAccountChoices',
                    // eslint-disable-next-line id-blacklist
                    error: exception,
                });
            });
    }

    /**
     * Handles the service setup to initialize `adminCustomer` root.
     *
     * @param {Function} resolve   The resolve function to resolve the setup promise.
     * @param {Function} reject    The reject function to reject the setup promise.
     * @param {Object}   response  The `service.init` response object.
     * @param {boolean}  fromCatch Whether the function is called in the `service.init` catch callback.
     * @param {string}   token     The user access token if any.
     */
    function _handleAdminCustomerSetup(resolve, reject, response, fromCatch, token) {
        User.setConnectedUser(response.user, token);

        User.init(true)
            .then(function onUserInitSuccess() {
                let customerStatusPromise = $q.resolve();
                if (fromCatch) {
                    Customer.initEmptyCustomer(angular.isUndefined($location.search().token));
                } else {
                    Customer.setCustomer(response.customer);
                    customerStatusPromise = Customer.initStatus($location.search().token);
                }

                customerStatusPromise
                    .then(function onCustomerStatusSuccess() {
                        InitialSettings.FEATURES = response.features;
                        Features.initFeaturesStatus(Customer.getCustomer());
                        _endSetup(resolve, true);
                    })
                    .catch(function onCustomerStatusError(exception) {
                        reject({
                            cause: 'customer.init_status',
                            // eslint-disable-next-line id-blacklist
                            error: exception,
                        });
                    });
            })
            .catch(reject);
    }

    /**
     * Initializes all the application services by wrapping them in a promise which will be resolved once all
     * services are initialized.
     *
     * CAUTION: If you ever refactor any of those services' init function to make it
     * asynchronous, be sure to take the `<service>.init([...])` call out of the allSyncServicesPromise array
     * and add its returned promise to the `servicesPromises` array, if you want the initialization to be blocking
     * before hidding the loader.
     *
     * @return {Promise} The promise which will be resolved once all services are initialized.
     */
    function _initAllServices() {
        const servicesPromises = [];

        const allSyncServicesPromise = $q(function deferSyncServices(resolveSyncServices) {
            // Initialize role service.
            Role.init();

            const beforeAnalyticsPromises = [];
            // Initialize the metadata service.
            beforeAnalyticsPromises.push(Metadata.init());
            // Initialize the custom content types service.
            beforeAnalyticsPromises.push(CustomContentType.init());

            // Initialize user settings and favorites services.
            $injector.get('UserSettings').init();
            UserFavorite.init();
            SocialProfile.init();

            // Initialize the feeds (and feed types) services.
            FeedType.init();
            Feed.init();
            // Initialize the content and template services.
            Template.init();
            Content.initService();
            // Initialize the widget service.
            Widget.init();
            Widget.initReduxStore();

            // Initialize the directory and directory entries services.
            Directory.init();
            DirectoryEntryUser.init();

            // Initialize the files, documents and media management services.
            Fsitems.init();
            Media.init();
            Document.init();

            // Initialize the social (community, post, comments, ...) services.
            Community.initService();
            CommunityTemplates.init();
            Post.init();

            Customer.init();

            Translation.init();
            Instance.initReduxStore();

            $q.all(beforeAnalyticsPromises).then(function onAnalyticsRequirementsCompleted() {
                Analytics.init();
            });

            // Redux Store.
            // Todo: maybe the reduxstore init should go further up, like in the mandatory services?
            ReduxStore.init();
            MainNav.initReduxStore();
            SocialAdvocacy.init();

            resolveSyncServices();
        });

        servicesPromises.push(allSyncServicesPromise);

        return $q.all(servicesPromises);
    }

    /**
     * Initializes blocking services that must be initialized synchronously.
     */
    function _initMandatoryServices() {
        // Initialize the features service after widgets (it may add some new widgets).
        Features.init();
        if (!isSocialAdvocacyPublicPage()) {
            // Setup the notifications available after the features.
            _setupNotifications();
            // Init user with langs and Featured notifications.
            User.initUserLangsAndNotifications();
            // Then init Main navigation after language definition.
            MainNav.init();
        }
    }

    /**
     * Handles the service setup to initialize `accountChoice` root.
     *
     * @param {Function} resolve   The resolve function to resolve the setup promise.
     * @param {Function} reject    The reject function to reject the setup promise.
     * @param {Object}   response  The `service.init` response object.
     * @param {boolean}  fromCatch Whether the function is called in the `service.init` catch callback.
     * @param {string}   token     The user access token if any.
     */
    function _handleAppSetup(resolve, reject, response, fromCatch, token) {
        if (fromCatch) {
            reject();
        }

        permissions(response);
        directories(response);
        headerInitState(response);

        User.setConnectedUser(response.user || {}, token);
        Customer.setCustomer(response.customer || {});
        Instance.setInstance(response.instance || {});
        Header.setDefaultHeader(response.header || {});

        if (angular.isDefinedAndFilled(response.globalWidgets)) {
            InitialSettings.GLOBAL_WIDGETS.push(...response.globalWidgets);
        }

        if (angular.isDefinedAndFilled(response.content)) {
            Content._postGet(response.content, true);
            Content.setCurrent(response.content || {});

            const customContentHeader = get(response.content, 'headerDetails');
            if (angular.isDefinedAndFilled(customContentHeader)) {
                Header.setCurrent(customContentHeader);
            }

            service.is.firstContentLoader = true;
        }

        // No need to use returned promise here since there is only synchronous work.
        User.init(false);

        const currentInstance = Instance.getInstance() || {};

        _setupTheme(currentInstance.theme);

        Style.init(response.styles || {}, false, response.parentStyle)
            .then(function onStyleInitialized() {
                const globalStyle = Style.getCurrent(Style.types.instance);

                if (angular.isDefinedAndFilled(globalStyle)) {
                    _setupStylesheets(globalStyle.stylesheets);
                } else if (angular.isDefinedAndFilled(currentInstance.customStylesheet)) {
                    _setupStylesheets([
                        {
                            kind: 'custom',
                            url: currentInstance.customStylesheet,
                        },
                    ]);
                }

                Header.init();
            })
            .catch(function onStylesInitError(exception) {
                reject({
                    cause: 'styles.init',
                    // eslint-disable-next-line id-blacklist
                    error: exception,
                });
            });

        const initialSetting = omit(response, ['instance', 'customer', 'header', 'styles', 'token', 'user']);

        if (angular.isUndefinedOrEmpty(initialSetting)) {
            reject({
                cause: 'settings.none',
                // eslint-disable-next-line id-blacklist
                error: 'No initial settings received',
            });

            return;
        }

        // Setup all the needed constants that were previously in the Jinja global letiables.
        _setupConstants(initialSetting);

        /*
         * Setup some global config (like global letiables needed by instances script, config, time,
         * ...).
         */
        _setupConfig();
        _setupTime();

        // Setup the widgets available before setting the feature.
        _setupWidgets();

        const setupSpecificPromises = [];

        setupSpecificPromises.push(_setupLang(currentInstance));

        $q.all(setupSpecificPromises)
            .then(function onInstanceConfigured() {
                // Setup favicon after langs are fetched because it's a translatable content.
                _setupFavicon(currentInstance.favicon, currentInstance.theme);

                const ConfigTheme = $injector.get('ConfigTheme');
                $anchorScroll.yOffset = ConfigTheme.HEADER_FRONT_OFFSET;

                const scripts = currentInstance.scripts || [];
                if (angular.isDefinedAndFilled(currentInstance.customScripts)) {
                    scripts.push(currentInstance.customScripts);
                }
                _setupInstanceScripts(scripts);

                _initMandatoryServices();

                _initAllServices()
                    .then(function onAllServicesInitialized() {
                        _setupAnalytics();
                        _endSetup(resolve, true);
                    })
                    .catch(function onServiceInitializationError(exception) {
                        reject({
                            cause: 'services.init',
                            // eslint-disable-next-line id-blacklist
                            error: exception,
                        });
                    });
            })
            .catch(function onServiceInitializationError(exception) {
                reject({
                    cause: 'instance.configure',
                    // eslint-disable-next-line id-blacklist
                    error: exception,
                });
            });
    }

    /**
     * Handles the service setup to initialize `login` root.
     *
     * @param {Function} resolve   The resolve function to resolve the setup promise.
     * @param {Function} reject    The reject function to reject the setup promise.
     * @param {Object}   response  The `service.init` response object.
     * @param {boolean}  fromCatch Whether the function is called in the `service.init` catch callback.
     * @param {string}   token     The user access token if any.
     */
    function _handleLoginSetup(resolve, reject, response, fromCatch, token) {
        if (fromCatch) {
            Customer.initEmptyCustomer();
        } else if (angular.isDefinedAndFilled(response.customer)) {
            Customer.setCustomer(response.customer);
        }

        if (angular.isDefinedAndFilled(response.instance)) {
            Instance.setInstance(response.instance || {});
            const currentInstance = Instance.getInstance();
            const setupSpecificPromises = [];

            setupSpecificPromises.push(_setupLang(currentInstance));
            _setupTheme(currentInstance.theme);

            if (get(Style, 'defaultParams.instance') !== currentInstance.id) {
                Style.init(response.styles || {}, false, response.parentStyle)
                    .then(function onStyleInitialized() {
                        const globalStyle = Style.getCurrent(Style.types.instance);

                        if (angular.isDefinedAndFilled(globalStyle)) {
                            _setupStylesheets(globalStyle.stylesheets);
                        } else if (angular.isDefinedAndFilled(currentInstance.customStylesheet)) {
                            _setupStylesheets([
                                {
                                    kind: 'custom',
                                    url: currentInstance.customStylesheet,
                                },
                            ]);
                        }

                        Header.init();
                    })
                    .catch(function onStylesInitError(exception) {
                        reject({
                            cause: 'styles.init',
                            // eslint-disable-next-line id-blacklist
                            error: exception,
                        });
                    });
            }

            $q.all(setupSpecificPromises).finally(function onSetupSpecificFinally() {
                _endSetup(resolve, true);
            });

            return;
        }

        _endSetup(resolve, true);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Hide the loader and the message ticker (only when all messages has been loaded and the progress is at 100%).
     *
     * @param {boolean} [immediate=false] Indicate if we want to immediatly end the loading.
     */
    function endLoading(immediate) {
        immediate = true;

        if (service.is.error) {
            return;
        }

        if (immediate) {
            _endLoading(immediate);

            return;
        }

        if (angular.isDefinedAndFilled(_cancelInterval)) {
            return;
        }

        _cancelInterval = $interval(function checkIfAllMessageHasBeenDisplayed() {
            if (angular.isUndefinedOrEmpty(_messageQueue)) {
                $timeout(_endLoading, _LOADER_HIDE_DELAY);

                if (angular.isDefinedAndFilled(_cancelInterval)) {
                    $interval.cancel(_cancelInterval);
                    _cancelInterval = undefined;
                }
            }
        }, _WAIT_FOR_MESSAGES_INTERVAL);
    }

    /**
     * Ask the settings service to execute a function when it is initialized.
     *
     * @param  {Function} cb The function to execute when the service is initialized.
     * @return {Promise}  A promise that will resolve when the function has been executed.
     */
    function execute(cb) {
        const deferred = $q.defer();

        service.executionQueue.push({
            cb,
            deferred,
        });

        return deferred.promise;
    }

    /**
     * Handle an error that occurred during the initial loading.
     *
     * @param {Object} exception The exception that occured.
     */
    function handleError(exception) {
        $log.error(exception);

        // eslint-disable-next-line id-blacklist
        service.is.error = true;

        _LOADING_CONTAINER.css('background-color', '#FAFAFA');

        _LOADING_ANIMATION.hide();
        _ERROR_SVG.show();

        _LOADING_CONTAINER.addClass('error');

        _endProgress();

        if (!angular.isObject(exception)) {
            service.message(Translation.translate(exception));

            return;
        }

        if (angular.isUndefinedOrEmpty(exception.cause) && angular.isUndefinedOrEmpty(exception.error)) {
            service.message(Translation.translate('LOADING.ERROR.UNEXPECTED'));

            return;
        }

        service.message(Translation.translate(`LOADING.ERROR.${snakeCase(exception.cause).toUpperCase()}`));

        const errorId = $exceptionHandler(exception.error, exception.cause);
        if (angular.isDefinedAndFilled(errorId)) {
            service.welcome(`${Translation.translate('GLOBAL.ERROR')} #${errorId}`);
        }
    }

    /**
     * Hide the application first loader.
     *
     * @param {boolean} [immediate=false] Indicate if we want to immediatly end the loader.
     */
    function hideFirstLoader(immediate) {
        if (immediate) {
            _removeLoader();

            return;
        }

        _LOADING_CONTAINER.fadeOut(_UNBLUR_DELAY, _removeLoader);
    }

    /**
     * Hide the message ticker.
     */
    function hideTicker() {
        if (angular.isDefinedAndFilled(_MESSAGE_TICKER)) {
            _MESSAGE_TICKER.remove();
            _MESSAGE_TICKER = undefined;
        }
    }

    /**
     * Display a loading message.
     *
     * @param {string}  text              The text to display as loading message.
     * @param {boolean} [immediate=false] Indicates if we want to immediatly display the loading message.
     */
    function message(text, immediate) {
        text = text || '';
        text = Translation.translate(text);

        if (angular.isUndefinedOrEmpty(_MESSAGE_TICKER)) {
            return;
        }

        if (!immediate && (angular.isDefinedAndFilled(_messageQueue) || angular.isObject(_messageDisplayedPromise))) {
            _messageQueue.push(text);
        } else {
            _displayMessage(text);
        }
    }

    /**
     * Setup the application after the initial settings load.
     *
     * @param  {string}  [from] Indicates from where we are setting up the application.
     *                          Possible values are: 'adminCustomer', 'login', 'accountChoice' or 'app'.
     * @return {boolean} If the application has been initialized or not.
     */
    function setupInitialSetting(from) {
        clearTimeout(window.refreshTimeoutID);
        if (service.is.initializing) {
            return service.initializationPromise;
        }

        service.is.initializing = true;

        service.initializationPromise = $q(function defer(resolve, reject) {
            let setupHandler = angular.noop;

            switch (from) {
                case 'adminCustomer':
                    setupHandler = _handleAdminCustomerSetup;

                    break;

                case 'login':
                    setupHandler = _handleLoginSetup;

                    break;

                case 'accountChoice':
                    setupHandler = _handleAccountChoiceSetup;

                    break;

                case 'app': {
                    setupHandler = _handleAppSetup;

                    break;
                }

                default:
                    reject();

                    break;
            }

            const loaded = () => {
                onInitialStateLoaded(
                    () => {
                        setupHandler(
                            resolve,
                            reject,
                            window.initRequest.responseJson || JSON.parse(window.initRequest.responseText),
                            window.initRequest.status !== 200,
                            window.USER_ACCESS_TOKEN,
                        );
                    },
                    from === 'login' || from === 'adminCustomer' || from === 'accountChoice',
                );
            };

            if (window.initRequest.readyState === 4) {
                loaded();
            } else {
                window.initRequest.addEventListener('load', loaded);
            }
        })
            .then(function onGlobalSettingInitialized() {
                if (from !== 'app') {
                    return;
                }

                // Initialize any existing tutorial.
                Tutorial.init();

                // Execute all the waiting code.
                angular.forEach(service.executionQueue, (item) => {
                    if (angular.isUndefinedOrEmpty(item) || !angular.isFunction(item.cb)) {
                        return;
                    }

                    item.deferred.resolve(item.cb());
                });
            })
            .catch(service.handleError);

        return service.initializationPromise;
    }

    /**
     * Welcome the connected user.
     * Display its name on top of the loading message with the loader.
     *
     * @param {string} [welcomeMessage=<Connected user fullname>] The message to display.
     */
    function welcome(welcomeMessage) {
        const isWelcomingUser = angular.isUndefinedOrEmpty(welcomeMessage);
        welcomeMessage = welcomeMessage || get(User.getConnected(), 'fullName');
        if (angular.isUndefinedOrEmpty([_WELCOME_MESSAGE, welcomeMessage], 'some')) {
            return;
        }

        const htmlMessage = isWelcomingUser
            ? Translation.replace('LOADING.WELCOME', 'USER_FULL_NAME', welcomeMessage)
            : Translation.translate(welcomeMessage);

        _WELCOME_MESSAGE.html($filter('safeAngular')(htmlMessage));
        _WELCOME_MESSAGE.fadeIn(_TICKER_FADE_DELAY);
    }

    /////////////////////////////

    service.endLoading = endLoading;
    service.execute = execute;
    service.handleError = handleError;
    service.hideFirstLoader = hideFirstLoader;
    service.hideTicker = hideTicker;
    service.message = message;
    service.setup = setupInitialSetting;
    service.welcome = welcome;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        _LOADING_CONTAINER.removeClass('starting');

        /* eslint-disable no-undef */
        // TODO [Clément]: Lodash aliases for UnderScoreJS compatibility. To be removed somewhere in the future!!!
        _.all = every;
        _.allKeys = keys;
        _.any = some;
        _.compose = flowRight;
        _.contains = includes;
        _.extendOwn = assign;
        _.findWhere = loFind;
        _.indexBy = keyBy;
        _.mapObject = mapValues;
        _.matcher = matches;
        _.object = zipObject;
        _.pairs = toPairs;
        _.pluck = map;
        _.where = filter;
        /* eslint-enable no-undef */
    }

    init();

    /////////////////////////////

    return service;
}

/////////////////////////////

// eslint-disable-next-line no-undef
angular.module('Services').service('Settings', SettingsService);

/////////////////////////////

export { SettingsService };
