import { useMemo } from 'react';

import { mdiFormatListBulleted, mdiFormatListNumbered } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { TypographyEditor } from '@lumapps/wrex-typography/types';
import { PLUGIN_SHORTCUTS } from '@lumapps/wrex/constants';
import { ReactEditor, useSlate, useSlateStatic } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { ToolbarItem } from '@lumapps/wrex/types';

import { ORDERED_LIST, UNORDERED_LIST } from '../../../constants';
import { WREX_LIST } from '../../../keys';
import { ListEditor, ListType } from '../../../types';

const KEYS = {
    withShortcuts: {
        [UNORDERED_LIST]: WREX_LIST.UNORDERED_LIST_WITH_SHORTCUT,
        [ORDERED_LIST]: WREX_LIST.ORDERED_LIST_WITH_SHORTCUT,
    },
    withoutShortcuts: {
        [UNORDERED_LIST]: WREX_LIST.UNORDERED_LIST_BUTTON,
        [ORDERED_LIST]: WREX_LIST.ORDERED_LIST_BUTTON,
    },
};

const ICONS = {
    [UNORDERED_LIST]: mdiFormatListBulleted,
    [ORDERED_LIST]: mdiFormatListNumbered,
};

export const useListButton = (type: ListType, otherProps?: any) => {
    const editor = useSlate() as ReactEditor & ListEditor & TypographyEditor;
    const editorStatic = useSlateStatic() as ReactEditor & ListEditor & TypographyEditor;
    const { translateAndReplace, translateKey } = useTranslate();

    const isSelected = editor.isListActive(type);

    const listButton = useMemo(() => {
        const onClick = () => {
            if (!editorStatic.selection) {
                initSelection(editorStatic, 'block');
            }
            editorStatic.setListType(editorStatic.isListActive(type) ? null : type);
            focusAt(editorStatic, editorStatic.selection);
        };
        const listButtonProps: ToolbarItem = {
            type: 'toggle-option',
            itemKey: type,
            icon: ICONS[type],
            tooltipLabel: translateAndReplace(KEYS.withShortcuts[type], {
                shortcut: PLUGIN_SHORTCUTS[type].shortcut,
            }),
            isChecked: isSelected,
            onClick,
            otherProps,
            verticalModeProps: {
                label: translateKey(KEYS.withoutShortcuts[type]),
                tooltipLabel: translateAndReplace(KEYS.withShortcuts[type], {
                    shortcut: PLUGIN_SHORTCUTS[type].shortcut,
                }),
            },
        };
        return listButtonProps;
    }, [type, translateAndReplace, isSelected, otherProps, translateKey, editorStatic]);
    return listButton;
};
