import React from 'react';

import { articleContainerIdSelector } from '@lumapps/articles/ducks/selectors';
import { eventContainerIdSelector } from '@lumapps/events/ducks/selectors';
import { mdiGoogleDrive } from '@lumapps/lumx/icons';
import { usePlayUser } from '@lumapps/play-roles';
import { UploadingCorporateVideoFormDialog } from '@lumapps/play-video-library/components/UploadingCorporateVideoFormDialog';
import { useCreateVideo } from '@lumapps/play-video-library/hooks/useCreateVideo/useCreateVideo';
import { useGoogleDriveUpload } from '@lumapps/play-video-library/hooks/useGoogleDriveUpload';
import { UploadingVideo, Video } from '@lumapps/play/api/types';
import { isPlayVideoEnabled } from '@lumapps/play/ducks/selectors';
import { FileUploadFromDrive } from '@lumapps/play/types';
import { editedPostSelector } from '@lumapps/posts/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { UploadEmployeeVideoDialog } from '../../components/UploadEmployeeVideoDialog';
import { isPlayCommunitiesFeatureEnabled } from '../../ducks/selectors';
import { COMMUNITIES } from '../../keys';
import { CommunityVideo } from '../../types';

export interface UseGooglePickerButtonProps {
    /** Callback to insert the video in the content  */
    onInsert(video: CommunityVideo): void;
}

export const useGooglePickerButton = ({ onInsert }: UseGooglePickerButtonProps) => {
    const { translateKey } = useTranslate();
    const isPlayCommunitiesEnabled = useSelector(isPlayCommunitiesFeatureEnabled);
    const isPlayEnabled = useSelector(isPlayVideoEnabled);

    const articleContainerId = useSelector(articleContainerIdSelector);
    const eventContainerId = useSelector(eventContainerIdSelector);
    const editedPost = useSelector(editedPostSelector);

    const [videoFromDrive, setVideoFromDrive] = React.useState<FileUploadFromDrive>();

    const clearFileData = () => {
        setVideoFromDrive(undefined);
    };

    const { isAllowedManagingVideos } = usePlayUser();

    /** The community ID will determine if we display the employee or corporate upload  */
    const communityId = React.useMemo(
        () => (editedPost && editedPost?.externalKey) || articleContainerId || eventContainerId,
        [articleContainerId, editedPost, eventContainerId],
    );

    const [isEmployeeVideoUploadDialogOpen, , closeEmployeeVideoUploadDialog, openEmployeeVideoUploadDialog] =
        useBooleanState(false);
    const [
        isCorporateVideoUploadingDialogOpen,
        ,
        closeCorporateVideoUploadingDialog,
        openCorporateVideoUploadingDialog,
    ] = useBooleanState(false);

    const onCreateVideoSuccess = () => {
        openCorporateVideoUploadingDialog();
    };

    const { createdVideo, upload } = useCreateVideo({
        videoFile: undefined,
        /* We don't create a video for employee videos it is handled inside the component UploadEmployeeVideoDialog */
        videoFromDrive: communityId ? undefined : videoFromDrive,
        clearFileData,
        onSuccess: onCreateVideoSuccess,
    });

    const { isGoogleUser, openGoogleDrivePicker } = useGoogleDriveUpload({
        multiselect: false,
        onFilesSelected: (files) => {
            setVideoFromDrive(files[0]);

            if (communityId) {
                openEmployeeVideoUploadDialog();
            } else {
                openCorporateVideoUploadingDialog();
            }
        },
    });

    const handleSubmitEmployee = React.useCallback(
        (video: CommunityVideo) => {
            onInsert(video);
            closeEmployeeVideoUploadDialog();
        },
        [closeEmployeeVideoUploadDialog, onInsert],
    );

    const handleSubmitCorporate = (video: Video | UploadingVideo) => {
        onInsert({ ...video, communityId: '', source: 'web' });
        closeCorporateVideoUploadingDialog();
    };

    const googlePickerButtonProps = React.useMemo(
        () => ({
            icon: mdiGoogleDrive,
            label: translateKey(COMMUNITIES.UPLOAD_VIDEO_FROM_DRIVE),
            onSelected: openGoogleDrivePicker,
        }),
        [openGoogleDrivePicker, translateKey],
    );

    const UploadEmployeeDialog = React.useMemo(
        () => (
            <UploadEmployeeVideoDialog
                isOpen={isEmployeeVideoUploadDialogOpen}
                videoFromDrive={videoFromDrive}
                communityId={communityId}
                onCancel={closeEmployeeVideoUploadDialog}
                onSubmit={handleSubmitEmployee}
            />
        ),
        [
            closeEmployeeVideoUploadDialog,
            communityId,
            handleSubmitEmployee,
            isEmployeeVideoUploadDialogOpen,
            videoFromDrive,
        ],
    );

    const UploadCorporateDialog = (
        <UploadingCorporateVideoFormDialog
            isOpen={Boolean(isCorporateVideoUploadingDialogOpen && createdVideo.id)}
            onClose={() => {
                closeCorporateVideoUploadingDialog();
                setVideoFromDrive(undefined);
            }}
            onSave={handleSubmitCorporate}
            videoFile={videoFromDrive}
            video={createdVideo}
            upload={upload}
        />
    );

    if (isPlayEnabled && isPlayCommunitiesEnabled && isGoogleUser) {
        /* When a communityId is provided we use the employee upload dialog */
        if (communityId) {
            return {
                googlePickerButtonProps,
                GoogleUploadDialog: UploadEmployeeDialog,
                isGoogleUploadDialogOpen: isEmployeeVideoUploadDialogOpen,
            };
        }

        /* When there is no communityId and we can manage the videos, we use the corporate upload dialog */
        if (isAllowedManagingVideos) {
            return {
                googlePickerButtonProps,
                GoogleUploadDialog: UploadCorporateDialog,
                isGoogleUploadDialogOpen: Boolean(isCorporateVideoUploadingDialogOpen && createdVideo.id),
            };
        }
    }

    return {
        googlePickerButtonProps: undefined,
        GoogleUploadDialog: undefined,
        isGoogleUploadDialogOpen: false,
    };
};
