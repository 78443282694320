import { useQuery } from '@lumapps/base-api/react-query';
import { AppId } from '@lumapps/constants/app';
import { isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { Route } from '@lumapps/router';

import { getLearningRoles, getMCLaunchUrl } from '../api';
import { MissionCenterPermitionRoles } from '../types';

export const useLearningRoles = () => {
    const learningEnabled = useSelector(isLearningEnabled);

    const { data: rolesData, isLoading } = useQuery<MissionCenterPermitionRoles[]>({
        queryKey: ['learningRoles'],
        queryFn: getLearningRoles,
        cacheTime: Infinity,
        staleTime: Infinity,
    });

    const { data: routeData, isLoading: isLoadingRoute } = useQuery<Route>({
        queryKey: ['learningRoute'],
        queryFn: async () => {
            const response = await getMCLaunchUrl();
            return { appId: AppId.external, path: response.launchUrl };
        },
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: !learningEnabled,
    });

    return {
        route: routeData,
        roles: rolesData,
        learningEnabled,
        isLoading,
        isLoadingRoute,
    };
};
