import { CSSProperties } from 'react';

import { MAP_SHORTHAND_PROPERTY_TO_LONGHAND_PROPERTIES } from '../constants';
import { AllStyles, ShorthandProperties } from '../types';

/**
 * Expand properties (ex: 'borderColor' => 'borderTopColor', 'borderRightColor', 'borderBottomColor', etc.)
 */
export function expandShorthandProperties(styles: AllStyles): {
    expandedProperties: CSSProperties;
    shorthandPropertyKeys: string[];
} {
    const shorthandPropertyKeys = Object.keys(
        MAP_SHORTHAND_PROPERTY_TO_LONGHAND_PROPERTIES,
    ) as Array<ShorthandProperties>;

    const expandedProperties: CSSProperties = {};

    for (const shorthandPropertyKey of shorthandPropertyKeys) {
        const expandedPropertyKeys = MAP_SHORTHAND_PROPERTY_TO_LONGHAND_PROPERTIES[shorthandPropertyKey];
        const value = styles[shorthandPropertyKey];

        if (value !== undefined && expandedPropertyKeys?.length) {
            for (const expandedPropertyKey of expandedPropertyKeys) {
                expandedProperties[expandedPropertyKey] = value as any;
            }
        }
    }

    return { expandedProperties, shorthandPropertyKeys };
}
