/* istanbul ignore file */
import React, { Suspense } from 'react';

import { ShareMenuItemLoading } from '@lumapps/share/components/ShareMenuItemLoading';

const LazyShareToSlackMenuItem = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'share-to-slack-menu-item' */
            './ShareToSlackMenuItem'
        ),
);

export const ShareToSlackMenuItem = (props: React.ComponentProps<typeof LazyShareToSlackMenuItem>) => {
    return (
        <Suspense fallback={<ShareMenuItemLoading />}>
            <LazyShareToSlackMenuItem {...props} />
        </Suspense>
    );
};
