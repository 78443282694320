import React, { useCallback, useMemo } from 'react';

import compact from 'lodash/fp/compact';
import find from 'lodash/fp/find';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import map from 'lodash/fp/map';
import property from 'lodash/fp/property';

import { FiltersDropdown } from '@lumapps/lumx-filter-and-sort/components/FiltersDropdown';
import { Alignment, FlexBox, Orientation, Placement, Size } from '@lumapps/lumx/react';
import { FilterComponentProps } from '@lumapps/widget-base/types';

import { useCommentRelevancyFilter } from '../../../hooks/PostListFilters/useCommentRelevancyFilter';
import { usePostAuthorFilter } from '../../../hooks/PostListFilters/usePostAuthorFilter';
import { usePostCategoriesFilter } from '../../../hooks/PostListFilters/usePostCategoriesFilter';
import { usePostListFilters } from '../../../hooks/PostListFilters/usePostListFilters';
import { usePostTypesFilter } from '../../../hooks/PostListFilters/usePostTypesFilter';
import { PostListFilterType, PostListFilterBlockProps } from '../../../types';
import { hasAtLeastXChoices } from '../../../utils/PostListFilters/hasAtLeastXChoices';
import { PostAuthorFilterBlock } from '../PostAuthorFilterBlock';
import { PostRelevantCommentFilterBlock } from '../PostRelevantCommentFilterBlock';
import { PostSearchQueryFilterBlock } from '../PostSearchQueryFilterBlock';
import { PostSelectMultipleFilterBlock } from '../PostSelectMultipleFilterBlock';
import { PostTypeFilterBlock } from '../PostTypeFilterBlock';

export const PostListFilters: React.FC<FilterComponentProps<Partial<PostListFilterType>>> = ({
    activeFilters: activeFiltersValues,
    availableFilters: rawAvailableFilters,
    onApply,
    onFilterChange,
    onReset,
    theme,
}) => {
    const {
        availableFilters,
        filtersValues,
        handleApply: onApplyFilters,
        handleCancel: onCancel,
        handleReset: onResetFilters,
        handleSearch,
        handleValuesChange,
    } = usePostListFilters({ activeFiltersValues, onApply, onFilterChange, onReset, rawAvailableFilters });
    const authorFilterProps = usePostAuthorFilter({ activeFiltersValues, filtersValues, handleValuesChange });
    const categoriesFilterProps = usePostCategoriesFilter({ activeFiltersValues, filtersValues, handleValuesChange });
    const relevancyFilterProps = useCommentRelevancyFilter({ activeFiltersValues, filtersValues, handleValuesChange });
    const typesFilterProps = usePostTypesFilter({ activeFiltersValues, filtersValues, handleValuesChange });

    const isSearchQueryAvailable = useMemo(
        () => Boolean(find(flow(property('type'), isEqual('searchQuery')))(availableFilters)),
        [availableFilters],
    );

    const allActiveValues = useMemo(
        () =>
            flow(
                map((filter: PostListFilterBlockProps) => map(filter.getChoiceName, filter.activeValues)),
                flatten,
                compact,
            )([
                authorFilterProps,
                categoriesFilterProps,
                relevancyFilterProps,
                typesFilterProps,
            ] as PostListFilterBlockProps[]),
        [authorFilterProps, categoriesFilterProps, relevancyFilterProps, typesFilterProps],
    );

    const filterIds = useMemo(() => map(property('type'), availableFilters), [availableFilters]);

    // when the user applies the filter(s)
    const handleApply = useCallback(() => {
        onApplyFilters(filterIds);
    }, [filterIds, onApplyFilters]);

    // when the user closes the modal without applying or clearing
    const handleClose = useCallback(() => {
        onCancel(filterIds);
    }, [filterIds, onCancel]);

    // when the user clears the filter(s)
    const handleReset = useCallback(() => {
        onResetFilters(filterIds);
    }, [filterIds, onResetFilters]);

    if (isEmpty(availableFilters)) {
        return null;
    }

    return (
        <FlexBox orientation={Orientation.horizontal} gap={Size.big} hAlign={Alignment.center} wrap>
            {isSearchQueryAvailable && (
                <PostSearchQueryFilterBlock
                    theme={theme}
                    onSearchQuery={handleSearch}
                    selectedQuery={property('searchQuery', filtersValues)}
                />
            )}
            <FiltersDropdown
                onClearClick={handleReset}
                onClose={handleClose}
                onFilter={handleApply}
                onClearAll={handleReset}
                selectedFilter={allActiveValues}
                dropdownProps={{ placement: Placement.BOTTOM_START }}
                theme={theme}
            >
                {map(({ type }) => {
                    switch (type) {
                        case typesFilterProps.filterId:
                            return (
                                hasAtLeastXChoices(typesFilterProps, 2) && (
                                    <PostTypeFilterBlock key={typesFilterProps.filterId} {...typesFilterProps} />
                                )
                            );
                        case authorFilterProps.filterId:
                            return <PostAuthorFilterBlock key={authorFilterProps.filterId} {...authorFilterProps} />;
                        case relevancyFilterProps.filterId:
                            return (
                                <PostRelevantCommentFilterBlock
                                    key={relevancyFilterProps.filterId}
                                    {...relevancyFilterProps}
                                />
                            );
                        case categoriesFilterProps.filterId:
                            return hasAtLeastXChoices(categoriesFilterProps) ? (
                                <PostSelectMultipleFilterBlock<string>
                                    key={categoriesFilterProps.filterId}
                                    {...categoriesFilterProps}
                                />
                            ) : null;
                        default:
                            return null;
                    }
                }, availableFilters)}
            </FiltersDropdown>
        </FlexBox>
    );
};
