import { ColorPalette, ColorVariant, FocusPoint } from '@lumapps/lumx/react';
import { TranslateObject } from '@lumapps/translations';
import { UserReference } from '@lumapps/widget-base/types';

export enum MediaType {
    IMAGE = 'image',
    DOCUMENT = 'document',
}

export enum MediaSource {
    GOOGLE_DRIVE = 'drive',
    LUMDRIVE = 'local',
    MICROSOFT = 'onedrive',
    COMMUNITY = 'community',
    HAUSSMANN_MEDIA = 'haussmann_media',
    STORAGE = 'storage',
}

export enum MediaSourceV2 {
    GOOGLE_DRIVE = 'google_drive',
    MICROSOFT = 'one_drive',
    HAUSSMANN_MEDIA = 'haussmann_media',
}

export type MediaSourceV2Drive = MediaSourceV2.GOOGLE_DRIVE | MediaSourceV2.MICROSOFT;

export enum MediaStatus {
    LIVE = 'live',
    DELETED = 'deleted',
}

export enum ContainerType {
    COMMUNITY = 'community',
    ORGANIZATION = 'organization',
    SITE = 'site',
}

export enum LibraryType {
    DEFAULT = 'default',
    MANAGED = 'managed',
}

export interface CommunityMedia {
    /** Name of the media. */
    name: string;
    /** The url of the media. */
    url: string;
    /** Whether the media is private or not. */
    isPrivate: boolean;
    /** Id of the media. */
    mediaId: string;
    /** Id of the media's organization. */
    organizationId: string;
    /** Id of the media's folder. */
    folderId: string;
    /** Id of the media's container. */
    containerId: string;
    /** Container type of the media */
    containerType: ContainerType;
    /** Library type of the media */
    libraryType?: LibraryType;
    /** The user id of who uploaded the media. */
    createdBy: string;
    /** The user who uploaded the media. */
    createdByDetails: UserReference;
    /** The date the media has been created. */
    createdAt: string;
    /** The user id of who updated the media. */
    updatedBy: string;
    /** The user who updated the media. */
    updatedByDetails: UserReference;
    /** The date the media has been updated. */
    updatedAt: string;
    /** The mimeType of the media. */
    mimeType: string;
    /** The size of the media, in bytes. */
    size: number;
    /** Focal point coordinates. */
    focalPoint: FocusPoint;
    /** The source of the media */
    source?: MediaSource;
    /** The height of the media, useful only for images. */
    height: number;
    /** The width of the media, useful only for images. */
    width: number;
}

export interface MediaContent {
    /** The lang of the media content. */
    lang: string;
    /** The mimeType of the media content. */
    mimeType: string;
    /** Id of the media content, use it as fallback since this field is used for old medias. */
    uid: string;
    /** Id of the media content, use it as fallback since this field is used for old medias. */
    uuid: string;
    /** The url of the media content. */
    url: string;
    /** The public url of the media content. Should be use to display a media. Can be empty for old medias. */
    servingUrl: string;
    /** The url to use when you want to download the media. */
    downloadUrl: string;
    /** Name of the media content. */
    name: string;
    /** Description of the media content. Can be used as the alt HTML attribute. */
    description?: string;
    /** The extension of the media content. */
    ext: string;
    /** The user who uploaded the media content. */
    owner: {
        fullName: string;
        email: string;
    };
    /** Id of the media content. For old medias, you should use uid and uuid as fallbacks. */
    fileId: string;
    /** The media type, to determine if it is an image or not. */
    type: MediaType;
    /** The size of the media, in bytes. */
    size: number;
    /** The height of the media, useful only for images. */
    height: number;
    /** The width of the media, useful only for images. */
    width: number;
    /** The blobkey of the media. */
    value: string;
    /** Focal point coordinates. */
    focalPoint: FocusPoint;
    /** The source of the media */
    source?: MediaSource;
    /** The Media creation date */
    createdAt?: string;
}

export interface Media {
    /** Id of the media. */
    uid: string;
    /** Unique (really unique?) id of the media. */
    uuid: string;
    /** Whether the media is a folder or not. */
    isFolder: boolean;
    /** The date the media has been updated. */
    updatedAt: string;
    /** List of the available contents for the media, each entry is a variant of the media based on a language. */
    content?: Array<MediaContent>;
    /** The email of the author of the media. */
    author: string;
    /** The content of the cropped media, if it has been cropped. */
    croppedContent?: Array<MediaContent>;
    /** Whether the component has been cropped once or not. */
    hasCroppedContent?: boolean;
    /** The provider of the media. Warning: you should always use the `from` field as a fallback for backward compatibility. */
    source: MediaSource;
    /** The provider of the media. (only used for backward compatibility) */
    from: MediaSource;
    thumbnail: Record<string, string>;
    override?: {
        /** The name of the media, by language */
        name: Record<string, string>;
        /** The description of the media, by language */
        description?: Record<string, string>;
    };
    name?: TranslateObject;
    /** Description text of the media (can be a html string) */
    description?: TranslateObject;
    /** The path to fetch the media folder */
    docPath?: string;
    /** The Media tree structure */
    path?: { depth: number; folderName: TranslateObject; folderUid: string }[];
    /** List of media tags */
    tags?: string[];
    /** The Media creation date */
    createdAt?: string;
    /** Id of the media's customer. */
    customer?: string;
}

export interface MediaIcon {
    icon: string;
    color: ColorPalette;
    colorVariant?: ColorVariant;
}

export interface UrlPatterns {
    dailymotion: Array<string>;
    vimeo: Array<string>;
    youtube: Array<string>;
}

export type VideoWebsites = keyof UrlPatterns;

export type MediaPickerSources = Exclude<
    MediaSource,
    MediaSource.COMMUNITY | MediaSource.HAUSSMANN_MEDIA | MediaSource.STORAGE
>;

export interface MediaPickerParams {
    allowedFileTypes: string[];
    allowedProviders: MediaSource[];
    multi: boolean;
}

export type FileListFieldType = 'thumbnail' | 'name' | 'tags' | 'description';
export type FieldsRecord = Partial<Record<FileListFieldType, boolean>>;

export interface UploadURL {
    mediaId: string;
    formDataField: string;
    headers: any;
    httpMethod: string;
    uploadUrl: string;
}

export interface UploadedMedia {
    url?: string;
    mediaId: string;
    organizationId: string;
    containerId: string;
    containerType: string;
    message?: string;
    id?: string;
}

export type WithBlob = {
    /**
     * Temporary URL using the blob URL scheme (used as a fallback on image upload to get around backend v1 sync issues)
     */
    blobUrl?: string;
};
