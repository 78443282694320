import { Image } from '@lumapps/wrex-image/components/blocks/Image';
import { Link } from '@lumapps/wrex-link/components/blocks/Link';
import { ListItem } from '@lumapps/wrex-list/components/blocks/ListItem';
import { OrderedList } from '@lumapps/wrex-list/components/blocks/OrderedList';
import { UnorderedList } from '@lumapps/wrex-list/components/blocks/UnorderedList';
import { Table, TD, TR } from '@lumapps/wrex-table/components/blocks';
import { CodeBlock } from '@lumapps/wrex-typography/components/blocks/CodeBlock';
import { Headline } from '@lumapps/wrex-typography/components/blocks/Headline';
import { InlineCode } from '@lumapps/wrex-typography/components/blocks/InlineCode';
import { Bold, Italic, Strikethrough } from '@lumapps/wrex-typography/components/blocks/Mark';
import { Paragraph } from '@lumapps/wrex-typography/components/blocks/Paragraph';
import { Subtitle } from '@lumapps/wrex-typography/components/blocks/Subtitle';
import { Title } from '@lumapps/wrex-typography/components/blocks/Title';

/** List of markdown Wrex elements used in generated content preview */
export const WREX_MARKDOWN_ELEMENTS = [
    Paragraph,
    Headline,
    Title,
    Subtitle,
    CodeBlock,
    InlineCode,
    Table,
    TR,
    TD,
    UnorderedList,
    OrderedList,
    ListItem,
    Link,
    Image,
];

/** List of markdown Wrex marks used in generated content preview */
export const WREX_MARKDOWN_MARKS = [Bold, Italic, Strikethrough];
