import React, { useEffect } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { WarningState } from '@lumapps/lumx-states/components/WarningState';
import { FlexBox, ProgressCircular } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { EMBEDDED_ANALYTICS_KEYS } from '../../keys';

import './index.scss';

export interface EmbedDashboardProps {
    dashboardStatus: BaseLoadingStatus;
    createDashboard: (dashboardName: string) => void;
}

const BASE_CLASSNAME = 'looker-embed-container';
const EMBED_DASHBOARD_CLASSNAME = `${BASE_CLASSNAME}__iframe-container`;

export const EmbedDashboard = ({ dashboardStatus, createDashboard }: EmbedDashboardProps) => {
    const { translateKey } = useTranslate();
    const { element, block } = useClassnames(BASE_CLASSNAME);

    useEffect(() => {
        createDashboard(`.${EMBED_DASHBOARD_CLASSNAME}`);
    }, [createDashboard]);

    return (
        <div className={block()}>
            {/* 
                We need to always have this div otherwise the sdk does nothing and the
                dashboard status never changes.
                We only modify the className so that it disapear if there is no need to display
                it. 
            */}
            {dashboardStatus === BaseLoadingStatus.loading && (
                <FlexBox vAlign="center" hAlign="center" orientation="vertical" style={{ height: '100%' }}>
                    <ProgressCircular />
                </FlexBox>
            )}
            <div className={dashboardStatus === BaseLoadingStatus.error ? '' : EMBED_DASHBOARD_CLASSNAME} />
            {dashboardStatus === BaseLoadingStatus.error && (
                <WarningState
                    className={element('warning-message')}
                    title={translateKey(EMBEDDED_ANALYTICS_KEYS.NO_DATA)}
                    subtitle={translateKey(EMBEDDED_ANALYTICS_KEYS.NO_DATA_HELPER)}
                    shouldShowImage
                />
            )}
        </div>
    );
};
