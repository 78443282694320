import React, { useMemo } from 'react';

import { mdiEmoticonOutline } from '@lumapps/lumx/icons';
import { EmojiPickerDialog } from '@lumapps/pickers/components/EmojiPickerDialog';
import { EmojiDetails } from '@lumapps/pickers/types';
import { useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { TypographyEditor } from '@lumapps/wrex-typography/types';
import { ReactEditor, Transforms, useSlateStatic } from '@lumapps/wrex/slate';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { ToolbarItem, WrexEditor } from '@lumapps/wrex/types';

import { WREX_EMOJI } from '../../../keys';
import { EmojiEditor } from '../../../types';

export const useEmojiButton = (otherProps?: any) => {
    const editorStatic = useSlateStatic() as WrexEditor<EmojiEditor & TypographyEditor>;
    const { translateKey } = useTranslate();

    const [isEmojiPickerOpen, , closeEmojiPicker, openEmojiPicker] = useBooleanState(false);
    const [currentAnchorRef, setCurrentAnchorRef] = React.useState<HTMLElement | null>(null);
    const isDisabled = !editorStatic.isEmojiAllowed();

    const emojiButton = useMemo(() => {
        const onInsertEmoji = (emojiObject: EmojiDetails) => {
            Transforms.insertText(editorStatic, emojiObject.unicode || emojiObject.emoji.unicode);
            ReactEditor.focus(editorStatic);
            closeEmojiPicker();
        };

        const onEmojiButtonSelect = () => {
            if (!editorStatic.selection) {
                initSelection(editorStatic, 'inline');
            }
            setCurrentAnchorRef(
                ReactEditor.toDOMRange(editorStatic, editorStatic.selection || initSelection(editorStatic, 'inline'))
                    .startContainer.parentElement,
            );
            openEmojiPicker();
        };

        const onCancelEmojiPicker = () => {
            ReactEditor.focus(editorStatic);
            closeEmojiPicker();
        };

        const insertEmojiPicker = (
            <EmojiPickerDialog
                isOpen={isEmojiPickerOpen}
                onEmojiClick={onInsertEmoji}
                onClose={onCancelEmojiPicker}
                anchorRef={{
                    current: currentAnchorRef,
                }}
            />
        );

        const emojiButtonProps: ToolbarItem = {
            itemKey: 'emoji',
            type: 'option',
            icon: mdiEmoticonOutline,
            tooltipLabel: translateKey(WREX_EMOJI.EMOJI_TOOLTIP),
            'aria-disabled': isDisabled,
            onClick: onEmojiButtonSelect,
            otherProps,
            verticalModeProps: {
                label: translateKey(WREX_EMOJI.EMOJI_BUTTON),
                tooltipLabel: undefined,
            },
        };

        return { insertEmojiPicker, emojiButtonProps };
    }, [
        isEmojiPickerOpen,
        currentAnchorRef,
        translateKey,
        isDisabled,
        otherProps,
        editorStatic,
        closeEmojiPicker,
        openEmojiPicker,
    ]);

    return emojiButton;
};
