import React from 'react';

import { useSurveyBlock } from '../../hooks/useSurveyBlock';
import { ClientComputedSurveyProps } from '../../types';
import { SurveyBlock } from '../SurveyBlock';

export const ClientComputedSurvey: React.FC<ClientComputedSurveyProps> = ({ legacyWidgetId, properties, uuid }) => {
    const surveyBlockProps = useSurveyBlock({ legacyWidgetId, properties, uuid });

    if (!surveyBlockProps) {
        return null;
    }

    return <SurveyBlock {...surveyBlockProps} />;
};
