import React from 'react';

import uniqueId from 'lodash/uniqueId';

import {
    GENERATED_SUBTITLE_ID_BASE,
    SUBTITLE_GENERATION_MODE,
    SUBTITLE_PREVIEW_STATUS,
    SUBTITLE_STATUS,
} from '../../constants';
import type { SubtitleGenerationMode, SubtitlePreviewStatus, SubtitleStatus, VideoSubtitle } from '../../types';
import { useTranslatedLanguages } from '../useTranslatedLanguages/useTranslatedLanguages';

export interface UseSubtitlesType {
    subtitles: VideoSubtitle[];
    onChange: (field: any, payload: any) => void;
    currentTranscriptLanguage?: string;
}

export const useSubtitles = ({ subtitles, onChange, currentTranscriptLanguage }: UseSubtitlesType) => {
    /** Adds a new subtitle to the existing list */
    const addSubtitle = React.useCallback(
        (
            lang: string,
            file?: File,
            generationMode: SubtitleGenerationMode = SUBTITLE_GENERATION_MODE.MANUAL,
            status: SubtitleStatus = SUBTITLE_STATUS.WAITING,
        ) => {
            const newSubtitle: VideoSubtitle = {
                status,
                previewStatus: SUBTITLE_PREVIEW_STATUS.NEW,
                filename: file?.name,
                id: uniqueId(GENERATED_SUBTITLE_ID_BASE),
                language: lang,
                createdAt: new Date().toUTCString(),
                createdById: '',
                updatedAt: new Date().toUTCString(),
                updatedById: '',
                generationMode,
                file,
            };
            onChange('subtitles', [newSubtitle, ...subtitles]);
        },
        [onChange, subtitles],
    );

    /** Adds a new automatics translations to the existing list */
    const addTranslations = React.useCallback(
        (languages: string[], translationId: string) => {
            const newSubtitles: VideoSubtitle[] = languages.map((lang) => {
                return {
                    status: SUBTITLE_STATUS.WAITING,
                    previewStatus: SUBTITLE_PREVIEW_STATUS.NEW,
                    id: uniqueId(GENERATED_SUBTITLE_ID_BASE),
                    language: lang,
                    generationMode: SUBTITLE_GENERATION_MODE.AI_TRANSLATION,
                    createdAt: new Date().toUTCString(),
                    createdById: '',
                    updatedAt: new Date().toUTCString(),
                    updatedById: '',
                    sourceId: translationId,
                };
            });

            onChange('subtitles', [...newSubtitles, ...subtitles]);
        },
        [onChange, subtitles],
    );

    // Finds the current subtitle in the list and replace it with payload
    const findSubtitleAndReplace = (
        subtitleList: VideoSubtitle[],
        currentSubtitle: VideoSubtitle,
        payload: Partial<VideoSubtitle>,
    ) => {
        return subtitleList.map((sub) =>
            sub.id === currentSubtitle.id
                ? {
                      ...payload,
                      id: sub.id,
                      language: sub.language,
                      generationMode: sub.generationMode,
                  }
                : sub,
        );
    };

    // Updates the video with the new subtitle list
    const updateSubtitle = (payload: Partial<VideoSubtitle>, subtitle: VideoSubtitle) => {
        const newList = subtitle && findSubtitleAndReplace(subtitles, subtitle, payload);
        onChange('subtitles', newList);
    };

    /** Replace an existing subtitle */
    const handleReplaceSubtitle = (file: File, subtitle: VideoSubtitle, generatedStatus: SubtitlePreviewStatus) => {
        const newList = findSubtitleAndReplace(subtitles, subtitle, {
            ...subtitle,
            file,
            filename: file.name,
            previewStatus: generatedStatus,
        });

        onChange('subtitles', newList);
    };

    /** Transcripts still need to be deleted (xhr) before requesting a new one */
    const handleDelete = (subtitle: VideoSubtitle) => {
        const payload: Partial<VideoSubtitle> = {
            status: SUBTITLE_STATUS.DELETED,
            language: '', // Reset language so that it’s no longer considered as a used language
        };
        updateSubtitle(payload, subtitle);
    };

    const { sortSubtitlesByLanguageLabel, switchLanguageCode } = useTranslatedLanguages();

    const displayedSubtitles = React.useMemo(() => {
        /** Remove deleted subtitles from the view */
        const subtitlesWithoutDeleted = subtitles.filter((sub) => sub.status !== SUBTITLE_STATUS.DELETED);

        /** Then sort them by label */
        return currentTranscriptLanguage ? sortSubtitlesByLanguageLabel(subtitlesWithoutDeleted) : [];
    }, [currentTranscriptLanguage, sortSubtitlesByLanguageLabel, subtitles]);

    /** Store the list of languages used by subtitles. Filter out the transcript since I should be
     * able to upload a subtitle manually, even though a transcript already exists in this language
     */
    const usedSubtitlesLanguages = React.useMemo(
        () =>
            displayedSubtitles
                .filter((sub) => sub.generationMode !== SUBTITLE_GENERATION_MODE.SPEECH_TO_TEXT) // Filter the transcript out so that the language still exists for the manual subtitles
                .map((sub) => sub.language && switchLanguageCode(sub.language)),
        [displayedSubtitles, switchLanguageCode],
    );

    const transcript = React.useMemo(
        () =>
            subtitles.find(
                (subtitle) =>
                    subtitle.generationMode === SUBTITLE_GENERATION_MODE.SPEECH_TO_TEXT &&
                    subtitle.status !== SUBTITLE_STATUS.DELETED,
            ),
        [subtitles],
    );

    const transcriptSubtitleWasAdded = React.useMemo(
        () => transcript?.status === SUBTITLE_STATUS.WAITING,
        [transcript?.status],
    );

    return {
        /**
         * Adds a 'deleted' status to the subtitle so that we can filter it out of the subtitle list,
         * but still dispatch a DELETE api request
         */
        handleDelete,
        /** Finds the subtitle in the subtitle list and replace it with payload */
        handleReplaceSubtitle,
        /** Transcript of the video */
        transcript,
        /** The list of subtitles that we actually display on screen (deleted files are stored but not displayed) */
        displayedSubtitles,
        /** Whether the transcript subtitle has been added */
        transcriptSubtitleWasAdded,
        /** The list of languages already taken by subtitles so that we can update the language options */
        usedSubtitlesLanguages,
        /** Callback to be used when selecting adding a new subtitle. Updates the video's subtitle list
         * with necessary info before saving the video editing dialog
         */
        addSubtitle,
        /** Callback to be used when selecting a subtitle to generate more. Updates the video's subtitle list
         * with necessary info before saving the video editing dialog
         */
        addTranslations,
    };
};
