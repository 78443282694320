import React from 'react';

import { cache, CACHE_TYPE } from '@lumapps/cache';
import { classnames } from '@lumapps/classnames';
import {
    SideNavigationItem as NavigationItem,
    SideNavigationItemProps as NavigationProps,
    Emphasis,
} from '@lumapps/lumx/react';
import { Route, useRouteMatching, useLinkProps, useLocation } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { scrollIntoView } from '@lumapps/utils/browser/scrollIntoView';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';
import { useIsInView } from '@lumapps/utils/hooks/useIsInView';

import { useSideBarNavigationDataAttributes } from '../../../hooks/useSideBarNavigationDataAttributes';
import { SideNavigationMenu } from './SideNavigationMenu';

import './index.scss';

export interface SideNavigationItemProps extends Omit<NavigationProps, 'toggleButtonProps'> {
    label: string;
    className?: string;
    dynamicIcon?: string;
    pathMatchForHighlighting?: string;
    route?: Route;
    id?: string;
    openInNewTab?: boolean;
    /**
     * Each route provided in excludedRoutes array will prevent item to be selected when matching
     * Useful when 2 sub-menu items have similar routes but one is too "weak". E.g:
     * - item "Campaigns > All campaigns" = /admin/campaigns
     * - item "Campaigns > Settings" = /admin/campaigns/settings
     * Providing settings route to excludedRoutes of "All campaigns" item prevents isSelected state when navigating to settings route
     */
    excludedRoutes?: Route[];
}

const CACHE_SCROLL_ID = 'back-office-side-nav-scroll';
export const CLASSNAME = 'side-navigation-item';
/**
 * Side Navigation Item that can display a single item or a section with multiple links
 *
 * @family Back office
 * @param SideNavigationItemProps
 * @returns SideNavigationItem
 */
const SideNavigationItem: React.FC<SideNavigationItemProps> = ({
    className,
    children,
    isOpen = false,
    route,
    dynamicIcon = '',
    linkProps = {},
    icon,
    openInNewTab = false,
    pathMatchForHighlighting,
    excludedRoutes = [],
    ...props
}) => {
    const { translateKey } = useTranslate();
    const { pathname } = useLocation();
    const isRouteMatching = useRouteMatching(route, pathMatchForHighlighting || `${route?.path}/*`);
    const isCurrentRoute = excludedRoutes.map((route) => route.path).includes(pathname) ? false : isRouteMatching;

    const { iconPath } = useDynamicIcon({ icon: dynamicIcon });
    const dataAttributes = useSideBarNavigationDataAttributes({ route });
    const { onClick, ...routerLinkProps } = useLinkProps({ route, target: linkProps.target, openInNewTab });
    const itemRef = React.useRef<HTMLLIElement>(null);
    const { ref: inViewRef, inView: isItemInView } = useIsInView();

    const classname = classnames(CLASSNAME, className);
    const iconToUse = dynamicIcon ? iconPath : icon || undefined;

    /**
     * Effect in order to determine whether we should scroll the side navigation item
     * into view. We only want to execute this once, since after the user has navigated (scroll or clicked on another item)
     * we no longer want to scroll anything into view.
     *
     * We use in memory cache in order to determine whether the side nav was scrolled or not.
     */
    React.useEffect(() => {
        const wasScrolled = cache.retrieve(CACHE_SCROLL_ID, CACHE_TYPE.MEMORY);
        inViewRef(itemRef.current);

        if (isCurrentRoute && !isItemInView && !wasScrolled && itemRef.current) {
            scrollIntoView(itemRef.current, { scrollMode: 'if-needed' });

            cache.store(CACHE_SCROLL_ID, true, CACHE_TYPE.MEMORY);
        }
    }, [inViewRef, isCurrentRoute, isItemInView]);
    /**
     * We check whether there are more navigation items passed in as children in order to determine
     * whether we need to add the open/close behaviour or not. If the prop is defined, it means that
     * we need to display more links on this section and we need to allow the user to open and close
     * the section to see the other links, therefore rendering the SideNavigationMenu component
     */
    const hasChildren = Boolean(children);

    // If the item in only a container, do not display it when there are no children
    if (!hasChildren && !routerLinkProps.href) {
        return null;
    }

    if (hasChildren) {
        return (
            <SideNavigationMenu
                className={className}
                baseClassName={CLASSNAME}
                isOpen={isOpen}
                icon={iconToUse}
                {...props}
            >
                {children}
            </SideNavigationMenu>
        );
    }

    return (
        <NavigationItem
            {...props}
            ref={itemRef}
            className={classname}
            emphasis={Emphasis.medium}
            isSelected={isCurrentRoute}
            linkProps={{
                ...linkProps,
                ...routerLinkProps,
            }}
            icon={iconToUse}
            toggleButtonProps={{ label: translateKey(GLOBAL.TOGGLE) }}
            {...dataAttributes}
            onClick={onClick}
        />
    );
};

SideNavigationItem.displayName = 'SideNavigationItem';

export { SideNavigationItem };
