import React, { useMemo } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { ImageLightbox, useImageLightbox } from '@lumapps/lumx-images/components/ImageLightbox';
import { mdiLink, mdiOpenInNew } from '@lumapps/lumx/icons';
import { Badge, Button, Icon, Mosaic, Text, Theme, Thumbnail, ThumbnailProps, useTheme } from '@lumapps/lumx/react';
import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { useDetectMobileAppWebView } from '@lumapps/utils/hooks/useDetectMobileAppWebView';
import { useDetectMobileOS } from '@lumapps/utils/hooks/useDetectMobileOS';
import { useEventListener } from '@lumapps/utils/hooks/useEventListener';
import { ElementRender } from '@lumapps/wrex/types';

import { CLASSNAME, IMAGE_GROUP, IMAGE_WIDTHS } from '../../../constants';
import { ImageGroupElement } from '../../../types';

import './index.scss';

export interface EnhancedImageProps {
    imgRef?: React.Ref<HTMLImageElement>;
    isInEditionMode?: boolean;
}

/**
 * Render image group as single image or mosaic images
 */
export const EnhancedImage: ElementRender<ImageGroupElement, HTMLDivElement, EnhancedImageProps> = (props) => {
    const { className, elementRef, element, children, isInEditionMode, imgRef, ...forwardedProps } = props;
    const theme = useTheme() || Theme.light;
    const { element: elementClass, block: blockClass } = useClassnames(CLASSNAME);
    const isMobileAppWebView = useDetectMobileAppWebView();
    const mobileOS = useDetectMobileOS();
    const isMobileApp = isMobileAppWebView || !!mobileOS;
    const { get } = useDataAttributes(CLASSNAME);

    const [shouldForceFullLink, setShouldForceFullLink] = React.useState(false);

    const linkShortBadgeRef = React.useRef(null);
    const linkFullBadgeRef = React.useRef<HTMLAnchorElement>(null);

    const { images = [], title, isLoading } = element ?? {};

    const isFullWidth = element?.width === IMAGE_WIDTHS.fullWidth;
    const isHalfWidth = element?.width === IMAGE_WIDTHS.halfWidth;

    const thumbnails = useMemo(
        () =>
            images.map(({ src = '', alt = '', tempSrc, height, width }): ThumbnailProps => {
                const image = tempSrc || makeSecuredMediaURLRelative(src) || '';
                const imgProps = { height, width };

                return { image, title, alt, imgProps };
            }),
        [images, title],
    );

    const width = isFullWidth ? undefined : thumbnails[0]?.imgProps?.width;

    const { getTriggerProps, imageLightboxProps } = useImageLightbox(thumbnails);

    const getContextTriggerProps = (activeImageIndex: number) => {
        if (isInEditionMode) {
            return undefined;
        }
        return getTriggerProps({ activeImageIndex });
    };

    const onFocusChange = (event: FocusEvent) => {
        // Handle focus of link badge with JS because of visibility change causing focus lost
        if (event.type === 'focusout') {
            setShouldForceFullLink(false);
        } else {
            setShouldForceFullLink(true);
            if (linkFullBadgeRef.current) {
                linkFullBadgeRef.current.focus();
            }
        }
    };

    useEventListener('focus', onFocusChange, linkShortBadgeRef);
    useEventListener('focusout', onFocusChange, linkFullBadgeRef);

    return (
        <figure
            {...forwardedProps}
            ref={elementRef}
            className={blockClass(
                {
                    'is-loading': !!isLoading,
                    multiple: thumbnails.length > 1,
                    'full-width': isFullWidth,
                    'half-width': isHalfWidth,
                },
                className,
            )}
        >
            {thumbnails.length === 1 && (
                <div className={elementClass('thumbnail-wrapper')}>
                    <Thumbnail
                        className={elementClass('thumbnail')}
                        imgRef={imgRef}
                        {...thumbnails[0]}
                        {...getContextTriggerProps(0)}
                        contentEditable={false}
                        imgProps={{
                            ...thumbnails[0].imgProps,
                            // Inline width style to reverse space while loading
                            style: { width },
                            draggable: isInEditionMode ? 'false' : undefined,
                        }}
                    />
                    {images[0].link && (
                        <>
                            <Badge
                                color={theme === Theme.light ? 'light' : 'dark'}
                                className={elementClass('link-badge-short', {
                                    'force-full-link': isMobileApp || shouldForceFullLink,
                                })}
                                contentEditable={false}
                                tabIndex={0}
                                ref={linkShortBadgeRef}
                            >
                                <Icon icon={mdiLink} />
                            </Badge>
                            <Button
                                className={elementClass('link-badge-full', {
                                    'force-full-link': isMobileApp || shouldForceFullLink,
                                    'is-mobile': isMobileApp,
                                })}
                                color={theme === Theme.light ? 'light' : 'dark'}
                                leftIcon={mdiOpenInNew}
                                size={isMobileApp ? 'm' : 's'}
                                href={images[0].link}
                                target="_blank"
                                contentEditable={false}
                                ref={linkFullBadgeRef}
                                {...get({ element: 'link' })}
                            >
                                <Text as="span" typography="caption" truncate color="primary" colorVariant="N">
                                    {images[0].link}
                                </Text>
                            </Button>
                        </>
                    )}
                </div>
            )}
            {thumbnails.length > 1 && (
                <Mosaic
                    className={elementClass('mosaic')}
                    style={{ width: '100%' }}
                    thumbnails={thumbnails.map((thumbnail, index) => ({
                        ...thumbnail,
                        ...getContextTriggerProps(index),
                    }))}
                    contentEditable={false}
                    theme={theme}
                />
            )}
            {children}
            {!isInEditionMode && title && (
                <figcaption className={elementClass('caption')} style={{ width }}>
                    {title}
                </figcaption>
            )}
            <ImageLightbox {...imageLightboxProps} />
        </figure>
    );
};
EnhancedImage.displayName = IMAGE_GROUP;
