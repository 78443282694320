/* istanbul ignore file */
import React, { useCallback, useMemo } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FULL_DATE_SIX_FRACTIONAL_SECONDS } from '@lumapps/constants';
import { EVENTS } from '@lumapps/events/keys';
import { currentLanguageSelector } from '@lumapps/languages';
import { Filters } from '@lumapps/lumx-filter-and-sort/components/Filters';
import { FiltersAndSortContainer } from '@lumapps/lumx-filter-and-sort/components/FiltersAndSortContainer';
import { SearchField } from '@lumapps/lumx-filter-and-sort/components/SearchField';
import { Sorters } from '@lumapps/lumx-filter-and-sort/components/Sorters';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { getMoment } from '@lumapps/utils/time/getMoment';
import { FilterComponentProps } from '@lumapps/widget-base/types';
import { getFilterByName } from '@lumapps/widget-base/utils/getFilterByName';

import './index.scss';

const CLASSNAME = 'event-list-filters';

interface EventListFiltersType {
    searchTerm?: string;
    fromDate?: string;
    toDate?: string;
    listOrderDir?: 'asc' | 'desc';
}

const sortChoices = {
    asc: { id: 'asc', label: GLOBAL.OLDEST },
    desc: { id: 'desc', label: GLOBAL.LATEST },
};

export const EventListFilters: React.FC<FilterComponentProps<EventListFiltersType>> = ({
    onFilterChange,
    onApply,
    activeFilters,
    availableFilters,
    theme,
}) => {
    const language = useSelector(currentLanguageSelector);
    const { element } = useClassnames(CLASSNAME);

    const debouncedOnChange = useCallback(
        (filterValue: any, filterName: keyof EventListFiltersType) => {
            onFilterChange({ [filterName]: filterValue });
            onApply();
        },
        [onFilterChange, onApply],
    );

    const { translateKey } = useTranslate();

    const currentSort = useMemo(() => {
        const activeSortValue = activeFilters?.listOrderDir;
        if (activeSortValue && sortChoices[activeSortValue]) {
            return sortChoices[activeSortValue];
        }
        const initialSortValue = getFilterByName('LIST_ORDER_DIR', availableFilters)?.defaultValue;
        if (initialSortValue && Object.keys(sortChoices).includes(initialSortValue)) {
            return sortChoices[initialSortValue as keyof typeof sortChoices];
        }

        return sortChoices.asc;
    }, [activeFilters, availableFilters]);

    const filters = [
        {
            id: 'date',
            type: 'DATE',
            config: {
                title: translateKey(GLOBAL.DATE),
                language,
            },
        },
    ];

    return (
        <FiltersAndSortContainer>
            {getFilterByName('SEARCH_TERM', availableFilters) && (
                <SearchField
                    theme={theme}
                    label={translateKey(EVENTS.SEARCH_FOR_EVENT)}
                    value={activeFilters?.searchTerm || ''}
                    onSearch={(value) => debouncedOnChange(value, 'searchTerm')}
                />
            )}

            {getFilterByName('FROM_DATE', availableFilters) && getFilterByName('TO_DATE', availableFilters) ? (
                <Filters
                    selectedFilters={activeFilters}
                    filters={filters}
                    onFilter={({ date }) => {
                        const appliedFilters: Partial<EventListFiltersType> = {};

                        if (date) {
                            appliedFilters.fromDate = date.from
                                ? getMoment()(date.from)
                                      .hours(0)
                                      .minutes(0)
                                      .seconds(0)
                                      .milliseconds(0)
                                      .utc()
                                      .format(FULL_DATE_SIX_FRACTIONAL_SECONDS)
                                : undefined;

                            appliedFilters.toDate = date.to
                                ? getMoment()(date.to)
                                      .hours(0)
                                      .minutes(0)
                                      .seconds(0)
                                      .milliseconds(0)
                                      .utc()
                                      .format(FULL_DATE_SIX_FRACTIONAL_SECONDS)
                                : undefined;
                        } else {
                            appliedFilters.fromDate = undefined;
                            appliedFilters.toDate = undefined;
                        }

                        onFilterChange(appliedFilters);
                        onApply();
                    }}
                    filterDropdownProps={{
                        theme,
                        dropdownProps: {
                            usePortal: true,
                        },
                    }}
                />
            ) : null}

            {getFilterByName('LIST_ORDER_DIR', availableFilters) ? (
                <Sorters
                    className={element('filter')}
                    sort={currentSort.id}
                    theme={theme}
                    onChange={(id) => debouncedOnChange(id, 'listOrderDir')}
                    choices={Object.values(sortChoices)}
                    isChangingOrderEnabled={false}
                />
            ) : undefined}
        </FiltersAndSortContainer>
    );
};
