import React from 'react';

import { analyticsSettingsRoute } from '@lumapps/analytics-settings/routes';
import { askAiAnalyticsRoute } from '@lumapps/ask-ai-analytics/routes';
import { learningAnalyticsRoute } from '@lumapps/learning-analytics/routes';
import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiChartLine } from '@lumapps/lumx/icons';
import { mobileAnalyticsRoute } from '@lumapps/mobile-analytics/routes';
import { platformAnalytics, platformAnalyticsRouteMatcher } from '@lumapps/platform-analytics/routes';
import { searchAnalyticsRoute } from '@lumapps/search-analytics/routes';
import { siteAnalytics, siteAnalyticsRouteMatcher } from '@lumapps/site-analytics/routes';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export type AnalyticsSideNavigationProps = {
    hasSearchAnalyticsAccess: boolean;
    hasSiteAnalyticsAccess: boolean;
    hasExtendedAnalyticsAccess: boolean;
    hasMobileAnalyticsAccess: boolean;
    hasAskAiAccess: boolean;
    hasLearningAnalyticsAccess: boolean;
    id: string;
};

export const AnalyticsSideNavigation: React.FC<AnalyticsSideNavigationProps> = ({
    hasSearchAnalyticsAccess,
    hasSiteAnalyticsAccess,
    hasExtendedAnalyticsAccess,
    hasMobileAnalyticsAccess,
    hasAskAiAccess,
    hasLearningAnalyticsAccess,
    id,
}) => {
    const { translateKey } = useTranslate();
    const sideNavigationPropsList: SideNavigationItemProps[] = [];

    // Adding sections depending on the user access
    if (hasAskAiAccess) {
        sideNavigationPropsList.push({
            label: translateKey(GLOBAL.ASK_AI),
            route: askAiAnalyticsRoute,
        });
    }

    if (hasSearchAnalyticsAccess) {
        sideNavigationPropsList.push({
            label: translateKey(GLOBAL.SEARCH),
            route: searchAnalyticsRoute,
        });
    }

    if (hasMobileAnalyticsAccess) {
        sideNavigationPropsList.push({
            label: translateKey(GLOBAL.MOBILE),
            route: mobileAnalyticsRoute,
        });
    }

    if (hasLearningAnalyticsAccess) {
        sideNavigationPropsList.push({
            label: translateKey(GLOBAL.LEARNING),
            route: learningAnalyticsRoute,
        });
    }

    if (hasSiteAnalyticsAccess) {
        sideNavigationPropsList.push({
            label: translateKey(GLOBAL.SITE),
            route: siteAnalytics(),
            pathMatchForHighlighting: siteAnalyticsRouteMatcher,
        });
    }

    if (hasExtendedAnalyticsAccess) {
        sideNavigationPropsList.push({
            label: translateKey(GLOBAL.PLATFORM),
            route: platformAnalytics(),
            pathMatchForHighlighting: platformAnalyticsRouteMatcher,
        });
    }

    if (hasExtendedAnalyticsAccess) {
        sideNavigationPropsList.push({
            label: translateKey(GLOBAL.SETTINGS),
            route: analyticsSettingsRoute,
        });
    }

    if (sideNavigationPropsList.length === 0) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(GLOBAL.ANALYTICS)} icon={mdiChartLine} id={id}>
            {sideNavigationPropsList.map((props) => (
                <SideNavigationItem key={props.label} {...props} />
            ))}
        </SideNavigationItem>
    );
};
