import React, { useRef } from 'react';

import { isImpressionsAnalyticsEnabled } from '@lumapps/analytics-tracking/ducks/selectors';
import { classnames } from '@lumapps/classnames';
import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { CommunityView } from '@lumapps/communities/constants';
import { getCurrentContentAsCommunity } from '@lumapps/communities/ducks/selectors';
import { RenderingType } from '@lumapps/communities/types';
import { CommunityPickerDialog } from '@lumapps/community-pickers/components/CommunityPickerDialog';
import { useSearchForMovingPicker } from '@lumapps/community-pickers/hooks/useSearchForMovingPicker';
import { get as getConstants } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { ContentTypes } from '@lumapps/content-types/types';
import { useDataAttributes } from '@lumapps/data-attributes';
import { ActionMenu } from '@lumapps/lumx-menu/components/ActionMenu';
import {
    mdiDotsVertical,
    mdiEye,
    mdiPin,
    mdiPinOff,
    mdiPencil,
    mdiTrashCan,
    mdiArrowRightThick,
    mdiMessageAlertOutline,
    mdiChartLine,
} from '@lumapps/lumx/icons';
import { Emphasis, AlertDialog, Theme, Placement, Text, Typography, Kind } from '@lumapps/lumx/react';
import { PostAnalyticsDialog } from '@lumapps/posts/components/PostAnalyticsDialog';
import { editPost } from '@lumapps/posts/ducks/thunks/editPost';
import { usePostAnalyticsDialog } from '@lumapps/posts/hooks/usePostAnalyticsDialog';
import { POSTS } from '@lumapps/posts/keys';
import { RefreshPosts } from '@lumapps/posts/types';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { ReportTunnel } from '@lumapps/reports/components/ReportTunnel/ReportTunnel';
import { isReportFeatureEnabled } from '@lumapps/reports/ducks/selectors';
import { useReport } from '@lumapps/reports/hooks/useReport';
import { REPORTS, REPORT_ALREADY_REPORTED } from '@lumapps/reports/keys';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { MenuItemSaveButton } from '@lumapps/user-saved-items/components/MenuItemSaveButton';
import { isConnected } from '@lumapps/user/ducks/selectors';
import { MediaPreview, SiteReference } from '@lumapps/widget-base/types';

import { usePostDelete } from '../../hooks/usePostDelete';
import { usePostMove } from '../../hooks/usePostMove';
import { usePostPin } from '../../hooks/usePostPin';
import { WIDGET_POST_LIST } from '../../keys';
import { CustomAction } from '../../types';

import './PostActions.scss';

export interface PostActionsProps {
    /** Light/Dark theme */
    theme?: Theme;
    /** Whether the post is pinned or not */
    isPostPinned?: boolean;
    /** The post id. */
    postId: string;
    /** The community slug. */
    communitySlug: string;
    /** The community rendering type */
    renderingType?: RenderingType;
    /** The site reference */
    site: Partial<SiteReference>;
    /** The community id */
    communityId: string;
    /** Whether the current user can pin/unpin the post or not. */
    canPin?: boolean;
    /** Whether the current user can display the post or not, useful for legacy. */
    canDisplay?: boolean;
    /** Whether the current user can edit the post or not. */
    canEdit?: boolean;
    /** Whether the current user can delete the post or not. */
    canDelete?: boolean;
    /**  Whether the current user can move the post or not. */
    canMove?: boolean;
    /**  Whether the post can be moved to a space or not. Calculated by the frontend. */
    canMoveToSpace?: boolean;
    /** Whether we are in a community context or not. */
    isInCommunityContext?: boolean;
    /**
     * Custom actions to add into the dropdown, actions will be added on top of the list,
     * separated from other actions by a divider.
     */
    additionalActions?: CustomAction[];
    /** The classname to apply on the icon button */
    className?: string;
    /**
     * The refresh function to called when we want to refresh
     * posts, in order to override the layout behaviour. Used by the legacy
     * to handle post lists refresh in a legacy context.
     */
    refreshLegacyPosts?: RefreshPosts;
    /**
     * this props is ment to manage the fact that in some list (ex: reports) button should be displayed
     * only as preview and mustn't be clickable
     */
    isPreviewOnly?: boolean;
    files: MediaPreview[];
    images: MediaPreview[];

    openPostDialog(): void;
}

const CLASSNAME = 'post-actions';

export const PostActions: React.FC<PostActionsProps> = ({
    theme,
    isPostPinned,
    postId,
    communityId,
    communitySlug,
    renderingType,
    site,
    canDisplay = true,
    canPin,
    canEdit,
    canDelete,
    canMove,
    canMoveToSpace,
    isInCommunityContext,
    additionalActions,
    className,
    refreshLegacyPosts,
    isPreviewOnly,
    files,
    images,
    openPostDialog,
}) => {
    const dispatch = useDispatch();
    const anchorRef = useRef(null);
    const reportBtnRef = useRef(null);
    const { get } = useDataAttributes('post-actions');
    const { translateKey } = useTranslate();
    const constants = getConstants();
    const isWebview = constants.applicationId === AppId.webview;
    const isReportEnabled = useSelector(isReportFeatureEnabled);
    const userCanSaveItem = useSelector(isConnected);
    const isImpressionsAnalyticsFeatureFlagEnabled = useSelector(isImpressionsAnalyticsEnabled);
    const { ReportTunnelProps, onReportButtonClick, isResourceAlreadyReported } = useReport({
        resourceId: postId,
        resourceType: 'post',
        reportBtnRef,
    });

    const onPostEdit = () => dispatch(editPost({ postId, openPostDialog }));
    const currentCommunity = useSelector(getCurrentContentAsCommunity);
    const currentCommunityId = currentCommunity?.id ?? communityId;

    const {
        isPostAnalyticsDialogOpen,
        postAnalyticsDashboardName,
        postAnalyticsDashboardUrl,
        openPostAnalyticsDialog,
        closeAnalyticsDialog,
    } = usePostAnalyticsDialog(postId);
    const { deleteConfirmDialogProps, openDeleteConfirm } = usePostDelete(postId, refreshLegacyPosts);
    const { unpinConfirmDialogProps, openUnpinConfirm, onPinPost } = usePostPin(
        postId,
        currentCommunityId,
        refreshLegacyPosts,
    );
    const { communityPickerProps, openCommunityPicker, closeCommunityPicker, moveBtnRef } = usePostMove({
        postId,
        refreshOverride: refreshLegacyPosts,
        files,
        images,
        canMoveToSpace,
    });

    const showButton =
        // Do not show the triple dot button if post is in previewOnly mode
        !isPreviewOnly &&
        // Do not show the triple dot button on the webview app
        !isWebview;

    return (
        <>
            {showButton ? (
                <ActionMenu
                    theme={theme}
                    className={classnames(className, CLASSNAME)}
                    emphasis={Emphasis.low}
                    icon={mdiDotsVertical}
                    ref={anchorRef}
                    label={translateKey(WIDGET_POST_LIST.POST_ACTIONS_LABEL)}
                    triggerProps={{ popoverProps: { placement: Placement.BOTTOM_END } }}
                    {...get({ action: 'open', element: 'dropdown-btn' })}
                >
                    {additionalActions?.length && (
                        <>
                            {additionalActions.map((action) => (
                                <ActionMenu.Item
                                    key={action.label}
                                    icon={action.icon}
                                    onClick={action.onClick}
                                    {...action.listItemProps}
                                >
                                    {action.label}
                                </ActionMenu.Item>
                            ))}

                            <ActionMenu.Divider />
                        </>
                    )}

                    {/* Save item button */}
                    {userCanSaveItem && (
                        <MenuItemSaveButton
                            resourceId={postId}
                            resourceType="post"
                            {...get({ action: 'save', element: 'action-btn' })}
                        />
                    )}

                    {canDisplay && (
                        <ActionMenu.Item
                            as={CommunityLink}
                            to={{
                                id: communityId,
                                identifier: postId,
                                slug: communitySlug,
                                type: ContentTypes.POST,
                                instance: { id: site?.siteId, slug: site?.slug },
                                view: CommunityView.post,
                                renderingType,
                            }}
                            icon={mdiEye}
                            {...get({ action: 'display', element: 'action-btn' })}
                        >
                            {translateKey(WIDGET_POST_LIST.DISPLAY_POST)}
                        </ActionMenu.Item>
                    )}
                    {isImpressionsAnalyticsFeatureFlagEnabled && (
                        <ActionMenu.Item
                            icon={mdiChartLine}
                            onClick={openPostAnalyticsDialog}
                            {...get({ action: 'analytics', element: 'action-btn' })}
                        >
                            {translateKey(POSTS.POST_ANALYTICS)}
                        </ActionMenu.Item>
                    )}

                    {canEdit && (
                        <ActionMenu.Item
                            icon={mdiPencil}
                            onClick={onPostEdit}
                            {...get({ action: 'edit', element: 'action-btn' })}
                        >
                            {translateKey(POSTS.EDIT_POST)}
                        </ActionMenu.Item>
                    )}

                    {canMove && (
                        <ActionMenu.Item
                            ref={moveBtnRef as React.RefObject<HTMLLIElement>}
                            icon={mdiArrowRightThick}
                            onClick={openCommunityPicker}
                            {...get({ action: 'move', element: 'action-btn' })}
                        >
                            {translateKey(WIDGET_POST_LIST.MOVE_POST)}
                        </ActionMenu.Item>
                    )}

                    {canDelete && (
                        <ActionMenu.Item
                            icon={mdiTrashCan}
                            onClick={openDeleteConfirm}
                            color="red"
                            {...get({ action: 'delete', element: 'action-btn' })}
                        >
                            {translateKey(WIDGET_POST_LIST.DELETE_POST)}
                        </ActionMenu.Item>
                    )}

                    {isReportEnabled && (
                        <>
                            <ActionMenu.Divider />

                            <ActionMenu.Item
                                ref={reportBtnRef}
                                onClick={onReportButtonClick}
                                icon={mdiMessageAlertOutline}
                                isDisabled={isResourceAlreadyReported}
                                tooltipLabel={isResourceAlreadyReported && translateKey(REPORT_ALREADY_REPORTED.post)}
                                {...get({ action: 'report', element: 'action-btn' })}
                            >
                                {translateKey(REPORTS.REPORT)}
                            </ActionMenu.Item>
                        </>
                    )}
                    {canPin && isInCommunityContext && (
                        <>
                            <ActionMenu.Divider />

                            {isPostPinned ? (
                                <ActionMenu.Item
                                    icon={mdiPinOff}
                                    onClick={openUnpinConfirm}
                                    {...get({ action: 'unpin', element: 'action-btn' })}
                                >
                                    {translateKey(WIDGET_POST_LIST.UNPIN_POST)}
                                </ActionMenu.Item>
                            ) : (
                                <ActionMenu.Item
                                    icon={mdiPin}
                                    onClick={onPinPost}
                                    {...get({ action: 'pin', element: 'action-btn' })}
                                >
                                    {translateKey(WIDGET_POST_LIST.PIN_POST)}
                                </ActionMenu.Item>
                            )}
                        </>
                    )}
                </ActionMenu>
            ) : null}

            {canPin && isPostPinned && isInCommunityContext && (
                <AlertDialog
                    id="unpin-confirm-dialog"
                    confirmProps={{
                        label: translateKey(GLOBAL.OK),
                        onClick: unpinConfirmDialogProps.onConfirm,
                        ...get({
                            action: 'ok',
                            element: 'unpin-confirm-dialog',
                        }),
                    }}
                    cancelProps={{
                        label: translateKey(GLOBAL.CANCEL),
                        onClick: unpinConfirmDialogProps.onClose,
                        ...get({
                            action: 'cancel',
                            element: 'unpin-confirm-dialog',
                        }),
                    }}
                    kind={Kind.warning}
                    isOpen={unpinConfirmDialogProps.isOpen}
                    isLoading={unpinConfirmDialogProps.isLoading}
                    title={translateKey(WIDGET_POST_LIST.UNPIN_CONFIRM_TITLE)}
                >
                    <Text as="p" typography={Typography.body1}>
                        {translateKey(WIDGET_POST_LIST.UNPIN_CONFIRM_DESCRIPTION)}
                    </Text>
                </AlertDialog>
            )}
            {canDelete && (
                <AlertDialog
                    id="delete-confirm-dialog"
                    confirmProps={{
                        label: translateKey(GLOBAL.OK),
                        onClick: deleteConfirmDialogProps.onConfirm,
                        ...get({
                            action: 'ok',
                            element: 'delete-confirm-dialog',
                        }),
                    }}
                    cancelProps={{
                        label: translateKey(GLOBAL.CANCEL),
                        onClick: deleteConfirmDialogProps.onClose,
                        ...get({
                            action: 'cancel',
                            element: 'delete-confirm-dialog',
                        }),
                    }}
                    isOpen={deleteConfirmDialogProps.isOpen}
                    isLoading={deleteConfirmDialogProps.isLoading}
                    title={translateKey(WIDGET_POST_LIST.DELETE_CONFIRM_TITLE)}
                    kind={Kind.error}
                >
                    <Text as="p" typography={Typography.body1}>
                        {translateKey(WIDGET_POST_LIST.DELETE_CONFIRM_DESCRIPTION)}
                    </Text>
                </AlertDialog>
            )}
            {isImpressionsAnalyticsFeatureFlagEnabled && (
                <PostAnalyticsDialog
                    isOpen={isPostAnalyticsDialogOpen}
                    onClose={closeAnalyticsDialog}
                    dashboadName={postAnalyticsDashboardName}
                    dashboardUrl={postAnalyticsDashboardUrl}
                />
            )}
            <CommunityPickerDialog
                {...communityPickerProps}
                parentElement={moveBtnRef}
                onVisibilityChange={(open) => {
                    if (!open && !communityPickerProps.isOpen) {
                        closeCommunityPicker();
                    }
                }}
                excludeList={[communityId]}
                communityPickerHook={useSearchForMovingPicker}
                confirmButtonProps={{ children: translateKey(GLOBAL.NEXT) }}
            />
            <ReportTunnel {...ReportTunnelProps} />
        </>
    );
};
