import BaseApi from '@lumapps/base-api';

import { CreateSubtitlePayloadDTO, UpdateSubtitleDTO } from '../types';
import type { CreateSubtitleResponse } from './types';

/**
 * Play Subtitles API
 */
export const subtitlesApi = new BaseApi({
    path: 'videos',
    version: BaseApi.versions.v2,
});

/**
 * SUBTITLES
 */
export async function postSubtitles(videoId: string, data: CreateSubtitlePayloadDTO, signal?: AbortSignal) {
    const { data: createSubtitlesResult } = await subtitlesApi.post<CreateSubtitleResponse>(`/${videoId}/subtitles`, {
        ...data,
        signal,
    });
    return createSubtitlesResult;
}

export async function updateSubtitle(videoId: string, subtitleId: string, data: UpdateSubtitleDTO) {
    await subtitlesApi.patch<void>(`/${videoId}/subtitles/${subtitleId}`, data);
}

export async function deleteSubtitle(videoId: string, subtitleId: string) {
    await subtitlesApi.delete<void>(`/${videoId}/subtitles/${subtitleId}`);
}

export async function downloadSubtitle(videoId: string, subtitleId: string) {
    const { data: subtitleContent } = await subtitlesApi.get<any>(`/${videoId}/subtitles/${subtitleId}/download`);
    return subtitleContent;
}

/**
 * TRANSCRIPTS
 */

/**
 * @deprecated
 */
export const createTranscript = async ({ videoId }: { videoId: string }) => {
    const { headers } = await subtitlesApi.post(`/${videoId}/transcripts`, {});
    const contentLocationHeaderInfo = headers['content-location']?.split('/');
    const transcriptId = contentLocationHeaderInfo[contentLocationHeaderInfo.length - 1];
    return transcriptId;
};

/**
 * @deprecated
 */
export const deleteTranscript = async ({ videoId, transcriptId }: { videoId: string; transcriptId: string }) => {
    await subtitlesApi.delete<void>(`/${videoId}/transcripts/${transcriptId}`);
};
