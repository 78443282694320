import { TranslateObject } from '@lumapps/translations';
import { LegacyWidgetProperties } from '@lumapps/widget-base/types';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

export interface BlockIframeProperties {
    /* display or not the borders on the iframe */
    displayBorders?: boolean;
    /* The height of the widget & the height of the iframe */
    height?: number;
    /* allow or not to scroll in the iframe */
    scrollDisabled?: boolean;
    /* add or not the contentId in the iframe SRC URL */
    sendContentId?: boolean;
    /* add or not the userIdentity in the iframe SRC URL */
    sendUserIdentity?: boolean;
    /* The title of the widget */
    title?: TranslateObject | string;
    /* The url to reach in iframe */
    url?: TranslateObject;
    /* The width of the iframe */
    width?: number;
    /* Text displayed when url is missing but empty message option checked */
    helper?: TranslateObject;
}

export enum PlayerAspectRatio {
    wide = '16/9',
    ntsc = '4/3',
    square = '1/1',
    portrait = '2/3',
    auto = 'auto',
}
export interface BlockIframeProps {
    /** iframe id */
    id: string;
    /* The iframe properties */
    properties?: BlockIframeProperties;
    /* The iframe ratio */
    aspectRatio?: PlayerAspectRatio;
    /* Default height of the widget if no height is provided through properties */
    defaultHeight?: string;
}

export interface LegacyIframeProperties {
    /** display or not the borders on the iframe */
    displayBorders?: boolean;
    /** The height of the widget & the height of the iframe */
    height?: number;
    /** allow or not to scroll in the iframe */
    scrollDisabled?: boolean;
    /** add or not the contentId in the iframe SRC URL */
    sendContentId?: boolean;
    /** add or not the userIdentity in the iframe SRC URL */
    sendUserIdentity?: boolean;
    /** The url to reach in iframe */
    url?: TranslateObject;
    /** The width of the iframe */
    width?: number;
    /** Text displayed when url is missing but empty message option checked only in the designer */
    helper?: LegacyWidgetProperties['helper'];
    /** The box Empty Message */
    noResults?: LegacyWidgetProperties['noResults'];
}

export interface ClientComputedIframeProps extends ClientComputedProps {
    properties?: LegacyIframeProperties;
}
