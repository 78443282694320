import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { get as getConfig } from '@lumapps/constants';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Alignment, FlexBox, Orientation, Size, Text, Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { getChildStyleProperties, getStyleProperties } from '@lumapps/style/ducks/selectors';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { LinkEditor } from '@lumapps/wrex-link/types';
import { isLink } from '@lumapps/wrex-link/utils/isLink';
import { ReactEditor, useSlateStatic } from '@lumapps/wrex/slate';
import { ToolbarItem } from '@lumapps/wrex/types';

import { TypographyEditor } from '../../../types';
import { ColorCircle } from './ColorCircle';

export const CLASSNAME = 'color-section';
const BASIC_COLOR_WHITE = '#FFFFFF';
const BASIC_COLOR_BLACK = '#000000';
const NUMBER_OF_MAX_COLUMNS = 6;

/* Default palette of colors */
const DEFAULT_PALETTE = getConfig().defaultColors;
/* Params for check if string is hexadecimal */
const IS_HEXADECIMAL = /^#[\dA-F]{6}$/i;

export interface ColorOptions {
    color: string | null;
    label?: string;
    default?: boolean;
}

export const useColorSelector = (
    theme: Theme = Theme.light,
    isReadOnly: boolean | undefined = undefined,
    withTooltip: boolean = true,
): ToolbarItem => {
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes('color-selector');
    const { translateKey } = useTranslate();
    const childStylesProperties = useSelector(getChildStyleProperties);
    const stylesProperties = useSelector(getStyleProperties);
    const properties = childStylesProperties?.colors ? childStylesProperties : stylesProperties;
    /* Checking which palette to use custom or default */
    const colorPalette = properties?.colors || DEFAULT_PALETTE;
    /* Verification if the base colors exist in the custom palette, if no, then add them */
    const newCustomPalette = colorPalette.filter((color: string) => IS_HEXADECIMAL.test(color));

    const getColorLabel = (color: string) => {
        if (color === properties?.primary) {
            return translateKey(GLOBAL.PRIMARY_COLOR);
        }

        if (color === properties?.accent) {
            return translateKey(GLOBAL.SECONDARY_COLOR);
        }

        return color;
    };

    const colorPaletteOptions: ColorOptions[] = [
        {
            color: null,
            label: translateKey(GLOBAL.DEFAULT_COLOR),
            default: true,
        },
        ...newCustomPalette.map((color: string) => ({
            color,
            label: getColorLabel(color),
            default: false,
        })),
    ];

    const editorStatic = useSlateStatic() as ReactEditor & TypographyEditor & LinkEditor;

    const defaultColor = theme === Theme.light ? BASIC_COLOR_BLACK : BASIC_COLOR_WHITE;
    const currentColor = editorStatic.getCurrentColor();
    const currentColorOption = colorPaletteOptions.find((option) => option.color === currentColor);

    // Disable selector if no selection or if current block is not recognized.
    const [linkNode] =
        (editorStatic.selection && Array.from(editorStatic.nodes({ at: editorStatic.selection, match: isLink }))) || [];
    const isDisabled = isReadOnly || !editorStatic.isMarkAllowed() || Boolean(linkNode);

    const selectButtonLabel = `${translateKey(GLOBAL.COLOR)}: ${
        currentColorOption?.label || translateKey(GLOBAL.DEFAULT_COLOR)
    }`;

    return {
        type: 'submenu',
        itemKey: 'color-submenu',
        verticalModeProps: {
            label: (
                <FlexBox orientation={Orientation.horizontal} hAlign={Alignment.center} gap={Size.regular}>
                    <ColorCircle
                        color={currentColor || defaultColor}
                        label={currentColor || translateKey(GLOBAL.DEFAULT_COLOR)}
                        isSelected={false}
                    />
                    <Text as="span">{translateKey(GLOBAL.COLOR)}</Text>
                </FlexBox>
            ),
        },
        label: <ColorCircle color={currentColor || defaultColor} label={selectButtonLabel} isSelected={false} />,
        'aria-disabled': isDisabled,
        tooltipLabel: withTooltip ? selectButtonLabel : undefined,
        childrenOptions: [
            {
                type: 'section',
                itemKey: '',
                otherProps: {
                    role: 'grid',
                    className: block(),
                    columns: NUMBER_OF_MAX_COLUMNS,
                },
                childrenOptions: colorPaletteOptions.map((option) => {
                    const isSelected = (option.default && !currentColor) || option.color === currentColor;
                    const colorLabel = option.default ? translateKey(GLOBAL.DEFAULT_COLOR) : (option.label as string);

                    return {
                        type: 'option',
                        tooltipLabel: colorLabel,
                        label: (
                            <ColorCircle
                                color={option.default ? defaultColor : option.color}
                                label={colorLabel}
                                isSelected={isSelected}
                            />
                        ),
                        itemKey: colorLabel,
                        onClick: () => {
                            editorStatic.changeColor(option.color);
                        },
                        otherProps: {
                            ...get({ element: 'option', action: 'select-color' }),
                        },
                    };
                }),
            },
        ],
        otherProps: {
            triggerProps: {
                movingFocusOptions: { direction: 'both', loopAround: { row: 'next-end', col: 'next-loop' } },
            },
            className: element('menu-item'),
            ...get({ element: 'option', action: 'select-color' }),
        },
    } as ToolbarItem;
};
