import React from 'react';

import { SearchField } from '@lumapps/lumx-filter-and-sort/components/SearchField';
import { Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_POST_LIST } from '../../../keys';

interface PostSearchQueryFilterBlockProps {
    onSearchQuery: (query: string) => void;
    selectedQuery?: string;
    theme: Theme;
}

export const PostSearchQueryFilterBlock: React.FC<PostSearchQueryFilterBlockProps> = ({
    onSearchQuery,
    selectedQuery = '',
    theme,
}) => {
    const { translateKey } = useTranslate();

    return (
        <SearchField
            label={translateKey(WIDGET_POST_LIST.FILTERS_SEARCH_FOR_A_POST)}
            onSearch={onSearchQuery}
            value={selectedQuery}
            theme={theme}
        />
    );
};
