/* istanbul ignore file */
import { Route, AppId, createPageRoute } from '@lumapps/router';

export const contentTypeRoute: Route = createPageRoute({
    slug: 'content-types',
    legacyId: 'app.admin.customContentType',
    appId: AppId.backOffice,
});

export const adminContentType = (): Route => contentTypeRoute;
