export enum REACTIONS_COMMENTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=59685972
     */
    COMMENT_BUTTON_ARIA = 'FRONT.CONTENTS.COMMENT_BUTTON.ARIA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=404451694
     */
    COMMENT_BUTTON_LINK_ARIA_LABEL = 'REACTIONS.COMMENT_BUTTON_LINK_ARIA_LABEL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452497
     */
    EDIT_COMMENT = 'COMMENT_EDIT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454076
     */
    COMMENTS = 'WIDGET_TYPE_COMMENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=269382841
     */
    HIGHLIGHT = 'COMMENTS.HIGHLIGHT_COMMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=269382926
     */
    UNHIGHLIGHT = 'COMMENTS.UNHIGHLIGHT_COMMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=67871437
     */
    MENU_ARIA_LABEL = 'FRONT.WIDGET.REACTIONS_COMMENTS.MENU.ARIA_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68116091
     */
    REPLIES_DISPLAY_MORE = 'FRONT.COMMENTS.REPLIES.DISPLAY_MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68116699
     */
    REPLIES_DISPLAY_LESS = 'FRONT.COMMENTS.REPLIES.DISPLAY_LESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=269382986
     */
    HIGHLIGHTED_COMMENT_TITLE = 'COMMENTS.HIGHLIGHTED_COMMENT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73549105
     */
    HIDE_THIS_COMMENT_DESCRIPTION = 'FRONT.COMMENTS.HIDE.CONFIRM_DIALOG.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74749897
     */
    TRANSLATE_ACTION = 'FRONT.AUTO_TRANSLATION.TRANSLATE_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74819299
     */
    TRANSLATION_LOADING = 'FRONT.AUTO_TRANSLATION.TRANSLATION_LOADING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74821183
     */
    TRANSLATION_SUCCESS = 'FRONT.AUTO_TRANSLATION.TRANSLATION_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74821196
     */
    SEE_ORIGINAL = 'FRONT.AUTO_TRANSLATION.SEE_ORIGINAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=71226570
     */
    REPLY_EDITOR_PLACEHOLDER = 'FRONT.COMMENTS.REPLY.EDITOR.PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=71226382
     */
    COMMENT_EDITOR_PLACEHOLDER = 'FRONT.COMMENTS.COMMENT.EDITOR.PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268679080
     */
    DELETE_COMMENT = 'COMMENTS.DELETE_CONFIRM_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268679093
     */
    DELETE_COMMENT_DESCRIPTION = 'COMMENTS.DELETE_CONFIRM_DIALOG_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=269298008
     */
    COMMENT_DELETED = 'COMMENTS.COMMENT_DELETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=288458527
     */
    NOTIFICATION_AVAILABLE_SOON = 'COMMENTS.NOTIFICATION_AVAILABLE_SOON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=288458541
     */
    NOTIFICATION_AVAILABLE_SOON_DESCRIPTION = 'COMMENTS.NOTIFICATION_AVAILABLE_SOON_DESCRIPTION',
}
