/* istanbul ignore file */
import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

const LazyShareToSlackDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'share-to-slack-dialog' */
            './ConnectedShareToSlackDialog'
        ),
);

export const ShareToSlackDialog = ({ isOpen, ...props }: React.ComponentProps<typeof LazyShareToSlackDialog>) => (
    <DelayedSuspense shouldLoad={isOpen} isDialog>
        <LazyShareToSlackDialog isOpen={isOpen} {...props} />
    </DelayedSuspense>
);
