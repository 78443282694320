import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { margin, padding } from '@lumapps/classnames';
import { FlexBox, Typography, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SEGMENT_EMPTY_DESC_PLACEHOLDER } from '../../constants';
import { Segment } from '../../types';
import { SegmentSizeField } from '../SegmentSizeField/SegmentSizeField';

interface SegmentInformationProps {
    segment: Segment;
}

/**
 * Display description and size of provided segment
 * @param segment
 * @returns <SegmentInformation />
 */
export const SegmentInformation = ({ segment }: SegmentInformationProps) => {
    const { translateKey, translateObject } = useTranslate();

    const segmentDescriptionTitle = translateKey(GLOBAL.DESCRIPTION);
    const segmentSizeTitle = translateKey(GLOBAL.SIZE);

    return (
        <FlexBox orientation="vertical" fillSpace>
            <div className={margin('bottom', 'regular')}>
                <Text as="h2" className={padding('bottom', 'regular')} typography={Typography.subtitle1}>
                    {segmentDescriptionTitle}
                </Text>
                <Text as="p" typography={Typography.body1}>
                    {isEmpty(segment.description?.translations)
                        ? SEGMENT_EMPTY_DESC_PLACEHOLDER
                        : translateObject(segment.description?.translations)}
                </Text>
            </div>
            <div>
                <Text as="h2" className={padding('bottom', 'regular')} typography={Typography.subtitle1}>
                    {segmentSizeTitle}
                </Text>
                <SegmentSizeField
                    canCalculateSize={false}
                    layout="line"
                    currentSize={segment.preview?.approxCount}
                    lastUpdateAt={segment.preview?.computedAt}
                    mode="popover"
                />
            </div>
        </FlexBox>
    );
};
