import { useMemo } from 'react';

import { codeBlock } from '@lumapps/lumx/custom-icons';
import { useTranslate } from '@lumapps/translations';
import { ListEditor } from '@lumapps/wrex-list/types';
import { ReactEditor, useSlate, useSlateStatic } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { ToolbarItem } from '@lumapps/wrex/types';

import { CODE_BLOCK, PARAGRAPH } from '../../../constants';
import { WREX_TYPOGRAPHY } from '../../../keys';
import { TypographyEditor } from '../../../types';

export const useCodeBlockButton = (otherProps?: any) => {
    const editor = useSlate() as ReactEditor & ListEditor & TypographyEditor;
    const editorStatic = useSlateStatic() as ReactEditor & ListEditor & TypographyEditor;

    const isCurrentNodeACodeBlock = editor.getBlockType() === CODE_BLOCK;
    const { translateKey } = useTranslate();

    const isSelected = isCurrentNodeACodeBlock;
    const isDisabled = !editor.isCodeBlockAllowed();

    return useMemo((): ToolbarItem => {
        return {
            type: 'toggle-option',
            itemKey: CODE_BLOCK,
            icon: codeBlock,
            tooltipLabel: translateKey(WREX_TYPOGRAPHY.CODEBLOCK_LABEL),
            onClick() {
                const at = editorStatic.selection || editorStatic.getLastSelection();

                if (editorStatic.getBlockType() === CODE_BLOCK) {
                    editorStatic.changeBlockType(PARAGRAPH, at);
                } else {
                    editorStatic.changeBlockType(CODE_BLOCK, at);
                }
                focusAt(editorStatic, editorStatic.selection);
            },
            'aria-disabled': isDisabled,
            isChecked: isSelected,
            verticalModeProps: {
                label: translateKey(WREX_TYPOGRAPHY.CODEBLOCK_LABEL),
                tooltipLabel: undefined,
            },
            otherProps,
        };
    }, [editorStatic, isDisabled, isSelected, otherProps, translateKey]);
};
