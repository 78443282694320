import { type Connector } from 'lumapps-sdk-js';

import { Route, createPageRoute, AppId, createEntityManagementRoutes } from '@lumapps/router';

import type { InstalledExtensionAPIProps } from './legacy-types';

const platformExtensionsBaseSlug = 'extensions/installed-extensions';

const platformExtensionsRoute: Route = createPageRoute({
    slug: platformExtensionsBaseSlug,
    legacyId: 'app.admin.platform-extensions',
    appId: AppId.backOffice,
});

const platformExtensionDetailRoute: Route = createPageRoute({
    appId: AppId.backOffice,
    slug: `${platformExtensionsBaseSlug}/:extensionId`,
});

const { createRoute, editRoute } = createEntityManagementRoutes({
    appId: AppId.backOffice,
    slug: `${platformExtensionsBaseSlug}/:extensionId/connectors`,
});

const platformExtensionDetail = ({ extensionId, anchor }: { extensionId: string; anchor?: string }): Route => {
    return {
        ...platformExtensionDetailRoute,
        anchor,
        params: {
            extensionId,
        },
    };
};

const platformExtensionNewConnector = ({ extensionId }: { extensionId: string }): Route => {
    return {
        ...createRoute,
        params: {
            extensionId,
        },
    };
};

export interface ExtensionConnectorEditParams {
    /** Connector id being edited */
    connectorId: Connector['id'];
    /** Extension id that own the connector */
    extensionId: InstalledExtensionAPIProps['id'];
}

const platformExtensionEditConnector = ({
    extensionId,
    connectorId,
    isPreset = false,
}: {
    extensionId: string;
    connectorId: string;
    isPreset?: boolean;
}): Route => {
    return {
        ...editRoute,
        params: {
            extensionId,
            id: connectorId,
        },
        query: { ...(Boolean(isPreset) && { isPreset: 'true' }) },
    };
};

export {
    platformExtensionsRoute,
    platformExtensionDetail,
    platformExtensionDetailRoute,
    platformExtensionNewConnector,
    createRoute as platformExtensionNewConnectorRoute,
    platformExtensionEditConnector,
    editRoute as platformExtensionEditConnectorRoute,
};
