import {
    isNewInterfaceRoutingEnabled as isNewInterfaceRoutingEnabledSelector
} from '@lumapps/content-layout/ducks/selectors';
import { notAllowedRoute } from '@lumapps/router';
import { createUrl, getParameterByName, addQueryParamsToUrl, removeParamFromUrl } from '@lumapps/router/utils';
import { setup as setupBackOfficeStates, setupCustomerAdmin as setupCustomerAdminStates } from 'back/states';
import {
    ENABLE_ANGULAR_DEBUG_INFO,
    ENABLE_LIST_XHR_QUEUE,
    MAX_XHR_QUEUE_DELAY,
    MAX_XHR_QUEUE_SIZE,
} from 'common/config';
import { setupApp as setupAppStates, setupLogin as setupLoginStates } from 'common/states';
import { PERMISSIONS, setupRouter } from 'common/utils/states-utils';
import endsWith from 'lodash/endsWith';
import lodashFind from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import set from 'lodash/set';
import some from 'lodash/some';
import startsWith from 'lodash/startsWith';

import { setCurrentPage } from '@lumapps/router/ducks/thunks';
import { actions } from '@lumapps/router/ducks/slice';

import { FRONT_OFFICE } from './app';
import { setup as setupFrontOfficeStates } from './states';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { PERMISSIONS as SA_PERMISSIONS } from 'components/components/social-advocacy/constants';
import { getLumappsPublicUniqueId, setLumappsPublicUniqueId } from '@lumapps/analytics-tracking/utils';

/////////////////////////////
//                         //
//    Private attributes   //
//                         //
/////////////////////////////

/**
 * This contains the states where we want to check for unsaved content when we leave it.
 *
 * @type {Array}
 */
const _CHECK_FOR_UNSAVED_CHANGES_STATES = [
    'app.front.content-create',
    'app.front.content-create-without-custom-type',
    'app.front.content-edit',
    'app.front.content-duplicate',
];

/**
 * Contains the requests currently beeing executed.
 * Each request is indexed by its callId.
 *
 * @type {Object}
 */
const _executingRequest = {};

/**
 * Contains the list of enqueued requests.
 *
 * @type {Array}
 */
const _requestsQueue = [];

let frontVersion;
let lastRedirectedUrlWithFrontVersion;

/////////////////////////////
//                         //
//     Public functions    //
//                         //
/////////////////////////////

/**
 * Check if a content has changed and we should be notified.
 *
 * @param  {Service} Content            The content service.
 * @param  {Service} CommunityTemplates The community template service.
 * @param  {string}  stateName          The current state name.
 * @param  {Object}  contentTypes       The content types.
 * @param  {boolean} isTemplate         Indicates if we are editing a template (content or community).
 * @return {boolean} If the content has changed and we should be notified.
 */
function contentHasChanged(Content, CommunityTemplates, stateName, contentTypes, isTemplate) {
    if (!includes(_CHECK_FOR_UNSAVED_CHANGES_STATES, stateName)) {
        return false;
    }

    const currentContent = Content.getCurrent();

    if (angular.isUndefinedOrEmpty(currentContent)) {
        return false;
    }

    if (currentContent.type === contentTypes.COMMUNITY && isTemplate) {
        return CommunityTemplates.hasChanges();
    }

    return Content.hasChanges();
}

/**
 * Indicates if a request/response must be passed/processed through/after a queue.
 *
 * @param  {Object}  config The configuration of the request/response.
 * @return {boolean} If it should be queued.
 */
function shouldBeQueued(config) {
    if (angular.isUndefinedOrEmpty(config)) {
        return false;
    }

    const isListCall = includes(config.url, '/list');
    const isIgnoredUrl =
        includes(config.url, '.html') ||
        includes(config.url, 'tutorial/list') ||
        includes(config.url, 'notification/list') ||
        includes(config.url, 'favorite/list') ||
        includes(config.url, 'feed/list') ||
        includes(config.url, 'metric');
    const callId = get(config, 'data.callId', get(config, 'params.callId'));
    const hasNoCallId = angular.isUndefinedOrEmpty(callId);

    return !(!ENABLE_LIST_XHR_QUEUE || !isListCall || isIgnoredUrl || hasNoCallId);
}

/**
 * When receiving a response to a XHR, trigger the next query in queue (if any).
 *
 * @param  {Object}  response The response received from the XHR.
 * @param  {Angular} $timeout The Angular's timeout service.
 * @return {Object}  The reponse untouched.
 */
function triggerNextRequest(response, $timeout) {
    if (!shouldBeQueued(response.config)) {
        return response;
    }

    const callId = get(response, 'config.data.callId', get(response, 'config.params.callId'));

    if (angular.isDefinedAndFilled(_executingRequest[callId])) {
        delete _executingRequest[callId];

        let nextRequest;
        while (angular.isUndefinedOrEmpty(nextRequest)) {
            if (angular.isUndefinedOrEmpty(_requestsQueue)) {
                break;
            }

            nextRequest = _requestsQueue.shift();

            if (angular.isDefined(nextRequest.timeout)) {
                if (nextRequest.timeout === false) {
                    nextRequest = undefined;
                } else {
                    $timeout.cancel(nextRequest.timeout);
                    delete nextRequest.timeout;
                }
            }
        }

        if (angular.isDefinedAndFilled(nextRequest)) {
            const nextRequestCallId = get(nextRequest.config, 'data.callId', get(nextRequest.config, 'params.callId'));

            _executingRequest[nextRequestCallId] = nextRequest.config;
            nextRequest.promise.resolve();
        }
    }

    return response;
}

/////////////////////////////

/**
 * The default configuration of the Front-Office application.
 *
 * @param {Provider} $analyticsProvider         The AngularJS analytics provider.
 * @param {Provider} $compileProvider           The AngularJS compile provider.
 * @param {Provider} $controllerProvider        The AngularJS controller provider.
 * @param {Provider} $httpProvider              The AngularJS http provider.
 * @param {Provider} $localStorageProvider      The AngularJS local storage provider.
 * @param {Provider} $locationProvider          The AngularJS location provider.
 * @param {Provider} $provide                   The AngularJS provide.
 * @param {Provider} $rootScopeProvider         The AngularJS root scope provider.
 * @param {Provider} $sceDelegateProvider       The AngularJS SCE delegate provider.
 * @param {Provider} $sessionStorageProvider    The AngularJS session storage provider.
 * @param {Provider} $stateProvider             The AngularJS state provider.
 * @param {Provider} $translateProvider         The AngularJS translate provider.
 * @param {Provider} $urlMatcherFactoryProvider The AngularJS url matcher factory provider.
 * @param {Provider} $urlRouterProvider         The AngularJS url router provider.
 * @param {Provider} emojiConfigProvider        The emoji configuration provider.
 * @param {Provider} hotkeysProvider            The hotkeys provider.
 */
function AppDefaultConfig(
    $analyticsProvider,
    $compileProvider,
    $controllerProvider,
    $httpProvider,
    $localStorageProvider,
    $locationProvider,
    $provide,
    $rootScopeProvider,
    $sceDelegateProvider,
    $sessionStorageProvider,
    $stateProvider,
    $translateProvider,
    $urlMatcherFactoryProvider,
    $urlRouterProvider,
    emojiConfigProvider,
    hotkeysProvider,
) {
    'ngInject';

    $provide.value('$translateProvider', $translateProvider);
    $provide.value('$stateProvider', $stateProvider);

    $localStorageProvider.setKeyPrefix('LumApps-');
    $sessionStorageProvider.setKeyPrefix('LumApps-');

    $sceDelegateProvider.resourceUrlWhitelist(['self', 'https://*.cdn.lumapps.com/**']);

    // eslint-disable-next-line angular/window-service
    let queryString = window.location.href.split('?') || [window.location.href, ''];
    queryString = queryString.length >= 2 ? queryString[1] : '';

    /* eslint-disable angular/definedundefined */
    const IS_ANGULAR_DEBUG_INFO_ENABLED =
        ENABLE_ANGULAR_DEBUG_INFO ||
        includes(queryString, 'debugInfo') ||
        includes(queryString, 'ngStats') ||
        (typeof IS_TEST_ENV !== 'undefined' && IS_TEST_ENV);

    if (IS_ANGULAR_DEBUG_INFO_ENABLED && typeof IS_TEST_ENV === 'undefined') {
        angular
            .injector(['ng'])
            .get('$log')
            .debug('[LumApps - Angular] - Debug info are enabled');
    }
    /* eslint-enable angular/definedundefined */

    $compileProvider.debugInfoEnabled(IS_ANGULAR_DEBUG_INFO_ENABLED);

    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);

    // We need to allow some protocols to avoid that the ng-href adds a prefix "unsafe:" which breaks the "open in a new tab".
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|slack):/);

    $rootScopeProvider.digestTtl(15);

    const $delegate = $stateProvider.state;
    $stateProvider.state = function forEmptyResolveDefinitionInState(stateName, definition) {
        if (!definition.resolve) {
            definition.resolve = {};
        }

        // eslint-disable-next-line prefer-rest-params
        return $delegate.apply(this, arguments);
    };

    const CONTROLLERS = angular.module('Controllers');
    CONTROLLERS._controller = CONTROLLERS.controller;
    CONTROLLERS.controller = function addControllerAfterBootstrap(controllerName, controller) {
        $controllerProvider.register(controllerName, controller);

        return CONTROLLERS;
    };

    const DIRECTIVES = angular.module('Directives');
    DIRECTIVES._directive = DIRECTIVES.directive;
    DIRECTIVES.directive = function addDirectiveAfterBootstrap(directiveName, directive) {
        // eslint-disable-next-line angular/directive-restrict, angular/module-getter
        $compileProvider.directive(directiveName, directive);

        return DIRECTIVES;
    };

    const WIDGETS = angular.module('Widgets');
    WIDGETS._directive = WIDGETS.directive;
    WIDGETS.directive = function addWidgetDirectiveAfterBootstrap(widgetName, widget) {
        // eslint-disable-next-line angular/directive-restrict, angular/module-getter
        $compileProvider.directive(widgetName, widget);

        return WIDGETS;
    };

    const FACTORIES = angular.module('Factories');
    FACTORIES._factory = FACTORIES.factory;
    FACTORIES.factory = function addFactoryAfterBootstrap(factoryName, factory) {
        $provide.factory(factoryName, factory);

        return FACTORIES;
    };

    const CUSTOM_SERVICES = angular.module('CustomServices');
    CUSTOM_SERVICES._service = CUSTOM_SERVICES.service;
    CUSTOM_SERVICES.service = function addServiceAfterBootstrap(serviceName, service) {
        $provide.service(serviceName, service);
        return CUSTOM_SERVICES;
    };

    $analyticsProvider.settings.ga.additionalAccountNames = ['lumsites'];

    setupRouter($locationProvider, $urlMatcherFactoryProvider, $urlRouterProvider);

    $httpProvider.interceptors.push(function httpInterceptor($injector, $location, $q, $timeout, InitialSettings) {
        'ngInject';

        /*
         * Store the previously received error code.
         * When having a 403 when getting the homepage after a redirection from a 403, go to a 404 page.
         */
        let previousError = -1;

        /*
         * This allow to avoid redirection on some handled 403 errors.
         * Simply index a part of the endpoint URL by the 403 error message.
         *
         *  - GOOGLE_CALENDAR_SHARE_ERROR: Ignore 403 when saving a community with a calendar we are not allowed to
         *                                 share.
         *  - GOOGLE_DRIVE_SHARE_ERROR:    Ignore 403 when saving a community with a drive folder we are not allowed
         *                                 to access.
         */
        const ignore403Messages = {
            GOOGLE_CALENDAR_SHARE_ERROR: ['community/save'],
            GOOGLE_DRIVE_SHARE_ERROR: ['community/save'],
            NOT_AUTHORIZED: ['customer/save', 'header/get'],
        };

        /**
         * Contains the currently displayed error notifications for calendar, drive, gmail or lumwork endpoints.
         *
         * @type {Object}
         */
        const displayedErrors = {};

        return {
            /**
             * Intercept all XHR request.
             * Add the calls to an endpoint to a queue of XHR to avoid having too many parallel calls.
             *
             * @param  {Object}  config The configuration of the XHR request.
             * @return {Promise} A promise that resolves when the request can be sent.
             */
            request: function httpInterceptorRequest(config) {

                // header used to opt in for analytics. Required for all calls to backend
                if (!('x-lumapps-analytics' in config.headers)) {
                    config.headers['x-lumapps-analytics'] = 'on';
                }

                const User = $injector.get('User');
                const token = User.getToken();

                // header used for analytics purpose. Only for public users
                if (!token) {
                    const lumappsPublicUniqueId = getLumappsPublicUniqueId();
                    if (!lumappsPublicUniqueId) {
                        setLumappsPublicUniqueId()
                    }
                    config.headers['LUMAPPS-PUBLIC-UNIQUE-ID'] = lumappsPublicUniqueId;   
                }

                const Utils = $injector.get('Utils');

                if (startsWith(config.url, '/client/') && endsWith(config.url, '.html')) {
                    const $templateCache = $injector.get('$templateCache');

                    if (!$templateCache.get(config.url)) {
                        const exception = `\nYou should not be able to request a template file, it must be provided by $templateCache.\nThe path to your html file (${config.url}) might be wrong.`;

                        if (__DEV__) {
                            throw new Error(exception);
                        } else {
                            // eslint-disable-next-line no-console
                            console.warn(exception);
                        }
                    }
                }

                if (!startsWith(config.url, InitialSettings.PUBLIC_PATH_PREFIX)) {
                    // Calls going to everything else than the CDN need a bearer token.
                    if (angular.isDefinedAndFilled(token)) {
                        config.headers.Authorization = `Bearer ${token}`;
                    }

                    config.headers['Lumapps-Organization-Id'] = window.CUSTOMER_ID;
                    config.headers['Lumapps-Web-Client-Version'] = window.BUILD_FRONTEND_VERSION;
                }

                if (!shouldBeQueued(config)) {
                    return config;
                }

                let callId = get(config, 'data.callId', get(config, 'params.callId'));
                if (angular.isUndefinedOrEmpty(callId)) {
                    callId = generateUUID();

                    if (includes(['GET', 'DELETE'], config.method)) {
                        set(config, 'data.callId', callId);
                    } else if (includes(['POST', 'PUT'], config.method)) {
                        set(config, 'params.callId', callId);
                    }
                }

                if (Object.size(_executingRequest) < MAX_XHR_QUEUE_SIZE) {
                    _executingRequest[callId] = config;

                    return config;
                }

                // eslint-disable-next-line angular/deferred
                const queuePromise = $q.defer();
                const request = {
                    config,
                    promise: queuePromise,
                };
                request.timeout = $timeout(function resolveOnTimeout() {
                    request.promise.resolve();
                    request.timeout = false;
                }, MAX_XHR_QUEUE_DELAY * 1000);
                _requestsQueue.push(request);

                return $q(function configResolver(resolve) {
                    queuePromise.promise.then(function onQueueNext() {
                        resolve(config);
                    });
                });
            },

            response: function httpInterceptorResponse(response) {
                return triggerNextRequest(response, $timeout);
            },

            /**
             * Intercept all errors from an XHR request.
             *
             * Handle the 403 in a specific way: redirect to the login page if the customer's licence is expired,
             *                                   else, redirect to the homepage and display an error message.
             *                                   Note that when having another 403 when getting the homepage after a
             *                                   first redirection from a 403, redirect to the 404 page.
             *
             * Any other error is ignored by this interceptor.
             *
             * @param  {Object}  response The response received from the XHR request.
             * @return {Promise} A rejected promise (because it's an error) with the original response object.
             */
            responseError: function httpInterceptorResponseError(response) {
                triggerNextRequest(response, $timeout);

                $timeout(function timeout() {
                    const LxNotificationService = $injector.get('LxNotificationService');

                    const message = get(response, 'data.error.message');

                    const ignore403 =
                        get(response, 'config.data.ignore403', get(response, 'config.params.ignore403')) ||
                        includes(get(response, 'config.url', ''), '/metric');

                    if (response.status === 403 && !ignore403) {
                        let ignore;
                        if (angular.isDefinedAndFilled(ignore403Messages[message])) {
                            previousError = -1;
                            ignore = lodashFind(ignore403Messages[message], function findMatchingEndpoint(endpoint) {
                                return includes(get(response, 'config.url', ''), endpoint);
                            });

                            if (angular.isDefinedAndFilled(ignore)) {
                                return;
                            }
                        }

                        let urlPath = '';
                        if (startsWith($location.path(), '/a/')) {
                            const Customer = $injector.get('Customer');
                            urlPath = `/a/${Customer.getCustomerSlug(false)}`;
                        }
                        const Instance = $injector.get('Instance');
                        urlPath += `/${Instance.getCurrentInstanceSlug()}`;

                        const isCustomerUnreachable = includes(
                            ['CUSTOMER_LICENCE_EXPIRED', 'PUBLIC_CONTENT_DISABLED', 'USER_DISABLED'],
                            message,
                        );
                        const isContentGet =
                            includes(get(response, 'config.url', ''), '/content/get') ||
                            includes(get(response, 'config.url', ''), '/community/get');

                        const Translation = $injector.get('Translation');
                        /*
                         * Go directly to the login page in some cases.
                         * When customer is expired or disabled, when having two 403 on a content get on a row or
                         * when trying to load the homepage and having a 403 error.
                         */
                        if (
                            isCustomerUnreachable ||
                            (previousError === 403 && isContentGet) ||
                            urlPath === $location.path() ||
                            `${urlPath}/` === $location.path()
                        ) {
                            previousError = -1;

                            LxNotificationService.error(Translation.translate('ERROR_NOT_AUTHORIZED'));

                            const errorMessage = isCustomerUnreachable ? message : 'NOT_AUTHORIZED_LOGIN';
                            $location.search('error', errorMessage);

                            const User = $injector.get('User');
                            const userEmail = get(User.getConnected(), 'email');
                            if (angular.isDefinedAndFilled(userEmail)) {
                                $location.search('email', userEmail);
                            }

                            $location.path(`${urlPath}/login`);
                        } else {
                            LxNotificationService.error(Translation.translate('ERROR_NOT_AUTHORIZED'));
                            const ReduxStore = $injector.get('ReduxStore')
                            const state = ReduxStore.store.getState();
                            const isNewInterfaceRoutingEnabled = isNewInterfaceRoutingEnabledSelector(state);

                            if (isNewInterfaceRoutingEnabled) {
                                // Forced V2 routing
                                window.location.href = createUrl(notAllowedRoute);
                            } else {
                                // Legacy fallback to home page
                                $location.path(urlPath);
                            }
                        }

                        if (isContentGet) {
                            previousError = response.status;
                        }

                        return;
                    }

                    if (
                        response.status === 400 &&
                        (includes(get(response, 'config.url', ''), '/drive/') ||
                            includes(get(response, 'config.url', ''), '/gmail/') ||
                            includes(get(response, 'config.url', ''), '/lumwork/'))
                    ) {
                        if (get(displayedErrors, `${response.config.url}.${message}`, false)) {
                            return;
                        }

                        if (angular.isUndefined(displayedErrors[response.config.url])) {
                            displayedErrors[response.config.url] = {};
                        }
                        displayedErrors[response.config.url][message] = true;

                        LxNotificationService.error(message);
                    }
                }, 1);

                return $q.reject(response);
            },
        };
    });

    setupCustomerAdminStates($stateProvider, $translateProvider);
    setupLoginStates($stateProvider, $translateProvider);
    setupAppStates($stateProvider, $translateProvider);
    setupBackOfficeStates($stateProvider, $translateProvider);
    setupFrontOfficeStates($stateProvider, $translateProvider);

    // Add aliases for some common emojis.
    /* eslint-disable object-curly-newline, sort-keys */
    const aliases = [
        { alias: 'smiley', beginning: ':', middle: '-', end: ')' },
        { alias: 'smile', beginning: ':', middle: '-', end: 'D' },
        { alias: 'neutral_face', beginning: ':', middle: '-', end: '|' },
        { alias: 'stuck_out_tongue', beginning: ':', middle: '-', end: 'p' },
        { alias: 'stuck_out_tongue_closed_eyes', beginning: ':', middle: '-', end: 'P' },
        { alias: 'laughing', beginning: '^', middle: '', end: '^' },
        { alias: 'blush', beginning: ':', middle: '-', end: '$' },
        { alias: 'kissing_heart', beginning: ':', middle: '-', end: '*' },
        { alias: 'wink', beginning: ';', middle: '-', end: ')' },
        { alias: 'worried', beginning: ':', middle: '-', end: '(' },
        { alias: 'open_mouth', beginning: ':', middle: '-', end: 'o' },
        { alias: 'hushed', beginning: ':', middle: '-', end: 'O' },
        { alias: 'cry', beginning: ":'", middle: '-', end: '(' },
        { alias: 'joy', beginning: ":'", middle: '-', end: ')' },
        { alias: 'astonished', beginning: 'O', middle: '_', end: 'O' },
        { alias: 'astonished', beginning: 'o', middle: '_', end: 'O' },
        { alias: 'astonished', beginning: 'O', middle: '_', end: 'o' },
        { alias: 'astonished', beginning: 'o', middle: '_', end: 'o' },
        { alias: 'sunglasses', beginning: '8', middle: '-', end: ')' },
        { alias: 'innocent', beginning: 'O:', middle: '-', end: ')' },
    ];
    /* eslint-enable object-curly-newline */

    angular.forEach(aliases, function forEachAliases(alias) {
        if (angular.isDefined(alias) && angular.isDefinedAndFilled(alias.alias)) {
            const beginning = alias.beginning || ':';
            const end = alias.end || ')';

            const defaultMiddle = '-';
            const middle = angular.isDefined(alias.middle) ? alias.middle : defaultMiddle;

            emojiConfigProvider.addAlias(alias.alias, beginning + middle + end);
        }
    });
}

/////////////////////////////

/**
 * The default startup of the Front-Office application.
 *
 * @param {Service} $anchorScroll         The AngularJS anchor scroll service.
 * @param {Service} $http                 The AngularJS http service.
 * @param {Service} $injector             The AngularJS injector service.
 * @param {Service} $location             The AngularJS location service.
 * @param {Service} $log                  The AngularJS log service.
 * @param {Service} $rootScope            The AngularJS root scope service.
 * @param {Service} $state                The AngularJS state service.
 * @param {Service} $stateParams          The AngularJS state parameters service.
 * @param {Service} $templateCache        The AngularJS template cache service.
 * @param {Service} $timeout              The AngularJS timeout service.
 * @param {Service} $window               The AngularJS window service.
 * @param {Service} Analytics             The analytics service.
 * @param {Service} CommunityTemplates    The community templates service.
 * @param {Service} Content               The content service.
 * @param {Service} Customer              The customer service.
 * @param {Service} InitialSettings       The initial settings service.
 * @param {Service} Instance              The instance service.
 * @param {Service} Layout                The layout service.
 * @param {Service} LxNotificationService The lx notification service.
 * @param {Service} MainNav               The main navigation service.
 * @param {Service} Translation           The translation service.
 * @param {Service} User                  The user service.
 * @param {Service} Utils                 The utilities service.
 * @param {Service} authService           The auth service.
 */
function AppDefaultRun(
    $anchorScroll,
    $http,
    $injector,
    $location,
    $log,
    $rootScope,
    $state,
    $stateParams,
    $templateCache,
    $timeout,
    $window,
    Analytics,
    CommunityTemplates,
    Content,
    Customer,
    InitialSettings,
    Instance,
    Layout,
    LxNotificationService,
    MainNav,
    Translation,
    User,
    Utils,
    SocialAdvocacy,
    authService,
    ReduxStore,
) {
    'ngInject';

    $window.lumappsPerformances = $window.lumappsPerformances || {};
    const performances = $window.lumappsPerformances;

    performances.angularRunning = Date.now();

    const navigationStart = get(window, 'performance.timing.navigationStart', 0);
    performances.angularRun = performances.angularRunning - navigationStart;
    performances.angularBootstrap = performances.angularBootstraping - navigationStart;
    performances.bodyInterpretation = performances.bodyInterpreting - navigationStart;
    performances.bodyInterpreted = performances.bodyEnd - navigationStart;

    if (angular.isDefined(performances.loaderDisplaying)) {
        performances.firstPaint = performances.loaderDisplaying - navigationStart;
    }

    FRONT_OFFICE._config = FRONT_OFFICE.config;
    FRONT_OFFICE.config = function configAfterBootstrap() {
        // eslint-disable-next-line prefer-rest-params
        $injector.invoke(arguments[0]);

        return FRONT_OFFICE;
    };

    FRONT_OFFICE._run = FRONT_OFFICE.run;
    FRONT_OFFICE.run = function runAfterBootstrap() {
        // eslint-disable-next-line prefer-rest-params
        $injector.invoke(arguments[0]);
    };

    $rootScope.Layout = Layout;

    // Required for Hotkeys cheatsheet.
    $rootScope.BUILD_TIME = BUILD_TIME;
    $rootScope.BUILD_FRONTEND_VERSION = BUILD_FRONTEND_VERSION;
    $rootScope.BUILD_BACKEND_VERSION = BUILD_BACKEND_VERSION;
    $rootScope.BUILD_VERSION_NUMBER = BUILD_VERSION_NUMBER;
    $rootScope.HAUSSMANN_CELL = HAUSSMANN_CELL;

    /**
     * Check if token have been refreshed.
     * If not refreshed, limit to 5 retries. After 5 retries, redirect user on login page.
     *
     * @param {boolean} isFromContentGet Indicates if the error is from a content get.
     */
    function refreshTokenEnd(isFromContentGet) {
        if ($rootScope.loginAttempt > 5) {
            authService.loginCancelled();

            if ($location.path().indexOf('login') < 0) {
                let urlPath = '';
                if (startsWith($location.path(), '/a/')) {
                    urlPath = `/a/${Customer.getCustomerSlug(false)}`;
                }
                urlPath += `/${Instance.getCurrentInstanceSlug()}`;

                urlPath += '/login';

                if (User.isConnected() || isFromContentGet) {
                    // Keep in memory the current url path.
                    var urlRedirect = $location.url();
                    // Clear the query params from the url.
                    $location.url($location.path());
                    // Display an error message if the user is not allowed, otherwise only send to the login page.
                    if (isFromContentGet) {
                        $location.search('error', 'NEED_LOGIN');
                    } else {
                        LxNotificationService.error(Translation.translate('ERROR_NOT_AUTHORIZED'));
                    }
                    // Add the params "r" with the original url to use when redirecting the user after the login.
                    $location.search('r', urlRedirect);
                    // $location.path(urlPath);
                    $window.location.href = urlPath;
                }
            }
        } else {
            authService.loginConfirmed(undefined, function authLoginConfirmed(config) {
                if (
                    angular.isDefined(config) &&
                    angular.isDefined(config.headers) &&
                    angular.isDefinedAndFilled(User.getToken())
                ) {
                    config.headers.Authorization = `Bearer ${User.getToken()}`;
                }

                return config;
            });
        }
    }

    /**
     * If the token expires, try to refresh it and unstack all waiting request.
     * Limit to 5 retries.
     */
    $rootScope.loginAttempt = 0;
    $rootScope.onLoginRequired = $rootScope.$on('event:auth-loginRequired', function onAuthLoginRequired(
        evt,
        loginData,
    ) {
        $rootScope.loginAttempt++;

        const isFromContentGet =
            includes(get(loginData, 'config.url', ''), '/content/get') ||
            includes(get(loginData, 'config.url', ''), '/community/get');

        User.refreshToken(
            function refreshTokenSuccess(tokenResponse) {
                if (angular.isDefinedAndFilled(tokenResponse)) {
                    $rootScope.loginAttempt = 0;
                } else {
                    $rootScope.loginAttempt = 10;
                }

                refreshTokenEnd(isFromContentGet);
            },
            function refreshTokenError() {
                $rootScope.loginAttempt = 10;

                refreshTokenEnd(isFromContentGet);
            },
        );
    });

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on('$locationChangeStart', function onLocationChangeStart(evt) {
        if (!startsWith($location.path(), '/newsletter')) {
            return true;
        }

        evt.preventDefault();
        $window.location.href = $location.absUrl();

        return false;
    });

    /**
     * This code adds the `frontVersion` to the URL that will be visited
     * and replaces it on the browser. We also save that URL in a local file
     * variable in order to avoid any unnecessary redirects
     */
    $rootScope.$on('$locationChangeStart', function onLocationChangeStart(evt) {
        if (frontVersion) {
            const newUrl = addQueryParamsToUrl($location.url(), { frontVersion });
            
            // We compare paths
            if (lastRedirectedUrlWithFrontVersion.split('?')[0] !== newUrl.split('?')[0]) {
                $location.url(newUrl);
                $location.replace();
            }

            lastRedirectedUrlWithFrontVersion = newUrl;
        }

        return false;
    });

    /**
     * This code retrieves the `frontVersion` when the web app is firstly accessed and
     * saves the URL as the `lastRedirectedUrlWithFrontVersion`, so we can use it
     * to validate on the `$locationChangeStart`.
     */
    $rootScope.$on('$locationChangeSuccess', function onLocationChangeStart(evt) {
        if (!frontVersion) {
            frontVersion = getParameterByName('frontVersion', $window.location.href);

            if (frontVersion) {
                lastRedirectedUrlWithFrontVersion = $window.location.href;
            }
        }
    });

    /**
     * Indicates if the current user fulfills all the permissions required to access the route.
     *
     * @param  {Object}  toParams The params of the route we're navigating to.
     * @return {boolean} Whether all permissions for the current route are fulfilled or not.
     */
    function _isFulfillingRoutePermissions(toParams) {
        return some(toParams.permissions, function isPermissionFulfilled(permission) {
            switch (permission) {
                case PERMISSIONS.instanceAdmin:
                    return User.isInstanceAdmin();

                case PERMISSIONS.superAdmin:
                    return User.getConnected().isSuperAdmin;

                case SA_PERMISSIONS.CAN_ACCESS_SOCIAL_ADVOCACY_BASIC_FEATURES:
                    return SocialAdvocacy.canAccessSocialAdvocacyBasicFeatures();

                case SA_PERMISSIONS.CAN_MANAGE_SOCIAL_ADVOCACY_PROGRAM_MANAGERS:
                    return SocialAdvocacy.canManageSocialAdvocacyProgramManagers();

                case SA_PERMISSIONS.CAN_MANAGE_SOCIAL_ADVOCACY_AMBASSADORS:
                    return SocialAdvocacy.canManageSocialAdvocacyAmbassadors();

                case SA_PERMISSIONS.CAN_MANAGE_SOCIAL_ADVOCACY_TOPICS:
                    return SocialAdvocacy.canManageSocialAdvocacyTopics();

                case SA_PERMISSIONS.CAN_MANAGE_SOCIAL_NETWORK_CONFIGURATIONS:
                    return SocialAdvocacy.canManagePlatformSocialNetworks();

                default:
                    return false;
            }
        });
    }

    /**
     * When the state starts to change, check if we can allow this change.
     * This can trigger an alert for unsaved changes, or disallow the access to the new state.
     *
     * @param {Event}  evt        The state change start event.
     * @param {Object} toState    The state being activated.
     * @param {Object} toParams   The parameters of the state being activated.
     * @param {Object} fromState  The state we are coming from.
     * @param {Object} fromParams The parameters of the state we are coming from.
     * @param {Object} err        The error of the state change, if any.
     */
    function stateChangeStart(evt, toState, toParams, fromState, fromParams, err) {
        // Main nav.
        angular.element('.header-main-nav .main-nav').addClass('main-nav--is-content-loading');

        // Check if user has permission to edit all content or just his own.
        // If not, redirect to the previous page or a 404 page.
        if (toState.name === 'app.front.content-edit') {
            toState.resolve.resolveContentPermissions = [
                'Config',
                'Content',
                'LxNotificationService',
                'Template',
                'Translation',
                'UserAccess',
                'resolveContent',
                function resolveContentPermissions(
                    Config,
                    ContentService,
                    LxNotification,
                    TemplateService,
                    TranslationService,
                    UserAccess,
                    resolveContent,
                ) {
                    /*
                     * This is here to trick the linter into thinking that the blocking resolve injection is
                     * used.
                     */
                    angular.noop(resolveContent);

                    let currentContent;

                    if (angular.isDefined(toParams.isTemplate) && toParams.isTemplate === 'true') {
                        currentContent = TemplateService.getCurrent();
                    } else {
                        currentContent = ContentService.getCurrent();
                    }

                    const params = {
                        checkContent: true,
                        customContentTypeId: get(currentContent, 'customContentType'),
                    };

                    // TODO: [] A bulk check for user permission?
                    if (
                        !UserAccess.isEditor() &&
                        !UserAccess.isUserAllowed('CUSTOM_CONTENT_EDIT', params) &&
                        !UserAccess.isUserAllowed('CUSTOM_CONTENT_PUBLISH', params) &&
                        !UserAccess.isUserAllowed('CUSTOM_CONTENT_DELETE', params) &&
                        !UserAccess.isUserAllowed('CUSTOM_CONTENT_ARCHIVE', params) &&
                        get(currentContent, 'type') === Config.AVAILABLE_CONTENT_TYPES.DIRECTORY &&
                        !UserAccess.isUserAllowed('DIRECTORY_EDIT')
                    ) {
                        if (fromState.name === '') {
                            $state.go('app.front.404');
                            LxNotification.error(TranslationService.translate('ERROR_NOT_AUTHORIZED'));
                        } else {
                            $state.go(fromState, fromParams);
                            LxNotification.error(TranslationService.translate('ERROR_NOT_AUTHORIZED'));
                        }

                        return false;
                    }

                    return true;
                },
            ];
            // https://github.com/angular-ui/ui-router/issues/1584
            // Ui-router hack to do redirects since .when doesn't work on v0.2.13+.
        } else if (angular.isDefinedAndFilled(toState.redirectTo)) {
            evt.preventDefault();
            $state.go(toState.redirectTo, toParams);
        }

        // Check route permisions.
        if (angular.isDefinedAndFilled(get(toParams, 'permissions'))) {
            toState.resolve.resolveConnectedUser = [
                '$q',
                'User',
                'resolveInitialSetting',
                function resolveConnectedUser($q, UserService, resolveInitialSetting) {
                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveInitialSetting);

                    return $q(function deferPermissionsCheck(resolve, reject) {
                        $q.all([
                            UserService.connectedUserDeferred.promise,
                            SocialAdvocacy.connectedUserDeferred.promise,
                        ]).then(function onConnectedUserResolved() {
                            if (!_isFulfillingRoutePermissions(toParams)) {
                                reject('ERROR_NOT_AUTHORIZED');

                                $timeout(function delayHomepageRedirect() {
                                    $state.go('app.front.content-get', toParams);
                                });
                                LxNotificationService.error(Translation.translate('ERROR_NOT_AUTHORIZED'));

                                return;
                            }

                            resolve(UserService.getConnected());
                        });
                    });
                },
            ];
        }

        // Header.
        $rootScope.$broadcast('cancel-header-autoplay');

        // Layout.
        Layout.stateChangeStart(evt, toState, toParams, fromState, fromParams, err);

        $timeout(function timeout() {
            // Main nav.
            $rootScope.$broadcast('main-nav-front-state-change-start');
        });

        $rootScope.$broadcast('lx-dialog__close');
    }

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on('$stateChangeStart', function onStateChangeStart(
        evt,
        toState,
        toParams,
        fromState,
        fromParams,
        err,
    ) {
        let changeState = false;

        const isTemplate = Boolean(fromParams.type === 'template' || fromParams.isTemplate);

        const hasChanged = contentHasChanged(
            Content,
            CommunityTemplates,
            fromState.name,
            InitialSettings.CONTENT_TYPES,
            isTemplate,
        );

        if (
            !hasChanged ||
            (toState.name === 'app.front.content-create-without-custom-type' &&
                fromState.name === 'app.front.content-create')
        ) {
            stateChangeStart(evt, toState, toParams, fromState, fromParams, err);

            changeState = true;
        }

        if (!changeState && $rootScope.documentUnsavedChangesChecked) {
            $rootScope.documentUnsavedChangesChecked = false;

            changeState = true;
        }

        if (changeState) {
            if (!get(toState, 'data.designer')) {
                Content.setAction('get');
            }

            return true;
        }

        evt.preventDefault();

        // Re-add the state in the browser history.
        $state.go(fromState, fromParams);

        if ($rootScope.unsavedNotificationConfirmShowed) {
            return false;
        }

        $rootScope.unsavedNotificationConfirmShowed = true;

        LxNotificationService.confirm(
            Translation.translate('DISCARD_UNSAVED_CHANGES_CONFIRM'),
            Translation.translate('DISCARD_UNSAVED_CHANGES_CONFIRM_DESC'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            function confirmDiscardUnsavedChangesCallback(answer) {
                $rootScope.unsavedNotificationConfirmShowed = false;

                if (!answer) {
                    return;
                }

                $rootScope.documentUnsavedChangesChecked = true;

                stateChangeStart(evt, toState, toParams, fromState, fromParams, err);
                $state.go(toState.name, toParams);
            },
        );

        return false;
    });

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on('$stateChangeSuccess', function onStateChangeSuccess(
        evt,
        toState,
        toParams,
        fromState,
        fromParams,
        err,
    ) {
        const customStyles = document.getElementById('custom-css-for-instance');
        if (customStyles) {
            customStyles.disabled = toState.name.includes('app.admin');
        }

        // Layout.
        Layout.stateChangeSuccess(evt, toState, toParams, fromState, fromParams, err);

        // Main nav.
        if (get(fromState, 'data.designer', false) && Translation.getLang('current') !== Translation.inputLanguage) {
            MainNav.init();
        }

        $timeout(function timeout() {
            // Main nav.
            $rootScope.$broadcast('main-nav-front-state-change-success');
            $rootScope.$broadcast('is-main-nav-item-active');

            Layout.computeIsScrolling();
        });

        Analytics.sendGTMEvent(toState.name);

        ReduxStore.store.dispatch(setCurrentPage(toState.name, true));
        ReduxStore.store.dispatch(actions.setPageTitle(Layout.getHeadTitle()));
    });

    // eslint-disable-next-line angular/on-watch
    $rootScope.$on('$stateChangeError', function onStateChangeError(
        evt,
        toState,
        toParams,
        fromState,
        fromParams,
        err,
    ) {
        if (angular.isUndefinedOrEmpty(err)) {
            return;
        }

        if (angular.isDefined(err) && err.status === 404 && get(err, 'config.url') !== '/service/user/getConnected') {
            $state.go('app.front.404', toParams);
        }

        if (angular.isObject(err)) {
            if (angular.isDefined(err.message)) {
                $log.err(err.message);
            }

            if (angular.isDefined(err.stack)) {
                $log.err(err.stack);
            }
        } else {
            $log.err(err);
        }
    });

    // On reload or closing page.
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    $window.onbeforeunload = function onBeforeUnload(evt) {
        const isTemplate = Boolean($stateParams.type === 'template' || $stateParams.isTemplate);

        if (contentHasChanged(Content, $state.current.name, InitialSettings.CONTENT_TYPES, isTemplate)) {
            evt.preventDefault();
            evt.returnValue = Translation.translate('DISCARD_UNSAVED_CHANGES_CONFIRM');

            return evt.returnValue;
        }

        return undefined;
    };

    /*
     * XXX [Clément]: if you ever need to test something that use the templateCache in local dev server, add your
     * template path to the `templatesToCache` letiable here. This will be cached.
     * Remember to never commit these templates to cache.
     */
    if (Utils.isLocalEnvironment()) {
        /* eslint-disable max-len */
        const templatesToCache = [];
        /* eslint-enable max-len */
        angular.forEach(templatesToCache, function cacheTemplates(templatePath) {
            $http.get(InitialSettings.PUBLIC_PATH_PREFIX + templatePath).then(function cacheTemplatesSuccess(template) {
                if (angular.isDefinedAndFilled(template)) {
                    $templateCache.put(templatePath, template.data);
                }
            });
        });
    }

    /**
     * Safely apply a digest loop.
     * I a digest is already in progress, simply call the callback.
     * Otherwise, call the callback in an "apply".
     *
     * @param {Function} [cb] The callback to execute.
     */
    $rootScope.$safeApply = function $safeApply(cb = angular.noop) {
        // eslint-disable-next-line angular/no-private-call
        const phase = this.$root.$$phase;
        if (phase === '$apply' || phase === '$digest') {
            cb();

            return;
        }

        this.$apply(cb);
    };

    angular.element(window).on('hashchange', function onHashChange() {
        angular.element(document).scrollTop(angular.element(document).scrollTop() - $anchorScroll.yOffset);
    });
}

/////////////////////////////

// eslint-disable-next-line angular/module-getter
FRONT_OFFICE.config(AppDefaultConfig).run(AppDefaultRun);
