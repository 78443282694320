import { getAskAiAdminPath } from '@lumapps/ask-ai/utils';
import { Route, AppId, createEntityManagementRoutes } from '@lumapps/router';

import type { AskAiSource } from './types';

const { baseRoute, createRoute, editRoute, getEditRoute } = createEntityManagementRoutes({
    slug: getAskAiAdminPath('sources'),
    id: 'ai-search-sources',
    appId: AppId.backOffice,
});

export const askAiEditSource = (rule: Pick<AskAiSource, 'id'>): Route => getEditRoute(rule.id);

export interface SourceParams {
    id: string;
}

export {
    baseRoute as askAiBaseSourcesAdminRoute,
    editRoute as askAiEditSourceRoute,
    createRoute as askAiCreateSourceRoute,
};
