import { DirectoryTag } from '@lumapps/directories-tags/types';
import { ConfirmDialogProps } from '@lumapps/lumx-dialogs/components/ConfirmDialog';
import { MultiLanguageFormDialogProps } from '@lumapps/lumx-forms/components/MultiLanguageFormDialog';
import { BadgeProps, HeadingElement, Theme, ThumbnailProps } from '@lumapps/lumx/react';
import { Extension } from '@lumapps/marketplace/types';
import { MetadataV2 } from '@lumapps/metadata/types';
import { TranslateObject } from '@lumapps/translations';

/** An item of a directory */
export type DirectoryEntry = {
    id: string;
    name: Record<string, string>;
    link?: Record<string, string>;
    thumbnail?: string;
    createdAt?: string;
    deletableFromFavorite?: boolean;
    directoryAllowsFavorites?: boolean;
    feedKeys?: string[];
    isInFavoriteFeedKeys?: string[];
    metadata?: string[];
    properties?: Record<string, any>;
    resourceId?: string;
    resourceProperties?: {
        inputVariables?: Record<string, string>;
        componentSettings: Record<string, any>[];
    };
    tags?: string[];
    type?: 'link' | 'microapp';
    directory?: string[] | string;
    values?: Record<string, TranslateObject | TranslateObject[]>;
};

/** Params used to query directory entries */
export interface DirectoryEndpointQuery {
    directory?: string[] | string;
    lang?: string;
    fields?: string;
    cursor?: string;
    instance?: string[];
    maxResults?: number;
    query?: string;
    tags?: string[];
    type?: 'link' | 'microapp';
    sortOrder?: string;
    userFavoritesOnly?: boolean;
    displaySortMandatory?: boolean;
    includeUserEntries?: boolean;
    combinedMetadata?: { metadata: string[] }[];
}

export interface EntryTypes {
    name: string;
    id: 'link' | 'microapp';
}

/** Detailed directory entry variant. */
export enum DetailedDirectoryEntryVariant {
    vertical = 'VERTICAL',
    horizontal = 'HORIZONTAL',
}

/** Directory entry custom field */
interface DirectoryEntryField {
    id: string;
    values: string[];
}

export interface DirectoryEntryCreationDialogProps
    extends Omit<MultiLanguageFormDialogProps, 'heading' | 'scope' | 'defaultLanguage' | 'languages'> {
    /** link to edit */
    entry?: DirectoryEntry;
    /** Marketplace extension that embbed the micro-app */
    extension?: Extension;
    /** parent directory id */
    directoryId: string;
    /** list of tags associated to the parent directory */
    directoryTags?: DirectoryTag[];
    /** force default language to a specific one */
    lang?: string;
    /** whether metadatas can be set for the entry or not */
    canSetMetadata?: boolean;
    /** callback on open micro-app picker */
    onOpenPicker?: () => void;
    /** callback on entry saved */
    onSubmit: (entry: Partial<DirectoryEntry>) => void;
    /** do not display the visibility block */
    hideVisibility?: boolean;
}

/** Directory entry block. */
export interface DetailedDirectoryEntryBlockProps {
    /** The entry's id. */
    directoryEntryId: string;
    /** The entry's type. */
    directoryEntryType: 'link' | 'microapp';
    /** The micro-app ID. */
    resourceId?: string;
    /** The micro-app properties. */
    microAppProperties?: {
        inputVariables?: Record<string, string>;
        componentSettings: Record<string, any>[];
    };
    /** The id of the directory to which this entry belongs. */
    directoryId: string;
    /** The entry's title. */
    title?: string;
    /** The entry's tags. */
    tags?: { items?: Record<string, string>[] };
    /** The entry's url. */
    link: string;
    /** The entry's image. */
    image?: Omit<ThumbnailProps, 'image'> & { url: string };
    /** The entry's variant. */
    variant: DetailedDirectoryEntryVariant;
    /** Whether the current user has added the entry to his favorites. */
    isFavorite: boolean;
    /** Whether the favorite icon is visible. */
    isFavoriteIconVisible: boolean;
    /** The entry's metadata. */
    metadata?: (Pick<MetadataV2, 'rootId' | 'rootName' | 'name'> & { metadataId: MetadataV2['id'] })[];
    /** an ordered list of additional directory entry fields */
    additionalFields?: DirectoryEntryField[];
    /** Callback to add a directory entry as favorite */
    favorite?: () => void;
    /** Callback to remove a directory entry from favorite */
    unfavorite?: () => void;
    /** Dark or light theme */
    theme?: Theme;
    /** Whether the current user can edit the entry */
    canEdit?: boolean;
    /** Whether the current user can delete the entry. To be passed only if canUpdateEntry is set true */
    canDelete?: boolean;
    /** Props to be used for the creation dialog. To be passed only if canUpdateEntry is set true */
    creationDialogProps?: DirectoryEntryCreationDialogProps;
    /** Props to be used for the delete dialog. To be passed only if canUpdateEntry is set true */
    deleteDialogProps?: ConfirmDialogProps;
    /** Callback for opening the saveDialog. To be passed only if canUpdateEntry is set true */
    openSaveDialog?: (directoryEntryId?: string | undefined) => Promise<void>;
    /** Callback for opening the deleteDialog. To be passed only if canUpdateEntry is set true */
    openDeleteDialog?: () => void;
    /** Badge that can be displayed in the Thumbnail */
    badgeProps?: Pick<BadgeProps, 'color'> & { icon: string };
    /** custom classname */
    classname?: string;
    /** custom title DOM tag (keep `undefined` for auto heading level) */
    titleAs?: HeadingElement | 'p';
}
