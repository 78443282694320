import { mdiCheck, mdiClose, mdiTimerSand } from '@lumapps/lumx/icons';
import { ColorPalette, ColorVariant } from '@lumapps/lumx/react';
import { GLOBAL } from '@lumapps/translations/keys';

import { DOMAINS_MANAGEMENT } from './keys';

export const SCOPE = 'domains-management';

export const FRONTIERS_ALPHA_FF = 'frontiers-alpha';

export const RECORD_TYPE_CNAME = 'CNAME';

export const DOMAINS_MANAGEMENT_DOC_LINK =
    'https://hive.lumapps.com/hive-customers/the-lumapps-web-front-door-is-going-to-change-soon';

export const DOMAINS_MANAGEMENT_SUPPORT_LINK = 'https://support.lumapps.com/';

export enum DOMAIN_STATUS {
    DRAFT = 'draft',
    LIVE = 'live',
    PENDING = 'pending',
}

export enum CERTIFICATE_DEPLOYMENT_STATUS {
    ERROR = 'error', // custom => stay on deploying / auto => go on Domain pending provisionning
    // the certificate is deployed (start of domain configuration step (2))
    LIVE = 'live', // go to step 2
    // the certificate is pending, action from user is needed (outside of lumapps) (start of certificate generation step (1))
    PENDING_DNS_CHALLENGE = 'pending_dns_challenge', // step 1
    // the certificate is pending, action from lumapps ongoing (end of certificate generation step (1))
    PENDING_PROVISIONING = 'pending_provisioning', // step 1 = PENDING_DNS_CHALLENGE si DNS chalenge
}

export enum PLATFORM_DEPLOYMENT_ERROR_STATUS {
    OTHER = 'other',
    HAVE_SAML_JIT = 'have_saml_jit',
    HAVE_MS_IDP = 'have_ms_idp',
    HAVE_SAML_V1_IDP = 'have_saml_v1_idp',
}

export const PLATFORM_DEPLOYMENT_ERROR_MESSAGE = {
    [PLATFORM_DEPLOYMENT_ERROR_STATUS.OTHER]: DOMAINS_MANAGEMENT.PLATFORM_DEPLOYMENT_ERROR_MESSAGE_BLOCKED,
    [PLATFORM_DEPLOYMENT_ERROR_STATUS.HAVE_SAML_JIT]: DOMAINS_MANAGEMENT.PLATFORM_DEPLOYMENT_ERROR_MESSAGE_SAML_JIT,
    [PLATFORM_DEPLOYMENT_ERROR_STATUS.HAVE_MS_IDP]: DOMAINS_MANAGEMENT.PLATFORM_DEPLOYMENT_ERROR_MESSAGE_NO_MS,
    [PLATFORM_DEPLOYMENT_ERROR_STATUS.HAVE_SAML_V1_IDP]: DOMAINS_MANAGEMENT.PLATFORM_DEPLOYMENT_ERROR_MESSAGE_SAML_V1,
};

/**
 * Configuration to be used for displaying status with their associated
 * labels and logos.
 */
export const DOMAIN_STATUS_CONFIG = {
    labels: {
        [DOMAIN_STATUS.DRAFT]: GLOBAL.NOT_READY,
        [DOMAIN_STATUS.LIVE]: GLOBAL.DEPLOYED,
        [DOMAIN_STATUS.PENDING]: GLOBAL.PENDING,
    },
    colors: {
        [DOMAIN_STATUS.DRAFT]: ColorPalette.dark,
        [DOMAIN_STATUS.LIVE]: ColorPalette.green,
        [DOMAIN_STATUS.PENDING]: ColorPalette.yellow,
    },
    logos: {
        [DOMAIN_STATUS.DRAFT]: mdiClose,
        [DOMAIN_STATUS.LIVE]: mdiCheck,
        [DOMAIN_STATUS.PENDING]: mdiTimerSand,
    },
};

/**
 * Configuration when certificate is auto-manage
 */
const AUTO_MANAGE_DOMAIN_CONFIG = {
    certificateGenerationTitleSuffix: {
        [CERTIFICATE_DEPLOYMENT_STATUS.LIVE]: DOMAINS_MANAGEMENT.CERTIFICATE_DEPLOYED,
        [CERTIFICATE_DEPLOYMENT_STATUS.ERROR]: DOMAINS_MANAGEMENT.CERTIFICATE_DEPLOYING,
        [CERTIFICATE_DEPLOYMENT_STATUS.PENDING_DNS_CHALLENGE]: undefined,
        [CERTIFICATE_DEPLOYMENT_STATUS.PENDING_PROVISIONING]: DOMAINS_MANAGEMENT.CERTIFICATE_DEPLOYING,
    },
    certificateGenerationTitleColor: {
        [CERTIFICATE_DEPLOYMENT_STATUS.LIVE]: ColorVariant.L2,
        [CERTIFICATE_DEPLOYMENT_STATUS.ERROR]: ColorVariant.L2,
        [CERTIFICATE_DEPLOYMENT_STATUS.PENDING_DNS_CHALLENGE]: ColorVariant.N,
        [CERTIFICATE_DEPLOYMENT_STATUS.PENDING_PROVISIONING]: ColorVariant.L2,
    },
};

/**
 * Configuration when certificate is handle by the customer
 */
const CUSTOM_DOMAIN_CONFIG = {
    certificateGenerationTitleSuffix: {
        [CERTIFICATE_DEPLOYMENT_STATUS.LIVE]: DOMAINS_MANAGEMENT.CERTIFICATE_DEPLOYED,
        [CERTIFICATE_DEPLOYMENT_STATUS.ERROR]: DOMAINS_MANAGEMENT.CUSTOM_CERTIFICATE_DEPLOYING,
        // can't happen
        [CERTIFICATE_DEPLOYMENT_STATUS.PENDING_DNS_CHALLENGE]: undefined,
        [CERTIFICATE_DEPLOYMENT_STATUS.PENDING_PROVISIONING]: DOMAINS_MANAGEMENT.CUSTOM_CERTIFICATE_DEPLOYING,
    },
    certificateGenerationTitleColor: {
        [CERTIFICATE_DEPLOYMENT_STATUS.LIVE]: ColorVariant.L2,
        [CERTIFICATE_DEPLOYMENT_STATUS.ERROR]: ColorVariant.L2,
        // can't happen
        [CERTIFICATE_DEPLOYMENT_STATUS.PENDING_DNS_CHALLENGE]: undefined,
        [CERTIFICATE_DEPLOYMENT_STATUS.PENDING_PROVISIONING]: ColorVariant.L2,
    },
};

/**
 * Configuration to be used for displaying steps specifications
 */
export const DOMAIN_CONFIG = {
    autoManage: AUTO_MANAGE_DOMAIN_CONFIG,
    custom: CUSTOM_DOMAIN_CONFIG,
};

export enum DOMAIN_MIGRATION_STATUS {
    ACTIVATED = 'activated',
    DEACTIVATED = 'deactivated',
}

// 2995952448771099 --> frontiers-provider-restrict, it's for test in sandbox
export const CUSTOMER_ROLLBACK_AUTHORIZE: string[] = [
    '9791394624012371',
    '5915284045037568',
    '8614194619447950',
    '6544799150899200',
    '8580933873190049',
    '6651425830469632',
    '4698741618507776',
    '6235627674664960',
    '5836055092264960',
    '8091566847774149',
    '6717727183994880',
    '4652551794589696',
    '6289989864259584',
    '5084306205048832',
    '6202832979492864',
    '2995952448771099',
];
