import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { RightElementProps } from '@lumapps/lumx-dialogs/components/ResourceOrderingDialog';
import { LUMX_DIALOGS } from '@lumapps/lumx-dialogs/keys';
import { mdiAutorenew, mdiClose } from '@lumapps/lumx/icons';
import { AspectRatio, Slideshow, Thumbnail, Message, Text, TextField, Size, Alignment } from '@lumapps/lumx/react';
import { MEDIAS } from '@lumapps/medias/keys';
import { useDimensions } from '@lumapps/responsive';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { ElementToolbar } from '@lumapps/wrex/components/ElementToolbar';
import { ToolbarItem } from '@lumapps/wrex/types';

import { WREX_IMAGE_GALLERY } from '../../../keys';
import { ImageWithKey } from '../type';

import './index.scss';

const CLASSNAME = 'image-gallery-dialog__image-details';

export interface ImageDetailsProps extends RightElementProps<ImageWithKey> {
    /** Wether an image is being loaded */
    isLoadingImage: boolean;
    /** Callback when the image replace button is clicked  */
    onReplace: () => void;
    /** Callback when the image remove button is clicked  */
    onRemove: () => void;
    /** Callback when submit button is pressed */
    onChangeAltText: (altText: string) => void;
}

/**
 * Component that displays the slideshow of images in the image gallery dialog.
 *
 * @param ImageDetailsProps
 * @returns ImageDetails
 */
export const ImageDetails = React.forwardRef<HTMLDivElement, ImageDetailsProps>((props, ref) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes('image-gallery-dialog');
    const { currentBreakpoint, ref: dimRef } = useDimensions({ breakpoints: { reduced: 0, full: 250 } });

    const { active, resources: images, setActive, isLoadingImage, onReplace, onRemove, onChangeAltText } = props;
    const [onChangeType, setOnChangeType] = React.useState<'slideshow' | 'remove' | 'altText'>('slideshow');

    const handleOnRemove = () => {
        onRemove();
        setOnChangeType('remove');
    };

    const handleChangeAltText = (altText: string) => {
        onChangeAltText(altText);
    };

    const toolbarOptions: ToolbarItem[] = [
        {
            type: 'option',
            icon: mdiAutorenew,
            itemKey: 'replace-image',
            label: translateKey(GLOBAL.REPLACE),
            onClick: onReplace,
            otherProps: {
                ...get({ element: 'button', action: 'replace-image' }),
            },
        },
        { type: 'divider', itemKey: '' },
        {
            type: 'option',
            icon: mdiClose,
            itemKey: 'remove-image',
            tooltipLabel: translateKey(MEDIAS.REMOVE_IMAGE),
            verticalModeProps: {
                label: translateKey(MEDIAS.REMOVE_IMAGE),
                tooltipLabel: undefined,
            },
            onClick: handleOnRemove,
            otherProps: {
                ...get({ element: 'button', action: 'remove-image' }),
            },
        },
    ];

    return (
        <div className={block()}>
            <div className={element('slideshow-wrapper')}>
                <ElementToolbar
                    currentBreakpoint={currentBreakpoint}
                    toolbarOptions={toolbarOptions}
                    toolbarAriaLabel={translateAndReplace(
                        WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_DETAILS_TOOLBAR_LABEL,
                        {
                            INDEX: active.index + 1,
                        },
                    )}
                    dataScope="image-gallery-dialog"
                    className={element('toolbar')}
                />
                <Slideshow
                    className={element('slideshow')}
                    ref={mergeRefs([ref, dimRef])}
                    activeIndex={active.index}
                    groupBy={1}
                    slideshowControlsProps={{
                        nextButtonProps: { label: translateKey(MEDIAS.NEXT_IMAGE) },
                        previousButtonProps: { label: translateKey(MEDIAS.PREVIOUS_IMAGE) },
                    }}
                    onChange={(index) => {
                        if (onChangeType === 'remove') {
                            // Select the same index on remove except for the last image
                            const newIndex = active.index >= images.length ? images.length - 1 : active.index;
                            /**
                             * The active index has been changed from a remove image click
                             */
                            setActive({
                                index: newIndex,
                                changed: true,
                                doFocusGrid: true,
                            });
                            setOnChangeType('slideshow');
                        } else if (active.changed) {
                            /**
                             * The active index has been changed from a click on the grid
                             */
                            setActive({ ...active, changed: false });
                        } else if (index !== active.index) {
                            /**
                             * The active index has been changed from the slideshow
                             */
                            setActive({
                                index,
                                changed: false,
                                doFocusGrid: false,
                            });
                        } else if (onChangeType === 'altText') {
                            /**
                             * The onChange have been called from an alt change
                             * The active index shouldn't be changed and focus should rest in the input alt text
                             */
                            setActive({ index, changed: false, doFocusGrid: false });
                            setOnChangeType('slideshow');
                        }
                    }}
                >
                    {images?.map((image) => (
                        <Thumbnail
                            key={image.key}
                            className={element('slideshow-image')}
                            image={image.blobUrl || image.url || ''}
                            alt={image.alt || ''}
                            isLoading={isLoadingImage && !image.url}
                            aspectRatio={AspectRatio.wide}
                        />
                    ))}
                </Slideshow>
            </div>
            <div>
                <TextField
                    className={margin(Alignment.top, Size.huge)}
                    placeholder={translateKey(LUMX_DIALOGS.INSERT_ALT_TEXT_DIALOG_INPUT_PLACEHOLDER)}
                    label={translateKey(LUMX_DIALOGS.INSERT_ALT_TEXT_DIALOG_TEXTFIELD_LABEL)}
                    name="altText"
                    helper={
                        <Message className={margin('top', 'big')} hasBackground kind="info">
                            <Text as="p">{translateKey(LUMX_DIALOGS.INSERT_ALT_TEXT_DIALOG_HELP_MESSAGE)}</Text>
                        </Message>
                    }
                    onChange={handleChangeAltText}
                    value={images[active.index]?.alt}
                    onFocus={(event) => {
                        event.preventDefault();
                        setOnChangeType('altText');
                    }}
                    {...get({ element: 'input', action: 'alt-text' })}
                />
            </div>
        </div>
    );
});
