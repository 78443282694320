import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';

import { CalendarEvent } from '@lumapps/lumx-calendar/types';
import { TranslationAPI } from '@lumapps/translations';
import { getDifferenceInDays } from '@lumapps/utils/date/getDatesDifference';
import { nextDay } from '@lumapps/utils/date/getDayRelativeToDate';
import { getStartOfCurrentDay } from '@lumapps/utils/time';

import { WIDGET_CALENDAR } from '../keys';

/**
 * Format events for the scheduled view
 * All events spanning multiple days need tu be replicated for each day's.
 * */
export const replicateMultipleDayEvents = (
    selectedDate: Date,
    translateAndReplace: TranslationAPI['translateAndReplace'],
) =>
    flow(
        map((event: CalendarEvent) => {
            const { start, end, summary } = event;

            /** Get the first day to display, either the first day of the event or the current date */
            let startDate = new Date(Math.max(selectedDate.getTime(), start.getTime()));

            /**
             * Do not replicate the event if :
             * This is a single day event
             * The event end before the current day
             * */
            if (!end || getDifferenceInDays(start, end) <= 1 || startDate.getTime() > end.getTime()) {
                return event;
            }

            /** Get the event duration in days */
            const eventDuration = getDifferenceInDays(start, end);

            const newEvents: CalendarEvent[] = [];
            while (startDate.getTime() < end.getTime()) {
                /** Get the event position */
                const eventPosition = getDifferenceInDays(start, startDate) + 1;
                newEvents.push({
                    /** Keep the event default values */
                    ...event,
                    /** Override the event ID, to keep uniq ID's */
                    id: `${event.id}-${getStartOfCurrentDay(startDate).getTime()}`,
                    summary: `${summary} ${translateAndReplace(WIDGET_CALENDAR.EVENT_DAY_PROGRESS, {
                        CURRENT_DAY_NUMBER: eventPosition,
                        TOTAL_DAYS: eventDuration,
                    })}`,
                    /** Create a full day event, with the current start date */
                    start: getStartOfCurrentDay(startDate),
                    end: getStartOfCurrentDay(nextDay(startDate)),
                });
                startDate = nextDay(startDate);
            }

            return newEvents;
        }),
        flatten,
    );
