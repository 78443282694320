import debounce from 'lodash/debounce';
import loFind from 'lodash/find';
import first from 'lodash/first';
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import union from 'lodash/union';
import values from 'lodash/values';

import { RenderingType } from '@lumapps/communities/types';
import { isSpacesFeatureEnabled } from '@lumapps/communities/ducks/selectors';

/////////////////////////////

function LsContentPickerController(
    $scope,
    $timeout,
    Community,
    Config,
    Content,
    CustomContentType,
    ContentPicker,
    Document,
    Instance,
    Metadata,
    Translation,
    User,
    Utils,
    ReduxStore,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The format string to use for dates.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _DATE_FORMAT = 'YYYY-MM-DDT00:00:00.000000';

    /**
     * The delay for the filter change event.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _FILTER_CONTENT_DELAY = 500;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.CONTENT_TYPE_CHOICES = [
        Config.AVAILABLE_CONTENT_TYPES.COMMUNITY,
        Config.AVAILABLE_CONTENT_TYPES.CUSTOM,
        Config.AVAILABLE_CONTENT_TYPES.CUSTOM_LIST,
        Config.AVAILABLE_CONTENT_TYPES.DIRECTORY,
        Config.AVAILABLE_CONTENT_TYPES.USER_DIRECTORY,
    ];

    /**
     * The list key to get the list of contents (library mode).
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.LIBRARY_LIST_KEY = 'content-picker';

    /**
     * The list key to get the list of selected contents (organize mode).
     *
     * @type {string}
     * @constant
     */
    vm.SELECTION_LIST_KEY = 'content-picker-selection-';

    /**
     * The available values for view mode.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    vm.VIEW_MODES = {
        library: 'library',
        organize: 'organize',
    };

    /**
     * Contains the list of metadata associated with the selected content type.
     *
     * @type {Array}
     */
    vm.contentTypeMetadata = [];

    /**
     * Contains all the custom content types available in the instance.
     *
     * @type {Object}
     */
    vm.customContentTypes = {};

    /**
     * Contains all the tags available for the selected custom content types.
     *
     * @type {Object}
     */
    vm.customContentTypesTags = {};

    /**
     * The number of tags available throughout all the selected content types.
     *
     * @type {number}
     */
    vm.customContentTypesTagsLength = 0;

    /**
     * Contains the filter used to search the contents.
     *
     * @type {Object}
     */
    vm.filter = {
        contentType: [Config.AVAILABLE_CONTENT_TYPES.CUSTOM],
    };

    /**
     * Contains the visibility of the filters according to the content type.
     *
     * @type {Object}
     */
    vm.filterOptionsVisibility = {};

    /**
     * Indicates if the content picker is opened or not.
     *
     * @type {boolean}
     */
    vm.isOpen = false;

    /**
     * Indicates if the filters are shown or not.
     *
     * @type {boolean}
     */
    vm.showFilter = true;

    /**
     * Contains the list of content corresponding to the ngModel (that can be a list of id, an object, ...).
     *
     * @type {Array}
     */
    vm.tmpList = [];

    /**
     * The view mode the content picker is currently in.
     * Possible values are:
     *     - 'organize': display the list of selected contents and allow to organize them;
     *     - 'library': display the list of contents according to the filters and allow the user to select them;
     *
     * @type {string}
     */
    vm.viewMode = vm.VIEW_MODES.organize;

    /**
     * If the content is of type community, store the link data
     * @type {Object|undefined}
     * */
    vm.communityLink = undefined;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Content = Content;
    vm.Document = Document;
    vm.Community = Community;
    vm.CustomContentType = CustomContentType;
    vm.Instance = Instance;
    vm.Metadata = Metadata;
    vm.Translation = Translation;
    vm.User = User;
    vm.Utils = Utils;
    vm.getTargetApi = getTargetApi;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Filter metadata according to custom content types.
     *
     * @param  {Array}  cctIds The list of the custom content type Ids we want to retrieve the metadata.
     * @return {Object} The custom content type metadata for all the custom content type Ids.
     */
    function _getCustomContentTypeMetadata(cctIds) {
        if (angular.isUndefinedOrEmpty(cctIds)) {
            return {};
        }

        return Metadata.listByCustomContentTypeIds(cctIds);
    }

    function getTargetApi(type) {
        /**
         * If the type is forced, use that one all the time
         */
        if (angular.isDefined(vm.forcedTypes) && vm.forcedTypes.length === 1 && first(vm.forcedTypes)) {
            type = first(vm.forcedTypes);
            /**
             * If there is no type provided, determine it from the applied filters
             */
        } else if (!type && vm.filter.contentType && vm.filter.contentType.length > 0) {
            type = vm.filter.contentType[0];
        }

        return type === Config.AVAILABLE_CONTENT_TYPES.COMMUNITY ? Community : Content;
    }

    /**
     * Initialize the contents list.
     */
    function _initList() {
        vm.ngModel = vm.ngModel || [];
        vm.tmpList = vm.multi ? [] : {};

        if (angular.isUndefinedOrEmpty(vm.ngModel)) {
            return;
        }

        const targetApi = getTargetApi();

        // Can be an array of content or an array of content ids.
        if (vm.multi) {
            targetApi.filterize(
                {
                    ids: vm.kind === 'object' ? map(vm.ngModel, 'id') : vm.ngModel,
                    lang: Translation.inputLanguage,
                    queryType: 'getContentByIds',
                    status: [Config.CONTENT_STATUS.LIVE.value],
                },
                function onContentFilterizeSuccess(response) {
                    vm.tmpList = response;
                },
                Utils.displayServerError,
                vm.SELECTION_LIST_KEY,
            );

            // Can be a content object or a content id.
        } else if (vm.kind === 'object') {
            targetApi.get(
                vm.ngModel.id,
                function onContentGetObjectSuccess(response) {
                    vm.tmpList = response;
                },
                undefined,
                vm.SELECTION_LIST_KEY,
            );
        } else if (vm.kind === 'id') {
            targetApi.get(
                vm.ngModel,
                function onContentGetIdSuccess(response) {
                    vm.tmpList = response;
                },
                undefined,
                vm.SELECTION_LIST_KEY,
            );
        }
    }

    /**
     * Pages and News are special cases.
     * When they're selected in CCT, we add them to the type param of our request, but we don't want them to show up
     * in the UI though.
     *
     * @param  {Array} customContentTypes The list of selected custom content types.
     * @return {Array} The list of Pages or News custom content type that have been selected.
     */
    function _mapCustomContentTypesToContentTypes(customContentTypes) {
        if (angular.isUndefinedOrEmpty(customContentTypes)) {
            return [];
        }

        return intersection(
            [Config.AVAILABLE_CONTENT_TYPES.PAGE, Config.AVAILABLE_CONTENT_TYPES.NEWS],
            customContentTypes,
        );
    }

    /**
     * Reset the filters.
     */
    function _resetFilters() {
        vm.filter = {
            author: undefined,
            contentType: [Config.AVAILABLE_CONTENT_TYPES.CUSTOM],
            customContentType: [],
            endDate: undefined,
            instance: [Instance.getCurrentInstanceId()],
            metadata: [],
            startDate: undefined,
            tags: [],
            title: undefined,
        };
    }

    /**
     * Reset the list of contents.
     */
    function _resetList() {
        vm.selectedItem = undefined;
        vm.communityLink = undefined;
        vm.tmpList = undefined;
    }

    /**
     * Set filter options visibility.
     * Depending on the type of content we want to pick, only show specific textfields/selects/...
     *
     * @param {Array} selectedContentTypes The content types to filter on.
     */
    function _setFilterOptionsVisibility(selectedContentTypes) {
        /*
         * We only want to show most of the filter options (i.e. CCT select, start / end dates) when the user is
         * about to pick some content pages.
         * We don't care about those filter options when picking communities for example.
         */
        const customizableContentTypes = [
            Config.AVAILABLE_CONTENT_TYPES.PAGE,
            Config.AVAILABLE_CONTENT_TYPES.NEWS,
            Config.AVAILABLE_CONTENT_TYPES.CUSTOM,
        ];
        const isPickingCustomizableContentType = angular.isDefinedAndFilled(
            intersection(selectedContentTypes, customizableContentTypes),
        );

        vm.filterOptionsVisibility = mapValues(vm.filter, function mapFiltersVisibility(value, key) {
            /*
             * Only show custom content types select if we want content/pages/news.
             * For other types, just show the title textfield.
             */
            if (isPickingCustomizableContentType || key === 'title') {
                return true;
            }

            return false;
        });
    }

    /**
     * Filter the list of pickable contents.
     */
    async function _filterContent() {
        /**
         * Avoid fetching the contents if the view only displays the ones selected.
         */
        if (vm.viewMode === vm.VIEW_MODES.organize) {
            return;
        }

        const actions = vm.filterActions;
        let contentTypes = [];
        let customContentTypes = map(vm.filter.customContentType, 'id');
        let currentInstanceId, endDate, startDate;
        let instanceIds = angular.fastCopy(vm.filter.instance);

        // Handle dates.
        if (angular.isDefined(vm.filter.startDate)) {
            startDate = moment(vm.filter.startDate).format(_DATE_FORMAT);
        }

        if (angular.isDefined(vm.filter.endDate)) {
            endDate = moment(vm.filter.endDate).format(_DATE_FORMAT);
        }

        // Handle content types passed in as a param to the directive.
        if (angular.isDefinedAndFilled(vm.forcedTypes)) {
            vm.filter.contentType = vm.forcedTypes;
        }

        // Handle custom content types passed in as a param to the directive.
        if (angular.isDefinedAndFilled(vm.forcedCustomContentTypes)) {
            customContentTypes = vm.forcedCustomContentTypes;
        }

        // Fallback - No content types selected in the dropdown.
        if (angular.isUndefinedOrEmpty(vm.filter.contentType)) {
            contentTypes = union(vm.CONTENT_TYPE_CHOICES, [
                Config.AVAILABLE_CONTENT_TYPES.NEWS,
                Config.AVAILABLE_CONTENT_TYPES.PAGE,
            ]);

            customContentTypes = [];
        } else if (includes(vm.filter.contentType, Config.AVAILABLE_CONTENT_TYPES.CUSTOM)) {
            /*
             * If we select 'custom' in the content type dropdown, we want to add 'pages' and 'news' as well.
             * But we don't want to see them in the UI.
             */
            contentTypes.push(Config.AVAILABLE_CONTENT_TYPES.NEWS);
            contentTypes.push(Config.AVAILABLE_CONTENT_TYPES.PAGE);
        } else {
            /*
             * Make sure we clear custom content types from the query if we don't select 'custom' from the content
             * type dropdown.
             */
            customContentTypes = [];
        }

        const combinedMetadata = [];
        angular.forEach(vm.filter.metadata, function forEachFilterMetadata(metadata) {
            if (angular.isArray(metadata) && angular.isDefinedAndFilled(metadata)) {
                combinedMetadata.push({
                    metadata,
                });
            }
        });

        vm.updateCustomContentTypeList(instanceIds);

        _setFilterOptionsVisibility(vm.filter.contentType);

        const typesFromCCT = _mapCustomContentTypesToContentTypes(customContentTypes);

        if (
            !includes(actions, 'COMMUNITY_READ') &&
            includes(vm.filter.contentType, Config.AVAILABLE_CONTENT_TYPES.COMMUNITY)
        ) {
            actions.push('COMMUNITY_READ');
        }

        /**
         * This is mandatory in order to fetch contents which comes from siblings instances.
         * If there is no instance provided, by setting the currentInstanceId property we can
         * fetch content from siblings only.
         */
        if (angular.isUndefinedOrEmpty(instanceIds)) {
            instanceIds = undefined;
            currentInstanceId = Instance.getCurrentInstanceId();
        }

        // If there's a text query, sort by relevance (default sortOrder on the backend).
        const sortOrder = angular.isDefinedAndFilled(vm.filter.title) ? [] : ['-publicationDate'];
        const types = union(vm.filter.contentType, typesFromCCT, contentTypes);

        const serviceToUse = getTargetApi(vm.filter.contentType[0]);

        const additionalProjection = vm.itemAdditionalProjection || {};

        /**
         * We are defining the community renderingTypes we are fetching
         * depending on the Space Feature Flag.
         */
        const state = ReduxStore.store.getState();
        const renderingTypes = isSpacesFeatureEnabled(state) ? [RenderingType.space, RenderingType.community] : [RenderingType.community];

        const response = await serviceToUse.filterize(
            {
                // The lumapps-back API silently converts list to their last element when expecting
                // a single value; but monolite will return a 400 BAD REQUEST when sent a list
                // instead of a single string
                action: actions[actions.length - 1],
                author: vm.filter.author,
                combinedMetadata,
                currentInstanceId,
                customContentType: customContentTypes,
                customContentTypeTags: vm.filter.tags,
                endDate,
                instanceId: instanceIds,
                renderingTypes,
                query: vm.filter.title,
                sortOrder,
                startDate,
                status: [Config.CONTENT_STATUS.LIVE.value],
                // removing a filter creates a list where the first value is undefined
                // this prevents sending [null] to the backend and send an empty list instead
                type: !Boolean(types[0]) ? [] : types,
            },
            undefined,
            Utils.displayServerError,
            vm.LIBRARY_LIST_KEY,
            {
                items: {
                    author: true,
                    authorId: true,
                    authorDetails: true,
                    canEdit: true,
                    canMarkRelevant: true,
                    canonicalUrl: true,
                    createdAt: true,
                    customContentType: true,
                    customContentTypeDetails: true,
                    customer: true,
                    id: true,
                    instance: true,
                    mediaThumbnail: true,
                    publicationDate: true,
                    slug: true,
                    status: true,
                    renderingType: true,
                    tagsDetails: true,
                    thumbnail: true,
                    thumbnailBlobkey: true,
                    title: true,
                    type: true,
                    uid: true,
                    updatedAt: true,
                    updatedBy: true,
                    updatedById: true,
                    url: true,
                    writerDetails : true,
                    ...additionalProjection,
                }
            }
        ).$promise;

        if (vm.filterByInputLanguage && !angular.isUndefinedOrEmpty(response.items)) {
            // If requested: filter content by input language.
            const filteredList = response.items.filter((content) => Translation.inputLanguage in content.slug);
            serviceToUse.initList(vm.LIBRARY_LIST_KEY, null, filteredList, false, undefined);
            $timeout(() => serviceToUse.displayList(vm.LIBRARY_LIST_KEY));
        }
    }

    const _debouncedFilterContent = debounce(_filterContent, _FILTER_CONTENT_DELAY);

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Close the filter sidebar.
     */
    function closeFilter() {
        vm.showFilter = false;
    }

    /**
     * Close the content picker dialog.
     */
    function closePicker() {
        ContentPicker.close(vm.pickerId);
    }

    /**
     * Check if a content is selected.
     *
     * @param  {Object}  content The content we want to check.
     * @return {boolean} If the content is selected or not.
     */
    function isSelected(content) {
        if (vm.multi) {
            return angular.isDefinedAndFilled(
                loFind(vm.tmpList, {
                    id: content.id,
                }),
            );
        }

        return content.id === vm.tmpList.id;
    }

    /**
     * Open the filters sidebar.
     */
    function openFilter() {
        vm.showFilter = true;
    }

    /**
     * Save the list of selected content to the model.
     */
    function submitList() {
        if (vm.multi) {
            if (vm.kind === 'object') {
                vm.ngModel = angular.fastCopy(vm.tmpList);
            } else if (vm.kind === 'id') {
                vm.ngModel = map(vm.tmpList, 'id');
            }
        } else if (vm.kind === 'object') {
            vm.ngModel = angular.fastCopy(vm.tmpList);
        } else if (vm.kind === 'id') {
            vm.ngModel = vm.tmpList.id;
        }

        $timeout(vm.closePicker);
    }

    /**
     * Switch the view mode.
     *
     * @param {string} viewMode The view mode.
     *                          Possible values are: 'library' or 'organize'.
     */
    function switchViewMode(viewMode) {
        if (angular.isDefinedAndFilled(vm.VIEW_MODES[viewMode])) {
            vm.viewMode = viewMode;

            if (viewMode === vm.VIEW_MODES.library) {
                _filterContent();
            }
        }
    }

    /**
     * Transform a tag object to an UUID.
     * This is used for the selectionToModel transform of the lxSelect.
     *
     * @param {Object}   tag The tag to transform.
     * @param {Function} cb  The lxSelect callback.
     */
    function tagToUuid(tag, cb) {
        if (!angular.isFunction(cb)) {
            return;
        }

        if (angular.isDefinedAndFilled(tag.uuid)) {
            cb(tag.uuid);
        }
    }

    /**
     * Toggle a content details.
     *
     * @param {Object} content The content we want to toggle the details of.
     */
    function toggleDetails(content) {
        if (angular.isUndefinedOrEmpty(content)) {
            return;
        }

        if (content === vm.selectedItem) {
            vm.selectedItem = undefined;
            vm.communityLink = undefined;
        } else {
            vm.selectedItem = content;
            if (Community.isCommunity(content)) {
                vm.communityLink = Community.getCommunityLink(content, true);
            }
        }
    }

    /**
     * Toggle a content selection.
     *
     * @param {Object} content The content we want to toggle the selection.
     */
    function toggleSelection(content) {
        if (angular.isUndefinedOrEmpty(content)) {
            return;
        }

        if (vm.multi) {
            if (vm.isSelected(content)) {
                Utils.reject(vm.tmpList, {
                    id: content.id,
                });
            } else {
                vm.tmpList.push(content);
            }
        } else {
            vm.tmpList = content;
        }
    }

    /**
     * Update the list of selectable custom content types.
     *
     * @param {Array} selectedInstances The list of selected instances.
     */
    function updateCustomContentTypeList(selectedInstances) {
        let isOnlyHeritableFromParent = false;
        const instances = angular.fastCopy(selectedInstances);

        const siblings = Instance.getSiblings(true);
        const parentId = get(
            loFind(siblings, function findParentSibling(sibling) {
                return angular.isUndefinedOrEmpty(sibling.parent);
            }),
            'id',
        );

        if (angular.isDefinedAndFilled(parentId) && !includes(selectedInstances, parentId)) {
            instances.push(parentId);

            isOnlyHeritableFromParent = true;
        }

        // Filter content types.
        CustomContentType.groupByInstances(instances, false, isOnlyHeritableFromParent, function groupCCTByInstances(
            cct,
        ) {
            vm.customContentTypes = cct;
        });
    }

    /**
     * Update the list of metadata as well as the tags for each selected custom content types.
     */
    function updateMetadata() {
        if (!angular.isArray(vm.filter.customContentType)) {
            return;
        }

        vm.contentTypeMetadata = _getCustomContentTypeMetadata(map(vm.filter.customContentType, 'id'));
        CustomContentType.getTagsForHeaderSelect(vm.filter.customContentType, function onTagsComputed(tags) {
            vm.customContentTypesTags = tags || {};
            vm.customContentTypesTagsLength = flattenDeep(values(vm.customContentTypesTags)).length;
        });
    }

    /**
     * Transform a tag UUID to a full tag object.
     * This is used for the modelToSelect transform of the lxSelect.
     *
     * @param {Object}   tagId The id of the tag we want to get.
     * @param {Function} cb    The lxSelect callback.
     */
    function uuidToTag(tagId, cb) {
        if (!angular.isFunction(cb) || angular.isUndefinedOrEmpty([tagId, vm.customContentTypesTags], 'some')) {
            return;
        }

        // Creates a flattened array of all the tags and tries to find the one that matches tagId.
        const selectedTag = loFind(flattenDeep(values(vm.customContentTypesTags)), {
            uuid: tagId,
        });

        if (angular.isDefinedAndFilled(selectedTag)) {
            cb(selectedTag);

            return;
        }

        cb();
    }

    /////////////////////////////

    vm.closeFilter = closeFilter;
    vm.closePicker = closePicker;
    vm.isSelected = isSelected;
    vm.openFilter = openFilter;
    vm.submitList = submitList;
    vm.switchViewMode = switchViewMode;
    vm.tagToUuid = tagToUuid;
    vm.toggleDetails = toggleDetails;
    vm.toggleSelection = toggleSelection;
    vm.updateCustomContentTypeList = updateCustomContentTypeList;
    vm.updateMetadata = updateMetadata;
    vm.uuidToTag = uuidToTag;

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any changes in the filters and refresh the list of content.
     * Debounce the refreshing so that we don't refresh to often.
     *
     * @param {Object} newValue The new filter object.
     * @param {Object} oldValue The old filter object.
     */
    $scope.$watch(
        'vm.filter',
        function filtersWatch(newValue, oldValue) {
            if (newValue !== oldValue) {
                _debouncedFilterContent();
            }
        },
        true,
    );

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the content picker closes, reset the lists and filters.
     *
     * @param {Event}  evt       The content picker close event.
     * @param {string} managerId The id of the content picker that closes.
     */
    $scope.$on('content-picker__close-end', function onContentPickerCloseEnd(evt, pickerId) {
        if (vm.pickerId === pickerId) {
            vm.isOpen = false;

            vm.init();
        }
    });

    /**
     * When the content picker opens, switch to the right mode and initialize the lists.
     * If there is no content already picked, always display the library mode. Else display the organize mode.
     *
     * @param {Event}  evt       The content picker open event.
     * @param {string} managerId The id of the content picker that opens.
     */
    $scope.$on('content-picker__open-start', function onContentPickerOpenStart(evt, pickerId) {
        if (vm.pickerId === pickerId) {
            vm.isOpen = true;

            if (angular.isUndefinedOrEmpty([vm.viewMode, vm.VIEW_MODES[vm.viewMode]], 'some')) {
                vm.viewMode = angular.isDefinedAndFilled(vm.ngModel) ? vm.VIEW_MODES.organize : vm.VIEW_MODES.library;
            }

            _initList();

            _filterContent();
        }
    });

    /**
     * When the list of instances siblings is updated, recompute the list of available custom content types.
     */
    $scope.$on('instances-siblings-list-updated', function onInstancesSiblingsUpdated() {
        vm.updateCustomContentTypeList(vm.filter.instance);
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    vm.init = function init() {
        vm.SELECTION_LIST_KEY += vm.pickerId;
        vm.title = vm.title || 'GLOBAL.CONTENT';

        vm.filterActions = vm.filterActions || ['PAGE_READ'];

        _resetList();
        _resetFilters();
    };

    vm.init();
}

/////////////////////////////

function LsContentPickerDirective() {
    'ngInject';

    function link(scope, el) {
        el.appendTo('body');

        scope.$on('$destroy', function onDestroy() {
            el.remove();
        });
    }

    return {
        bindToController: true,
        controller: LsContentPickerController,
        controllerAs: 'vm',
        link,
        restrict: 'E',
        scope: {
            filterActions: '=?lsFilterActions',
            filterByInputLanguage: '=?lsFilterByInputLanguage',
            forcedCustomContentTypes: '=lsForcedCustomContentTypes',
            forcedTypes: '=lsForcedTypes',
            itemAdditionalProjection: '=lsItemAdditionalProjection',
            hideInstanceSelector: '=lsHideInstanceSelector',
            kind: '@lsKind',
            multi: '=lsMulti',
            ngModel: '=',
            pickerId: '@lsPickerId',
            title: '@?lsTitle',
            viewMode: '@lsViewMode',
        },
        templateUrl: '/client/common/modules/content-picker/views/content-picker.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsContentPicker', LsContentPickerDirective);

/////////////////////////////

export { LsContentPickerDirective };
