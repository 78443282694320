import React from 'react';

import { TeamKpi } from '@lumapps/learning-team-kpi/components/TeamKpi';
import { isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';

import { TeamKpiProps } from '../../types';

export const ClientComputedTeamKpi: React.FC<TeamKpiProps> = () => {
    const learningEnabled = useSelector(isLearningEnabled);

    if (!learningEnabled) {
        return null;
    }
    return <TeamKpi />;
};
