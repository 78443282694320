import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { SORT_FIELDS } from '@lumapps/articles/constants';
import { ARTICLES_SORTING } from '@lumapps/articles/types';
import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { FiltersAndSortContainer } from '@lumapps/lumx-filter-and-sort/components/FiltersAndSortContainer';
import { SearchField } from '@lumapps/lumx-filter-and-sort/components/SearchField';
import { Sorters } from '@lumapps/lumx-filter-and-sort/components/Sorters';
import { SortersType } from '@lumapps/lumx-filter-and-sort/types';
import { useTranslate } from '@lumapps/translations';
import { FilterComponentProps } from '@lumapps/widget-base/types';
import { getFilterByName } from '@lumapps/widget-base/utils/getFilterByName';

import { WIDGET_ARTICLE_LIST } from '../../keys';

import './index.scss';

const CLASSNAME = 'article-list-filters';

export interface ArticleListFiltersType {
    /** Value of the SearchField */
    searchTerm?: string;
    /** Current selected sort item */
    sort?: SortersType;
}

/**
 * Component to display filters of the widget-article-list
 * @param FilterComponentProps
 * @returns ArticleListFilters
 */
export const ArticleListFilters: React.FC<FilterComponentProps<ArticleListFiltersType>> = ({
    onFilterChange,
    onApply,
    activeFilters,
    availableFilters,
    loadingState,
    theme,
}) => {
    const { translateKey } = useTranslate();
    const isFilterDisabled = loadingState === 'error' || loadingState === 'loading';
    const { get: getDataAttributes } = useDataAttributes(CLASSNAME);
    const { block } = useClassnames(CLASSNAME);

    const debouncedOnChange = React.useCallback(
        (filterValue: any, filterName: keyof ArticleListFiltersType) => {
            if (isEmpty(filterValue)) {
                onFilterChange({ [filterName]: undefined });
            } else {
                onFilterChange({ [filterName]: filterValue });
            }
            onApply();
        },
        [onFilterChange, onApply],
    );

    const sortingValue = React.useMemo(() => {
        const activeSortValue = activeFilters?.sort;
        const sortOption = SORT_FIELDS.find((el) => el.id === activeFilters?.sort);

        if (activeSortValue && sortOption) {
            return sortOption;
        }

        const initialSortValue = getFilterByName('SORT', availableFilters)?.defaultValue;
        const initialSortOption = SORT_FIELDS.find((el) => el.id === initialSortValue);

        if (initialSortValue && initialSortOption) {
            return initialSortOption;
        }

        return SORT_FIELDS.find((el) => el.id === ARTICLES_SORTING.CREATED_DESC);
    }, [activeFilters, availableFilters]);

    return (
        <FiltersAndSortContainer className={block()}>
            {getFilterByName('SEARCH_TERM', availableFilters) && (
                <SearchField
                    value={activeFilters?.searchTerm || ''}
                    label={translateKey(WIDGET_ARTICLE_LIST.SEARCH_BY_TEXT)}
                    theme={theme}
                    isDisabled={isFilterDisabled}
                    textFieldProps={getDataAttributes({ element: 'search-field', action: 'search' })}
                    onSearch={(value) => debouncedOnChange(value, 'searchTerm')}
                />
            )}
            {getFilterByName('SORT', availableFilters) ? (
                <Sorters
                    onChange={(sort) => debouncedOnChange(sort, 'sort')}
                    isDisabled={isFilterDisabled}
                    theme={theme}
                    sort={sortingValue?.id}
                    choices={SORT_FIELDS}
                    isChangingOrderEnabled={false}
                    sortButtonProps={getDataAttributes({ element: 'sorters', action: 'sort' })}
                />
            ) : undefined}
        </FiltersAndSortContainer>
    );
};
