import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { ClickAwayProvider } from '@lumapps/lumx/react/utils';
import { StructuredContentEditor } from '@lumapps/structured-content/components/StructuredContentEditor/StructuredContentEditor';
import { useTranslate } from '@lumapps/translations';
import { LegacyWidget } from '@lumapps/widget-base/types';
import type { Wrex } from '@lumapps/wrex/types';

import { WIDGET_CONTRIBUTION } from '../../keys';
import { LegacyWidgetContributionProps } from '../../type';

import './index.scss';

export interface EditableWidgetContributionProps {
    legacyWidget: LegacyWidget<LegacyWidgetContributionProps>;
    wrapperRef: React.MutableRefObject<null>;
    childrenRefs: React.MutableRefObject<React.MutableRefObject<null>[]>;
    exitEditionMode: (content: any) => void;
    onFileUpload: (files: File[]) => Promise<
        {
            id: string;
            url: string;
        }[]
    >;
    content: Wrex.Nodes;
    onChange: React.Dispatch<React.SetStateAction<Wrex.Nodes>>;
}

export const EditableWidgetContribution: React.FC<EditableWidgetContributionProps> = ({
    wrapperRef,
    childrenRefs,
    exitEditionMode,
    onFileUpload,
    content,
    onChange,
}: EditableWidgetContributionProps) => {
    const { get } = useDataAttributes('widget-contribution');
    const { translateKey } = useTranslate();

    if (content) {
        return (
            <ClickAwayProvider callback={() => exitEditionMode(content)} childrenRefs={childrenRefs}>
                <StructuredContentEditor
                    wrapperRef={wrapperRef}
                    placeholder={translateKey(WIDGET_CONTRIBUTION.EDITOR_PLACEHOLDER)}
                    value={content}
                    onChange={onChange}
                    uploadFunction={onFileUpload}
                    isRequired
                    {...get({ element: 'editor' })}
                />
            </ClickAwayProvider>
        );
    }
    return null;
};
