import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { SelectTextField } from '@lumapps/combobox/components/SelectTextField';
import { Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { usePaginatedFrontendSearch } from '@lumapps/utils/hooks/usePaginatedFrontendSearch';

import { useAvailableTags } from '../../hooks/useAvailableTags';
import { FormattedSiteFilter, FormattedTagFilter, SiteFilteredBy, TagFilteredByReference } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';

export interface CustomContentTypeTagsFilterProps {
    theme: Theme;
    scope: string;
    /** Whether the filter should be hidden if there is no available tags. */
    shouldBeHiddenIfEmpty?: boolean;
    /** The list of selected tags */
    selectedTags?: FormattedTagFilter[];
    /** The list of tag references that can be used. */
    tagReferences?: TagFilteredByReference[];
    /** The list of sites related to the content types. */
    sites?: FormattedSiteFilter[];
    /** The list of site references available for the filter. */
    siteReferences?: SiteFilteredBy[];
    /** Does siblings sites are included in the site */
    includeSiblingSites?: boolean;
    /** Number of items per page */
    itemsPerPage?: number;
    /** The callback that will be trigger on each value change of the select */
    onChange: (tags: FormattedTagFilter[]) => void;
    hideSubheader: boolean;
}

const getTagName = (t: FormattedTagFilter) => t.name;

/**
 * Group tags by their custom content types.
 * Tags are always linked to a CCT so tag options should be contextualised
 * because some tags can share the same names, and the only way to differentiate
 * them is to know their parents.
 */
const getTagSectionId = (tag: FormattedTagFilter) => [tag.site?.name, tag.cct?.name].filter(Boolean).join(' - ');

/**
 * A select field to select multiple content type tags,
 * filtered by content type ids.
 * The list of tags will be grouped by ccts.
 *
 * @param CustomContentTypeTagsFilterProps
 * @returns CustomContentTypeTagsFilter
 */
export const CustomContentTypeTagsFilter: React.FC<CustomContentTypeTagsFilterProps> = ({
    scope,
    sites,
    siteReferences,
    includeSiblingSites,
    selectedTags = [],
    tagReferences,
    shouldBeHiddenIfEmpty,
    onChange,
    hideSubheader,
    theme = Theme.light,
    itemsPerPage = 20,
}) => {
    const { translateKey } = useTranslate();

    /** Get all available tags for the current configuration; based on the selected sites. */
    const availableTags = useAvailableTags(tagReferences, sites, siteReferences, includeSiblingSites);

    const { getMoreItems, items, onSearch } = usePaginatedFrontendSearch({
        itemList: availableTags ?? [],
        perPage: itemsPerPage,
        getItemName: getTagName,
    });

    const handleChange = React.useCallback((tags: FormattedTagFilter[] = []) => onChange(tags), [onChange]);

    if (shouldBeHiddenIfEmpty && isEmpty(tagReferences)) {
        return null;
    }

    return (
        <>
            {!hideSubheader && <FilterSubheader label={translateKey(GLOBAL.TAGS)} theme={theme} />}
            <SelectTextField<FormattedTagFilter>
                selectionType="multiple"
                theme={theme}
                scope={scope}
                onLoadMore={getMoreItems}
                onSearch={onSearch}
                autoFilter={false}
                getOptionId="id"
                getOptionName="name"
                getSectionId={getTagSectionId}
                onChange={handleChange}
                label={translateKey(GLOBAL.TAGS)}
                value={selectedTags}
                options={items}
                showEmptyState
            />
        </>
    );
};
