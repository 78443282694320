import React from 'react';

import first from 'lodash/fp/first';

import { UserFilter } from '@lumapps/lumx-filter-and-sort/components/UserFilter';
import { User } from '@lumapps/user/types';

import { PostListFilterBlockProps } from '../../../types';

const CLASSNAME = 'post-author-filter';

export const PostAuthorFilterBlock: React.FC<PostListFilterBlockProps<User>> = ({
    label,
    selectedValues,
    onSelect,
    getChoiceName,
    filterId,
}) => {
    const handleChange = (author: User) => {
        if (author) {
            const { email, lastName, firstName, id, accountType } = author;
            onSelect(filterId, [{ email, lastName, firstName, id, accountType, fullName: getChoiceName(author) }]);
        } else {
            onSelect(filterId, []);
        }
    };

    return (
        <UserFilter
            id={filterId}
            title={label}
            onSelected={handleChange}
            selection={first(selectedValues)}
            className={CLASSNAME}
        />
    );
};
