import last from 'lodash/last';

import { PARAGRAPH } from '@lumapps/wrex-typography/constants';
import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';
import { Editor, Node, Path, Transforms } from '@lumapps/wrex/slate';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';
import { isText } from '@lumapps/wrex/slate/utils/isText';
import type { Wrex } from '@lumapps/wrex/types';

import { LIST_FEATURES, ORDERED_LIST, UNORDERED_LIST } from '../../constants';
import { ListItemElement } from '../../types';
import { createListItem } from '../../utils/createListItem';
import { createUnorderedList } from '../../utils/createUnorderedList';
import { isList } from '../../utils/isList';
import { isListItem } from '../../utils/isListItem';
import { Normalize } from './types';

/**
 * This regex match the correct pattern of list items children, ie.
 * The first one should be a paragraph, as many as needed, then as many lists (ul or ol) as needed can follow, but after a list only other lists are accepted.
 */
const LIST_ITEM_CHILDREN_PATTERN = `^(${PARAGRAPH}(-){0,1})+((${UNORDERED_LIST}|${ORDERED_LIST})(-){0,1})*$`;

/**
 * Normalize list item node.
 */
export const normalizeListItem: Normalize = (editor, [node, path]) => {
    if (!isListItem(node)) {
        return false;
    }

    const [parent, parentPath] = Editor.parent(editor, path);
    Editor.withoutNormalizing(editor, () => {
        if (!isList(parent)) {
            // Wrap list item in unordered list if not in a list already.
            let grandParent;
            try {
                grandParent = Editor.parent(editor, parentPath);
                // eslint-disable-next-line no-empty
            } catch (error) {}
            if (isListItem(parent) && grandParent) {
                Transforms.moveNodes(editor, {
                    at: path,
                    to: [...grandParent[1], (last(parentPath) as number) + 1],
                });
            } else {
                Transforms.wrapNodes(editor, createUnorderedList([]), {
                    at: path,
                });
            }
        }

        if (!node.children.length) {
            // Remove list item if empty.
            Transforms.removeNodes(editor, { at: path });
            return true;
        }

        const childrenTypes = node.children.map((ch) => (ch as Wrex.Element).type);
        const unauthorizedTypeIndex = childrenTypes.findIndex(
            (type) => type !== ORDERED_LIST && type !== UNORDERED_LIST && type !== PARAGRAPH,
        );

        // Check if there is at least one node not authorized as a list item children.
        if (unauthorizedTypeIndex > -1) {
            // We have to check if the unauthorized node is the last child, if not, we have some
            // additional transformations to apply.
            if (unauthorizedTypeIndex !== childrenTypes.length - 1) {
                // Split the li before the sibling following the unauthorized node type.
                Transforms.splitNodes(editor, {
                    at: getSiblingPath([...path, unauthorizedTypeIndex], 'after') as Path,
                });
                // It will create a new li, that we store.
                const splittedListItemPath = [...parentPath, (last(path) as number) + 1];
                // Wrap the new li in a list (the same list type as the original parent list)
                Transforms.wrapNodes(
                    editor,
                    {
                        type: (parent as Wrex.Element).type,
                        children: [],
                    } as Wrex.Element,
                    {
                        at: splittedListItemPath,
                        split: true,
                    },
                );
                // We move this list outside of the parent list, making it its direct sibling.
                Transforms.moveNodes(editor, {
                    to: getSiblingPath(parentPath, 'after') as Path,
                    at: splittedListItemPath,
                });
            }

            // We move the unauthorized node outside of the parent list, making it its direct sibling
            Transforms.moveNodes(editor, {
                to: getSiblingPath(parentPath, 'after') as Path,
                at: [...path, unauthorizedTypeIndex],
            });

            // Remove the now empty <li> parent
            const nodePath = [...path, unauthorizedTypeIndex].slice(0, -1);
            const node = Editor.node(editor, nodePath)[0];
            if (isListItem(node) && node.children.length === 0) {
                Transforms.removeNodes(editor, {
                    at: nodePath,
                });
            }
            // If the parent list is empty, remove it too
            const listPath = parentPath;
            const list = Editor.node(editor, listPath)[0];
            if (isList(list) && list.children.length === 0) {
                Transforms.removeNodes(editor, {
                    at: listPath,
                });
            }

            return true;
        }

        // If the first child is not a paragraph, we insert it
        if (!isParagraph(node.children[0])) {
            Transforms.insertNodes(editor, createParagraph(), {
                at: [...path, 0],
            });
            return true;
        }

        /**
         * Move nested paragraph text on the list item paragraph
         */
        if (isParagraph(node.children[0]) && isParagraph(node.children[0].children[0])) {
            const [paragraph] = Array.from(Node.children(editor, path));
            const paragraphPath = paragraph[1];
            const [child] = Array.from(Node.children(editor, paragraphPath));
            const childPath = child[1];

            if (paragraphPath && childPath) {
                Transforms.moveNodes(editor, { to: getSiblingPath(paragraphPath, 'after') as Path, at: childPath });
                Transforms.removeNodes(editor, { at: paragraphPath });

                return true;
            }
        }

        if (node.children.length > 2 && !childrenTypes.join('-').match(LIST_ITEM_CHILDREN_PATTERN)) {
            let firstInvalidParagraphIndex;
            let currentIndex = 1;
            Transforms.insertNodes(editor, createListItem([]), {
                at: [...parentPath, (last(path) as number) + 1],
            });
            while (currentIndex < childrenTypes.length) {
                if (childrenTypes[currentIndex] === PARAGRAPH) {
                    if (childrenTypes[currentIndex - 1] !== PARAGRAPH) {
                        firstInvalidParagraphIndex = currentIndex;
                    }
                }
                if (firstInvalidParagraphIndex !== undefined) {
                    // Move the last node of the li, until we move the firstInvalidParagraph
                    Transforms.moveNodes(editor, {
                        at: [...path, childrenTypes.length + 1 - currentIndex],
                        to: [...parentPath, (last(path) as number) + 1, 0],
                    });
                }
                currentIndex += 1;
            }

            return true;
        }

        /**
         * Set list color if every child of the list have the same color
         * Only if the markerColor feature is enabled
         */
        const paragraph = node.children[0];
        if (editor.isListFeatureEnabled(LIST_FEATURES.markerColor) && isParagraph(paragraph)) {
            const textChilds = paragraph.children.filter(isText);

            if (textChilds.length > 0) {
                const { color: firstTextColor } = textChilds[0];
                // Journey backend returns null color by default
                if (firstTextColor === null) {
                    return true;
                }

                const lineHaveSameColor = textChilds.every(({ color }) => color === firstTextColor);
                if (lineHaveSameColor) {
                    Transforms.setNodes(editor, { markerColor: firstTextColor } as Partial<ListItemElement>, {
                        at: path,
                    });

                    return true;
                }
            }
        }

        return true;
    });

    return true;
};
