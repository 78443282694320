import React from 'react';

import type { ExtensionProps } from '@lumapps/marketplace/legacy-types';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { useLazyLoadingProgress } from '@lumapps/utils/hooks/useLazyLoadingProgress';

export function useLoadExtensionsShareDialog() {
    const [isShareToExtensionDialog, openShareToExtensionDialog, closeShareToExtensionDialog] = useBooleanState(false);
    const [extensionIsLoaded, setExtensionIsLoaded] = React.useState(false);
    const { onComponentLoaded: onExtensionLoaded } = useLazyLoadingProgress(extensionIsLoaded);
    const [currentExtension, setCurrentExtension] = React.useState<null | ExtensionProps>(null);
    const handleOpenExtension = React.useCallback(
        (extension: ExtensionProps) => {
            setCurrentExtension(extension);
            setExtensionIsLoaded(true);
            openShareToExtensionDialog();
        },
        [openShareToExtensionDialog],
    );
    return {
        extensionIsLoaded,
        onExtensionLoaded,
        currentExtension,
        handleOpenExtension,
        isShareToExtensionDialog,
        openShareToExtensionDialog,
        closeShareToExtensionDialog,
    };
}
