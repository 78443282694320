import { useMemo } from 'react';

import { mdiTable } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { ReactEditor, useSlateStatic } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { ToolbarItem } from '@lumapps/wrex/types';

import { TABLE } from '../../../constants';
import { WREX_TABLE } from '../../../keys';
import { TableEditor } from '../../../types';

export const useTableButton = (otherProps?: any) => {
    const { translateKey } = useTranslate();
    const editorStatic = useSlateStatic() as ReactEditor & TableEditor;

    return useMemo((): ToolbarItem => {
        return {
            type: 'option',
            itemKey: TABLE,
            icon: mdiTable,
            label: translateKey(WREX_TABLE.TABLE_BUTTON),
            otherProps,
            onClick() {
                editorStatic.insertTable({});
                focusAt(editorStatic, editorStatic.selection);
            },
        };
    }, [translateKey, otherProps, editorStatic]);
};
