import { AppId, createEntityManagementRoutes } from '@lumapps/router';

import type { Connector } from './types';

const { baseRoute, createRoute, editRoute, getEditRoute } = createEntityManagementRoutes({
    slug: 'extensions/connectors',
    appId: AppId.backOffice,
});

export interface ConnectorEditParams {
    /** Connector id being edited */
    id: Connector['id'];
}

export {
    baseRoute as platformConnectorsRoute,
    createRoute as connectorCreateRoute,
    editRoute as connectorEditRoute,
    getEditRoute as connectorEdit,
};
