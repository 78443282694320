import React from 'react';

import { margin } from '@lumapps/classnames';
import { Suspense } from '@lumapps/utils/components/Suspense';

import { CommentsProps } from '../../types';
import { CommentListSkeleton } from '../CommentListSkeleton';
import { CommentSkeleton } from '../CommentSkeleton';

const LazyComments = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'widget-comments-block-comments-v2' */
            './Comments'
        ),
);

export const Loading = ({ canComment, shouldDisplayTitle }: Partial<CommentsProps>) => (
    <>
        {canComment && <CommentSkeleton className={margin('top', 'huge')} variant="editor" />}
        <CommentListSkeleton showTitle={shouldDisplayTitle} />
    </>
);

export const Comments = (props: CommentsProps) => (
    <Suspense loadingFallback={<Loading {...props} />}>
        <LazyComments {...props} />
    </Suspense>
);
