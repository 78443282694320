import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiApplication, mdiClose } from '@lumapps/lumx/icons';
import {
    Alignment,
    AspectRatio,
    Emphasis,
    FlexBox,
    Heading,
    IconButton,
    ImageBlock,
    Orientation,
    Size,
    Typography,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WidgetMicroAppSettingsProps } from '../../types';
import './index.scss';

const CLASSNAME = 'micro-app-preview';

export interface MicroAppPreviewProps {
    properties: WidgetMicroAppSettingsProps['properties'];
    onChange: WidgetMicroAppSettingsProps['onChange'];
}

/**
 * Component used to preview the selected micro-app in the widget's settings panel
 * It displays:
 * - A thumbnail with a screenshot of the image if provided
 * - A delete button to deselect the micro-app and clear the preview
 * - The name of the micro-app if provided
 * @param MicroAppPreviewProps
 * @returns MicroAppPreview
 */
export const MicroAppPreview: React.FC<MicroAppPreviewProps> = ({ properties, onChange }) => {
    const { translateObject, translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const thumbnail = properties?.thumbnail;
    const extensionName = properties?.extensionName;

    const handleDelete = () => {
        onChange({ extensionId: null, thumbnail: null, extensionName: null });
    };

    return (
        <FlexBox vAlign={Alignment.center} hAlign={Alignment.center} orientation={Orientation.vertical}>
            <ImageBlock
                actions={
                    <IconButton
                        label={translateKey(GLOBAL.DELETE)}
                        emphasis={Emphasis.low}
                        onClick={handleDelete}
                        icon={mdiClose}
                        className={element('icon-button')}
                        hasBackground
                    />
                }
                image={thumbnail || ''}
                alt=""
                size={Size.xxl}
                thumbnailProps={{
                    aspectRatio: AspectRatio.horizontal,
                    fallback: mdiApplication,
                }}
            />
            {extensionName && (
                <Heading as="h3" typography={Typography.subtitle2}>
                    {translateObject(extensionName)}
                </Heading>
            )}
        </FlexBox>
    );
};
