import { Emoji } from '@lumapps/pickers/types';

/**
 * Provided emojis are sorted by the order in which they appear in the picker by default
 * But as it can be not really intuitive, we change the order to be :
 * 1. Favorite emojis, ordered alphabetically
 * 2. Emojis that starts with the search term, ordered alphabetically
 * 3. Emojis that contains the search term, ordered alphabetically
 */
export const sortEmojiList = ({
    emojis,
    favoriteEmojis,
    searchTerm,
}: {
    emojis: Emoji[];
    favoriteEmojis?: Emoji[];
    searchTerm: string;
}) => {
    const orderedEmojis = [...emojis].sort((a, b) => {
        const aValue = a.shortcodes?.[0];
        const bValue = b.shortcodes?.[0];

        if (!aValue || !bValue) {
            return 0;
        }

        // Put the favorite emojis of result first no matter what
        if (favoriteEmojis && favoriteEmojis.length > 0) {
            const aFavorite = favoriteEmojis.find((emoji) => emoji.shortcodes?.[0] === aValue);
            const bFavorite = favoriteEmojis.find((emoji) => emoji.shortcodes?.[0] === bValue);

            if (!!aFavorite && !bFavorite) {
                return -1;
            }
            if (!aFavorite && !!bFavorite) {
                return 1;
            }

            // Put the most used in the favorites first
            if (!!aFavorite && !!bFavorite) {
                const aFavoriteIndex = favoriteEmojis.indexOf(aFavorite);
                const bFavoriteIndex = favoriteEmojis.indexOf(bFavorite);

                return bFavoriteIndex > aFavoriteIndex ? -1 : 1;
            }
        }

        const isAStartWith = aValue.startsWith(searchTerm);
        const isBStartWith = bValue.startsWith(searchTerm);

        // Put the results that start with searchTerm first
        if (isAStartWith && !isBStartWith) {
            // A should be above B
            return -1;
        }
        if (!isAStartWith && isBStartWith) {
            // B should be above A
            return 1;
        }

        return aValue.localeCompare(bValue);
    });

    return orderedEmojis;
};
