import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox } from '@lumapps/lumx/react';
import { useDimensions } from '@lumapps/responsive';
import { isBreakpoint } from '@lumapps/responsive/utils/breakpoints';

import { FILTERS_MODE } from '../../constants';
import { Filters } from '../Filters';
import { FiltersDropdown } from '../FiltersDropdown';
import { SearchField } from '../SearchField';
import { Sorters, SortersProps } from '../Sorters';

import './index.scss';

export interface FiltersAndSortContainerProps {
    children: React.ReactNode;
    className?: string;
}

const BREAKPOINTS = {
    xxs: 0,
    xs: 435,
    s: 552,
    m: 740,
    l: 1024,
    xl: 1280,
};

const EXPOSED_FACETS_BREAKPOINTS = {
    xxs: 0,
    xs: 435,
    s: 552,
    m: 740,
    l: 1024,
    xl: 1280,
};

const CLASSNAME = 'lumx-filters-and-sort-container';
/**
 * Component that groups the Search, Sort and Filters component and manages their placement as well as
 * responsive.
 * @param FiltersAndSortContainerProps
 * @returns FiltersAndSortContainer
 */
export const FiltersAndSortContainer: React.FC<FiltersAndSortContainerProps> = ({ children, className }) => {
    const { block } = useClassnames(CLASSNAME);

    let searchField: React.ReactNode;
    let filters: React.ReactNode;
    let sorters: React.ReactNode;
    let sortersProps: SortersProps | undefined;
    let filtersMode: FILTERS_MODE = FILTERS_MODE.GROUPED;

    /**
     * We go through the passed in children and retrieve the components that we are expecting to be.
     * This is in order to position them where we want, as well as to retrieve some props from these components
     */
    React.Children.toArray(children).forEach((child) => {
        if (React.isValidElement(child)) {
            if (child.type === SearchField) {
                searchField = child;
            } else if (child.type === Filters) {
                filters = child;
                filtersMode = child.props.mode || FILTERS_MODE.GROUPED;
            } else if (child.type === FiltersDropdown) {
                filters = child;
                filtersMode = FILTERS_MODE.GROUPED;
            } else if (child.type === Sorters) {
                sorters = child;
                sortersProps = child.props;
            }
        }
    });

    const breakpoints = filtersMode === FILTERS_MODE.GROUPED ? BREAKPOINTS : EXPOSED_FACETS_BREAKPOINTS;
    const { currentBreakpoint, ref } = useDimensions({ breakpoints });
    const isFilterAndSortContainerBreakpoint = isBreakpoint(currentBreakpoint).matching(breakpoints);
    const isSmallerThanM = isFilterAndSortContainerBreakpoint.smallerThan('m');
    const isSmallerThanL = isFilterAndSortContainerBreakpoint.smallerThan('l');
    const isSmallerThanS = isFilterAndSortContainerBreakpoint.smallerThan('xs');

    /**
     * When there is not much space, we move the Sorters to a compacted version.
     */
    let sortersVariant = isSmallerThanM ? 'compact' : undefined;

    if (isSmallerThanL && filtersMode !== FILTERS_MODE.GROUPED) {
        sortersVariant = 'compact';
    }

    return (
        <FlexBox
            ref={ref}
            orientation={isSmallerThanS ? 'vertical' : 'horizontal'}
            hAlign="center"
            gap="big"
            className={block(
                { [`${currentBreakpoint}`]: true, [`${filtersMode.toLowerCase()}`]: Boolean(filtersMode) },
                [className],
            )}
        >
            {searchField}
            <FlexBox fillSpace orientation="horizontal" hAlign="center">
                {filters}

                {sorters && sortersProps ? (
                    <Sorters
                        {...sortersProps}
                        sortButtonProps={{
                            ...sortersProps.sortButtonProps,
                            variant: sortersVariant,
                        }}
                    />
                ) : null}
            </FlexBox>
        </FlexBox>
    );
};
