import React from 'react';

import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { list } from '@lumapps/communities/api';
import { CommunitiesListRequestParams } from '@lumapps/communities/types';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';

import { CommunityPickerDialogHook } from '../types';
import { useCommunityPickerFilters } from './useCommunityPickerFilters';

const FILTERS = ['privacy', 'instances'];

export const useCommunityListPicker: CommunityPickerDialogHook<CommunitiesListRequestParams, typeof list> = (
    fetchParams,
    query = '',
    enabledFilters = FILTERS,
) => {
    const lang = useSelector(currentLanguageSelector);
    const { component, sortOrder, filters, resetFilters } = useCommunityPickerFilters({
        enableMultipleInstancesSearch: !fetchParams.instanceId,
        enabledFilters,
        defaultSort: isArray(fetchParams.sortOrder) ? fetchParams.sortOrder[0] : fetchParams.sortOrder,
    });

    const fetchAPIParams: CommunitiesListRequestParams = React.useMemo(
        () => ({
            ...filters,
            ...fetchParams,
            lang,
            query,
            sortOrder,
        }),
        [fetchParams, lang, query, sortOrder, filters],
    );

    return {
        fetchAPIParams,
        fetchAPI: list,
        additionalFilterFields: component,
        resetFilters,
        hasNoFilter:
            !fetchAPIParams.query && !fetchAPIParams.instanceId && (!fetchAPIParams.ids || isEmpty(fetchAPIParams.ids)),
    };
};
