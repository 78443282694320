import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { User } from '@lumapps/user/types';

export interface AuthorSlice {
    author?: User;
    isLoading: boolean;
    hasError: boolean;
}

const initialState = {
    author: undefined,
    isLoading: false,
    hasError: false,
};

const { actions, reducer } = createSlice({
    domain: 'author',
    initialState,
    reducers: {
        fetchAuthorLoading: (state: AuthorSlice) => ({
            ...state,
            isLoading: true,
        }),
        fetchAuthorSuccess: (state: AuthorSlice, action: PayloadAction<User>) => ({
            ...state,
            isLoading: false,
            author: { ...action.payload },
            hasError: false,
        }),
        fetchAuthorError: (state: AuthorSlice) => ({
            ...state,
            isLoading: false,
            hasError: true,
        }),
        fetchAuthorCancel: (state: AuthorSlice) => ({
            ...state,
            isLoading: false,
        }),
    },
});

export { actions, initialState, reducer };
