// istanbul ignore file
/**
 * Because jest can't find the emoji-picker-element-data module
 */
import { SUPPORTED_EMOJI_LANGUAGES } from '../constants';

/**
 * Get the emoji of a specified language
 * @param currentLang the user current language
 * @returns the emoji of the current language if available (fallback to the english emojis)
 */
export const fetchEmojiData = async (currentLang: string) => {
    // Fallback to english data source if the user language have no translated emojis
    const emojiDataLocale = SUPPORTED_EMOJI_LANGUAGES.includes(currentLang) ? currentLang : 'en';
    const emojiData = await import(
        /* webpackMode: "lazy-once" */
        /* webpackExclude: /\.data\.json$/ */
        `emoji-picker-element-data/${emojiDataLocale}/cldr/data.json?url`
    );

    return emojiData;
};
