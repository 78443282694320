import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';

import { margin, useClassnames } from '@lumapps/classnames';
import { BaseActions, BaseActionsProps } from '@lumapps/lumx-buttons/components/Actions/BaseActions';
import { ThumbnailWithIcon, ThumbnailWithIconProps } from '@lumapps/lumx-images/components/ThumbnailWithIcon';
import {
    GenericBlock,
    Heading,
    HeadingProps,
    Flag,
    FlagProps,
    FlexBox,
    InlineList,
    TextProps,
    Text,
    ColorPalette,
    ColorVariant,
    GenericBlockProps,
} from '@lumapps/lumx/react';

import { TITLE_TYPOGRAPHY, EXCERPT_TYPOGRAPHY, THUMBNAIL_DEFAULT_SIZE } from '../../constants';
import { AttributeProps, BaseAttribute } from '../Attributes/BaseAttribute';
import { UserActionAttribute } from '../Attributes/UserActionAttribute';
import { UserAttribute, UserAttributeProps } from '../Attributes/UserAttribute';

import './index.scss';

export interface GenericEntityBlockProps extends Omit<Partial<GenericBlockProps>, 'actions'> {
    /** props to pass in in order to display an image besides the drawer's title */
    thumbnail?: ThumbnailWithIconProps;
    /** custom image if needed, please always use `thumbnail` instead */
    customImage?: React.ReactNode;
    /** title for the entity */
    title: string | React.ReactNode;
    /** custom props for heading */
    titleProps?: HeadingProps;
    /** status for the entity */
    status?: FlagProps;
    /** PLEASE DO NOT USE IF THERE IS NO NEED FOR THIS AND YOU CAN MANAGE ALL YOUR ACTIONS WITH `status` */
    customStatus?: React.ReactNode;
    /** second line of data to be display on the block, below the title. Usually displays metadata associated to the entity */
    metadata?: React.ReactNode | React.ReactNode[];
    /** whether the entity is disabled or not */
    isDisabled?: boolean;
    /**
     * Simple description that will be displayed below the title, if you want to avoid creating
     * complex components list for metadata. It does not display a list of elements, just one.
     * THIS OVERRIDES THE `metadata`a prop.
     */
    description?: string | React.ReactNode;
    /** additional props for the description */
    descriptionProps?: Partial<AttributeProps>;
    /** text to be displayed as the snippet of the block */
    excerpt?: string;
    /** custom props to be passed in to the excerpt. */
    excerptProps?: Partial<TextProps>;
    /** PLEASE DO NOT USE IF THERE IS NO NEED FOR THIS AND YOU CAN MANAGE ALL YOUR ACTIONS WITH `excerpt` */
    customExcerpt?: React.ReactNode;
    /** second to last line of data to be displayed on the block. Usually displays attributes related to the entity, like comments, views, etc. */
    additionalInfo?: React.ReactNode | React.ReactNode[];
    /** last line of data to be displayed on the block. Usually displays the author, creation and update dates. */
    authorship?: React.ReactNode | React.ReactNode[];
    /** props to be passed in to setup the different actions for the row */
    actions?: BaseActionsProps;
    /** PLEASE DO NOT USE IF THERE IS NO NEED FOR THIS AND YOU CAN MANAGE ALL YOUR ACTIONS WITH `actions` */
    customActions?: React.ReactNode;
    /** accessibility and tracking purposes */
    scope?: string;
    /** additional props to be passed in to the thumbnail's wrapper */
    figureProps?: Partial<GenericBlockProps>;
    /** should the title be centred if there is only the title displayed */
    shouldCenterTitle?: boolean;
}

const CLASSNAME = 'lumx-generic-entity-block';
const BaseGenericEntityBlock: React.FC<GenericEntityBlockProps> = ({
    thumbnail,
    title,
    titleProps,
    status,
    metadata,
    excerpt,
    additionalInfo,
    authorship,
    actions,
    excerptProps,
    customExcerpt,
    customActions,
    customStatus,
    customImage,
    scope,
    figureProps,
    description,
    descriptionProps,
    isDisabled = false,
    shouldCenterTitle = false,
    ...props
}) => {
    const { element, block } = useClassnames(CLASSNAME);
    const onlyHasTitle =
        isEmpty(excerpt) &&
        isEmpty(additionalInfo) &&
        isEmpty(customExcerpt) &&
        isEmpty(authorship) &&
        isEmpty(metadata) &&
        isEmpty(description);

    return (
        <GenericBlock
            orientation="horizontal"
            hAlign={onlyHasTitle && shouldCenterTitle ? 'center' : 'top'}
            {...props}
            className={block({ 'is-disabled': isDisabled }, [props.className])}
        >
            {thumbnail || customImage ? (
                <GenericBlock.Figure className={element('figure')} {...figureProps}>
                    {thumbnail && !customImage ? (
                        <ThumbnailWithIcon
                            size={THUMBNAIL_DEFAULT_SIZE}
                            aspectRatio="square"
                            variant="rounded"
                            {...thumbnail}
                            className={element('thumbnail', [thumbnail.className])}
                        />
                    ) : null}

                    {customImage}
                </GenericBlock.Figure>
            ) : null}

            <GenericBlock.Content gap="tiny" className={element('content')}>
                <FlexBox orientation="horizontal" gap="regular" hAlign="center">
                    <Heading
                        as="h2"
                        typography={TITLE_TYPOGRAPHY}
                        truncate
                        {...titleProps}
                        className={element('title', [titleProps?.className])}
                    >
                        {title}
                    </Heading>

                    {status && !customStatus ? <Flag {...status} /> : null}

                    {customStatus}
                </FlexBox>

                {metadata && !description ? (
                    <InlineList
                        color={ColorPalette.dark}
                        colorVariant={ColorVariant.L2}
                        className={element('attributes')}
                    >
                        {metadata}
                    </InlineList>
                ) : null}

                {isString(description) ? (
                    <BaseAttribute key="description" as="p" value={description} {...descriptionProps} />
                ) : (
                    description
                )}

                {excerpt && !customExcerpt ? (
                    <Text
                        as="p"
                        typography={EXCERPT_TYPOGRAPHY}
                        {...excerptProps}
                        className={element('excerpt', [margin('vertical', 'tiny'), excerptProps?.className])}
                    >
                        {excerpt}
                    </Text>
                ) : null}

                {customExcerpt}

                {additionalInfo ? (
                    <InlineList
                        color={ColorPalette.dark}
                        colorVariant={ColorVariant.L2}
                        className={element('attributes')}
                    >
                        {additionalInfo}
                    </InlineList>
                ) : null}

                {authorship ? (
                    <InlineList
                        color={ColorPalette.dark}
                        colorVariant={ColorVariant.L2}
                        className={element('attributes')}
                    >
                        {authorship}
                    </InlineList>
                ) : null}
            </GenericBlock.Content>

            {actions || customActions ? (
                <GenericBlock.Actions hAlign="center" className={element('actions')}>
                    {actions && !customActions ? (
                        <BaseActions {...actions} isInline describedBy={titleProps?.id} scope={scope} />
                    ) : null}

                    {customActions}
                </GenericBlock.Actions>
            ) : null}
        </GenericBlock>
    );
};

/**
 * Component that displays any entity in a block fashion, with a common layout reused across the product.
 * It allows to display a title, an image, metadata, excerpt and more options for any entity.
 *
 * @param GenericEntityBlockProps
 * @family Blocks
 * @returns GenericEntityBlock
 */
export const GenericEntityBlock = Object.assign(BaseGenericEntityBlock, {
    /**
     * Component to be used for displaying an entity with attributes associated to the entity.
     * This will display an image on the left hand side, the entity's title, as well as metadata
     * associated to it.
     *
     * @family Blocks
     * @param AttributeProps
     * @returns Attribute
     */
    Attribute: BaseAttribute,
    /**
     * Component to be used for displaying users while using the `GenericEntityBlock`
     * pattern.
     *
     * @param UserBlockProps
     * @family Blocks
     * @returns UserAttribute
     */
    User: (props: UserAttributeProps) => <UserAttribute {...props} />,
    /**
     * Component to be used for displaying an action that a user has performed while using the `GenericEntityBlock`
     * pattern. For example, when an entity was created, or when an entity was updated.
     *
     * @param UserActionProps
     * @family Blocks
     * @returns UserActionAttribute
     */
    UserAction: UserActionAttribute,
});
