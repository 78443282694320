import get from 'lodash/get';

import { addBlob } from '@lumapps/lumx-images/components/BlobThumbnail/store';

/////////////////////////////

function FeaturedImageService(
    $rootScope,
    AbstractPicker,
    Config,
    Content,
    Features,
    Media,
    Translation,
    Utils,
    Upload,
    Document,
    Instance,
) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The media picker id.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    service.MEDIA_PICKER_ID = 'media-picker-content-thumbnail';
    /**
     * Whether a file is currently being uploaded
     */
    service.isUploading = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    service.Config = Config;
    service.Content = Content;
    service.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Media picker is closed, add new media uploaded to a local variable.
     */
    function _onMediaPickerClose() {
        const currentContent = Content.getCurrent();
        const translatedMedia = Media.getMediaContent(currentContent.mediaThumbnail, Translation.inputLanguage, true);

        currentContent.thumbnail = get(translatedMedia, 'value');
    }

    /**
     * Get upload url request parameters.
     *
     * @return {Object} Request parameters.
     */
    async function _getUploadUrlRequestParameters() {
        const currentSite = Instance.getCurrentInstanceId();

        const parentPath = 'provider=local/site=' + currentSite;

        return {
            parentPath,
            shared: false,
        };
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add content thumbnail.
     *
     * @param {Object} media The new media uploaded.
     */
    function addContentThumbnail(responses, _, files) {
        const media = responses[0].items[0];

        const currentContent = Content.getCurrent();
        const translatedMedia = Media.getMediaContent(media, Translation.inputLanguage, true);
        currentContent.thumbnail = translatedMedia.value;
        service.featuredImage = translatedMedia.servingUrl;

        if (media.content[0].type === 'image' && (media.source === 'local' || media.source === 'haussmann_media')) {
            // Provide a blob url on featured image upload
            responses.forEach((res, index) => {
                const mediaThumbnail = Document.getImageThumbnailForMedia(res.items[0]);
                const file = files[index];
                addBlob({ ...mediaThumbnail, blobUrl: URL.createObjectURL(file.file || file) }, true);
            });
        }

        currentContent.mediaThumbnail = media;

        $rootScope.$broadcast('update-content-thumbnail');
        $rootScope.$broadcast('update-featured-image-thumbnail');
    }

    /**
     * Delete content thumbnail.
     */
    function deleteContentThumbnail() {
        Content.getCurrent().mediaThumbnail = undefined;
        Content.getCurrent().thumbnail = undefined;

        $rootScope.$broadcast('delete-content-thumbnail', Content.getCurrent().id);
    }

    /**
     * Open media picker for the content thumbnail.
     */
    function openMediaPicker() {
        Utils.waitForAndExecute(`#${service.MEDIA_PICKER_ID}`, AbstractPicker);
    }

    /**
     * Callback when an image have been picked from the media picker
     * @param {any} image the picked image
     */
    function onMediaPickerConfirm(image) {
        const content = Content.getCurrent();
        const translatedMedia = Media.getMediaContent(image, Translation.inputLanguage, true);
        content.mediaThumbnail = image;
        content.thumbnail = translatedMedia.value;

        $rootScope.$broadcast('update-content-thumbnail');
        $rootScope.$broadcast('update-featured-image-thumbnail');
    }

    /**
     * Upload an image picked from the computer
     * @param {File[]} files the picked files
     * @param {any} cb callback on upload success
     */
    async function onUploadImageDialogFromComputerConfirm(files, cb) {
        const uploadUrlParameters = {
            fileName: files[0].name,
            lang: Translation.inputLanguage,
            ...(await _getUploadUrlRequestParameters()),
        };
        service.isUploading = true;
        const res = await Upload.loadUploadUrlPromise(uploadUrlParameters, Upload.DOCUMENT_TYPE);

        const content = Content.getCurrent();

        await Upload.httpRequest(res.uploadUrl, {}, cb, undefined, content.id, files, true);
        service.isUploading = false;
    }

    /////////////////////////////

    service.addContentThumbnail = addContentThumbnail;
    service.deleteContentThumbnail = deleteContentThumbnail;
    service.openMediaPicker = openMediaPicker;
    service.onUploadImageDialogFromComputerConfirm = onUploadImageDialogFromComputerConfirm;
    service.onMediaPickerConfirm = onMediaPickerConfirm;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the media picker is closed, add new media uploaded to a local variable.
     *
     * @param {Event}  evt           The original event triggering this method.
     * @param {string} mediaPickerId The id of the closed media picker.
     */
    $rootScope.$on('abstract-picker__close-end', (evt, mediaPickerId) => {
        if (mediaPickerId !== service.MEDIA_PICKER_ID) {
            return;
        }

        _onMediaPickerClose();
    });
}

/////////////////////////////

angular.module('Services').service('FeaturedImage', FeaturedImageService);

export { FeaturedImageService as FeaturedImage };
