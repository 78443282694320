/* eslint-disable  */
import { persistentCache } from '@lumapps/cache/api/persistentStore';
import { LEGACY_EMPTY_COMMUNITY_TEMPLATE, SAVE_AS_TEMPLATE_DATA_STORAGE_KEY } from '@lumapps/communities/constants';
import { CommunityPrivacy } from '@lumapps/communities/types';
import loFind from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';

/////////////////////////////

function ContentFrontController(
    $q,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    Community,
    CommunityTemplate,
    CommunityTemplates,
    Config,
    Content,
    ContentTemplate,
    CustomContentType,
    Customer,
    Directory,
    EndUserTranslation,
    Header,
    InitialSettings,
    Instance,
    Layout,
    MainNav,
    Features,
    Media,
    MediaConstant,
    Metadata,
    Notification,
    Template,
    TemplateBuilder,
    Translation,
    User,
    Utils,
    Widget,
    resolveCustomContentType,
) {
    'ngInject';

    // This is here to trick the linter into thinking that the blocking resolve injection is used.
    angular.noop(resolveCustomContentType);

    /////////////////////////////

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains the actions for the floating toolbar when designing a community template.
     * This is an array of row of buttons. So an array containing other arrays. Inner arrays contains the buttons.
     *
     * @type {Array}
     * @constant
     */
    vm.FLOATING_TOOLBAR_BUTTONS = [];

    /**
     * The list of content types.
     *
     * @type {Array}
     */
    vm.contentTypes = InitialSettings.CONTENT_TYPES;

    /**
     * Indicates if it's an old content list.
     *
     * @type {boolean}
     */
    vm.isOldContentList = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Content = Content;
    vm.EndUserTranslation = EndUserTranslation;
    vm.Header = Header;
    vm.Layout = Layout;
    vm.Utils = Utils;

    vm.currentView = $state.current.name;
    vm.currentParams = $state.params;
    vm.isNotificationDisplayable = false;

    // Force the notification to close on route change
    $scope.$on('$stateChangeStart', () => {
        vm.isNotificationDisplayable = false;
    });

    $scope.$on('$stateChangeSuccess', function onStateChangeStart(evt, toState) {
        vm.currentView = $state.current.name;
        vm.currentParams = $state.params;
    });

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Change the community subtemplate beeing edited.
     *
     * @param {string} subTemplate The sub template to edit.
     */
    function _changeSubTemplate(subTemplate) {
        const { contentType } = $stateParams;
        if (contentType !== 'community') {
            return;
        }

        const currentContent = Content.getCurrent();
        if (angular.isUndefinedOrEmpty(currentContent)) {
            return;
        }

        $rootScope.$broadcast('close-component-settings');

        CommunityTemplate.updateTemplate(currentContent);
        CommunityTemplate.setAsCurrent(currentContent, subTemplate);

        Content.originalContent.template = angular.fastCopy(currentContent.template);
    }

    /**
     * Get designer mode.
     * Could be: default, locked, basic or simple.
     *
     * @return {string} The current designer mode.
     */
    function _getDesignerMode() {
        if (!$state.current.data.designer) {
            return undefined;
        }

        const currentContent = Content.getCurrent();
        if (angular.isUndefinedOrEmpty(currentContent)) {
            return undefined;
        }

        const action = Content.getAction();
        if (action === 'style') {
            // Force non-basic mode when editing styles.
            currentContent.template.basicMode = false;
            return 'locked';
        }

        const contentType = $stateParams.contentType || 'content';

        const asDraft = currentContent.status === Config.CONTENT_STATUS.DRAFT.value;
        if (!Content.isEditable(asDraft, true)) {
            return 'locked';
        }

        const TemplateService = contentType === 'content' ? Template : CommunityTemplates;
        if ($stateParams.isTemplate || $stateParams.type === 'template') {
            // Disable edition if the template is inherited.
            return action === 'create' || TemplateService.isEditable(TemplateService.getCurrent())
                ? 'default'
                : 'locked';
        }

        const template = currentContent.template || {};
        if (template.fixedLayout && template.fixedWidgets) {
            return template.basicMode ? 'basic' : 'simple';
        }

        return 'default';
    }

    /**
     * Check if we are designing a community or a community template.
     *
     * @return {boolean} If we are designing a community or a community template.
     */
    function _isDesigningCommunity() {
        const contentAction = Content.getAction();
        const contentType = $stateParams.contentType || 'content';

        const community = contentType === 'community' ? Community.getCurrent() : undefined;
        const isSpace = Community.isSpace(community);

        return contentType === 'community' && !isSpace && (contentAction === 'create' || contentAction === 'edit');
    }

    /**
     * Set content header.
     *
     * @param {boolean} copyHeader If we will copy header or not.
     */
    function _setHeader(copyHeader) {
        let currentContent = Content.getCurrent();

        /**
         * If the content does not contain any header ID.
         * We do not need to fetch the header.
         * */
        if (angular.isUndefinedOrEmpty(currentContent.header)) {
            /**
             * However, we need to reset the current Header if it's not the default one.
             * This is required because we can have relic from a previous page.
             * */
            if (Header.getCurrent() && Header.getDefaultHeader() && Header.getCurrent().id !== Header.getDefaultHeader().id) {
                Header.setCurrent(Header.getDefaultHeader());
                $rootScope.$broadcast('template__get-header');
            }
            return;
        }

        Header.get(currentContent.header, function getHeader(response) {
            Header.setCurrent(response);
            currentContent = Content.getCurrent();

            if (copyHeader) {
                currentContent.header = undefined;
                currentContent.headerDetails = Header.getCurrent();
                if (angular.isDefinedAndFilled(currentContent.headerDetails)) {
                    currentContent.headerDetails.id = undefined;
                }
            }

            $rootScope.$broadcast('template__get-header');
        });
    }

    /**
     * Resets all global widgets of the current content.
     * This is needed when we create a new content based on an existing template.
     * When global widgets where attached to a template, the template was using an old version
     * of the global widgets it contains.
     * This was causing fake positive on global widget changes. So the global widgets are initialized
     * at their most recent version in order to trully detect real changes.
     */
    function _resetGlobalWidgets() {
        const globalWidgets = ContentTemplate.getElementList(Content.getCurrent(), 'widget', 'isGlobal', true);

        angular.forEach(globalWidgets, function forEachGlobalWidgets(globalWidget) {
            // We need to copy the widget in order to not mutate it directly.
            const initialWidget = angular.fastCopy(
                loFind(Widget.getGlobalWidgets(), {
                    id: globalWidget.id,
                }),
            );

            angular.extend(globalWidget, initialWidget);
        });
    }

    /**
     * Initialize create mode.
     */
    function _initCreateMode() {
        let isResetGlobalWidgetNeeded = false;
        let header, template;

        const contentType = $stateParams.contentType || 'content';
        const subTemplate = $stateParams.template || 'posts';
        const type = contentType === 'content' ? $stateParams.type : subTemplate;

        const currentTemplate = Template.getCurrent();
        if (angular.isDefinedAndFilled($stateParams.template) && currentTemplate) {
            template = currentTemplate;
            // eslint-disable-next-line prefer-destructuring
            header = currentTemplate.header;
            isResetGlobalWidgetNeeded = true;
        } else {
            template = TemplateBuilder.createBaseTemplate(contentType, type, $stateParams.customContentType);
        }

        if (isResetGlobalWidgetNeeded) {
            Content._fixGlobalWidgets(Content.getCurrent());
            _resetGlobalWidgets();
        }

        const content = {
            author: User.getConnected().email,
            customer: Customer.getCustomerId(),
            header,
            instance: Instance.getCurrentInstanceId(),
            properties: {
                ...template.properties,
                /**
                 * This property is return by the api for Templates created from content
                 * but overrides the endDate of the content type settings
                 */
                lastLifeCycle: undefined,
            },
            status: Config.CONTENT_DEFAULT_STATUS,
        };

        if (contentType === 'content') {
            angular.extend(content, {
                template,
                type: $stateParams.type,
            });
        } else if (contentType === 'community') {
            angular.extend(content, {
                templates: [],
                type: InitialSettings.CONTENT_TYPES.COMMUNITY,
            });

            angular.forEach(Community.availableViews, function forEachAvailableViews(view) {
                content.templates.push(CommunityTemplate.getDefaultPageTemplate(view.id));
            });

            /**
             * Retrieve saved data in localStorage, if any.
             * This means the user comes from the new react app.
             */
            const storage = persistentCache.retrieve(SAVE_AS_TEMPLATE_DATA_STORAGE_KEY);
            const hasLocalStorage = !angular.isUndefinedOrEmpty(get(storage, 'fromCommunity'));

            /**
             * Retrieve saved template in angularService, if any.
             * This means the user comes from the legacy backoffice.
             */
            const currentCommunityTemplate = CommunityTemplates.getCurrent();
            const hasInService = !angular.isUndefinedOrEmpty(get(currentCommunityTemplate, 'fromCommunity'));

            // If there is "fromCommunity" query in state and
            // a stored community template in the service, use it.
            if ($stateParams.fromCommunity && hasInService) {
                content.templates = currentCommunityTemplate.templates;

                // If there is "fromCommunity" query in state and
                // data in the localStorage, use it.
            } else if ($stateParams.fromCommunity && hasLocalStorage) {
                CommunityTemplates.createNew();

                // If the url says the creation is made from a community
                // and there is a community saved in local storage,
                // pre-fill the template with stored data.
                if ($stateParams.fromCommunity && !angular.isUndefinedOrEmpty(get(storage, 'fromCommunity'))) {
                    const currentCommunityTemplate = CommunityTemplates.getCurrent();
                    const community = get(storage, 'community');
                    const templates = get(storage, 'templates');

                    currentCommunityTemplate.name = community.title;
                    currentCommunityTemplate.privacy = community.privacy || LEGACY_EMPTY_COMMUNITY_TEMPLATE.privacy;
                    currentCommunityTemplate.templates = templates || LEGACY_EMPTY_COMMUNITY_TEMPLATE.templates;
                    currentCommunityTemplate.postStatuses =
                        community.postStatuses || LEGACY_EMPTY_COMMUNITY_TEMPLATE.postStatuses;
                    currentCommunityTemplate.postTypes =
                        community.postTypes || LEGACY_EMPTY_COMMUNITY_TEMPLATE.postTypes;
                    currentCommunityTemplate.tagsDetails =
                        community.tagsDetails || LEGACY_EMPTY_COMMUNITY_TEMPLATE.tagsDetails;
                    CommunityTemplates.setCurrent(currentCommunityTemplate);

                    content.templates = currentCommunityTemplate.templates;
                    // Empty local storage
                    persistentCache.store(SAVE_AS_TEMPLATE_DATA_STORAGE_KEY, undefined);
                }
                // If no condition matches, simply create a new community template
            } else {
                CommunityTemplates.createNew();
            }
        }

        Content.setCurrent(content);

        _setHeader(true);

        const currentContent = Content.getCurrent();

        if (contentType === 'content') {
            if (currentContent.type === InitialSettings.CONTENT_TYPES.DIRECTORY) {
                Directory.setCurrent({
                    customer: Customer.getCustomerId(),
                    instance: Instance.getCurrentInstanceId(),
                    template: {
                        components: [],
                    },
                });
            }

            if (angular.isDefinedAndFilled($stateParams.customContentType)) {
                currentContent.customContentType = $stateParams.customContentType;

                currentContent.customContentTypeDetails = CustomContentType.getCurrent();
            }
        } else if (contentType === 'community') {
            CommunityTemplate.setAsCurrent(currentContent, subTemplate);
        }

        // Reset unassigned Media list.
        Media.setUnassignedMedia([]);

        Content.originalContent = angular.fastCopy(currentContent);
    }

    /**
     * Initialize edit mode.
     */
    function _initEditMode() {
        const currentContent = Content.getCurrent();

        _resetGlobalWidgets();

        // Handle backward compatibility for the content list module.
        if (currentContent.type === vm.contentTypes.CUSTOM_LIST) {
            TemplateBuilder.reworkContentListTemplate(currentContent);
        }
    }

    /**
     * Initialize edit mode for directories.
     */
    function _initDirectoryEditMode() {
        const currentContent = Content.getCurrent();

        Directory.get(currentContent.externalKey, function onDirectoryGet(response) {
            TemplateBuilder.reworkDirectoryTemplate(currentContent, response.directoryProperties);
        });
    }

    /**
     * Initialize template mode.
     */
    function _initTemplateMode() {
        const contentType = $stateParams.contentType || 'content';

        const TemplateService = contentType === 'content' ? Template : CommunityTemplates;
        const currentTemplate = TemplateService.getCurrent();

        const content = {
            author: User.getConnected().email,
            customer: Customer.getCustomerId(),
            header: currentTemplate.header,
            instance: Instance.getCurrentInstanceId(),
            properties: currentTemplate.properties,
            status: Config.CONTENT_DEFAULT_STATUS,
        };

        if (contentType === 'content') {
            angular.extend(content, {
                template: currentTemplate,
                type: 'template',
            });
        } else if (contentType === 'community') {
            angular.extend(content, {
                templates: currentTemplate.templates,
                type: InitialSettings.CONTENT_TYPES.COMMUNITY,
            });
        }

        Content.setCurrent(content);

        _resetGlobalWidgets();
        _setHeader();

        const currentContent = Content.getCurrent();

        if (contentType === 'community') {
            CommunityTemplate.setAsCurrent(currentContent, $stateParams.subTemplate);
        }

        Content.originalContent = angular.fastCopy(currentContent);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get content class.
     *
     * @return {Array} The list of classes for the current content.
     */
    function getContentClass() {
        let contentClass = [];
        const currentContent = Content.getCurrent();

        if (angular.isUndefinedOrEmpty(currentContent)) {
            return contentClass;
        }

        const contentViewMode = Content.getViewMode();
        const contentAction = Content.getAction();

        // Content classes.
        if (angular.isDefinedAndFilled(currentContent.properties) && currentContent.properties.class) {
            contentClass = union(contentClass, Utils.getCustomClass(currentContent.properties.class, 'content--'));
        }

        // Metadata classes.
        if (angular.isDefinedAndFilled(currentContent.metadata)) {
            const metadataClasses = Metadata.getMetadataClasses(currentContent.metadata, 'content--metadata-');

            contentClass = contentClass.concat(metadataClasses);
        }

        // Designer class.
        if (contentViewMode === 'default') {
            contentClass.push('content--designer-mode');

            if ((
                Content.isCurrentDesignerMode('NEW_SIMPLE_BUILDER_MODE') ||
                Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')
            )) {
                contentClass.push('content--fixed-layout');
            }
        } else if (contentViewMode === 'simple') {
            contentClass.push('content--simple-designer-mode');
        } else if (contentViewMode === 'basic') {
            contentClass.push('content--basic-designer-mode');
        }

        // Layout customization classes.
        if (contentAction === 'get' && !Layout.isCustomizationLocked) {
            contentClass.push('content--is-layout-customization-enabled');
        } else if (contentAction === 'get' && Layout.isCustomizationLocked) {
            contentClass.push('content--is-layout-customization-disabled');
        }

        if (angular.isDefinedAndFilled(currentContent.type)) {
            contentClass.push(`content--type-${currentContent.type.toLowerCase().replace('_', '-')}`);
        }

        return contentClass;
    }

    function isSpace() {
        const currentCommunity = Community.getCurrent();

        return Community.isSpace(currentCommunity);
    }

    function getMarginTop() {
        const marginTop = Utils.getMarginTop(Header, Features, Layout);

        if (isSpace() && marginTop < 0) {
            return 0;
        }

        return marginTop;
    }

    /////////////////////////////

    vm.getContentClass = getContentClass;
    vm.isSpace = isSpace;
    vm.getMarginTop = getMarginTop;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        Content.setAction($state.current.data.action);

        // Flip the switch when the page is ready to load the last widgets.
        // See cell_directive.js -> isWidgetLoadable for the usage of this variable.
        Content.displayAllWidgets = false;
        $timeout(function displayAllWidgets() {
            Content.displayAllWidgets = true;
        }, 3000);

        const contentAction = Content.getAction();
        let currentContent = Content.getCurrent();

        if (contentAction === 'create') {
            _initCreateMode();
        } else if ($stateParams.isTemplate && $stateParams.isTemplate === 'true') {
            _initTemplateMode();
        } else if (contentAction === 'edit' && angular.isDefined(currentContent.customContentType)) {
            _initEditMode();
        } else if (contentAction === 'edit' && currentContent.type === vm.contentTypes.DIRECTORY) {
            _initDirectoryEditMode();
        } else if (contentAction === 'edit' && currentContent.type === vm.contentTypes.COMMUNITY) {
            // Initialize the template swap.
            const subTemplate = $stateParams.subTemplate || 'posts';
            CommunityTemplate.setAsCurrent(currentContent, subTemplate);

            Content.originalContent = angular.fastCopy(currentContent);
            _resetGlobalWidgets();
        }

        currentContent = Content.getCurrent() || {};

        vm.isNotificationDisplayable = currentContent.canEdit;

        const promise = Notification.is.initializing ? Notification.initializationPromise : $q.resolve();

        promise.then(function onNotificationServiceReady() {
            Notification.setAllAsRead(get(currentContent, 'uid', -1));
        });

        if (contentAction !== 'get' && Translation.getLang('current') !== Translation.inputLanguage) {
            MainNav.init();
        } else {
            Content.createBreadcrumb();

            vm.isOldContentList =
                contentAction === 'get' &&
                currentContent.type === vm.contentTypes.CUSTOM_LIST &&
                angular.isDefined(currentContent.properties) &&
                (angular.isDefined(currentContent.properties.showFilter) ||
                    angular.isDefined(currentContent.properties.contentFields));

            // Handle backward compatibility for the content list module.
            if (currentContent.type === vm.contentTypes.CUSTOM_LIST && !vm.isOldContentList) {
                TemplateBuilder.reworkContentListTemplate(currentContent);
            }
        }

        if (Content.isDesignerInNewSimpleMode()) {
            Content.getCurrent().template.fixedLayout = true;
            Content.getCurrent().template.fixedWidgets = true;
        }

        if (!vm.Content.initialContributionModeSet) {
            Content.setViewMode(_getDesignerMode());
        }

        if (_isDesigningCommunity()) {
            let buttons = [];

            const isEditingCommunityTemplate =
                includes($state.current.name, 'create') ||
                (includes($state.current.name, 'edit') && $stateParams.isTemplate);

            let propertyName = 'subTemplate';
            if (includes($state.current.name, 'create')) {
                propertyName = 'template';
            }

            // Remove CFL and Events static pages from the editable pages list on community layout
            const views = isEditingCommunityTemplate
                ? map(Community.availableViews, 'id').filter((view) => view !== 'files' && view !== 'events')
                : Community.getAvailableViews(currentContent).filter((view) => view !== 'files' && view !== 'events');

            angular.forEach(views, function forEachAvailableViews(view) {
                const button = angular.fastCopy(Community.availableViews[view]);
                button.action = function buttonAction(actionButton) {
                    const previouslyActivatedButton = loFind(buttons, {
                        id: $stateParams[propertyName],
                    });
                    if (angular.isDefinedAndFilled(previouslyActivatedButton)) {
                        previouslyActivatedButton.isActive = false;
                    }

                    actionButton.isActive = true;

                    const newStateParams = {};
                    newStateParams[propertyName] = actionButton.id;

                    $state.go($state.current.name, angular.extend($stateParams, newStateParams), {
                        notify: false,
                    });
                    _changeSubTemplate(actionButton.id);
                };

                if (view === $stateParams[propertyName]) {
                    button.isActive = true;
                }

                // TODO[Greg] : A quick fix while the Google and Microsft Drive template have different behaviour.
                if (
                    view === Community.availableViews.media.id &&
                    (get(Content.getCurrent(), 'driveFolder.source') === MediaConstant.PROVIDERS.microsoft ||
                        (isEditingCommunityTemplate && User.isMicrosoft()))
                ) {
                    button.icon = 'folder';
                    button.label = 'FRONT.COMMUNITY.DRIVE_VIEW';
                }

                if (
                    view === Community.availableViews.calendar.id &&
                    (Community.isOutlookCommunity(currentContent) || (isEditingCommunityTemplate && User.isMicrosoft()))
                ) {
                    button.label = 'FRONT.COMMUNITY.CALENDAR_VIEW';
                }

                buttons.push(button);
            });

            buttons = sortBy(buttons, 'order');

            vm.FLOATING_TOOLBAR_BUTTONS = [buttons];
        }
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('ContentFrontController', ContentFrontController);

/////////////////////////////

export { ContentFrontController };
