/* istanbul ignore file */
import BaseApi, { ServerListResponse } from '@lumapps/base-api';
import { getListEvents } from '@lumapps/events/api/getEvents';
import { SpaceServiceProvider } from '@lumapps/spaces/types';

import { LegacyCalendarEvent, ListEventParams } from '../types';
import { mapLumappsEvents } from '../utils/mapLumappsEvents';

const PATH = 'calendar';

export const calendarApi = new BaseApi({
    path: PATH,
});

export const eventsQueryKeys = {
    all: () => [PATH] as const,
    list: (params: ListEventParams[]) => [...eventsQueryKeys.all(), 'list', ...params] as const,
};

export const listEvents = (params: ListEventParams) =>
    calendarApi.get<ServerListResponse<LegacyCalendarEvent>>('/event/list', { params });

export const fetchCalendarEvents = async (params: ListEventParams) => {
    const events = await listEvents({ ...params, callId: params.calendarId });

    return events.data as ServerListResponse<LegacyCalendarEvent>;
};

/* Fetch Lumapps Events from a space */
export const fetchLumappsEvents = async (lumappsEventsFetchParams: ListEventParams) => {
    const { data } = await getListEvents({
        communityIds: [lumappsEventsFetchParams.calendarId],
        endAfter: lumappsEventsFetchParams.startDate,
        startBefore: lumappsEventsFetchParams.endDate,
        maxResults: lumappsEventsFetchParams.maxResults,
        cursor: lumappsEventsFetchParams.cursor,
    });
    return mapLumappsEvents(lumappsEventsFetchParams, data);
};

/**
 * Fetch events in bulk for each calendar's specified.
 * We're using the `Promise.allSettled` in order to combine the request instead of a Promise.all.
 * This is because the calls will frequently fail, since calendars are restricted based on the user rights and provider.
 * */
export const fetchCalendarsEventsMulti = async (fetchParams: ListEventParams[]) => {
    return Promise.allSettled(
        fetchParams.map((params) =>
            params?.provider === SpaceServiceProvider.lumapps
                ? fetchLumappsEvents(params)
                : fetchCalendarEvents(params),
        ),
    );
};
