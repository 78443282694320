import { useLayoutEffect } from 'react';

export enum LANGUAGES {
    css,
    html,
    javascript,
    json,
}

const editorForId: Record<string, any> = {};

export interface UseCodeTextAreaOptions {
    /** code language to be used for rendering the code mirror field */
    language: LANGUAGES;
    /** whether the data is still loading or it has already loaded */
    hasFinishedLoading?: boolean;
    /** whether syntax highlighting should be applied or not */
    shouldApplyHighlightSyntax?: boolean;
    /** id of the field */
    id: string;
    /** optional boolean to fold/unfold code mirror */
    isFolded?: boolean;
    /** callback that will be executed once the text area is blurred */
    setValue?: (value: string) => void;
    /** initial value to be displayed on the text area */
    value?: string;
    /** to set editor size */
    editorSize?: { width?: string; height?: string };
    /** optional boolean to if code mirror is read only */
    readOnly?: boolean;
    /** The level of indentation */
    indentationLevel?: number;
    /** The indentation size */
    indentSize?: number;
}

/**
 * Text Area with syntax highlighting provided by Code Mirror. It needs to be used with the useThirdPartyLibrary hook
 * @param CodeTextAreaOptions
 * @returns CodeMirrorTextArea
 */
export const useCodeMirrorTextArea = ({
    hasFinishedLoading,
    id,
    setValue,
    shouldApplyHighlightSyntax = false,
    value,
    isFolded,
    editorSize,
    readOnly,
    indentationLevel = 1,
    language,
    indentSize = 2,
}: UseCodeTextAreaOptions) => {
    const initialValue = value?.toString();

    /**
     * Fold code for a given indentation level
     * @param {any} editor The code mirror editor
     * @param {number} level The level of indentation required
     * @param {number} indentSize The size of indentation
     * @param {boolean | undefined} isInclusive Is folding inclusive
     * @returns {boolean}
     */
    const foldAllAtLevel = (view: any, level: number, indentSize: number, isInclusive = true) => {
        const { state } = view;
        const effects = [];
        /**
         * This can be improve using getIndentUnit instead of indentSize
         */
        const targetIndentation = level * indentSize;

        for (let pos = 0; pos < state.doc.length; ) {
            const line = state.doc.lineAt(pos);
            /**
             * This can be improve using getIndentation instead of search
             */
            const lineIndent = line.text.search(/\S/);

            if (lineIndent >= targetIndentation) {
                const range = window.CodeMirror.foldable(state, line.from, line.to);

                if (range) {
                    effects.push(window.CodeMirror.foldEffect.of(range));
                }

                pos = (range && !isInclusive ? view.lineBlockAt(range.to) : line).to + 1;
            } else {
                pos = line.to + 1;
            }
        }

        if (effects.length) {
            view.dispatch({ effects });
        }

        return Boolean(effects.length);
    };

    /**
     * Force full editor parsing (only parsed section of editor can be folded)
     */
    const forceParsing = (editor: any) => {
        window.CodeMirror.forceParsing(editor, editor.state.doc.length, 30000);
    };

    useLayoutEffect(() => {
        let editor;

        /**
         * If there is already an editor created for the given id, we need to avoid rendering again CodeMirror
         * because this lib instead of replacing the existing text area, it will create a new one beside the existing
         * one. If there was already and editor created for the given id, we just change the value of the editor
         * for the new one.
         */
        const wrapper = document.getElementById(id);
        const editorElement = wrapper?.getElementsByClassName('cm-editor');
        if (editorForId[id] && (editorElement?.length ?? 0) > 0) {
            editor = editorForId[id];
        } else if (hasFinishedLoading && shouldApplyHighlightSyntax && window.CodeMirror) {
            /**
             * We should only trigger code mirror if the data was loaded and if this component's instance
             * wants to display syntax highlighting and if the code mirror variable is loaded
             */

            const languagesMap = {
                [LANGUAGES.css]: window.CodeMirror.css,
                [LANGUAGES.javascript]: window.CodeMirror.javascript,
                [LANGUAGES.json]: window.CodeMirror.json,
                [LANGUAGES.html]: window.CodeMirror.html,
            };

            editor = new window.CodeMirror.EditorView({
                doc: initialValue,
                extensions: [
                    // Basic functionnality, such as folding
                    window.CodeMirror.basicSetup,
                    // Language functionnality
                    languagesMap[language](),
                    // Readonly or editable
                    window.CodeMirror.EditorState.readOnly.of(readOnly),
                    // On change callback
                    window.CodeMirror.EditorView.updateListener.of((v: any) => {
                        if (!v.docChanged) {
                            return;
                        }
                        const newValue = v.state.doc.toString();

                        if (setValue) {
                            setValue(newValue);
                        }
                    }),
                ],
                parent: wrapper,
            });

            // Set editor size using CSS
            if (editorSize?.width || editorSize?.height) {
                const element = document.getElementById(id)?.getElementsByClassName('cm-editor')[0];
                const existingStyle = element?.getAttribute('style') ?? '';
                let newStyle = existingStyle;
                if (editorSize?.width) {
                    newStyle += ` width: ${editorSize.width};`;
                }
                if (editorSize?.height) {
                    newStyle += ` height: ${editorSize.height};`;
                }
                if (newStyle !== '') {
                    element?.setAttribute('style', newStyle);
                }
            }

            forceParsing(editor);

            editorForId[id] = editor;
        }

        /**
         * Set new editor value on value change
         */
        if (editor && value) {
            const currentValue = editor.state.doc.toString();
            if (currentValue !== value) {
                editor.dispatch({
                    changes: {
                        from: 0,
                        to: editor.state.doc.length,
                        insert: value,
                    },
                });
                forceParsing(editor);
            }
        }

        /**
         * We fold all code blocks after the first level of indentation
         */
        if (editor && isFolded) {
            foldAllAtLevel(editor, indentationLevel, indentSize);
        }

        /**
         * We unfold all code blocks
         */
        if (editor && isFolded === false) {
            window.CodeMirror.unfoldAll(editor);
        }
    }, [
        indentationLevel,
        editorSize,
        hasFinishedLoading,
        id,
        indentSize,
        initialValue,
        isFolded,
        language,
        readOnly,
        setValue,
        shouldApplyHighlightSyntax,
        value,
    ]);
};
