import React from 'react';

import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export const ShareMenuItemLoading = ({ itemNumber = 1 }) => {
    const { translateKey } = useTranslate();
    return (
        <>
            {[...Array(itemNumber).keys()].map((skeletonNumber) => (
                <Menu.ItemSkeleton loadingMessage={translateKey(GLOBAL.LOADING)} key={skeletonNumber} hasIcon />
            ))}
        </>
    );
};
