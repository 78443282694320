import React, { useEffect } from 'react';

import { currentArticleSelector } from '@lumapps/articles/ducks/selectors';
import { contentSelector } from '@lumapps/contents/ducks/selectors';
import { currentEventPageSelector } from '@lumapps/events/ducks/selectors';
import { ThemeProvider } from '@lumapps/lumx/react';
import { CommentResourceRef } from '@lumapps/reactions-comments/api/types';
import { Comments as ReactionComments } from '@lumapps/reactions-comments/components/Comments';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { isConnected } from '@lumapps/user/ducks/selectors';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

import { LegacyCommentsProperties } from '../../types';
import { BlockComments } from '../BlockComments';

import './index.scss';

export interface ClientComputedCommentsProps extends ClientComputedProps {
    properties?: LegacyCommentsProperties;
}

export const ClientComputedComments: React.FC<ClientComputedCommentsProps> = (props) => {
    const dispatch = useDispatch();
    const { properties, uuid } = props;
    const connectedUser = useSelector(isConnected);
    const currentContent = useSelector(
        contentSelector,
        // Never update even if the store updates
        // (updating `currentContent` overrides the comment list that might have been edited)
        () => true,
    );
    const currentEvent = useSelector(currentEventPageSelector);
    const currentArticle = useSelector(currentArticleSelector);
    const currentResource = currentArticle || currentEvent || currentContent;
    const resourceId = currentResource.id;
    let resourceType: CommentResourceRef['resourceType'] | undefined;

    let commentCount;

    if (currentEvent?.id) {
        resourceType = 'events';
        commentCount = currentEvent.commentCount;
    } else if (currentArticle?.id) {
        resourceType = 'articles';
        commentCount = currentArticle.commentCount;
    } else {
        commentCount = currentContent.comments;
    }

    const isEmptyWidget = (!properties?.isEnabled || !connectedUser) && commentCount === 0;

    useEffect(() => {
        if (isEmptyWidget) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [dispatch, isEmptyWidget, uuid]);

    if (isEmptyWidget) {
        return null;
    }

    const theme = properties?.style?.content?.theme;

    if (properties?.commentVersion === 2 && resourceId && resourceType) {
        return (
            <ThemeProvider value={theme}>
                <ReactionComments
                    resource={{ resourceId, resourceType }}
                    canComment={properties.isEnabled}
                    displayNumber={properties.displayNumber}
                />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider value={theme}>
            <BlockComments
                theme={properties?.style?.content?.theme}
                contentId={currentContent.id}
                hasComments
                canComment={properties?.isEnabled}
                displayNumber={properties?.displayNumber}
                totalComments={currentContent.comments}
                relevantCommentId={currentContent.relevantComment}
            />
        </ThemeProvider>
    );
};
