import React from 'react';

import { UseInfiniteQueryOptions, useInfiniteQuery } from '@lumapps/base-api/react-query';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { getUserProfilePictureUrl } from '@lumapps/user/utils/getUserProfilePicture';

import { getUserList, playUsersQueryKeys } from '.';
import { GetUserListParams } from './types';

export interface UsePlayUsersProps extends Omit<GetUserListParams, 'cursor'> {}

export const usePlayUsers = (params: UsePlayUsersProps, options?: UseInfiniteQueryOptions) => {
    const instanceId = useSelector(instanceIdSelector);

    const [searchText, setSearchText] = React.useState('');

    const formattedParams: UsePlayUsersProps = React.useMemo(
        () => ({
            maxResults: 10,
            roleAssignmentScopeType: 'site',
            ...params,
            search: searchText,
            roleAssignmentScopeIds: [instanceId, ...(params.roleAssignmentScopeIds ?? [])],
        }),
        [instanceId, params, searchText],
    );

    const { data, ...query } = useInfiniteQuery({
        ...options,
        queryKey: playUsersQueryKeys.list(formattedParams),
        queryFn: ({ pageParam: cursor, signal }) => getUserList({ ...formattedParams, cursor }, signal),
        getNextPageParam: (lastPage) => (lastPage?.more ? lastPage?.cursor : undefined),
    });

    const formattedUsers = React.useMemo(
        () =>
            data?.pages.flatMap(
                (page) =>
                    page.items.map((item) => ({ ...item, profilePictureUrl: getUserProfilePictureUrl(item) })) || [],
            ),
        [data?.pages],
    );

    return React.useMemo(
        () => ({
            ...query,
            /** Array of users */
            users: formattedUsers || [],
            /** Search text filter */
            searchText,
            /** Update search text filter */
            setSearchText,
        }),
        [formattedUsers, query, searchText],
    );
};
