import first from 'lodash/first';
import get from 'lodash/get';
import includes from 'lodash/includes';
import loFind from 'lodash/find';
import set from 'lodash/set';
import { getAttributes } from '@lumapps/data-attributes';

// ///////////////////////////

function MediaPickerDetailController(
    $injector,
    $q,
    $scope,
    $timeout,
    Config,
    Content,
    Document,
    FroalaLoader,
    Folder,
    Instance,
    Lightbox,
    LxDropdownService,
    LxNotificationService,
    Media,
    MediaConstant,
    Tag,
    Translation,
    Upload,
    User,
    Utils,
) {
    'ngInject';

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vmDetail = this;
    let vm;

    /**
     * Services and utilities.
     */
    vmDetail.dataScope = 'picker';

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The maximum number of tags to retrieve.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    // eslint-disable-next-line no-underscore-dangle
    const _MAX_TAGS_RESULTS = 100;

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * The action dropdown identifier.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vmDetail.ACTION_DROPDOWN_ID = 'media-picker-detail-action-dropdown';

    /**
     * The target id of the action dropdown.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vmDetail.ACTION_DROPDOWN_TARGET = 'media-picker-detail-action-dropdown-target';

    /**
     * The get link dropdown identifier.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vmDetail.DROPDOWN_LINK = 'detail-dropdown-link';

    /**
     * The move dropdown identifier.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vmDetail.DROPDOWN_MOVE = 'detail-dropdown-move';

    /**
     * Options for the Froala WYSIWYG editor.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    vmDetail.FROALA_OPTIONS = {};

    /**
     * The list key of the tag list.
     *
     * @type {string}
     * @constant
     */
    vmDetail.TAG_LIST_KEY = 'detail-tag-';

    /**
     * Contains the media/folder content that should be used for editing the media/folder name/description/tags.
     *
     * @type {Object}
     */
    vmDetail.contentForEdition = {};

    /**
     * Unique language will be the only one language with we will be able to view/edit the media
     * since we do not want it anymore to be translatable.
     *
     * @type {string}
     */
    vmDetail.mediaContentUniqueLanguage = Translation.getLang().current;

    /**
     * The media picker detail controller view state.
     *
     * @type {Object}
     */
    vmDetail.is = {
        cropperOpened: false,
        menuShown: false,
        saving: {
            document: false,
        },
    };

    /**
     * The selected detail dropdown.
     *
     * @type{string}
     */
    vmDetail.selectedDropdown = undefined;

    /**
     * The selected folder to display in the sidebar.
     *
     * @type {Object}
     */
    vmDetail.selectedFolder = {};

    /**
     * The translated content of the selected folder.
     *
     * @type {Object}
     */
    vmDetail.selectedFolderContent = {};

    /**
     * The selected media to display in the sidebar.
     *
     * @type {Object}
     */
    vmDetail.selectedMedia = {};

    /**
     * The translated content of the selected media.
     *
     * @type {Object}
     */
    vmDetail.selectedMediaContent = {};

    /**
     * Indicates if the user can edit or remove files or folders.
     *
     * @type {boolean}
     */
    vmDetail.userCanEdit = false;

    // ///////////////////////////

    /**
     * Services and utilities.
     */
    vmDetail.Config = Config;
    vmDetail.Content = Content;
    vmDetail.Folder = Folder;
    vmDetail.Instance = Instance;
    vmDetail.Lightbox = Lightbox;
    vmDetail.Media = Media;
    vmDetail.MediaConstant = MediaConstant;
    vmDetail.Tag = Tag;
    vmDetail.Upload = Upload;
    vmDetail.Utils = Utils;
    vmDetail.getDataId = getAttributes;

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    /**
     * Check if the user can edit/delete files and folders.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _canUserEditFiles() {
        vmDetail.userCanEdit =
            User.isAdmin() ||
            vm.currentFilter.providerId !== MediaConstant.PROVIDERS.lumapps ||
            vm.currentFilter.categoryId === MediaConstant.CATEGORIES.mine;
    }

    /**
     * 
     * @param {Object} options 
     * @param {string} options.element 
     * @param {string} options.action 
     * @returns 
     */
    function getDataId(scope, options) {
        return getAttributes(scope)(options)['data-id'];
    }

    /**
     * Retrieve tag list for the media filter.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _filterTags() {
        vmDetail.mediaTags = Tag.getList(undefined, undefined, vm.current.instanceId);

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(vmDetail.mediaTags)) {
            return;
        }

        Tag.filterize(
            {
                instance: vm.current.instanceId,
                kind: Config.TAG_TYPE.MEDIA,
                maxResults: _MAX_TAGS_RESULTS,
            },
            (response) => {
                vmDetail.mediaTags = response;
            },
            Utils.displayServerError,
            vm.current.instanceId,
        );
    }

    /**
     * Open or Close the filter select.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _resetDetail() {
        vmDetail.selectedMedia = undefined;
        vmDetail.selectedMediaContent = undefined;
        vmDetail.selectedFolder = undefined;
    }

    /**
     * Save a document then reload list.
     *
     * @param  {Object}  doc  Document to save (folder/media)
     * @param  {string}  type Type of the document.
     * @return {Promise} Save request promise.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _saveDocument(doc, type) {
        // eslint-disable-next-line no-undef
        if (angular.isUndefined(doc) || vmDetail.is.saving.document) {
            return $q.reject();
        }

        vmDetail.is.saving.document = true;

        // eslint-disable-next-line no-undef
        const documentToSave = angular.fastCopy(doc);

        if (vm.currentFilter.providerId !== MediaConstant.PROVIDERS.lumapps) {
            // Apply only for other providers than lumapps.
            const documentContent = first(documentToSave.content);

            documentContent.name = vmDetail.contentForEdition.name;
            documentToSave.content = [documentContent];
        }

        return Document.update(documentToSave)
            .then(() => {
                LxNotificationService.success(
                    Translation.translate(`FRONT.MEDIA_PICKER.${type.toUpperCase()}_SAVE_SUCCESS`),
                );
                vm.executeFilter();
            })
            .catch(Utils.displayServerError)
            .finally(() => {
                vmDetail.is.saving.document = false;
            });
    }

    /**
     * Display details for selected object.
     *
     * @param {Object} object The object (media or folder) to display in detail template.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _toggleDetail(object) {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(object)) {
            return;
        }

        const objectKind = get(object, 'kind', MediaConstant.KIND.MEDIA);

        vmDetail.is.menuShown = false;

        if (includes([MediaConstant.KIND.MEDIA, MediaConstant.KIND.DRIVE], objectKind)) {
            vmDetail.selectedItemType = MediaConstant.KIND.MEDIA;
            vmDetail.selectedMedia = object;
            vmDetail.updateMediaContent();
            // Is media editable by the user.
            vmDetail.isMediaEditable = vm.is.admin || vmDetail.canEditMedia();
        } else if (objectKind === MediaConstant.KIND.FOLDER) {
            vmDetail.isFolderEditable = vm.is.admin || vm.currentFilter.categoryId === MediaConstant.CATEGORIES.mine;
            vmDetail.selectedItemType = MediaConstant.KIND.FOLDER;
            vmDetail.selectedFolder = object;
            vmDetail.updateMediaContent();
        }
    }

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Add media from uploaded files to the selected document's content.
     *
     * @param {Object} file A file object to add to selected Media's content.
     */
    function addSelectedMediaContent(file) {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(vmDetail.selectedMediaContent) || vm.is.selectionShown) {
            return;
        }

        // eslint-disable-next-line no-undef
        if (angular.isUndefined(vmDetail.selectedMedia.content)) {
            vmDetail.selectedMedia.content = [];
        }

        Document.removeCrop(vmDetail.selectedMedia, vmDetail.mediaContentUniqueLanguage);

        const mediaContent = {
            fileId: vmDetail.selectedMediaContent.fileId,
            height: file.height,
            lang: Translation.inputLanguage || vmDetail.mediaContentUniqueLanguage,
            mimeType: file.mimeType,
            name: file.name,
            owner: {
                email: User.getConnected().email,
                fullName: User.getUserFullName(),
            },
            servingUrl: file.url,
            size: file.fileSize,
            type: file.type,
            url: file.url,
            value: file.blobKey,
            width: file.width,
        };

        const existingMediaContent = loFind(vmDetail.selectedMedia.content, {
            lang: vmDetail.mediaContentUniqueLanguage,
        });

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(existingMediaContent)) {
            // eslint-disable-next-line no-undef
            angular.extend(existingMediaContent, mediaContent);
        } else {
            vmDetail.selectedMedia.content = [mediaContent];
        }

        // Update the media content for the current language;
        vmDetail.updateMediaContent();
    }

    /**
     * Add media from uploaded files to the selected document's thumbnail.
     *
     * @param {Object} file A file object to add to selected Media's thumbnail.
     */
    function addSelectedMediaThumbnail(file) {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(vmDetail.selectedMediaContent)) {
            return;
        }

        if (vm.is.selectionShown) {
            set(vmDetail.selectedMedia, ['override', 'thumbnail', vmDetail.mediaContentUniqueLanguage], file.blobKey);
        } else if (Media.isImage(vmDetail.selectedMediaContent.type)) {
            vmDetail.addSelectedMediaContent(file);
        } else {
            set(vmDetail.selectedMedia, ['thumbnail', vmDetail.mediaContentUniqueLanguage], file.blobKey);
        }

        // Update the media content for the current language;
        vmDetail.updateMediaContent();
    }

    /**
     * Whether user can edit the current Media.
     *
     * @return {boolean} If user can edit the media.
     */
    function canEditMedia() {
        return Media.canEditMedia(vmDetail.selectedMedia);
    }

    /**
     * Close the action dropdown.
     */
    function closeActionDropdown() {
        LxDropdownService.close(vmDetail.ACTION_DROPDOWN_ID);
    }

    /**
     * Delete selected media thumbnail.
     */
    function deleteSelectedMediaThumbnail() {
        if (Media.isImage(vmDetail.selectedMediaContent.type)) {
            return;
        }

        if (vm.is.selectionShown) {
            delete vmDetail.selectedMedia.override.thumbnail[vmDetail.mediaContentUniqueLanguage];
        } else {
            delete vmDetail.selectedMedia.thumbnail[vmDetail.mediaContentUniqueLanguage];
        }

        // Update the media content for the current language;
        vmDetail.updateMediaContent();
    }

    /**
     * Check if the selected media has a background image.
     *
     * @param  {boolean} [useFallback=false]              Should fallback on default language.
     * @param  {string}  [lang=Translation.inputLanguage] The lang to use to get the background image.
     * @return {boolean} If the selected media has background image or not.
     */
    function hasBackgroundImage(useFallback, lang) {
        // eslint-disable-next-line no-undef
        return angular.isDefinedAndFilled(
            Document.getBackgroundImage(
                vmDetail.selectedMedia,
                useFallback,
                undefined,
                lang,
                vm.is.selectionShown ? vmDetail.selectedMedia.override : undefined,
            ),
        );
    }

    /**
     * Closes the cropper.
     */
    function onCropperClose() {
        vmDetail.is.cropperOpened = false;
    }

    /**
     * Upload cropped image and return media.
     *
     * @param {Object} canvas The `Cropper` canvas.
     */
    function uploadCropContent(canvas) {
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                Upload.uploadMultiple([blob], undefined, (response) => {
                    resolve({
                        ...response,
                        name: (vmDetail.selectedMediaContent && vmDetail.selectedMediaContent.name) || response.name,
                    });
                });
            });
        });
    }

    /**
     * Saves crop and focal point info and attach them to
     * the original media.content and media.croppedContent if exists.
     *
     * @param {Object} focalPointInfo Information about the Focal Point.
     * @param {Object} cropInfo Infomation about the crop.
     * @param {Object} canvas The `Cropper` canvas (optionnal, if image has been cropped)
     */
    async function onCropperConfirm(focalPointInfo, cropInfo, canvasCroppedImage) {
        // eslint-disable-next-line no-undef
        const copySelectedMedia = angular.fastCopy(vmDetail.selectedMedia);
        const lang = vmDetail.mediaContentUniqueLanguage;
        if (canvasCroppedImage) {
            const mediaCroppedContent = await uploadCropContent(canvasCroppedImage);
            copySelectedMedia.croppedContent = copySelectedMedia.croppedContent || [];
            Document.removeCrop(copySelectedMedia, lang);
            const firstContent = first(Media.formatUploadedFile(mediaCroppedContent, lang).content);
            firstContent.focalPoint = focalPointInfo;
            firstContent.cropInfo = cropInfo;
            copySelectedMedia.croppedContent.push(firstContent);
            copySelectedMedia.hasCroppedContent = true;
        } else {
            Document.removeCrop(copySelectedMedia, lang);
        }

        copySelectedMedia.content = copySelectedMedia.content || [{}];
        copySelectedMedia.content[0].focalPoint = focalPointInfo;
        copySelectedMedia.content[0].cropInfo = cropInfo;

        try {
            Document.update(copySelectedMedia).then(() => {
                LxNotificationService.success(Translation.translate('MEDIA_PICKER_SAVE_SUCCESS'));
                vm.executeFilter();
            });
        } catch (exception) {
            Utils.displayServerError(exception);
        }

        vmDetail.onCropperClose();
    }

    /**
     * Opens the cropper dialog.
     */
    function openCropper() {
        vmDetail.is.cropperOpened = true;
    }

    /**
     * Open selected media uploader.
     *
     * @param {string} type The type of media uploader to use.
     *                      Possible values are: 'content' or 'thumbnail'.
     */
    function openSelectedMediaUploader(type) {
        $timeout(() => {
            // eslint-disable-next-line no-undef
            angular
                .element(`ls-uploader[ls-uuid="edit-selected-media-${type}"]`)
                .find('.uploader__placeholder')
                .click();
        });
    }

    /**
     * Save currently selected folder.
     *
     * @return {Promise} Save request promise.
     */
    function saveSelectedFolder() {
        return _saveDocument(vmDetail.selectedFolder, 'folder');
    }

    /**
     * Save currently selected media.
     *
     * @return {Promise} Save request promise.
     */
    function saveSelectedMedia() {
        return _saveDocument(vmDetail.selectedMedia, 'media');
    }

    /**
     * Open or close action details menu.
     */
    function toggleMenu() {
        vmDetail.is.menuShown = !vmDetail.is.menuShown;
    }

    /**
     * Update media content for the current language.
     */
    function updateMediaContent() {
        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(vmDetail.selectedMedia) && angular.isArray(vmDetail.selectedMedia.content)) {
            vmDetail.mediaContentUniqueLanguage = vmDetail.selectedMedia.content?.[0]?.lang || Translation.DEFAULT_LANG;
            vmDetail.selectedMediaContent = Document.getMediaContentByLang(
                vmDetail.selectedMedia,
                true,
                vmDetail.mediaContentUniqueLanguage,
                false,
                'content',
            );

            vmDetail.selectedMediaContentImageBlob = Document.getImageThumbnailForMedia(vmDetail.selectedMedia, true, 500, vmDetail.mediaContentUniqueLanguage)

            if (vm.currentFilter.providerId === MediaConstant.PROVIDERS.lumapps) {
                vmDetail.contentForEdition = vmDetail.selectedMedia;
            } else {
                vmDetail.contentForEdition = loFind(vmDetail.selectedMedia.content, {
                    lang: vmDetail.mediaContentUniqueLanguage,
                });

                // eslint-disable-next-line no-undef
                if (angular.isUndefined(vmDetail.contentForEdition)) {
                    vmDetail.selectedMedia.content.push({
                        lang: vmDetail.mediaContentUniqueLanguage,
                    });

                    vmDetail.contentForEdition = loFind(vmDetail.selectedMedia.content, {
                        lang: vmDetail.mediaContentUniqueLanguage,
                    });
                }
            }
        } else if (
            // eslint-disable-next-line no-undef
            angular.isDefinedAndFilled(vmDetail.selectedFolder) &&
            // eslint-disable-next-line no-undef
            angular.isArray(vmDetail.selectedFolder.content)
        ) {
            vmDetail.selectedFolderContent = Document.getMediaContentByLang(
                vmDetail.selectedFolder,
                true,
                vmDetail.mediaContentUniqueLanguage,
            );

            if (vm.currentFilter.providerId === MediaConstant.PROVIDERS.lumapps) {
                vmDetail.contentForEdition = vmDetail.selectedFolder;
            } else {
                vmDetail.contentForEdition = loFind(vmDetail.selectedFolder.content, {
                    lang: vmDetail.mediaContentUniqueLanguage,
                });

                // eslint-disable-next-line no-undef
                if (angular.isUndefined(vmDetail.contentForEdition)) {
                    vmDetail.selectedFolder.content.push({
                        lang: vmDetail.mediaContentUniqueLanguage,
                        name: vmDetail.selectedFolderContent.name,
                    });

                    vmDetail.contentForEdition = loFind(vmDetail.selectedFolder.content, {
                        lang: vmDetail.mediaContentUniqueLanguage,
                    });
                }
            }
        }
    }

    // ///////////////////////////

    vmDetail.addSelectedMediaContent = addSelectedMediaContent;
    vmDetail.addSelectedMediaThumbnail = addSelectedMediaThumbnail;
    vmDetail.canEditMedia = canEditMedia;
    vmDetail.closeActionDropdown = closeActionDropdown;
    vmDetail.deleteSelectedMediaThumbnail = deleteSelectedMediaThumbnail;
    vmDetail.hasBackgroundImage = hasBackgroundImage;
    vmDetail.onCropperClose = onCropperClose;
    vmDetail.onCropperConfirm = onCropperConfirm;
    vmDetail.openCropper = openCropper;
    vmDetail.openSelectedMediaUploader = openSelectedMediaUploader;
    vmDetail.saveSelectedFolder = saveSelectedFolder;
    vmDetail.saveSelectedMedia = saveSelectedMedia;
    vmDetail.toggleMenu = toggleMenu;
    vmDetail.updateMediaContent = updateMediaContent;
    vmDetail.getDataId = getDataId;

    // ///////////////////////////
    //                         //
    //          Events         //
    //                         //
    // ///////////////////////////

    /**
     * Listen to input language change to update selectedMedia.
     */
    $scope.$on('inputLanguage', () => {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(vmDetail.selectedMedia)) {
            return;
        }

        vmDetail.updateMediaContent();
    });

    // ///////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    // ///////////////////////////

    /**
     * Watch changes on the parent abstract selected object to display abstract object detail.
     */
    $scope.$watch(
        'vm.selectedDetailObject',
        (newValue, oldValue) => {
            if (newValue === oldValue) {
                return;
            }

            // eslint-disable-next-line no-undef
            if (angular.isDefinedAndFilled(newValue)) {
                _toggleDetail(newValue);
            } else {
                _resetDetail();
            }

            _canUserEditFiles();
        },
        true,
    );

    // ///////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        // eslint-disable-next-line consistent-this
        vm = $scope.$parent.$parent.vm;

        FroalaLoader.getFroala().then(() => {
            try {
                vmDetail.FroalaService = $injector.get('FroalaService');

                /**
                 * Options for the Froala WYSIWYG editor.
                 *
                 * @type {Object}
                 */
                vmDetail.FROALA_OPTIONS = vmDetail.FroalaService.getOptions(true, true, false, false, []);
            } catch (exception) {
                // Nothing to do here.
            }
        });

        // Get Tags to display.
        _filterTags();
    }

    init();
}

// ///////////////////////////

// eslint-disable-next-line no-undef
angular.module('Controllers').controller('MediaPickerDetailController', MediaPickerDetailController);

// ///////////////////////////

export { MediaPickerDetailController };
