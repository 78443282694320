import { CSSProperties } from 'react';

import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import { MAX_IMAGE_SIZE } from '@lumapps/constants';
import { isImageUrlResized, makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { resizeImage } from '@lumapps/medias/utils/resizeImage';

import { DEFAULT_OPACITY } from '../constants';
import { AllStyles, ShorthandProperties } from '../types';
import { expandShorthandProperties } from './expandShorthandProperties';

/**
 * Compute content styles into CSSProperties.
 */
export function computeStyles(
    styles: AllStyles | string | undefined,
): Omit<CSSProperties, ShorthandProperties> | undefined {
    if (!styles || typeof styles === 'string') {
        return undefined;
    }

    const { expandedProperties, shorthandPropertyKeys } = expandShorthandProperties(styles);

    const {
        // Non valid css properties:
        shadowElevation,
        defaultShadowElevation,
        shadowOpacity = DEFAULT_OPACITY,
        backgroundImage: backgroundUrl,
        // Valid css properties:
        ...otherStyles
    } = styles;

    const cssProperties: Omit<CSSProperties, ShorthandProperties> = {
        ...expandedProperties,
        ...omitBy(
            otherStyles,
            // Remove undefined values and shorthand properties
            (value, key) => value === undefined || shorthandPropertyKeys.includes(key as any),
        ),
    };
    const shadowElevationComputed = shadowElevation ?? defaultShadowElevation;

    if (shadowElevationComputed === 0 || shadowOpacity === 0) {
        cssProperties.boxShadow = 'none';
    } else if (shadowElevationComputed) {
        const opacity = (shadowOpacity / 100).toFixed(2);
        cssProperties.boxShadow = `0 ${2 ** shadowElevationComputed}px ${
            2 ** (shadowElevationComputed + 1)
        }px rgba(0,0,0,${opacity})`;
    }

    if (backgroundUrl) {
        cssProperties.backgroundImage = `url(${makeSecuredMediaURLRelative(
            isImageUrlResized(backgroundUrl) ? backgroundUrl : resizeImage(backgroundUrl, MAX_IMAGE_SIZE),
        )})`;
    }

    return isEmpty(cssProperties) ? undefined : cssProperties;
}
