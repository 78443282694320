import React from 'react';

import { DEFAULT_LANGUAGE, currentLanguageSelector, getLanguages } from '@lumapps/languages';
import { useSelector } from '@lumapps/redux/react';

import { reducer } from './reducer';
import { ACTIONS, type InitialState, type InitialValues } from './types';

// Hook
const VideoEditorContext = React.createContext<InitialState | undefined>(undefined);

export const useVideoEditor = () => {
    const context = React.useContext(VideoEditorContext);
    if (context === undefined) {
        throw new Error('useVideoEditor must be used within a VideoEditorContextProvider');
    }

    return context;
};

export type VideoEditorContextProviderProps = {
    children: React.ReactNode;
    initialValue: InitialValues;
};

// Provider
export const VideoEditorContextProvider = ({ children, initialValue }: VideoEditorContextProviderProps) => {
    const [state, dispatch] = React.useReducer(reducer, initialValue);
    const currentLanguage = useSelector(currentLanguageSelector);
    const languages = useSelector(getLanguages);
    const defaultLanguage = DEFAULT_LANGUAGE;
    /**
     * The default language used for translatableTextField components. If the user current language is not available,
     * replace it with the default language
     */
    const translatableTextFieldDefaultLanguage = languages.includes(currentLanguage)
        ? currentLanguage
        : defaultLanguage;

    const [nextThumbnail, setNextThumbnail] = React.useState<File | undefined>(undefined);

    const value = React.useMemo(() => {
        return {
            ...state,
            nextThumbnail,
            transcriptError: state.transcriptError,
            setNextThumbnail,
            setTranscriptError: (payload: boolean) => {
                dispatch({ type: ACTIONS.SET_TRANSCRIPT_ERROR, payload });
            },
            isScheduled: state.isScheduled,
            setIsScheduled: (payload: boolean) => {
                dispatch({ type: ACTIONS.SET_IS_SCHEDULED, payload });
            },
            video: state.video,
            languages,
            defaultLanguage: translatableTextFieldDefaultLanguage,
        };
    }, [languages, nextThumbnail, state, translatableTextFieldDefaultLanguage]);

    return <VideoEditorContext.Provider value={value}>{children}</VideoEditorContext.Provider>;
};
