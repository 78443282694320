export enum PLAY_SUBTITLES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301322502
     */
    ADD_SUBTITLES = 'PLAY.ADD_SUBTITLES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172625507
     */
    DOWNLOAD_VTT = 'PLAY.DOWNLOAD_VTT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300602400
     *
     */
    GENERATED_BY = 'PLAY.GENERATED_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301606758
     */
    GENERATE_FROM_AUDIO = 'PLAY.GENERATE_FROM_AUDIO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300565698
     */
    GENERATING = 'PLAY.GENERATING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222049645
     * */
    LANG_EN_UK = 'PLAY.LANG_EN_UK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222050470
     * */
    LANG_EN_US = 'PLAY.LANG_EN_US',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453130
     * */
    LANG_PT_BR = 'LANG_PT_BR',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=288314014
     * */
    LANG_ZH_HK = 'LANG_ZH_HK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300822886
     */
    NO_SUBTITLES = 'PLAY.NO_SUBTITLES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300772427
     * */
    SAVE_MESSAGE = 'PLAY.SUBTITLES_SAVE_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172610183
     */
    SEARCH_A_LANGUAGE = 'PLAY.SEARCH_A_LANGUAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=61562229
     */
    SELECT_LANGUAGE = 'FRONT.LANGUAGE_SWITCHER.ARIA_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130860271
     */
    SUBTITLES_LANGUAGE = 'PLAY.SUBTITLES_LANGUAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130858205
     */
    SUBTITLES_UPLOADER_NO_SUBTITLE = 'PLAY.SUBTITLES_UPLOADER_NO_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130859482
     */
    SUBTITLES_UPLOADER_DESCRIPTION = 'PLAY.SUBTITLES_UPLOADER_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130860254
     */
    SUBTITLES_UPLOADER_BUTTON = 'PLAY.SUBTITLES_UPLOADER_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130860490
     */
    SUBTITLES_DROP_SUBTITLE = 'PLAY.SUBTITLES_DROP_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=131046568
     */
    SUBTITLES_NO_AVAILABLE_LANGUAGE = 'PLAY.SUBTITLES_NO_AVAILABLE_LANGUAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172612345
     */
    TRANSCRIPT = 'PLAY.TRANSCRIPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172612406
     */
    TRANSCRIPT_DONE = 'PLAY.TRANSCRIPT_DONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172613761
     */
    TRANSCRIPT_GENERATION_ERROR = 'PLAY.TRANSCRIPT_GENERATION_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172613924
     */
    TRANSCRIPT_LANGUAGE_LABEL = 'PLAY.TRANSCRIPT_LANGUAGE_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172614072
     */
    TRANSCRIPT_POPUP_TITLE = 'PLAY.TRANSCRIPT_POPUP_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172614085
     */
    TRANSCRIPT_POPUP_DELETE_BUTTON = 'PLAY.TRANSCRIPT_POPUP_DELETE_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172620529
     */
    TRANSCRIPT_POPUP_LANGUAGE_UNAVAILABLE = 'PLAY.TRANSCRIPT_POPUP_LANGUAGE_UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172623314
     */
    TRANSCRIPT_POPUP_UNAVAILABLE_FOR_SEARCH = 'PLAY.TRANSCRIPT_POPUP_UNAVAILABLE_FOR_SEARCH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=321522785
     */
    UPLOAD_ONE_SUBTITLE_AT_A_TIME = 'PLAY.UPLOAD_ONE_SUBTITLE_AT_A_TIME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=124733944
     */
    VIDEO_DIALOG_SUBTITLES_TAB_TITLE = 'PLAY.SUBTITLES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551226644
     */
    USED_AS_TRANSCRIPT = 'PLAY.USED_AS_TRANSCRIPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551230797
     */
    DELETE_TRANSCRIPT_SUBTITLE = 'PLAY.DELETE_TRANSCRIPT_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551240340
     */
    DELETE_SUBTITLE_NOTICE = 'PLAY.DELETE_SUBTITLE_NOTICE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551241616
     */
    UPDATE_TRANCRIPT_NOTICE = 'PLAY.UPDATE_TRANCRIPT_NOTICE',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551242944
     */
    AUTO_TRANSCRIPT_TITLE = 'PLAY.AUTO_TRANSCRIPT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551243184
     */
    DELETE_SUBTITLE = 'PLAY.DELETE_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551267591
     */
    NO_SUBTITLE_TRANSCRIPT_NOTICE = 'PLAY.NO_SUBTITLE_TRANSCRIPT_NOTICE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=606005843
     */
    TRANSLATE_SUBTITLES = 'PLAY.TRANSLATE_SUBTITLES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=612608311
     */
    COULD_NOT_GENERATE_TRANSCRIPT = 'PLAY.COULD_NOT_GENERATE_TRANSCRIPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=612610477
     */
    COULD_NOT_TRANSLATE_TO = 'PLAY.COULD_NOT_TRANSLATE_TO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=613035547
     */
    PENDING_SUBTITLE_GENERATION = 'PLAY.PENDING_SUBTITLE_GENERATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=619847877
     */
    TRANSLATING = 'PLAY.TRANSLATING',
}
