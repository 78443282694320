/* eslint-disable */
import { RenderingType } from '@lumapps/communities/types';
import { spaceView } from '@lumapps/spaces/routes';
import { angularApi } from '@lumapps/router/routers';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import identity from 'lodash/identity';
import includes from 'lodash/includes';
import loCamelCase from 'lodash/camelCase';
import loEscape from 'lodash/escape';
import loFind from 'lodash/find';
import loUnescape from 'lodash/unescape';
import loValues from 'lodash/values';
import map from 'lodash/map';
import pick from 'lodash/pick';
import set from 'lodash/set';
import snakeCase from 'lodash/snakeCase';
import startsWith from 'lodash/startsWith';
import endsWith from 'lodash/endsWith';
import tail from 'lodash/tail';
import trimStart from 'lodash/trimStart';
import { getEnabledModes, MODES } from '@lumapps/customizations/modes';

import { getLocationFromString } from '@lumapps/router/utils';
import { getBlob, doesBlobExist } from '@lumapps/lumx-images/components/BlobThumbnail/store';
import { sanitizeHTMLWithDebugScriptsAndStyles } from '@lumapps/utils/hooks/useInjectHTMLWithJS';
import { makeSecuredMediaURLRelative, applyRelativeURLForSecuredImagesInHTML, retrieveKeyFromServeImageUrl } from '@lumapps/medias/utils';
import { isSlugValid, slugify } from 'components/utils/common_utils';

/////////////////////////////

/**
 * Remove a value from an array using a matcher.
 * This function is the same as `reject` except that it works on the same array and not on a copy.
 *
 * @param  {Array}           array   The array in which we want to remove the value.
 * @param  {Object|Function} matcher An UnderscoreJS matcher to identify the value we want to remove from the
 *                                   array.
 * @return {*}               The removed value.
 */
const reject = (array, matcher) => {
    if (angular.isUndefinedOrEmpty(array)) {
        return undefined;
    }

    const index = findIndex(array, matcher);
    if (index > -1) {
        return array.splice(index, 1);
    }

    return undefined;
};

function UtilsService(
    $document,
    $httpParamSerializer,
    $injector,
    $interval,
    $location,
    $q,
    $rootScope,
    $sce,
    $state,
    $timeout,
    $window,
    ConfigTheme,
    InitialSettings,
    LxDialogService,
    LxNotificationService,
    Translation,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = {};

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The timeout to launch the check of the captcha.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _CHECK_CAPTCHA_TIMEOUT = 100;

    /**
     * The default image size to use when resizing an image.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_IMAGE_SIZE = 512;

    /**
     * The default password size.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_PASSWORD_SIZE = 8;

    /**
     * The default precision (number of decimals).
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_PRECISION = 3;

    /**
     * The default interval to wait when waiting for an element to appears before executing some code.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_WAIT_INTERVAL = 50;

    /**
     * The number of interval to wait before giving up waiting for an element before executing some code.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_WAIT_INTERVAL_COUNT = 600;

    /**
     * Represents the value of the `which` property of a click event when the middle mouse button is clicked.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MIDDLE_CLICK_EVENT_WHICH = 2;

    /**
     * The print class, added to the body when we want to enable a specific css for printing.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _PRINT_CLASS = 'body--print';

    /**
     * Default replacement tokens used when trying to escape tags in string.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _REPLACEMENT_TOKENS = {
        '<': '&lt;',
        '>': '&gt;',
    };

    /**
     * Contains the JSONPath library to be used to compute user portions.
     *
     * @type {Object}
     */
    let _jsonpath = {};

    /**
     * Contains some usefull key code (like enter, space, ...).
     *
     * @type {Object}
     */
    const _keys = {
        /* eslint-disable no-magic-numbers */
        enter: 13,
        space: 32,
        /* eslint-enable no-magic-numbers */
    };

    /**
     * Contains a RegExp to match an URL.
     *
     * @type {RegExp}
     */
    const _urlRegex = /(?:^|\s|\/\/)[-a-zA-Z0-9:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9:%_+.~#?&/=]*)/g;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Build the base URL for the given customer slug.
     *
     * @param  {string} customerSlug The customer slug.
     * @return {string} The customer URL.
     */
    function _buildCustomerUrl(customerSlug) {
        if (includes($window.location.href, `/a/${customerSlug}/`)) {
            return `/a/${customerSlug}`;
        }

        return '';
    }

    /**
     * Check the captcha.
     *
     * @param {Function} cb The callback to call when captcha is checked.
     */
    function _checkCaptcha(cb) {
        cb = cb || angular.noop;

        if (angular.isDefined($window.grecaptcha)) {
            $window.grecaptcha.ready(cb);

            return;
        }

        $timeout(() => _checkCaptcha(cb), _CHECK_CAPTCHA_TIMEOUT);
    }

    /**
     * Encode an array value depending on its length.
     *
     * @param  {Array}  valueToEncode The value to encode to URI component.
     * @return {string} The encoded value.
     */
    function _formatAndEncodeArrayValues(valueToEncode) {
        let tempValue = angular.fastCopy(valueToEncode);

        if (!angular.isArray(valueToEncode) || angular.isUndefinedOrEmpty(valueToEncode)) {
            return 'not_found';
        }

        if (valueToEncode.length === 1) {
            tempValue = angular.isString(tempValue[0]) ? tempValue[0] : angular.toJson(tempValue[0]);
        } else {
            tempValue = angular.toJson(tempValue);
        }

        return encodeURIComponent(tempValue);
    }

    /**
     * Check if the given thing is a RegExp object.
     *
     * @param  {*}       thingToCheck The thing to check.
     * @return {boolean} If the thing to check is a RegExp object or not.
     */
    function _isRegExp(thingToCheck) {
        return Object.prototype.toString.call(thingToCheck) === '[object RegExp]';
    }

    /**
     * Test if a string is an UUID.
     *
     * @param  {string}  str The string to test.
     * @return {boolean} Whether the string is an UUID or not.
     */
    function _isUUID(str) {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/g;

        return uuidRegex.test(str);
    }

    /**
     * Check if the given object is a scope object.
     *
     * @param  {Object}  object The object to check if it's a scope object.
     * @return {boolean} If the object is a scope object or not.
     */
    function _isScope(object) {
        return angular.isObject(object) && angular.isDefined(object.$evalAsync) && angular.isDefined(object.$watch);
    }

    /**
     * Check if the given object is the window object.
     * I know this is weird, but the window object contains a
     * reference to itself.
     *
     * @param  {Object}  object The object to check if it's the window object.
     * @return {boolean} If the object is the window object or not.
     */
    function _isWindow(object) {
        return angular.isObject(object) && object.window === object;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Attach a scope to an element.
     * This allow to use `angular.element().scope()` even with the production mode
     * disabled .
     *
     * ATTENTION: THIS SHOULD NOT BE OVERUSED. IT'S ONLY FOR SOME SPECIFIC CASE WHERE WE CANNOT DO OTHERWISE.
     *
     * @param {jQElement} el                 The element to attach the scope to.
     * @param {Object}    scope              The scope we want to attach to the element.
     * @param {boolean}   [isolated=false]   Indicates if the scope is isolated or not.
     * @param {boolean}   [noTemplate=false] Indicates if this scope is not linked to any template.
     */
    function addScopeInfo(el, scope, isolated, noTemplate) {
        let dataName = isolated ? '$isolateScope' : '$scope';
        dataName += noTemplate ? 'NoTemplate' : '';

        if (angular.isString(el)) {
            service.waitForAndExecute(el, (foundEl) => foundEl.data(dataName, scope));
            // eslint-disable-next-line lumapps/angular-isdefined
        } else if (angular.isDefined(el) && el.length > 0) {
            el.data(dataName, scope);
        }
    }

    /**
     * Refresh the content of an array but keep its reference intact.
     *
     * @param  {Array} arr                The array to refresh.
     * @param  {*}     [values=<Array()>] The values to put in the refreshed array.
     * @return {Array} The refreshed array.
     */
    function arrayRefresh(arr, values) {
        if (!angular.isArray(arr)) {
            return arr;
        }

        values = values || [];
        values = angular.isArray(values) ? values : [values];

        service.empty(arr);

        angular.forEach(values, (value) => arr.push(value));

        return arr;
    }

    /**
     * Decode a state parameter and build an object with it.
     *
     * @param  {string} stateParam The state parameter to transform.
     * @param  {Object} [pattern]  The patter of the built object.
     *                             If a filter is not in the pattern, then it won't be copied in the built object.
     * @return {Object} The object built from the state parameter.
     *
     * Todo [Arnaud]: move to uri utils.
     */
    function buildFilterFromUri(stateParam, pattern) {
        let filters;

        // Try to get and parser filters.
        try {
            filters = angular.fromJson(decodeURIComponent(stateParam));
        } catch (exception) {
            filters = {};
        }

        if (angular.isUndefinedOrEmpty(filters)) {
            return {};
        }

        const values = {};
        angular.forEach(filters, (value, key) => {
            if (angular.isUndefined(pattern) || pattern.hasOwnProperty(key)) {
                values[key] = value;
            }
        });

        return values;
    }

    /**
     * Build the base URL for the given instance.
     *
     * @param  {Object} instance     The instance we want to get the URL of.
     * @param  {string} customerSlug The customer slug.
     * @return {string} The instance url.
     *
     * Todo [Arnaud]: move to instance utils.
     */
    function buildInstanceUrl(instance, customerSlug) {
        return `${_buildCustomerUrl(customerSlug)}/${instance.slug}/`;
    }

    /**
     * Copies `propertyNames` at `object[sourcePath]` to `object[destinationPath]`.
     * Defined properties found in `sourcePath` are deleted if `remove` is `true`.
     * If the `destinationPath` does not exist, it is created.
     *
     * @param  {Array}   propertyNames   Array of property names to migrate.
     * @param  {string}  sourcePath      The path to the root object from which to copy the `propertyNames`.
     * @param  {string}  destinationPath The path to the root object to which `propertyNames` are copied to.
     * @param  {Object}  object          The object holding the properties.
     * @param  {boolean} remove          Remove the properties found at `sourcePath`.
     * @return {Object}  Returns the same `object`.
     */
    function copyProperties(propertyNames, sourcePath, destinationPath, object, remove) {
        const sourceValues = angular.isDefinedAndFilled(sourcePath) ? get(object, sourcePath) : object;
        let destinationValues = get(object, destinationPath);
        if (angular.isUndefined(destinationValues)) {
            destinationValues = {};
            set(object, destinationPath, destinationValues);
        }

        if (angular.isUndefinedOrEmpty(sourceValues)) {
            return object;
        }

        angular.forEach(propertyNames, (property) => {
            const value = sourceValues[property];

            if (angular.isDefinedAndFilled(value)) {
                destinationValues[property] = value;

                if (remove) {
                    delete sourceValues[property];
                }
            }
        });

        return object;
    }

    /**
     * Copies `propertyName` at `object[sourcePath]` to `object[destinationPath]`, and
     * sets every `subPropertyNames` in `object[destinationPath]` to the value of `propertyName`.
     * If the `destinationPath` does not exist, it is created.
     *
     * @param  {Array}  propertyName     Name of the property to copy.
     * @param  {string} subPropertyNames Name of the sub-properties to set.
     * @param  {string} sourcePath       The path to the root object from which the `propertyName` is copied.
     * @param  {string} destinationPath  The path to the root object to which `propertyName` and
     *                                   `subPropertyNames` are copied to.
     * @param  {Object} object           The object holding the properties.
     * @param  {bool}   remove           Remove the properties found at `sourcePath`.
     * @return {Object} Returns the same `object`.
     */
    function copyPropertySet(propertyName, subPropertyNames, sourcePath, destinationPath, object, remove) {
        const sourceValues = angular.isDefinedAndFilled(sourcePath) ? get(object, sourcePath) : object;
        let destinationValues = get(object, destinationPath);
        const value = sourceValues[propertyName];

        if (angular.isUndefined(destinationValues)) {
            destinationValues = {};
            set(object, destinationPath, destinationValues);
        }

        if (angular.isUndefined(value)) {
            return object;
        }

        destinationValues[propertyName] = value;

        angular.forEach(subPropertyNames, (subProperty) => {
            destinationValues[subProperty] = value;
        });

        if (remove) {
            delete sourceValues[propertyName];
        }

        return object;
    }

    /**
     * Copy an object to existing one, without changing the references of both the source and the destination.
     *
     * @param {Object}  source          The object to copy from.
     * @param {Object}  destination     The destination object.
     * @param {boolean} setDefaultValue Ensure that all previous destination keys are undefined.
     *
     * Todo [Arnaud]: move to overrides/veolia.
     */
    function copyObjectToExistingOne(source, destination, setDefaultValue) {
        $.each(destination, (key) => {
            if (angular.isUndefined(setDefaultValue)) {
                destination[key] = undefined;
            }
        });

        $.each(source, (key) => {
            if (jQuery.type(source[key]) === 'object') {
                if (jQuery.type(destination[key]) !== 'object') {
                    destination[key] = {};
                }

                copyObjectToExistingOne(source[key], destination[key]);
            } else if (jQuery.type(source[key]) === 'array') {
                destination[key] = $.extend(true, [], source[key]);
            } else {
                destination[key] = source[key];
            }
        });
    }

    /**
     * Add the text in the clipboard.
     *
     * @param {string}         textToCopy     The text to copy to the clipboard.
     * @param {boolean|string} [notify=false] Indicates if you want to display a notification when text is in the
     *                                        clipboard.
     */
    function copyText(textToCopy, notify) {
        notify = angular.isUndefinedOrEmpty(notify) ? false : notify;

        const copyingTextArea = document.createElement('textarea');
        copyingTextArea.value = textToCopy;

        document.body.appendChild(copyingTextArea);

        copyingTextArea.select();
        document.execCommand('copy');

        document.body.removeChild(copyingTextArea);

        if (notify) {
            LxNotificationService.success(angular.isString(notify) ? notify : Translation.translate('GLOBAL.COPIED'));
        }
    }

    /**
     * Display a notification on server error.
     *
     * @param {Object} err The server error.
     */
    function displayServerError(err) {
        LxNotificationService.error(service.getServerError(err));
    }

    /**
     * Empty a target (without changing its reference for array and objects).
     * If the target is an array, remove every element.
     * If the target is an object, remove every own properties.
     * If the target is a string, create a new empty string.
     * Else, returns undefined.
     *
     * @param  {*}       target       The target to empty.
     * @param  {boolean} [deep=false] Indicates if we want to deeply empty an array.
     * @return {*}       The emptied target.
     */
    function empty(target, deep) {
        if (angular.isUndefinedOrEmpty(target)) {
            return target;
        }

        if (angular.isArray(target)) {
            if (deep) {
                while (angular.isDefinedAndFilled(target)) {
                    target.splice(0, 1);
                }
            } else {
                target.length = 0;
            }
        } else if (angular.isObject(target)) {
            Object.empty(target);
        } else if (angular.isString(target)) {
            target = '';
        } else {
            target = undefined;
        }

        return target;
    }

    /**
     * Overrides the "angular.equals" function to be able to ignore some specifics things from the comparison.
     * For example, if "{}" is given as ignore, then if any of the thing is "{}", then we automatically assume that
     * the two things are equals.
     * This function recursively call itself in the case of arrays and objects.
     *
     * @param  {*}            o1       The first thing to compare.
     * @param  {*}            o2       The second thing to compare.
     * @param  {Array|string} [ignore] The things to ignore.
     *                                 Possible values are: "null", "{}", """", "[]", "NaN", "false".
     * @return {boolean}      If the two things are equals or not.
     */
    function equals(o1, o2, ignore) {
        /* eslint-disable angular/typecheck-object, lumapps/angular-isdefined */
        ignore = ignore || [];
        ignore = angular.isArray(ignore) ? ignore : [ignore];

        if (o1 === o2) {
            return true;
        }

        if ((o1 === null || o2 === null) && ignore.indexOf('null') === -1) {
            return false;
        }

        if ((o1 === null || o2 === null) && ignore.indexOf('null') > -1) {
            return true;
        }

        // eslint-disable-next-line no-self-compare
        if (o1 !== o1 && o2 !== o2 && ignore.indexOf('NaN') === -1) {
            return true;
        }

        const t1 = typeof o1;
        const t2 = typeof o2;
        let key;
        let keySet;
        let len;

        if (t1 === t2) {
            if (t1 === 'object') {
                if (angular.isArray(o1)) {
                    if (!angular.isArray(o2)) {
                        return false;
                    }

                    len = o1.length;
                    if (len === o2.length) {
                        // eslint-disable-next-line max-depth
                        for (key = 0; key < len; key++) {
                            // eslint-disable-next-line max-depth
                            if (!equals(o1[key], o2[key], ignore)) {
                                return false;
                            }
                        }

                        return true;
                    }
                }

                if (angular.isDate(o1)) {
                    if (!angular.isDate(o2)) {
                        return false;
                    }

                    return equals(o1.getTime(), o2.getTime(), ignore);
                }

                if (_isRegExp(o1)) {
                    return _isRegExp(o2) ? o1.toString() === o2.toString() : false;
                }

                if (
                    _isScope(o1) ||
                    _isScope(o2) ||
                    _isWindow(o1) ||
                    _isWindow(o2) ||
                    angular.isArray(o2) ||
                    angular.isDate(o2) ||
                    _isRegExp(o2)
                ) {
                    return false;
                }
                // Do not use {} otherwise we'll get the hasOwnProperty property in the object prototype.
                keySet = Object.create(null);
                for (key in o1) {
                    if (key.charAt(0) === '$' || angular.isFunction(o1[key])) {
                        continue;
                    }

                    if (!equals(o1[key], o2[key], ignore)) {
                        return false;
                    }

                    keySet[key] = true;
                }

                for (key in o2) {
                    if (key.charAt(0) === '$' || angular.isFunction(o2[key])) {
                        continue;
                    }

                    if (!(key in keySet)) {
                        // eslint-disable-next-line max-depth
                        if (!equals(o1[key], o2[key], ignore)) {
                            return false;
                        }
                    }
                }

                return true;
            }
        } else if (angular.isDefinedAndFilled(ignore)) {
            if (ignore.indexOf(t1) > -1 || ignore.indexOf(t2) > -1) {
                return true;
            }

            if (
                ignore.indexOf('""') > -1 &&
                ((angular.isString(o1) && o1.length === 0) || (angular.isString(o2) && o2.length === 0))
            ) {
                return true;
            }

            if (
                ignore.indexOf('false') > -1 &&
                ((typeof o1 === 'boolean' && o1 === false) || (typeof o2 === 'boolean' && o2 === false))
            ) {
                return true;
            }

            if (
                ignore.indexOf('[]') > -1 &&
                ((angular.isArray(o1) && compact(o1).length === 0) || (angular.isArray(o2) && compact(o2).length === 0))
            ) {
                return true;
            }

            if (
                ignore.indexOf('{}') > -1 &&
                ((angular.isObject(o1) && Object.keys(pick(o1, identity)).length === 0) ||
                    (angular.isObject(o2) && Object.keys(pick(o2, identity)).length === 0))
            ) {
                return true;
            }
        }

        return false;
        /* eslint-enable angular/typecheck-object, lumapps/angular-isdefined */
    }

    /**
     * Escape all HTML tags from a string.
     * Transform '<' to '&lt;' and '>' to '&gt;'.
     *
     * @param  {string}       str  The string to escape.
     * @param  {string|Array} tags The list of tags to escape.
     * @return {string}       The escaped string.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function escapeHtmlTags(str, tags) {
        if (angular.isUndefinedOrEmpty(str)) {
            return str;
        }

        tags = tags || '[^>]+';
        tags = angular.isArray(tags) ? tags : [tags];

        return str.replace(new RegExp(`<([^ ])?(${tags.join('|')})>`, 'g'), '&lt;$1$2&gt;');
    }

    /**
     * Escape string to use in regular expression.
     *
     * @param  {string} queryToEscape The string to escape.
     * @return {string} The escaped string ready to be used in a regular expression.
     *
     * Todo [Arnaud]: move to regexp utils.
     */
    function escapeRegexp(queryToEscape) {
        return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }

    /**
     * Listens to content change on the content, searches for urls and gets a preview.
     *
     * @param {Event}    evt                     The event that triggers this method.
     * @param {Object}   content                 The content object to attach a preview to. i.e. a post or a
     *                                           comment.
     * @param {string}   [currentAttachmentType] The current attachment type of the content object.
     * @param {Function} [cb]                    The callback to execute if we find a url.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function findUrls(evt, content, currentAttachmentType, cb) {
        cb = cb || angular.noop;

        const code = angular.isDefined(evt.keyCode) ? evt.keyCode : evt.which;

        // The content for posts lives in 'content' but it lives in 'text' for comments.
        const contentPropertyName = angular.isDefinedAndFilled(content.text) ? 'text' : 'content';

        const text = angular.isDefinedAndFilled(contentPropertyName)
            ? get(content, `${contentPropertyName}.${Translation.getLang('current')}`)
            : '';
        const hasPastedText =
            evt.originalEvent.clipboardData &&
            angular.isDefinedAndFilled(evt.originalEvent.clipboardData.getData('text/plain'));

        // Check if we pressed 'enter' or 'space' or pasted some text.
        if ((angular.isUndefinedOrEmpty(text) || !includes([_keys.enter, _keys.space], code)) && !hasPastedText) {
            return;
        }

        const Config = $injector.get('Config');

        // If we don't have any attachment yet, then try to generate a preview from the first link we find.
        if (
            angular.isDefinedAndFilled(currentAttachmentType) &&
            (currentAttachmentType !== Config.ATTACHMENT_TYPE.LINK || angular.isDefinedAndFilled(content.links))
        ) {
            return;
        }

        const searchText = hasPastedText ? evt.originalEvent.clipboardData.getData('text/plain'): text;
        const foundUrls = service.findUrlsInString(searchText);

        if (angular.isDefinedAndFilled(foundUrls)) {
            cb(trimStart(foundUrls[0], '//'), searchText);
        }
    }

    /**
     * Return an array of all the URLs in a given string.
     *
     * @param  {string} string The string in which try to find URLs.
     * @return {Array}  An array of all find URL within the given string.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function findUrlsInString(string) {
        return string.match(_urlRegex) || [];
    }

    /**
     * Format a file size in bytes in an human readable format.
     *
     * @param  {number} fileSize     The file size.
     * @param  {number} [decimals=3] The decimals number of decimal for the precision.
     * @return {string} The file size in an human readable format.
     *
     * Todo [Arnaud]: move to number utils.
     */
    function formatBytes(fileSize, decimals) {
        if (angular.isUndefinedOrEmpty(fileSize)) {
            return '';
        }

        if (fileSize === 0) {
            return '0 Byte';
        }

        const precision = angular.isDefined(decimals) ? decimals + 1 : _DEFAULT_PRECISION;

        const factor = 1024;
        const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(fileSize) / Math.log(factor));

        const convertedFileSize = (fileSize / factor ** i).toPrecision(precision);
        if (convertedFileSize > 1) {
            sizes[0] = 'Bytes';
        }

        return `${convertedFileSize} ${sizes[i]}`;
    }

    /**
     * Generate an easy random password.
     *
     * @param  {number} [len=8] The wanted length of the password.
     * @return {string} The generated password.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function generatePassword(len) {
        len = len || _DEFAULT_PASSWORD_SIZE;

        const char = 'abcdefghijklmnopqrstuvwxyz';
        const digit = '123456789';
        const authorizedChar = char + char.toUpperCase() + digit;
        let password = '';

        for (let i = 0; i < len; i++) {
            password += authorizedChar.charAt(Math.floor(Math.random() * authorizedChar.length));
        }

        return password;
    }

    /**
     * Get a property for the admin.
     *
     * @param  {string}  key            The property name.
     * @param  {Object}  fromObject     The object from which we want to get the admin property.
     * @param  {*}       [defaultValue] The default value to send if not overriden.
     * @param  {*}       [fallback]     The fallback value if nothing found.
     * @param  {boolean} [force=false]  Act as overridden property, for to get customer property.
     * @return {*}       The wanted property if found.
     */
    function getAdminProperty(key, fromObject, defaultValue, fallback, force) {
        force = angular.isUndefined(force) ? false : force;

        const isOverridden = Boolean(service.getProperty(key, fromObject, 'properties.__overridden'));

        const dirtyProperties = get(fromObject, 'properties.__dirty', []);
        const dirty =
            includes(dirtyProperties, key) ||
            includes(dirtyProperties, service.toUpperSnakeCase(key)) ||
            includes(dirtyProperties, service.toCamelCase(key));

        // Get customer property value if overridden/force or value is not dirty and undefined.
        if (isOverridden || force || (!dirty && angular.isUndefined(service.getProperty(key, fromObject)))) {
            return angular.fastCopy(defaultValue);
        }

        const value = service.getProperty(key, fromObject);
        if (angular.isDefined(value)) {
            return value;
        }

        return fallback;
    }

    /**
     * Return the value of the user's ApiProfile field matching the path.
     * The field path can be like: "organizations/0/name", "addresses[type=home]/formatted", ...
     *
     * @param  {string}  fieldPath               The path of the field we want to get in the user's
     *                                           ApiProfile or CustomProfile, if fieldPath is an
     *                                           UUID.
     * @param  {Object}  [user=<Connected User>] The user we want to get the ApiProfile field or
     *                                           CustomProfile value.
     * @param  {boolean} [patternOnly=false]     Only return the parsed pattern.
     * @return {*}       The user's ApiProfile field or CustomProfile value for the given path.
     */
    function getApiProfileFieldFromMap(fieldPath, user, patternOnly) {
        if (angular.isUndefinedOrEmpty(fieldPath)) {
            return undefined;
        }

        /*
         * Ugly (temporary) fix for pattern mixing '/' and '.'.
         * Since we use get function, this works.
         */
        if (fieldPath.startsWith("'")) {
            try {
                const tokens = fieldPath.split("'");
                let [, innerPath, path] = tokens;

                path = path.replace(/\./g, '/');

                fieldPath = `'${innerPath}'${path}`;
            } catch (ex) {}
        } else {
            fieldPath = fieldPath.replace(/\./g, '/');
        }

        const User = $injector.get('User');

        patternOnly = patternOnly || false;
        user = user || User.getConnected() || {};

        const computedPattern = [];

        let found = false;

        const locators = fieldPath.split('/');
        const regexp = /(.*)\[(.*)=(.*)]/;
        let value = _isUUID(fieldPath) ? angular.fastCopy(user.customProfile) : angular.fastCopy(user.apiProfile);

        for (let i = 0; i < locators.length; i++) {
            const field = locators[i];
            const match = regexp.exec(field);

            if (angular.isDefined(match)) {
                const fieldName = match[1];
                const conditionKey = match[2];
                let conditionValue = match[3];
                const fieldNameValue = get(value, fieldName);

                if (angular.isDefinedAndFilled(fieldNameValue)) {
                    for (let j = 0, len2 = fieldNameValue.length; j < len2; j++) {
                        const elem = fieldNameValue[j];

                        // eslint-disable-next-line max-depth
                        if (angular.isString(conditionValue)) {
                            const lowerConditionValue = conditionValue.toLowerCase();

                            // Check if the string is not in fact a boolean.
                            // eslint-disable-next-line max-depth
                            if (lowerConditionValue === 'true' || lowerConditionValue === 'false') {
                                conditionValue = lowerConditionValue === 'true';
                                // Also check if the string is in fact a number.
                            } else if (!isNaN(parseFloat(conditionValue)) && isFinite(conditionValue)) {
                                conditionValue = parseInt(conditionValue, 10);
                            }
                        }

                        if (elem[conditionKey] === conditionValue) {
                            computedPattern.push(fieldName);
                            computedPattern.push(j);
                            value = elem;

                            break;
                        }
                    }
                }
            } else {
                let index = locators[i];

                if (index.indexOf("'") === 0) {
                    try {
                        const val = value[index] || value[index.replace(/\'/g, '')];

                        value = get(val, tail(locators).join('.'));

                        found = true;

                        break;
                    } catch (ex) {}
                } else if (index.indexOf('.') > 1) {
                    const subValue = index.split('.');

                    if (angular.isDefined(value) && value[subValue[0]]) {
                        value = value[subValue[0]];
                        locators.push(tail(subValue).join('.'));
                    }
                } else {
                    // If index is an int.
                    if (index % 1 === 0) {
                        index = parseInt(index, 10);
                    }

                    if (angular.isDefined(value) && angular.isDefined(value[index])) {
                        value = value[index];
                        found = true;
                    } else {
                        found = false;
                        computedPattern.push(index);

                        break;
                    }
                }

                computedPattern.push(index);
            }
        }

        if (patternOnly) {
            return computedPattern;
        }

        if (!angular.isNumber(value) && !angular.isString(value) && !angular.isArray(value)) {
            value = undefined;
        }

        if (found) {
            return value;
        }

        return undefined;
    }

    /**
     * Check if key belongs in item and return it into a callback and return it too.
     * Use this for a generic "Model to Selection" in a "lx-select".
     *
     * @param  {string}   key  The name of the property to get.
     * @param  {Object}   item The object to get the value from.
     * @param  {Function} [cb] A callback to execute (if given) with the found value.
     * @return {*}        The found value.
     */
    function getAttr(key, item, cb) {
        cb = cb || angular.noop;

        if (angular.isUndefined(item) || angular.isUndefinedOrEmpty(item[key])) {
            return undefined;
        }

        cb(item[key]);

        return item[key];
    }

    /**
     * Gets the image url for background images.
     *
     * @param  {string} key    The key of the image in Cloud Storage/the Blobstore.
     * @param  {number} [size] The new size of the image we want it resized to.
     * @return {string} The image url.
     */
    function getImageURL(key, size) {
        let value = 'url(';

        if (angular.isDefinedAndFilled(size)) {
            value += service.resizeImage(key, size);
        } else {
            value += service.getMediaUrl(key);
        }

        value += ')';

        return value;
    }

    /**
     * DEPRECATED METHOD
     * Only for mediaDetails to prevent any breaking changes.
     * Gets the image url.
     *
     * @param  {string} resource    The picture url.
     * @param  {number} [size]      The new size of the image we want it resized to.
     * @return {string}             The image url.
     */
    function deprecatedGetCroppedThumbnailURL(resource, size) {
        if (angular.isDefinedAndFilled(size)) {
            return service.resizeImage(resource, size);
        }
        return service.getMediaUrl(resource);
    }

    /**
     * Get the image URL as background image.
     *
     * @param  {string}        key                    The key of the image in Cloud Storage/the Blobstore.
     * @param  {number}        [size]                 The new size of the image we want it resized to.
     * @param  {boolean}       [returnAsString=false] Return the value as a string rather than a key / value.
     * @return {Object|string} The image as a CSS "background-image".
     */
    function getBackgroundImage(key, size, returnAsString, useBlob = false) {
        if (angular.isUndefinedOrEmpty(key)) {
            return {};
        }

        returnAsString = returnAsString || false;

        const imageUrl = getImageURL(key, size);
        const imageKey = retrieveKeyFromServeImageUrl(key);

        const value = useBlob && doesBlobExist(imageKey) ? `url(${getBlob(imageKey)})` : imageUrl;

        if (returnAsString) {
            return `background-image:${value}`;
        }

        return {
            'background-image': value,
        };
    }

    /**
     * Get any of the config constants property by key.
     *
     * @param  {string} key The property key identifier.
     * @return {*}      The value of the property.
     *
     * Todo [Arnaud]: move to customer service / utils.
     */
    function getConfigProperty(key) {
        const Config = $injector.get('Config');
        const ConfigInstance = $injector.get('ConfigInstance');
        const constantsServices = [ConfigTheme, ConfigInstance, Config];

        for (let idx = 0, len = constantsServices.length; idx < len; ++idx) {
            const constantService = constantsServices[idx];

            const value = service.getProperty(key, constantService, '');
            if (angular.isDefined(value)) {
                return value;
            }
        }

        return undefined;
    }

    /**
     * Return the icon for a content according to its type.
     *
     * @param  {string} contentType The content type.
     * @return {string} The content icon.
     *
     * @see    Config.AVAILABLE_CONTENT_TYPES
     */
    function getContentIcon(contentType) {
        const Config = $injector.get('Config');

        switch (contentType) {
            case Config.AVAILABLE_CONTENT_TYPES.PAGE:
            case Config.AVAILABLE_CONTENT_TYPES.CUSTOM:
                return 'file-document-box';

            case Config.AVAILABLE_CONTENT_TYPES.NEWS:
                return 'newspaper';

            case Config.AVAILABLE_CONTENT_TYPES.MENU:
                return 'menu';

            case Config.AVAILABLE_CONTENT_TYPES.CUSTOM_LIST:
                return 'format-list-bulleted';

            case Config.AVAILABLE_CONTENT_TYPES.COMMUNITY:
                return 'google-circles-extended';

            case Config.AVAILABLE_CONTENT_TYPES.DIRECTORY:
                return 'book-multiple';

            case Config.AVAILABLE_CONTENT_TYPES.USER_DIRECTORY:
                return 'folder-account';

            case Config.AVAILABLE_CONTENT_TYPES.TUTORIAL:
                return 'help-circle';

            default:
                return undefined;
        }
    }

    /**
     * Get the slug of the default content list.
     *
     * @param  {string} customContentType     The content type of the content list we want to get the slug.
     * @param  {string} [lang=<Current Lang>] The language in which we want the content list slug.
     * @return {string} The slug of the default content list.
     */
    function getContentListSlug(customContentType, lang) {
        lang = lang || Translation.getLang('current');

        const defaultContentList = loFind(
            InitialSettings.DEFAULT_CONTENT_LISTS,
            (contentList) => contentList.customContentType === customContentType,
        );

        let slug = '';
        if (angular.isUndefined(defaultContentList) || angular.isUndefinedOrEmpty(defaultContentList.slug)) {
            return slug;
        }

        if (angular.isDefinedAndFilled(defaultContentList.slug[lang])) {
            slug = defaultContentList.slug[lang];

            // If the wanted lang is not available in the slug, use the first lang available.
        } else {
            const slugLangs = Object.keys(defaultContentList.slug);
            slug = defaultContentList.slug[slugLangs[0]];
        }

        return slug;
    }

    /**
     * Figures out the current attachment types based on what properties the content object has or not.
     *
     * @param  {Object} content The content we want to check the attachment type.
     * @return {string} The attachment type name.
     *
     * Todo [Arnaud]: move to content utils?
     */
    function getCurrentAttachmentType(content) {
        if (angular.isUndefinedOrEmpty(content)) {
            return '';
        }

        const Config = $injector.get('Config');

        if (angular.isDefinedAndFilled(content.images)) {
            return Config.ATTACHMENT_TYPE.IMAGE;
        }

        if (angular.isDefinedAndFilled(content.files)) {
            return Config.ATTACHMENT_TYPE.FILE;
        }

        if (angular.isDefinedAndFilled(content.links)) {
            return Config.ATTACHMENT_TYPE.LINK;
        }

        return '';
    }

    /**
     * Build an array of custom CSS classes from a string of comma separated CSS classes.
     *
     * @param  {string} customClasses    The custom CSS classes string (comma separated CSS classes).
     * @param  {string} [classPrefix=''] A prefix to add before each CSS class.
     * @return {Array}  The array of all (prefixed if needed) CSS classes.
     */
    function getCustomClass(customClasses, classPrefix) {
        classPrefix = classPrefix || '';

        const classesArray = [];

        if (angular.isUndefinedOrEmpty(customClasses)) {
            return classesArray;
        }

        const customClassesSplit = customClasses.split(',');
        if (angular.isUndefinedOrEmpty(customClassesSplit)) {
            return classesArray;
        }

        angular.forEach(customClassesSplit, (customClass) => {
            if (angular.isDefinedAndFilled(customClass)) {
                classesArray.push(classPrefix + customClass.trim());
            }
        });

        return classesArray;
    }

    /**
     * Get the hostname from a string URL.
     * E.g. "http://lumapps.com" will return "lumapps.com".
     *
     * @param  {string} urlToParse The URL to get the hostname from.
     * @return {string} The hostname of the given URL.
     *
     * Todo [Arnaud]: move to uri utils.
     */
    function getHostnameFromString(urlToParse) {
        return get(getLocationFromString(urlToParse), 'hostname');
    }

    /**
     * Get color based on index.
     *
     * @param  {number} idx    Index.
     * @param  {Array}  colors Colorscheme.
     * @return {string} HEX color.
     */
    function getItemColor(idx, colors) {
        if (angular.isUndefinedOrEmpty(colors)) {
            colors = ConfigTheme.COLORS_WIDGET;
        }

        const colorsLen = colors.length;
        // Euclidean division, to calculate the wanted index (looping over index).
        const colorIndex = Math.abs(Math.floor(idx / colorsLen) * colorsLen - idx);

        return colors[colorIndex];
    }

    /**
     * Return a media full url.
     *
     * @param  {string}  resource The resource path or absolute URL (ex: /serve/blobkey/).
     * @return {string} The full media url.
     */
    function getMediaUrl(resource) {
        if (angular.isUndefinedOrEmpty(resource)) {
            return resource;
        }

        // Transform any absolute URL into a relative one to support secured images from HM or Lumdrive.
        return makeSecuredMediaURLRelative(resource);
    }

    /**
     * Parse a MIME type and return the media type.
     * E.g: "image/pampul" will return 'image'.
     *
     * @param  {string} mimeType The MIME type to parse.
     * @return {string} The media type.
     *
     * Todo [Arnaud]: move to media service / utils?
     */
    function getMimeTypeMediaType(mimeType) {
        if (angular.isUndefinedOrEmpty(mimeType) || !angular.isString(mimeType) || !includes(mimeType, '/')) {
            return undefined;
        }

        return get(mimeType.split('/'), '[0]');
    }

    /**
     * Get the parent full slug for a given path.
     *
     * @param  {string} urlPath The path to use to compute the slug.
     * @return {string} The parent full slug.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function getParentFullSlug(urlPath) {
        const split = urlPath.split('/');

        return split.length > 1 ? `${split.slice(0, split.length - 1).join('/')}/` : undefined;
    }

    /**
     * Get the value of a property of an object based on a path and the property name.
     * This function has multiple fallback and backward compatibility:
     *     - it first check for the given property name in camelCase;
     *     - then it check for the given property name in UPPER_SNAKE_CASE;
     *     - then it check for the given property name as given;
     *     - finally it can check for the backward property name (if any);
     *
     * This function is mainly used to get properties from an Instance or a Customer, due to multiple backward and
     * a bad history on this subject.
     *
     * @param  {Object|string} key                   The key of the property we want to get.
     *                                               If an object is given, the name of the property must be in the
     *                                               'key' property and you can provide a 'backward' property.
     * @param  {Object}        fromObject            The object from which we want to get the property.
     * @param  {string}        [xPath='properties']  The path to the property within the object.
     * @param  {boolean}       [camelCase=true]      Indicates if we want to check the key in camelCase.
     * @param  {boolean}       [upperSnakeCase=true] Indicates if we want to check the key in UPPER_SNAKE_CASE.
     * @param  {boolean}       [original=true]       Indicates if we want to check the key as it was written.
     * @param  {boolean}       [backward=true]       Indicates if we want to check the 'backward' key given.
     * @return {*}             The wanted property value.
     */
    function getProperty(key, fromObject, xPath, camelCase, upperSnakeCase, original, backward) {
        if (
            angular.isUndefinedOrEmpty(key) ||
            (angular.isObject(key) && angular.isUndefinedOrEmpty([key.key, key.backward], 'every'))
        ) {
            return undefined;
        }

        xPath = angular.isUndefined(xPath) ? 'properties' : xPath;
        camelCase = angular.isUndefined(camelCase) ? true : camelCase;
        upperSnakeCase = angular.isUndefined(upperSnakeCase) ? true : upperSnakeCase;
        original = angular.isUndefined(original) ? true : original;
        backward = angular.isUndefined(backward) ? true : backward;

        if (angular.isString(key)) {
            key = {
                key: key || '',
            };
        }

        const propertyName = key.key;

        if (original) {
            // Try to find the requested property  in the instance properties as it was given.
            const originalInstanceProperty = get(fromObject, compact([xPath, propertyName]));
            if (angular.isDefined(originalInstanceProperty)) {
                return originalInstanceProperty;
            }
        }

        if (camelCase) {
            // Try to find the requested property in the instance properties in camelCase.
            const camelCaseInstanceProperty = get(fromObject, compact([xPath, service.toCamelCase(propertyName)]));
            if (angular.isDefined(camelCaseInstanceProperty)) {
                return camelCaseInstanceProperty;
            }
        }

        if (upperSnakeCase) {
            // Try to find the requested property in the instance properties in UPPER_SNAKE_CASE.
            const upperSnakeCaseInstanceProperty = get(
                fromObject,
                compact([xPath, service.toUpperSnakeCase(propertyName)]),
            );
            if (angular.isDefined(upperSnakeCaseInstanceProperty)) {
                return upperSnakeCaseInstanceProperty;
            }
        }

        // If there is a backward key, try to find it in the instance properties.
        if (backward && angular.isDefinedAndFilled(key.backward)) {
            const backwardInstanceProperty = get(fromObject, compact([xPath, key.backward]));
            if (angular.isDefined(backwardInstanceProperty)) {
                return backwardInstanceProperty;
            }
        }

        return undefined;
    }

    /**
     * Get the formatted content displayed in read mode.
     *
     * @param  {string} html The HTML content to format.
     * @return {string} The formatted content.
     *
     * Todo [Arnaud]: move to string utils?
     */
    function getReadableHtmlContent(html) {
        if (angular.isUndefinedOrEmpty(html)) {
            return html;
        }

        // Removes empty hrefs added by Froala.
        html = html.replace(/ href(=(""|''))? /g, '');

        // Escape Script tag in safe mode.
        if (service.isSafeModeEnabled()) {
            html = sanitizeHTMLWithDebugScriptsAndStyles(html);
        }

        // Apply relative URLs to secured images
        html = applyRelativeURLForSecuredImagesInHTML(html);

        return $sce.trustAsHtml(service.replaceVariables(service.replaceTranslatableValues(html)));
    }

    /**
     * Get the error message from an error response of the server.
     *
     * @param  {Object} err The server error.
     * @return {string} The server error message.
     */
    function getServerError(err) {
        if (angular.isUndefined(err) || angular.isUndefined(err.data) || angular.isUndefinedOrEmpty(err.data.error)) {
            return Translation.translate('ERROR_GENERIC');
        }

        if (angular.isDefinedAndFilled(err.data.error.message) && err.data.error.message.match('^([A-Z_]+)$')) {
            return Translation.translate(`SERVER_ERROR_${err.data.error.message}`);
        }

        // eslint-disable-next-line no-magic-numbers
        if (err.data.error.code === 403) {
            return Translation.translate('ERROR_NOT_AUTHORIZED');
        }

        return Translation.translate('ERROR_GENERIC');
    }

    /**
     * Get the filters in the state/URL parameters.
     * A filter must be in the format "<name>_<value>" (e.g. "tags_123").
     *
     * @param  {Array|string} stateParams The parameters of the state.
     * @return {Object}       The filters.
     *
     * Todo [Arnaud]: move to uri utils.
     */
    function getSimpleUrlParams(stateParams) {
        const urlFilters = angular.isArray(stateParams) ? stateParams : [stateParams];

        const properties = {};

        angular.forEach(urlFilters, (urlFilter) => {
            if (angular.isUndefinedOrEmpty(urlFilter) || !angular.isString(urlFilter)) {
                return;
            }

            const splittedUrlFilter = urlFilter.split('_');

            // eslint-disable-next-line no-magic-numbers
            if (angular.isUndefinedOrEmpty(splittedUrlFilter) || splittedUrlFilter.length !== 2) {
                return;
            }

            const identifier = splittedUrlFilter[0];
            const value = splittedUrlFilter[1];

            if (angular.isUndefinedOrEmpty(identifier) || angular.isUndefinedOrEmpty(value)) {
                return;
            }

            // It's an array filter, check if the element already exists and push it if not.
            if (angular.isArray(properties[identifier]) && properties[identifier].indexOf(value) === -1) {
                properties[identifier].push(value);

                // It's a string but there is another value found for the same filter, create an array.
            } else if (angular.isDefined(properties[identifier]) && properties[identifier] !== value) {
                properties[identifier] = [properties[identifier]];
                properties[identifier].push(value);

                // It's a string, just set the filter.
            } else {
                properties[identifier] = value;
            }
        });

        return properties;
    }

    /**
     * Get newUrl if user params has to be replaced.
     *
     * @param  {string} urlToFormat The url to format with user values.
     * @param  {Object} user        The user for data injection.
     * @return {string} The formatted url.
     */
    async function getUrlUserInjectionInformation(urlToFormat, user) {
        const User = $injector.get('User');

        user = user || User.getConnected() || {};

        if (angular.isUndefinedOrEmpty(user.id)) {
            return urlToFormat;
        }

        let tempUrlToFormat = '';
        const mapKey = [];
        const paramsUrl = urlToFormat.split('&');

        // The for each is asynced so that we can load jsonpath if needed.
        await Promise.all(
            map(paramsUrl, async (paramsAndValueToConvert) => {
                const split = paramsAndValueToConvert.split(/[=](.+)/);
                const paramObject = {};

                if (angular.isDefinedAndFilled([split[0], split[1]], 'every')) {
                    paramObject[split[0]] = split[1].split(/\(user\.(.+)\)/)[1];
                    if (angular.isDefinedAndFilled(paramObject[split[0]])) {
                        if (angular.isUndefinedOrEmpty(_jsonpath)) {
                            // eslint-disable-next-line no-inline-comments
                            _jsonpath = await import(
                                /* webpackChunkName: "jsonpath", webpackPrefetch: true */ 'jsonpath'
                            );
                            _jsonpath = _jsonpath || {
                                query: get,
                            };
                        }
                        if (!angular.isFunction(_jsonpath.query)) {
                            _jsonpath.query = get;
                        }

                        const keyValue = _jsonpath.query(user, paramObject[split[0]]);
                        paramObject[split[0]] = _formatAndEncodeArrayValues(keyValue);
                    } else {
                        paramObject[split[0]] = angular.isDefinedAndFilled(split[1])
                            ? split[1]
                            : 'not_assessable_value';
                    }
                    mapKey.push(paramObject);
                }
            }),
        );

        angular.forEach(mapKey, (paramObject) => {
            const property = Object.getOwnPropertyNames(paramObject);
            tempUrlToFormat += `${property}=${paramObject[property]}&`;
        });

        return tempUrlToFormat;
    }

    /**
     * Handle the correct redirection when a user clicks on a link linked
     * to a content in the HTML widget.
     *
     * @param {Event} evt The click event on the link.
     */
    function handleClickOnLink(evt) {
        /**
         * The newWindow object is a workaround the popups blocking issue.
         * Browsers will block windows which are not resulting from instant user actions. In this case
         * the new window is set when the Content.get is made. The asynchronous call of $window.open
         * will be considered as a popup.
         */
        const dataUrlProp = get(evt.target.closest('[data-url]'), 'attributes["data-url"]');

        if (angular.isUndefinedOrEmpty(dataUrlProp)) {
            return;
        }

        evt.preventDefault();
        evt.stopImmediatePropagation();

        const content = angular.fromJson(get(dataUrlProp, 'value', {}));

        if (angular.isUndefinedOrEmpty([content.id, content.instance, content.type], 'some')) {
            return;
        }

        const blank = get(evt, 'currentTarget.target', '_self') === '_blank';

        // Redirect to space
        if (content.type === InitialSettings.CONTENT_TYPES.COMMUNITY && content.renderingType === RenderingType.space) {
            angularApi.redirect(
                spaceView({
                    params: { id: content.id, slug: Translation.translate(content.slug) }
                }), { openInNewTab: blank }
            );
        }

        let newWindow;

        // If blank, we create the new window. The URL of this window will be set later.
        if (blank) {
            newWindow = $window.open('');
        }

        const Service = content.type === InitialSettings.CONTENT_TYPES.COMMUNITY ?
                        $injector.get('Community') :
                        $injector.get('Content');

        Service.getContentLink(content.id, content.instance, content.type).then(function onSuccess(contentLink) {
            if (angular.isUndefinedOrEmpty(contentLink.slug)) {
                return;
            }

            if (angular.isDefinedAndFilled(contentLink.externalLink) && Translation.hasTranslations(contentLink.externalLink, true)) {
                $window.open(Translation.translate(contentLink.externalLink), '_blank');
                return;
            }

            const stateName =
                content.type === InitialSettings.CONTENT_TYPES.COMMUNITY
                    ? 'app.front.community'
                    : 'app.front.content-get';

            const MainNav = $injector.get('MainNav');
            MainNav.goTo(
                stateName,
                content.id,
                content.instance,
                Translation.translate(contentLink.slug),
                undefined,
                content.type,
                blank,
                newWindow,
            );
        });
    }

    /**
     * Recursively check if a component has a given child.
     *
     * @param  {Object}  container The container of elements.
     * @param  {Object}  child     The child we are looking for.
     * @return {boolean} If the given child is in the container or any of its children.
     */
    function hasChild(container, child) {
        if (angular.isUndefinedOrEmpty([container, child], 'some')) {
            return false;
        }

        const list = container.components || container.cells || [];

        if (angular.isUndefinedOrEmpty(list) || !angular.isArray(list)) {
            return false;
        }

        for (let i = 0, len = list.length; i < len; i++) {
            const item = list[i];

            if (item.uuid === child.uuid || service.hasChild(item, child)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if an object has all the given key set.
     *
     * @param  {Object}       object      The object to check.
     * @param  {Array|string} keysToCheck The keys to check in the object.
     * @return {boolean}      If the given object has all the given keys set.
     */
    function hasMultiple(object, keysToCheck) {
        if (angular.isUndefinedOrEmpty(object)) {
            return false;
        }

        keysToCheck = angular.isDefined(keysToCheck) && !angular.isArray(keysToCheck) ? [keysToCheck] : keysToCheck;

        if (angular.isUndefinedOrEmpty(keysToCheck)) {
            return true;
        }

        for (let i = 0, len = keysToCheck.length; i < len; ++i) {
            if (angular.isUndefined(object[keysToCheck[i]])) {
                return false;
            }
        }

        return true;
    }

    /**
     * Fully escape an HTML string.
     *
     * @param  {string} str The string to escape.
     * @return {string} The escaped string.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function htmlEscape(str) {
        return loEscape(str.replace(/'/g, '&#039;'));
    }

    /**
     * Fully unescape an HTML string.
     *
     * @param  {string} str The string to unescape.
     * @return {string} The unescaped string.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function htmlUnescape(str) {
        return loUnescape(str.replace(/&#0?39;/g, "'"));
    }

    /**
     * Initialize the components recursively in a template.
     *
     * @param {Object} template The template in which initialize the components.
     */
    function initComponents(template) {
        if (angular.isDefinedAndFilled(template.components)) {
            angular.forEach(template.components, (component) => {
                if (component.type === 'row') {
                    if (angular.isDefinedAndFilled(component.cells)) {
                        angular.forEach(component.cells, (cell) => initComponents(cell));
                    }
                }
            });
        } else {
            template.components = [];
        }
    }

    /**
     * Init the html content.
     *
     * @param  {string}  widgetUIID               The UUID of the widget which contain Froala.
     * @param  {string}  translatedContent        The translated content of the main content.
     * @param  {string}  htmlParentSelector       The HTML selector of of the Froala parent element.
     * @param  {string}  [popinTranslatedContent] The translated content of the popin.
     * @param  {boolean} isListenerOnLinks        If the html links are already listened.
     * @return {Object}  The HTML content object.
     */
    function initHtmlContent(
        widgetUIID,
        translatedContent,
        htmlParentSelector,
        popinTranslatedContent,
        isListenerOnLinks,
    ) {
        const htmlContent = {
            mainContent: '',
            popinContent: '',
        };

        htmlContent.mainContent = service.getReadableHtmlContent(translatedContent);
        const isDefinedContent = angular.isDefinedAndFilled(htmlContent.mainContent);

        if (isDefinedContent && !isListenerOnLinks) {
            service.waitForAndExecute(htmlParentSelector).then(function waitForWidgetToRender() {
                // This will handle possible mutilple html widgets in the same page.
                service.rebindLinks(htmlParentSelector, service.handleClickOnLink);

                $rootScope.$broadcast('listenerOnLinks', widgetUIID);
            });
        }

        if (angular.isDefinedAndFilled(popinTranslatedContent)) {
            htmlContent.popinContent = service.getReadableHtmlContent(popinTranslatedContent);
        }

        return htmlContent;
    }

    /**
     * Render Google reCaptcha.
     *
     * @param {$scope} $scope  The current scope from which the initialization is made.
     * @param {Object} captcha The object holding the captcha state.
     */
    function initGoogleCaptcha($scope, captcha) {
        const Config = $injector.get('Config');

        captcha.id = $window.grecaptcha.render('captcha', {
            // eslint-disable-next-line id-blacklist
            callback() {
                $scope.$apply(() => {
                    captcha.valid = true;
                    captcha.response = $window.grecaptcha.getResponse(captcha.id);
                });
            },
            'expired-callback': () => {
                $scope.$apply(() => {
                    captcha.valid = false;
                    captcha.response = null;
                });
            },
            sitekey: Config.G_RECAPTCHA_KEY,
        });
    }

    /**
     * Inject a script in the DOM.
     *
     * @param  {string}  scriptPath      The path to the script to inject.
     * @param  {string}  [id]            The HTML id of the script element.
     * @param  {string}  [target='body'] The target where to insert the lang script.
     * @param  {string}  [afterId]       The HTML id of the element to inject the script after.
     * @param  {boolan}  [wait=true]     Indicates if we want to wait for the script to be loaded before resolving
     *                                   the promise.
     * @return {Promise} A promise that resolves when the script has been loaded.
     */
    function injectScript(scriptPath, id, target, afterId, wait) {
        wait = angular.isUndefined(wait) ? true : wait;
        target = target || 'body';

        return $q((resolve) => {
            /* eslint-disable angular/document-service */
            const newScript = document.createElement('script');

            let afterElement;
            let targetElement;
            if (angular.isDefinedAndFilled(afterId)) {
                afterElement = document.getElementById(afterId);

                if (angular.isDefinedAndFilled(afterElement)) {
                    targetElement = afterElement.parentNode;
                }
            }

            targetElement = targetElement || target;
            targetElement = angular.element(targetElement);

            if (angular.isDefinedAndFilled(id)) {
                newScript.id = id;
            }

            if (afterElement) {
                angular.element(newScript).insertAfter(afterElement);
            } else {
                targetElement.append(newScript);
            }

            if (wait) {
                newScript.addEventListener('load', resolve);
                newScript.addEventListener('error', resolve);
            }

            newScript.charset = 'UTF-8';
            newScript.src =
                startsWith(scriptPath, 'http') || startsWith(scriptPath, '/serve')
                    ? scriptPath
                    : InitialSettings.PUBLIC_PATH_PREFIX + scriptPath;

            if (!wait) {
                resolve();
            }
            /* eslint-disable angular/document-service */
        });
    }

    /**
     * Inject a stylesheet in the DOM.
     *
     * @param  {string}  stylesheetPath The path to stylesheet.
     * @param  {string}  [id]           The HTML id of the link element.
     * @param  {Element} [afterElement] The HTML element we want to append the stylesheet after. This is usefull in
     *                                 order to keep a clean hierarchy between styles.
     * @return {Element} The element that was just append.
     */
    function injectStylesheet(stylesheetPath, id, afterElement) {
        /* eslint-disable angular/document-service */
        const header = document.head;
        const newLink = document.createElement('link');

        newLink.rel = 'stylesheet';
        if (angular.isDefinedAndFilled(id)) {
            newLink.id = id;
        }

        newLink.href =
            service.isLocalEnvironment() && startsWith(stylesheetPath, '/client/')
                ? InitialSettings.PUBLIC_PATH_PREFIX + stylesheetPath
                : stylesheetPath;

        if (angular.isDefined(afterElement) && afterElement instanceof Element) {
            header.insertBefore(newLink, afterElement.nextSibling);
        } else {
            header.appendChild(newLink);
        }

        return newLink;
        /* eslint-disable angular/document-service */
    }

    /**
     * Check if the debug mode is enabled.
     *
     * @return {boolean} If debug mode is enabled or not.
     */
    function isDebugModeEnabled() {
        return angular.isDefined(get($location.search(), 'debug'));
    }

    /**
     * Determines if we are in designer mode.
     *
     * @return {boolean} If we are in designer mode or not.
     */
    function isDesignerMode() {
        const Content = $injector.get('Content');
        const action = Content.getAction();

        return angular.isDefinedAndFilled(action) && action !== 'get';
    }

    /**
     * Validate email value.
     *
     * @param  {string}  email The email to validate.
     * @return {boolean} If email is valid or not.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function isEmailValid(email) {
        if (angular.isUndefinedOrEmpty(email)) {
            return false;
        }

        const atPosition = email.indexOf('@');
        const lastDotPosition = email.lastIndexOf('.');
        const beforeLastLength = email.length - 1;

        return (
            atPosition > 0 &&
            atPosition < beforeLastLength &&
            lastDotPosition > -1 &&
            lastDotPosition > atPosition + 1 &&
            lastDotPosition < beforeLastLength
        );
    }

    /**
     * Validate Google Analytics code.
     *
     * @param  {string}  code The Analytics code to validate.
     * @return {boolean} Whether the Google Analytics code is valid.
     */
    function isGAValid(code) {
        const regex = /^UA-[0-9]+-[0-9]+|G-[A-Z0-9]{10}$/;

        return angular.isUndefinedOrEmpty(code) || regex.test(code);
    }

    /**
     * Validate hexadecimal format.
     *
     * @param  {string}  value The value to check.
     * @return {boolean} If the value is a valid hexadecimal or not.
     */
    function isHexadecimal(value) {
        const regExp = /^#[0-9A-F]{6}$/i;

        return regExp.test(value);
    }

    /**
     * Check if all translations are empty.
     *
     * @deprecated Prefer usage of `Translation.hasTranslations(s)`
     * @param  {Array|Object} translations The translations.
     * @return {boolean}      If the translations are empty or not.
     */
    function isLangArrayEmpty(translations) {
        if (angular.isUndefinedOrEmpty(translations)) {
            return true;
        }

        for (const lang in translations) {
            if (translations.hasOwnProperty(lang)) {
                if (!angular.isString(lang)) {
                    continue;
                }

                if (angular.isUndefinedOrEmpty(translations[lang])) {
                    continue;
                }

                return false;
            }
        }

        return true;
    }

    /**
     * Check if we are in a local environment.
     *
     * @return {boolean} If the current environment is local or not.
     */
    function isLocalEnvironment() {
        return includes(InitialSettings.API_HOST, 'localhost') || includes(InitialSettings.API_HOST, '127.0.0.1');
    }

    /**
     * Check if the safe mode is enabled.
     *
     * @return {boolean} If safe mode is enabled or not.
     *
     * Todo [Arnaud]: move to common utils.
     */
    function isSafeModeEnabled() {
        return angular.isDefined(get($location.search(), 'safe'));
    }

    function isHeadlessModeOn() {
        if (!window.LUMAPPS_MODES) {
            const modesParam = get($location.search(), 'modes');
            window.LUMAPPS_MODES = modesParam;
        }

        const modes = getEnabledModes(window.LUMAPPS_MODES ? window.LUMAPPS_MODES.split(',') : []);
        const isHeadless = modes[MODES.HEADLESS];
        const { body } = document;

        if (isHeadless && !body.classList.contains('headless')) {
          body.classList.add('headless');
        }

        return isHeadless;
    }

    function getHeaderHeight() {
        const header = document.querySelector('header');
        const headerHeight = header ? header.getBoundingClientRect().height : 110;

        return headerHeight;
    }

    function getMarginTop(header, features, layout) {
        const shouldAddMargin = !layout.isInShareContent && !isHeadlessModeOn();
        const baseMargin = 12;

        if (!shouldAddMargin) {
            return 0;
        }

        let margin = header ? header.getCurrent().properties.layoutPosition : 0;

        const hasInheritedNavWithEmptyHeader = features.hasMainNavInheritance()
            ? !document.querySelector('.header > .header-content')
            : false;
        if (hasInheritedNavWithEmptyHeader) {
            margin += 108;
        }

        return margin - baseMargin;
    }

    /**
     * Include Google reCaptcha into the DOM.
     *
     * @param {Function} cb The callback to call to check the captcha.
     */
    function loadGoogleCaptcha(cb) {
        cb = cb || angular.noop;

        const captcha = $document[0].createElement('script');
        captcha.setAttribute(
            'src',
            `https://www.google.com/recaptcha/api.js?render=explicit&hl=${Translation.getLang('current')}`,
        );
        captcha.setAttribute('async', '');
        captcha.setAttribute('defer', '');

        $document[0].head.appendChild(captcha);

        _checkCaptcha(cb);
    }

    /**
     * Transform an array of object with enable property in an array of string, extracting only enabled entries.
     *
     * @param  {Array} list A list of object with enabled properties.
     * @return {Array} An array of string with enabled entries.
     */
    function mapEnabledEntries(list) {
        if (!angular.isArray(list) || angular.isUndefinedOrEmpty(list)) {
            return [];
        }

        if (angular.isUndefinedOrEmpty(get(list[0], 'enable'))) {
            return list;
        }

        return list.reduce((newList, item) => {
            if (item.enable) {
                newList.push(item.name);
            }

            return newList;
        }, []);
    }

    /**
     * Search for an item and return it.
     * Use this for a generic "Selection to Model" in a "lx-select".
     *
     * @param  {Array}    haystack A list of object.
     * @param  {string}   needle   The name of the attributes to use to find the right object.
     * @param  {*}        match    The value to match.
     * @param  {Function} [cb]     A callback to execute (if given) with the found object.
     * @return {Object}   The found object.
     */
    function matchAttr(haystack, needle, match, cb) {
        cb = cb || angular.noop;

        if (!angular.isArray(haystack)) {
            return undefined;
        }

        const result = loFind(
            haystack,
            (item) => angular.isDefined(item) && angular.isDefined(item[needle]) && item[needle] === match,
        );

        cb(result);

        return result;
    }

    /**
     * Callback to be execute when multiple item have been processed.
     * All items that haven't been processed remains selected.
     *
     * @param  {Object} response       The response of the processing.
     *                                 This response contains a `uid` array (which is the list of successfully
     *                                 processed items) and a `error` array (which contains a list of object
     *                                 { uid: <item uid>, reason: <the error message> } for each item that has
     *                                 not been processed due to an error).
     * @param  {Array}  selectedItems  The list of selected items (the ones that have been given to the processing
     *                                 endpoint).
     * @param  {string} successMessage The message to display when all items have been successfully processed.
     * @param  {string} warningMessage The message to display when some items haven't been processed.
     * @param  {string} errorMessage   The message to display when all items haven't been processed.
     * @param  {string} errPrefix      The prefix to add to the error message of each item.
     * @return {Array}  The list of still selected items.
     */
    function multiCallback(response, selectedItems, successMessage, warningMessage, errorMessage, errPrefix) {
        if (angular.isUndefinedOrEmpty(response.errors)) {
            LxNotificationService.success(successMessage);
            selectedItems = [];
        } else {
            let message;
            let type;
            if (response.errors.length < selectedItems.length) {
                message = warningMessage;
                type = 'warning';
            } else {
                message = errorMessage;
                type = 'error';
            }

            const tag = response.errors.length > 1 ? 'ol' : 'ul';
            message += `<${tag}>`;
            angular.forEach(response.errors, (err) => {
                const reason = Translation.translate(`${errPrefix}_${err.reason}`);

                message += `<li>${reason}</li>`;
            });
            message += `</${tag}>`;

            LxNotificationService[type](message);

            selectedItems = filter(selectedItems, (item) => !includes(response.uid || [], item.uid));
        }

        return selectedItems;
    }

    /**
     * Open a content page.
     *
     * @param {Event}  evt           The original click event triggering this method.
     * @param {number} contentId     The id of the content to navigate to.
     * @param {string} widgetUuid    The uuid of the widget containing the link to the content we are about to open.
     * @param {string} instanceId    The id of the instance of the content to open.
     * @param {string} [contentType] The type of content to open.
     *
     * Todo [Arnaud]: move to specific airliquide.
     */
    function openContent(evt, contentId, widgetUuid, instanceId, contentType) {
        evt.preventDefault();

        if (angular.isUndefinedOrEmpty(instanceId)) {
            return;
        }

        const params = {
            uid: contentId,
        };

        let contentService = $injector.get('Content');
        let stateName = 'app.front.content-get';

        // Close dialog if any.
        if (angular.isDefinedAndFilled(widgetUuid)) {
            LxDialogService.close(widgetUuid);
        }

        // Backward compatibility, instanceId must be a string.
        if (!angular.isString(instanceId)) {
            instanceId = instanceId.toString();
        }

        if (contentType === InitialSettings.CONTENT_TYPES.COMMUNITY) {
            contentService = $injector.get('Community');
            stateName = 'app.front.community';
        }

        params.instance = instanceId;

        contentService.get(
            params,
            (response) => {
                const blank = get(evt, 'currentTarget.target') === '_blank';
                const slug = get(response, 'slug');

                if (angular.isUndefinedOrEmpty(slug)) {
                    return;
                }

                const MainNav = $injector.get('MainNav');
                MainNav.goTo(
                    stateName,
                    contentId,
                    instanceId,
                    Translation.translate(slug),
                    undefined,
                    contentType,
                    blank,
                );
            },
            undefined,
            undefined,
            {
                id: true,
                instance: true,
                link: true,
                slug: true,
                type: true,
            },
            false,
        );
    }

    /**
     * Open a popin.
     *
     * @param {Event}  evt        The click event.
     * @param {string} widgetUuid The id of the popin to open.
     *
     * Todo [Arnaud]: move to Froala service.
     */
    function openPopin(evt, widgetUuid) {
        evt.preventDefault();

        service.waitForAndExecute(`#${widgetUuid}`);

        const Analytics = $injector.get('Analytics');
        const Content = $injector.get('Content');
        Analytics.handleTaggingMap('open-content-popin', 'open-content-popin', {
            item: Content.getCurrent(),
        });
    }

    /**
     * Return an array of all the urls in a given string.
     * Update an URL to add dynamic values from the Api Profile.
     * The format is: "$$<Field name>$$".
     *
     * @param  {string} urlToUpdate The URL to update.
     * @return {string} The update URL.
     *
     * Todo [Arnaud]: move to uri utils.
     */
    function parseUrlPattern(urlToUpdate) {
        const matches = urlToUpdate.match(/\$\$([^$]*)\$\$/g);

        angular.forEach(matches, (match) => {
            const patternValue = getApiProfileFieldFromMap(match.replace(/\$/g, ''));

            if (angular.isDefinedAndFilled(patternValue)) {
                urlToUpdate = urlToUpdate.replace(match, patternValue);
            }
        });

        return urlToUpdate;
    }

    /**
     * Rebind links in Froala in readmode.
     *
     * @param {string}   selector The element identifier.
     * @param {Function} cb       The function to call.
     */
    function rebindLinks(selector, cb) {
        if (angular.isUndefinedOrEmpty([selector, cb], 'some')) {
            return;
        }

        const targetElement = angular.element(`${selector} a`);
        Array.from(targetElement).forEach((link) => {
            const href = link && link.getAttribute('href');

            // Mailto are always opened in a new tab (LUM-4205)
            if (href && href.toLowerCase().startsWith('mailto:')) {
                link.setAttribute('target', '_blank');
            } else {
                link.setAttribute('target', link.getAttribute('target') || '_self');
            }
        });

        targetElement.unbind('click', cb);
        targetElement.bind('click', cb);
    }

    /**
     * Redirect to a content list filtered by metadata and tags.
     *
     * @param {string} customContentType     The content type of the content list we want to redirect.
     * @param {string} [lang=<Current Lang>] The language in which we want the content list.
     * @param {Array}  [metadataKeys]        The metadata keys.
     * @param {Array}  [tagKeys]             The tag keys.
     */
    function redirectToContentList(customContentType, lang, metadataKeys, tagKeys) {
        const slug = service.getContentListSlug(customContentType, lang);

        if (angular.isUndefinedOrEmpty(slug)) {
            return;
        }

        const filters = [];

        angular.forEach(metadataKeys, (metadataKey) => filters.push(`metadata_${metadataKey}`));

        angular.forEach(tagKeys, (tagKey) => filters.push(`tags_${tagKey}`));

        $location.url(
            service.uri(
                $state.href('app.front.content-get', {
                    slug,
                }),
                filters,
            ),
        );
    }

    /**
     * Redirect to the corresponding state with state params.
     *
     * @param {string}  state       The name of the state to reach.
     * @param {Object}  stateParams The state params.
     * @param {boolean} forceReload Whether the page should reload or not.
     */
    function redirectTo(state, stateParams, forceReload) {
        $state.go(state, stateParams, { reload: forceReload });
    }

    /**
     * Get a string and remove accent from it.
     *
     * @param  {string} string The string to remove accent from.
     * @return {string} The string without accent.
     *
     * Todo [Arnaud]: use lodash equivalent? _.deburr.
     */
    function removeAccentFromString(string) {
        if (angular.isUndefinedOrEmpty(string)) {
            return '';
        }

        const words = string.split(' ') || [];
        angular.forEach(words, (word, index) => (words[index] = service.slugify(word)));

        return words.join(' ');
    }

    /**
     * Remove the body print class.
     */
    function removePrintClass() {
        angular.element('body').removeClass(_PRINT_CLASS);
    }

    /**
     * Replace classic new lines ("\n" and "\r") by HTML new lines ("<br>").
     *
     * @param  {string} string The string in which to replace new lines.
     * @return {string} The string with HTML new lines.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function replaceNewLines(string) {
        if (angular.isNumber(string)) {
            return string;
        }

        if (angular.isUndefinedOrEmpty(string) || !angular.isString(string)) {
            return '';
        }

        return string.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    /**
     * Replace variables in a string by their translated value.
     * The format is: "##<Translation key>##".
     *
     * @param  {string} string The string in which replace the variables.
     * @return {string} The string with replaced values.
     *
     * Todo [Arnaud]: move to translation utils?
     */
    function replaceTranslatableValues(string) {
        if (angular.isUndefinedOrEmpty(string)) {
            return string;
        }

        return string.replace(/##([^#]*)##/g, (replaceA, replaceB) => {
            if (angular.isDefinedAndFilled(replaceB)) {
                return Translation.translate(replaceB);
            }

            return replaceB;
        });
    }

    /**
     * Replace variables in a string.
     * The format is: "$$<Variable name>$$".
     *
     * @param  {string} string The string in which replace the variables.
     * @return {string} The string with replaced values.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function replaceVariables(string) {
        if (angular.isUndefinedOrEmpty(string)) {
            return string;
        }

        return string.replace(/\$\$([^$]*)\$\$/g, (replaceA, replaceB) => {
            if (angular.isUndefinedOrEmpty(replaceB)) {
                return replaceB;
            }

            const User = $injector.get('User');
            const Content = $injector.get('Content');

            if (replaceB === 'CONNECTED_USER_EMAIL') {
                const user = User.getConnected();

                if (angular.isDefined(user) && angular.isDefinedAndFilled(user.email)) {
                    return User.getConnected().email;
                }

                return '';
            } else if (replaceB === 'TARGET_NAME') {
                const contentTitle = get(Content.getCurrent(), 'properties.currentTarget.title', '');

                if (angular.isDefinedAndFilled(contentTitle)) {
                    return Translation.translate(contentTitle);
                }

                return '';

                // Variable to insert a "$nbsp;"s as they are not supported by Froala.
            } else if (replaceB === 'NBSP') {
                return '&nbsp;';
            }

            return replaceB;
        });
    }

    /**
     * Replace tokens in the string.
     * The tokens object should looks like this :
     * {
     *  'string_to_search': 'string_to_replace'
     * }.
     *
     * @param  {string}  string     The string in which we replace the tokens.
     * @param  {boolean} useDefault Indicates default tokens should be added.
     * @param  {Object}  [tokens]   The replacement tokens.
     * @return {string}  The string with replaced values.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function replaceTokens(string, useDefault, tokens) {
        tokens = tokens || {};

        if (useDefault) {
            tokens = angular.extend(tokens, _REPLACEMENT_TOKENS);
        }

        return string.replace(new RegExp(Object.keys(tokens).join('|'), 'gmi'), (matched) => tokens[matched]);
    }

    /**
     * Add the size of the image at the end of the url.
     * "=s[size]" is added at the end of the url.
     *
     * @param  {string}  imageUrl             The URL of the image to resize.
     * @param  {number}  [size=512]           The new size of the image we want it resized to.
     * @param  {boolean} [crop=false]         Indicates if the image should be cropped or not.
     *                                        When the image is cropped, it is centered both horizontally and vertically.
     * @param  {boolean} [enforceAbsoluteUrl] Enforce absolute URL whenever it's a haussmann media and it's relative.
     * @return {string}  The URL to the image with the new size.
     */
    function resizeImage(imageUrl, size, crop, enforceAbsoluteUrl) {
        if (angular.isUndefinedOrEmpty(imageUrl)) {
            return undefined;
        }

        /**
         * Handle case where they've uploaded a file when we only allow images.
         * In theory this should not happen anymore since we filter file types on upload as well now.
         */
        if (angular.isObject(imageUrl) && angular.isDefinedAndFilled(imageUrl.value)) {
            imageUrl = imageUrl.value;
        }

        if (!angular.isString(imageUrl)) {
            return service.getMediaUrl(imageUrl);
        }

        // Url is just a blobkey.
        if (!startsWith(imageUrl, 'http') && !includes(imageUrl, '/serve/') && !includes(imageUrl, 'data:image')) {
            imageUrl = `/serve${startsWith(imageUrl, '/') ? '' : '/'}${imageUrl}`;
        }

        // We don't want to resize media (because they're not images).
        if (size === -1) {
            return service.getMediaUrl(imageUrl);
        }

        // Only resize images that are not base64 and that are on our domain / cdn.
        if (
            includes(imageUrl, '/serve/') ||
            (!includes(imageUrl, 'data:image') &&
                (includes(imageUrl, $location.host()) ||
                    includes(imageUrl, 'googleusercontent.com') ||
                    includes(imageUrl, '0.0.0.0')))
        ) {
            imageUrl = endsWith(imageUrl, '/') ? imageUrl.slice(0, -1) : imageUrl;
            // 512px is the default size the google image API serves images at. Although that's not documented...
            size = angular.isUndefined(size) ? _DEFAULT_IMAGE_SIZE : size;
            const splitUrl = imageUrl.split('=s');
            const currentSize = parseInt(splitUrl[splitUrl.length - 1], 10);
            const isAlreadyResized = splitUrl.length > 1 && angular.isNumber(currentSize) && !isNaN(currentSize);

            // If the currentSize is 0, we don't change it to keep a potential gif animated.
            if (currentSize !== 0 && isAlreadyResized) {
                // Remove the existing size which is the last item in the split.
                splitUrl.splice(splitUrl.length - 1, 1);
                imageUrl = `${splitUrl.join('=s')}=s${size}`;
            } else if (currentSize !== 0) {
                imageUrl += `=s${size}`;
            }

            if (crop) {
                imageUrl += '-c';
            }
        }

        imageUrl = service.getMediaUrl(imageUrl);
        return (enforceAbsoluteUrl && startsWith(imageUrl, '/')) ? window.location.origin + imageUrl : imageUrl;
    }

    /**
     * Select the text content of an element from its HTML ID.
     *
     * @param {string} elementId The element ID in the HTML DOM.
     *
     * Todo [Arnaud]: move to specific veolia.
     */
    function selectText(elementId) {
        const text = $document[0].getElementById(elementId);
        let range;
        let selection;

        if ($document[0].body.createTextRange) {
            range = $document[0].body.createTextRange();
            range.moveToElementText(text);
            range.select();
        } else if ($window.getSelection) {
            selection = $window.getSelection();
            range = $document[0].createRange();
            range.selectNodeContents(text);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    /**
     * Move the cursor to the end of an contenteditable element.
     *
     * @param {Object} contentEditableElement The contenteditable element.
     */
    function setEndOfContenteditable(contentEditableElement) {
        let range;
        let selection;

        // Firefox, Chrome, Opera, Safari, IE 9+.
        if ($document[0].createRange) {
            // Create a range (a range is a like the selection but invisible).
            range = $document[0].createRange();
            // Select the entire contents of the element with the range.
            range.selectNodeContents(contentEditableElement);
            // Collapse the range to the end point. false means collapse to end rather than the start.
            range.collapse(false);
            // Get the selection object (allows you to change selection).
            selection = $window.getSelection();
            // Remove any selections already made.
            selection.removeAllRanges();
            // Make the range you have just created the visible selection.
            selection.addRange(range);

            // IE 8 and lower.
        } else if ($document[0].selection) {
            // Create a range (a range is a like the selection but invisible).
            range = $document[0].body.createTextRange();
            // Select the entire contents of the element with the range.
            range.moveToElementText(contentEditableElement);
            // Collapse the range to the end point. false means collapse to end rather than the start.
            range.collapse(false);
            // Select the range (make it the visible selection.
            range.select();
        }
    }

    /**
     * Check if the mouse click event should open a new window or tab.
     * A mouse click should open a new window or tab when the CTRL or SHIFT key of the keyboard is hold while
     * clicking or of the click has been made with the middle mouse button (or mousewheel).
     * It's also possible to pass "true" as the event to force an opening in a new window or tab.
     *
     * @param  {Event|boolean} evt The event to check, or a boolean to force/forbid the opening in a new window or
     *                             tab.
     * @return {boolean}       If the mouse click should lead to an opening in a new window or tab or not.
     */
    function shouldOpenInNewWindow(evt) {
        return (
            angular.isDefined(evt) &&
            (evt === true ||
                (angular.isObject(evt) && (evt.ctrlKey || evt.shiftKey || evt.which === _MIDDLE_CLICK_EVENT_WHICH)))
        );
    }

    /**
     * Remove all HTML tags from a string.
     *
     * @param  {string}  string               The string to clean.
     * @param  {boolean} [stripComments=true] Indicates if we also want to strip the comments.
     * @param  {boolean} [br2nl=true]         Indicates if we want to replace <br> tags by newlines.
     * @param  {boolean} [p2nl=true]          Indicates if we want to replace closing </p> tags by newlines.
     * @return {string}  The string without any HTML tag.
     */
    function stripTags(string, stripComments, br2nl, p2nl) {
        stripComments = angular.isUndefined(stripComments) ? true : stripComments;
        br2nl = angular.isUndefined(br2nl) ? true : br2nl;
        p2nl = angular.isUndefined(p2nl) ? true : p2nl;

        if (angular.isUndefinedOrEmpty(string)) {
            return '';
        }

        if (stripComments) {
            string = string.replace(/(<!--([^]*?)-->)/gi, '');
        }

        string = string.replace(/<p>/gi, '');
        string = string.replace(/<\/p>/gi, p2nl ? '\n' : ' ');
        string = string.replace(/<br ?\/?>/gi, br2nl ? '\n' : ' ');

        return string.replace(/(<([^>]+)>)/gi, '');
    }

    /**
     * Format string in camelCase.
     *
     * @param  {string} string The string to format.
     * @return {string} The string formatted in camelCase.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function toCamelCase(string) {
        if (!angular.isString(string)) {
            return '';
        }

        return loCamelCase(string.toLowerCase(), true);
    }

    /**
     * Format string in upperSnakecase.
     *
     * @param  {string} string The string to format.
     * @return {string} The string formatted in UPPER_SNAKE_CASE.
     *
     * Todo [Arnaud]: move to string utils.
     */
    function toUpperSnakeCase(string) {
        if (!angular.isString(string)) {
            return '';
        }

        return snakeCase(string).toUpperCase();
    }

    /**
     * Update a paramater value in an URL.
     * It can also remove a parameter from an URL.
     *
     * @param  {string} urlToUpdate The url in which to update the parameter value.
     * @param  {string} key         The key of the parameter to update in the URL.
     * @param  {string} [value]     The new value to set to the parameter in the URL.
     *                              If none given, remove the parameter from the URL
     * @return {string} The updated URL.
     *
     * @see    {@link http://stackoverflow.com/questions/5999118/add-or-update-query-string-parameter}
     *
     * Todo [Arnaud]: move to uri utils.
     */
    function updateUrlParameter(urlToUpdate, key, value) {
        urlToUpdate = urlToUpdate || $window.location.href;

        let hash;
        const regexp = new RegExp(`([?&])${key}=.*?(&|#|$)(.*)`, 'gi');

        if (regexp.test(urlToUpdate)) {
            if (angular.isDefined(value)) {
                return urlToUpdate.replace(regexp, `$1${key}=${value}$2$3`);
            }

            hash = urlToUpdate.split('#');
            if (angular.isDefinedAndFilled(hash) && angular.isArray(hash)) {
                urlToUpdate = hash[0].replace(regexp, '$1$3').replace(/(&|\?)$/, '');
                if (angular.isDefined(hash[1])) {
                    urlToUpdate += `#${hash[1]}`;
                }
            }

            return urlToUpdate;
        }

        if (angular.isDefined(value)) {
            const separator = urlToUpdate.indexOf('?') > -1 ? '&' : '?';

            hash = urlToUpdate.split('#');
            if (angular.isDefinedAndFilled(hash) && angular.isArray(hash)) {
                urlToUpdate = `${hash[0] + separator + key}=${value}`;
                if (angular.isDefined(hash[1])) {
                    urlToUpdate += `#${hash[1]}`;
                }
            }

            return urlToUpdate;
        }

        return urlToUpdate;
    }

    /**
     * Returns the url with the filters formatted as a query string appended.
     *
     * @param  {string} url     The URL on which to append the filters.
     * @param  {Array}  filters Optional filters to format as query string and append to the URL.
     * @return {string} The URL with the filters appended.
     *
     * Todo [Arnaud]: move to uri utils.
     */
    function uri(url, filters) {
        if (angular.isUndefinedOrEmpty(filters)) {
            return url;
        }

        return (
            url +
            (includes(url, '?') ? '&' : '?') +
            $httpParamSerializer({
                filters,
            })
        );
    }

    /**
     * Wait for an element to be in the DOM before executing a callback.
     *
     * @param  {string}           selector                  The selector of the element to wait for.
     * @param  {Function|Service} [onReady=LxDialogService] A function to execute once the element is found in the
     *                                                      DOM.
     *                                                      If a service is given, then this service will be used to
     *                                                      call the `open()` function.
     *                                                      Defaults to opening a standard dialog with LumX.
     * @param  {boolean}          [waitForScope=false]      Indicates if we want to wait for the scope to be
     *                                                      available on the element (instead of simply the element).
     * @param  {number}           [interval=50]             The interval (in milliseconds) between each check of the
     *                                                      availability of the element.
     * @param  {number|string}    [max=600]                 The maximum number of time to wait for the element.
     *                                                      If it's a string that end with '[m]s', then it's
     *                                                      considered as the maximum time (in [milli]seconds) to
     *                                                      wait for the element.
     * @param  {Object}           [onReadyParams]           Optional parameters to pass to the onReady function.
     * @return {Promise}          The promise that resolves when the element is found and thats rejects when
     *                            element hasn't been found after `max` interval.
     */
    function waitForAndExecute(selector, onReady, waitForScope, interval, max, onReadyParams) {
        if (angular.isUndefinedOrEmpty(selector)) {
            return $q.reject();
        }

        waitForScope = angular.isUndefined(waitForScope) ? false : waitForScope;
        interval = interval || _DEFAULT_WAIT_INTERVAL;

        max = angular.isUndefinedOrEmpty(max) ? _DEFAULT_WAIT_INTERVAL_COUNT : max;

        if (angular.isString(max) && max.endsWith('s')) {
            // eslint-disable-next-line no-magic-numbers
            max = max.endsWith('ms') ? parseInt(max.replace('ms', ''), 10) : parseInt(max.replace('s', ''), 10) * 1000;

            max /= interval;
        }
        const deferred = $q.defer();
        let cancelInterval = $interval(
            () => {
                const el = angular.element(selector);

                // eslint-disable-next-line lumapps/angular-isdefined
                if (
                    angular.isUndefined(el) ||
                    el.length === 0 ||
                    (waitForScope && angular.isUndefined(angular.element(el[0]).scope()))
                ) {
                    return;
                }

                if (angular.isDefinedAndFilled(cancelInterval)) {
                    $interval.cancel(cancelInterval);
                    cancelInterval = undefined;
                }

                if (angular.isFunction(onReady)) {
                    onReady(el);
                    // By default just open a regular dialog.
                } else if (onReady !== false) {
                    const Service = angular.isUndefinedOrEmpty(onReady) || onReady === true ? LxDialogService : onReady;

                    if (angular.isFunction(Service.open)) {
                        Service.open(selector.replace('#', ''), onReadyParams);
                    }
                }

                deferred.resolve();
            },
            interval,
            max,
        );
        cancelInterval.then(() => deferred.reject());

        return deferred.promise;
    }

    /////////////////////////////

    service.addScopeInfo = addScopeInfo;
    service.arrayRefresh = arrayRefresh;
    service.buildFilterFromUri = buildFilterFromUri;
    service.buildInstanceUrl = buildInstanceUrl;
    service.copyObjectToExistingOne = copyObjectToExistingOne;
    service.copyProperties = copyProperties;
    service.copyPropertySet = copyPropertySet;
    service.copyText = copyText;
    service.displayServerError = displayServerError;
    service.empty = empty;
    service.equals = equals;
    service.escapeHtmlTags = escapeHtmlTags;
    service.escapeRegexp = escapeRegexp;
    service.findUrls = findUrls;
    service.findUrlsInString = findUrlsInString;
    service.formatBytes = formatBytes;
    service.generatePassword = generatePassword;
    service.getAdminProperty = getAdminProperty;
    service.getApiProfileFieldFromMap = getApiProfileFieldFromMap;
    service.getAttr = getAttr;
    service.getImageURL = getImageURL;
    service.deprecatedGetCroppedThumbnailURL = deprecatedGetCroppedThumbnailURL;
    service.getBackgroundImage = getBackgroundImage;
    service.getConfigProperty = getConfigProperty;
    service.getContentIcon = getContentIcon;
    service.getContentListSlug = getContentListSlug;
    service.getCurrentAttachmentType = getCurrentAttachmentType;
    service.getCustomClass = getCustomClass;
    service.getHostnameFromString = getHostnameFromString;
    service.getItemColor = getItemColor;
    service.getMediaUrl = getMediaUrl;
    service.getMimeTypeMediaType = getMimeTypeMediaType;
    service.getParentFullSlug = getParentFullSlug;
    service.getProperty = getProperty;
    service.getReadableHtmlContent = getReadableHtmlContent;
    service.getServerError = getServerError;
    service.getSimpleUrlParams = getSimpleUrlParams;
    service.getUrlUserInjectionInformation = getUrlUserInjectionInformation;
    service.getMarginTop = getMarginTop;
    service.getHeaderHeight = getHeaderHeight;
    service.handleClickOnLink = handleClickOnLink;
    service.hasChild = hasChild;
    service.hasMultiple = hasMultiple;
    service.htmlEscape = htmlEscape;
    service.htmlUnescape = htmlUnescape;
    service.include = includes;
    service.initComponents = initComponents;
    service.initHtmlContent = initHtmlContent;
    service.initGoogleCaptcha = initGoogleCaptcha;
    service.injectScript = injectScript;
    service.injectStylesheet = injectStylesheet;
    service.isArray = angular.isArray;
    service.isDebugModeEnabled = isDebugModeEnabled;
    service.isDefined = angular.isDefined;
    service.isDefinedAndFilled = angular.isDefinedAndFilled;
    service.isDesignerMode = isDesignerMode;
    service.isEmailValid = isEmailValid;
    service.isGAValid = isGAValid;
    service.isFunction = angular.isFunction;
    service.isHexadecimal = isHexadecimal;
    service.isLangArrayEmpty = isLangArrayEmpty;
    service.isLocalEnvironment = isLocalEnvironment;
    service.isNumber = angular.isNumber;
    service.isObject = angular.isObject;
    service.isSafeModeEnabled = isSafeModeEnabled;
    service.isHeadlessModeOn = isHeadlessModeOn;
    service.isSlugValid = isSlugValid;
    service.isString = angular.isString;
    service.isUndefined = angular.isUndefined;
    service.isUndefinedOrEmpty = angular.isUndefinedOrEmpty;
    service.legacyEquals = angular.equals;
    service.loadGoogleCaptcha = loadGoogleCaptcha;
    service.mapEnabledEntries = mapEnabledEntries;
    service.matchAttr = matchAttr;
    service.multiCallback = multiCallback;
    service.noop = angular.noop;
    service.objectSize = Object.size;
    service.openContent = openContent;
    service.openPopin = openPopin;
    service.parseUrlPattern = parseUrlPattern;
    service.rebindLinks = rebindLinks;
    service.redirectTo = redirectTo;
    service.redirectToContentList = redirectToContentList;
    service.reject = reject;
    service.removeAccentFromString = removeAccentFromString;
    service.removePrintClass = removePrintClass;
    service.replaceNewLines = replaceNewLines;
    service.replaceTranslatableValues = replaceTranslatableValues;
    service.replaceVariables = replaceVariables;
    service.replaceTokens = replaceTokens;
    service.resizeImage = resizeImage;
    service.selectText = selectText;
    service.setEndOfContenteditable = setEndOfContenteditable;
    service.shouldOpenInNewWindow = shouldOpenInNewWindow;
    service.slugify = slugify;
    service.stripTags = stripTags;
    service.swapObject = Object.swap;
    service.toCamelCase = toCamelCase;
    service.toJson = angular.toJson;
    service.toUpperSnakeCase = toUpperSnakeCase;
    service.updateUrlParameter = updateUrlParameter;
    service.uri = uri;
    service.values = loValues;
    service.waitForAndExecute = waitForAndExecute;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Utils', UtilsService);

/////////////////////////////

export { UtilsService, reject };
