import { Video } from '@lumapps/play/api/types';

import { SUBTITLE_GENERATION_MODE, SUBTITLE_STATUS } from '../../constants';
import { VideoSubtitle } from '../../types';

/**
 * Return the video subtitles with the right format to handle the new data model
 * This whole mess should be removed once the new data model is pushed in production
 */
/**  */
export const oldToNewTranscript = (video: Video, lang: string) => {
    if (!video.subtitles) {
        return [];
    }

    /** Transcript is already in subtitle */
    const transcript = video.subtitles.find(
        (subtitle) => subtitle.language === 'cc' || subtitle.generationMode === 'speech_to_text',
    );

    if (video.transcriptDetails && transcript) {
        const subtitleWithoutTranscript = video.subtitles.filter((subtitle) => subtitle.language !== 'cc');

        const newTranscript: VideoSubtitle = {
            status:
                video.transcriptDetails.status === 'success' ? SUBTITLE_STATUS.LIVE : video.transcriptDetails.status,
            id: video.transcriptDetails.id,
            transcriptSubtitleId: transcript.id,
            language: video.audioLanguage || '',
            filename: transcript.filename,
            file: transcript.file,
            createdAt: new Date().toUTCString(),
            createdById: '',
            updatedAt: new Date().toUTCString(),
            updatedById: '',
            generationMode: SUBTITLE_GENERATION_MODE.SPEECH_TO_TEXT,
        };

        return [newTranscript, ...subtitleWithoutTranscript];
    }

    /** Add transcript from transcriptDetails to subtitles */
    if (
        video.transcriptDetails &&
        (video.transcriptDetails.status === 'waiting' ||
            video.transcriptDetails.status === 'processing' ||
            video.transcriptDetails.status === 'error')
    ) {
        const newSubtitle: VideoSubtitle = {
            status: video.transcriptDetails.status,
            id: video.transcriptDetails.id,
            language: lang,
            createdAt: new Date().toUTCString(),
            createdById: '',
            updatedAt: new Date().toUTCString(),
            updatedById: '',
            generationMode: SUBTITLE_GENERATION_MODE.SPEECH_TO_TEXT,
        };
        return [newSubtitle, ...video.subtitles];
    }
    return video.subtitles;
};
