import React from 'react';

import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { useLazyLoadingProgress } from '@lumapps/utils/hooks/useLazyLoadingProgress';

export function useLoadTeamsShareDialog() {
    const [isShareToTeamsDialog, openShareToTeamsDialog, closeShareToTeamsDialog] = useBooleanState(false);
    const [teamsIsLoaded, setTeamsIsLoaded] = React.useState(false);
    const { onComponentLoaded: onTeamsLoaded } = useLazyLoadingProgress(teamsIsLoaded);
    const handleOpenTeams = React.useCallback(() => {
        setTeamsIsLoaded(true);
        openShareToTeamsDialog();
    }, [openShareToTeamsDialog]);
    return {
        teamsIsLoaded,
        onTeamsLoaded,
        handleOpenTeams,
        isShareToTeamsDialog,
        openShareToTeamsDialog,
        closeShareToTeamsDialog,
    };
}
