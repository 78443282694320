import React from 'react';

import over from 'lodash/fp/over';

import { inputLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { isTranslatableObject, isTranslateObject, TranslatableObject, TranslateObject } from '@lumapps/translations';

import { actions } from '../ducks/slice';
import type { LegacyWidget } from '../types';
import type { EditableWidgetProps } from '../types/designer';

/**
 * Register the widget into redux.
 * Needed in order to fetch the widget with the Block API
 * */
export const useRegisterEditableWidget = <LegacyWidgetProps>(initLegacyWidget: LegacyWidget<LegacyWidgetProps>) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        const { widgetType, uuid: widgetId } = initLegacyWidget;
        if (widgetId) {
            dispatch(
                actions.setWidget({
                    widgetId,
                    widgetType,
                }),
            );

            dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: undefined } }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export const useEditableWidget = <LegacyWidgetProps>(
    initLegacyWidget: LegacyWidget<LegacyWidgetProps>,
    switchToReadMode: EditableWidgetProps<LegacyWidgetProps>['switchToReadMode'],
    saveTextInWidgetProperties: EditableWidgetProps<LegacyWidgetProps>['saveTextInWidgetProperties'],
    getTranslateObject: (properties?: LegacyWidgetProps) => TranslatableObject | TranslateObject | undefined,
    updateOnChange: boolean = false,
) => {
    const currentInputLang = useSelector(inputLanguageSelector);
    const wrapperRef = React.useRef(null);
    const childrenRefs = React.useRef([wrapperRef]);

    /**
     * Since <react-element> don't watch the object entirely, but watch it's 'properties' properties
     * (to avoid re-rendering and make first double click to edit work), we need to destructure the object
     * (since only the 'properties' properties is watched).
     */
    const legacyWidget = { ...initLegacyWidget } as LegacyWidget<LegacyWidgetProps>;

    const getText = React.useCallback(
        (lang: string, properties?: LegacyWidgetProps) => {
            const trad = getTranslateObject(properties);
            if (isTranslatableObject(trad)) {
                return trad.translations[lang] ?? '';
            }
            if (isTranslateObject(trad)) {
                return trad[lang] ?? '';
            }
            return '';
        },
        [getTranslateObject],
    );

    const [content, onChange] = React.useState<string>(getText(currentInputLang, legacyWidget?.properties));

    const onUpdate = React.useCallback(
        (updatedContent: React.SetStateAction<string>) => {
            const trad = getTranslateObject(legacyWidget.properties) ?? {};

            if (isTranslatableObject(trad)) {
                saveTextInWidgetProperties({
                    ...trad,
                    translations: {
                        ...trad.translations,
                        [currentInputLang]: updatedContent,
                    },
                });
            } else {
                saveTextInWidgetProperties({
                    ...trad,
                    [currentInputLang]: updatedContent,
                });
            }
        },
        [currentInputLang, getTranslateObject, legacyWidget.properties, saveTextInWidgetProperties],
    );

    /** on click outside the editor, we call onUpdate callback and switch to read mode */
    const exitEditionMode = React.useCallback(() => {
        onUpdate(content);

        switchToReadMode();
    }, [content, switchToReadMode, onUpdate]);

    const theme = legacyWidget.properties?.style?.content?.theme;

    /**
     * Update content translated value when the  current input lang change
     * */
    React.useEffect(() => {
        onChange(getText(currentInputLang, legacyWidget?.properties));
    }, [currentInputLang, getText, legacyWidget?.properties]);

    useRegisterEditableWidget(initLegacyWidget);

    return {
        legacyWidget,
        content,
        editableWidgetProps: {
            wrapperRef,
            childrenRefs,
            exitEditionMode,
            content,
            onChange: updateOnChange ? over([onUpdate, onChange]) : onChange,
            theme,
        },
    };
};
