import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ReactEditor, useSlateStatic, useSelected } from '@lumapps/wrex/slate';
import { useInlineVoid } from '@lumapps/wrex/slate/utils/useInlineVoid';
import { ElementRender } from '@lumapps/wrex/types';

import { EmbeddedMapEditor, EmbeddedMapElement } from '../../../types';
import { CLASSNAME, EmbeddedMap } from '../../blocks/EmbeddedMap';
import { DeleteButton } from '../../buttons/DeleteButton';

import './index.scss';

export const EditableEmbeddedMap: ElementRender<EmbeddedMapElement, HTMLDivElement> = ({
    element = {} as EmbeddedMapElement,
    children,
    ...forwardedProps
}) => {
    const editorStatic = useSlateStatic() as ReactEditor & EmbeddedMapEditor;
    const selected = useSelected();
    const { block } = useClassnames(CLASSNAME);
    const { onFocus, onDelete } = useInlineVoid(editorStatic, element);

    return (
        <EmbeddedMap
            className={block({
                editable: true,
                selected,
            })}
            element={element}
            onFocus={onFocus}
            tabIndex={0}
            {...forwardedProps}
        >
            <DeleteButton onDelete={onDelete} />
            {children}
        </EmbeddedMap>
    );
};

EditableEmbeddedMap.displayName = 'embedded-map';
