import React from 'react';

import { useRovingTabIndex } from '@lumapps/moving-focus';

/**
 * Implement ARIA role "option" accessibility state (tabIndex) and behavior (keydown, click).
 *
 * @param ref Reference of the "option" element.
 * @param selectItem Callback on "option" element selected.
 * @param isDisabled Whether the "option" element is disabled
 * @param isInMultipleSelect Whether the "option" element is in a multiple or in a single select
 */
export const useOption = (
    ref: React.RefObject<HTMLElement>,
    selectItem: () => void,
    isDisabled: boolean,
    isInMultipleSelect: boolean,
) => {
    const [tabIndex, focused, rovingTabindexKeyDown, rovingTabindexClick] = useRovingTabIndex(ref, isDisabled);
    // Track previous focus state;
    const prevFocused = React.useRef(focused);

    // Activate focus and select the item when it becomes focused via the roving tabindex.
    React.useEffect(() => {
        const { current } = ref;
        const { current: currentPrevFocused } = prevFocused;

        /**
         * As selectItem is defined as depency, we need to check that the focus state
         * has actually changed from unfocused to focused.
         */
        prevFocused.current = focused;
        if (!currentPrevFocused && focused && current) {
            current.focus();
            if (!isInMultipleSelect) {
                selectItem();
            }
        }
    }, [focused, isInMultipleSelect, ref, selectItem]);

    // Implements select on Space and Enter keys. Uses `react-roving-tabindex` for keyboard navigation.
    const onKeyDown = React.useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === ' ' || event.key === 'Enter') {
                // ARIA radio: select on space or enter key pressed.
                selectItem();
                event.preventDefault();
            } else {
                // ARIA roving tab index: keyboard navigation
                rovingTabindexKeyDown(event);
            }
        },
        [rovingTabindexKeyDown, selectItem],
    );

    const onClick = React.useCallback(() => {
        // ARIA roving tab index: focus on click.
        rovingTabindexClick();
        // In single selection mode click is called on the useEffect on focus, we should avoid to twice selectItem
        const { current } = ref;
        const { current: currentPrevFocused } = prevFocused;

        if (isInMultipleSelect || (currentPrevFocused && current && focused)) {
            selectItem();
        }
    }, [focused, isInMultipleSelect, ref, rovingTabindexClick, selectItem]);

    return { tabIndex, onClick, onKeyDown };
};
