import React, { useMemo, useRef } from 'react';

import { useClassnames, margin } from '@lumapps/classnames';
import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { useDataAttributes } from '@lumapps/data-attributes';
import { DynamicIcon } from '@lumapps/lumx-images/components/DynamicIcon';
import {
    Avatar,
    GenericBlock,
    Heading,
    Text,
    FlexBox,
    Size,
    Typography,
    ColorPalette,
    Theme,
    Placement,
    Link,
    InlineList,
    Tooltip,
    ColorVariant,
} from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';
import { UserCardPopover, useUserCardPopover } from '@lumapps/user/components/UserCardPopover';
import { getAvatarUrl } from '@lumapps/user/utils/getUserProfilePicture';
import { BlockComponent } from '@lumapps/widget-base/types';

import { BlockUserProfilePreview as BlockUserProfilePreviewProps } from '../types';

import './index.scss';

const CLASSNAME = 'block-user-profile-preview';

export const BlockUserProfilePreview: BlockComponent<BlockUserProfilePreviewProps> = ({
    fields,
    userId,
    pictureUrl,
    fullName,
    jobTitle,
    theme = Theme.light,
    genericBlockProps,
}) => {
    const refForCard = useRef(null);
    const { linkProps } = useSocialProfileRoute({ routeParams: { userId } });
    const customerId = useSelector(customerIdSelector);
    const { block, element } = useClassnames(CLASSNAME);

    const { get } = useDataAttributes(CLASSNAME);

    const avatarProps = useMemo(
        () => ({
            image: pictureUrl || getAvatarUrl(userId, customerId),
            alt: fullName || '',
        }),
        [customerId, fullName, pictureUrl, userId],
    );

    const { onMouseEnter, onMouseLeave, userProfile, isLoadingUser, showUserCard } = useUserCardPopover({
        id: userId,
        fullName,
        jobTitle,
    });

    const color = theme === Theme.light ? ColorPalette.dark : ColorPalette.light;

    return (
        <>
            <GenericBlock
                className={block()}
                ref={refForCard}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                hAlign="center"
                {...genericBlockProps}
                {...get({ element: 'block', action: userId })}
            >
                <GenericBlock.Figure>
                    <Avatar
                        className={margin('top', 'tiny')}
                        linkAs={Link}
                        linkProps={{ ...linkProps, tabIndex: fullName ? -1 : undefined }}
                        {...avatarProps}
                    />
                </GenericBlock.Figure>
                <GenericBlock.Content className={element('content')}>
                    {fullName && (
                        <Link {...linkProps} color={color} colorVariant="N" typography={Typography.custom.title5}>
                            <Heading as="h2" typography={Typography.custom.title5} color={color} colorVariant="N">
                                {fullName}
                            </Heading>
                        </Link>
                    )}
                    {jobTitle && (
                        <Text as="span" color={color} colorVariant={ColorVariant.L2} typography={Typography.body1}>
                            {jobTitle}
                        </Text>
                    )}
                    {Boolean(fields?.length) && (
                        <FlexBox orientation="vertical" gap={Size.tiny} className={element('fields')}>
                            {fields.map((field) => (
                                <FlexBox
                                    key={`${CLASSNAME}-${userId}-${field.definition_id}`}
                                    gap={Size.regular}
                                    orientation="horizontal"
                                    hAlign="center"
                                    className={element('field')}
                                >
                                    {field.iconClass && (
                                        <DynamicIcon
                                            icon={field.iconClass}
                                            color={color}
                                            colorVariant={ColorVariant.L2}
                                            className={element('icon')}
                                        />
                                    )}

                                    {field.values.length > 1 ? (
                                        <Tooltip label={field.values.join()}>
                                            <Text
                                                key={`${CLASSNAME}-${userId}-${field.definition_id}-${field.definition_id}`}
                                                as="p"
                                                typography={Typography.body1}
                                                color={color}
                                                colorVariant={ColorVariant.L2}
                                                truncate
                                            >
                                                {field.values.join()}
                                            </Text>
                                        </Tooltip>
                                    ) : (
                                        <InlineList color={color} className={element('list')}>
                                            {field.values.map((value) => (
                                                <Text
                                                    key={`${CLASSNAME}-${userId}-${field.definition_id}-${field.definition_id}`}
                                                    as="p"
                                                    typography={Typography.body1}
                                                    color={color}
                                                    colorVariant={ColorVariant.L2}
                                                >
                                                    {value}
                                                </Text>
                                            ))}
                                        </InlineList>
                                    )}
                                </FlexBox>
                            ))}
                        </FlexBox>
                    )}
                </GenericBlock.Content>
            </GenericBlock>

            <UserCardPopover
                user={userProfile}
                isDetailsOpen={showUserCard}
                anchorRef={refForCard}
                onClose={onMouseLeave}
                isLoading={isLoadingUser}
                onMouseEnter={onMouseEnter}
                placement={Placement.BOTTOM_START}
            />
        </>
    );
};
