import React from 'react';

import includes from 'lodash/fp/includes';

import { RadioFilter } from '@lumapps/lumx-filter-and-sort/components/RadioFilter';

import { HasRelevantComment, PostListFilterBlockProps, PostListFilterChoice } from '../../../types';

export const PostRelevantCommentFilterBlock = ({
    onSelect,
    selectedValues,
    filterId,
    choices = [],
    getChoiceName,
    label,
}: PostListFilterBlockProps<HasRelevantComment>) => {
    const handleChange = (choice: PostListFilterChoice<HasRelevantComment>) => {
        onSelect(filterId, [choice.value]);
    };

    const isInSelection = (choice: PostListFilterChoice<HasRelevantComment>) => {
        return includes(choice.value, selectedValues);
    };

    return (
        <RadioFilter
            options={choices}
            title={label}
            isInSelection={isInSelection}
            onSelected={handleChange}
            renderRadio={(choice) => getChoiceName(choice.value)}
            getId={(choice) => choice.value}
            id={filterId}
        />
    );
};
