import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiAccountMultiple } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { SEGMENT_ADMIN } from '../../keys';
import { segmentBaseRoute } from '../../routes';

export interface SegmentSideNavigationProps {
    isAllowed: boolean;
}

const SegmentSideNavigation = ({ isAllowed }: SegmentSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            icon={mdiAccountMultiple}
            label={translateKey(SEGMENT_ADMIN.SEGMENT_SIDE_NAV_TITLE)}
            route={segmentBaseRoute}
        />
    );
};

SegmentSideNavigation.displayName = 'SideNavigationItem';
export { SegmentSideNavigation };
