import { Route, AppId, createPageRoute } from '@lumapps/router';

export const coveoSearchSettingsRoute: Route = createPageRoute({
    slug: 'search-settings/coveo',
    id: 'coveo-search-settings',
    appId: AppId.backOffice,
});

export const searchSettingsRoute: Route = createPageRoute({
    slug: 'search-settings/results-page',
    id: 'search-settings-results-page',
    appId: AppId.backOffice,
});

export const searchSettingsConfigRoute: Route = createPageRoute({
    slug: 'search-settings/results-page/:id',
    id: 'search-settings-results-page-details',
    appId: AppId.backOffice,
});

export const coveoSearchSettings = (): Route => coveoSearchSettingsRoute;
export const searchSettings = (): Route => searchSettingsRoute;
export const searchSettingsConfig = (id: string): Route => {
    return {
        ...searchSettingsConfigRoute,
        params: {
            id,
        },
    };
};
