import React, { lazy } from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { UploadEmployeeVideoDialogProps } from './UploadEmployeeVideoDialog';

const LazyUploadEmployeeVideoDialog = lazy(
    () =>
        import(
            /* webpackChunkName: 'upload-employee-video-dialog' */
            './UploadEmployeeVideoDialog'
        ),
);

export const UploadEmployeeVideoDialog = ({ isOpen, ...props }: UploadEmployeeVideoDialogProps) => (
    <DelayedSuspense shouldLoad={isOpen}>
        {(setShouldMount) => (
            <LazyUploadEmployeeVideoDialog isOpen={isOpen} onVisibilityChange={setShouldMount} {...props} />
        )}
    </DelayedSuspense>
);

export type { UploadEmployeeVideoDialogProps };
