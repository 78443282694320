import { NGI_WIDGETS_IN_DESIGNER_FF_TOKEN } from '@lumapps/widget-base/constants';
import loFind from 'lodash/find';

/////////////////////////////

function WidgetMetaSocialController(
    $stateParams,
    Config,
    Community,
    Content,
    Features,
    InitialSettings,
    Instance,
    Utils,
    Widget,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Widget fields.
     * Contains every fields name and enable flag.
     *
     * @type {Object}
     */
    let _fields = {};

    /**
     * If current customer has social feature.
     *
     * @type {boolean}
     */
    const _hasSocialFeature = Features.hasFeature('social');

    /**
     * If "follow" is in widget fied.
     *
     * @type {boolean}
     */
    let _hasFollowField = false;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    // TODO [max]: like all widget with community context, it could be really good to make a context manager/service.
    /**
     * If the content is a community.
     *
     * @type {boolean}
     */
    vm.isCommunityContext = Content.getCurrent().type === InitialSettings.CONTENT_TYPES.COMMUNITY;

    /**
     * Community context Only.
     * Indicates if we should be showing the follow button or not.
     * The follow button is hidden when the community is in "Description only" mode and that the user is not a
     * contributor of the community.
     *
     * @type {boolean}
     */
    vm.showFollowButton = false;

    /**
     * Community context Only.
     * Indicates if we should be showing the request button or not.
     * The request button is shown when the user is not a contributor of a "Read-only" community.
     *
     * @type {boolean}
     */
    vm.showRequestButton = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Content = Content;

    /**
     * Whether the widget should be displayed in NGI or not.
     */
    vm.isNGICompatible = Utils.isDesignerMode() && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2');


    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Handle community specific intelligence.
     */
    function _handleCommunitySpecificity() {
        let userCanWrite = true;

        if (!$stateParams.isTemplate && $stateParams.type !== 'template') {
            userCanWrite = Community.isWriteable(Content.getCurrent());
        }

        vm.showRequestButton =
            Features.hasFeature('advanced_community') && Community.isReadOnly(Content.getCurrent()) && !userCanWrite;

        vm.showFollowButton = Features.hasFeature('advanced_community') && (userCanWrite || !Community.isDescriptionOnly(Content.getCurrent()));
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get widget class.
     *
     * @return {Array} An array of class names.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        return widgetClass;
    }

    /**
     * Is widget empty in designer mode.
     *
     * @return {boolean} If widget is empty in designer mode.
     */
    function isWidgetEmpty() {
        return false;
    }

    /**
     * Is widget hidden in reading mode.
     *
     * @return {boolean} If widget is hidden.
     */
    function isWidgetHidden() {
        vm.parentCtrl.isHidden = false;

        return false;
    }

    function _shouldDisplayAsNGI() {
        return vm.isNGICompatible;
    }

    function setAsNonNgiCompatible() {
        vm.isNGICompatible = false;
    }

    function setAsNgiCompatible() {
        const isInDesignerContext = vm.parentCtrl.designerMode();
        // Prevent the switch to true if the required FF are not enabled.
        if(isInDesignerContext && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2')) {
            vm.isNGICompatible = true;
        }
    }

    /////////////////////////////

    vm.getWidgetClass = getWidgetClass;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;
    vm.shouldDisplayAsNGI = _shouldDisplayAsNGI;
    vm.setAsNonNgiCompatible = setAsNonNgiCompatible;
    vm.setAsNgiCompatible = setAsNgiCompatible;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const defaultMetaSocialFields = Instance.getProperty(Config.INSTANCE_PROPERTIES.META_SOCIAL_FIELDS);
        _fields = vm.widget.properties.fields;

        if (angular.isUndefinedOrEmpty(_fields)) {
            _fields = [];

            if (angular.isDefinedAndFilled(defaultMetaSocialFields)) {
                if (angular.isString(defaultMetaSocialFields[0])) {
                    _fields = [];

                    angular.forEach(defaultMetaSocialFields, function forEachMetaSocialFields(metaSocialField) {
                        if (metaSocialField !== 'follow' || _hasSocialFeature) {
                            _fields.push({
                                enable: true,
                                name: metaSocialField,
                            });
                        }

                        if (
                            (metaSocialField === 'like' || metaSocialField === 'comment') &&
                            Content.getCurrent().type === InitialSettings.CONTENT_TYPES.COMMUNITY
                        ) {
                            _fields[_fields.length - 1].enable = false;
                            _fields[_fields.length - 1].ngDisabled = true;
                        } else if (
                            metaSocialField === 'comment' &&
                            angular.isUndefinedOrEmpty(
                                Widget.findWidgetsByType(
                                    Content.getCurrent().template,
                                    InitialSettings.WIDGET_TYPES.COMMENTS,
                                ),
                            )
                        ) {
                            _fields[_fields.length - 1].enable = false;
                        }
                    });
                } else {
                    _fields = angular.copy(defaultMetaSocialFields);
                }
            }

            vm.widget.properties.fields = _fields;
        }

        _hasFollowField = loFind(_fields, {
            name: 'follow',
        });

        if (!_hasFollowField && _hasSocialFeature) {
            _fields.push({
                enable: false,
                name: 'follow',
            });
        } else if (_hasFollowField && !_hasSocialFeature) {
            for (let i = 0, len = _fields.length; i < len; i++) {
                if (_fields[i].name === 'follow') {
                    _fields.splice(i, 1);

                    break;
                }
            }
        }

        if (vm.isCommunityContext) {
            _handleCommunitySpecificity();
        }
    }

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl Parent Controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;

        const isInDesignerContext = vm.parentCtrl.designerMode();

        // If we are not in the designer context or the content is not v2 compatible
        // we fallback to legacy display (we consider unsaved content as v2 compatible).
        // Here we are trying to guess which display will be the correct one in view mode.
        // This is not perfect, but we want to avoid too much logic in the frontend to know if a content is
        // compatible v2 or not (widget types presents on the content + some specific settings on some widgets)
        if ((!isInDesignerContext || (Content.getAction() !== 'create' && Content.getCurrent()?.template.isV2Compatible === false))) {
            setAsNonNgiCompatible();
        }

        init();
    };
}

function WidgetMetaSocialDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetMetaSocialController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetMetaSocial', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-meta-social/views/widget-meta-social.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetMetaSocial', WidgetMetaSocialDirective);

/////////////////////////////

export { WidgetMetaSocialController, WidgetMetaSocialDirective };
