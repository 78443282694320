import React from 'react';

import { margin, spacings, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Dialog, Button, Emphasis, Toolbar, DialogSizes, TextField, Message, Heading, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import type { LinkPreviewMetadata } from '../../types';
import { LinkPreview, SUPPORTED_PREVIEWS } from '../LinkPreview';

export interface InsertUrlPreviewDialogProps {
    /** custom classname */
    className?: string;
    /** Whether de dialog is open. */
    isOpen?: boolean;
    /** Dialog size. */
    size?: DialogSizes;
    /** Provide a message in to put inside the */
    children?: React.ReactNode;
    /** The preview object. */
    preview: Partial<LinkPreviewMetadata>;
    /** The error message text. */
    errorMessage?: string;
    /** The info message text. */
    infoMessage?: string;
    /** The dialog validation button text. */
    insertButtonText?: string;
    /** Whether the url field has been touched or not. */
    isUrlDirty: boolean;
    /** Whether the url field is valid or not. */
    isUrlValid: boolean;
    /** Whether the url preview is loading or not. */
    isPreviewLoading: boolean;
    /** Callback called when the thumbnail switch button is clicked. */
    onPreviewThumbnailSwitch?: () => void;
    /** The error status code of the preview fetch call. */
    previewError?: number;
    /** Function to fetch the preview, used for the retry button. */
    fetchPreview: () => void;
    /** The icon of the input. */
    inputIcon?: string;
    /** The label of the input. */
    inputLabel?: string;
    /** The helper of the input. */
    inputHelper?: string;
    /** The placeholder of the input. */
    inputPlaceholder?: string;
    /** The current url. */
    url: string;
    /** Function to set the current url. */
    setUrl: (url: string) => void;
    /** Title of the dialog. */
    title: string;
    /** Error message of the url field. */
    inputErrorMessage?: string;
    /** The prefix to use on every classname. */
    classNamePrefix: string;
    /** Cancel button callback. */
    onClose(): void;
    /** Confirm button callback. */
    onInsert(link: LinkPreviewMetadata): void;
    /** List of types of previews to render in the component */
    supportedPreviews?: SUPPORTED_PREVIEWS[];
}

/**
 * UI component that displays a text field in order to enter a URL, and if a preview is
 * passed in, the preview of that URL is displayed.
 *
 * Use `InsertLinkDialog` if you need a component that fetches the preview and manages everything.
 *
 * @family Dialog
 * @param InsertUrlPreviewDialogProps
 * @returns InsertUrlPreviewDialog
 */
export const InsertUrlPreviewDialog: React.FC<InsertUrlPreviewDialogProps> = (props) => {
    const {
        className,
        onClose,
        onInsert,
        isOpen,
        preview,
        infoMessage,
        errorMessage,
        insertButtonText,
        isUrlDirty,
        isUrlValid,
        isPreviewLoading,
        onPreviewThumbnailSwitch,
        previewError,
        fetchPreview,
        inputIcon,
        url,
        setUrl,
        title,
        inputErrorMessage,
        classNamePrefix,
        inputLabel,
        inputHelper,
        inputPlaceholder,
    } = props;
    const inputRef = React.useRef(null);
    const { get } = useDataAttributes(classNamePrefix);
    const { block, element } = useClassnames(classNamePrefix);

    const { translateKey } = useTranslate();

    const handleInsertion = React.useCallback(
        (e: React.SyntheticEvent) => {
            e.preventDefault();
            if (preview.url) {
                onInsert(preview as LinkPreviewMetadata);
                setUrl('');
            }
        },
        [onInsert, preview, setUrl],
    );

    const handleClose = React.useCallback(
        (e: React.SyntheticEvent) => {
            e.preventDefault();
            setUrl('');
            onClose();
        },
        [onClose, setUrl],
    );

    return (
        <Dialog
            {...get({ element: 'dialog' })}
            className={block([className])}
            focusElement={inputRef}
            isOpen={isOpen}
            forceFooterDivider
            forceHeaderDivider
        >
            <header>
                <Toolbar label={<Heading typography="title">{title}</Heading>} />
            </header>
            <form
                onSubmit={handleInsertion}
                className={spacings([
                    { type: 'padding', direction: 'horizontal', size: 'huge' },
                    { type: 'padding', direction: 'bottom', size: 'huge' },
                ])}
            >
                <TextField
                    className={margin('bottom')}
                    icon={inputIcon}
                    inputRef={inputRef}
                    isRequired
                    label={inputLabel}
                    value={url}
                    hasError={isUrlDirty && !isUrlValid}
                    onChange={setUrl}
                    helper={inputHelper}
                    placeholder={inputPlaceholder}
                    clearButtonProps={{
                        label: translateKey(GLOBAL.CLEAR),
                        ...get({ element: 'button', action: 'clear-url-input' }),
                    }}
                    error={inputErrorMessage}
                />
                {infoMessage && (
                    <Message className={margin('bottom', 'big')} hasBackground kind="info">
                        <Text as="p">{infoMessage}</Text>
                    </Message>
                )}
                {errorMessage && (
                    <Message className={margin('bottom', 'big')} hasBackground kind="error">
                        <Text as="p">{errorMessage}</Text>
                    </Message>
                )}
                <LinkPreview
                    classNamePrefix={classNamePrefix}
                    fetchPreview={fetchPreview}
                    isPreviewLoading={isPreviewLoading}
                    preview={preview}
                    onPreviewThumbnailSwitch={onPreviewThumbnailSwitch}
                    previewError={previewError}
                    onDelete={() => setUrl('')}
                />
            </form>

            <footer>
                <Toolbar
                    after={
                        <>
                            <Button
                                className={element('cancel-btn')}
                                emphasis={Emphasis.medium}
                                onClick={handleClose}
                                {...get({ element: 'button', action: 'close-dialog' })}
                            >
                                {translateKey(GLOBAL.CANCEL)}
                            </Button>

                            <Button
                                className={element('insert-btn', [margin('left', 'regular')])}
                                onClick={handleInsertion}
                                isDisabled={!preview.url}
                                {...get({ element: 'button', action: 'insert-link' })}
                            >
                                {insertButtonText || translateKey(GLOBAL.INSERT)}
                            </Button>
                        </>
                    }
                />
            </footer>
        </Dialog>
    );
};

InsertUrlPreviewDialog.displayName = 'InsertUrlPreviewDialog';
