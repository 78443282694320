/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable spaced-comment */
import endsWith from 'lodash/endsWith';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { search } from '@lumapps/search/routes';
import { admin as newsletterAdmin } from '@lumapps/newsletter/routes';
import { angularApi } from '@lumapps/router/routers';

import {
    duplicateContent,
    getTemplatePath,
    PERMISSIONS,
    provideTemplate,
    resolvePromise,
} from 'common/utils/states-utils';

import { loadFroala } from 'common/froala/modules/froala_utils';

/////////////////////////////
//                         //
//    Private functions    //
//                         //
/////////////////////////////

/**
 * Initialize the services that we need in the back office bundle.
 *
 * @param {Object} $injector Angular's `$injector` provider.
 */
const _initServices = ($injector) => {
    const servicesToInit = [];

    servicesToInit.forEach((service) => $injector.get(service).init());
};

/**
 * Loads back-office's modules and templates asynchronously.
 *
 * @param  {AngularService} $injector   Angular's `$injector` service.
 * @param  {Service}        $ocLazyLoad The $ocLazyLoad service.
 * @param  {Service}        Translation The Translation service.
 * @param  {Service}        Utils       The Utils service.
 * @return {Promise}        The modules resolution promise.
 */
const _resolveBackOfficeModules = async ($injector, $ocLazyLoad, Translation, Utils) => {
    let templatesLoaded = false;

    // eslint-disable-next-line no-inline-comments
    const asyncModules = import(/* webpackChunkName: "back-office", webpackPrefetch: true */ './app');
    // eslint-disable-next-line no-inline-comments
    const asyncTemplates = import(/* webpackChunkName: "back-office_templates", webpackPrefetch: true */ './templates');
    const [modules] = await Promise.all([asyncModules, asyncTemplates]);

    const runBlocks = angular.module('AppTemplates')._runBlocks;

    if (!templatesLoaded) {
        // Manually invokes templates angular module run blocks to initiate `$templateCache`.
        runBlocks.forEach((runBlock) => {
            $injector.invoke(runBlock);
        });

        templatesLoaded = true;
    }

    try {
        await Promise.all([
            $ocLazyLoad.inject(modules.BACK_OFFICE.name),
            $ocLazyLoad.inject('Services'),
            $ocLazyLoad.inject('Constants'),
            $ocLazyLoad.inject('Controllers'),
        ]);

        _initServices($injector);

        return loadFroala($injector, $ocLazyLoad, Translation, Utils);
    } catch (exception) {
        throw new Error(exception);
    }
};

/////////////////////////////
//                         //
//     Public functions    //
//                         //
/////////////////////////////

/**
 * Define the states for the Back-Office.
 *
 * @param {Provider} $stateProvider     The state provider.
 * @param {Provider} $translateProvider The translation provider.
 */
const setup = ($stateProvider) => {
    $stateProvider
        .state('app.admin', {
            resolve: {
                resolveModules($injector, $ocLazyLoad, Translation, Utils) {
                    'ngInject';

                    return _resolveBackOfficeModules($injector, $ocLazyLoad, Translation, Utils);
                },

                // eslint-disable-next-line sort-keys
                resolveLoadingAdmin($location, Settings, resolveModules, resolveInitialSetting) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveModules, resolveInitialSetting);

                    if (endsWith($location.path(), '/admin') || endsWith($location.path(), '/admin/')) {
                        Settings.endLoading();
                    }

                    return true;
                },
            },
            url: '/admin',
            views: {
                footer: {
                    controller: 'FooterController',
                    controllerAs: 'vm',
                    templateProvider($http, $templateCache, ConfigTheme, Instance) {
                        'ngInject';

                        const templatePath = getTemplatePath(
                            Instance,
                            'layout/modules/footer/views/footer.html',
                            ConfigTheme.HAS_CUSTOM_FOOTER,
                            'common',
                        );

                        return provideTemplate($http, $templateCache, templatePath);
                    },
                },
                header: {
                    controller: 'HeaderController',
                    controllerAs: 'vm',
                    templateProvider($http, $templateCache, ConfigTheme, Instance) {
                        'ngInject';

                        let templatePath;
                        if (
                            angular.isDefined(ConfigTheme.HAS_CUSTOM_HEADER) &&
                            angular.isDefined(ConfigTheme.HAS_CUSTOM_HEADER_ADMIN) &&
                            ConfigTheme.HAS_CUSTOM_HEADER &&
                            ConfigTheme.HAS_CUSTOM_HEADER_ADMIN
                        ) {
                            templatePath = `/client/front-office/specifics/themes/${
                                Instance.getInstance().theme
                            }/modules/layout/modules/header/common/views/header.html`;
                        } else {
                            templatePath =
                                '/client/back-office/modules/layout/modules/header/common/views/header-admin.html';
                        }

                        return provideTemplate($http, $templateCache, templatePath);
                    },
                },
                main: {
                    controller: 'AdminController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/layout/modules/admin/views/admin.html',
                },
            },
        })
        .state('app.admin.content', {
            resolve: {
                resolveCustomContentType($q, $stateParams, CustomContentType, resolveLoadingAdmin) {
                    'ngInject';

                    /*
                     * This is here to trick the linter into thinking that the blocking resolve injection is
                     * used.
                     */
                    angular.noop(resolveLoadingAdmin);

                    return $q(function deferCustomContentTypeGet(resolve, reject) {
                        CustomContentType.getItem($stateParams.uid, resolve, reject, undefined, true);
                    });
                },
                resolveLoading(Settings, resolveCustomContentType) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveCustomContentType);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/content/:uid?filters',
            views: {
                content: {
                    controller: 'ContentAdminController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/content/common/views/content-admin.html',
                },
            },
        })
        .state('app.admin.media', {
            resolve: {
                resolveLoading(Settings, resolveLoadingAdmin) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingAdmin);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/media-library',
            views: {
                content: {
                    controller: 'MediaAdminController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/media/views/media-admin.html',
                },
            },
        })
        .state('app.admin.media_trash', {
            resolve: {
                resolveLoading(Settings, resolveLoadingAdmin) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingAdmin);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/media-trash',
            views: {
                content: {
                    controller: 'MediaAdminController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/media/views/media-admin.html',
                },
            },
        })
        .state('app.admin.module', {
            resolve: {
                resolveLoading(Settings, resolveLoadingAdmin) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingAdmin);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/module',
            views: {
                content: {
                    controller: 'ModuleAdminController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/module/common/views/module-admin.html',
                },
            },
        })
        .state('app.admin.newsletter-create', {
            resolve: {
                resolveNewsletter(Customer, Instance, Newsletter, resolveLoadingAdmin) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingAdmin);

                    Newsletter.setCurrent({
                        customer: Customer.getCustomerId(),
                        instance: Instance.getCurrentInstanceId(),
                        sender: 'noreply@lumapps.com',
                    });

                    return true;
                },
                // eslint-disable-next-line sort-keys
                resolveLoading(Settings, resolveLoadingAdmin, resolveNewsletter) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingAdmin, resolveNewsletter);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/newsletters/create',
            views: {
                content: {
                    controller: 'NewsletterDetailsController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/newsletter/common/views/newsletter-details.html',
                },
            },
        })
        .state('app.admin.newsletter-edit', {
            resolve: {
                resolveNewsletter($stateParams, Newsletter, resolveLoadingAdmin) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingAdmin);

                    return resolvePromise(Newsletter.getAsync($stateParams.key));
                },
                // eslint-disable-next-line sort-keys
                resolveLoading(Settings, resolveNewsletter) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveNewsletter);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/newsletters/edit/:key',
            views: {
                content: {
                    controller: 'NewsletterDetailsController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/newsletter/common/views/newsletter-details.html',
                },
            },
        })
        .state('app.front.instance-style', {
            // eslint-disable-next-line id-blacklist
            data: {
                action: 'style',
                designer: true,
            },
            params: {
                permissions: [PERMISSIONS.instanceAdmin, PERMISSIONS.superAdmin],
            },
            resolve: {
                resolveModules($injector, $ocLazyLoad, Translation, Utils, resolveLoadingFront) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingFront);

                    return _resolveBackOfficeModules($injector, $ocLazyLoad, Translation, Utils);
                },
                // eslint-disable-next-line sort-keys
                resolveContent(Content, Customer, Instance, resolveModules) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveModules);

                    const params = {
                        action: 'PAGE_EDIT',
                    };

                    const instanceId = Instance.getCurrentInstanceId();
                    if (angular.isDefinedAndFilled(instanceId)) {
                        params.instance = instanceId;
                    } else {
                        let customerSlug = Customer.getCustomerSlug(true);
                        const propertyName = customerSlug.isDomain ? 'customerHost' : 'customerSlug';
                        customerSlug = customerSlug.slug;

                        params[propertyName] = customerSlug;
                        params.instanceSlug = Instance.getCurrentInstanceSlug();
                    }

                    return Content.getAsync(params).$promise;
                },
                resolveCustomContentType: angular.noop,
                resolveLoading(Settings, resolveContent) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveContent);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/admin/instance-style',
            views: {
                content: {
                    controller: 'ContentFrontController',
                    controllerAs: 'vm',
                    templateUrl: '/client/front-office/modules/content/common/views/content-front.html',
                },
                'sidebar@app': {
                    controller: 'InstanceStyleSidebarController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/instance-style/views/instance-style-sidebar.html',
                },
            },
        })
        .state('app.front.search', {
            resolve: {
                resolveLoading($stateParams) {
                    'ngInject';

                    angularApi.redirect(search($stateParams.query, $stateParams.tab));
                },
            },
            url: '/new/search/:query/:tab?isQI',
        })
        .state('app.front.content-create', {
            // eslint-disable-next-line id-blacklist
            data: {
                action: 'create',
                designer: true,
            },
            params: {
                template: {
                    squash: true,
                    value: null,
                },
            },
            resolve: {
                resolveModules($injector, $ocLazyLoad, Translation, Utils, resolveLoadingFront) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingFront);

                    return _resolveBackOfficeModules($injector, $ocLazyLoad, Translation, Utils);
                },
                resolveTemplate($stateParams, Template, resolveModules) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveModules);

                    return $stateParams.template
                        ? resolvePromise(Template.getAsync({ uid: $stateParams.template }))
                        : true;
                },
                // eslint-disable-next-line sort-keys
                resolveCustomContentType($q, $stateParams, CustomContentType, resolveModules) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is  used.
                    angular.noop(resolveModules);

                    return $q(function deferCustomContentTypeGet(resolve, reject) {
                        CustomContentType.getItem($stateParams.customContentType, resolve, reject, undefined, true);
                    });
                },
                // eslint-disable-next-line sort-keys
                resolveLoading(Settings, resolveCustomContentType, resolveTemplate) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveCustomContentType, resolveTemplate);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/content/create/:type/:customContentType/{template}?parentNavUuid',
            views: {
                content: {
                    controller: 'ContentFrontController',
                    controllerAs: 'vm',
                    templateUrl: '/client/front-office/modules/content/common/views/content-front.html',
                },
                'sidebar@app': {
                    controller: 'ContentSidebarController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/content/common/views/content-sidebar.html',
                },
            },
        })
        .state('app.front.content-create-without-custom-type', {
            // eslint-disable-next-line id-blacklist
            data: {
                action: 'create',
                designer: true,
            },
            params: {
                contentType: 'content',
                template: {
                    squash: true,
                    value: null,
                },
            },
            resolve: {
                resolveModules($injector, $ocLazyLoad, Translation, Utils, resolveLoadingFront) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingFront);

                    return _resolveBackOfficeModules($injector, $ocLazyLoad, Translation, Utils);
                },
                resolveTemplate($stateParams, Template, resolveModules) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveModules);

                    const contentType = $stateParams.contentType || 'content';

                    if (!includes(['content', 'community'], contentType)) {
                        return false;
                    }

                    if (contentType === 'content') {
                        return $stateParams.template
                            ? resolvePromise(Template.getAsync({ uid: $stateParams.template }))
                            : true;
                    }

                    return true;
                },
                // eslint-disable-next-line sort-keys
                resolveCustomContentType: angular.noop,
                resolveLoading(Settings, resolveTemplate) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveTemplate);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/:contentType/create/:type/{template}?fromCommunity',
            views: {
                content: {
                    controller: 'ContentFrontController',
                    controllerAs: 'vm',
                    templateUrl: '/client/front-office/modules/content/common/views/content-front.html',
                },
                'sidebar@app': {
                    controller: 'ContentSidebarController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/content/common/views/content-sidebar.html',
                },
            },
        })
        .state('app.front.content-edit', {
            // eslint-disable-next-line id-blacklist
            data: {
                action: 'edit',
                designer: true,
            },
            params: {
                contentType: 'content',
                previousSlug: undefined,
                subTemplate: {
                    squash: true,
                    value: null,
                },
            },
            resolve: {
                resolveModules($injector, $ocLazyLoad, Translation, Utils, resolveLoadingFront) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingFront);

                    return _resolveBackOfficeModules($injector, $ocLazyLoad, Translation, Utils);
                },
                // eslint-disable-next-line sort-keys
                resolveContent($state, $stateParams, Community, CommunityTemplates, Content, Template, resolveModules) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveModules);

                    // This is a pretty ugly workaround for community edition in designer.
                    if (!includes(['content', 'community'], $stateParams.contentType)) {
                        return false;
                    }

                    let id;
                    const isCommunity = $stateParams.contentType === 'community';
                    const isCurrentStateEdit = get($state.$current, 'self.data.action') === 'edit';

                    let Service = Content;
                    let TemplateService = Template;

                    if (isCommunity) {
                        Service = Community;
                        TemplateService = CommunityTemplates;
                    }

                    if ($stateParams.key) {
                        id = $stateParams.key;
                    } else {
                        $state.go(
                            'app.front.404',
                            {},
                            {
                                inherit: false,
                            },
                        );
                    }

                    let promise;
                    if (angular.isDefined($stateParams.isTemplate) && $stateParams.isTemplate === 'true') {
                        promise = TemplateService.getAsync({
                            uid: id,
                        });
                    } else {
                        if (isCommunity && get(Service.getCurrent(), 'uid') === id && isCurrentStateEdit) {
                            return true;
                        }

                        promise = Service.getAsync({
                            action: 'PAGE_EDIT',
                            uid: id,
                        });

                        if (isCommunity) {
                            promise.$promise.then(function onFulfilled(value) {
                                Community.setCurrent(value);
                                Content.setCurrent(value);
                            });
                        }
                    }

                    return resolvePromise(promise);
                },
                // eslint-disable-next-line sort-keys
                resolveCustomContentType($q, $stateParams, Content, CustomContentType, Template, resolveContent) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is  used.
                    angular.noop(resolveContent);

                    const isCommunity = $stateParams.contentType === 'community';
                    if (isCommunity) {
                        return true;
                    }

                    let currentContent;

                    if (angular.isDefined($stateParams.isTemplate) && $stateParams.isTemplate === 'true') {
                        currentContent = Template.getCurrent();
                    } else {
                        currentContent = Content.getCurrent();
                    }

                    const customContentTypeId = get(currentContent, 'customContentType');
                    if (angular.isUndefinedOrEmpty(customContentTypeId)) {
                        return true;
                    }

                    return $q(function deferCustomContentTypeGet(resolve, reject) {
                        CustomContentType.getItem(customContentTypeId, resolve, reject, undefined, true);
                    });
                },
                resolveLoading(Settings, resolveCustomContentType) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveCustomContentType);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/:contentType/edit/:key/:subTemplate?isTemplate&postId',
            views: {
                content: {
                    controller: 'ContentFrontController',
                    controllerAs: 'vm',
                    templateUrl: '/client/front-office/modules/content/common/views/content-front.html',
                },
                'sidebar@app': {
                    controller: 'ContentSidebarController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/content/common/views/content-sidebar.html',
                },
            },
        })
        .state('app.front.content-duplicate', {
            // eslint-disable-next-line id-blacklist
            data: {
                action: 'edit',
                designer: true,
            },
            resolve: {
                resolveModules($injector, $ocLazyLoad, Translation, Utils, resolveLoadingFront) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingFront);

                    return _resolveBackOfficeModules($injector, $ocLazyLoad, Translation, Utils);
                },
                // eslint-disable-next-line sort-keys
                resolveDuplicate(
                    $state,
                    $stateParams,
                    Config,
                    Content,
                    Customer,
                    Header,
                    Instance,
                    InitialSettings,
                    Translation,
                    Template,
                    Widget,
                    resolveModules,
                ) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveModules);

                    if (angular.isUndefinedOrEmpty($stateParams.key)) {
                        $state.go(
                            'app.front.404',
                            {},
                            {
                                inherit: false,
                            },
                        );
                    }

                    const customer = Customer.getCustomer();
                    const isVeolia = angular.isDefined(customer) && customer.slug === 'veolia';

                    let promise;
                    if (angular.isDefined($stateParams.isTemplate) && $stateParams.isTemplate === 'true') {
                        if (
                            angular.isDefinedAndFilled($stateParams.fromContent) &&
                            $stateParams.fromContent === 'true'
                        ) {
                            promise = Content.getAsync(
                                {
                                    action: isVeolia ? 'PAGE_READ' : 'PAGE_EDIT',
                                    uid: $stateParams.key,
                                },
                                function contentGetSuccess() {
                                    const currentContent = Content.getCurrent();
                                    const [contributionWidget] = Widget.findWidgetsByType(
                                        currentContent.template,
                                        InitialSettings.WIDGET_TYPES.CONTRIBUTION,
                                    );

                                    if (contributionWidget) {
                                        // If there is a contribution widget in the original content, we remove the structured content
                                        // id and version from its properties to avoid multiple contents/templates refer to the same entity.
                                        contributionWidget.properties.structuredContentId = undefined;
                                        contributionWidget.properties.structuredContentVersion = undefined;
                                    }

                                    Template.duplicate(currentContent, $stateParams);
                                },
                            );
                        } else {
                            promise = Template.getAsync(
                                {
                                    uid: $stateParams.key,
                                },
                                function templateGetSuccess(obj) {
                                    Template.duplicate(obj, $stateParams);
                                },
                            );
                        }
                    } else {
                        promise = Content.getAsync(
                            {
                                action:
                                    isVeolia && $stateParams.status !== Config.CONTENT_STATUS.DRAFT.value
                                        ? 'PAGE_READ'
                                        : 'PAGE_EDIT',
                                uid: $stateParams.key,
                            },
                            function contentGetSuccess() {
                                duplicateContent(
                                    $stateParams,
                                    Config,
                                    Content,
                                    Header,
                                    InitialSettings,
                                    Instance,
                                    Translation,
                                    Widget,
                                    isVeolia,
                                );
                            },
                        );
                    }

                    return resolvePromise(promise);
                },
                // eslint-disable-next-line sort-keys
                resolveCustomContentType($q, $stateParams, Content, CustomContentType, Template, resolveDuplicate) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is  used.
                    angular.noop(resolveDuplicate);

                    let currentContent;

                    if (angular.isDefined($stateParams.isTemplate) && $stateParams.isTemplate === 'true') {
                        currentContent = Template.getCurrent();
                    } else {
                        currentContent = Content.getCurrent();
                    }

                    const customContentTypeId =
                        get(currentContent, 'customContentType') || $stateParams.customContentType;
                    if (angular.isUndefinedOrEmpty(customContentTypeId)) {
                        return true;
                    }

                    return $q(function deferCustomContentTypeGet(resolve, reject) {
                        CustomContentType.getItem(customContentTypeId, resolve, reject, undefined, true);
                    });
                },
                resolveLoading(Settings, resolveCustomContentType) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveCustomContentType);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/content/duplicate/:key?instanceId&customContentType&contentType&isTemplate&fromContent&status',
            views: {
                content: {
                    controller: 'ContentFrontController',
                    controllerAs: 'vm',
                    templateUrl: '/client/front-office/modules/content/common/views/content-front.html',
                },
                'sidebar@app': {
                    controller: 'ContentSidebarController',
                    controllerAs: 'vm',
                    templateUrl: '/client/back-office/modules/content/common/views/content-sidebar.html',
                },
            },
        });
};

/**
 * Define the states for the Customer Administration.
 *
 * @param {Provider} $stateProvider     The state provider.
 * @param {Provider} $translateProvider The translation provider.
 */
const setupCustomerAdmin = ($stateProvider, $translateProvider) => {
    $stateProvider.state('adminCustomer', {
        resolve: {
            resolveModules($injector, $ocLazyLoad, Translation, Utils) {
                'ngInject';

                return _resolveBackOfficeModules($injector, $ocLazyLoad, Translation, Utils);
            },
            // eslint-disable-next-line sort-keys
            resolveInitialSetting($q, Settings, resolveModules) {
                'ngInject';

                // This is here to trick the linter into thinking that the blocking resolve injection is used.
                angular.noop(resolveModules);

                return $q(function resolveAllInitialSetting(resolve, reject) {
                    Settings.setup('adminCustomer').then(resolve).catch(reject);
                });
            },
            resolveTranslations(Translation, resolveInitialSetting) {
                'ngInject';

                // This is here to trick the linter into thinking that the blocking resolve injection is used.
                angular.noop(resolveInitialSetting);

                Translation.setTranslationsTable($translateProvider.translations());

                return true;
            },
            // eslint-disable-next-line sort-keys
            resolveLoading(Settings, resolveInitialSetting) {
                'ngInject';

                // This is here to trick the linter into thinking that the blocking resolve injection is used.
                angular.noop(resolveInitialSetting);

                Settings.endLoading();

                return true;
            },
        },
        url: '/admin/customer?token',
        views: {
            app: {
                controller: 'CustomerSettingsController',
                controllerAs: 'vm',
                templateUrl: '/client/back-office/modules/customer/views/customer-settings.html',
            },
        },
    });
};

/////////////////////////////

export { setup, setupCustomerAdmin };
