import React from 'react';

import { mdiLink } from '@lumapps/lumx/icons';
import { TextField, TextFieldProps } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { isURLValid } from '@lumapps/utils/string/isUrl';

import { LUMX_INPUTS } from '../../keys';

const CLASSNAME = 'url-text-field';
export interface URLTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
    /**
     * url to be edited.
     */
    url?: string;
    /**
     * callback to be executed once the text field has changed. it returns the current value and whether
     * the url is valid or not.
     */
    onUrlChange: (value: string, isValid: boolean) => void;
    /**
     * custom url validator. Will default to isURLValid from @lumapps/utils/string/isUrl
     */
    urlValidator?: (url: string) => boolean;
    /**
     * Whether to force the error displayed for this component or not. Useful when the component
     * is controlled from the outside
     */
    forceErrorDisplay?: boolean;
    /**
     * Whether the validity should be displayed on the text field
     */
    shouldDisplayValidity?: boolean;
}

/**
 * Field that displays a text field that allows the user to enter a URL, which will be validated
 * accordingly to determined if it is a real URL.
 *
 * @family Inputs
 * @param URLTextFieldProps
 */
export const URLTextField: React.FC<URLTextFieldProps> = ({
    url = '',
    onUrlChange,
    urlValidator = isURLValid,
    onBlur,
    forceErrorDisplay = false,
    shouldDisplayValidity = true,
    ...props
}) => {
    const { translateKey } = useTranslate();
    const [value, setUrl] = React.useState(url);
    const [displayValidationState, setDisplayValidationState] = React.useState(false);
    const [isUrlInputFocused, setIsUrlInputFocused] = React.useState(false);
    const [isUrlInputBlurred, setIsUrlInputBlurred] = React.useState(false);

    /**
     * This hook keeps in sync the internal state of the modal with the passed in props
     */
    React.useEffect(() => {
        setUrl(url);
    }, [url]);

    const isUrlValid = React.useMemo(() => {
        setDisplayValidationState(!isUrlInputFocused && Boolean(value));
        return urlValidator(value);
    }, [isUrlInputFocused, urlValidator, value]);

    const onUrlInputBlur = React.useCallback(
        (evt: React.FocusEvent) => {
            if (onBlur) {
                onBlur(evt);
            }
            setIsUrlInputFocused(false);
            setIsUrlInputBlurred(true);
        },
        [onBlur],
    );

    const onUrlInputFocus = React.useCallback(() => {
        setIsUrlInputFocused(true);
    }, []);

    const onChange = (val: string) => {
        setUrl(val);
        onUrlChange(val, urlValidator(val));
    };

    const isValid = forceErrorDisplay ? false : Boolean(isUrlValid) && displayValidationState;

    return (
        <TextField
            className={CLASSNAME}
            label={translateKey(LUMX_INPUTS.URL_FIELD_LABEL)}
            value={value}
            onChange={onChange}
            onFocus={onUrlInputFocus}
            onBlur={onUrlInputBlur}
            isValid={
                shouldDisplayValidity && forceErrorDisplay
                    ? false
                    : isUrlInputBlurred && Boolean(isUrlValid) && displayValidationState
            }
            aria-invalid={!isValid}
            hasError={forceErrorDisplay || (displayValidationState && (!isUrlValid || !value))}
            error={translateKey(LUMX_INPUTS.URL_FIELD_ERROR_MESSAGE)}
            icon={mdiLink}
            {...props}
        />
    );
};
