import React from 'react';

import WarningIllustration from '@lumapps/assets/common/warning_illustration.svg';
import { classnames } from '@lumapps/classnames';
import { IconProps, Size, GapSize, Theme, HeadingElement, ButtonProps, TextProps } from '@lumapps/lumx/react';

import { BaseState } from '../BaseState';

export interface WarningStateProps {
    actions?: React.ReactNode;
    className?: string;
    buttonProps?: ButtonProps;
    icon?: string;
    iconProps?: Omit<IconProps, 'icon'>;
    image?: string;
    /** alt attribute to pass to the img, keep it empty if the image is only decorative */
    imageAlt?: string;
    shouldShowImage?: boolean;
    subtitle?: string;
    subtitleProps?: Partial<TextProps>;
    title?: string;
    gap?: GapSize;
    theme?: Theme;
    titleAs?: HeadingElement;
}

const CLASSNAME = 'lumx-warning-state';
/**
 * Customisable component used for displaying a warning message to the user. This should only be used
 * if the message is not one of the standard ones. If it is standard please refer to:
 * - `EmptyState` for any message displaying that there are no entities created
 * - `NoResults` for any message regarding filtering a list and not finding any matching results
 * - `ServiceNotAvailable` for any API error.
 *
 * @family States
 * @param WarningStateProps
 * @returns WarningState
 */
export const WarningState: React.FC<WarningStateProps> = ({
    actions,
    className,
    buttonProps,
    icon,
    iconProps,
    image = WarningIllustration,
    imageAlt = '',
    shouldShowImage = false,
    subtitle,
    subtitleProps,
    title,
    gap = Size.huge,
    theme = Theme.light,
    titleAs = 'h1',
}) => {
    return (
        <BaseState
            className={classnames(CLASSNAME, className)}
            imageProps={shouldShowImage ? { image, alt: imageAlt } : undefined}
            iconProps={icon ? { icon, ...iconProps } : undefined}
            buttonProps={buttonProps}
            title={title}
            titleAs={titleAs}
            message={subtitle}
            messageProps={subtitleProps}
            theme={theme}
            gap={gap}
            actions={actions}
        />
    );
};
