import React from 'react';

import { mdiPlay } from '@lumapps/lumx/icons';
import { VideoPickerDialog } from '@lumapps/play-pickers/components/VideoPickerDialog';
import type { Video } from '@lumapps/play/api/types';
import { isPlayVideoEnabled } from '@lumapps/play/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { ToolbarItem } from '@lumapps/wrex/types';

import { PLAY_VIDEO } from '../../../constants';
import { WREX_PLAY_VIDEO } from '../../../keys';

export const usePickPlayVideoButton = ({
    onInsert,
    otherProps,
}: {
    onInsert: (video: Video) => void;
    otherProps?: any;
}) => {
    const { translateKey } = useTranslate();

    const [isDialogOpen, , closeDialog, openDialog] = useBooleanState(false);

    const isPlayEnabled = useSelector(isPlayVideoEnabled);

    const PickPlayVideoButton = React.useMemo(() => {
        const onInsertVideo = (selectedVideos: Video[]) => {
            onInsert(selectedVideos[0]);
            closeDialog();
        };

        const insertPlayVideoDialog = (
            <VideoPickerDialog
                isOpen={isDialogOpen}
                onSelectionConfirm={onInsertVideo}
                onSelectionCancel={closeDialog}
            />
        );

        const PickPlayVideoButtonProps: ToolbarItem = {
            itemKey: PLAY_VIDEO,
            icon: mdiPlay,
            label: translateKey(WREX_PLAY_VIDEO.PICK_FROM_VIDEO_GALLERY),
            otherProps,
            onClick: openDialog,
            type: 'option',
        };

        return { insertPlayVideoDialog, PickPlayVideoButtonProps };
    }, [closeDialog, isDialogOpen, onInsert, openDialog, otherProps, translateKey]);

    return { ...(isPlayEnabled && PickPlayVideoButton) };
};
