import { combineReducers } from '@lumapps/redux';

import { reducer as tasksReducer } from '../../tasks/_ducks/tasks_reducer';

import { reducer as entitiesReducer } from './entities';

import { reducer as chartReducer } from '../../chart/_ducks/chart_reducer';

const rootReducer = combineReducers({
    entities: entitiesReducer,
    tasks: tasksReducer,
    chart: chartReducer,
});

export default rootReducer;
