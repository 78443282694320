import React, { FormEventHandler } from 'react';

import curry from 'lodash/fp/curry';
import map from 'lodash/map';

import { margin } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiArrowRight } from '@lumapps/lumx/icons';
import { Link, Typography, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { WIDGET_DIRECTORY_ENTRIES_TYPE } from '@lumapps/widget-directory-entries/constants';

import { WIDGET_CONTENT_FILTER_FORM_ID } from '../../constants';
import { WIDGET_CONTENT_FILTER } from '../../keys';
import { GenericListFilterType, NGIFilterId } from '../../types';
import { AuthorContentFilter } from '../AuthorContentFilter';
import { CustomContentTypeTagsFilter } from '../CustomContentTypeTagsFilter';
import { HighlightedContentFilter } from '../HighlightedContentFilter';
import { KeywordContentFilter } from '../KeywordContentFilter';
import { MetadataContentFilter } from '../MetadataContentFilter';
import { PublicationDatesContentFilter } from '../PublicationDatesContentFilter';
import { SitesContentFilter } from '../SitesContentFilter';
import { TagsFromDirectoriesFilter } from '../TagsFromDirectoriesFilter';

interface ContentFilterFormProps {
    /** Widget Props */
    mainWidgetType?: string;
    theme: Theme;
    scope: string;
    hideSubheader: boolean;
    /** Features */
    advancedSearchURL?: string;
    /** Filters values */
    availableFilters: NGIFilterId[] | undefined;
    filtersCurrentValues: GenericListFilterType;
    filtersFromWidgetProperties: Partial<GenericListFilterType> | null;
    /** Handlers */
    handleFilterChange(filterId: NGIFilterId | string, values: GenericListFilterType[NGIFilterId]): void;
    handleApply(): void;
}

export const ContentFilterForm: React.FC<ContentFilterFormProps> = ({
    availableFilters,
    filtersCurrentValues,
    filtersFromWidgetProperties,
    handleFilterChange,
    handleApply,
    advancedSearchURL,
    mainWidgetType,
    theme,
    scope,
    hideSubheader,
}) => {
    const { get } = useDataAttributes(scope);
    const { translateKey } = useTranslate();

    const handleApplyPreventDefault: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        handleApply();
    };

    return (
        <form id={WIDGET_CONTENT_FILTER_FORM_ID} onSubmit={handleApplyPreventDefault}>
            {map(availableFilters, (type) => {
                switch (type) {
                    case NGIFilterId.searchQuery:
                        return (
                            <KeywordContentFilter
                                value={filtersCurrentValues?.searchQuery || ''}
                                onChange={curry(handleFilterChange)(NGIFilterId.searchQuery)}
                                theme={theme}
                                scope={scope}
                                key={NGIFilterId.searchQuery}
                                hideSubheader={hideSubheader}
                            />
                        );
                    case NGIFilterId.tags:
                        return mainWidgetType === WIDGET_DIRECTORY_ENTRIES_TYPE ? (
                            <TagsFromDirectoriesFilter
                                shouldBeHiddenIfEmpty
                                onChange={curry(handleFilterChange)(NGIFilterId.tags)}
                                directories={filtersFromWidgetProperties?.directories}
                                selectedTags={filtersCurrentValues?.tags}
                                theme={theme}
                                scope={`${scope}-tags`}
                                key={NGIFilterId.tags}
                                hideSubheader={hideSubheader}
                            />
                        ) : (
                            <CustomContentTypeTagsFilter
                                shouldBeHiddenIfEmpty
                                onChange={curry(handleFilterChange)(NGIFilterId.tags)}
                                selectedTags={filtersCurrentValues?.tags}
                                tagReferences={filtersFromWidgetProperties?.tagReferences}
                                sites={filtersCurrentValues?.site}
                                siteReferences={filtersFromWidgetProperties?.siteReferences}
                                includeSiblingSites={filtersFromWidgetProperties?.includeSiblingSites}
                                theme={theme}
                                scope={`${scope}-tags`}
                                key={NGIFilterId.tags}
                                hideSubheader={hideSubheader}
                            />
                        );
                    case NGIFilterId.metadata:
                        return (
                            <MetadataContentFilter
                                onChange={curry(handleFilterChange)(NGIFilterId.metadata)}
                                rootMetadataReferences={filtersFromWidgetProperties?.rootMetadataReferences}
                                selectedMetadata={filtersCurrentValues?.metadata}
                                theme={theme}
                                scope={`${scope}-metadata`}
                                key={NGIFilterId.metadata}
                                hideSubheader={hideSubheader}
                            />
                        );
                    case NGIFilterId.onlyHighlighted:
                        return (
                            <HighlightedContentFilter
                                theme={theme}
                                scope={scope}
                                isChecked={!!filtersCurrentValues?.onlyHighlighted}
                                onChange={curry(handleFilterChange)(NGIFilterId.onlyHighlighted)}
                                key={NGIFilterId.onlyHighlighted}
                            />
                        );
                    case NGIFilterId.publicationDates:
                        return (
                            <PublicationDatesContentFilter
                                theme={theme}
                                scope={scope}
                                onChange={curry(handleFilterChange)(NGIFilterId.publicationDates)}
                                publicationDates={filtersCurrentValues?.publicationDates}
                                key={NGIFilterId.publicationDates}
                                hideSubheader={hideSubheader}
                            />
                        );
                    case NGIFilterId.author:
                        return (
                            <AuthorContentFilter
                                theme={theme}
                                scope={scope}
                                selectedValue={filtersCurrentValues?.author}
                                onChange={curry(handleFilterChange)(NGIFilterId.author)}
                                key={NGIFilterId.author}
                                hideSubheader={hideSubheader}
                            />
                        );
                    case NGIFilterId.site:
                        return (
                            <SitesContentFilter
                                theme={theme}
                                scope={scope}
                                shouldBeHiddenIfEmpty
                                handleSitesFilter={curry(handleFilterChange)(NGIFilterId.site)}
                                selectedSites={filtersCurrentValues?.site}
                                siteReferences={filtersFromWidgetProperties?.siteReferences}
                                key={NGIFilterId.site}
                                hideSubheader={hideSubheader}
                            />
                        );
                    default:
                        return null;
                }
            })}
            {advancedSearchURL && (
                <Link
                    className={margin('top', 'big')}
                    rightIcon={mdiArrowRight}
                    href={advancedSearchURL}
                    typography={Typography.body1}
                    theme={theme}
                    {...get({ element: 'link', action: 'advanced-search' })}
                >
                    {translateKey(WIDGET_CONTENT_FILTER.ADVANCED_FILTERS)}
                </Link>
            )}
        </form>
    );
};
