import { NodeEntry } from '@lumapps/wrex/slate';
import { WrexEditor } from '@lumapps/wrex/types';

import { ListEditor } from '../../types';
import { normalizeList } from './normalizeList';
import { normalizeListItem } from './normalizeListItem';

export * from './types';

export function normalizeListNodes(
    editor: WrexEditor<ListEditor>,
    normalizeNode: WrexEditor<ListEditor>['normalizeNode'],
    entry: NodeEntry,
) {
    if (normalizeList(editor, entry)) {
        return;
    }
    if (normalizeListItem(editor, entry)) {
        return;
    }
    normalizeNode(entry);
}
