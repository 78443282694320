/* istanbul ignore file */
import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

const LazyShareToExtensionDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'share-to-extension-dialog' */
            './ShareToExtensionDialog'
        ),
);

export const ShareToExtensionDialog = ({
    isOpen,
    ...props
}: React.ComponentProps<typeof LazyShareToExtensionDialog>) => {
    return (
        <DelayedSuspense isDialog shouldLoad={isOpen}>
            <LazyShareToExtensionDialog isOpen={isOpen} {...props} />
        </DelayedSuspense>
    );
};
