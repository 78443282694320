import type { EmojiDetails } from '@lumapps/pickers/types';
import type { InlineAutocompleteOptions } from '@lumapps/wrex-inline-autocomplete/types';
import type { Text } from '@lumapps/wrex/slate';

import { useSearchEmojiData } from './hooks/useSearchEmojiData';

/** Emoji inline autocomplete option to pass to the withInlineAutocomplete plugin */
export const emojiAutocompleteOption = {
    triggerCharacter: ':',
    elementCreator: (emojiObject: EmojiDetails['emoji']) => ({ text: emojiObject.unicode }),
    getEntityId: (emoji: EmojiDetails['emoji']) => emoji?.shortcodes?.[0],
    getEntityLabel: (emoji: EmojiDetails['emoji']) => emoji?.shortcodes?.[0],
    getListItemProps: (emojiObject: EmojiDetails['emoji']) => ({
        before: emojiObject?.unicode,
    }),
    fetchHook: useSearchEmojiData,
} as InlineAutocompleteOptions<Text, EmojiDetails['emoji']>;
