import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { getFirstContentAsString } from '../../../slate/utils/getFirstContentAsString';
import {
    SlateStructuredContent,
    FeaturedImage,
    FeaturedImageMedia,
    Prolog,
    ShortDesc,
    Title,
    Topic,
    Text,
} from '../types';
import { fromSlate } from './fromSlate';

export const UID_PREFIX = 'art-';

export const generateRoot = (): Topic => ({
    type: 'element',
    name: 'topic',
    attributes: {
        id: UID_PREFIX + generateUUID(),
    },
    children: [],
});

export const generateArticleTitle = (title: string): Title => ({
    type: 'element',
    name: 'title',
    children: [
        {
            type: 'text',
            value: title,
        },
    ],
});

export const generateArticleShortDesc = (description?: string): ShortDesc => {
    const children: Text[] = [];
    if (description) {
        children.push({
            type: 'text',
            value: description,
        });
    }
    return {
        type: 'element',
        name: 'shortdesc',
        children,
    };
};

export const generateProlog = (): Prolog => ({
    type: 'element',
    name: 'prolog',
    children: [],
});

export const generateFeaturedImage = (featuredImage: FeaturedImageMedia) => {
    const featuredImageDita: FeaturedImage = {
        name: 'data-about',
        type: 'element',
        attributes: {
            type: 'lumapps:featured-image',
            href: featuredImage.url,
            keyref: featuredImage.id || '',
        },
        children: [
            {
                type: 'element',
                name: 'data',
                attributes: {
                    name: 'alt',
                    value: featuredImage.alt || '',
                },
                children: [],
            },
        ],
    };
    if (featuredImage.focalPoint) {
        featuredImageDita.children.push({
            type: 'element',
            name: 'data',
            attributes: {
                name: 'focalPoint',
            },
            children: [
                {
                    type: 'element',
                    name: 'data',
                    children: [],
                    attributes: {
                        name: 'focalPoint:x',
                        value: featuredImage.focalPoint.x,
                    },
                },
                {
                    type: 'element',
                    name: 'data',
                    children: [],
                    attributes: {
                        name: 'focalPoint:y',
                        value: featuredImage.focalPoint.y,
                    },
                },
            ],
        } as any);
    }
    return featuredImageDita as FeaturedImage;
};

export const toDITA = ({ children, description, title, featuredImage }: SlateStructuredContent) => {
    const dita = generateRoot();
    const titleDita = generateArticleTitle(title);
    const shortDescDita = generateArticleShortDesc(description || getFirstContentAsString(children));
    const prologDita = generateProlog();
    dita.children.push(titleDita);
    dita.children.push(shortDescDita);
    dita.children.push(prologDita);
    if (featuredImage) {
        const featuredImageDita = generateFeaturedImage(featuredImage);
        prologDita.children.push(featuredImageDita);
    }
    const slateDita = fromSlate(children);
    dita.children.push(...slateDita);
    return dita;
};
