import React from 'react';

import { margin, padding } from '@lumapps/classnames/utils';
import { SkeletonTypography, Typography, Text, FlexBox } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export const SegmentInformationSkeleton = () => {
    const { translateKey } = useTranslate();
    const segmentDescriptionTitle = translateKey(GLOBAL.DESCRIPTION);
    const segmentSizeTitle = translateKey(GLOBAL.SIZE);

    return (
        <FlexBox orientation="vertical" fillSpace>
            <div className={margin('bottom', 'regular')}>
                <Text as="h2" className={padding('bottom', 'regular')} typography={Typography.subtitle1}>
                    {segmentDescriptionTitle}
                </Text>
                <SkeletonTypography typography={Typography.subtitle2} />
                <SkeletonTypography typography={Typography.subtitle2} />
                <SkeletonTypography typography={Typography.subtitle2} />
                <SkeletonTypography typography={Typography.subtitle2} />
            </div>
            <div>
                <Text as="h2" className={padding('bottom', 'regular')} typography={Typography.subtitle1}>
                    {segmentSizeTitle}
                </Text>
                <SkeletonTypography typography={Typography.subtitle2} width="220px" />
                <SkeletonTypography typography={Typography.subtitle2} width="220px" />
            </div>
        </FlexBox>
    );
};
