/* istanbul ignore file */
import moment from 'moment';

import { SEARCH_ANALYTICS } from './keys';
import { DateRange, DateRangeType } from './types';

export const COVEO_INDEXING_FEATURE_TOKEN = 'coveo-indexing';
export const SEARCH_ANALYTICS_DATA_ATTRIBUTES_SCOPE = 'search-analytics';

export const SEARCH_ANALYTICS_FEATURE_TOKEN = 'search-analytics';
export const PACKAGE_NAMESPACE = 'search-analytics';
export const PAGE_HEADER_NAMESPACE = `${PACKAGE_NAMESPACE}-page-header`;
export const SELECT_PANEL_NAMESPACE = `${PAGE_HEADER_NAMESPACE}-select-panel`;
export const ANALYTICS_MODULE_NAMESPACE = `${PACKAGE_NAMESPACE}-analytics-module`;
export const ANALYTICS_MODULE_RESULTS_NAMESPACE = `${ANALYTICS_MODULE_NAMESPACE}-results`;

export const DateRangeTypeToMomentUnitOfTime: Record<DateRangeType, moment.unitOfTime.DurationConstructor> = {
    [DateRangeType.DAYS]: 'days',
};

export const DateRangeTypeToTranslateKey: Record<DateRangeType, SEARCH_ANALYTICS> = {
    [DateRangeType.DAYS]: SEARCH_ANALYTICS.DATE_RANGE_DAYS,
};

export const ANALYTICS_MODULE_RESULTS_PER_PAGE = 10;
export const ANALYTICS_MODULE_RESULTS_PERCENT_FRACTION_DIGITS = 1;

export const DATE_RANGE_30_DAYS: DateRange = { type: DateRangeType.DAYS, value: '30' };
export const DATE_RANGE_15_DAYS: DateRange = { type: DateRangeType.DAYS, value: '15' };
export const DATE_RANGE_7_DAYS: DateRange = { type: DateRangeType.DAYS, value: '7' };
export const AVAILABLE_DATE_RANGE = [DATE_RANGE_7_DAYS, DATE_RANGE_15_DAYS, DATE_RANGE_30_DAYS];
