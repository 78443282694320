import React from 'react';

import { SocialNetworks } from '@lumapps/sa-social-networks/types';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

export function useLoadSocialNetworkShareDialog() {
    const [isOpen, , onClose, open] = useBooleanState(false);
    const [activeSocialNetwork, setActiveSocialNetwork] = React.useState<SocialNetworks>();
    const handleSetActiveSocialNetwork = React.useCallback(
        (socialNetwork: SocialNetworks) => {
            setActiveSocialNetwork(socialNetwork);
            open();
        },
        [open],
    );
    return { isOpen, activeSocialNetwork, handleSetActiveSocialNetwork, onClose };
}
