import React, { useMemo } from 'react';

import { InsertMapDialog } from '@lumapps/lumx-preview/components/InsertUrlPreviewDialog/InsertMapDialog';
import { mdiMap } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { LinkAttachment } from '@lumapps/widget-base/types';
import { ReactEditor, useSlateStatic } from '@lumapps/wrex/slate';
import { ToolbarItem } from '@lumapps/wrex/types';

import { EMBEDDED_MAP } from '../../../constants';
import { WREX_EMBEDDED_MAP } from '../../../keys';
import { EmbeddedMapEditor } from '../../../types';

/**
 * Hook to make embeddedMapButton properties reusable from specific component as dropdown (ex: in structured content toolbar)
 */
export const useEmbeddedMapButton = (otherProps?: any) => {
    const editorStatic = useSlateStatic() as ReactEditor & EmbeddedMapEditor;
    const { translateKey } = useTranslate();
    const [isMapDialogOpen, , closeMapDialog, openEmbeddedMapDialog] = useBooleanState(false);

    return useMemo(() => {
        const onInsertEmbedMap = (link: LinkAttachment) => {
            editorStatic.insertEmbeddedMap({
                url: link.url,
                title: link.title,
                thumbnailUrl: link?.images && link.images[0],
                description: link.description,
            });

            closeMapDialog();
        };

        const insertEmbeddedMapDialog = (
            <InsertMapDialog isOpen={isMapDialogOpen} onInsert={onInsertEmbedMap} onClose={closeMapDialog} />
        );

        const embeddedMapButtonProps: ToolbarItem = {
            itemKey: EMBEDDED_MAP,
            icon: mdiMap,
            label: translateKey(WREX_EMBEDDED_MAP.BUTTON_LABEL),
            otherProps,
            onClick: openEmbeddedMapDialog,
            type: 'option',
        };

        return { insertEmbeddedMapDialog, embeddedMapButtonProps };
    }, [isMapDialogOpen, closeMapDialog, translateKey, otherProps, openEmbeddedMapDialog, editorStatic]);
};
