import React, { ReactNode } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { DroppableFileZone as LumxDroppableZone } from '@lumapps/lumx-files/components/DroppableFileZone';
import { DOCUMENTATION_LINKS, ReferToDocumentation } from '@lumapps/lumx-texts/components/ReferToDocumentation';
import { mdiUpload } from '@lumapps/lumx/icons';
import {
    Alignment,
    FlexBox,
    Uploader as LumxUploader,
    Orientation,
    Size,
    Text,
    Typography,
    UploaderProps,
    UploaderVariant,
} from '@lumapps/lumx/react';

import './DroppableFileZone.scss';

export interface DroppableFileZoneProps {
    /** Custom className */
    className?: string;
    /** React node children */
    children?: React.ReactNode;
    /** Text displayed on drag */
    droppableZoneText: string;
    /** when we have selected something we use this function to make actions possible by returning the selected files */
    onUpload: (selectedFile: File) => void;
}

const CLASSNAME = 'play-droppable-file-zone';

/**
 *
 * @param DroppableFileZoneProps
 * @returns DroppableFileZone
 */
export const DroppableFileZone = ({ className, children, droppableZoneText, onUpload }: DroppableFileZoneProps) => {
    const { block } = useClassnames(CLASSNAME);
    const handleUpload = (files: File[]) => {
        const file = files[0];
        onUpload(file);
    };

    return (
        <LumxDroppableZone
            className={block(undefined, className)}
            onUpload={handleUpload}
            droppableZoneText={droppableZoneText}
        >
            {children}
        </LumxDroppableZone>
    );
};

const Uploader = ({ children, ...uploaderProps }: React.PropsWithChildren<Partial<UploaderProps>>) => {
    const { element } = useClassnames(CLASSNAME);
    return (
        <>
            <LumxUploader
                className={element('uploader')}
                icon={mdiUpload}
                size={Size.xxl}
                variant={UploaderVariant.rounded}
                {...uploaderProps}
            />
            {children}
        </>
    );
};

const Subtitle = ({ children }: { children: ReactNode }) => {
    const { element } = useClassnames(CLASSNAME);
    return (
        <Text as="p" typography={Typography.subtitle2} className={element('subtitle')}>
            {children}
        </Text>
    );
};

const Description = ({ children }: { children: ReactNode }) => {
    const { element } = useClassnames(CLASSNAME);
    return (
        <Text as="p" typography={Typography.body1} className={element('description')}>
            {children}
        </Text>
    );
};

type FormatsProps = {
    extensions: string;
    maxSize: string;
};

const Formats = ({ extensions, maxSize }: FormatsProps) => {
    const { element } = useClassnames(CLASSNAME);
    return (
        <FlexBox orientation={Orientation.vertical} vAlign={Alignment.center} className={element('formats')}>
            <Text as="span" typography={Typography.caption}>
                {extensions}
            </Text>

            <ReferToDocumentation typography={Typography.caption} as="span" link={DOCUMENTATION_LINKS.play.upload} />

            <Text as="span" typography={Typography.caption}>
                {maxSize}
            </Text>
        </FlexBox>
    );
};

export const CompoundDroppableFileZone = Object.assign(DroppableFileZone, { Uploader, Subtitle, Description, Formats });
