import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

import { CONTENT_LIST_HTML_ALLOWED_TAGS, CONTENT_LIST_HTML_ALLOWED_ATTRIBUTES } from '../constants';

/** Sanitise the content excerpt HTML for full content display */
export const sanitizeExcerpt = (html: string) => {
    return sanitizeHTML(html, {
        allowedTags: CONTENT_LIST_HTML_ALLOWED_TAGS,
        allowedAttr: CONTENT_LIST_HTML_ALLOWED_ATTRIBUTES,
    });
};
