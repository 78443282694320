import React from 'react';

import { padding, useClassnames } from '@lumapps/classnames';
import { SearchField, SearchFieldProps } from '@lumapps/lumx-filter-and-sort/components/SearchField';
import { NoResultsState } from '@lumapps/lumx-states/components/NoResultsState';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import {
    FlexBox,
    List,
    ListItem,
    Select,
    Orientation,
    Alignment,
    InputHelper,
    SelectVariant,
    SelectProps,
    Size,
    ListSubheader,
    ListDivider,
} from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { useFocus } from '@lumapps/utils/hooks/useFocus';
import { InfiniteScroll } from '@lumapps/utils/hooks/useInfiniteScroll';

import { useFixedHeightList } from '../../hooks/useFixedHeightList';
import { OptionsLoadingState } from './OptionsLoadingState';

import './index.scss';

export type ChoiceRenderer<V = any> = (
    choice: V,
    isSelected: boolean,
    onSelect: (choice: V) => () => void,
    getValueName: (value: V) => React.ReactNode,
    getValueId: (value: V) => string,
) => React.ReactNode;

export interface SelectFieldSimpleProps<V = any> {
    /**
     * Select field label.
     */
    label?: string;
    /**
     * Selected value.
     */
    value: V | null;
    /**
     * On change selected values.
     */
    onChange(value: V): void;
    /**
     * Get identifier for given value (or cast to string by default).
     */
    getValueId?(value: V): string;
    /**
     * Get display name of the value (or cast to string by default).
     */
    getValueName?(value: V): React.ReactNode;
    /**
     * Open the select choice dropdown.
     */
    isOpen: boolean;
    /**
     * Open/Close dropdown on user interaction.
     */
    setOpen(open: boolean): void;
    /**
     * Either provide an array of values of the same type of the select values and get generic rendering of choices.
     * Or provide your own renderer for choices.
     */
    choices: V[];
    /**
     * The variant prop used by inner Select component
     */
    selectVariant?: SelectVariant;
    /**
     * What to do when the select gets cleared
     */
    onSelectClear?(): void;
    /**
     * Completly custom choice renderer.
     */
    choiceRenderer?: ChoiceRenderer;
    /**
     * ClassName forwarded to the underlying select
     */
    className?: string;
    /**
     * Select isRequired property
     */
    isRequired?: boolean;
    /** Select is disabled */
    isDisabled?: boolean;
    /** Optional icon */
    icon?: SelectProps['icon'];
    /** Other Select props that we want to pass to the Select component */
    selectProps?: Partial<SelectProps>;
    /** whether options are loading or not */
    isLoading?: boolean;
    /** whether there was an error loading the options or not */
    hasLoadingError?: boolean;
    isLoadingMore?: boolean;
    /** The callback function called when the button on the loading error state is clicked. */
    onRetryLoading?(): void;
    /** Select field choice search text. */
    searchText?: string;
    /** Select field choice search placeholder. */
    searchPlaceholder?: string;
    /** On select field choice search change. */
    onSearch?(searchText: string): void;
    /** props for the search field */
    searchFieldProps?: Partial<SearchFieldProps>;
    /** The callback function called when the bottom of the dropdown is reached. */
    onInfiniteScroll?(): void;
}

const defaultChoiceRender = <V,>(
    choice: V,
    isSelected: boolean,
    onSelect: (choice: V) => () => void,
    getValueName: (value: V) => React.ReactNode,
    getValueId: (value: V) => string,
) => {
    return (
        <ListItem size={Size.tiny} key={getValueId(choice)} isSelected={isSelected} onItemSelected={onSelect(choice)}>
            {getValueName(choice)}
        </ListItem>
    );
};

const CLASSNAME = 'lumx-select-field-simple';
/**
 * Component that groups a select and a list in order to display a list of items that can be
 * individually selected.
 *
 * @deprecated If possible, prefer usage of `SelectTextField` with `selectionType="single"`. This new component
 * uses the combobox accessible pattern.
 * Be aware that `SelectTextField` implements only the `input` `selectVariant` with the search feature.
 * It should now be the default design.
 * @family Combobox
 * @param props SelectFieldSimpleProps
 * @returns SelectFieldSimple
 */
export function SelectFieldSimple<V>(props: SelectFieldSimpleProps<V>) {
    const {
        label,
        value,
        choices = [],
        isOpen,
        setOpen,
        onChange,
        getValueId = String,
        getValueName = String,
        selectVariant = SelectVariant.input,
        onSelectClear,
        choiceRenderer = defaultChoiceRender,
        className = CLASSNAME,
        isRequired = false,
        icon,
        selectProps,
        isLoading = false,
        hasLoadingError = false,
        isDisabled = false,
        onRetryLoading,
        searchText,
        onSearch,
        searchPlaceholder,
        searchFieldProps = {},
        isLoadingMore,
        onInfiniteScroll,
    } = props;
    const { translate, translateKey } = useTranslate();
    const searchFieldRef = React.useRef<HTMLInputElement>(null);
    const listRef = React.useRef<HTMLUListElement | null>(null);
    const { element } = useClassnames(CLASSNAME);

    useFocus(isOpen && searchFieldRef);

    const toggleSelect = () => setOpen(!isOpen);
    const closeSelect = () => isOpen && setOpen(false);

    const onSelect = (choice: V) => () => {
        onChange(choice);
        setOpen(false);
    };

    let renderedChoices =
        choices.length === 0 ? (
            <FlexBox orientation={Orientation.vertical} vAlign={Alignment.center}>
                <InputHelper>{translate(GLOBAL.NO_RESULTS)}</InputHelper>
            </FlexBox>
        ) : (
            choices.map((choice) => {
                const isSelected = !!value && getValueId(value) === getValueId(choice);
                return choiceRenderer(choice, isSelected, onSelect, getValueName, getValueId);
            })
        );

    if (isLoading) {
        renderedChoices = <OptionsLoadingState />;
    } else if (hasLoadingError) {
        renderedChoices = <ServiceNotAvailableState onRetry={onRetryLoading} className={padding('all', 'regular')} />;
    } else if (React.Children.count(renderedChoices) <= 0) {
        renderedChoices = <NoResultsState searchValue={searchText} />;
    }

    const onScrollBottomReached = () => {
        if (onInfiniteScroll) {
            onInfiniteScroll();
        }
    };

    const heightList = useFixedHeightList({
        totalChoices: choices.length,
        hasHeader: Boolean(onSearch),
        shouldCalculateHeight: Boolean(onInfiniteScroll),
    });

    let clearButtonProps;

    /**
     * If `onSelectClear` is provided but no `clearButtonProps`, we default to
     * something basic so that the clear button appears in any case.
     */
    if (selectProps && !selectProps.clearButtonProps && onSelectClear) {
        clearButtonProps = {
            label: translateKey(GLOBAL.CLEAR),
        };
    }

    return (
        <Select
            isOpen={isOpen}
            label={label}
            icon={icon}
            value={value as any}
            onDropdownClose={closeSelect}
            onInputClick={toggleSelect}
            selectedValueRender={getValueName as any}
            variant={selectVariant}
            onClear={onSelectClear}
            className={className}
            isRequired={isRequired}
            isDisabled={isDisabled}
            closeOnClick={onSearch ? false : selectProps?.closeOnClick}
            clearButtonProps={clearButtonProps}
            {...selectProps}
        >
            <List
                isClickable
                className={element('list')}
                style={{
                    height: !isLoading ? `${heightList}px` : undefined,
                }}
            >
                {onSearch && (
                    <>
                        <ListSubheader>
                            <SearchField
                                onSearch={onSearch}
                                debounced
                                {...searchFieldProps}
                                textFieldProps={{
                                    ...searchFieldProps?.textFieldProps,
                                    ref: searchFieldRef,
                                }}
                                wrapperProps={{
                                    className: element('search-field'),
                                }}
                                label={searchPlaceholder || translateKey(GLOBAL.SEARCH)}
                                value={searchText ?? ''}
                            />
                        </ListSubheader>
                        <ListDivider role="presentation" />
                    </>
                )}
                {renderedChoices}

                {onInfiniteScroll ? (
                    <InfiniteScroll
                        callback={onScrollBottomReached}
                        options={{
                            root: listRef.current,
                        }}
                    />
                ) : null}

                {isLoadingMore ? <OptionsLoadingState /> : null}
            </List>
        </Select>
    );
}
SelectFieldSimple.displayName = 'SelectFieldSimple';
