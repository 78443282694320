import React from 'react';

import { articleContainerIdSelector } from '@lumapps/articles/ducks/selectors';
import { eventContainerIdSelector } from '@lumapps/events/ducks/selectors';
import { mdiCellphone, mdiLaptop } from '@lumapps/lumx/icons';
import { usePlayUser } from '@lumapps/play-roles';
import { UploadCorporateVideoDialog } from '@lumapps/play-video-library/components/UploadCorporateVideoDialog';
import { UploadingCorporateVideoFormDialog } from '@lumapps/play-video-library/components/UploadingCorporateVideoFormDialog';
import { useCreateVideo } from '@lumapps/play-video-library/hooks/useCreateVideo/useCreateVideo';
import { useUploadVideo } from '@lumapps/play-video-library/hooks/useUploadVideo';
import { UploadingVideo, Video } from '@lumapps/play/api/types';
import { isPlayVideoEnabled } from '@lumapps/play/ducks/selectors';
import { editedPostSelector } from '@lumapps/posts/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useResponsive } from '@lumapps/responsive';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { UploadEmployeeVideoDialog } from '../../components/UploadEmployeeVideoDialog';
import { isPlayCommunitiesFeatureEnabled } from '../../ducks/selectors';
import { COMMUNITIES } from '../../keys';
import type { CommunityVideo } from '../../types';

export interface UseVideoUploadButtonProps {
    onInsert(video: CommunityVideo): void;
}

export const useVideoUploadButton = ({ onInsert }: UseVideoUploadButtonProps) => {
    const { isMobile } = useResponsive();

    const isPlayCommunitiesEnabled = useSelector(isPlayCommunitiesFeatureEnabled);
    const isPlayEnabled = useSelector(isPlayVideoEnabled);

    const articleContainerId = useSelector(articleContainerIdSelector);
    const eventContainerId = useSelector(eventContainerIdSelector);
    const editedPost = useSelector(editedPostSelector);

    /** The community ID will determine if we display the employee or corporate upload  */
    const communityId = React.useMemo(
        () => (editedPost && editedPost?.externalKey) || articleContainerId || eventContainerId,
        [articleContainerId, editedPost, eventContainerId],
    );

    const { isAllowedManagingVideos } = usePlayUser();

    const [isEmployeeVideoUploadDialogOpen, , closeEmployeeVideoUploadDialog, openEmployeeVideoUploadDialog] =
        useBooleanState(false);
    const [isCorporateVideoUploadDialogOpen, , closeCorporateVideoUploadDialog, openCorporateVideoUploadDialog] =
        useBooleanState(false);
    const [
        isCorporateVideoUploadingDialogOpen,
        ,
        closeCorporateVideoUploadingDialog,
        openCorporateVideoUploadingDialog,
    ] = useBooleanState(false);

    const [createVideoError, setCreateVideoError] = React.useState<string>();

    const [videoFile, setVideoFile] = React.useState<File>();

    const onCreateVideoSuccess = () => {
        closeCorporateVideoUploadDialog();
        openCorporateVideoUploadingDialog();
        setCreateVideoError(undefined);
    };

    const clearFileData = () => {
        setVideoFile(undefined);
    };

    const { createdVideo, upload } = useCreateVideo({
        /* We don't create a video for employee videos it is handled inside the component UploadEmployeeVideoDialog */
        videoFile: communityId ? undefined : videoFile,
        videoFromDrive: undefined,
        clearFileData,
        onSuccess: onCreateVideoSuccess,
        setCreateVideoError,
    });

    // ⚠️ It is mandatory to memoized this function otherwise we will get abusive rerender
    const onUpload = React.useCallback(
        (file: File) => {
            setVideoFile(file);
            if (communityId) {
                openEmployeeVideoUploadDialog();
            } else {
                openCorporateVideoUploadingDialog();
            }
        },
        [communityId, openCorporateVideoUploadingDialog, openEmployeeVideoUploadDialog],
    );

    const { openPicker, hiddenInput: UploadInput } = useUploadVideo({ onUpload, inputId: 'video' });

    const uploadButtonProps = React.useMemo(
        () => ({
            icon: isMobile ? mdiCellphone : mdiLaptop,
            label: isMobile ? COMMUNITIES.UPLOAD_VIDEO_FROM_DEVICE : COMMUNITIES.UPLOAD_VIDEO_FROM_COMPUTER,
            onSelected: communityId ? openPicker : openCorporateVideoUploadDialog,
        }),
        [communityId, isMobile, openCorporateVideoUploadDialog, openPicker],
    );

    const handleSubmit = React.useCallback(
        (video: CommunityVideo) => {
            setVideoFile(undefined);
            onInsert(video);
            closeEmployeeVideoUploadDialog();
        },
        [closeEmployeeVideoUploadDialog, onInsert],
    );

    const handleVideoSave = (video: Video | UploadingVideo) => {
        onInsert({ ...video, communityId: '', source: 'web' });
        closeCorporateVideoUploadingDialog();
    };

    const UploadEmployeeDialog = React.useMemo(
        () =>
            videoFile ? (
                <UploadEmployeeVideoDialog
                    isOpen={isEmployeeVideoUploadDialogOpen}
                    videoFile={videoFile}
                    communityId={communityId}
                    onCancel={closeEmployeeVideoUploadDialog}
                    onSubmit={handleSubmit}
                />
            ) : null,
        [closeEmployeeVideoUploadDialog, communityId, handleSubmit, isEmployeeVideoUploadDialogOpen, videoFile],
    );

    const UploadCorporateDialog = (
        <>
            <UploadCorporateVideoDialog
                isOpen={isCorporateVideoUploadDialogOpen}
                onCancel={closeCorporateVideoUploadDialog}
                onDrop={onUpload}
                createVideoError={createVideoError}
            />
            <UploadingCorporateVideoFormDialog
                isOpen={Boolean(isCorporateVideoUploadingDialogOpen && createdVideo.id)}
                onClose={() => {
                    closeCorporateVideoUploadingDialog();
                    setVideoFile(undefined);
                    setCreateVideoError(undefined);
                }}
                onSave={handleVideoSave}
                videoFile={videoFile}
                video={createdVideo}
                upload={upload}
            />
        </>
    );

    if (isPlayEnabled && isPlayCommunitiesEnabled) {
        /* When a communityId is provided we use the employee upload dialog */
        if (communityId) {
            return {
                uploadButtonProps,
                UploadDialog: UploadEmployeeDialog,
                UploadInput,
                isUploadDialogOpen: isEmployeeVideoUploadDialogOpen,
            };
        }

        /* When there is no communityId and we can manage the videos, we use the corporate upload dialog */
        if (isAllowedManagingVideos) {
            return {
                uploadButtonProps,
                UploadDialog: UploadCorporateDialog,
                UploadInput,
                isUploadDialogOpen: Boolean(isCorporateVideoUploadingDialogOpen && createdVideo.id),
            };
        }
    }

    return {
        uploadButtonProps: undefined,
        UploadDialog: undefined,
        UploadInput: undefined,
        isUploadDialogOpen: false,
    };
};
