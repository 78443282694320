import {
    UPLOADER_ACCEPT_AUDIO_TYPES,
    UPLOADER_ACCEPT_IMAGE_TYPES,
    UPLOADER_ACCEPT_MIME_TYPES,
    UPLOADER_ACCEPT_SUBTITLES_TYPES,
    UPLOADER_ACCEPT_VIDEO_TYPES,
    UPLOADER_MKV_FILE_TYPE,
    UPLOADER_MOV_FILE_TYPE,
    UPLOADER_MP3_FILE_TYPE,
    UPLOADER_OGG_FILE_TYPE,
    UPLOADER_SRT_FILE_TYPE,
    UPLOADER_VTT_FILE_TYPE,
} from '../constants';
import { UploadType, type FileSize } from '../types';

/**
 * Converts a human readable filesize into bytes.
 *
 * @param {string} size Human readable file size
 *
 * @returns {number} Size in bytes.
 *
 * @example humanReadableSizeToBytes('20MB');
 */
export const humanReadableSizeToBytes = (size: string) => {
    const sizeString = `${size}`.toString().toUpperCase();
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const matches = sizeString.match(/([\d|.]+?)\s*?([A-Z]+)/);

    if (!matches) {
        return parseFloat(sizeString);
    }

    const exponent = units.indexOf(matches[2]);

    if (exponent === -1) {
        return parseFloat(sizeString);
    }

    return parseFloat(matches[1]) * 1024 ** exponent;
};

/**
 * @param type - The type of file to upload : 'video', 'audio', 'image', or 'subtitle'
 * @returns the accepted file types
 */
export const getAcceptedTypes = (type: UploadType) => {
    switch (type) {
        case UploadType.image:
            return UPLOADER_ACCEPT_IMAGE_TYPES;
        case UploadType.subtitle:
            return UPLOADER_ACCEPT_SUBTITLES_TYPES;
        case UploadType.podcast:
            return UPLOADER_ACCEPT_AUDIO_TYPES;
        case UploadType.video:
            return UPLOADER_ACCEPT_VIDEO_TYPES;
        default:
            return UPLOADER_ACCEPT_MIME_TYPES;
    }
};

/**
 * @param file - The file to upload'
 * @param type - The type of file to upload : 'video', 'audio', 'image', or 'subtitle'
 * @returns {boolean} if mime type is valid
 */
export const fileMimeTypeIsValid = (file: File, type: UploadType) => {
    const acceptedType = getAcceptedTypes(type).replace(/\s/g, '').split(',');

    let fileType = file.type;

    /**
     * Ovveride a specific subtitle type to handle .srt and .vtt
     * files not recognized on some OS
     */
    if (file.name.includes(UPLOADER_VTT_FILE_TYPE) || file.name.includes(UPLOADER_SRT_FILE_TYPE)) {
        fileType = UPLOADER_ACCEPT_SUBTITLES_TYPES;
    }

    /**
     * Override a specific podcast type to handle .mp3 and .ogg
     * files not recognized on some OS
     */
    if (file.name.includes(UPLOADER_MP3_FILE_TYPE) || file.name.includes(UPLOADER_OGG_FILE_TYPE)) {
        fileType = UPLOADER_ACCEPT_AUDIO_TYPES;
    }

    /**
     * Overide a specific video type to handle .mkv and .mov
     * files not recognized on some OS
     */
    if (file.name.includes(UPLOADER_MKV_FILE_TYPE) || file.name.includes(UPLOADER_MOV_FILE_TYPE)) {
        fileType = UPLOADER_ACCEPT_VIDEO_TYPES;
    }

    return acceptedType.some((accept) => {
        const regex = new RegExp(accept.replace('*', '.*'));
        return regex.test(fileType);
    });
};

/**
 * @param file - The file to upload'
 * @param maxSize - The file maximum size allowed'
 * @returns {boolean} If file size is valid
 */
export const fileMaxSizeIsValid = (file: File, maxSize: FileSize) => {
    return file.size <= humanReadableSizeToBytes(maxSize);
};
