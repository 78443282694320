/* istanbul ignore file */
import { Route, AppId, createPageRoute } from '@lumapps/router';

export const contentTagAdminRoute: Route = createPageRoute({
    slug: 'content-type/:uid/tags',
    legacyId: 'app.admin.tag',
    appId: AppId.backOffice,
});

export const adminContentTag = (uid: string): Route => ({
    ...contentTagAdminRoute,
    params: { customContentType: uid, uid },
});
