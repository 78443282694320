import React, { ReactNode } from 'react';

import uniqueId from 'lodash/uniqueId';

import { classnames, separator, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import {
    ColorPalette,
    ColorVariant,
    FlexBox,
    FlexBoxProps,
    Heading,
    HeadingProps,
    Orientation,
    Size,
    Text,
    TextProps,
    Theme,
    Typography,
} from '@lumapps/lumx/react';

import { FORM_SUBSECTION_VERTICAL_ORIENTATION_SIZES } from '../../constants';

import './index.scss';

export interface FormSubSectionProps {
    /** a className to override existing style */
    className?: string;
    /** some props to override the wrapper containing children */
    contentWrapperProps?: FlexBoxProps;
    /** ref of the top container to handle orientation/display change */
    containerRef: any; // any needed to match useDimension type
    /** text to be displayed under the title, in the left part */
    description?: string | ReactNode;
    /** helper text or component to be displayed below the description */
    helper?: string | ReactNode;
    /** HTML id of the sub-section, can be used for tracking if needed */
    id?: string;
    /** title to be displayed on top in the left part */
    title?: string;
    /** some props to override the top wrapper */
    topWrapperProps?: FlexBoxProps;
    /** whether the sub-section has a separator below or not */
    withSeparator?: boolean;
    /** whether the form is displayed horizontally (title on the left, fields on the right) or vertically */
    orientation?: Orientation;
    /** current size for the form  */
    size?: Size;
    /** additional props for the title */
    titleProps?: Partial<HeadingProps>;
    /** additional props for the description */
    descriptionProps?: Partial<TextProps>;
    /** theme */
    theme?: Theme;
    /** optional scope for tracking purposes */
    scope?: string;
    children?: ReactNode;
}

const CLASSNAME = 'form-sub-section';

/**
 * A simple component to be used in a form as a sub section, with a title and description on the left side
 * and given children (mostly form inputs) on the right side
 *
 * @family Forms
 * @param FormSubSectionProps
 * @returns FormSubSection
 */
export const FormSubSection: React.FC<FormSubSectionProps> = ({
    children,
    className,
    containerRef,
    contentWrapperProps = {},
    description,
    helper,
    id,
    title,
    topWrapperProps = {},
    descriptionProps = {},
    scope = CLASSNAME,
    titleProps = {
        as: 'h2',
    },
    withSeparator = true,
    orientation = Orientation.horizontal,
    size,
    theme = Theme.light,
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(scope);
    const isCompact = size
        ? FORM_SUBSECTION_VERTICAL_ORIENTATION_SIZES.includes(size) && orientation === Orientation.vertical
        : false;
    const titleId = id ? `${id}-${uniqueId()}` : `${CLASSNAME}-${uniqueId()}`;
    const fontColor = theme === Theme.dark ? ColorPalette.light : ColorPalette.dark;
    const variantColor = theme === Theme.dark ? ColorVariant.D2 : ColorVariant.N;

    return (
        <FlexBox
            id={id}
            gap={Size.huge}
            ref={containerRef}
            orientation={orientation}
            {...topWrapperProps}
            className={classnames(
                block({
                    [`${size}`]: Boolean(size),
                    [`${orientation}`]: Boolean(orientation),
                    compact: isCompact,
                }),
                className,
                {
                    [separator()]: withSeparator,
                },
            )}
        >
            {/* Left part */}
            {title ? (
                <FlexBox
                    orientation={Orientation.vertical}
                    gap={orientation === Orientation.vertical ? Size.tiny : Size.regular}
                    className={element('detail', {
                        horizontal: orientation === Orientation.horizontal,
                        [`horizontal--${size}`]: orientation === Orientation.horizontal,
                        compact: isCompact,
                    })}
                >
                    <Heading
                        className={element('title')}
                        typography={Typography.title}
                        id={titleId}
                        color={fontColor}
                        colorVariant={variantColor}
                        {...get({ element: 'title' })}
                        {...titleProps}
                    >
                        {title}
                    </Heading>

                    {description ? (
                        <Text
                            className={element('description')}
                            typography={Typography.body1}
                            as="p"
                            color={fontColor}
                            colorVariant={variantColor}
                            {...get({ element: 'description' })}
                            {...descriptionProps}
                        >
                            {description}
                        </Text>
                    ) : null}

                    {helper}
                </FlexBox>
            ) : null}

            {/* Right part */}
            <FlexBox
                as="fieldset"
                {...get({ element: 'fieldset' })}
                {...contentWrapperProps}
                aria-labelledby={titleId}
                gap={contentWrapperProps.gap || Size.big}
                orientation={contentWrapperProps.orientation || 'vertical'}
                className={element('content', { compact: isCompact }, [contentWrapperProps.className])}
            >
                {children}
            </FlexBox>
        </FlexBox>
    );
};
