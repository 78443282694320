/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { canAccessSearchSettings, canAccessCoveoSearchSettings } from '../../ducks/selectors';
import {
    SearchSettingsSideNavigation as SearchSettingsSideNavigationUI,
    SearchSettingsSideNavigationProps,
} from './SearchSettingsSideNavigation';

const mapStateToProps = (state: BackOfficeStore): Omit<SearchSettingsSideNavigationProps, 'id'> => ({
    canAdminNS: canAccessSearchSettings(state),
    canAdminCoveo: canAccessCoveoSearchSettings(state),
});

const Connected = connect(mapStateToProps)(SearchSettingsSideNavigationUI);
Connected.displayName = 'SideNavigationItem';

export { Connected as SearchSettingsSideNavigation };
