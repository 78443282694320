import React from 'react';

import throttle from 'lodash/throttle';

import { useInfiniteQuery } from '@lumapps/base-api/react-query';
import { useClassnames } from '@lumapps/classnames';
import { ComboboxOptionSkeleton } from '@lumapps/combobox/components/ComboboxOption/ComboboxOptionSkeleton';
import { SelectTextField, type SelectTextFieldProps } from '@lumapps/combobox/components/SelectTextField';
import { get as getConfig } from '@lumapps/constants';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiAccountPlus } from '@lumapps/lumx/icons';
import { Avatar, type AvatarProps, Chip, SkeletonCircle } from '@lumapps/lumx/react';
import { list, userQueryKeys } from '@lumapps/user/api';
import { type UserListRequestParams, type User } from '@lumapps/user/types';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';
import { getAvatarUrl } from '@lumapps/user/utils/getUserProfilePicture';
import { isComponentType } from '@lumapps/utils/types/isComponentType';

import './index.scss';

const { customerId } = getConfig();

type InheritedSelectTextFieldProps = Pick<
    SelectTextFieldProps<User>,
    | 'selectionType'
    | 'label'
    | 'placeholder'
    | 'helper'
    | 'error'
    | 'isDisabled'
    | 'isRequired'
    | 'className'
    | 'value'
    | 'onChange'
    | 'openOnFocus'
    | 'renderOption'
    | 'getOptionName'
    | 'getOptionId'
    | 'onSearch'
    | 'listProps'
    | 'inputRef'
    | 'theme'
>;

const CLASSNAME = 'lumx-user-picker-field';

const SCOPE = 'user-picker';

const DEFAULT_FETCH_PARAMS: UserListRequestParams = {
    maxResults: 20,
    showHidden: false,
    status: 'ENABLED',
    queryFields: ['fullName', 'partialSearch', 'emails'],
};

export interface UserPickerFieldProps extends InheritedSelectTextFieldProps {
    /** Scope of the data attributes (defaults to "user-picker") */
    scope?: string;
    /** Customized user list params */
    fetchParams?: UserListRequestParams;
    /** Field icon (set to null to remove the default one) */
    icon?: SelectTextFieldProps['icon'] | null;
    /** Customize the option avatar size (defaults to `m`) */
    optionAvatarSize?: AvatarProps['size'];
}

/**
 * User picker field
 *
 * @family Pickers
 */
export const UserPickerField = (props: UserPickerFieldProps) => {
    const {
        className,
        scope,
        renderOption,
        listProps,
        fetchParams,
        error,
        icon,
        optionAvatarSize = 'm',
        ...forwardedProps
    } = props;
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(scope || SCOPE);
    const [fetchEnabled, setFetchEnabled] = React.useState(false);
    const enableFetch = React.useCallback(() => setFetchEnabled(true), []);

    const [listParams, setListParams] = React.useState(() => ({
        ...DEFAULT_FETCH_PARAMS,
        ...fetchParams,
        searchTerm: '',
    }));

    const updateSearchTerm = React.useMemo(() => {
        return throttle((searchTerm: string) => {
            setListParams((prevParams) => ({ ...prevParams, searchTerm }));
        }, 300);
    }, []);

    const query = useInfiniteQuery({
        enabled: fetchEnabled,
        queryKey: userQueryKeys.list(listParams),
        queryFn: ({ pageParam: cursor }) => list({ ...listParams, cursor }),
        getNextPageParam: (page) => page.data.cursor,
        staleTime: 100_000,
    });

    const loadingStatus = query.baseLoadingStatus;
    const options = React.useMemo(() => query.data?.pages.flatMap((p) => p.data.items), [query.data?.pages]);

    return (
        <SelectTextField<User>
            getOptionName={getUserFullName}
            getOptionId="id"
            {...(forwardedProps as any)}
            {...get({ element: 'autocomplete' })}
            icon={icon || mdiAccountPlus}
            status={loadingStatus}
            className={block([className])}
            showEmptyState
            showErrorState
            error={error}
            hasError={!!error}
            onLoadMore={query.hasNextPage ? query.fetchNextPage : undefined}
            onSearch={updateSearchTerm}
            autoFilter={false}
            onFocus={enableFetch}
            renderChip={(user) => (
                <Chip before={<Avatar size={'xxs' as any} image={getAvatarUrl(user.id, customerId)} alt="" />}>
                    {getUserFullName(user)}
                </Chip>
            )}
            options={options}
            listProps={{
                renderItemSkeleton: (
                    <ComboboxOptionSkeleton
                        className={element('option-skeleton')}
                        before={<SkeletonCircle size={optionAvatarSize} />}
                    />
                ),
                ...listProps,
                ...get({ element: 'suggestions-list' }),
            }}
            renderOption={(user, index) => {
                let baseOption: React.ReactElement | undefined;
                if (renderOption) {
                    baseOption = renderOption(user, index) as React.ReactElement;
                    if (!isComponentType(SelectTextField.Option, baseOption)) {
                        // Invalid custom render
                        return null;
                    }
                }

                return (
                    <SelectTextField.Option
                        {...(baseOption?.props as any)}
                        {...get({ element: 'suggestions-list-item', position: index + 1 })}
                        className={element('option', [baseOption?.props.className])}
                        before={
                            <Avatar
                                size={optionAvatarSize}
                                image={getAvatarUrl(user.id, user.customer || customerId)}
                                alt=""
                            />
                        }
                    >
                        {getUserFullName(user)}
                    </SelectTextField.Option>
                );
            }}
        />
    );
};

UserPickerField.Option = SelectTextField.Option;
