import React from 'react';

import { classnames } from '@lumapps/classnames';
import { HeadingLevelProvider, Heading, HeadingProps } from '@lumapps/lumx/react';
import { ALIGNMENTS, ALIGNMENT_CLASSES } from '@lumapps/wrex/constants';
import type { ElementRender } from '@lumapps/wrex/types';

import { SUBTITLE } from '../../../constants';
import type { SubtitleElement } from '../../../types';

import './index.scss';

export const Subtitle: ElementRender<SubtitleElement, HTMLHeadingElement> = (props) => {
    const { children, className, elementRef, element, ...forwardedProps } = props;
    const alignment = element?.alignment ? element.alignment : ALIGNMENTS.start;

    return (
        <HeadingLevelProvider>
            <HeadingLevelProvider>
                <Heading
                    {...(forwardedProps as HeadingProps)}
                    className={classnames(className, 'wrex-content-subtitle', ALIGNMENT_CLASSES[alignment])}
                    ref={elementRef}
                >
                    {children}
                </Heading>
            </HeadingLevelProvider>
        </HeadingLevelProvider>
    );
};
Subtitle.displayName = SUBTITLE;
