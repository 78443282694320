import { Route, AppId, createPageRoute } from '@lumapps/router';

export const customerAdvancedSettingsRoute: Route = createPageRoute({
    slug: 'platform/advanced-settings',
    appId: AppId.backOffice,
});

export const globalAdminsRoute: Route = createPageRoute({
    slug: 'platform/global-administrators',
    id: 'super-admin',
    appId: AppId.backOffice,
});
