import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import cond from 'lodash/fp/cond';
import flow from 'lodash/fp/flow';
import identity from 'lodash/fp/identity';
import map from 'lodash/fp/map';
import stubTrue from 'lodash/fp/stubTrue';

import { CalendarEvent, CalendarViews } from '@lumapps/lumx-calendar/types';
import { TranslationAPI } from '@lumapps/translations';

import { LegacyCalendar, LegacyCalendarEvent } from '../types';
import { mapLegacyEvent } from './mapLegacyEvent';
import { replicateMultipleDayEvents } from './replicateMultipleDayEvents';

/**
 * Extract all events from the legacy calendar API call's
 * then map the events to the new CalendarEvent format.
 * When in Scheduled view, we also apply a formater in order to replicate multiple days events.
 * */
export const concatEvents = (
    responses: LegacyCalendarEvent[],
    selectedCalendars: LegacyCalendar[],
    userCalendarColor: string,
    view: CalendarViews,
    selectedDate: Date,
    translateAndReplace: TranslationAPI['translateAndReplace'],
): CalendarEvent[] => {
    return flow(
        flatten,
        compact,
        map(mapLegacyEvent(selectedCalendars, userCalendarColor)),
        cond([
            [() => view === CalendarViews.schedule, replicateMultipleDayEvents(selectedDate, translateAndReplace)],
            [stubTrue, identity],
        ]),
    )(responses);
};
