import React from 'react';

import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';

import { ShareButton, type ShareButtonProps } from '../ShareButton';

export type { ShareButtonProps };

interface ShareMenuButtonProps extends ShareButtonProps {
    buttonRef?: React.Ref<HTMLButtonElement>;
    children: React.ReactNode;
}

/** Base share menu button. */
const BaseShareMenuButton: React.FC<ShareMenuButtonProps> = ({ buttonRef, children, ...forwardedProps }) => (
    <Menu.Trigger popoverWidth={{ minWidth: REAL_SIZE_FOR_LUMX_SIZE.xxl }}>
        <Menu.TriggerButton ref={buttonRef} as={ShareButton} {...forwardedProps} />
        <Menu>{children}</Menu>
    </Menu.Trigger>
);

export const ShareMenuButton = Object.assign(BaseShareMenuButton, { Section: Menu.Section });
