import { AI_GENERATED_CONTENT } from './keys';

export const GENERATIVE_CONTENT = 'generative-content';
export const GENERATIVE_AI_FEATURE_TOKEN = 'generative-ai';

export interface PromptHelpers {
    [key: string]: {
        resume: string;
        prompt: string;
        dataId: string;
    };
}

export interface RefinePromptHelpers extends PromptHelpers {
    [key: string]: {
        resume: string;
        prompt: string;
        refinePrompt: string;
        dataId: string;
    };
}

export const SUGGESTED_PROMPTS: PromptHelpers = {
    START_WRITING_ABOUT: {
        resume: AI_GENERATED_CONTENT.PROMPT_SUGGESTION_LABEL_WRITING_ABOUT,
        prompt: AI_GENERATED_CONTENT.PROMPT_SUGGESTION_RESULT_WRITING_ABOUT,
        dataId: 'start-writing-about',
    },
    ARTICLE: {
        resume: AI_GENERATED_CONTENT.CHIP_ARTICLE,
        prompt: AI_GENERATED_CONTENT.PROMPT_ARTICLE,
        dataId: 'article',
    },
    INTERNAL_COMMUNICATION: {
        resume: AI_GENERATED_CONTENT.CHIP_INTERNAL_COMMUNICATION,
        prompt: AI_GENERATED_CONTENT.PROMPT_INTERNAL_COMMUNICATION,
        dataId: 'internal-communication',
    },
    JOB_OFFER: {
        resume: AI_GENERATED_CONTENT.CHIP_JOB_OFFER,
        prompt: AI_GENERATED_CONTENT.PROMPT_JOB_OFFER,
        dataId: 'job-offer',
    },
    TO_DO_LIST: {
        resume: AI_GENERATED_CONTENT.CHIP_TO_DO_LIST,
        prompt: AI_GENERATED_CONTENT.PROMPT_TO_DO_LIST,
        dataId: 'todo-list',
    },
    PLAN: {
        resume: AI_GENERATED_CONTENT.CHIP_PLAN,
        prompt: AI_GENERATED_CONTENT.PROMPT_PLAN,
        dataId: 'plan',
    },
};

export const SUGGESTED_FORMALITIES: RefinePromptHelpers = {
    CASUAL: {
        resume: AI_GENERATED_CONTENT.CHIP_FORMALITY_CASUAL,
        prompt: AI_GENERATED_CONTENT.PROMPT_FORMALITY_CASUAL,
        refinePrompt: 'Make this text more casual.',
        dataId: 'casual',
    },
    NEUTRAL: {
        resume: AI_GENERATED_CONTENT.CHIP_FORMALITY_NEUTRAL,
        prompt: AI_GENERATED_CONTENT.PROMPT_FORMALITY_NEUTRAL,
        refinePrompt: 'Make this text more neutral.',
        dataId: 'neutral',
    },
    FORMAL: {
        resume: AI_GENERATED_CONTENT.CHIP_FORMALITY_FORMAL,
        prompt: AI_GENERATED_CONTENT.PROMPT_FORMALITY_FORMAL,
        refinePrompt: 'Make this text more formal.',
        dataId: 'formal',
    },
};

export const SUGGESTED_TONES: RefinePromptHelpers = {
    PROFESSIONAL: {
        resume: AI_GENERATED_CONTENT.CHIP_TONE_PROFESSIONAL,
        prompt: AI_GENERATED_CONTENT.PROMPT_TONE_PROFESSIONAL,
        refinePrompt: 'Make this text more professional.',
        dataId: 'professional',
    },
    HUMOUROUS: {
        resume: AI_GENERATED_CONTENT.CHIP_TONE_HUMOUROUS,
        prompt: AI_GENERATED_CONTENT.PROMPT_TONE_HUMOUROUS,
        refinePrompt: 'Make this text more humourous.',
        dataId: 'humourous',
    },
    FRIENDLY: {
        resume: AI_GENERATED_CONTENT.CHIP_TONE_FRIENDLY,
        prompt: AI_GENERATED_CONTENT.PROMPT_TONE_FRIENDLY,
        refinePrompt: 'Make this text more friendly.',
        dataId: 'friendly',
    },
    DIRECT: {
        resume: AI_GENERATED_CONTENT.CHIP_TONE_DIRECT,
        prompt: AI_GENERATED_CONTENT.PROMPT_TONE_DIRECT,
        refinePrompt: 'Make this text more direct.',
        dataId: 'direct',
    },
    SUPPORTIVE: {
        resume: AI_GENERATED_CONTENT.CHIP_TONE_SUPPORTIVE,
        prompt: AI_GENERATED_CONTENT.PROMPT_TONE_SUPPORTIVE,
        refinePrompt: 'Make this text more supportive.',
        dataId: 'supportive',
    },
    POSITIVE: {
        resume: AI_GENERATED_CONTENT.CHIP_TONE_POSITIVE,
        prompt: AI_GENERATED_CONTENT.PROMPT_TONE_POSITIVE,
        refinePrompt: 'Make this text more positive.',
        dataId: 'positive',
    },
    CONFIDENT: {
        resume: AI_GENERATED_CONTENT.CHIP_TONE_CONFIDENT,
        prompt: AI_GENERATED_CONTENT.PROMPT_TONE_CONFIDENT,
        refinePrompt: 'Make this text more confident.',
        dataId: 'confident',
    },
};
