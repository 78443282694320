import React from 'react';

import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';

import { BaseApiPromise, ServerListResponse } from '@lumapps/base-api/types';
import { getMultipleMetadata } from '@lumapps/metadata/api';
import { InstanceListMetadataRequest } from '@lumapps/metadata/api/instanceMetadataApi';
import { Metadata } from '@lumapps/metadata/types';
import { useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';
import { useServerList } from '@lumapps/utils/hooks/useServerList';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

type MetadataParams = InstanceListMetadataRequest;

export interface UseMetadataListWithSelectedOptions {
    /** metadata query params */
    params: MetadataParams;
    /** endpoint to use for retrieving metadatas */
    getMetadata: (...params: any[]) => BaseApiPromise<ServerListResponse<Metadata>>;
    /** list of already selected metadatas */
    selectedMetadatas?: string[];
    /** whether the metadatas should be fetched when this hook is mounted */
    fetchOnMount?: boolean;
    /** Retrieve metadata restricted to one of those content types, empty by default, all unrestricted metadata are returned */
    contentTypes?: string[];
}

export interface UseMetadataListWithSelected {
    /** parent metadata grouped by their id */
    parentMetadataGroupedById: Record<string, Metadata>;
    /** selected metadatas that correspond to the `selectedMetadatas` ids */
    selectedMetadatas: Record<string, Metadata | Metadata[]>;
    /** list of parent metadatas */
    parentMetadatas: Metadata[];
    /** whether the metadatas are still loading or not */
    isLoading: boolean;
    /** whether there was an error while loading metadatas */
    hasError: boolean;
    /** whether metadatas have loaded or not */
    hasLoaded: boolean;
    /** callback for resetting the loaded data */
    reset: () => void;
    /** callback for resetting the selected metadata  */
    resetSelectedMetadata: () => void;
}

/**
 * Custom metadata hook that retrieves:
 * - A list of parent metadata from a given endpoint
 * - A list of selected metadata given their ids.
 * The use case, displaying a list of metadata that have values
 * @param UseMetadataListWithSelectedOptions
 * @returns UseMetadataListWithSelected
 */
export const useMetadataListWithSelected = ({
    params,
    getMetadata,
    selectedMetadatas = [],
    fetchOnMount = true,
    contentTypes,
}: UseMetadataListWithSelectedOptions): UseMetadataListWithSelected => {
    const {
        items: fetchedMetadataList = [],
        isLoading,
        hasLoadingError,
        status,
        reset,
    } = useServerList<Metadata, MetadataParams>({
        params,
        onFetch: getMetadata,
        initialFetchParams: {
            params,
        },
        fetchOnMount,
    });

    /**
     * Filter's out all metadata that are restricted for a specific content type, if that's content type is not required
     * */
    const items = contentTypes
        ? fetchedMetadataList.filter(
              (metadata) =>
                  isEmpty(metadata.customContentTypes) ||
                  metadata.customContentTypes.some((cct) => contentTypes?.includes(cct)),
          )
        : fetchedMetadataList;

    const areThereaAnySelectedMetadatas = selectedMetadatas.length > 0;

    const {
        response,
        status: selectedStatus,
        fetch,
        reset: resetSelectedMetadata,
    } = useFetchWithStatus({
        fetchOnMount: fetchOnMount && areThereaAnySelectedMetadatas,
        onFetch: getMultipleMetadata,
        initialFetchParams: {
            params: {
                ids: selectedMetadatas,
            },
        },
    });

    const parentMetadatas = items.filter((m) => (params.rootOnly ? !m.instance : !m.parent));
    const parentMetadataGroupedById = keyBy(parentMetadatas, 'id');

    /**
     * In case the `selectedMetadatas` change from outside of the hook, this use effect
     * will trigger a new search for them and update them if necessary.
     */
    React.useEffect(() => {
        if (fetchOnMount && selectedMetadatas && selectedMetadatas.length > 0) {
            fetch({
                params: {
                    ids: selectedMetadatas,
                },
            });
        }
    }, [fetch, fetchOnMount, selectedMetadatas]);

    /**
     * If there are any metadatas selected, it means that we executed the `getMultipleMetadata`
     * API and that we need to manage this hook's status by evaluating the `getMultipleMetadata`
     * as well as the `getMetadata` one. In the following lines we do that, if both calls
     * were made, we join the status in a single one. If not, we just evaluate the `getMetadata` call
     */
    const areMetadataIdle = areThereaAnySelectedMetadatas
        ? selectedStatus === 'idle' && status === 'idle'
        : status === 'idle';
    const areMetadataLoading = areThereaAnySelectedMetadatas
        ? selectedStatus === BaseLoadingStatus.loading || isLoading
        : isLoading;
    const hasError = areThereaAnySelectedMetadatas
        ? selectedStatus === BaseLoadingStatus.error || hasLoadingError
        : hasLoadingError;

    return {
        parentMetadataGroupedById,
        parentMetadatas,
        selectedMetadatas: groupBy(response?.items || [], 'familyKey'),
        isLoading: areMetadataLoading,
        hasError,
        hasLoaded: !areMetadataLoading && !hasError && areMetadataIdle,
        reset,
        resetSelectedMetadata,
    };
};
