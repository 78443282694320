import { AppId } from '@lumapps/constants/app';
import { createPageRoute, Route } from '@lumapps/router';

export const learningBaseRoute: Route = createPageRoute({
    slug: 'learning',
    appId: AppId.backOffice,
});

const learningCategoriesRoute: Route = createPageRoute({
    slug: 'learning/catalog/categories',
    appId: AppId.backOffice,
});

const learningTrainingCoursesRoute: Route = createPageRoute({
    slug: 'learning/catalog/training-courses',
    appId: AppId.backOffice,
});

const learningLearnersRoute: Route = createPageRoute({
    slug: 'learning/role-settings/learners',
    appId: AppId.backOffice,
});

const learningAdministratorsRoute: Route = createPageRoute({
    slug: 'learning/role-settings/administrators',
    appId: AppId.backOffice,
});

const learningCertificatesRoute: Route = createPageRoute({
    slug: 'learning/catalog/certificates',
    appId: AppId.backOffice,
});

export {
    learningCategoriesRoute,
    learningCertificatesRoute,
    learningAdministratorsRoute,
    learningTrainingCoursesRoute,
    learningLearnersRoute,
};
