/* istanbul ignore file */
import { baseAdminDirectory, BASE_DIRECTORY_URL } from '@lumapps/directories-back-office/routes';
import { Route, AppId, createPageRoute } from '@lumapps/router';

const directoryTagsRoutes: Route = createPageRoute({
    slug: `${BASE_DIRECTORY_URL}/:id/tags`,
    legacyId: 'app.admin.directory-tag',
    appId: AppId.backOffice,
});

const adminDirectoryTags = (id: string): Route => baseAdminDirectory(id, directoryTagsRoutes);

export { adminDirectoryTags, directoryTagsRoutes };
