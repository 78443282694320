import React from 'react';

import { useUpdateDriveFiles } from '@lumapps/integrations/hooks/useUpdateDriveFiles';
import { MediaSourceV2 } from '@lumapps/medias/types';
import { useSelector } from '@lumapps/redux/react';
import { accountTypeSelector } from '@lumapps/user/ducks/selectors';
import { UserAccountType } from '@lumapps/user/types';
import { Wrex } from '@lumapps/wrex/types';

import { FileElement } from './types';
import { getDriveFilesFromSlate } from './utils/getDriveFilesFromSlate';
import { mapGoogleDriveFileToSlateFile } from './utils/mapGoogleDriveFileToSlateFile';
import { mapOneDriveFileToSlateFile } from './utils/mapOneDriveFileToSlateFile';

interface ContextData {
    isFetching?: boolean;
    updatedFiles?: FileElement[];
    userProvider?: MediaSourceV2.GOOGLE_DRIVE | MediaSourceV2.MICROSOFT;
}

export const WrexFileContext = React.createContext<ContextData | undefined>(undefined);

interface WrexFileContextProviderProps {
    /** Component children */
    children: React.ReactNode;
    /** The slate content */
    content: Wrex.Nodes;
}

export const WrexFileContextProvider = ({ children, content }: WrexFileContextProviderProps) => {
    const userAccountType = useSelector(accountTypeSelector);
    const userProvider: MediaSourceV2.GOOGLE_DRIVE | MediaSourceV2.MICROSOFT | undefined = React.useMemo(() => {
        switch (userAccountType) {
            case UserAccountType.GOOGLE:
                return MediaSourceV2.GOOGLE_DRIVE;
            case UserAccountType.MICROSOFT:
                return MediaSourceV2.MICROSOFT;
            default:
                return undefined;
        }
    }, [userAccountType]);
    const { driveFiles } = getDriveFilesFromSlate(content, userProvider);

    const { data, isFetching } = useUpdateDriveFiles(
        {
            gDrive: {
                requestParam: {
                    filesId: driveFiles?.map((file) => file.resource?.id as string),
                    batchParams: {
                        fields: 'id,webContentLink,webViewLink,name,driveId,mimeType,createdTime,modifiedTime,trashed,explicitlyTrashed,thumbnailLink',
                    },
                },
            },
            oneDrive: {
                requestParam: {
                    files: driveFiles?.map((file) => ({
                        id: file.resource?.id as string,
                        url: file.src as string,
                        driveId: (file.resource as any).driveId as string,
                    })),
                    backupFiles: driveFiles,
                },
            },
        },
        { gDrive: mapGoogleDriveFileToSlateFile, oneDrive: mapOneDriveFileToSlateFile },
    );

    const contextValue = React.useMemo(() => {
        return {
            isFetching,
            updatedFiles: data as FileElement[],
            userProvider,
        };
    }, [data, isFetching, userProvider]);

    return <WrexFileContext.Provider value={contextValue}>{children}</WrexFileContext.Provider>;
};
