import React, { ReactElement, useMemo } from 'react';

import keyBy from 'lodash/keyBy';

import { Theme, ThemeProvider, useTheme } from '@lumapps/lumx/react';
import { Paragraph } from '@lumapps/wrex-typography/components/blocks/Paragraph';
import { isMention } from '@lumapps/wrex-user-mention/utils/isMention';

import { renderElements, renderLeafs } from '../../slate/render';
import { isText } from '../../slate/utils/isText';
import { ElementRender, MarkRender, RenderElementType, RenderLeafType, Wrex } from '../../types';

interface Props {
    /**
     * Slate content.
     */
    content: Wrex.Nodes;

    /**
     * Element renderer functions.
     */
    elements: ElementRender<any, any>[];

    /**
     * Mark renderer functions.
     */
    marks: MarkRender[];

    /**
     * Mention index.
     */
    mentionIndex?: Record<string, { fullName: string }>;

    /**
     * The Lumx theme.
     */
    theme?: Theme;
}

const render = (
    content: Wrex.Nodes,
    renderElement: RenderElementType,
    renderLeaf: RenderLeafType,
    path: number[],
    mentionIndex?: Record<string, { fullName: string }>,
): Array<ReactElement | undefined> => {
    return (content ?? []).map((node, idx) => {
        const newPath = [...path, idx];
        const attributes: any = { key: newPath.join('-') };

        if (isText(node)) {
            const text = node as Wrex.Text;
            return renderLeaf({
                text,
                leaf: text,
                attributes,
                children: text.text,
            });
        }

        const newNode = { ...node };

        if (mentionIndex && isMention(newNode)) {
            const userDetails = mentionIndex[newNode.user.id];
            newNode.user = {
                ...newNode.user,
                ...userDetails,
            };
        }

        return renderElement({
            element: newNode,
            attributes,
            children: render(node.children, renderElement, renderLeaf, newPath, mentionIndex),
        });
    });
};

/**
 * Render a slate content.
 */
export const ContentView: React.FC<Props> = (props) => {
    const defaultTheme = useTheme() || Theme.light;
    const { content, elements, marks, mentionIndex, theme = defaultTheme } = props;
    const renderElement = useMemo(() => renderElements(Paragraph, keyBy(elements, 'displayName')), [elements]);
    const renderLeaf = useMemo(() => renderLeafs(keyBy(marks, 'displayName'), { viewMode: true }), [marks]);

    const renderContent = React.useMemo(() => {
        return render(content, renderElement, renderLeaf, [], mentionIndex);
    }, [content, mentionIndex, renderElement, renderLeaf]);

    return <ThemeProvider value={theme}>{renderContent}</ThemeProvider>;
};
