import { Dispatch } from '@lumapps/redux/types';

import { actions as widgetActions } from '../slice';
import { WidgetState } from '../type';
import { fetchWidgetBlocksById } from './fetchWidgetBlocksById';

export const filterByMetadata = (mainWidget: WidgetState, metadata: string) => async (dispatch: Dispatch) => {
    await dispatch(widgetActions.setWidgetFiltersMetadata({ widgetId: mainWidget.widgetId, metadata }));

    await dispatch(
        fetchWidgetBlocksById({
            widgetId: mainWidget.widgetId,
            widgetType: mainWidget.widgetType,
            params: mainWidget.blockResolutionInfo,
            isMainWidget: true,
        }),
    );
};
