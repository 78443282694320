import { useMemo } from 'react';

import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';

import { GLOBAL, useTranslate } from '@lumapps/translations';
import evolve from '@lumapps/utils/function/evolve';

import { WIDGET_POST_LIST } from '../../keys';
import {
    PostListFilterChoice,
    HasRelevantComment,
    PostListFilterBlockProps,
    PostListFilterHookProps,
} from '../../types';
import { createGetChoiceName } from '../../utils/PostListFilters/getChoiceName';

export const HAS_RELEVANT_COMMENT_FILTER_CHOICES = [
    {
        name: GLOBAL.ALL as string,
        value: HasRelevantComment.all,
    },
    {
        name: WIDGET_POST_LIST.FILTERS_AS_RELEVANT as string,
        value: HasRelevantComment.only,
    },
    {
        name: WIDGET_POST_LIST.FILTERS_NOT_AS_RELEVANT as string,
        value: HasRelevantComment.exclude,
    },
];

export function useCommentRelevancyFilter({
    activeFiltersValues,
    filtersValues,
    handleValuesChange,
}: PostListFilterHookProps): PostListFilterBlockProps<HasRelevantComment> {
    const filterId = 'hasRelevantComment';

    const { translateKey } = useTranslate();

    const selectedValues = getOr([] as HasRelevantComment[], filterId, filtersValues);

    const activeValues = getOr([] as HasRelevantComment[], filterId, activeFiltersValues);

    const choices = useMemo(
        () => map(evolve({ name: translateKey }), HAS_RELEVANT_COMMENT_FILTER_CHOICES),
        [translateKey],
    ) as PostListFilterChoice<HasRelevantComment>[];

    const { getChoiceName, label } = useMemo(() => {
        const getChoiceName = createGetChoiceName(choices);
        const label = translateKey(WIDGET_POST_LIST.FILTERS_COMMENTS_RELEVANCY);

        return {
            getChoiceName,
            label,
        };
    }, [choices, translateKey]);

    return {
        choices,
        filterId,
        getChoiceName,
        label,
        onSelect: handleValuesChange,
        activeValues,
        selectedValues,
    };
}
