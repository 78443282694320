import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { useGoogleDrivePicker } from '@lumapps/google-drive-pickers/hooks/useGoogleDrivePicker';
import { GDCustomDocViewType, GDDocument, GDPickerConfiguration } from '@lumapps/google-drive-pickers/types';
import { currentLanguageSelector } from '@lumapps/languages';
import { mdiGoogleDrive, mdiLaptop, mdiMicrosoftSharepoint } from '@lumapps/lumx/icons';
import { useOneDrivePicker } from '@lumapps/one-drive-pickers/hooks/useOneDrivePicker';
import { OneDriveDocument } from '@lumapps/one-drive-pickers/types';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { accountTypeSelector, connectedUserSelector } from '@lumapps/user/ducks/selectors';
import { UserAccountType } from '@lumapps/user/types';
import { WREX_IMAGE } from '@lumapps/wrex-image/keys';
import { ToolbarItem } from '@lumapps/wrex/types';

import { MEDIAS } from '../keys';
import { MediaSourceV2, MediaSourceV2Drive } from '../types';

interface UseMediaPickerOptionsPropsV2 {
    /** Whether Drive is allowed in the media picker. */
    isDriveAllowed?: boolean;
    /** On upload from computer callback. */
    onUploadFromComputer(): void;
    /** Callback for select files */
    onFilesSelected?: (file: GDDocument[] | OneDriveDocument[], provider: MediaSourceV2Drive) => void;
    /** Whether the maximum number of drive files has been added */
    isMaxDriveFiles?: boolean;
}

const DRIVE_ITEM_INFOS = {
    [UserAccountType.GOOGLE]: {
        icon: mdiGoogleDrive,
        key: MEDIAS.FROM_GOOGLE_DRIVE,
    },
    [UserAccountType.MICROSOFT]: {
        icon: mdiMicrosoftSharepoint,
        key: MEDIAS.FROM_MICROSOFT_SHAREPOINT,
    },
};

/**
 * List media picker options depending on the current context and options.
 */
export const useMediaPickerOptionsV2 = ({
    isDriveAllowed,
    onUploadFromComputer,
    onFilesSelected,
    isMaxDriveFiles,
}: UseMediaPickerOptionsPropsV2) => {
    const { get } = useDataAttributes('media-picker-dropdown');
    const { translateKey } = useTranslate();

    const accountType = useSelector(accountTypeSelector);
    const currentUser = useSelector(connectedUserSelector);
    const currentLanguage = useSelector(currentLanguageSelector);
    const googleAccount = accountType === UserAccountType.GOOGLE;
    const microsoftAccount = accountType === UserAccountType.MICROSOFT;
    const token = currentUser?.token;

    const { openGoogleDrivePicker } = useGoogleDrivePicker(
        {
            clientId: '',
            developerKey: '',
            multiselect: true,
            customDocViews: [GDCustomDocViewType.DEFAULT_VIEW, GDCustomDocViewType.SHARED_DRIVE_VIEW],
            locale: currentLanguage,
            setIncludeFolders: true,
            setSelectFolderEnabled: false,
            token,
        } as GDPickerConfiguration,
        {
            onFilesSelected: (files) => onFilesSelected && onFilesSelected(files as any, MediaSourceV2.GOOGLE_DRIVE),
        },
    );

    const { openOneDrivePicker, OneDrivePicker } = useOneDrivePicker(
        { type: 'file' },
        (files) => onFilesSelected && onFilesSelected([files], MediaSourceV2.MICROSOFT),
    );

    const options = React.useMemo(() => {
        const options: ToolbarItem[] = [
            /**
             * Upload from computer
             */
            {
                type: 'option',
                itemKey: 'media-from-computer',
                onClick: onUploadFromComputer,
                icon: mdiLaptop,
                label: translateKey(WREX_IMAGE.FROM_COMPUTER),
                otherProps: {
                    ...get({
                        element: 'button',
                        action: 'open-computer-picker',
                    }),
                },
            },
        ];

        /**
         * Pick from Google or Microsoft drive
         */
        if (isDriveAllowed && (googleAccount || microsoftAccount)) {
            options.push({
                type: 'option',
                itemKey: 'drive-media-picker',
                onClick: () => (googleAccount ? openGoogleDrivePicker() : openOneDrivePicker()),
                label: translateKey(DRIVE_ITEM_INFOS[accountType].key),
                icon: DRIVE_ITEM_INFOS[accountType].icon,
                helper: translateKey(MEDIAS.MAX_FILES),
                otherProps: {
                    ...get({
                        element: 'button',
                        action: googleAccount ? 'open-google-drive-picker' : 'open-microsoft-drive-picker',
                    }),
                },
                isDisabled: isMaxDriveFiles,
            });
        }

        return options;
    }, [
        accountType,
        get,
        googleAccount,
        isDriveAllowed,
        isMaxDriveFiles,
        microsoftAccount,
        onUploadFromComputer,
        openGoogleDrivePicker,
        openOneDrivePicker,
        translateKey,
    ]);
    return { options, OneDrivePicker };
};
