export enum EMBEDDED_ANALYTICS_KEYS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=42884213
     */
    NO_DATA = 'ADMIN.ANALYTICS.NO_DATA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=42884804
     */
    NO_DATA_HELPER = 'ADMIN.ANALYTICS.PLATFORM.NO_DATA_HELPER',
}
