/* eslint-disable react/forbid-elements */
/* eslint-disable lumapps/no-ds-classes */
/* eslint-disable lumapps/no-classname-strings */
/* eslint-disable lumapps/no-manual-bems */
import React from 'react';

import includes from 'lodash/includes';

import { classnames, margin } from '@lumapps/classnames';
import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { CommunityVisibility } from '@lumapps/communities/components/CommunityVisibility';
import { CommunityLinkRef, RenderingType } from '@lumapps/communities/types';
import { useDataAttributes } from '@lumapps/data-attributes';
import {
    Alignment,
    AspectRatio,
    ColorPalette,
    FlexBox,
    Heading,
    Link,
    Orientation,
    Size,
    Theme,
} from '@lumapps/lumx/react';
import { useResponsive } from '@lumapps/responsive';
import { SubscriptionsActions } from '@lumapps/subscriptions/components/SubscriptionsActions';
import { SubscriptionsTypes } from '@lumapps/subscriptions/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BlockImage } from '@lumapps/widget-base/components/Block/BlockImage/BlockImage';
import { BlockUser } from '@lumapps/widget-base/components/Block/BlockUser/BlockUser';
import { useBlockBreakpoints } from '@lumapps/widget-base/hooks/useBlockBreakpoints';
import { BlockComponent, WidgetSizes } from '@lumapps/widget-base/types';

import {
    BlockCommunityPreview as BlockCommunityPreviewType,
    BlockCommunityPreviewVariants,
    OrderElement,
    ThumbnailDisplayType,
} from '../../types';
import { AccessRequest } from '../AccessRequest/AccessRequest';

import './index.scss';

export const CLASSNAME = 'block-community-preview';

export const BlockCommunityPreview: BlockComponent<BlockCommunityPreviewType> = (props) => {
    const {
        areNotificationsEnabled,
        communityId,
        description,
        image,
        isFollowed,
        members,
        order,
        renderingType,
        site,
        siteId,
        slug,
        theme = Theme.light,
        title,
        variant,
        visibility,
        thumbnailDisplayType,
        areNotificationsEnabledOnSubscription,
        myMembership,
        myPermissions,
    } = props;
    const scope = `community-${communityId}`;
    const { translateKey } = useTranslate();
    const { ref, currentBreakpoint } = useBlockBreakpoints();
    const { isMobile } = useResponsive();
    const { get } = useDataAttributes(scope);

    const isHorizontal = includes(
        [BlockCommunityPreviewVariants.horizontal, BlockCommunityPreviewVariants.horizontalStretch],
        variant,
    );

    const communityLinkRef: CommunityLinkRef = {
        slug,
        id: communityId,
        instance: { id: siteId || site?.siteId, slug: site?.slug },
        renderingType,
    };

    const isSmallThumbnail =
        image &&
        (thumbnailDisplayType === ThumbnailDisplayType.small ||
            (thumbnailDisplayType !== undefined && renderingType === RenderingType.community));

    const fontColor = theme === Theme.dark ? ColorPalette.light : ColorPalette.dark;

    /**
     * The ImageBlock. Depending on whether the Preview has a small thumbnail, it is displayed as first element,
     * or inside a sub-Flexbox
     */
    const ImageBlock =
        order.includes(OrderElement.image) && image ? (
            <CommunityLink
                /*
                 * If a title is defined, remove tab index from image as the title will suffice.
                 * However without any title, the image should remain tabbable as it is the only
                 * link into the community.
                 */
                tabIndex={title ? -1 : 0}
                to={communityLinkRef}
                className={classnames(`${CLASSNAME}__thumbnail-wrapper`, {
                    [`${CLASSNAME}__thumbnail-wrapper--small`]: isSmallThumbnail,
                })}
            >
                <BlockImage
                    {...image}
                    theme={theme}
                    className={isSmallThumbnail ? `${CLASSNAME}__title-horizontal` : undefined}
                    thumbnailProps={{
                        size: isSmallThumbnail ? Size.l : undefined,
                        variant: thumbnailDisplayType ? 'rounded' : undefined,
                        aspectRatio: thumbnailDisplayType
                            ? (isSmallThumbnail && AspectRatio.square) || AspectRatio.wide
                            : AspectRatio.horizontal,
                        alt: title,
                    }}
                />
            </CommunityLink>
        ) : null;

    /** The list of fields that are next to the ImageBlock when in Small thumbnail mode. Else it will be just rendered as the first fields in the list */
    const AsideSmallThumbnailFields = (
        <>
            {order.includes(OrderElement.members) && members && members.length > 0 && (
                <FlexBox
                    className={`${CLASSNAME}__field ${CLASSNAME}__field--block ${CLASSNAME}__members`}
                    orientation={Orientation.horizontal}
                    wrap
                >
                    {members.map((member) => (
                        <BlockUser key={member.userId} {...member} avatarSize={Size.s} />
                    ))}
                </FlexBox>
            )}

            {order.includes(OrderElement.site_name) && site?.name && (
                <span className={`${CLASSNAME}__field ${CLASSNAME}__field--text ${CLASSNAME}__site`}>
                    {translateKey(GLOBAL.IN)} {site.name}
                </span>
            )}

            {order.includes(OrderElement.title) && title && (
                <Link
                    linkAs={CommunityLink}
                    to={communityLinkRef}
                    color={fontColor}
                    className={`${CLASSNAME}__field ${CLASSNAME}__field--text ${CLASSNAME}__title`}
                    {...get({
                        element: 'community-title',
                        action: 'link',
                    })}
                >
                    <Heading
                        typography="custom-title4"
                        className={classnames({
                            [`${CLASSNAME}__field-text--line-clamp`]: Boolean(thumbnailDisplayType),
                        })}
                    >
                        {title}
                    </Heading>
                </Link>
            )}

            {order.includes(OrderElement.visibility) && visibility && (
                <CommunityVisibility
                    visibility={visibility}
                    className={`${CLASSNAME}__field ${CLASSNAME}__field--text lumx-typography-body1 lumx-color-font-${fontColor}-L2`}
                />
            )}
        </>
    );

    return (
        <FlexBox
            ref={ref}
            className={classnames(
                CLASSNAME,
                `${CLASSNAME}--theme-${theme}`,
                `${CLASSNAME}--size-${currentBreakpoint || WidgetSizes.l}`,
                {
                    [`${CLASSNAME}--horizontal`]: isHorizontal,
                    [`${CLASSNAME}--vertical`]: variant === BlockCommunityPreviewVariants.vertical,
                    [`${CLASSNAME}--vertical-stretch`]: variant === BlockCommunityPreviewVariants.verticalStretch,
                    [`${CLASSNAME}--mobile`]: isMobile,
                },
            )}
            orientation={isHorizontal ? Orientation.horizontal : Orientation.vertical}
            hAlign={Alignment.top}
        >
            {!isSmallThumbnail && ImageBlock}

            <FlexBox className={`${CLASSNAME}__content`} orientation={Orientation.vertical} vAlign={Alignment.left}>
                {/* If not small thumbnail, simply display the aside fields as children of the Flexbox */}
                {!isSmallThumbnail && AsideSmallThumbnailFields}

                {/* If small thumbnail, display the thumbnail image, then the aside fields next to it */}
                {isSmallThumbnail && (
                    <FlexBox className={`${CLASSNAME}__small-thumbnail-wrapper`} orientation={Orientation.horizontal}>
                        {ImageBlock}

                        <FlexBox
                            orientation={Orientation.vertical}
                            className={classnames(`${CLASSNAME}__small-thumbnail-aside`, margin('left', Size.huge))}
                        >
                            {AsideSmallThumbnailFields}
                        </FlexBox>
                    </FlexBox>
                )}

                {/* The rest of the fields are always under the elements above */}

                {order.includes(OrderElement.description) && description && (
                    <p
                        className={classnames(
                            `${CLASSNAME}__field ${CLASSNAME}__field--text ${CLASSNAME}__description`,
                            {
                                /* Description only has a top margin if title is also defined */
                                'lumx-spacing-margin-top-tiny': title,
                                [`${CLASSNAME}__field-text--line-clamp`]: thumbnailDisplayType,
                            },
                        )}
                    >
                        {description}
                    </p>
                )}

                {order.includes(OrderElement.follow_button) && (
                    <>
                        <SubscriptionsActions
                            theme={theme}
                            className={`${CLASSNAME}__field ${CLASSNAME}__field--block ${CLASSNAME}__follow`}
                            subscription={{
                                resourceId: communityId,
                                resourceType: SubscriptionsTypes.content,
                                isSubscribed: isFollowed,
                                isNotified: areNotificationsEnabled,
                                enableNotification: true,
                                autoFetch: false,
                                areNotificationsEnabledOnSubscription,
                            }}
                            buttonProps={{ size: Size.s }}
                            scope={`${scope}-subscription`}
                        />
                        {myPermissions?.canRequestMembership && (
                            <AccessRequest
                                theme={theme}
                                scope={scope}
                                requestId={communityId}
                                requestInitialStatus={myMembership?.requestDetails?.status}
                            />
                        )}
                    </>
                )}
            </FlexBox>
        </FlexBox>
    );
};
