import React, { ReactNode } from 'react';

import { classnames } from '@lumapps/classnames';

import './index.scss';

export interface SubSectionProps {
    className?: string;
    children?: ReactNode;
}

/**
 * Small component to create a subSection with a margin
 *
 * @family Layouts
 * @param SubSectionProps
 * @returns SubSection
 */
export const SubSection: React.FC<SubSectionProps> = ({ className, children, ...forwardedProps }) => {
    return (
        <div className={classnames('lumx-sub-section', className)} {...forwardedProps}>
            {children}
        </div>
    );
};
