import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiChevronDown, mdiChevronUp } from '@lumapps/lumx/icons';
import {
    Alignment,
    Emphasis,
    FlexBox,
    Icon,
    IconButton,
    Orientation,
    Placement,
    Size,
    Text,
    Theme,
    Tooltip,
} from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { CLASSNAME as ITEM_CLASSNAME } from '../NavigationItem';
import { NavigationSection, NavigationSectionProps } from '../NavigationSection';

import './index.scss';

export interface NavigationSectionLinkProps extends NavigationSectionProps {
    /** Whether the component is active or not. */
    isSelected?: boolean;
    /** Custom react component for the link (can be used to inject react router Link). */
    linkAs?: 'a' | React.ElementType;
    /** Props to pass to the link */
    linkProps:
        | React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
        | React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
        | Record<string, any>;
    /** On click callback. */
    onClick?(evt: React.MouseEvent): void;
}

const CLASSNAME = 'navigation-section-link';

/**
 * A Navigation section that also has a link. It opens the link on the label click, or else it opens the section on
 * the arrow click. A11Y compliant
 */
const NavigationSectionLink = React.forwardRef<HTMLLIElement, NavigationSectionLinkProps>((props, ref) => {
    const {
        children,
        className,
        element,
        icon,
        label,
        isSelected,
        theme,
        scope,
        linkAs,
        linkProps,
        onClick,
        openOnMount = false,
        ...forwardedProps
    } = props;

    const [isOpen, setIsOpen] = React.useState(openOnMount);
    const { block, element: elementClassname } = useClassnames(CLASSNAME);
    const { element: elementItemClassname } = useClassnames(ITEM_CLASSNAME);
    const { translateAndReplace } = useTranslate();

    const [labelElement, setLabelElement] = React.useState<HTMLSpanElement | null>(null);

    const tooltipLabel =
        typeof label === 'string' && labelElement && labelElement.offsetWidth < labelElement.scrollWidth ? label : null;

    const { get } = useDataAttributes(scope ?? '');
    const dataAttributes = scope && element ? get({ element }) : {};

    const childrenArray = React.Children.toArray(children);

    const onSectionLinkClick = React.useCallback(
        (evt: React.MouseEvent) => {
            evt.stopPropagation();
            onClick?.(evt);
        },
        [onClick],
    );

    if (!childrenArray.length) {
        return null;
    }

    const isLink = Boolean((linkProps && 'href' in linkProps && linkProps?.href) || linkAs);
    const LinkElement: 'a' | React.ElementType = isLink && linkAs ? linkAs : 'a';

    // If node is not a link, treat it as a standard section
    if (!isLink) {
        return <NavigationSection {...props} />;
    }

    return (
        <li className={block(CLASSNAME, [className, ITEM_CLASSNAME])} ref={ref} {...forwardedProps}>
            <Tooltip label={tooltipLabel} placement={Placement.TOP}>
                <FlexBox
                    orientation={Orientation.horizontal}
                    hAlign={Alignment.center}
                    className={elementItemClassname(
                        'link',
                        {
                            'is-selected': Boolean(isSelected),
                            dark: theme === Theme.dark,
                        },
                        [elementClassname('section')],
                    )}
                    tabIndex={-1}
                    {...dataAttributes}
                >
                    {icon ? (
                        <Icon className={elementItemClassname('icon')} icon={icon} size={Size.xs} theme={theme} />
                    ) : null}
                    <LinkElement
                        {...linkProps}
                        onClick={onSectionLinkClick}
                        tabIndex={0}
                        className={elementItemClassname('section-link', [elementClassname('link')])}
                        ref={setLabelElement}
                    >
                        <Text as="span" className={elementItemClassname('label')}>
                            {label}
                        </Text>
                    </LinkElement>
                    <IconButton
                        aria-expanded={isOpen}
                        className={elementItemClassname('icon', [elementClassname('chevron')])}
                        icon={isOpen ? mdiChevronUp : mdiChevronDown}
                        size={Size.s}
                        emphasis={Emphasis.low}
                        label={translateAndReplace(
                            `FRONT.MAIN_NAV.ITEM.TOGGLE_BUTTON.LABEL.${isOpen ? 'HIDE' : 'SHOW'}`,
                            { ITEM: (label as string) || '' },
                        )}
                        // Empty tooltip label to not display a tooltip on toggle buttons
                        tooltipProps={{
                            label: '',
                        }}
                        theme={theme}
                        onClick={() => setIsOpen(!isOpen)}
                    />
                </FlexBox>
            </Tooltip>
            {isOpen && (
                <ul className={elementClassname('drawer')} id={`section-${label}`}>
                    {childrenArray.map((child) => React.cloneElement(child as React.ReactElement, { theme, scope }))}
                </ul>
            )}
        </li>
    );
});

NavigationSectionLink.displayName = 'NavigationSectionLink';

export { NavigationSectionLink };
