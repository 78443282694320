import { FULL_DATE_SIX_FRACTIONAL_SECONDS, HALF_AN_HOUR, HOURS_IN_A_DAY, TIME_WITH_TIMEZONE } from '@lumapps/constants';

import { Time } from '../types/Time';
import { getMoment } from './getMoment';

const moment = getMoment();

export const getStartOfToday = () => {
    return moment().startOf('day').toDate();
};

export const getStartOfCurrentDay = (currentDay: Date) => {
    const day = moment(currentDay);
    const startOfCurrentDay = day.startOf('day').toDate();
    return startOfCurrentDay;
};

export const getEndOfCurrentDay = (currentDay: Date) => {
    const day = moment(currentDay);
    return day.endOf('day').toDate();
};

interface GetRoundedUpTimeOptions {
    /** The number of minutes to round the time to. Default to 60. */
    roundTo?: number;
    /** The math operation to round the time with. Default to 'round'.  */
    operation?: 'round' | 'ceil' | 'floor';
}

/**
 *
 * Round up the time to the given unit.
 * The time can either be rounded to the previous / next / closest unit.
 *
 * By default, rounds up the time to the nearest hour.
 */
export function getRoundedTime(date: Date | string, options?: GetRoundedUpTimeOptions) {
    const { roundTo = 60, operation = 'round' } = options || {};

    // If a string was set, convert it to a date object.
    const dateObject = typeof date === 'string' ? new Date(date) : date;

    // Convert minutes to milliseconds
    const ms = 1000 * 60 * roundTo;

    // Return rounded date to given operation.
    return new Date(Math[operation](dateObject.getTime() / ms) * ms);
}

export const getCurrentStartTime = (options?: GetRoundedUpTimeOptions) => {
    return getRoundedTime(new Date(), { operation: 'ceil', ...options });
};
export const getCurrentEndTime = () => {
    const baseTime = getCurrentStartTime();
    const endTime = baseTime.setTime(baseTime.getTime() + 1 * 60 * 60 * 1000);
    return endTime;
};

export const getCurrentStartTimeObject = () => {
    const currentRoundedUpTime = getRoundedTime(new Date(), { operation: 'ceil' });
    const currentHour = moment(currentRoundedUpTime).hour();
    const currentMinute = moment(currentRoundedUpTime).minute();
    return { hour: currentHour, minute: currentMinute };
};

export const getUpdatedEndTimeObject = (startTime: { hour: number; minute: number }) => {
    if (startTime.minute) {
        return { hour: startTime.hour + 1, minute: 0 };
    }
    return { hour: startTime.hour, minute: 30 };
};

export const getLocalFormatedTime = (time: { hour: number; minute: number }, locale: string) => {
    return moment().hours(time.hour).minutes(time.minute).locale(locale).format('LT');
};

export const extractTimeFromDate = (date: string) => {
    const momentDate = moment(date);
    const hour = momentDate.hours();
    const minute = momentDate.minutes();
    return { hour, minute };
};

export const formatTimeFromDate = (date: string | Date, format = TIME_WITH_TIMEZONE) => {
    return moment(date).utc().format(format);
};

export const buildDateFromDayAndTime = (day: Date, time: Time) => {
    const momentDay = moment(day);
    const updatedMomentDay = momentDay.set({
        hour: time.hour,
        minute: time.minute,
        second: time?.seconds,
        millisecond: 0,
    });
    const date = updatedMomentDay.format(FULL_DATE_SIX_FRACTIONAL_SECONDS).toString();
    return date;
};

export const buildTimeList = (minTime: Time | undefined): Time[] => {
    const times = [];
    if (minTime) {
        const minHour = minTime.hour;
        for (let i = 0, nbHours = HOURS_IN_A_DAY - minHour; i < nbHours; i++) {
            if (minTime.minute === HALF_AN_HOUR) {
                if (i === 0) {
                    times.push({ hour: minHour + i, minute: HALF_AN_HOUR });
                } else {
                    times.push({ hour: minHour + i, minute: 0 });
                    times.push({ hour: minHour + i, minute: HALF_AN_HOUR });
                }
            } else {
                times.push({ hour: minHour + i, minute: 0 });
                times.push({ hour: minHour + i, minute: HALF_AN_HOUR });
            }
        }
    } else {
        for (let i = 0, nbHours = HOURS_IN_A_DAY; i < nbHours; i++) {
            times.push({ hour: i, minute: 0 });
            times.push({ hour: i, minute: HALF_AN_HOUR });
        }
    }
    return times;
};
