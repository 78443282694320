import { MediaSourceV2 } from '@lumapps/medias/types';

export const FILE = 'file';

/* 20 files maximum per one request to GDrive or OneDrive */
export const MAX_DRIVE_FILE_COUNT = 20;

export const WREX_LUMAPPS_MEDIA_PROVIDER = 'lumapps_media';

export const WREX_MEDIA_SOURCE_VALUES = [
    MediaSourceV2.GOOGLE_DRIVE,
    MediaSourceV2.MICROSOFT,
    WREX_LUMAPPS_MEDIA_PROVIDER,
] as const;
