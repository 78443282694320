import React from 'react';

import { useMutation, useQuery, useQueryClient } from '@lumapps/base-api/react-query';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { FetchLikeStatus, FetchToggleLike, reactionsQueryKeys } from '@lumapps/reactions-core/api/query';
import { LikeState, ResourceRef } from '@lumapps/reactions-core/api/types';
import { GLOBAL } from '@lumapps/translations';

/**
 * Get or fetch like status and toggle like.
 */
export const useResourceLike = ({
    resourceRef,
    initialState,
    isInitialStateStale = false,
    fetchToggleLike,
    fetchLikeStatus,
}: {
    resourceRef: ResourceRef<string>;
    initialState: LikeState;
    isInitialStateStale?: boolean;
    fetchToggleLike: FetchToggleLike;
    fetchLikeStatus?: FetchLikeStatus;
}) => {
    const notify = useNotification();

    // Get or fetch like status (if fetch function provided and data is stale)
    const { isLoading, data } = useQuery({
        enabled: isInitialStateStale && !!fetchLikeStatus,
        queryKey: reactionsQueryKeys.likeStatus(resourceRef),
        queryFn: ({ signal }) => fetchLikeStatus?.(resourceRef, signal),
        initialData: initialState,
    });

    const state = data || initialState;

    const queryClient = useQueryClient();

    React.useEffect(() => {
        queryClient.setQueryData(reactionsQueryKeys.likeStatus(resourceRef), initialState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialState.isLiked]);

    const stateBeforeToggleRef = React.useRef(state);

    // Toggle like/unlike
    const toggleLike = useMutation({
        mutationFn: () => fetchToggleLike(resourceRef, state.isLiked, state.likeCount),
        mutationKey: reactionsQueryKeys.likeStatus(resourceRef),
        onMutate() {
            stateBeforeToggleRef.current = state;
            // Optimistic update
            queryClient.setQueryData(reactionsQueryKeys.likeStatus(resourceRef), {
                isLiked: !state.isLiked,
                likeCount: state.likeCount + (state.isLiked ? -1 : 1),
            });
        },
        onSuccess(newState) {
            // Store in cache
            queryClient.setQueryData(reactionsQueryKeys.likeStatus(resourceRef), newState);
        },
        onError() {
            // Generic error notification
            notify.error({ translate: GLOBAL.GENERIC_ERROR });
            // Rollback optimistic update
            queryClient.setQueryData(reactionsQueryKeys.likeStatus(resourceRef), stateBeforeToggleRef.current);
        },
        onSettled() {
            // Invalidate like list query
            const queryKey = reactionsQueryKeys.listLikes(resourceRef).filter(Boolean);
            queryClient.invalidateQueries({ queryKey });
        },
    });

    return { ...state, toggleLike: toggleLike.mutate, isLoading: isLoading || toggleLike.isLoading };
};
