/* istanbul ignore file */
import BaseApi, { BaseApiPromise, ServerListResponse } from '@lumapps/base-api';

import { Directory } from '../types';

export const directoryApi = new BaseApi({
    path: 'directory',
});

export const saveDirectory = (directory: Partial<Directory>): Promise<Directory> => {
    return directoryApi.post('save', directory).then((response) => {
        return response.data as Directory;
    });
};

export interface ListDirectoriesParams {
    instance: string;
    query?: string;
    maxResults?: string;
    cursor?: string;
    fields?: string;
}

export const listDirectories = async (params: ListDirectoriesParams, signal?: AbortSignal) => {
    const { data } = await directoryApi.get<ServerListResponse<Directory>>('/list', { params, signal });

    return data;
};

export interface GetDirectoryParams {
    uid: string;
    fields?: string;
}

export const get = (params: GetDirectoryParams): BaseApiPromise<Directory> => {
    return directoryApi.get('get', { params });
};

export interface DeleteDirectoryParams {
    uid: string[];
}

export const deleteDirectories = async (params: DeleteDirectoryParams) =>
    directoryApi.delete('deleteMulti', { params });

export const directoriesQueryKeys = {
    all: () => ['directories'] as const,
    listDirectories: (params?: ListDirectoriesParams) => [...directoriesQueryKeys.all(), 'list', params] as const,
};
