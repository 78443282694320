import React from 'react';

import uniq from 'lodash/uniq';

import { margin } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { RadioGroup, RadioButton, Divider, Switch, FlexBox, Size, Orientation, TextField } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';
import { SettingSection } from '@lumapps/widget-settings/components/SettingSection';

import { WIDGET_PERSONAL_FEED_PREVIEW_SETTINGS_ITEM_NUMBERS } from '../../constants';
import { WIDGET_PERSONAL_FEED_PREVIEW } from '../../keys';
import { WidgetPersonalFeedPreviewFields, WidgetPersonalFeedPreviewPropertiesType } from '../../types';

export interface WidgetPersonalFeedPreviewSettingsProps {
    /** Specific properties that will be sent to the backend */
    properties?: WidgetPersonalFeedPreviewPropertiesType;
    /** Callback that will update the properties object */
    onChange: (properties: WidgetPersonalFeedPreviewPropertiesType) => void;
}

const CLASSNAME = 'widget-personal-feed-preview-settings';
const SCOPE = 'widget-personal-feed-preview-settings';

/**
 * Widget specific settings for the Widget personal Feed Preview.
 * @param WidgetPersonalFeedPreviewSettingsProps
 * @returns WidgetPersonalFeedPreviewSettings
 */
export const WidgetPersonalFeedPreviewSettings: React.FC<WidgetPersonalFeedPreviewSettingsProps> = ({
    properties,
    onChange,
}) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const { id: siteId } = useSelector(getCurrentInstance);
    const { get } = useDataAttributes(SCOPE);

    const onPropertyChange = React.useCallback(
        (property: keyof WidgetPersonalFeedPreviewPropertiesType) =>
            (newProperty: WidgetPersonalFeedPreviewPropertiesType[keyof WidgetPersonalFeedPreviewPropertiesType]) => {
                if (properties) {
                    onChange({ ...properties, [property]: newProperty });
                }
            },
        [onChange, properties],
    );

    const onInformationDisplayedChange = React.useCallback(
        (field: WidgetPersonalFeedPreviewFields) => (isDisplayed: boolean) => {
            if (properties) {
                onPropertyChange('fields')(
                    isDisplayed
                        ? uniq([...properties.fields, field])
                        : properties.fields.filter((propField) => propField !== field),
                );
            }
        },
        [onPropertyChange, properties],
    );

    const handleMaxNumberChange = (value: string) => {
        const numberValue = Number(value);

        const newValue =
            value === ''
                ? ''
                : Math.max(
                      WIDGET_PERSONAL_FEED_PREVIEW_SETTINGS_ITEM_NUMBERS.minimum,
                      Math.min(numberValue, WIDGET_PERSONAL_FEED_PREVIEW_SETTINGS_ITEM_NUMBERS.maximum),
                  );

        onPropertyChange('maxNumber')(newValue);
    };

    if (!properties) {
        return null;
    }

    return (
        <div className={CLASSNAME}>
            <SettingSection
                title={translateKey(WIDGET_BASE.SETTINGS_SELECTION_TYPE_TITLE)}
                className={margin('vertical', 'huge')}
            >
                <RadioGroup>
                    <RadioButton
                        isChecked={properties.siteId !== undefined}
                        label={translateKey(GLOBAL.CURRENT_SITE)}
                        value={siteId}
                        onChange={onPropertyChange('siteId')}
                        name="selection-type"
                    />
                    <RadioButton
                        isChecked={properties.siteId === undefined}
                        label={translateKey(GLOBAL.ALL_SITES)}
                        value={undefined}
                        onChange={onPropertyChange('siteId')}
                        name="selection-type"
                    />
                </RadioGroup>
            </SettingSection>
            <Divider />
            <SettingSection title={translateKey(GLOBAL.PROPERTIES)} className={margin('vertical', 'huge')}>
                <TextField
                    label={translateKey(WIDGET_BASE.SETTINGS_MAX_RESULTS)}
                    value={properties.maxNumber.toString()}
                    type="number"
                    onChange={handleMaxNumberChange}
                    helper={translateAndReplace(GLOBAL.MAX_RESULTS_NUMBER_HELP, {
                        NB: WIDGET_PERSONAL_FEED_PREVIEW_SETTINGS_ITEM_NUMBERS.default,
                    })}
                    max={WIDGET_PERSONAL_FEED_PREVIEW_SETTINGS_ITEM_NUMBERS.maximum}
                    min={WIDGET_PERSONAL_FEED_PREVIEW_SETTINGS_ITEM_NUMBERS.minimum}
                    {...get({ element: 'input', action: 'set-max-items' })}
                />
                <Switch
                    className={margin('top', 'huge')}
                    isChecked={properties.displayHeader}
                    onChange={onPropertyChange('displayHeader')}
                    helper={translateKey(WIDGET_PERSONAL_FEED_PREVIEW.WELCOME_MESSAGE_DESCRIPTION)}
                >
                    {translateKey(WIDGET_PERSONAL_FEED_PREVIEW.WELCOME_MESSAGE)}
                </Switch>
            </SettingSection>
            <Divider />
            <SettingSection
                title={translateKey(WIDGET_BASE.SETTINGS_INFORMATION_DISPLAYED_TITLE)}
                className={margin('vertical', 'huge')}
            >
                <FlexBox gap={Size.big} orientation={Orientation.vertical}>
                    <Switch
                        isChecked={properties.fields?.includes('contextual_banner')}
                        onChange={onInformationDisplayedChange('contextual_banner')}
                    >
                        {translateKey(WIDGET_PERSONAL_FEED_PREVIEW.CONTEXTUAL_BANNER)}
                    </Switch>
                    <Switch
                        isChecked={properties.fields?.includes('title')}
                        onChange={onInformationDisplayedChange('title')}
                    >
                        {translateKey(GLOBAL.TITLE)}
                    </Switch>
                    <Switch
                        isChecked={properties.fields?.includes('excerpt')}
                        onChange={onInformationDisplayedChange('excerpt')}
                    >
                        {translateKey(GLOBAL.EXCERPT)}
                    </Switch>
                    <Switch
                        isChecked={properties.fields?.includes('reactions')}
                        onChange={onInformationDisplayedChange('reactions')}
                    >
                        {translateKey(GLOBAL.SOCIAL)}
                    </Switch>
                </FlexBox>
            </SettingSection>
        </div>
    );
};
