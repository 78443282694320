import React, { useContext } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiClose } from '@lumapps/lumx/icons';
import { Emphasis, IconButton } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { ReactEditor, useSlateStatic, useSelected, Transforms, Path } from '@lumapps/wrex/slate';
import { useInlineVoid } from '@lumapps/wrex/slate/utils/useInlineVoid';

import { WrexFileContext } from '../../../context';
import { FileEditor } from '../../../types';
import { CLASSNAME, File } from '../../blocks/File';

import './index.scss';

export const EditableFile: typeof File = ({ element, children, ...forwardedProps }) => {
    const editorStatic = useSlateStatic() as ReactEditor & FileEditor;
    const selected = useSelected();
    const { block, element: classElement } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();
    const { onFocus, onDelete } = useInlineVoid(editorStatic, element as any);
    const contextData = useContext(WrexFileContext);

    // Update file if needed
    const updatedRef = React.useRef(false);
    React.useEffect(() => {
        if (updatedRef.current || !contextData || contextData.isFetching || !editorStatic || !element) {
            return;
        }

        const updatedFile = contextData.updatedFiles?.find((file) => file.resource?.id === element.resource?.id);
        if (updatedFile) {
            const path = ReactEditor.findPath(editorStatic, element) as Path;
            Transforms.setNodes(editorStatic, updatedFile, { at: path });
            updatedRef.current = true;
        }
    }, [editorStatic, element, contextData]);

    return (
        <File
            className={block({ editable: true, selected })}
            element={element}
            onFocus={onFocus}
            tabIndex={0}
            actions={[
                <IconButton
                    className={classElement('actions')}
                    key={element?.resource?.id}
                    icon={mdiClose}
                    emphasis={Emphasis.low}
                    hasBackground
                    onClick={onDelete}
                    label={translateKey(GLOBAL.DELETE)}
                />,
            ]}
            {...forwardedProps}
        >
            {children}
        </File>
    );
};

EditableFile.displayName = 'file';
