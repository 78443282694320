import React, { ReactNode } from 'react';

import { LumdrianContext, LumdrianContextProps } from 'lumapps-sdk-js';

import { useTheme, Theme } from '@lumapps/lumx/react';

export type LumdrianContextProviderProps = Omit<LumdrianContextProps, 'theme'> & { children?: ReactNode };

export const LumdrianContextProvider: React.FC<LumdrianContextProviderProps> = ({
    children,
    connectorId,
    contributionMode,
    connectorLoginRedirect,
    contentId,
    componentType,
    exportProp,
    extensionId,
    globalProperties,
    legacyWidgetId,
    properties,
    uuid,
    widgetRef,
}) => {
    const theme = useTheme() || Theme.light;

    const remoteContextValue = React.useMemo<LumdrianContextProps>(
        () => ({
            componentType,
            connectorId,
            contributionMode,
            connectorLoginRedirect,
            contentId,
            exportProp,
            extensionId,
            globalProperties,
            legacyWidgetId,
            properties,
            theme,
            uuid,
            widgetRef,
        }),
        [
            componentType,
            connectorId,
            contributionMode,
            connectorLoginRedirect,
            contentId,
            exportProp,
            extensionId,
            globalProperties,
            legacyWidgetId,
            properties,
            theme,
            uuid,
            widgetRef,
        ],
    );

    return <LumdrianContext.Provider value={remoteContextValue}>{children}</LumdrianContext.Provider>;
};
