import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Theme, useTheme } from '@lumapps/lumx/react';
import { ElementRender } from '@lumapps/wrex/types';

import { EmbeddedVideoElement } from '../../../types';

import './index.scss';

export const CLASSNAME = 'wrex-embedded-video';

export const EmbeddedVideo: ElementRender<EmbeddedVideoElement, HTMLDivElement> = ({
    element,
    elementRef,
    children,
    className,
    ...forwardedProps
}) => {
    const theme = useTheme() || Theme.light;
    const { block, element: elementClassName } = useClassnames(CLASSNAME);
    const { url, title } = element ?? {};
    return (
        <div className={block(`theme-${theme}`, [className])} ref={elementRef} {...forwardedProps}>
            {children}
            <iframe
                className={elementClassName('embed')}
                title={title}
                src={url}
                frameBorder="0"
                allowFullScreen
                contentEditable={false}
            />
        </div>
    );
};

EmbeddedVideo.displayName = 'embedded-video';
