import { USE_RICH_TEXT_IN_CONTRIBUTION_TOKEN } from '@lumapps/wrex/constants';
import { isDitaContentEmpty } from '@lumapps/wrex/serialization/dita/utils/isDitaContentEmpty';
import { isRichTextContentEmpty } from '@lumapps/wrex/serialization/slate/utils/isRichTextContentEmpty';

/* eslint-disable lines-around-directive */
(function IIFE() {
    // eslint-disable-next-line strict
    'use strict';

    function WidgetContributionController($element, $scope, Utils, Widget, Content, Features, Translation) {
        'ngInject';
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;

        /**
         * Indicates if we are currently editing the content of the contribution widget.
         *
         * @type {boolean}
         */
        vm.editingContent = false;

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller object.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            init();
        };

        /**
         * Get widget classes.
         *
         * @return {Array} An array of widget css classes.
         */
        function getWidgetClass() {
            const widgetClass = ['widget-contribution'];

            vm.parentCtrl.getWidgetClass(widgetClass);

            widgetClass.push('widget-editable');

            if (vm.isWidgetEmpty) {
                widgetClass.push('widget--is-empty');
            }

            if (vm.editingContent) {
                widgetClass.push('widget-contribution--is-editing');
                widgetClass.push('widget-editable--is-editing');
            }

            return widgetClass;
        }

        /** TODO : We'll manage the empty state of the widget contribution in a second time */
        function isEmpty() {
            const inputLanguage = Translation.inputLanguage;
            const isInDesigner = vm.parentCtrl?.designerMode();

            const richTextTranslatedContent = isInDesigner
                ? vm.widget.properties?.richText?.[inputLanguage]
                : Translation.translate(vm.widget.properties?.richText);
            const isRichTextNotEmpty = richTextTranslatedContent && !isRichTextContentEmpty(richTextTranslatedContent)
            if (Features.hasFeature(USE_RICH_TEXT_IN_CONTRIBUTION_TOKEN) && isRichTextNotEmpty) {
                return false;
            }

            const ditaTranslatedContent = isInDesigner
                ? vm.widget.properties?.dita?.[inputLanguage]
                : Translation.translate(vm.widget.properties?.dita);

            return !ditaTranslatedContent || isDitaContentEmpty(ditaTranslatedContent);
        }

        /**
         * In designer mode, when the widget is empty, it is visible and displays
         * the widget type.
         * Used by the designer mode.
         */
        function isWidgetEmpty() {
            return !vm.editingContent && isEmpty();
        }

        /**
         * Edit the content.
         */
        function _editContent() {
            vm.editingContent = true;
            _toggleDragAndDrop();
        }

        /**
         * Read the content.
         */
        function _readContent() {
            vm.editingContent = false;
            _toggleDragAndDrop();
        }

        /**
         * Toggle drag and drop allowed for the contribution widget.
         * When it is in edit mode, disable drag'n'drop.
         */
        function _toggleDragAndDrop() {
            if (Widget.isDragDisabled()) {
                Widget.isDragDisabled(false);

                $element.parents('.component-cell').removeClass('component-cell--hover-disabled');
            } else {
                Widget.isDragDisabled(true);

                $element.parents('.component-cell').addClass('component-cell--hover-disabled');

                // [Fix IE/Edge]: prevent cell drag & drop to take over from the text selection.
                const stopPropagation = (evt) => evt.stopPropagation();
                Utils.waitForAndExecute('.widget-contribution .fr-element', (el) => {
                    el.on('dragstart', stopPropagation).on('selectstart', stopPropagation);
                });
            }
        }

        /**
         * In reader mode, when the widget is empty, it is not displayed at all
         */
        function isWidgetHidden() {
            return !vm.parentCtrl.designerMode() && isEmpty();
        }

        let _viewMode;

        /**
         * Switch to edition mode to allow user to the content of the contribution widget.
         */
        function switchToEditMode() {
            if (!vm.parentCtrl.designerMode() || vm.editingContent || Content.getViewMode() === 'basic') {
                return;
            }

            _editContent();

            _viewMode = Content.getViewMode();
            Content.setViewMode('locked');
        }

        /**
         * Switch to edition mode to allow user to the content of the contribution widget when in basic mode.
         */
        function switchToBasicEditMode() {
            if (Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE') || !vm.parentCtrl.designerMode() || vm.editingContent || Content.getViewMode() !== 'basic') {
                return;
            }

            _editContent();
        }

        /**
         * Switch the content of the contribution widget to reading mode.
         */
        function switchToReadMode() {
            if (!vm.editingContent || Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')) return;

            _readContent();

            if (_viewMode) {
                Content.setViewMode(_viewMode);
            }
        }

        function saveTextualContentInWidgetProperties(textualContent, format) {
            const properties = vm.widget.properties;

            if (format && format === 'lumapps') {
                properties.richText = textualContent;
                delete properties.dita;
            } else {
                properties.dita = textualContent;
                delete properties.richText;
            }
        }

        vm.switchToEditMode = switchToEditMode;
        vm.switchToReadMode = switchToReadMode;
        vm.switchToBasicEditMode = switchToBasicEditMode;
        vm.saveTextualContentInWidgetProperties = saveTextualContentInWidgetProperties;
        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        $scope.$on('contribution-mode', (evt, contributionMode) => {
            if (contributionMode === 'writer') {
                _editContent();
            } else {
                _readContent();
            }
        });

        /**
         * Initialize the widget properties and the controller parameters.
         */
        function _initProperties() {
            const properties = vm.widget.properties;
            properties.dita = properties.dita || {};
            properties.richText = properties.richText || {};
        }

        function init() {
            _initProperties();

            if (Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')) {
                _editContent();
            }
        }
    }

    function widgetContributionDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetContributionController,
            controllerAs: 'vm',
            replace: true,
            require: ['widgetContribution', '^widget'],
            restrict: 'E',
            link,
            scope: {
                widget: '=',
            },
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-contribution/views/widget-contribution.html',
        };
    }

    window.angular.module('Widgets').directive('widgetContribution', widgetContributionDirective);
})();
