/*
 * Utils to base64 encode/decode a string or object
 * This is needed to encode correctly all unicode characters (like é ...)
 * https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 */

// Encoding UTF-8 ⇢ base64
export function b64EncodeUnicode(str: string) {
    return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function replacer(match, p1) {
            return String.fromCharCode(parseInt(p1, 16));
        }),
    );
}

// Decoding base64 ⇢ UTF-8
export function b64DecodeUnicode(str: string) {
    return decodeURIComponent(
        Array.prototype.map
            .call(atob(str), function called(c) {
                // eslint-disable-next-line prefer-template
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );
}

export function encodeJsonToB64(data: object) {
    return b64EncodeUnicode(JSON.stringify(data));
}

export function decodeB64ToJson(b64str: string) {
    return JSON.parse(b64DecodeUnicode(b64str));
}
