import React, { useMemo } from 'react';

import { actions } from '@lumapps/communities/ducks/frontOfficeSlice';
import { isConfigurationWizardOpenSelector } from '@lumapps/communities/ducks/selectors';
import { useCommunity } from '@lumapps/communities/hooks/useCommunity';
import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages';
import { isInstanceSuperAdmin as isInstanceSuperAdminSelector } from '@lumapps/permissions';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { useHash } from '@lumapps/router';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';
import { accountTypeSelector, getConnectedUserLoginProvider } from '@lumapps/user/ducks/selectors';

import { EDIT_COMMUNITY_DIALOG_EVENT } from '../constants';
import { getDisplayedSteps } from '../utils/getDisplayedSteps';

/**
 * Retrieve the base configuration for the Configuration dialog
 * Used both for Spaces and communities
 * */
export const useConfigurationDialog = () => {
    const dispatch = useDispatch();
    const { id: spaceId, canEdit: canEditSpace, isSpace } = useSpace();
    const { canEditCommunity, currentCommunityId } = useCommunity();
    const isInstanceSuperAdmin = useSelector(isInstanceSuperAdminSelector);
    const customerId = useSelector(customerIdSelector);
    const instanceId = useSelector(instanceIdSelector);
    const userLanguage = useSelector(currentLanguageSelector);
    const userAccountType = useSelector(accountTypeSelector);
    const userLoginProvider = useSelector(getConnectedUserLoginProvider);
    // Listen to hash change in case dialog is opened from notification within a community.
    const [hash, setHash] = useHash();
    // Open dialog if redux state is at true or if hash is in url.
    const isOpen = useSelector(isConfigurationWizardOpenSelector) || hash === `#${EDIT_COMMUNITY_DIALOG_EVENT}`;

    const handleClose = React.useCallback(() => {
        dispatch(actions.closeConfigurationWizard());

        if (hash === `#${EDIT_COMMUNITY_DIALOG_EVENT}`) {
            // Remove hash on close.
            setHash('');
        }
    }, [dispatch, hash, setHash]);

    const displayedSteps = useMemo(
        () => getDisplayedSteps(isInstanceSuperAdmin, isSpace),
        [isSpace, isInstanceSuperAdmin],
    );

    const communityId = spaceId || currentCommunityId;
    const isConfigurationEnabled = (spaceId && canEditSpace) || (currentCommunityId && canEditCommunity);

    return {
        customerId,
        instanceId,
        userLanguage,
        userAccountType,
        userLoginProvider,
        isOpen,
        handleClose,
        displayedSteps,
        isConfigurationEnabled,
        communityId,
    };
};
