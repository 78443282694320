/* istanbul ignore file */
import React, { useRef } from 'react';

import { ShareContentMenuButton } from '@lumapps/content-share/components/ShareContentMenuButton';
import { useContentActions } from '@lumapps/sa-shareable-contents/hooks';
import { useTranslate } from '@lumapps/translations';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

export interface ShareContentProps {
    content: any;
}

export const ShareContent: React.FC<ShareContentProps> = ({ content }) => {
    useContentActions(content.uid);
    const { translateObject } = useTranslate();

    const contentTitle = translateObject(content.title) || '';
    const contentUrl = translateObject(content.canonicalUrl) || '';

    const uid = useRef(generateUUID());

    return (
        <ShareContentMenuButton
            preview={{
                id: content.uid,
                uid: uid.current,
                title: contentTitle,
                link: contentUrl,
            }}
        />
    );
};
