import { useMemo } from 'react';

import { mdiUndo, mdiRedo } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { PLUGIN_SHORTCUTS } from '@lumapps/wrex/constants';
import { ReactEditor, useSlate, HistoryEditor, useSlateStatic } from '@lumapps/wrex/slate';
import { ToolbarItem } from '@lumapps/wrex/types';

import { WREX_TYPOGRAPHY } from '../../../keys';
import { TypographyEditor } from '../../../types';

const ICONS = {
    undo: mdiUndo,
    redo: mdiRedo,
};

const KEYS = {
    undo: WREX_TYPOGRAPHY.UNDO_WITH_SHORTCUT,
    redo: WREX_TYPOGRAPHY.REDO_WITH_SHORTCUT,
};

/** Check if the editor has undo or redo operations in history. */
const hasOperations = (editor: HistoryEditor, type: 'undo' | 'redo') => {
    const operationArray = type === 'redo' ? 'redos' : 'undos';

    return editor.history[operationArray].some(
        // Has operations and operations are not only set_selection operations.
        ({ operations = [] }) =>
            operations.length && operations.some((operation) => operation.type !== 'set_selection'),
    );
};

export const useHistoryButton = (type: 'undo' | 'redo', otherProps?: any) => {
    const editor = useSlate() as ReactEditor & TypographyEditor & HistoryEditor;
    const editorStatic = useSlateStatic() as ReactEditor & TypographyEditor & HistoryEditor;
    const { translateAndReplace } = useTranslate();

    const isDisabled = !hasOperations(editor, type);

    return useMemo((): ToolbarItem => {
        return {
            type: 'option',
            itemKey: type,
            icon: ICONS[type],
            tooltipLabel: translateAndReplace(KEYS[type], {
                shortcut: PLUGIN_SHORTCUTS[type].shortcut,
            }),
            onClick() {
                if (type === 'undo') {
                    editorStatic.undo();
                } else {
                    editorStatic.redo();
                }
            },
            'aria-disabled': isDisabled,
            verticalModeProps: {
                label: translateAndReplace(KEYS[type], {
                    shortcut: PLUGIN_SHORTCUTS[type].shortcut,
                }),
                tooltipLabel: undefined,
            },
            otherProps,
        };
    }, [type, translateAndReplace, isDisabled, otherProps, editorStatic]);
};
