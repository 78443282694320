import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiContentCopy } from '@lumapps/lumx/icons';
import { Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { useCopyToClipboard } from '@lumapps/utils/hooks/useCopyToClipboard';

import { SHARE } from '../../keys';

interface ShareToCopyMenuItemProps {
    scope: string;
    link: string;
    copyFromValue?: string;
}

/**
 * Menu item coping the share link to the clipboard
 */
export const ShareToCopyMenuItem: React.FC<ShareToCopyMenuItemProps> = ({ link, scope, copyFromValue }) => {
    const { get } = useDataAttributes(scope);
    const { translateKey, translateAndReplace } = useTranslate();
    const copyToClipboard = useCopyToClipboard();
    const onClick = () => copyToClipboard(link);

    const menuLabel = copyFromValue
        ? translateAndReplace(SHARE.COPY_LINK_FROM, { TIME: copyFromValue || '' })
        : translateKey(SHARE.COPY_LINK);

    const action = copyFromValue ? 'copy-link-from' : 'copy-link';

    return (
        <Menu.Item icon={mdiContentCopy} onClick={onClick} {...get({ element: 'list-entry', action })}>
            <Text as="p" style={{ whiteSpace: 'break-spaces' }}>
                {menuLabel}
            </Text>
        </Menu.Item>
    );
};
