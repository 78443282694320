import { useQuery } from '@lumapps/base-api/react-query';
import { useSelector } from '@lumapps/redux/react';
import { isGoogleAccount } from '@lumapps/user/ducks/selectors';

import { BatchGoogleDriveFilesRequestParam, getBatchGoogleDriveFilesApi, googleDriveQueryKeys } from '../../api';

/**
 * Hook to fetch a batch of Google Drive files.
 *
 * @param apiParams - Parameters for the API request.
 * @param queryOptions - Options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
export function useGetGoogleDriveFiles(apiParams?: BatchGoogleDriveFilesRequestParam) {
    const isGoogleUser = useSelector(isGoogleAccount);

    const params = { ...apiParams, isGoogleUser };

    const query = useQuery({
        queryKey: googleDriveQueryKeys['batch-files'](params),
        queryFn: async ({ signal }) => getBatchGoogleDriveFilesApi(params, signal),
    });

    return query;
}
