import filter from 'lodash/filter';
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import loFind from 'lodash/find';

import { translateToApiV2Format } from '@lumapps/translations';

/////////////////////////////

function MetadataService(
    $q,
    $rootScope,
    CustomContentType,
    Customer,
    Instance,
    LumsitesBaseService,
    MetadataFactory,
    Translation,
    User,
    Utils,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(MetadataFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    // Store the base service methods to override them later but still be able to use them.
    service._delMetadata = service.del;
    service._saveMetadata = service.save;
    service._saveMulti = service.saveMulti;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The size of pages for retrieving the available metadata.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_MAX_RESULTS = 9999;

    /**
     * The list of filtered metadata (metadata for LxSelect for better performances).
     *
     * @type {Object}
     */
    const _filteredMetadata = {};

    /**
     * A list of metadata indexed by custom content type (id).
     *
     * @type {Object}
     */
    let _metadataListComputed = {};

    /**
     * The promise of the load of metadata.
     *
     * @type {Promise}
     */
    const _metadataLoadingDeferred = $q.defer();

    /**
     * The list of formatted metadata.
     *
     * @type {Array}
     */
    let _refactoredMetadata = [];

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The list key for getting the list of available metadata.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    service.AVAILABLE_METADATA_LIST_KEY = 'available';

    /**
     * The default parameters of the service.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    /**
     * Contains various indicators about the state of the service.
     *
     * @type {Object}
     */
    service.is = {
        initialized: false,
        initializing: false,
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Recursively get all the metadata for a given parent id.
     *
     * @param  {Array}  children The list of metadata in which to look for.
     * @param  {string} parentId The id of the parent metadata we want to get all children of (recursively).
     * @return {Array}  The list of all metadata for the parent id (recursively).
     */
    function _recursivelyGetMetadata(children, parentId) {
        // Get element that have my id as a parentId.
        if (!angular.isArray(children)) {
            return [];
        }

        let ids = [];
        angular.forEach(children, (metadata) => {
            if (metadata.parent !== parentId) {
                return;
            }

            const id = metadata.id || metadata.uid;

            ids.push(id);
            ids = ids.concat(_recursivelyGetMetadata(children, id));
        });

        return ids;
    }

    /**
     * Get the list of metadata ids related to the given parent.
     *
     * @param  {Array}   list                 The list of metadata we want to get the parentless metadata ids from.
     * @param  {string}  parentId             The id of the metadata we want to get the children of.
     * @param  {boolean} [includeParent=true] Indicates if we want to add the parent metadata id in the list?
     * @return {Array}   The list of all metadata related to the given metadata.
     */
    function _getMetadataIds(list, parentId, includeParent) {
        includeParent = includeParent || true;
        const ids = [];

        if (includeParent) {
            ids.push(parentId);
        }

        return ids.concat(_recursivelyGetMetadata(list, parentId));
    }

    /**
     * Delete a metadata and all of its children.
     *
     * @param {Array}  list The list of metadata we want to clean.
     * @param {string} id   The id of the metadata we want to delete.
     */
    function _deleteMetadata(list, id) {
        if (angular.isUndefinedOrEmpty(list)) {
            return;
        }

        const toRemove = _getMetadataIds(list, id);
        let len = list.length;

        while (len--) {
            const metadataId = list[len].id || list[len].uid;

            if (includes(toRemove, metadataId)) {
                list.splice(len, 1);
            }
        }
    }

    /**
     * Get a metadata from its key.
     *
     * @param  {Array}   refactoredMetadata           The list of metadata to find key in.
     * @param  {string}  key                          The key of the metadata we want to get.
     * @param  {boolean} [checkVisibilityFront=false] Indicates if we want to check if the metadata is visible in
     *                                                the front.
     * @return {Object}  The metadata.
     */
    function _findMetadataFromKey(refactoredMetadata, key, checkVisibilityFront) {
        let childMetadataObject;

        const metadataObj = loFind(refactoredMetadata, (metadata) => {
            const visibleFromFront =
                angular.isUndefined(checkVisibilityFront) || !checkVisibilityFront || metadata.isVisibleFront;

            if (metadata.key === key && visibleFromFront) {
                return true;
            }

            const childMetadata = loFind(metadata.items, (item) => {
                const childVisibleFromFront =
                    angular.isUndefined(checkVisibilityFront) || !checkVisibilityFront || metadata.isVisibleFront;

                if (item.key === key && childVisibleFromFront) {
                    childMetadataObject = item;
                    childMetadataObject.isVisibleFront = metadata.isVisibleFront;

                    return true;
                }

                return false;
            });

            return angular.isDefinedAndFilled(childMetadata);
        });

        return (
            childMetadataObject ||
            metadataObj || {
                name: '',
            }
        );
    }

    /**
     * Flatten a list of items.
     *
     * @param {Array} target Where to store the flattened tree.
     * @param {Array} items  The list of items to flatten.
     */
    function _flattenTree(target, items) {
        if (angular.isUndefinedOrEmpty(items)) {
            return;
        }

        angular.forEach(items, (item) => {
            target.push(item);

            if (angular.isDefinedAndFilled(item.items)) {
                _flattenTree(target, item.items);
            }
        });
    }

    /**
     * Format the metadata from server to client (array to object).
     *
     * @param {Object} obj                The object containing metadata to format.
     * @param {Array}  refactoredMetadata The list of refactored metadata.
     */
    function _formatMetadataForClient(obj, refactoredMetadata) {
        let objMetadata = obj.metadata;
        if (!angular.isArray(obj.metadata)) {
            objMetadata = [];
            angular.forEach(obj.metadata, (item) => {
                if (angular.isUndefinedOrEmpty(item)) {
                    return;
                }

                item = angular.isArray(item) ? item : [item];
                objMetadata = objMetadata.concat(item);
            });
        }

        const metadata = {};

        angular.forEach(refactoredMetadata, (refactoredMetadataItem) => {
            metadata[refactoredMetadataItem.key] = [];

            angular.forEach(refactoredMetadataItem.items, (item) => {
                if (angular.isUndefinedOrEmpty(item) || !includes(flattenDeep(objMetadata), item.key)) {
                    return;
                }

                if (refactoredMetadataItem.multiple) {
                    metadata[refactoredMetadataItem.key].push(item.key);
                } else {
                    metadata[refactoredMetadataItem.key] = item.key;
                }
            });
        });

        obj.metadata = metadata;
    }

    /**
     * Update the name of the metadata according to the current depth.
     * Add "--" for each depth level relative to the parent metadata.
     *
     * @param  {Object} metadataName The name of the metadata.
     * @param  {number} depth        The depth of the metadata.
     * @return {string} The updated name of the metadata according to its depth.
     */
    function _updateNameByDepth(metadataName, depth) {
        let startName = '';
        const returnName = angular.fastCopy(metadataName);

        for (let i = 1; i < depth; i++) {
            startName += '--';
        }

        angular.forEach(returnName, (translatedName, lang) => {
            returnName[lang] = `${startName} ${translatedName}`;
        });

        return returnName;
    }

    /**
     * Set the depth and name of metadata recursively.
     * Add "--" for each depth level relative to the parent metadata.
     *
     * @param {Array}  metadata  The metadata to setup.
     * @param {number} depth     The depth of the list's parent.
     * @param {string} topParent The top parent of the current metadata list.
     * @param {string} parent    The parent of the metadata in the list.
     */
    function _setDepthAndName(metadata, depth, topParent, parent) {
        angular.forEach(metadata, (item) => {
            item.depth = depth;
            item.name = _updateNameByDepth(item.name, depth);

            // Compute the path of the metadata.
            item.path =
                angular.isDefined(parent) && angular.isDefinedAndFilled(parent.path)
                    ? angular.fastCopy(parent.path)
                    : {};

            if (depth > 1 && angular.isDefinedAndFilled(parent)) {
                angular.forEach(parent.flatName, (parentName, lang) => {
                    if (angular.isUndefined(item.path[lang])) {
                        item.path[lang] = parentName;
                    } else {
                        item.path[lang] += ` > ${parentName}`;
                    }
                });
            }

            if (angular.isUndefinedOrEmpty(topParent)) {
                topParent = item.id;
            } else {
                item.topParent = topParent;
            }

            if (angular.isDefinedAndFilled(item.items)) {
                _setDepthAndName(item.items, depth + 1, topParent, item);
            }

            if (depth === 0) {
                topParent = undefined;
            }
        });
    }

    /**
     * Format the metadata list.
     */
    function _formatMetadataList() {
        const availableMetadata = service.getList(service.AVAILABLE_METADATA_LIST_KEY);

        if (angular.isUndefinedOrEmpty(_refactoredMetadata)) {
            _refactoredMetadata = [];
        } else {
            Utils.empty(_refactoredMetadata);
        }

        const nodes = [];
        const lookupList = {};

        // Populate the nodes and build an object to be able to lookup parents later on.
        angular.forEach(angular.fastCopy(availableMetadata), (item) => {
            const node = {
                customContentTypes: item.customContentTypes,
                depth: 0,
                description: item.description,
                displayInFilter: item.displayInFilter,
                flatName: item.name,
                functionalInnerId: item.functionalInnerId,
                heritable: item.heritable,
                id: item.id,
                instance: item.instance,
                isVisibleFront: item.isVisibleFront,
                items: [],
                key: item.id,
                multiple: item.multiple,
                name: item.name,
                parent: item.parent,
                values: item.values,
            };

            lookupList[node.id] = node;
            nodes.push(node);

            if (angular.isUndefinedOrEmpty(node.parent)) {
                _refactoredMetadata.push(node);
            }
        });

        // Build the nested tree.
        angular.forEach(nodes, (node) => {
            if (angular.isDefinedAndFilled(node.parent) && angular.isDefinedAndFilled(lookupList[node.parent])) {
                lookupList[node.parent].items.push(node);
            }
        });

        // Set the depth and name.
        _setDepthAndName(_refactoredMetadata, 0);

        // Flatten the tree.
        angular.forEach(_refactoredMetadata, (refactoredMetadata) => {
            const savedItems = angular.fastCopy(refactoredMetadata.items);

            refactoredMetadata.items = [];
            _flattenTree(refactoredMetadata.items, savedItems);
        });

        $rootScope.$broadcast('metadata-refactored');
    }

    /**
     * Init the filtered metadata list with some items.
     */
    function _initFilteredMetadata() {
        angular.forEach(service.getRefactoredMetadata(true), (refactoredMetadata) => {
            _filteredMetadata[refactoredMetadata.key] = refactoredMetadata.items;
        });
    }

    /**
     * Check the given content types belongs to the current instance's content types.
     *
     * @param  {Array}   contentTypes           The content types we want to check.
     * @param  {string}  instanceContentTypeIds The list of instance's content type's ids.
     * @return {boolean} If any of the content types belongs to the instance's content types.
     */
    function _isContentTypesInInstance(contentTypes, instanceContentTypeIds) {
        if (angular.isUndefinedOrEmpty(contentTypes)) {
            return false;
        }

        return angular.isDefinedAndFilled(intersection(instanceContentTypeIds, contentTypes));
    }

    /**
     * Check if a metadata (or any of its parents) is allowed in an instance.
     *
     * @param  {Object}  metadata       The metadata we want to check.
     * @param  {Object}  instance       The instance we want to check.
     * @param  {Array}   [metadataList] The metadata list where to check for parents.
     * @return {boolean} If metadata allowed in instance or not.
     */
    function _isMetadataAllowedInInstance(metadata, instance, metadataList) {
        metadataList = metadataList || [];

        let parent;
        if (angular.isDefinedAndFilled(metadata.parent)) {
            parent = loFind(metadataList, {
                id: metadata.parent,
            });
        }

        return (
            angular.isUndefinedOrEmpty(metadata.instance) ||
            metadata.instance === instance.id ||
            (metadata.heritable && metadata.instance === instance.parent) ||
            (angular.isDefinedAndFilled(parent) && parent.heritable && parent.instance === instance.parent)
        );
    }

    /**
     * Load all the available metadata for the instance. Get all the pages available.
     *
     * Available metadata includes all platform metadata (the full hierarchy), all instance's metadata (all
     * hierarchy), all metadata that doesn't belong to any instance (the full hierarchy too) or parent instance's
     * heritable metadata (and their direct child).
     *
     * @param {string}   [cursor] The cursor to use to get the next page.
     *                            If none given, then we want to load the first page.
     * @param {Function} [endCb]  The function to call when the list has ended.
     * @param {Function} [errCb]  The function to call when there is an error with any of the pages.
     */
    function _listAvailableMetadata(cursor, endCb, errCb) {
        endCb = endCb || angular.noop;
        errCb = errCb || angular.noop;

        MetadataFactory.listAvailable({
            cursor,
            // TODO [Clément]: implement projection for retrieving only what's necessary from the metadata.
            fields: undefined,
            instance: Instance.getCurrentInstanceId(),
            maxResults: _DEFAULT_MAX_RESULTS,
            withInheritance: true,
        })
            .$promise.then((response) => {
                angular.forEach(response.items, (metadata) => {
                    service.getList(service.AVAILABLE_METADATA_LIST_KEY).push(metadata);
                });

                if (response.more) {
                    _listAvailableMetadata(response.cursor, endCb, errCb);
                } else {
                    service.refactor();

                    endCb(service.getList(service.AVAILABLE_METADATA_LIST_KEY));
                }
            })
            .catch(endCb);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Delete a metadata and refactor them afterwards.
     *
     * @param  {Object}   params  The parameters of the delete request.
     * @param  {Function} [cb]    The callback to execute when the deletion is successfull.
     * @param  {Function} [errCb] The callback to execute in case of a deletion error.
     * @param  {string}   listKey The list key of the delete request.
     * @return {Promise}  The promise of the delete request.
     */
    function del(params, cb, errCb, listKey) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        return MetadataFactory.delete(
            params,
            (response) => {
                _deleteMetadata(service.getList(undefined, undefined, listKey), params.uid);

                service.getAvailableMetadata(false).then((availableMetadata) => {
                    _deleteMetadata(availableMetadata, params.uid);

                    // Refactor the metadata.
                    service.refactor();

                    cb(response);
                });
            },
            errCb,
            listKey,
        );
    }

    /**
     * Filter the local list of metadata (to improve LxSelect performances).
     *
     * @param  {string}  key     The key of the parent metadata we want to filter the list from.
     * @param  {string}  [value] The value to use to filter the metadata (with name or path).
     * @return {Promise} The promise of the filtering of the metadata that resolves with the filtered metadata for
     *                   the given key.
     */
    function filterAllMetadata(key, value) {
        value = (value || '').toLowerCase();

        let metadataItems = [];

        return $q((resolve, reject) => {
            service
                .getRefactoredMetadata(false)
                .then((refactoredMetadata) => {
                    angular.forEach(refactoredMetadata, (metadata) => {
                        if (metadata.key === key) {
                            metadataItems = metadata.items;
                        }
                    });

                    _filteredMetadata[key] = filter(metadataItems, (item) => {
                        const metadataName = (Translation.translate(item.name) || '').toLowerCase();
                        const metadataPath = (Translation.translate(item.path) || '').toLowerCase();

                        return includes(metadataName, value) || includes(metadataPath, value);
                    });

                    resolve(_filteredMetadata[key]);
                })
                .catch(reject);
        });
    }

    /**
     * Format the metadata from server to client (array to object).
     *
     * @param  {Object}  obj          The object containing metadata to format.
     * @param  {boolean} [sync=false] Indicates if we want to format the currently available metadata (directly,
     *                                without promise) or wait to be sure that the metadata has been loaded (and
     *                                thus, returning a promise).
     * @return {Promise} The promise of the formatting of the metadata that resolves with the updated object.
     */
    function formatMetadataForClient(obj, sync) {
        if (angular.isUndefinedOrEmpty(get(obj, 'metadata'))) {
            return $q.resolve(obj);
        }

        if (sync) {
            _formatMetadataForClient(obj, service.getRefactoredMetadata(true));

            return $q.resolve(obj);
        }

        return $q((resolve, reject) => {
            service
                .getRefactoredMetadata(false)
                .then((refactoredMetadata) => {
                    _formatMetadataForClient(obj, refactoredMetadata);

                    resolve(obj);
                })
                .catch(reject);
        });
    }

    /**
     * Format the metadata from client to server (object to array).
     *
     * @param {Object} obj The object containing metadata to format.
     */
    function formatMetadataForServer(obj) {
        let metadataList = [];

        angular.forEach(obj.metadata, (metadata) => {
            if (angular.isUndefinedOrEmpty(metadata)) {
                return;
            }

            if (angular.isString(metadata)) {
                metadataList.push(metadata);
            } else if (angular.isArray(metadata)) {
                metadataList = metadataList.concat(metadata);
            }
        });

        obj.metadata = metadataList;
    }

    /**
     * Get all available metadata.
     *
     * Available metadata includes all platform metadata (the full hierarchy), all instance's metadata (all
     * hierarchy), all metadata that doesn't belong to any instance (the full hierarchy too) or parent instance's
     * heritable metadata (and their direct child).
     *
     * @param  {boolean}       [sync=false] Indicates if we want to have the currently available value (directly,
     *                                      without promise) or wait to be sure that the metadata has been loaded
     *                                      (and thus, returning a promise).
     * @return {Promise|Array} The available metadata in sync mode, or a promise that resolve with the available
     *                         metadata.
     */
    function getAvailableMetadata(sync) {
        if (sync) {
            return service.getList(service.AVAILABLE_METADATA_LIST_KEY) || [];
        }

        return $q((resolve, reject) => {
            if (service.is.initializing && angular.isDefined(_metadataLoadingDeferred.promise)) {
                _metadataLoadingDeferred.promise
                    .then((availableMetadata) => {
                        resolve(availableMetadata || []);
                    })
                    .catch(reject);
            } else {
                resolve(service.getList(service.AVAILABLE_METADATA_LIST_KEY) || []);
            }
        });
    }

    /**
     * Get a metadata from its key if it can be displayed in the front.
     *
     * @param  {string}        key                  The key of the metadata we want to get.
     * @param  {boolean}       [isTranslated=false] Indicates if we want to get the metadata object or an HTML
     *                                              string of the translated name of the metadata.
     * @param  {boolean}       [isFirst=false]      Indicates if it's the first metadata of the list (and thus
     *                                              should be displayed with a leading dash).
     * @return {Object|string} The metadata (or HTML string representing the metadata).
     */
    function getAvailableMetadataFromKey(key, isTranslated, isFirst) {
        const metadata = service.getMetadataFromKey(key, false, true);
        if (angular.isUndefinedOrEmpty(metadata)) {
            return undefined;
        }

        const metadataParent = service.getMetadataFromKey(metadata.topParent, false, true);
        if (angular.isUndefinedOrEmpty(metadataParent) || !metadataParent.isVisibleFront) {
            return undefined;
        }

        if (isTranslated) {
            const htmlMetadata = `<span>${Translation.translate(metadata.flatName)}</span>`;

            return isFirst ? htmlMetadata : ` <span>-</span> ${htmlMetadata}`;
        }

        return metadata;
    }

    /**
     * Get the filtered list of metadata.
     *
     * @param  {string}        key          The key of the parent metadata we want to get the list from.
     * @param  {boolean}       [sync=false] Indicates if we want to have the currently available value (directly,
     *                                      without promise) or wait to be sure that the filtered metadata has been
     *                                      loaded (and thus, returning a promise).
     * @return {Promise|Array} The filtered metadata in sync mode, or a promise that resolves with the filtered
     *                         metadata.
     */
    function getFilteredMetadata(key, sync) {
        if (sync) {
            return get(_filteredMetadata, key, []);
        }

        return $q((resolve, reject) => {
            if (service.is.initializing && angular.isDefined(_metadataLoadingDeferred.promise)) {
                _metadataLoadingDeferred.promise
                    .then(() => {
                        resolve(get(_filteredMetadata, key, []));
                    })
                    .catch(reject);
            } else {
                resolve(get(_filteredMetadata, key, []));
            }
        });
    }

    /**
     * Get the classes for all given metadata (prefixed if necessary).
     *
     * @param  {Array|Object} metadataKeysList The list of metadata's keys we want to get the class of.
     * @param  {string}       [prefix]         The prefix to append to the class name.
     * @return {Array}        The list of metadata's classes (prefixed if necessary).
     */
    function getMetadataClasses(metadataKeysList, prefix) {
        let metadata;
        const metadataClasses = [];

        prefix = prefix || '';

        angular.forEach(metadataKeysList, (key) => {
            if (angular.isArray(key)) {
                angular.forEach(key, (metadataKey) => {
                    metadata = service.getMetadataFromKey(metadataKey, false, true);

                    if (angular.isDefined(metadata) && angular.isDefinedAndFilled(metadata.functionalInnerId)) {
                        metadataClasses.push(prefix + metadata.functionalInnerId);
                    }
                });
            } else {
                metadata = service.getMetadataFromKey(key, false, true);

                if (angular.isDefined(metadata) && angular.isDefinedAndFilled(metadata.functionalInnerId)) {
                    metadataClasses.push(prefix + metadata.functionalInnerId);
                }
            }
        });

        return metadataClasses;
    }

    /**
     * Get a list of metadata that contains a property with the given value.
     *
     * @param  {string}       property     The name of the property we want to check in the metadata.
     * @param  {*}            value        The value the property should have.
     * @param  {Array|Object} metadataList The list of metadata to search in.
     * @return {Array}        A list of metadata having the given property with the given value.
     */
    function getMetadataFilteredByProperty(property, value, metadataList) {
        const matchingMetadata = [];

        angular.forEach(metadataList, (metadata) => {
            if (angular.isDefined(metadata[property]) && metadata[property] === value) {
                matchingMetadata.push(metadata);
            }
        });

        return matchingMetadata;
    }

    /**
     * Get a metadata from its key.
     *
     * @param  {string}         key                          The key of the metadata we want to get.
     * @param  {boolean}        [checkVisibilityFront=false] Indicates if we want to check if the metadata is
     *                                                       visible in the front.
     * @param  {boolean}        [sync=false]                 Indicates if we want to have the currently available
     *                                                       value (directly, without promise) or wait to be sure
     *                                                       that the filtered metadata has been loaded (and thus,
     *                                                       returning a promise).
     * @return {Object|Promise} The metadata.
     */
    function getMetadataFromKey(key, checkVisibilityFront, sync) {
        if (sync) {
            return _findMetadataFromKey(service.getRefactoredMetadata(true), key, checkVisibilityFront);
        }

        return $q((resolve, reject) => {
            service
                .getRefactoredMetadata(false)
                .then((refactoredMetadata) => {
                    resolve(_findMetadataFromKey(refactoredMetadata, key, checkVisibilityFront));
                })
                .catch(reject);
        });
    }

    /**
     * Get a list of metadata keys from a functional inner id.
     *
     * @param  {string} functionalInnerId The functional inner id to use to find the metadata.
     * @return {Array}  The list of metadata keys.
     */
    function getMetadataKey(functionalInnerId) {
        const metadataKeys = [];

        angular.forEach(service.getRefactoredMetadata(true), (refactoredMetadata) => {
            if (refactoredMetadata.functionalInnerId === functionalInnerId) {
                metadataKeys.push(refactoredMetadata.key);
            }

            angular.forEach(refactoredMetadata.items, (item) => {
                if (item.functionalInnerId === functionalInnerId) {
                    metadataKeys.push(item.key);
                }
            });
        });

        return metadataKeys;
    }

    /**
     * Get a parent refactored metadata from its id.
     *
     * @param  {string} id The id of the parent metadata we want to get.
     * @return {Object} The parent refactored metadata.
     */
    function getParentRefactoredMetadata(id) {
        return loFind(service.getRefactoredMetadata(true), {
            id,
        });
    }

    /**
     * Get the list of refactored metadata.
     *
     * @param  {boolean}       [sync=false] Indicates if we want to have the currently available value (directly,
     *                                      without promise) or wait to be sure that the refactored metadata has
     *                                      been loaded (and thus, returning a promise).
     * @return {Promise|Array} The refactored metadata in sync mode, or a promise that resolve with the refactored
     *                         metadata.
     */
    function getRefactoredMetadata(sync) {
        if (sync) {
            return _refactoredMetadata || [];
        }

        return $q((resolve, reject) => {
            if (angular.isDefined(_metadataLoadingDeferred.promise)) {
                _metadataLoadingDeferred.promise
                    .then(() => {
                        resolve(_refactoredMetadata || []);
                    })
                    .catch(reject);
            }
        });
    }

    /**
     * Check if the metadata is displayable in the content.
     *
     * @param  {Array|string} metadataKeys The current metadataKeys
     * @return {boolean}      If the metadata is displayable.
     */
    function isDisplayableMetadata(metadataKeys) {
        return (
            Utils.isDefinedAndFilled(metadataKeys) &&
            ((isArray(metadataKeys) &&
                service.getMetadataFromKey(
                    service.getMetadataFromKey(metadataKeys[0], false, true).topParent,
                    false,
                    true,
                ).isVisibleFront) ||
                (!isArray(metadataKeys) &&
                    service.getMetadataFromKey(
                        service.getMetadataFromKey(metadataKeys, false, true).topParent,
                        false,
                        true,
                    ).isVisibleFront))
        );
    }

    /**
     * Check if the metadata has been deleted.
     * [TODO: Philippe] The backend needs to clean all metadata family picker after a medatata family suppression.
     *
     * @param  {string}  metadataId The id of the metadata to check.
     * @return {boolean} If the metadata has been deleted.
     */
    function isMetadataDeleted(metadataId) {
        return angular.isUndefinedOrEmpty(get(service.getMetadataFromKey(metadataId, false, true), 'name'));
    }

    /**
     * Check if a metadata is multiple.
     *
     * @param  {string}  id The id of the metadata to check.
     * @return {boolean} If the metadata is multiple or not.
     */
    function isMultipleMetadata(id) {
        const parentMeta = service.getParentRefactoredMetadata(id);

        return get(parentMeta, 'multiple', false);
    }

    /**
     * List all metadata of a given custom content type from its id.
     *
     * @param  {string} cctId         The id of the custom content type we want to get the metadata of.
     * @param  {string} [parentCctId] The id of the parent custom content type we want to get the metadata of.
     *                                Used for heritable `Page` or `News` metadata.
     * @return {Array}  The list of metadata of the given custom content type.
     */
    function listByCustomContentTypeId(cctId, parentCctId) {
        const metadata = service.getRefactoredMetadata(true);

        if (angular.isUndefinedOrEmpty(cctId)) {
            return metadata;
        }

        if (angular.isUndefinedOrEmpty(_metadataListComputed[cctId])) {
            if (angular.isUndefined(_metadataListComputed[cctId])) {
                _metadataListComputed[cctId] = [];
            }

            const instanceContentTypeIds = CustomContentType.displayList(`ids-${Instance.getCurrentInstanceId()}`);

            angular.forEach(metadata, (item) => {
                if (
                    angular.isDefinedAndFilled(item.customContentTypes) &&
                    !includes(item.customContentTypes, cctId) &&
                    (angular.isUndefinedOrEmpty(parentCctId) || !includes(item.customContentTypes, parentCctId))
                ) {
                    return;
                }
                /*
                 * Keep the metadata if it belongs to the current instance, if there is no instance in the metadata
                 * or if the metadata's parent belong to the current instance.
                 */
                const haveCctOrNoneOfInstanceCct = !_isContentTypesInInstance(
                    item.customContentTypes,
                    instanceContentTypeIds,
                );
                const validCustomContentTypes =
                    (angular.isUndefined(item.customContentTypes) || angular.isArray(item.customContentTypes)) &&
                    haveCctOrNoneOfInstanceCct;

                // If has no content types or if the right content types is in.
                if (_isMetadataAllowedInInstance(item, Instance.getInstance(), metadata) && validCustomContentTypes) {
                    _metadataListComputed[cctId].push(item);
                }
            });
        }

        return _metadataListComputed[cctId];
    }

    /**
     * List all metadata of all given custom content type from their ids.
     *
     * @param  {Array} cctIds The list of ids of the custom content types we want to get the metadata of.
     * @return {Array} The list of metadata of all the given custom content types.
     */
    function listByCustomContentTypeIds(cctIds) {
        if (!angular.isArray(cctIds) || angular.isUndefinedOrEmpty(cctIds)) {
            return [];
        }

        const filteredMeta = [];
        const metadataIdInList = {};

        angular.forEach(cctIds, (cctId) => {
            const metadataByCct = service.listByCustomContentTypeId(cctId);

            // Remove already set metadata.
            angular.forEach(metadataByCct, (metadata) => {
                if (!metadataIdInList[metadata.id]) {
                    metadataIdInList[metadata.id] = true;
                    filteredMeta.push(metadata);
                }
            });
        });

        return filteredMeta;
    }

    /**
     * Transform a full metadata to its key.
     * This is mainly used by LumX lxSelect "selectionToModel".
     *
     * @param  {Object}   metadata The metadata we want to transform to its key.
     * @param  {Function} [cb]     The lxSelect callback function.
     * @return {string}   The key of metadata.
     */
    function metadataToMetadataKeys(metadata, cb) {
        cb = cb || angular.noop;
        const key = get(metadata, 'key');

        cb(key);

        return key;
    }

    /**
     * Get the `metadataDetails` property from all the currently selected metadata
     * This object is used in NGI in order to display the metadata list in widgets.
     * @param {Object} obj - content metadata map
     * */
    function getMetadataDetails(obj) {
        if (angular.isUndefinedOrEmpty(obj)) {
            return [];
        }

        return Object.values(obj)
          .flat()
          .reduce((acc, curr) => {
              if (angular.isDefinedAndFilled(curr)) {
                  const metadataFromKey = getMetadataFromKey(curr, true, true);
                  if(metadataFromKey && metadataFromKey.name) {
                      acc.push({
                          id: metadataFromKey.key,
                          rootId: metadataFromKey.topParent,
                          parentId: metadataFromKey.parent,
                          name: translateToApiV2Format(metadataFromKey.name, Translation.getLang('current')),
                          description: angular.isDefinedAndFilled(metadataFromKey.description)
                            ? translateToApiV2Format(metadataFromKey.description, Translation.getLang('current'))
                            : undefined,
                      });
                  }
              }
              return acc;
          }, []);
    }

    /**
     * Transform a metadata's key to a full metadata.
     * This is mainly used by LumX lxSelect "modelToSelection".
     *
     * @param {Object}   key  The key of the metadata we want to get.
     * @param {Function} [cb] The lxSelect callback function.
     */
    function metadataKeysToMetadata(key, cb) {
        cb = cb || angular.noop;

        if (angular.isUndefinedOrEmpty(key)) {
            cb();

            return;
        }

        const matchingMetadata = [];

        service.getRefactoredMetadata(false).then((refactoredMetadata) => {
            angular.forEach(refactoredMetadata, (refactoredMetadataItem) => {
                if (refactoredMetadataItem.key === key) {
                    cb(refactoredMetadataItem);
                    matchingMetadata.push(refactoredMetadataItem);
                }

                if (angular.isDefinedAndFilled(refactoredMetadataItem.items)) {
                    angular.forEach(refactoredMetadataItem.items, (item) => {
                        if (item.key === key) {
                            cb(item);
                            matchingMetadata.push(item);
                        }
                    });
                }
            });
        });
    }

    /**
     * Format metadata recursively and add them to a list.
     */
    function refactor() {
        _formatMetadataList();
        _initFilteredMetadata();

        _metadataListComputed = {};
    }

    /**
     * Save the metadata and refactor them after.
     *
     * @param  {Object}   metadataToSave The object
     * @param  {number}   sortOrder      The sort order of the metadata.
     * @param  {Function} [cb]           The callback to execute when the save is successfull.
     * @param  {Function} [errCb]        The callback to execute in case of a save error.
     * @param  {string}   listKey        The list key of the save.
     * @return {Promise}  The promise of the save.
     */
    function save(metadataToSave, sortOrder, cb, errCb, listKey) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        // SortOrder is removed from the query if untouched to prevent back from recomputing.
        return service._saveMetadata(
            {
                ...metadataToSave,
                sortOrder,
            },
            (response) => {
                let responseUpdated = false;

                service.getAvailableMetadata(false).then((customerMetadata) => {
                    for (let i = 0, len = customerMetadata.length; i < len; i++) {
                        if (customerMetadata[i].id === response.key) {
                            customerMetadata[i] = angular.fastCopy(response);
                            responseUpdated = true;

                            break;
                        }
                    }

                    if (!responseUpdated) {
                        customerMetadata.push(response);
                    }

                    // Refactor the metadata.
                    service.refactor();

                    cb(response);
                });
            },
            errCb,
            listKey,
        );
    }

    /**
     * Save multiple metadata and refactor them after.
     *
     * @param  {Object}   params  The parameters of the save.
     * @param  {Function} [cb]    The callback to execute when the save is successfull.
     * @param  {Function} [errCb] The callback to execute in case of a save error.
     * @return {Promise}  The promise of the multiple save.
     */
    function saveMulti(params, cb, errCb) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        return MetadataFactory.saveMulti(
            undefined,
            params,
            (response) => {
                service.getAvailableMetadata(false).then((customerMetadata) => {
                    for (let i = 0, len = customerMetadata.length; i < len; i++) {
                        for (let j = 0, lenJ = response.length; j < lenJ; j++) {
                            if (customerMetadata[i].id === response[j].id) {
                                customerMetadata[i] = angular.fastCopy(response[j]);

                                break;
                            }
                        }
                    }

                    // Refactor the metadata.
                    service.refactor();
                    cb(response);
                });
            },
            errCb,
        );
    }

    /**
     * Set the order on a given metadata list.
     *
     * @param {Object} metadata The metadata to set a new order value for.
     * @param {number} index    The new position index of the metadata.
     * @param {string} listKey  The identifier of the list the metadata is part of.
     */
    function setOrder(metadata, index, listKey) {
        if (angular.isUndefinedOrEmpty([metadata, index], 'some')) {
            return;
        }

        const list = service.displayList(listKey);
        const indexItemToRemove = list.findIndex((meta, metaIndex) => meta.id === metadata.id && metaIndex !== index);

        if (indexItemToRemove >= 0) {
            list.splice(indexItemToRemove, 1);
        }

        const newItemPosition = list.findIndex((meta) => meta.id === metadata.id);

        service.save(metadata, newItemPosition, undefined, Utils.displayServerError, listKey);
    }

    /////////////////////////////

    service.del = del;
    service.filterAllMetadata = filterAllMetadata;
    service.formatMetadataForClient = formatMetadataForClient;
    service.formatMetadataForServer = formatMetadataForServer;
    service.getAvailableMetadata = getAvailableMetadata;
    service.getAvailableMetadataFromKey = getAvailableMetadataFromKey;
    service.getFilteredMetadata = getFilteredMetadata;
    service.getMetadataClasses = getMetadataClasses;
    service.getMetadataFilteredByProperty = getMetadataFilteredByProperty;
    service.getMetadataFromKey = getMetadataFromKey;
    service.getMetadataKey = getMetadataKey;
    service.getMetadataDetails = getMetadataDetails;
    service.getParentRefactoredMetadata = getParentRefactoredMetadata;
    service.getRefactoredMetadata = getRefactoredMetadata;
    service.isDisplayableMetadata = isDisplayableMetadata;
    service.isMetadataDeleted = isMetadataDeleted;
    service.isMultipleMetadata = isMultipleMetadata;
    service.listByCustomContentTypeId = listByCustomContentTypeId;
    service.listByCustomContentTypeIds = listByCustomContentTypeIds;
    service.metadataKeysToMetadata = metadataKeysToMetadata;
    service.metadataToMetadataKeys = metadataToMetadataKeys;
    service.refactor = refactor;
    service.save = save;
    service.saveMulti = saveMulti;
    service.setOrder = setOrder;

    /////////////////////////////

    /**
     * Initialize the controller.
     *
     * @return {Promise} A promise that will resolve when the metadata has been initialized.
     */
    service.init = function init() {
        service.defaultParams = {
            customerId: Customer.getCustomerId(),
        };

        if (!User.isConnected()) {
            _metadataLoadingDeferred.resolve();

            return _metadataLoadingDeferred.promise;
        }

        if (service.is.initializing) {
            return _metadataLoadingDeferred.promise;
        }

        service.is.initializing = true;

        service.initList(service.AVAILABLE_METADATA_LIST_KEY, {}, [], true, undefined);
        _listAvailableMetadata(undefined, _metadataLoadingDeferred.resolve, _metadataLoadingDeferred.reject);

        _metadataLoadingDeferred.promise.finally(() => {
            service.is.initialized = true;
            service.is.initializing = false;
        });

        return _metadataLoadingDeferred.promise;
    };

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Metadata', MetadataService);

/////////////////////////////

export { MetadataService };
