/**
 * CF:
 * https://cdn.jsdelivr.net/npm/emoji-picker-element-data@1.6.0/
 */
export const SUPPORTED_EMOJI_LANGUAGES = [
    'bn',
    'da',
    'de',
    'en',
    'en-gb',
    'es',
    'es-mx',
    'et',
    'fi',
    'fr',
    'hi',
    'hu',
    'it',
    'ja',
    'ko',
    'lt',
    'ms',
    'nb',
    'nl',
    'pl',
    'pt',
    'ru',
    'sv',
    'th',
    'uk',
    'zh',
    'zh-hant',
];
