import { ALIGNMENTS } from '@lumapps/wrex/constants';
import { Converter } from '@lumapps/wrex/serialization/dita/toDITA/types';

import { ParagraphElement } from '../../types';

/** Convert slate paragraph block to DITA paragraph. */
export const PARAGRAPH_CONVERT: Converter<ParagraphElement> = (node, children = []) => {
    const alignment = node?.alignment ? ALIGNMENTS[node.alignment] : undefined;
    return {
        type: 'element',
        name: 'p',
        attributes: alignment ? { outputclass: `align-${alignment}` } : undefined,
        children,
    };
};

/** Convert slate italic mark to DITA italic */
export const ITALIC_CONVERT: Converter = (node, children = []) => ({
    type: 'element',
    name: 'i',
    children,
});

/** Convert slate bold mark to DITA bold */
export const BOLD_CONVERT: Converter = (node, children = []) => ({
    type: 'element',
    name: 'b',
    children,
});

/** Convert slate underline mark to DITA underline */
export const UNDERLINE_CONVERT: Converter = (node, children = []) => ({
    type: 'element',
    name: 'u',
    children,
});

/** Convert slate strikethrough mark to DITA underline */
export const STRIKETHROUGH_CONVERT: Converter = (node, children = []) => ({
    type: 'element',
    name: 'line-through',
    children,
});

/** Convert slate inline code mark to DITA codeph */
export const CODE_CONVERT: Converter = (node, children = []) => ({
    type: 'element',
    name: 'codeph',
    children,
});

/** Convert slate code block to DITA code block. */
export const CODE_BLOCK_CONVERT: Converter = (node, children = []) => ({
    type: 'element',
    name: 'codeblock',
    children,
});

/** Convert slate color to DITA color. */
export const COLORED_TEXT_CONVERT: Converter<string> = (color, children = []) => {
    return {
        type: 'element',
        name: 'ph',
        attributes: {
            outputclass: `color-${color?.slice(1)}`,
        },
        children,
    };
};
