import React from 'react';

import { usePersistentStore } from '@lumapps/cache/hooks/usePersistentStore';
import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { BROADCAST_ICON, BROADCAST_LIST_VIEW, BROADCAST_LIST_VIEW_STORAGE_KEY } from '../../constants';
import { BROADCASTS } from '../../keys';
import { broadcastCalendarRoute, broadcastRoutes, broadcastTimelineRoute } from '../../routes';

export interface BroadcastSideNavigationProps extends Partial<SideNavigationItemProps> {
    /** True if the user has access to the broadcasts section */
    isAllowed: boolean;
}

/**
 * The broadcast entry in the back-office side navigation
 * @param props BroadcastSideNavigationProps
 * @returns BroadcastSideNavigation
 */
const BroadcastSideNavigation = (props: Partial<BroadcastSideNavigationProps>) => {
    const { isAllowed } = props;

    const { translateKey } = useTranslate();

    const [stored] = usePersistentStore(BROADCAST_LIST_VIEW_STORAGE_KEY, { view: BROADCAST_LIST_VIEW.TIMELINE });

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(BROADCASTS.TITLE)} icon={BROADCAST_ICON} id="broadcasts">
            <SideNavigationItem
                /**
                 * Here, we cannot use broadcastRoutes.getDefaultRoute() or link will never change while the user remains in BO
                 * With usePersistentStore, we listen to cache change and update link accordingly
                 */
                route={stored.view === BROADCAST_LIST_VIEW.CALENDAR ? broadcastCalendarRoute : broadcastTimelineRoute}
                label={translateKey(BROADCASTS.SIDE_NAV_TITLE)}
            />
            <SideNavigationItem route={broadcastRoutes.settingsRoute} label={translateKey(GLOBAL.SETTINGS)} />
        </SideNavigationItem>
    );
};

BroadcastSideNavigation.displayName = 'SideNavigationItem';

export { BroadcastSideNavigation };
