import type { ObjectValues } from '@lumapps/utils/types/ObjectValues';

export type BreakpointsObject<K extends string> = Record<K, number>;

/**
 * Changes an object of breakpoints in array of breakpoint names, ordered from smallest to biggest
 * Input: { one: 100, three: 300, two: 200, }
 * Output: ['one', 'two', 'three']
 */
function getOrderedBreakpoints<K extends string>(breakpoints: BreakpointsObject<K>): K[] {
    const entries = Object.entries(breakpoints) as [K, ObjectValues<typeof breakpoints>][];

    return entries.sort(([, valueA], [, valueB]) => valueA - valueB).map(([key]) => key);
}

/**
 * Typed version of indexOf in a curry way
 *      const getIndexOf = getBreakpointIndex(breakpoints)
 *      getIndexOf('my-breakpoint') // -1, 0, 1, 2...
 * This function requires the result of getOrderedBreakpoints as first param, hence why they are "chained" in isBreakpointMatching to avoid doing it manually
 */
function getBreakpointIndex<T extends string>(orderedBreakpoints: T[]) {
    return (breakpoint: T) => orderedBreakpoints.indexOf(breakpoint);
}

enum BreakpointMatchingOperator {
    exactly = 'exactly',
    atLeast = 'atLeast',
    atMost = 'atMost',
    biggerThan = 'biggerThan',
    smallerThan = 'smallerThan',
}

export type BaseIsMatching<T> = (referenceBreakpoint: T) => boolean;

/**
 * Returns a function that aims to compare a breakpoint index (from the result of getOrderedBreakpoints)
 * to another breakpoint that we do not know the index yet, according to a specidifc operator.
 * That's why the 2nd param is a function to retrieve the index from the breakpoint name
 * Use:
 *      const matchSmallerThan = getBreakpointMatchingKey('smallerThan')
 *      const matchExactly = getBreakpointMatchingKey('exactly')
 *      ...
 *      const myBreakpointIndex = 3
 *      const getIndex = (breakpoint) => myBreakpoints.indexOf(breakpoint)
 *      ...
 *      const isMyBreakpointSmallerThan = matchSmallerThan(myBreakpointIndex, getIndex)
 *      const isMyBreakpointExactly = matchExactly(myBreakpointIndex, getIndex)
 *      ...
 *      isMyBreakpointSmallerThan('a-specific-breakpoint')
 *      --> returns true if 3 is striclty smaller than getIndex('a-specific-breakpoint')
 *      --> returns false (if not)
 *      isMyBreakpointExactly('a-specific-breakpoint')
 *      --> returns true if 3 equals to getIndex('a-specific-breakpoint')
 *      --> returns false (if not)
 */
const getBreakpointMatchingKey =
    <T>(operator: BreakpointMatchingOperator) =>
    (breakpointIndex: number, getIndexOfBreakpoint: (breakpoint: T) => number): BaseIsMatching<T> | null => {
        switch (operator) {
            case BreakpointMatchingOperator.exactly:
                return (referenceBreakpoint: T) => breakpointIndex === getIndexOfBreakpoint(referenceBreakpoint);
            case BreakpointMatchingOperator.atLeast:
                return (referenceBreakpoint: T) => breakpointIndex >= getIndexOfBreakpoint(referenceBreakpoint);
            case BreakpointMatchingOperator.atMost:
                return (referenceBreakpoint: T) => breakpointIndex <= getIndexOfBreakpoint(referenceBreakpoint);
            case BreakpointMatchingOperator.biggerThan:
                return (referenceBreakpoint: T) => breakpointIndex > getIndexOfBreakpoint(referenceBreakpoint);
            case BreakpointMatchingOperator.smallerThan:
                return (referenceBreakpoint: T) => breakpointIndex < getIndexOfBreakpoint(referenceBreakpoint);
            default:
                // for typing reason only since we are not allowed to call this function with a wrong operator
                return null;
        }
    };

/**
 * Depending on the use, each matching key can return either a boolean (isMatching.exactly === true), or a function
 * receiving a reference breakpoint to compare to and returnting a boolean (isMatching.exactly('this-breakpoint') === true)
 * This is what the type M represents
 */
export interface BreakpointMatches<T extends string, M extends boolean | BaseIsMatching<T>> {
    exactly: M;
    atLeast: M;
    atMost: M;
    biggerThan: M;
    smallerThan: M;
}
export interface IsBreakpointMatching<
    // the type of breakpoints = all possible values of breakpoint ('full' | 'no-video' | ...)
    T extends string,
    // the matches return type (object with all matches, which are either booleans or functions returning a boolean)
    M extends boolean | BaseIsMatching<T> = boolean | BaseIsMatching<T>,
    // the 'all' type (either object with all matches of function returning the object with all matches)
    A extends BreakpointMatches<T, boolean> | ((referenceBreakpoint: T) => BreakpointMatches<T, boolean>) =
        | BreakpointMatches<T, boolean>
        | ((referenceBreakpoint: T) => BreakpointMatches<T, boolean>),
> extends BreakpointMatches<T, M> {
    all: A;
    // list of all breakpoints, ordered from smaller to bigger
    orderedBreakpoints: T[];
    // callback to provide the index of any breakpoint in the list
    getIndexOf: ReturnType<typeof getBreakpointIndex<T>>;
    // index of given breakpoint in the list
    breakpointIndex: number;
    // given breakpoint
    breakpoint: T;
}

/**
 * Return type of isMatching with only 1 param: isMatching(breakpoints)
 * The function will return functions to be executed with the missing param
 *      const { exactly, all } = isMatching(breakpoints)
 *      exactly(referenceBreakpoint) // true/false
 *      const { biggerThan } = all(referenceBreakpoint)
 *      biggerThan // true/false
 */
export type IsBreakpointMatchingCurrying<T extends string> = IsBreakpointMatching<
    T,
    BaseIsMatching<T>,
    (referenceBreakpoint: T) => BreakpointMatches<T, boolean>
>;
/**
 * Return type of isMatching with 2 params: isMatching(breakpoints, referenceBreakpoint)
 * The function will return boolean for all matching keys
 *      const { exactly, all: { biggerThan } } = isMatching(breakpoints, referenceBreakpoint)
 *      exactly // true/false
 *      biggerThan // true/false
 */
export type IsBreakpointMatchingDirectly<T extends string> = IsBreakpointMatching<
    T,
    boolean,
    BreakpointMatches<T, boolean>
>;

/**
 * This util aims to return how the given breakpoint (expected as the current one) matches the whole list of handled breakpoints
 * It can match a certain reference exactly, be bigger, smaller, or included in range
 * It also returns other useful data in case they are needed
 */
export function isBreakpointMatching<T extends string>(breakpoint: T) {
    /**
     * isMatching is flexible enough to handle either only breakpoints object or a reference breakpoint as an additional param
     * Depending on how you want/need to use it, you can either do:
     *    - isMatching(MY_BREAKPOINTS, reference).exactly
     *    - isMatching(MY_BREAKPOINTS).exactly(reference)
     * Same with "all" which gathers all other matching functions:
     *    - isMatching(MY_BREAKPOINTS, reference).all.exactly
     *    - isMatching(MY_BREAKPOINTS).all(reference).exactly
     */
    function isMatching(breakpoints: BreakpointsObject<T>): IsBreakpointMatchingCurrying<T>;
    function isMatching(breakpoints: BreakpointsObject<T>, referenceBreakpoint: T): IsBreakpointMatchingDirectly<T>;
    function isMatching(breakpoints: BreakpointsObject<T>, referenceBreakpoint?: T) {
        const orderedBreakpoints = getOrderedBreakpoints(breakpoints);
        const getIndexOf = getBreakpointIndex(orderedBreakpoints);
        const breakpointIndex = getIndexOf(breakpoint);

        // generates matching functions of all operators
        const matchingKeys = Object.values(BreakpointMatchingOperator).reduce(
            (acc, curr) => ({
                ...acc,
                [curr]: getBreakpointMatchingKey<T>(curr as BreakpointMatchingOperator)(breakpointIndex, getIndexOf),
            }),
            {},
        ) as Record<BreakpointMatchingOperator, (ref: T) => boolean>;

        const exactly = referenceBreakpoint ? matchingKeys.exactly(referenceBreakpoint) : matchingKeys.exactly;
        const atLeast = referenceBreakpoint ? matchingKeys.atLeast(referenceBreakpoint) : matchingKeys.atLeast;
        const atMost = referenceBreakpoint ? matchingKeys.atMost(referenceBreakpoint) : matchingKeys.atMost;
        const biggerThan = referenceBreakpoint ? matchingKeys.biggerThan(referenceBreakpoint) : matchingKeys.biggerThan;
        const smallerThan = referenceBreakpoint
            ? matchingKeys.smallerThan(referenceBreakpoint)
            : matchingKeys.smallerThan;

        return {
            all: referenceBreakpoint
                ? { exactly, atLeast, atMost, biggerThan, smallerThan }
                : (refBreakpoint: T) => ({
                      exactly: matchingKeys.exactly(refBreakpoint),
                      atLeast: matchingKeys.atLeast(refBreakpoint),
                      atMost: matchingKeys.atMost(refBreakpoint),
                      biggerThan: matchingKeys.biggerThan(refBreakpoint),
                      smallerThan: matchingKeys.smallerThan(refBreakpoint),
                  }),
            exactly,
            atLeast,
            atMost,
            biggerThan,
            smallerThan,
            // other useful data
            breakpoint,
            breakpointIndex,
            orderedBreakpoints,
            getIndexOf,
        };
    }

    return isMatching;
}

/**
 * Short verbose version of isBreakpointMatching
 * allows to call -> isBreakpoint(currentBreakpoint).matching(MY_BREAKPOINTS)
 * instead of   ->   isBreakpointMatching(currentBreakpoint)(MY_BREAKPOINTS)
 */
export function isBreakpoint<T extends string>(currentBreakpoint: T) {
    return {
        matching: isBreakpointMatching<T>(currentBreakpoint),
    };
}
