import { Dispatch } from '@lumapps/redux/types';
import { get, cancelGet } from '@lumapps/user/api';

import { actions } from './authorFilterSlice';

const fields = 'uid,email,firstName,lastName';

/**
 * Fetch the post list author filter info
 */
export const fetchAuthor = (email: string) => async (dispatch: Dispatch) => {
    dispatch(actions.fetchAuthorLoading());

    try {
        const { data: author } = await get({ email, fields });
        dispatch(actions.fetchAuthorSuccess(author));
    } catch (excp) {
        dispatch(actions.fetchAuthorError());
    }
};

/**
 * Cancel the post list author filter info fetching
 */
export const cancelFetchAuthor = (email: string) => (dispatch: Dispatch) => {
    cancelGet({ email, fields });
    dispatch(actions.fetchAuthorCancel());
};
