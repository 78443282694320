import React, { useMemo } from 'react';

import pick from 'lodash/pick';

import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Placement, Size, UserBlock as LumXUserBlock, UserBlockProps as LumXUserBlockProps } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { isHideEmailEnabled } from '../../ducks/selectors';
import { getAvatarUrl } from '../../utils/getUserProfilePicture';
import { UserCardPopover, UserCardPopoverProps, useUserCardPopover } from '../UserCardPopover';
import { UserBlockSkeleton, UserBlockSkeletons } from './UserBlockSkeleton';

/** Base UserBlock LumX props. */
type BaseUserBlockProps = Pick<
    LumXUserBlockProps,
    'multipleActions' | 'onMouseLeave' | 'onMouseEnter' | 'orientation' | 'size' | 'theme' | 'nameProps'
>;

export interface UserBlockProps extends BaseUserBlockProps {
    /** Forwarded class name. */
    className?: string;
    /** The user's id. */
    userId: string;
    /** The user's full name. */
    fullName?: string;
    /** The user's email. */
    email?: string;
    /** The url of the user's picture. */
    picture?: string;
    /** Primary fields to display instead of the email. */
    primaryFields?: string[];
    /** Secondary fields to display after the email. */
    secondaryFields?: string[];
    /** Props to forward to the user block avatar. */
    avatarProps?: LumXUserBlockProps['avatarProps'];
    /** Show user card on mouse hover. */
    showCardOnHover?: boolean;
    /** Forward props to the user card popover. */
    userCardPopoverProps?: Partial<UserCardPopoverProps>;
    /** whether the user name should redirect to the user's profile */
    shouldNameRedirectToUserProfile?: boolean;
    /** scope used for tracking and testing purposes */
    scope?: string;
}

/**
 * LumX User block extended with lumapps business logic.
 * - Linked to the user profile (with good a11y label).
 * - Handles email shown/hidden FF.
 * - Can show a user card on hover
 *
 * @family Blocks
 */
export const UserBlock = React.forwardRef<HTMLElement, UserBlockProps>((props, ref) => {
    const {
        userId,
        fullName,
        email,
        picture,
        primaryFields,
        secondaryFields,
        showCardOnHover,
        userCardPopoverProps,
        scope,
        shouldNameRedirectToUserProfile = true,
        avatarProps,
        ...restOfProps
    } = props;
    const { route } = useSocialProfileRoute({ routeParams: { userId } });
    const isEmailHidden = useSelector(isHideEmailEnabled);
    const { get } = useDataAttributes(scope ? `${scope}-user` : 'user');
    const customerId = useSelector(customerIdSelector);
    const avatar = picture || getAvatarUrl(userId, customerId);

    const fields = useMemo(() => {
        return [...(primaryFields || []), !isEmailHidden && !primaryFields && email, ...(secondaryFields || [])].filter(
            Boolean,
        ) as string[];
    }, [email, isEmailHidden, primaryFields, secondaryFields]);

    const { onMouseEnter, onMouseLeave, userProfile, isLoadingUser, showUserCard } = useUserCardPopover(
        {
            id: userId,
            fullName,
            email,
        },
        userCardPopoverProps,
    );

    const refForCard = React.useRef<HTMLElement>(null);
    const linkProps = { to: route } as any;

    return (
        <>
            <LumXUserBlock
                {...get({ element: 'block' })}
                {...pick(restOfProps, [
                    'data-id',
                    'multipleActions',
                    'orientation',
                    'theme',
                    'nameProps',
                    'className',
                    'size',
                ])}
                ref={mergeRefs([ref, refForCard])}
                onMouseEnter={showCardOnHover ? onMouseEnter : undefined}
                onMouseLeave={onMouseLeave}
                name={fullName}
                avatarProps={{
                    image: avatar,
                    alt: fullName || '',
                    linkProps: shouldNameRedirectToUserProfile
                        ? {
                              ...linkProps,
                              'aria-hidden': fullName || !avatar ? true : undefined,
                          }
                        : undefined,
                    ...avatarProps,
                }}
                fields={fields}
                linkAs={shouldNameRedirectToUserProfile && RouterLink}
                linkProps={
                    shouldNameRedirectToUserProfile && {
                        ...linkProps,
                        ...get({ element: 'block-link', action: 'name' }),
                    }
                }
            />

            {showCardOnHover && (
                <UserCardPopover
                    customUserAvatar={avatar}
                    {...userCardPopoverProps}
                    user={userProfile}
                    isDetailsOpen={showUserCard}
                    anchorRef={refForCard}
                    onClose={onMouseLeave}
                    isLoading={isLoadingUser}
                    onMouseEnter={onMouseEnter}
                    placement={Placement.BOTTOM_START}
                />
            )}
        </>
    );
});
UserBlock.defaultProps = { showCardOnHover: true, size: Size.m } as Partial<UserBlockProps>;
UserBlock.displayName = 'UserBlock';

export { UserBlockSkeletons, UserBlockSkeleton };
