/* eslint-disable react/forbid-elements */
import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { sendTrackingDirectoryEntryClickActionEvent } from '@lumapps/directories/api/analytics';
import { currentLanguageSelector } from '@lumapps/languages';
import { ConfirmDialog } from '@lumapps/lumx-dialogs/components/ConfirmDialog';
import { ContextMenu } from '@lumapps/lumx-menu/components/ContextMenu';
import { MenuOption, MenuOptionType } from '@lumapps/lumx-menu/components/ContextMenu/types';
import { ColorBubbleLetter } from '@lumapps/lumx-texts/components/ColorBubbleLetter';
import { mdiStar, mdiStarOutline } from '@lumapps/lumx/icons';
import {
    AspectRatio,
    ColorPalette,
    ColorVariant,
    Emphasis,
    IconButton,
    Link,
    ThumbnailVariant,
    Theme,
    Text,
    Typography,
    Thumbnail,
    Badge,
    Icon,
    Heading,
    HeadingElement,
} from '@lumapps/lumx/react';
import { MetadataLinkList } from '@lumapps/metadata-link/components/MetadataLinkList';
import { MetadataListV2 } from '@lumapps/metadata/types';
import { MicroAppDialog } from '@lumapps/micro-apps/components/MicroAppDialog';
import { useSelector } from '@lumapps/redux/react';
import { isExternalUrl } from '@lumapps/router/utils';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { hashCode } from '@lumapps/utils/string/hashCode';

import { ENTRY_TYPES } from '../../constants';
import { useEntryLink } from '../../hooks/useEntryLink';
import { DIRECTORY_ENTRY } from '../../keys';
import { DetailedDirectoryEntryBlockProps, DetailedDirectoryEntryVariant } from '../../types';
import { getDirectoryEntryIcon } from '../../utils';
import { DirectoryEntryCreationDialog } from '../DirectoryEntryCreationDialog';

import './index.scss';

const Config = get();

export const CLASSNAME = 'directory-entry-block';

export const DetailedDirectoryEntryBlock = ({
    additionalFields,
    directoryEntryId,
    directoryEntryType,
    microAppProperties,
    resourceId,
    title,
    tags,
    metadata,
    link,
    image,
    isFavorite,
    isFavoriteIconVisible,
    variant = DetailedDirectoryEntryVariant.horizontal,
    favorite,
    unfavorite,
    canEdit = false,
    canDelete = false,
    theme = Theme.light,
    creationDialogProps,
    deleteDialogProps,
    openDeleteDialog,
    openSaveDialog,
    badgeProps,
    classname,
    titleAs,
}: DetailedDirectoryEntryBlockProps) => {
    const { translateKey } = useTranslate();
    const { element, block } = useClassnames(CLASSNAME);
    const currentLanguage = useSelector(currentLanguageSelector);

    const [isMicroAppDialogOpen, toggleMicroAppDialog] = useBooleanState(false);

    const href = useEntryLink(link) || undefined;

    const linkProps = React.useMemo(
        () =>
            directoryEntryType === ENTRY_TYPES.LINK
                ? {
                      href,
                      target: isExternalUrl(href) ? '_blank' : '_self',
                      rel: isExternalUrl(href) ? 'noopener noreferrer' : undefined,
                  }
                : {},
        [directoryEntryType, href],
    );

    // Metadata needs to be formaded to type MetadataListV2 in order to be used with MetadataLinkList component
    const metadataList: MetadataListV2 | undefined = metadata?.map(({ metadataId, name }) => ({
        name,
        id: metadataId,
    }));

    const trackClickActions = () => {
        if (directoryEntryType === ENTRY_TYPES.MICROAPP && resourceId) {
            toggleMicroAppDialog();
        }
        sendTrackingDirectoryEntryClickActionEvent({
            targetId: directoryEntryId,
            url: link,
            title,
            thumbnail: image?.url,
        });
    };

    const microAppIcon = getDirectoryEntryIcon({ type: directoryEntryType, resourceProperties: microAppProperties });

    const microAppThumbnail = microAppProperties?.componentSettings.length
        ? microAppIcon?.[currentLanguage] || ''
        : undefined;

    const thumbnailUrl = image?.url || microAppThumbnail;

    const menuOptions = React.useMemo(() => {
        const options: MenuOption[] = [];

        if (canEdit && openSaveDialog) {
            options.push({
                type: MenuOptionType.option,
                labelKey: translateKey(GLOBAL.EDIT),
                onSelect: () => {
                    openSaveDialog(directoryEntryId);
                },
            });
        }

        if (canDelete) {
            options.push({
                type: MenuOptionType.option,
                labelKey: translateKey(GLOBAL.DELETE),
                onSelect: openDeleteDialog,
            });
        }

        return options;
    }, [canEdit, canDelete, translateKey, openSaveDialog, directoryEntryId, openDeleteDialog]);

    return (
        <>
            <div
                key={directoryEntryId}
                data-testid="container-directory-entry"
                className={block(
                    {
                        [`theme-${theme}`]: true,
                        horizontal: variant === DetailedDirectoryEntryVariant.horizontal,
                        vertical: variant === DetailedDirectoryEntryVariant.vertical,
                    },
                    classname,
                )}
            >
                {/**
                 * Hide thumbnail from screen readers and remove focus from it as it is only decorative.
                 * As the title itself is the same link, this element is only useful for mouse users that
                 * want to click on the icon instead of the title.
                 */}
                <a className={element('thumbnail-wrapper')} tabIndex={-1} aria-hidden="true" {...linkProps}>
                    {thumbnailUrl ? (
                        <Thumbnail
                            {...image}
                            theme={theme}
                            alt=""
                            variant={ThumbnailVariant.squared}
                            aspectRatio={AspectRatio.square}
                            image={thumbnailUrl}
                            className={element('image')}
                            badge={
                                badgeProps && (
                                    <Badge {...badgeProps}>
                                        <Icon icon={badgeProps.icon} />
                                    </Badge>
                                )
                            }
                        />
                    ) : (
                        <ColorBubbleLetter
                            className={element('letter')}
                            text={title || link}
                            colors={Config.defaultColors}
                        />
                    )}
                </a>

                <div className={element('content')}>
                    <Heading as={titleAs as HeadingElement} typography="custom-title5">
                        <Link {...linkProps} className={element('title')} onClick={trackClickActions}>
                            {title || link}
                        </Link>
                    </Heading>

                    {!!tags?.items?.length && (
                        <div className={element('tags')}>
                            {tags.items.map((tag) => (
                                <span key={tag.tagId}>
                                    <span>{tag.name}</span>
                                </span>
                            ))}
                        </div>
                    )}

                    {additionalFields &&
                        additionalFields
                            .flatMap(({ values }) => values)
                            .map((value) => (
                                <Text
                                    key={hashCode(String(value))}
                                    className={element('custom-field')}
                                    typography={Typography.body1}
                                    color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
                                    colorVariant={ColorVariant.L2}
                                    as="span"
                                >
                                    {value}
                                </Text>
                            ))}

                    <MetadataLinkList metadataList={metadataList} theme={theme} contentTypeId="" />
                </div>

                {deleteDialogProps && creationDialogProps && (canEdit || canDelete) && (
                    <>
                        <ContextMenu
                            theme={theme}
                            menuOptions={menuOptions}
                            scope={`directory-entry-${directoryEntryId}`}
                        />
                        <DirectoryEntryCreationDialog {...creationDialogProps} />
                        <ConfirmDialog
                            {...deleteDialogProps}
                            confirmMessage={
                                <Text as="p" typography={Typography.body1}>
                                    {deleteDialogProps.confirmMessage}
                                </Text>
                            }
                        />
                    </>
                )}

                {isFavoriteIconVisible && (
                    <div className={element('actions')}>
                        <IconButton
                            emphasis={Emphasis.low}
                            icon={isFavorite ? mdiStar : mdiStarOutline}
                            onClick={isFavorite ? unfavorite : favorite}
                            theme={theme}
                            label={translateKey(
                                isFavorite
                                    ? DIRECTORY_ENTRY.REMOVE_ENTRY_FROM_FAVORITE
                                    : DIRECTORY_ENTRY.FAVORITE_BUTTON_ARIA,
                            )}
                            aria-pressed={isFavorite}
                        />
                    </div>
                )}
            </div>
            {resourceId && (
                <MicroAppDialog isOpen={isMicroAppDialogOpen} onClose={toggleMicroAppDialog} id={resourceId} />
            )}
        </>
    );
};

DetailedDirectoryEntryBlock.displayName = 'DetailedDirectoryEntryBlock';
